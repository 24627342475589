import React from 'react';
import { Stack, Icon, Typography } from '../../design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getRiskLevelByScore } from '../../module_interface/RiskManagement/RiskLevel';
import { IconSizes } from '../../design-system/components-v2/Icon/Icon.types';

const RiskScoreBadge: React.FC<{ score: number; iconOnly?: boolean; iconSize?: IconSizes }> = ({
    score,
    iconOnly,
    iconSize,
}) => {
    const { t } = useTranslation();

    const riskLevel = getRiskLevelByScore(score);
    const riskScoreText = t('COMMON.PRETTY_NUMBER', {
        value: score,
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });

    return (
        <Stack alignItems='center' spacing={2} direction='row' overflow='hidden' data-aid='risk-score-renderer'>
            <Icon name={riskLevel?.gaugeIconName || 'riskGaugeUnknown'} context={riskLevel?.context} size={iconSize} />
            {!iconOnly && <Typography ellipsis>{riskScoreText}</Typography>}
        </Stack>
    );
};

export default RiskScoreBadge;
