import { forwardRef } from 'react';
import { IMarkdownProps } from './Markdown.types';
import LibMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Link from '../Link/Link';
import Command from '../Command/Command';
import Typography from '../Typography/Typography';
import MarkdownStyles from './Markdown.styles';
import Stack from '../Stack/Stack';

const Markdown = forwardRef<HTMLDivElement, IMarkdownProps>((props, ref) => {
    const { markdown, customizeComponents } = props;

    return (
        <MarkdownStyles.StyledWrapper ref={ref}>
            <LibMarkdown
                components={{
                    p({ children }) {
                        return <Typography>{children}</Typography>;
                    },
                    strong({ children }) {
                        return <Typography variant='body500'>{children}</Typography>;
                    },
                    ol({ children }) {
                        return (
                            <Stack spacing={1} padding={[3, 0]}>
                                {children}
                            </Stack>
                        );
                    },
                    li({ children }) {
                        return <MarkdownStyles.TextWithDisc>{children}</MarkdownStyles.TextWithDisc>;
                    },
                    a({ children }) {
                        return <Link externalUrl={children?.toString() || ''}>{children}</Link>;
                    },
                    code({ children }) {
                        return <Command text={children?.toString() || ''}>{children}</Command>;
                    },
                    ...customizeComponents,
                }}
                remarkPlugins={[remarkGfm]}
            >
                {markdown}
            </LibMarkdown>
        </MarkdownStyles.StyledWrapper>
    );
});
Markdown.displayName = 'Markdown';

export default Markdown;
