import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IAlternativeCloudEntityData, ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { getAllAvailableCloudEntityData } from 'common/components/ProtectedAssets/AssetUtils';
import { AssetPropertiesPanel } from 'common/components/AssetPropertiesPanel/AssetPropertiesPanel';
import { EntityNameLink } from './EntityNameLink';
import { t } from 'i18next';
import { FINDING_ASSET_PANEL_ID } from '../../KustoEvents/KustoEvent.const';
import { IEntityObjectModel } from '../Findings.interface';
import { LoadingState } from '../../../interface/general';
import { generalApiError } from '../../../utils/http';
import FullSizeSpinner from '../../../erm-components/custom/FullSize/FullSizeSpinner';
import { getProtectedAssetsService } from '../../../module_interface/assets/ProtectedAssets';
import { AssetStyled } from '../../AssetPropertiesPanel/AssetPropertiesPanel.styled';
import IconLinkCellRenderer, { IIconLinkCellProps } from '../../ag-grid/Renderers/IconLinkCellRenderer';

export interface IFindingAssetPanelProps {
    entityName: string;
    entityType: string;
    entityDome9Id: string;
    entityExternalId: string;
    cloudAccountType: string;
    cloudAccountId: string;
    entityObject?: IEntityObjectModel;
}

export const FindingAssetPanel: React.FC<IFindingAssetPanelProps> = ({
    entityName,
    entityExternalId,
    entityDome9Id,
    entityType,
    cloudAccountType,
    cloudAccountId,
    entityObject,
}) => {
    const typeByPlatform = useMemo(() => cloudAccountType + '|' + entityType, [cloudAccountType, entityType]);
    const [cloudEntityData, setCloudEntityData] = useState<ICloudEntityData>();
    const [alternativeEntityData, setAlternativeEntityData] = useState<IAlternativeCloudEntityData | undefined>();
    const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);

    const getNameComponent = useCallback((): JSX.Element => {
        if (cloudEntityData) {
            return (
                <EntityNameLink
                    entityName={entityName}
                    entityType={entityType}
                    entityId={entityDome9Id}
                    entityExternalId={entityExternalId}
                    cloudAccountId={cloudAccountId}
                />
            );
        }

        const options: IIconLinkCellProps = {
            isLink: false,
            value: entityName,
        };
        return <IconLinkCellRenderer {...options} />;
    }, [cloudEntityData, entityName, entityType, entityDome9Id, entityExternalId, cloudAccountId]);

    const entityFields = useMemo(() => EventOverviewRegistry.getFields(FINDING_ASSET_PANEL_ID), []);

    const getCloudEntity = useCallback((): Promise<ICloudEntityData | undefined> => {
        return Promise.resolve(cloudEntityData);
    }, [cloudEntityData]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const partialEntityData: Partial<ICloudEntityData> | undefined = await getAllAvailableCloudEntityData(
                    entityDome9Id || entityExternalId,
                    entityType,
                    cloudAccountType,
                    cloudAccountId,
                    generalApiError,
                );
                if (
                    partialEntityData &&
                    partialEntityData.cloudEntity &&
                    partialEntityData.protectedAsset &&
                    partialEntityData.entityId &&
                    partialEntityData.cloudAccount &&
                    partialEntityData.cloudAccountId &&
                    partialEntityData.typeByPlatform &&
                    partialEntityData.asset
                ) {
                    const currEntityData: ICloudEntityData = {
                        ...partialEntityData,
                        typeByPlatform: partialEntityData.typeByPlatform,
                        cloudAccountId: partialEntityData.cloudAccountId,
                        cloudEntity: partialEntityData.cloudEntity,
                        entityId: partialEntityData.entityId,
                        protectedAsset: partialEntityData.protectedAsset,
                        cloudAccount: partialEntityData.cloudAccount,
                        asset: partialEntityData.asset,
                    };
                    setCloudEntityData(currEntityData);
                } else {
                    const currAlternativeEntityData: IAlternativeCloudEntityData = {
                        entityName,
                        entityType,
                        cloudAccountType,
                        cloudAccountId: partialEntityData?.cloudAccountId || cloudAccountId,
                        entityId: partialEntityData?.entityId || entityDome9Id || entityExternalId,
                        typeByPlatform: partialEntityData?.typeByPlatform || typeByPlatform,
                        entityObject,
                        asset:
                            partialEntityData?.asset ||
                            getProtectedAssetsService().getAssetByType(typeByPlatform) ||
                            undefined,
                        cloudEntity: partialEntityData?.cloudEntity,
                        cloudAccount: partialEntityData?.cloudAccount,
                        organizationalUnit: partialEntityData?.organizationalUnit,
                        organizationalUnitFullPath: partialEntityData?.organizationalUnitFullPath,
                    };
                    setAlternativeEntityData(currAlternativeEntityData);
                }
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            } catch (err) {
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            }
        };

        setLoadingState(LoadingState.IS_LOADING);
        void loadData();
    }, [
        cloudAccountId,
        cloudAccountType,
        entityDome9Id,
        entityExternalId,
        entityName,
        entityObject,
        entityType,
        typeByPlatform,
    ]);

    if (loadingState === LoadingState.IS_LOADING) {
        return (
            <AssetStyled.TopDiv>
                <FullSizeSpinner />
            </AssetStyled.TopDiv>
        );
    }

    return (
        <AssetPropertiesPanel
            registeredEntityFields={entityFields}
            typeByPlatform={cloudEntityData?.typeByPlatform || typeByPlatform}
            alternativeCloudEntityData={alternativeEntityData}
            getCloudEntity={getCloudEntity}
            headerComponent={getNameComponent()}
            title={t('EVENTS.FOUND_IN_ASSET')}
        />
    );
};
