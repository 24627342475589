import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgWaiting = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
        <path
            d='M3 1.5h1.5m8.5 0h-1.5M3 14.5h1.5m8.5 0h-1.5m-7-13h7m-7 0v1.66c0 1.167.51 2.277 1.397 3.037l4.206 3.606A4 4 0 0 1 11.5 12.84v1.66m-7 0h7m-7 0v-1.66a4 4 0 0 1 1.397-3.037l4.206-3.606A4.001 4.001 0 0 0 11.5 3.16V1.5'
            stroke='currentColor'
            strokeOpacity={0.9}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
const ForwardRef = forwardRef(SvgWaiting);
export default ForwardRef;
