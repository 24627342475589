import React from 'react';
import { changeUrl, generateHref } from '../../utils/http';
import './Anchor.scss';

interface AProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
    children: any;
    className?: string;
    dataAid?: string;
}

export const A = React.forwardRef<HTMLAnchorElement, AProps>((props, ref) => {
    const { href, children, className = '', dataAid, onClick, ...rest } = props;
    const onAClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (event.ctrlKey || event.metaKey) {
            //if Control or Command pressed let the browser handle this.
            return;
        }
        event.preventDefault();
        changeUrl(href);
        onClick && onClick(event);
    };

    return (
        <a
            ref={ref}
            className={`anchor cursor-pointer ${className}`}
            href={generateHref(href)}
            onClick={onAClick}
            data-aid={dataAid}
            {...rest}
        >
            {children}
        </a>
    );
});
A.displayName = 'A';
