import styled from 'styled-components';

export const ExclusionsLayout = styled.main`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
    padding: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
`;

export const FilterPanelWrapper = styled.div``;
export const LoaderWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
`;
