import React from 'react';
import { LoadingState } from '../../interface/general';
import AssetContent from './AssetContent';
import { ILevelsWidgetData, ITipProps, IWidgetLevelInfo } from './AssetWidget.interface';
import { Card, Icon, Tooltip } from '../../design-system/components-v2';
import { AssetWidgetStyled } from './AssetWidget.styled';
import { logAndRunOnComponentClick } from '../../utils/logUtils';

interface SingleAssetWidgetProps {
    descendingLevelInfos: IWidgetLevelInfo[];
    widget: ILevelsWidgetData;
    loadingState?: LoadingState;
    tipProps?: ITipProps;
}

const SingleAssetWidget: React.FC<SingleAssetWidgetProps> = ({
    descendingLevelInfos,
    widget,
    loadingState = LoadingState.LOADING_SUCCEEDED,
    tipProps,
}) => {
    const onClickWithLog = tipProps
        ? () => logAndRunOnComponentClick(tipProps.onClick, widget.title + '-header')
        : undefined;
    const rightContent = tipProps && (
        <AssetWidgetStyled.TitleActionDiv onClick={onClickWithLog}>
            <Tooltip placement='bottom' content={tipProps.text}>
                <Icon name='tips' size={16} />
            </Tooltip>
        </AssetWidgetStyled.TitleActionDiv>
    );

    return (
        <Card
            key={widget.title}
            title={widget.title}
            tooltip={widget.helpInfoText}
            smallSize
            rightContent={rightContent}
        >
            <AssetContent
                descendingLevelInfos={descendingLevelInfos}
                widget={widget}
                loadingState={loadingState}
                onClick={widget?.onClick}
            />
        </Card>
    );
};

export default SingleAssetWidget;
