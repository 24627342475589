import React from 'react';
import { Button, Tooltip } from '@dome9/berries/react-components';
import { isNil } from '../../../utils/helpFunctions';

const TooltipWrapper: React.FC<{ children?: JSX.Element; tooltip?: React.ReactNode; className?: string }> = ({
    children,
    tooltip,
    className,
}) => {
    if (!isNil<React.ReactNode>(tooltip)) {
        return (
            <Tooltip placement='bottom' content={tooltip}>
                <Button variant='quiet' size='sm' className={`btn--icon ${className || ''}`}>
                    {children}
                </Button>
            </Tooltip>
        );
    } else {
        return <> {children} </>;
    }
};

export default TooltipWrapper;
