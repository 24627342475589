import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCommand = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g stroke="currentColor" strokeOpacity={0.9} strokeLinecap="round">
      <path d="m3.5 5.5 4 2-4 2m5 1h4" strokeLinejoin="round" />
      <path d="M13.5.5h-11a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-11a2 2 0 0 0-2-2Z" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgCommand);
export default ForwardRef;
