import styled from 'styled-components';
import IconButton from '../IconButton';

const CopyIconWrapper = styled.span`
    cursor: pointer;
`;
const CopiableLineWrapper = styled.span`
    position: relative;
    display: inline;
    width: fit-content;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 20px;
        transform: translateX(20px);
    }
`;

interface IconButtonOnHoverProps {
    isCopied: boolean;
}
const IconButtonOnHover = styled(IconButton)<IconButtonOnHoverProps>`
    cursor: pointer;
    top: 50%;
    right: -4px;
    transform: translateY(-50%) translateX(100%);
    z-index: 10;
    display: ${({ isCopied }) => (isCopied ? 'block' : 'none')};
    position: absolute;

    ${CopiableLineWrapper}:hover & {
        display: block;
    }
`;

const CopiableLineStyled = {
    CopyIconWrapper,
    CopiableLineWrapper,
    IconButtonOnHover,
};

export default CopiableLineStyled;
