import { CompoundFilterLogicalOperator, ICompoundFilter } from './CompoundFilter';
import React from 'react';
import { FilterConditionOperator, IFilterConditionValue } from './FilterCondition';
import { SelectOption } from '../SelectV2/Select.types';

export interface FilterTreeProps {
    compoundFilter?: ICompoundFilter;
    filterDefinitions: IFilterTreeFieldDefinition[];
    displayErrors: boolean;
    onFilterChange?: (filterInfo: IFilterChangeEventInfo) => void;
}

export enum FilterTreeItemType {
    condition,
    node,
}

export interface IFilterTreeItem {
    id: string;
    parentNode?: IFilterTreeNode;
    itemType: FilterTreeItemType;
    errorMarkRef?: HTMLInputElement | null;
}

export interface IFilterTreeCondition extends IFilterTreeItem {
    name: string;
    values: IFilterConditionValue[];
    operator?: FilterConditionOperator;
    parentNode: IFilterTreeNode;
    nameErrorMsg?: string;
    operatorErrorMsg?: string;
    valuesErrorMsg?: string;
    itemType: FilterTreeItemType.condition;
}

export interface IFilterTreeNode extends IFilterTreeItem {
    logicalOperator: CompoundFilterLogicalOperator;
    childItems: IFilterTreeItem[];
    errorMsg?: string;
    itemType: FilterTreeItemType.node;
}

export interface IFilterTree {
    root: IFilterTreeNode;
}

export enum FilterConditionType {
    DATE,
    SELECTION,
    FREE_TEXT,
}

export interface IFilterTreeFieldDefinition {
    name: string;
    header: string;
    getSelectOptions?: (values: any[]) => Promise<IConditionValueOption[]>;
    conditionOperatorsInfo: IConditionOperatorsInfo;
    conditionValuesComponent: React.FC<IConditionValuesComponentProps>;
    conditionValuesChecker?: (values: any[]) => string | undefined;
    valueOptions?: IConditionValueOption[];
}

export interface IFilterTreeInnerApi {
    onConditionChange: (condition: IFilterTreeCondition) => void;
    onLogicalOperatorChange: (node: IFilterTreeNode, operator: CompoundFilterLogicalOperator) => void;
    onRemoveItem: (item: IFilterTreeItem) => void;
    onAddCondition: (parentNode: IFilterTreeNode, insertionIndex: number) => void;
    onAddNode: (
        parentNode: IFilterTreeNode,
        insertionIndex: number,
        logicalOperator: CompoundFilterLogicalOperator,
    ) => void;
    onAddChildCondition: (parentNode: IFilterTreeNode) => void;
    onAddChildNode: (parentNode: IFilterTreeNode, logicalOperator: CompoundFilterLogicalOperator) => void;
    clearFilter: () => void;
}

export interface IFilterTreeCommonProps {
    filterDefinitions: IFilterTreeFieldDefinition[];
    displayErrors: boolean;
    api: IFilterTreeInnerApi;
}

export interface IFacetCounter {
    value: any;
    count: number;
}

export interface IFacetData {
    facet: string;
    data: IFacetCounter[];
}

export interface IFilterChangeEventInfo {
    filter?: ICompoundFilter;
    validFilterSubset?: ICompoundFilter;
    hasErrors?: boolean;
    errorRefs?: HTMLInputElement[];
}

export interface IConditionValueOption extends Omit<SelectOption, 'value'> {
    value: IFilterConditionValue;
}

export interface IConditionOperatorsInfo {
    operators: FilterConditionOperator[];
    defaultOperator: FilterConditionOperator;
}

export interface IConditionValuesComponentProps {
    condition: IFilterTreeCondition;
    filterProps: IFilterTreeCommonProps;
    onValuesChange: (values: IFilterConditionValue[]) => void;
}

export interface IMovingTreeItemInfo {
    movingItem: IFilterTreeItem;
    oldParent: IFilterTreeNode;
    newParent: IFilterTreeNode;
    indexInNewParent: number;
}
