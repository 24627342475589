import styled from 'styled-components';

const TopDiv = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 100;
    border-radius: 8px;
`;

const SpinnerDiv = styled.div`
    display: flex;
    margin-left: 5px;
    align-items: center;
    background-color: white;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    min-height: 32px;
    
    button, button:hover {
        cursor: default;
    }
`;

export const PendingFilterPanelStyled = {
    TopDiv,
    SpinnerDiv,
};
