import { FC, ReactElement, useState } from 'react';
import { Icon } from '@dome9/berries/react-components';
import Typography from 'common/design-system/components-v2/Typography';
import { HighlightedExpandablePanelWrapper } from './ExpandablePanel.styled';

interface ExpandablePanelProps {
    title: string;
    children: ReactElement;
    isHighlighted?: boolean;
}

const ExpandablePanel: FC<ExpandablePanelProps> = ({ children, title, isHighlighted = false }) => {
    const [expandedContent, setExpandedContent] = useState(false);

    return (
        <>
            <HighlightedExpandablePanelWrapper
                isHighlighted={isHighlighted}
                onClick={() => setExpandedContent(!expandedContent)}
            >
                <Icon size={10} name={expandedContent ? 'chevron-down' : 'chevron-right'} />
                {isHighlighted ? (
                    <Typography variant='bodyLg' color='link'>
                        {title}
                    </Typography>
                ) : (
                    title
                )}
            </HighlightedExpandablePanelWrapper>
            {expandedContent && <div>{children}</div>}
        </>
    );
};

export default ExpandablePanel;
