import { convertCompoundFilterToQuery } from 'common/components/gsl/GslCompoundFilter';
import {
    IGslBin,
    IGslCount,
    IGslFilter,
    IGslFreeTextFilter,
    IGslJoin,
    IGslQueryRequest,
    IGslSort,
    IGslTimeFilter,
} from 'common/components/gsl/GslService.interface';
import { IGslRunResponse } from 'common/module_interface/intelligence/Intelligence.interface';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { isEmpty } from 'lodash';
import { GSL_CLAUSES } from '../../module_interface/intelligence/Gsl/GslService.const';
import { GslCommonFields } from './GslCommonFields';

export const createGslQuery = (gslQueryRequest: IGslQueryRequest): string => {
    const { filter, sort, count, limit, aggregations, join } = gslQueryRequest;
    const query: string[] = [];
    query.push(createFilterClause(filter));
    if (join) query.push(createJoinClause(join));
    if (aggregations) query.push(createAggregationClause(aggregations));
    if (count) query.push(createCountClause(count));
    if (sort) query.push(createSortClause(sort));
    if (limit) query.push(createLimitClause(limit));
    return `${filter.source} ${query.join(' ')}`;
};

export const createFilterClause = (filter: IGslFilter): string => {
    const filtersQuery = [];
    if (filter.compoundFilter) {
        filtersQuery.push(`(${convertCompoundFilterToQuery(filter.compoundFilter)})`);
    }
    if (filter.freeTextFilter) {
        filtersQuery.push(`(${createFreeTextClause(filter.freeTextFilter)})`);
    }
    if (filter.timeFilter && filter.timeFilter.range.from && filter.timeFilter.range.to) {
        filtersQuery.push(`(${createTimeTRangeClause(filter.timeFilter)})`);
    }
    return `${GSL_CLAUSES.WHERE} ${filtersQuery.join(' and ')}`;
};

export const createFreeTextClause = (freeTextFilter: IGslFreeTextFilter): string => {
    const freeTextClauses = freeTextFilter.fields.map((field: string) => `${field} like '%${freeTextFilter.phrase}%'`);
    return freeTextClauses.join(' or ');
};

export const createTimeTRangeClause = (timeRangeFilter: IGslTimeFilter): string => {
    const timeClause: string[] = [];
    const { range, field } = timeRangeFilter;
    timeClause.push(`${field} >= ${GSL_CLAUSES.UNIX_TO_DATETIME}(${range.from})`);
    timeClause.push(`${field} <= ${GSL_CLAUSES.UNIX_TO_DATETIME}(${range.to})`);
    return timeClause.join(' and ');
};

export const createSortClause = (sort: IGslSort[]): string => {
    const sortQuery: string[] = [];
    sortQuery.push(GSL_CLAUSES.ORDER_BY);
    sortQuery.push(createSortByClause(sort));
    return sortQuery.join(' ');
};

const createSortByClause = (sort: IGslSort[]): string => {
    const orderByClause: string[] = [];
    sort.forEach((sort: IGslSort) => {
        orderByClause.push(`${sort.fieldName} ${sort.direction}`);
    });
    return orderByClause.join(', ');
};

export const createAggregationClause = (aggregations: string[]): string => {
    const aggregationsQuery: string[] = [];
    aggregationsQuery.push(GSL_CLAUSES.FACET_BY);
    aggregationsQuery.push(aggregations.join(', '));
    return aggregationsQuery.join(' ');
};

const createBinClause = (bins: IGslBin[]): string => {
    return `${GSL_CLAUSES.BIN}(${bins.map((bin: IGslBin) => `${bin.field}, ${bin.roundTo}`).join(', ')})`;
};

export const createCountClause = (count: IGslCount): string => {
    const countType = count.distinctCountField
        ? `${GSL_CLAUSES.DISTINCT_COUNT}(${count.distinctCountField})`
        : `${GSL_CLAUSES.COUNT}()`;
    const countQuery: string[] = [];
    const noBinsFields = count.fields?.filter((field) => !count.bins?.find((bin) => bin.field === field));
    if (count.bins && count.bins.length > 0) {
        countQuery.push(`${createBinClause(count.bins)}`);
    }
    if (noBinsFields && noBinsFields.length > 0) {
        countQuery.push(`${noBinsFields.join(', ')}`);
    }
    return `${GSL_CLAUSES.SUMMARIZE} ${countType} as ${GslCommonFields.count} ${count.fields || count.bins ? 'by' : ''} ${countQuery.join(', ')}`;
};

export const createLimitClause = (limit: number): string => {
    return `${GSL_CLAUSES.LIMIT} ${limit}`;
};

export const createJoinClause = (join: IGslJoin): string => {
    return `${GSL_CLAUSES.JOIN} ${join.source}`;
};

export const createGetItemsQuery = (filter: IGslFilter, limit?: number, sort?: IGslSort[], join?: IGslJoin): string => {
    return createGslQuery({ filter, sort, limit, join });
};

export const createGetCountQuery = (
    filter: IGslFilter,
    count: IGslCount,
    join?: IGslJoin,
    sort?: IGslSort[],
    limit?: number,
): string => {
    return createGslQuery({ filter, count, join, sort, limit });
};

export const createGetAggregationsQuery = (filter: IGslFilter, aggregations: string[], join?: IGslJoin): string => {
    return createGslQuery({ filter, aggregations, join });
};

export const convertFacetsToAggregations = (facetFields: string[], facets?: IGslRunResponse[]): Aggregations => {
    const aggregations: Aggregations = {};
    facets?.forEach((facet) => {
        if (!facet?.cols) return aggregations;
        const cols = Object.keys(facet.cols);
        const facetFieldName = cols.find((col) => facetFields.includes(col)) || EMPTY_STRING;
        if (!isEmpty(facetFieldName)) {
            const facetCountFieldName = `count_${facetFieldName}`;
            aggregations[`${facetFieldName}`] = facet.data.map((data) => {
                return { value: data[facet.cols[facetFieldName]], count: data[facet.cols[facetCountFieldName]] };
            });
        }
    });
    return aggregations;
};
