import { Addin } from '../../extensibility/AddinRegistry';
import { buildPath, globalAddinContainer } from '../../extensibility/AddinContainer';
import {
    IAssetDetailsPageAliases,
    IProtectedAssetDetailPanelProvider,
    IProtectedAssetDetailProvider,
} from './ProtectedAssets';
import { InfoPaneFieldProps, InfoPanelActionProps } from '../../components/InfoPane/InfoPane';
import { PATH_PROTECTED_ASSETS_PAGE } from './ProtectedAssets.consts';

const PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_FIELDS = buildPath(PATH_PROTECTED_ASSETS_PAGE, 'asset details fields');
const PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_ALIASES = buildPath(PATH_PROTECTED_ASSETS_PAGE, 'asset details aliases');
const PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_PANELS = buildPath(PATH_PROTECTED_ASSETS_PAGE, 'asset details panels');
const PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_ACTIONS = buildPath(PATH_PROTECTED_ASSETS_PAGE, 'asset details actions');

export const ProtectedAssetsPageRegistry = {
    addFieldAddins: (fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[]) => {
        globalAddinContainer.add<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_FIELDS,
            fieldAddins,
        );
    },

    getFields: (): IProtectedAssetDetailProvider<InfoPaneFieldProps>[] => {
        return globalAddinContainer.get<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_FIELDS,
        );
    },

    addAliasesAddins: (aliasAddins: Addin<IAssetDetailsPageAliases>[]) => {
        globalAddinContainer.add<IAssetDetailsPageAliases>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_ALIASES,
            aliasAddins,
        );
    },

    getAliases: (): IAssetDetailsPageAliases[] => {
        return globalAddinContainer.get<IAssetDetailsPageAliases>(PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_ALIASES);
    },

    addPanelAddins: (panelAddins: Addin<IProtectedAssetDetailPanelProvider>[]) => {
        globalAddinContainer.add<IProtectedAssetDetailPanelProvider>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_PANELS,
            panelAddins,
        );
    },

    getPanels: (): IProtectedAssetDetailPanelProvider[] => {
        return globalAddinContainer.get<IProtectedAssetDetailPanelProvider>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_PANELS,
        );
    },

    addChipProviders: (chipProviderAddins: Addin<IProtectedAssetDetailProvider<InfoPanelActionProps>>[]) => {
        globalAddinContainer.add<IProtectedAssetDetailProvider<InfoPanelActionProps>>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_ACTIONS,
            chipProviderAddins,
        );
    },

    getChipProviders: (): IProtectedAssetDetailProvider<InfoPanelActionProps>[] => {
        return globalAddinContainer.get<IProtectedAssetDetailProvider<InfoPanelActionProps>>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_ACTIONS,
        );
    },
};
