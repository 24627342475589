import React from 'react';
import { DrawerBus } from './DrawerBus';

type IUseDrawerHandlerProps = (eventHandlerId: string, handleEvent: (event?: any) => void) => void;
export const useDrawerHandler: IUseDrawerHandlerProps = (eventHandlerId, handleEvent) => {
    React.useEffect(() => {
        DrawerBus.registerEventHandler(eventHandlerId, handleEvent);
        return () => {
            DrawerBus.unregisterEventHandler(eventHandlerId);
        };
    }, [eventHandlerId, handleEvent]);
};
