import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
    min-width: 0px;
`;

const ActionWrapper = styled.div`
    overflow: hidden;
`;

const ActionsStyles = {
    Wrapper,
    ActionWrapper,
};

export default ActionsStyles;
