import Stack from '../../Stack';
import { StatusColor, StatusIconProps, StatusIconType, StatusVariant } from './StatusIcon.types';
import { StatusIconContainer } from './StatusIcon.styles';

export const getVariant = (statusIconType: StatusIconType) => statusIconType.split('-')[1] as StatusVariant;
export const getColor = (statusIconType: StatusIconType) => statusIconType.split('-')[0] as StatusColor;

const colorToIcon: Record<StatusIconProps['color'], (onlyIcon: boolean) => JSX.Element> = {
    success: (onlyIcon: boolean) => (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={onlyIcon ? 32 : 24}
            height={onlyIcon ? 32 : 25}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 22.3C17.523 22.3 22 17.823 22 12.3C22 6.77705 17.523 2.30005 12 2.30005C6.477 2.30005 2 6.77705 2 12.3C2 17.823 6.477 22.3 12 22.3ZM15.536 8.76405C15.7238 8.57654 15.9783 8.47131 16.2437 8.47149C16.5091 8.47168 16.7635 8.57728 16.951 8.76505C17.1385 8.95282 17.2437 9.20739 17.2436 9.47276C17.2434 9.73812 17.1378 9.99254 16.95 10.18L11.297 15.833L11.293 15.837C11.2003 15.9302 11.0902 16.0042 10.9689 16.0546C10.8475 16.1051 10.7174 16.1311 10.586 16.1311C10.4546 16.1311 10.3245 16.1051 10.2031 16.0546C10.0818 16.0042 9.97165 15.9302 9.879 15.837L9.875 15.833L7.05 13.008C6.95449 12.9158 6.87831 12.8055 6.8259 12.6835C6.77349 12.5615 6.7459 12.4302 6.74475 12.2975C6.7436 12.1647 6.7689 12.033 6.81918 11.9101C6.86946 11.7872 6.94371 11.6755 7.0376 11.5817C7.1315 11.4878 7.24315 11.4135 7.36605 11.3632C7.48894 11.3129 7.62062 11.2876 7.7534 11.2888C7.88618 11.29 8.0174 11.3175 8.1394 11.3699C8.26141 11.4224 8.37175 11.4985 8.464 11.594L10.586 13.715L15.536 8.76505V8.76405Z'
                fill='#3BA713'
            />
        </svg>
    ),
    warning: (onlyIcon: boolean) => (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={onlyIcon ? 32 : 24}
            height={onlyIcon ? 32 : 25}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                d='M2.35399 21.1727C2.79274 21.8759 3.55409 22.3018 4.38644 22.3018H19.6136C20.4459 22.3018 21.2073 21.8759 21.646 21.1727C22.0783 20.4629 22.117 19.5919 21.7492 18.8499L14.1292 3.61627C13.7227 2.80976 12.9097 2.30005 12 2.30005C11.0903 2.30005 10.2773 2.80976 9.87079 3.61628L2.25077 18.8499C1.883 19.5983 1.9217 20.4629 2.35399 21.1727ZM12.9678 8.72639V14.8753C12.9678 15.4109 12.5355 15.8432 12 15.8432C11.4645 15.8432 11.0322 15.4109 11.0322 14.8753V8.72639C11.0322 8.19088 11.4645 7.75856 12 7.75856C12.5355 7.75856 12.9678 8.19088 12.9678 8.72639ZM11.0322 18.4047V17.7014C11.0322 17.1658 11.4645 16.7336 12 16.7336C12.5355 16.7336 12.9678 17.1658 12.9678 17.7014V18.4047C12.9678 18.9337 12.5355 19.3725 12 19.3725C11.4645 19.3725 11.0322 18.9337 11.0322 18.4047Z'
                fill='#FFB72D'
            />
        </svg>
    ),
    error: (onlyIcon: boolean) => (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={onlyIcon ? 32 : 24}
            height={onlyIcon ? 32 : 25}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                d='M12 2.30005C10.0222 2.30005 8.08879 2.88654 6.4443 3.98535C4.79981 5.08417 3.51809 6.64595 2.76121 8.47321C2.00433 10.3005 1.8063 12.3111 2.19215 14.2509C2.578 16.1908 3.53041 17.9726 4.92894 19.3711C6.32746 20.7696 8.10929 21.722 10.0491 22.1079C11.9889 22.4937 13.9996 22.2957 15.8268 21.5388C17.6541 20.782 19.2159 19.5002 20.3147 17.8557C21.4135 16.2113 22 14.2779 22 12.3C21.9971 9.64876 20.9426 7.10689 19.0679 5.23215C17.1932 3.35741 14.6513 2.30292 12 2.30005ZM15.535 14.6575C15.6912 14.8138 15.779 15.0257 15.779 15.2467C15.779 15.4677 15.6912 15.6796 15.535 15.8359C15.3761 15.987 15.1651 16.0713 14.9458 16.0713C14.7265 16.0713 14.5156 15.987 14.3567 15.8359L12 13.4784L9.64334 15.8359C9.486 15.9901 9.27448 16.0765 9.05417 16.0765C8.83386 16.0765 8.62233 15.9901 8.465 15.8359C8.30878 15.6796 8.22101 15.4677 8.22101 15.2467C8.22101 15.0257 8.30878 14.8138 8.465 14.6575L10.8217 12.3L8.465 9.94255C8.38541 9.86567 8.32193 9.77372 8.27825 9.67205C8.23458 9.57038 8.21159 9.46103 8.21063 9.35038C8.20967 9.23973 8.23075 9.13 8.27265 9.02759C8.31455 8.92517 8.37643 8.83213 8.45467 8.75389C8.53292 8.67564 8.62596 8.61376 8.72838 8.57186C8.83079 8.52996 8.94052 8.50888 9.05117 8.50984C9.16182 8.5108 9.27117 8.53379 9.37284 8.57746C9.47451 8.62114 9.56646 8.68462 9.64334 8.76421L12 11.1217L14.3567 8.76421C14.4335 8.68462 14.5255 8.62114 14.6272 8.57746C14.7288 8.53379 14.8382 8.5108 14.9488 8.50984C15.0595 8.50888 15.1692 8.52996 15.2716 8.57186C15.374 8.61376 15.4671 8.67564 15.5453 8.75389C15.6236 8.83213 15.6855 8.92517 15.7274 9.02759C15.7693 9.13 15.7903 9.23973 15.7894 9.35038C15.7884 9.46103 15.7654 9.57038 15.7218 9.67205C15.6781 9.77372 15.6146 9.86567 15.535 9.94255L13.1783 12.3L15.535 14.6575Z'
                fill='#F24E4E'
            />
        </svg>
    ),
    info: (onlyIcon: boolean) => (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={onlyIcon ? 32 : 24}
            height={onlyIcon ? 32 : 25}
            viewBox='0 0 24 25'
            fill='none'
        >
            <path
                d='M12 2.30005C10.0222 2.30005 8.08879 2.88654 6.4443 3.98535C4.79981 5.08417 3.51809 6.64595 2.76121 8.47321C2.00433 10.3005 1.8063 12.3111 2.19215 14.251C2.578 16.1908 3.53041 17.9726 4.92894 19.3711C6.32746 20.7696 8.10929 21.722 10.0491 22.1079C11.9889 22.4938 13.9996 22.2957 15.8268 21.5388C17.6541 20.782 19.2159 19.5002 20.3147 17.8558C21.4135 16.2113 22 14.2779 22 12.3C22 10.9868 21.7413 9.68647 21.2388 8.47321C20.7363 7.25996 19.9997 6.15757 19.0711 5.22898C18.1425 4.3004 17.0401 3.5638 15.8268 3.06125C14.6136 2.55871 13.3132 2.30005 12 2.30005ZM13 16.3C13 16.5653 12.8946 16.8196 12.7071 17.0072C12.5196 17.1947 12.2652 17.3 12 17.3C11.7348 17.3 11.4804 17.1947 11.2929 17.0072C11.1054 16.8196 11 16.5653 11 16.3V11.3C11 11.0348 11.1054 10.7805 11.2929 10.5929C11.4804 10.4054 11.7348 10.3 12 10.3C12.2652 10.3 12.5196 10.4054 12.7071 10.5929C12.8946 10.7805 13 11.0348 13 11.3V16.3ZM12 9.30005C11.8022 9.30005 11.6089 9.2414 11.4444 9.13152C11.28 9.02164 11.1518 8.86546 11.0761 8.68273C11.0004 8.50001 10.9806 8.29894 11.0192 8.10496C11.0578 7.91098 11.153 7.73279 11.2929 7.59294C11.4328 7.45309 11.6109 7.35785 11.8049 7.31926C11.9989 7.28068 12.2 7.30048 12.3827 7.37617C12.5654 7.45186 12.7216 7.58003 12.8315 7.74448C12.9414 7.90893 13 8.10227 13 8.30005C13 8.56527 12.8946 8.81962 12.7071 9.00716C12.5196 9.19469 12.2652 9.30005 12 9.30005Z'
                fill='#3594FF'
            />
        </svg>
    ),
};

const StatusIcon = ({ color, variant }: StatusIconProps) => {
    const isOnlyIcon = variant === 'icon';
    const icon = colorToIcon[color](isOnlyIcon);
    const Container = isOnlyIcon ? Stack : StatusIconContainer;
    return (
        <Container statusColor={color} padding={1} justifyContent='center' alignItems='center'>
            {icon}
        </Container>
    );
};
StatusIcon.displayName = 'StatusIcon';

export default StatusIcon;
