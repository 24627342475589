import styled from 'styled-components';
import { Stack, Typography } from '../../design-system/components-v2';

const TopDiv = styled(Stack)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const TitleDiv = styled(Typography)`
    display: flex;
    align-items: center;
    font-weight: 500;
`;

const ContentDiv = styled(Stack)`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
    border-radius: ${({ theme }) => `${theme.border.radius(4)}${theme.units}`};
    padding: 24px;
    gap: 10px;
`;

const HeaderDiv = styled(Stack)<{ withSpinner?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    align-self: stretch;
    overflow: hidden;
`;

const SpinnerDiv = styled(Stack)`
    width: 100px;
    flex-direction: row;
    justify-content: center;
`;

const ErrorDiv = styled(Stack)`
    color: ${({ theme }) => theme.palette.status.error};
    flex-direction: row;
`;

const MessageDiv = styled(Stack)`
    flex-direction: row;
`;

const HeaderLeftDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    overflow: hidden;
`;

const HeaderRightDiv = styled(Stack)`
    justify-content: flex-end;
    max-width: 30%;
`;

const HeaderLeftIconDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.surface.secondary};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    min-width: 48px;
    min-height: 48px;
`;

const HeaderLeftTextStack = styled(Stack)`
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

const PropsDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    align-content: flex-start;
    align-self: stretch;
`;

const FieldsDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    align-self: stretch;
    gap: 30px;
`;

const FieldDiv = styled(Stack)`
    display: flex;
    flex-direction: row;

    .prop-container {
        gap: 4px;
        display: flex;
        flex-direction: column;

        .prop-title {
            color: ${({ theme }) => theme.palette.onSurface.normal};
            font-size: ${({ theme }) => theme.typography.body.fontSize};
            font-weight: ${({ theme }) => theme.typography.subtitleLg.fontWeight};
            line-height: 16px;
        }

        .field-component {
            color: ${({ theme }) => theme.palette.onSurface.normal};
            max-width: 200px;
            font-size: 12px;
            line-height: 16px;
        }
    }
`;

export const AssetStyled = {
    TopDiv,
    TitleDiv,
    ContentDiv,
    HeaderDiv,
    SpinnerDiv,
    ErrorDiv,
    MessageDiv,
    HeaderLeftDiv,
    HeaderLeftIconDiv,
    HeaderRightDiv,
    PropsDiv,
    FieldsDiv,
    FieldDiv,
    HeaderLeftTextStack,
};
