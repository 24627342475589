import { IDateFilterOption, ITimeFromTo } from '../DefaultFilters.interface';
import i18next from 'i18next';

export const TIME_RANGES_TYPES = {
    HOURS: 'hours',
    DAYS: 'days',
};

export enum TimePresets {
    ONE_HOUR = '1 Hr',
    FOUR_HOURS = '4 Hrs',
    TWELVE_HOURS = '12 Hrs',
    ONE_DAY = '24 Hrs',
    WEEK = '7 Days',
    THIRTY_DAYS = '30 Days',
    ONE_YEAR = '1 Year',
    ALL = 'All',
    CUSTOM = 'Custom',
}

export const DATE_FILTER_TABS = {
    PRESETS: 'presets',
    CUSTOM: 'custom',
};
export const computeRangeFromSelection = (selectedDate: any): ITimeFromTo => {
    if (selectedDate.epoch) {
        return {
            from: selectedDate.epoch.from,
            to: selectedDate.epoch.to,
        };
    } else {
        const from = new Date();
        const { type, count } = selectedDate;
        switch (type) {
            case TIME_RANGES_TYPES.HOURS:
                from.setHours(from.getHours() - count);
                break;
            case TIME_RANGES_TYPES.DAYS:
                from.setDate(from.getDate() - count);
                break;
        }
        return { from: from.toISOString(), to: new Date().toISOString() };
    }
};

export const getDateDisplayName = (key: TimePresets, displayName: string, timeRange: ITimeFromTo): string => {
    if (key === TimePresets.CUSTOM && timeRange) {
        return `${new Date(timeRange.from).toLocaleDateString()}-${new Date(timeRange.to).toLocaleDateString()}`;
    } else return displayName;
};

export const convertOldDateObjToNew = (selectedFilter: any): IDateFilterOption => {
    const newDateObjs = {} as IDateFilterOption;
    Object.values(DEFAULT_RANGES_VALUES).forEach((value) => {
        if (selectedFilter.name === value.key) {
            const { key, displayName, type, count } = value;
            newDateObjs.key = key;
            newDateObjs.displayName = getDateDisplayName(key, displayName, selectedFilter?.epoch);
            newDateObjs.count = count;
            newDateObjs.type = type;
            newDateObjs.isNewDateFilter = true;
            newDateObjs.epoch = computeRangeFromSelection(key === TimePresets.CUSTOM ? selectedFilter : value);
        }
    });
    return newDateObjs;
};

export const DEFAULT_RANGES_VALUES: { [key: string]: IDateFilterOption } = {
    ONE_HOUR: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.1_HOUR'),
        type: TIME_RANGES_TYPES.HOURS,
        count: 1,
        key: TimePresets.ONE_HOUR,
        isNewDateFilter: true,
    },
    FOUR_HOURS: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.4_HOURS'),
        type: TIME_RANGES_TYPES.HOURS,
        count: 4,
        key: TimePresets.FOUR_HOURS,
        isNewDateFilter: true,
    },
    TWELVE_HOURS: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.12_HOURS'),
        type: TIME_RANGES_TYPES.HOURS,
        count: 12,
        key: TimePresets.TWELVE_HOURS,
        isNewDateFilter: true,
    },
    ONE_DAY: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.24_HOURS'),
        type: TIME_RANGES_TYPES.HOURS,
        count: 24,
        key: TimePresets.ONE_DAY,
        isNewDateFilter: true,
    },
    WEEK: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.7_DAYS'),
        type: TIME_RANGES_TYPES.DAYS,
        count: 7,
        key: TimePresets.WEEK,
        isNewDateFilter: true,
    },
    THIRTY_DAYS: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.30_DAYS'),
        type: TIME_RANGES_TYPES.DAYS,
        count: 30,
        key: TimePresets.THIRTY_DAYS,
        isNewDateFilter: true,
    },
    ONE_YEAR: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.ONE_YEAR'),
        type: TIME_RANGES_TYPES.DAYS,
        count: 365,
        key: TimePresets.ONE_YEAR,
        isNewDateFilter: true,
    },
    ALL: {
        displayName: i18next.t('FILTER_PANEL.DATE_FILTER.PRESETS.ALL'),
        type: TIME_RANGES_TYPES.DAYS,
        count: Math.floor((new Date().getTime() - new Date(0).getTime()) / (1000 * 60 * 60 * 24)),
        key: TimePresets.ALL,
        isNewDateFilter: true,
    },
    CUSTOM: {
        displayName: 'Custom',
        type: TIME_RANGES_TYPES.DAYS,
        count: 0,
        key: TimePresets.CUSTOM,
        isNewDateFilter: true,
    },
};

export const formatWebappTimeToNewDate = (webAppTimeParam: string): IDateFilterOption | undefined => {
    interface IWebappTimeParams {
        time:
            | string
            | {
                  Custom: {
                      from: string;
                      to: string;
                  };
              };
    }
    try {
        const time: IWebappTimeParams = JSON.parse(webAppTimeParam);
        if (typeof time.time === 'string') {
            const relevantTime = Object.values(DEFAULT_RANGES_VALUES).find((value) => value.key === time.time);
            return relevantTime;
        } else {
            // Custom
            const { from, to } = time.time.Custom;
            const fixedFrom = new Date(from).toISOString();
            const fixedTo = new Date(to).toISOString();
            return {
                ...DEFAULT_RANGES_VALUES.CUSTOM,
                displayName: getDateDisplayName(TimePresets.CUSTOM, 'Custom', { from: fixedFrom, to: fixedTo }),
                epoch: { from: fixedFrom, to: fixedTo },
            };
        }
    } catch {
        return undefined;
    }
};
