import i18n from 'i18next';
import _ from 'lodash';
import { isArray } from '../../utils/helpFunctions';

const LOWER_TRUE = 'true';

export const isBoolean = (value?: boolean | string | number): boolean => {
    if (_.isString(value)) {
        return value.toLowerCase() === LOWER_TRUE;
    }
    return Boolean(value);
};

export const getStringFromBoolean = (value?: boolean): string | undefined => {
    if (value !== undefined) {
        return String(value);
    }
    return undefined;
};

export const transBoolean = (value?: boolean | string | number): string => {
    return isBoolean(value) ? i18n.t('GENERAL.YES') : i18n.t('GENERAL.NO');
};

export const getStringArrayFromString = (str?: string): string[] | undefined => {
    if (!str) {
        return undefined;
    }
    try {
        const parsedValue: any = JSON.parse(str);
        if (isArray(parsedValue)) {
            return parsedValue as string[];
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

export const getSafeStringArrayFromString = (str?: string): string[] => {
    return getStringArrayFromString(str) ?? [];
};

export const getStringFromStringArray = (stringList?: string[]): string | undefined => {
    if (!stringList) {
        return undefined;
    }
    return JSON.stringify(stringList);
};

export const getSafeStringFromStringArray = (stringList?: string[]): string | undefined => {
    return getStringFromStringArray(stringList) ?? '';
};

export const isValidJSON = (str: string): boolean => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};

export const getNumberFromString = (numberAsString?: string): number | undefined => {
    if (numberAsString !== undefined) {
        return isFinite(Number(numberAsString)) ? Number(numberAsString) : undefined;
    }
    return undefined;
};

export const getStringFromNumber = (value?: number): string | undefined => {
    if (value !== undefined) {
        return String(value);
    }
    return undefined;
};