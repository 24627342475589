import React from 'react';
import { ICustomTreeRendererProps } from '../CustomTree.interface';
import { DEFAULT_INDENT_WIDTH, MINI_INDENT_WIDTH_STRING } from '../CustomTree.consts';
import { Stack } from '../../../../design-system/components-v2';
import { getCustomTreeRowsGap } from '../CustomTree.utils';

export const CustomTreeIndentBox: React.FC<{ renderProps: ICustomTreeRendererProps, indentLevel: number }> = ({
    renderProps,
    indentLevel,
}) => {
    const { depth, compProps } = renderProps;
    const { settings, lastChildFlagsArray } = compProps;
    const widthStr = `${settings?.indentWidth || DEFAULT_INDENT_WIDTH}px`;
    const lastChildFlag: boolean = lastChildFlagsArray[indentLevel];

    if (indentLevel === (depth - 1)) {
        const midHeightStr = `calc(50% + ${getCustomTreeRowsGap(settings) / 2}px)`;
        if (lastChildFlag) {
            return (
                <Stack direction={'row'} alignItems={'flex-start'} style={{
                    marginLeft: MINI_INDENT_WIDTH_STRING,
                    maxWidth: widthStr,
                    minWidth: widthStr,
                    height: '100%',
                }}>
                    <div style={{
                        maxWidth: '1px',
                        minWidth: '1px',
                        borderLeft: '1px solid #ddd',
                        height: midHeightStr,
                    }}></div>
                    <div style={{
                        width: '100%',
                        height: midHeightStr,
                        borderBottom: '1px solid #ddd',
                    }}></div>
                </Stack>
            );
        } else {
            return (
                <Stack direction={'row'} alignItems={'flex-start'} style={{
                    marginLeft: MINI_INDENT_WIDTH_STRING,
                    maxWidth: widthStr,
                    minWidth: widthStr,
                    height: '100%',
                }}>
                    <div style={{
                        maxWidth: '1px',
                        minWidth: '1px',
                        borderLeft: '1px solid #ddd',
                        height: '100%',
                    }}></div>
                    <div style={{
                        width: '100%',
                        height: midHeightStr,
                        borderBottom: '1px solid #ddd',
                    }}></div>
                </Stack>
            );
        }
    } else {
        if (lastChildFlag) {
            return <div style={{
                marginLeft: MINI_INDENT_WIDTH_STRING,
                maxWidth: widthStr,
                minWidth: widthStr,
                height: '100%',
            }}></div>;
        } else {
            return <div style={{
                marginLeft: MINI_INDENT_WIDTH_STRING,
                maxWidth: widthStr,
                minWidth: widthStr,
                height: '100%',
                borderLeft: '1px solid #ddd',
            }}></div>;
        }
    }
};