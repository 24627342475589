import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { hash, runAll } from 'common/utils/helpFunctions';
import { Vendors } from 'common/consts/vendors';
import { Location } from 'common/utils/history';
import { getEntityService, getHttpService } from 'common/interface/services';
import { getTypeByPlatform, IAsset } from 'common/assets/common.assets';
import { ICloudEntity, ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { IAssetInfo } from './AssetTabs';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnit,
} from '../../interface/data_services';
import { AxiosError } from 'axios';
import { IProtectedAssetViewModel, ProtectedAssetsResponse, SearchRequest } from './ProtectedAssetsTable.interface';
import { SelectOption } from '../../design-system/components-v2/SelectV2/Select.types';
import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from './ProtectedAssetsTable.consts';
import { ASSETS_SEARCH_URL } from '../../module_interface/assets/AssetsConsts';

export const TAGS_CACHE_PROTECTED_ASSETS = 'protected-assets-get-single-asset';
export async function getAllAvailableCloudEntityData(
    id: string,
    type: string,
    platform: string,
    cloudAccountId: string,
    customErrHandler?: (error: AxiosError<ICloudEntity>) => ICloudEntity,
    collectAllAvailableInfo?: boolean,
): Promise<Partial<ICloudEntityData> | undefined> {
    if (id === null || type === null || platform === null || cloudAccountId === null) {
        return undefined;
    }

    const cacheTimeout = 3 * 60; //3 minutes
    const cachingConfig = { useCache: true, cacheTimeout, tags: [TAGS_CACHE_PROTECTED_ASSETS] };

    const calculatedTypeByPlatform = getTypeByPlatform(platform, type);
    const asset: IAsset | undefined = getProtectedAssetsService().getAssetByType(calculatedTypeByPlatform) || undefined;
    if (!asset) {
        return undefined;
    }

    const data: Partial<ICloudEntityData> = {
        asset,
        cloudAccountId,
        typeByPlatform: calculatedTypeByPlatform,
    };

    async function getCloudEntity() {
        return await getEntityService().getEntity(id, type, platform, cloudAccountId, cachingConfig, customErrHandler);
    }

    let cloudEntityPromise: Promise<ICloudEntity | null> = Promise.resolve(null);
    if (!asset?.getSpecificEntity) {
        cloudEntityPromise = getCloudEntity();
    }
    const protectedAssetPromise: Promise<IProtectedAssetViewModel | null> =
        getProtectedAssetsService().getProtectedAssetById(id, asset.typeByPlatform, cloudAccountId, cachingConfig);
    const cloudAccountPromise: Promise<ICloudAccount | null> =
        getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId);
    const orgUnitsPromise: Promise<IOrganizationalUnit[]> =
        getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath(true);

    try {
        const promisesMap: any = {
            cloudEntity: cloudEntityPromise,
            protectedAsset: protectedAssetPromise,
            cloudAccount: cloudAccountPromise,
            orgUnits: orgUnitsPromise,
        };

        const resultsMap: any = await runAll(promisesMap, false);
        const cloudEntity: ICloudEntity | undefined = resultsMap.cloudEntity || undefined;
        const protectedAsset: IProtectedAssetViewModel | undefined = resultsMap.protectedAsset || undefined;
        const cloudAccount: ICloudAccount | undefined = resultsMap.cloudAccount || undefined;
        const allOrgUnits: IOrganizationalUnit[] = resultsMap.orgUnits || [];
        if (protectedAsset === null || (cloudAccount === null && !collectAllAvailableInfo)) {
            return data;
        }
        data.protectedAsset = protectedAsset;
        data.cloudAccount = cloudAccount;
        data.cloudEntity = cloudEntity;
        data.entityId = cloudEntity?.id ?? protectedAsset?.entityId;

        if (!data.cloudEntity && asset?.getSpecificEntity && data.protectedAsset) {
            data.cloudEntity = (await asset.getSpecificEntity(data.protectedAsset)) || undefined;
            if (!data.cloudEntity) {
                data.cloudEntity = (await getCloudEntity()) || undefined;
            }
        }

        if (!data.cloudEntity && !collectAllAvailableInfo) {
            return data;
        }

        if (data.cloudAccount) {
            const account: ICloudAccount = data.cloudAccount;
            data.organizationalUnit = allOrgUnits.find(
                (ou: IOrganizationalUnit) => ou.id === account.organizationalUnitId,
            );
            data.organizationalUnitFullPath = await getOrgUnitFullPath(data.cloudAccount.organizationalUnitId);
        }
        return data;
    } catch (error) {
        console.error(error);
        return data;
    }
}

export async function getCloudEntityData(
    id: string,
    type: string,
    platform: string,
    cloudAccountId: string,
    customErrHandler?: (error: AxiosError<ICloudEntity>) => ICloudEntity,
): Promise<ICloudEntityData | null> {
    const data: Partial<ICloudEntityData> | undefined = await getAllAvailableCloudEntityData(
        id,
        type,
        platform,
        cloudAccountId,
        customErrHandler,
    );
    if (!data) {
        return null;
    }

    const {
        protectedAsset,
        cloudAccount,
        cloudEntity,
        typeByPlatform,
        organizationalUnit,
        organizationalUnitFullPath,
        entityId,
        asset,
    } = data;
    if (!protectedAsset || !cloudAccount || !cloudEntity || !typeByPlatform || !cloudAccountId || !entityId || !asset) {
        return null;
    }

    return {
        cloudEntity,
        typeByPlatform,
        cloudAccountId,
        protectedAsset,
        entityId,
        cloudAccount,
        organizationalUnit,
        organizationalUnitFullPath,
        asset,
    };
}

export async function getEntityFromUrlParams(assetInfo: IAssetInfo | null): Promise<ICloudEntityData | null> {
    if (assetInfo === null) {
        return null;
    }
    const { id, type, platform, cloudAccountId } = assetInfo;
    if (id === null || type === null || platform === null || cloudAccountId === null) {
        return null;
    }
    return getCloudEntityData(id, type, platform, cloudAccountId);
}

function getHash(id: string, cloudAccountId: string) {
    return hash(`${id}|${cloudAccountId}`);
}

export function getAssetInfoFromLocation(location: Location<unknown>): IAssetInfo {
    const path = location.pathname + location.search;
    const queryParams = new URLSearchParams(location.search);
    let instanceId = queryParams.get('instanceid') || '';
    const type = queryParams.get('type');

    switch (type) {
        case '18':
        case '19':
        case '20': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const entityDataChips = instanceId.split('|');
            const cloudAccountId = entityDataChips[1];
            const assetName = entityDataChips[2];
            return {
                id: entityDataChips[3],
                type: asset?.type || '',
                cloudAccountId: cloudAccountId,
                platform: asset?.platform || Vendors.KUBERNETES,
                name: asset?.displayName || assetName,
                url: path,
                hash: getHash(instanceId, cloudAccountId),
            };
        }
        case '21': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const cloudAccountId = queryParams.get('cloudaccountid') || queryParams.get('cloudAccountId') || '';
            const assetId = queryParams.get('assetid') || '';
            const assetType = asset?.type || '';
            const platform = asset?.platform || '';
            return {
                id: assetId,
                type: assetType,
                platform: platform,
                cloudAccountId: cloudAccountId,
                url: path,
                hash: getHash(assetId, cloudAccountId),
                name: '',
            };
        }
        case '1': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const assetType = asset?.type || '';
            const cloudAccountId = queryParams.get('cloudaccountid') || '';
            const assetId = queryParams.get('instanceid') || '';
            const platform = asset?.platform || '';
            return {
                id: assetId,
                type: assetType,
                platform: platform,
                cloudAccountId: cloudAccountId,
                url: path,
                hash: getHash(assetId, cloudAccountId),
                name: '',
            };
        }
        case '12': {
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            const cloudAccountId = queryParams.get('cloudaccountid') || '';
            const assetId = queryParams.get('externalid') || '';
            const assetType = asset?.type || '';
            const platform = asset?.platform || '';
            return {
                id: assetId,
                type: assetType || '',
                platform: platform,
                cloudAccountId: cloudAccountId,
                url: path,
                hash: getHash(assetId, cloudAccountId),
                name: '',
            };
        }
        case '2':
        case '3':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '10':
        case '13':
        case '14':
        case '17': {
            if (type === '8') {
                // A specific fix for AWS ALB (type is '8').
                // In that case, when the request is coming from angular,
                // the HTML encoded / (%2F) turns to ~2F and tilda (~) turns into two tildas.
                instanceId = instanceId.replaceAll('~2F', '/').replaceAll('~~', '~');
            }
            const entityDataChips = instanceId.split('|');
            const cloudAccountId = entityDataChips[1];
            const asset = getProtectedAssetsService().getAssetByTypeNumber(type);
            if (asset === null) {
                throw new Error(`Could not get asset type for number ${type}`);
            }
            const assetName = entityDataChips[5];
            return {
                id: instanceId,
                type: asset.type,
                cloudAccountId: cloudAccountId,
                platform: asset.platform,
                name: assetName,
                url: path,
                hash: getHash(instanceId, cloudAccountId),
            };
        }
        default: {
            if (instanceId) {
                const entityDataChips = instanceId.split('|');
                const cloudAccountId = entityDataChips[1];
                const asset = getProtectedAssetsService().getAssetByType(type ?? entityDataChips[4] ?? '');
                if (asset === null) {
                    throw new Error(`Could not get asset type from instanceId ${instanceId}`);
                }
                const assetName = entityDataChips[5];
                return {
                    id: instanceId,
                    type: asset.type,
                    cloudAccountId: cloudAccountId,
                    platform: asset.platform,
                    name: assetName,
                    url: path,
                    hash: getHash(instanceId, cloudAccountId),
                };
            } else {
                const assetType = queryParams.get('assetType') || '';
                let assetId = queryParams.get('assetId') || '';
                // this is a fix when we jump to aks asset page (angular to react).
                // should remove when we move the environment (workload) table to react
                // For example, AngularJS history update replaces assetId param from:
                // assetId=<sha256:id>~arn:aws:ecs:us-west-2:371954679992:cluster%2Fmicroservice-qa
                // to:
                //assetId=<sha256:id>~~arn:aws:ecs:us-west-2:371954679992:cluster~2Fmicroservice-qa
                // the HTML encoded / (%2F) turns to ~2F and tilda (~) turns into two tildas.
                assetId = assetId.replaceAll('~2F', '/').replaceAll('~~', '~');
                const cloudAccountId = queryParams.get('cloudAccountId') || queryParams.get('cloudaccountid') || '';
                const platform = queryParams.get('platform') || '';
                if (platform === Vendors.ALIBABA) {
                    return {
                        id: assetId,
                        type: assetType,
                        cloudAccountId: cloudAccountId,
                        platform: Vendors.ALIBABA,
                        name: assetId,
                        url: path,
                        hash: getHash(assetId, cloudAccountId),
                    };
                } else {
                    return {
                        id: assetId,
                        type: assetType || '',
                        platform: platform,
                        cloudAccountId: cloudAccountId,
                        url: path,
                        hash: getHash(assetId, cloudAccountId),
                        name: '',
                    };
                }
            }
        }
    }
}

export function getEnvironmentDisplayNameFromCloudAccount(cloudAccount: ICloudAccount, cloudAccountId: string) {
    if (cloudAccount.name) {
        return `${cloudAccount.name} (${cloudAccount.externalId})`;
    }
    return cloudAccount.externalId || cloudAccountId;
}

export async function getEnvironmentDisplayNameById(cloudAccountId: string): Promise<string | null> {
    if (cloudAccountId) {
        const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId);
        if (cloudAccount === null) {
            return null;
        }
        return getEnvironmentDisplayNameFromCloudAccount(cloudAccount, cloudAccountId);
    }
    return null;
}

export const getOrgUnitFullPath = async (orgUnitId: string | undefined): Promise<string> => {
    if (orgUnitId) {
        const ouPath = await getOrganizationalUnitService().getOrganizationalUnitPathById(orgUnitId);
        if (ouPath) {
            return ouPath.map((ou) => ou.item.name).join('/');
        }
    }
    const rootOrgUnit = await getOrganizationalUnitService().getOrganizationalUnitsView();
    return rootOrgUnit?.name || '';
};

export async function getMatchingAssetsOptions(
    freeText: string | undefined,
    includedEntityTypes: string[],
): Promise<SelectOption[]> {
    const requestObject: SearchRequest = {
        externalAdditionalFields: { source: EXTERNAL_ADDITIONAL_FIELDS_SOURCE.THIRD_PARTY },
        filter: {
            freeTextPhrase: freeText,
            includedEntityTypes,
        },
        pageSize: 100,
        skipAggregations: true,
    };

    const searchResult: ProtectedAssetsResponse = await getHttpService().request<ProtectedAssetsResponse>(
        ASSETS_SEARCH_URL,
        { data: requestObject, method: 'POST' },
        { cachingConfig: { useCache: true } },
    );

    return searchResult.assets.map((asset) => ({
        label: asset.name,
        value: asset.name,
    }));
}
