import React from 'react';
import backgroundImage from './Images/pageBackdropGraphic.svg';
import checkpointLogo from './Images/check-point-logo-new.svg';
import { GeneralErrorsPageStyled } from './GeneralErrorsPage.styled';
import { Stack, Typography } from '../../../design-system/components-v2';
import i18n from 'i18next';

export interface GeneralErrorPageProps {
    titleKey?: string;
    firstMessageKey?: string;
    secondMessageKey?: string;
}

export const GeneralErrorPage: React.FC<GeneralErrorPageProps> = ({
    titleKey = '',
    firstMessageKey = '',
    secondMessageKey = '',
}) => {
    return (
        <GeneralErrorsPageStyled>
            <Stack alignItems={'center'} spacing={4}>
                <Stack padding={[12, 0, 10, 0]} alignItems={'center'} justifyContent={'center'}>
                    <img src={backgroundImage} alt={''} />
                </Stack>
                <Typography variant={'xl'}>{i18n.t(titleKey)}</Typography>
                <Typography variant={'lg'}>{i18n.t(firstMessageKey)}</Typography>
                <Typography variant={'lg'}>{i18n.t(secondMessageKey)}</Typography>
                <Stack padding={[10, 0, 0, 0]}>
                    <img src={checkpointLogo} alt={''} width='205' height='100' />
                </Stack>
            </Stack>
        </GeneralErrorsPageStyled>
    );
};
