import React, { useEffect, useState, useCallback } from 'react';
import { Button, Message } from 'common/design-system/components-v2';
import { EMAIL_REGEX } from 'common/utils/http';
import { useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import { ErrorType, ExportMethod, ExportToCsvProps, IncludeColumns, IncludeRows, RadioName } from './ExportToCsv.types';
import { DOWNLOAD_ROWS_LIMIT } from './ExportToCsv.consts';
import Form from './Components/Form';
import { useExportToCsvTranslations } from './hooks/useExportToCsvTranslations';

const ExportToCsv: React.FC<ExportToCsvProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [exportMethod, setExportMethod] = useState<ExportMethod>(ExportMethod.DirectDownload);
    const [includeColumns, setIncludeColumns] = useState<IncludeColumns>(IncludeColumns.Visible);
    const [includeRows, setIncludeRows] = useState<IncludeRows>(IncludeRows.Filtered);
    const [error, setError] = useState<ErrorType | null>(null);
    const [email, setEmail] = useState('');

    const { t } = useTranslation(getCompsI18nNS('table'), { keyPrefix: 'TOOLBAR.EXPORT_TO_CSV' });

    const {
        onExport,
        rowsCount = 0,
        filteredRowsCount = 0,
        allColumnsCount = 0,
        visibleColumnsCount = 0,
        closeModalAfterExport = true,
        modalTitle = t('MODAL_TITLE'),
        exportBtnText = t('EXPORT_BUTTON'),
    } = props;

    const translations = useExportToCsvTranslations(rowsCount, filteredRowsCount, allColumnsCount, visibleColumnsCount);

    const selectedRowsCount = includeRows === IncludeRows.Filtered ? filteredRowsCount : rowsCount;
    const isOverLimit = selectedRowsCount > DOWNLOAD_ROWS_LIMIT && exportMethod === ExportMethod.DirectDownload;

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const resetState = () => {
            setExportMethod(ExportMethod.DirectDownload);
            setIncludeColumns(IncludeColumns.Visible);
            setIncludeRows(IncludeRows.Filtered);
            setEmail('');
            setError(null);
        };

        if (!isModalOpen) {
            setTimeout(() => {
                resetState();
            }, 0);
        }
    }, [isModalOpen]);

    const handleOnExport = () => {
        if (isOverLimit) {
            setError(ErrorType.OverLimit);
            return;
        }
        if (exportMethod === ExportMethod.ByEmail && (!email || !EMAIL_REGEX.test(email))) {
            setError(ErrorType.Email);
            return;
        }
        onExport({ includeColumns, includeRows, exportMethod, email });
        if (closeModalAfterExport) {
            handleCloseModal();
        }
    };

    const handleRadioChange = useCallback(
        <T extends IncludeColumns | IncludeRows | ExportMethod>(name: RadioName, value: T) => {
            switch (name) {
                case RadioName.Columns:
                    setIncludeColumns(value as IncludeColumns);
                    break;
                case RadioName.Rows:
                    setIncludeRows(value as IncludeRows);
                    setError(null);
                    break;
                case RadioName.Method:
                    setExportMethod(value as ExportMethod);
                    setError(null);
                    break;
            }
        },
        [],
    );

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setError(null);
    };

    const handleOpenModalClick = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    return (
        <>
            <Button onClick={handleOpenModalClick} iconProps={{ name: 'export' }} variant='text'>
                {exportBtnText}
            </Button>
            <Message
                title={modalTitle}
                onClose={handleCloseModal}
                onConfirm={handleOnExport}
                onCancel={handleCloseModal}
                cancelBtnText={translations.cancelCta}
                submitBtnText={translations.exportCta}
                dataAid='export-to-csv-modal'
                isOpen={isModalOpen}
                width='lg'
            >
                <Form
                    error={error}
                    rowsCount={rowsCount}
                    filteredRowsCount={filteredRowsCount}
                    allColumnsCount={allColumnsCount}
                    visibleColumnsCount={visibleColumnsCount}
                    includeColumns={includeColumns}
                    includeRows={includeRows}
                    exportMethod={exportMethod}
                    email={email}
                    isOverLimit={isOverLimit}
                    onRadioChange={handleRadioChange}
                    onEmailChange={handleEmailChange}
                />
            </Message>
        </>
    );
};

export default ExportToCsv;
