import { FC, ReactNode, useEffect, useState } from 'react';
import { ExclusionsLayout, FilterPanelWrapper, LoaderWrapper } from './exclusions.style';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Spinner, Table } from 'common/design-system/components-v2';
import { useInit } from './hooks/useInit';
import { getGridOptions } from './table/columnDef';
import { IExclusionProps, IGlobalStringObject } from './exclusions.type';
import { useFilter } from './hooks/useFilter';
import { getAggregations, getFilterPanelElementsList } from './table/filter.options';
import EmptyState from 'common/components/EmptyState';

export const Exclusions: FC<IExclusionProps> = ({ init, initFilters, actionButtons }) => {
    const [filterElement, setFilterElement] = useState<ReactNode>(null);
    const [filterBy, setFilterBy] = useState<IGlobalStringObject | null>(null);
    const { isLoading, exclusions, isError, data } = useInit({ init, initFilters });
    const filteredData = useFilter({ data: exclusions, filterBy });

    useEffect(() => {
        if (!exclusions) return;
        const filterPanelElementsList = getFilterPanelElementsList(exclusions, data?.recentlyUsed);
        const filterPanel = initFilterPanel({
            filterPanelElementsList,
            getAggregations,
            onFilterChangeCallBack: (_: string, value: IGlobalStringObject) => {
                setFilterBy(value);
            },
            shouldBuildObjectForAPI: false,
            filterId: 'filter-panel-vulnerability',
        });
        setFilterElement(filterPanel);
    }, [exclusions, data]);

    if (isLoading) {
        return (
            <LoaderWrapper>
                <Spinner size={48} />
            </LoaderWrapper>
        );
    }
    if (!exclusions || isError) return <EmptyState label='No Exclusion Found' />;
    const gridOptions = getGridOptions(filteredData);
    return (
        <ExclusionsLayout>
            <FilterPanelWrapper>{filterElement}</FilterPanelWrapper>
            <Table
                actions={actionButtons.buttonsOptions}
                exportButtons={actionButtons.exportButtonsOptions}
                pageSize={50}
                currentRows={exclusions.length}
                totalRows={exclusions.length}
                saveColumnsState
                tableId='exclusions-table'
                gridOptions={gridOptions}
            />
        </ExclusionsLayout>
    );
};
