import React, { SyntheticEvent, useEffect } from 'react';
import { changeUrl } from 'common/utils/http';
import { getVendor, IVendor } from 'common/consts/vendors';
import { ICloudAccount } from 'common/interface/data_services';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { IChipProps } from '../../../design-system/components-v2/Chip/Chip.types';
import { MultiChipCellContent } from '../../../erm-components/custom/MultiChipCellContent/MultiChipCellContent';

interface IChipsEnvCellRendererProps {
    cloudAccountIds: string[];
    customUrl?: (platform: IVendor, cloudAccountId: string) => string;
    chipSize?: IChipProps['size'];
}

const ChipsEnvCellRenderer: React.FC<IChipsEnvCellRendererProps> = ({ cloudAccountIds, customUrl, chipSize }) => {
    const [cloudAccounts, setCloudAccount] = React.useState<ICloudAccount[]>([]);
    const { allCloudAccounts } = useAllCloudAccounts();

    useEffect(() => {
        const relatedCloudAccount: ICloudAccount[] = [];
        cloudAccountIds.forEach((cloudAccountId) => {
            const cloudAccount = allCloudAccounts.find((cloudAccount) => cloudAccountId === cloudAccount.id);
            if (cloudAccount) {
                relatedCloudAccount.push(cloudAccount);
            }
        });

        setCloudAccount(relatedCloudAccount);
    }, [allCloudAccounts, cloudAccountIds]);

    const getCloudAccountsAsChipsProps = (cloudAccounts: ICloudAccount[]): IChipProps[] => {
        return cloudAccounts.map((cloudAccount) => {
            const platform = getVendor(cloudAccount.platform);
            const iconName = platform?.newIcon;
            const url = platform ? `/cloud-account/${platform?.uri}/${cloudAccount.id}` : undefined;
            return {
                ...(iconName && { leadingIconProps: { name: iconName } }),
                label: cloudAccount.name,
                size: chipSize,
                disableInteraction: !!url,
                tooltip: cloudAccount.id,
                pointerCursor: !!url,
                onClick: url
                    ? (event: SyntheticEvent) => {
                          event.preventDefault();
                          changeUrl(customUrl && platform ? customUrl(platform, cloudAccount.id) : url);
                      }
                    : undefined,
            };
        });
    };

    if (cloudAccounts.length === 0) return null;

    return <MultiChipCellContent propsList={getCloudAccountsAsChipsProps(cloudAccounts)} />;
};

export default ChipsEnvCellRenderer;
