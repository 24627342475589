import { useState, useEffect } from 'react';
import { getAgentsService, IAgent } from 'common/interface/services';

interface NetworkAgentsData {
    agents: IAgent[];
    isLoading: boolean;
}

export const useAgents = (): NetworkAgentsData => {
    const [agents, setAgents] = useState<IAgent[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchAgents = async () => {
            try {
                const response = await getAgentsService().getAgents();
                setAgents(response);
            } catch (error) {
                console.error('Failed to fetch network agents', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAgents();
    }, []);

    return { agents, isLoading };
};
