import React, { useCallback, useState } from 'react';
import { Checkbox, Label, Stack } from '../../../../design-system/components-v2';

export let activeDebugState = false;

export const DevModalExample2: React.FC = () => {
    const [counter, setCounter] = useState<number>(0);
    const toggleDebugState = useCallback(() => {
        activeDebugState = !activeDebugState;
        setCounter(counter + 1);
    }, [counter]);

    return (
        <Stack spacing={2} direction={'column'} >
            <Label text={'Example 2: Turn debug state on/off'} size={'xl'} />
            <Checkbox label="Active Debug State" onChange={toggleDebugState} checked={activeDebugState}></Checkbox>
        </Stack>
    );
};
