import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ValueGetterFunc } from 'ag-grid-enterprise';

export function getAdditionalFieldValue(data: IProtectedAssetViewModel, fieldName: string) {
    const additionalFields = data?.additionalFields;
    if (!additionalFields) {
        return null;
    }
    const match = additionalFields.find((item) => item.name === fieldName);
    return match?.value ?? null;
}

export function getAdditionalFieldValueExport(fieldName: string) {
    return async (data: any) => {
        return Promise.resolve(getAdditionalFieldValue(data, fieldName));
    };
}

export function getAdditionalFieldValueGetter(fieldName: string): ValueGetterFunc {
    return (params) => {
        if (params.data.isGrouped) {
            return params.data[fieldName];
        }

        return getAdditionalFieldValue(params?.data, fieldName) ?? undefined;
    };
}
