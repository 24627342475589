import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PageStyled } from './ClientFilterPage.styled';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IClientFilterInfo } from 'common/erm-components/custom/ClientFilter/ClientFilter.interface';
import { getClientAggregations } from 'common/erm-components/custom/ClientFilter/ClientFilter';
import { ClientFilterTable } from './ClientFilterTable/ClientFilterTable';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { ClientFilterPanel } from './ClientFilterPanel/ClientFilterPanel';
import { ClientFilterEmptyState } from './ClientFilterEmptyState/ClientFilterEmptyState';
import { IEntityItem } from '../../../utils/filterUtils';
import {
    IClientFilterPageTableProps,
    IClientFilterPanelProps,
    IClientFilterTableProps,
    IClientFilterType,
} from './ClientFilterPage.interface';
import { GridApi } from 'ag-grid-community';
import FullPageLoader from '../FullSize/FullPageLoader/FullPageLoader';

export const ClientFilterPageTable: React.FC<IClientFilterPageTableProps<any>> = <T extends IEntityItem>(
    props: IClientFilterPageTableProps<T>,
) => {
    const {
        getTableFilterDefs,
        fetchAllItems,
        getFooterItemTitle,
        pageTableId,
        getTableColumnDefs,
        getTableActions,
        emptyStateComponent,
        getFieldOptionsMap,
        onTableDataChanged,
        onTableReady,
        tableSettings,
        onSelectionChanged,
        onRowDataUpdated,
    } = props;
    const [filterConditionsContainer, setFilterConditionsContainer] = useState<
        IFilterConditionsContainer | undefined
    >();
    const [dataChangesCounter, setDataChangesCounter] = useState<number>(0);
    const [allItems, setAllItems] = useState<T[]>();
    const filterTypes: IClientFilterType<T>[] = useMemo(
        () => (getTableFilterDefs ? getTableFilterDefs() : []),
        [getTableFilterDefs],
    );

    const clientInfos: IClientFilterInfo<T, IFilterConditionsContainer>[] = useMemo(() => {
        if (filterTypes.length === 0) {
            return [];
        }
        const infos: IClientFilterInfo<T, IFilterConditionsContainer>[] = [];
        filterTypes.forEach((filterType) => {
            if (filterType.clientInfo) {
                infos.push(filterType.clientInfo);
            }
        });
        return infos;
    }, [filterTypes]);
    const aggregationsResolveRef = useRef<(aggregations: Aggregations) => void>();
    const getAggregationFromItems = useCallback(
        async (items: T[]): Promise<Aggregations> => {
            return getClientAggregations<T>(clientInfos, items);
        },
        [clientInfos],
    );

    const loadAllItems = useCallback(() => {
        void fetchAllItems().then((items: T[]) => {
            setAllItems(items);
        });
    }, [fetchAllItems]);

    const onDataChange = useCallback(() => {
        setDataChangesCounter(dataChangesCounter + 1);
        loadAllItems();
        onTableDataChanged && onTableDataChanged();
    }, [dataChangesCounter, loadAllItems, onTableDataChanged]);

    const onTableFilterChange = useCallback(
        async (items: T[]) => {
            if (!aggregationsResolveRef.current) {
                return;
            }

            const resolve = aggregationsResolveRef.current;
            aggregationsResolveRef.current = undefined;
            try {
                const aggregations: Aggregations = await getAggregationFromItems(items);
                resolve(aggregations);
            } catch (e) {
                console.error('Failed loading items');
                resolve({});
            }
        },
        [getAggregationFromItems],
    );

    const onFilterChanged = useCallback(async (filtersValues: IFiltersValues): Promise<Aggregations> => {
        const filterConditionsContainer: IFilterConditionsContainer | undefined =
            filtersValues as IFilterConditionsContainer;
        setFilterConditionsContainer(filterConditionsContainer);
        return new Promise((resolve) => {
            aggregationsResolveRef.current = resolve;
        });
    }, []);

    const getInitialAggregations = useCallback(async (): Promise<Aggregations> => {
        try {
            return fetchAllItems().then((items: T[]) => {
                return getAggregationFromItems(items);
            });
        } catch (e) {
            console.error('Failed loading items');
            return Promise.resolve({});
        }
    }, [fetchAllItems, getAggregationFromItems]);

    useEffect(() => {
        loadAllItems();
    }, [loadAllItems]);

    const refreshTable = useCallback(() => {
        onDataChange();
    }, [onDataChange]);

    const setItems = useCallback((items: T[]) => {
        setAllItems(items);
    }, []);

    const onPageTableReady = useCallback(
        (gridApi: GridApi<T>) => {
            onTableReady &&
                onTableReady({
                    refreshTable,
                    setItems,
                    gridApi,
                });
        },
        [onTableReady, refreshTable, setItems],
    );

    const tableProps: IClientFilterTableProps<T> = useMemo(
        () => ({
            filterConditionsContainer,
            clientInfos,
            onFilteredItemsChanged: onTableFilterChange,
            onDataChange,
            allItems: allItems || [],
            pageTableId,
            getFooterItemTitle: getFooterItemTitle,
            getTableColumnDefs,
            getTableActions,
            onPageTableReady,
            tableSettings,
            onSelectionChanged,
            onRowDataUpdated,
        }),
        [
            filterConditionsContainer,
            clientInfos,
            onTableFilterChange,
            onDataChange,
            allItems,
            pageTableId,
            getFooterItemTitle,
            getTableColumnDefs,
            getTableActions,
            onPageTableReady,
            tableSettings,
            onSelectionChanged,
            onRowDataUpdated,
        ],
    );

    const filterPanelProps: IClientFilterPanelProps = useMemo(
        () => ({
            getInitialAggregations,
            getAggregations: onFilterChanged,
            filterTypes,
            dataChangesCounter,
            pageTableId,
            getFieldOptionsMap,
        }),
        [getInitialAggregations, onFilterChanged, filterTypes, dataChangesCounter, pageTableId, getFieldOptionsMap],
    );

    if (!allItems) {
        return (
            <PageStyled.TopDiv>
                <FullPageLoader />
            </PageStyled.TopDiv>
        );
    }

    if (emptyStateComponent && allItems.length === 0) {
        return (
            <ClientFilterEmptyState
                EmptyStateComponent={emptyStateComponent}
                onDataChange={onDataChange}
                pageTableId={pageTableId}
            />
        );
    }

    return (
        <PageStyled.TopDiv>
            <PageStyled.BodyDiv padding={tableSettings?.padding}>
                {filterTypes.length > 0 && <ClientFilterPanel {...filterPanelProps} />}
                {(filterTypes.length === 0 || filterConditionsContainer) && <ClientFilterTable {...tableProps} />}
            </PageStyled.BodyDiv>
        </PageStyled.TopDiv>
    );
};
