import { Context } from 'common/design-system/theme/colors/colors.types';
import styled from 'styled-components';
import Stack from '../Stack';

export const ScoreTileWrapper = styled(Stack)<{ $borderRadius: string }>`
    border-radius: ${({ $borderRadius }) => $borderRadius};
    overflow: hidden;
`;

export const ScoreTileIconWrapper = styled(Stack)<{ $context: Context }>`
    padding: 0 10px;
    background-color: ${({ theme, $context }) => theme.palette[$context].backgrounds.strength.weak};
`;

export const ScoreTileContentWrapper = styled(Stack)<{ $context: Context }>`
    flex: 1;
    padding: 10px 12px;
    gap: ${({ theme }) => theme.spacing(2)}px;
    background-color: ${({ theme, $context }) => theme.palette[$context].backgrounds.strength.weaker};
`;
