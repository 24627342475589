import EntityLink from 'common/components/ProtectedAssets/Renderers/EntityLink';
import React from 'react';
import { generateEntityLink } from '../../../module_interface/intelligence/Intelligence.utils';

export const EntityNameLink: React.FC<{
    entityName: string;
    entityType: string;
    entityExternalId: string;
    entityId: string;
    cloudAccountId: string;
    tabName?: string;
}> = ({ entityName, entityType, entityExternalId, entityId, cloudAccountId, tabName }) => {
    const entityNameOrId = entityName || entityExternalId;
    const finalUrl = generateEntityLink(entityType, entityExternalId, cloudAccountId, entityId, tabName);

    if (!finalUrl) {
        return <div>{entityNameOrId}</div>;
    }

    return <EntityLink entityName={entityNameOrId} url={finalUrl} containerName={''} />;
};
