import React from 'react';
import { ICustomTreeRendererProps } from '../CustomTree.interface';
import { Stack, Typography } from '../../../../design-system/components-v2';

export const CustomTreeDefaultNodeRenderer: React.FC<ICustomTreeRendererProps> = (props) => {
    const { compProps } = props;
    const { node } = compProps;
    const { data } = node;
    return (
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} padding={2}
            style={{ height: '30px', border: '1px solid #ddd', borderRadius: '4px', width: 'fit-content' }}>
            <Typography>{data.label || data.name || node.id || '(noname)'}</Typography>
        </Stack>
    );
};