/* eslint-disable react/prop-types */
import ActivityGaugeChart from 'common/components/Highcharts/ActivityGaugeChart';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import Legend from '../Legend/Legend';
import { IGenericWidgetDataItem, IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
highchartsMore(Highcharts);
solidGauge(Highcharts);

/**
 * TODO notes here
 */

export interface IWidgetActivityGaugeDataProps {
    color?: string;
    numerator: number;
    denominator: number;
    title?: string | number;
    subtitle?: string;
    className?: string;
    items?: IGenericWidgetDataItem[];
}

const WidgetActivityGauge: React.FC<IGenericWidgetDataProps> = (props) => {
    const className = props?.data?.className;
    const items = props?.data?.items;
    const options = props?.data?.options;

    return (
        <>
            {items ? (
                <div className='@container flex flex-1'>
                    <div className={`flex flex-col @[300px]:flex-row gap-4 py-6 px-8 flex-1 ${className}`}>
                        <div className={'flex flex-1'}>
                            <ActivityGaugeChart data={props.data} header={props.widget?.title || ''} />
                        </div>
                        {!options?.suppressLegend && items && (
                            <div className={'items-center flex @[300px]:flex-1'}>
                                <Legend items={items} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default WidgetActivityGauge;
