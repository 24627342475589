import React, { useCallback, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import EnvironmentLink from 'common/components/ProtectedAssets/Renderers/EnvironmentLink';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';

const EnvironmentCellRender: React.FC<ICellRendererParams & { componentName?: string }> = (params) => {
    const { data, componentName } = params;
    const [displayText, setDisplayText] = useState<string | null | undefined>(undefined);
    const displayTextChanged = useCallback((text: string) => setDisplayText(text), []);

    CellToClipboardHandler(params, String(displayText));

    if (params.node.group && !data.cloudAccountId && !data.environmentId) {
        return <></>;
    }

    return (
        <EnvironmentLink
            cloudAccountId={data.cloudAccountId || data.environmentId}
            isIcon={false}
            onDisplayTextChanged={displayTextChanged}
            containerName={componentName}
        />
    );
};

export default EnvironmentCellRender;
