import {
    getAssessmentService,
    getNotificationsService,
    getPolicyService,
    getRulesetService,
} from 'common/interface/services';
import { IRuleset } from 'common/interface/ruleset';
import { IRulesetsPageDataService } from 'common/components/rulesetsPage/RulesetsPage';
import i18n from '../../services/translations/translations';

export interface IRunAssessment {
    ruleId: number;
    cloudAccountIds: string[];
    cloudAccountType: number;
    description: string;
    ruleName: string;
    version: string;
    recipients: string[];
}

export interface IExtendRuleset extends IRuleset {
    numberOfPolicies: number;
}

export const defaultRulesetsDataService: IRulesetsPageDataService = {
    getAll: async (useCache: boolean) => {
        const getRulesets = await getRulesetService().getAllRulesets(useCache);
        const getPolicies = await getPolicyService().getPolicies(useCache);
        const extendRulesets = [] as IExtendRuleset[];
        Promise.all([getRulesets, getPolicies]).then(([rulesets, policies]) => {
            rulesets.forEach((ruleset) => {
                extendRulesets.push({
                    ...ruleset,
                    numberOfPolicies: policies.filter((policy) => policy.rulesetId === ruleset.id).length,
                });
            });
        });
        return extendRulesets;
    },
    deleteItem: async (ruleset, translationKey) => {
        try {
            await getRulesetService().deleteRuleset(ruleset.id);
            getNotificationsService().success(
                i18n.t('TOASTS.DELETE_SUCCESS.TITLE', { ns: translationKey }),
                ruleset.name,
            );
        } catch (error) {
            getNotificationsService().error(i18n.t('TOASTS.DELETE_FAILED.TITLE', { ns: translationKey }), ruleset.name);
        }
    },
    runAssessment: async (runAssessmentData: IRunAssessment, isMultipleEnvironmenrs: boolean) => {
        const { ruleId, cloudAccountIds, cloudAccountType, description, version, ruleName, recipients } =
            runAssessmentData;
        try {
            const response = isMultipleEnvironmenrs
                ? await getAssessmentService().runAssessmentMultipleEnvironments({
                      ruleId: ruleId,
                      cloudAccountIds: cloudAccountIds,
                      cloudAccountType: cloudAccountType,
                      description: description,
                      ruleName: ruleName,
                      version: version,
                      recipients: recipients,
                  })
                : await getAssessmentService().runAssessment({
                      ruleId: ruleId,
                      cloudAccountIds: cloudAccountIds,
                      cloudAccountType: cloudAccountType,
                      description: description,
                      ruleName: ruleName,
                      version: version,
                  });
            getNotificationsService().success('run assessment', 'run assessment completed');
            return response.id;
        } catch (error) {
            getNotificationsService().error('run assessment', 'run assessment failed');
        }
    },
};
