import { Popover } from '@dome9/berries/react-components';
import styled, { css } from 'styled-components';

export const TreeDataWrapper = styled.div`
    display: flex;
    font-size: 13px;

    position: relative;
`;

export const TreeDataBox = styled.div<{ isOpen: boolean }>`
    display: flex;
    align-items: center;
    padding: 0px 7.5px;
    border: 1px solid ${({ theme }) => theme.palette.border.normal};
    border-radius: 8px;
    font-size: 13px;
    height: 32px;
    width: 100%;
    justify-content: space-between;
    gap: 5px;
    background-color: white;

    ${(props) =>
        props.isOpen &&
        css`
            box-shadow: rgb(38, 132, 255) 0 0 0 1px;
        `}

    cursor: pointer;
`;

export const ToggleArrow = styled.div<{ isOpen: boolean }>`
    display: flex;
    align-items: center;

    ${(props) =>
        props.isOpen &&
        css`
            transform: rotate(180deg);
        `};

    & svg {
        fill: ${({ theme, isOpen }) => (isOpen ? theme.palette.text.strong : theme.palette.text.light)};
        opacity: 0.5;
    }
`;

export const StyledPopover = styled(Popover)<{ width: number }>`
    background: ${({ theme }) => theme.palette.surface.primary};
    width: ${(props) => props.width}px;
    max-width: ${(props) => props.width}px;
    min-width: ${(props) => props.width}px;
    max-height: 300px;
    overflow: auto;
    padding: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};

    .tippy-content {
        padding: 0;
    }
`;
