import {
    ICompoundFilter,
    ICompoundFilterItem,
    ICompoundFilterNode,
} from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { FilterConditionOperator, IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';
import _ from 'lodash';

export const operatorsToQueryMap: { [key: string]: string } = {
    [FilterConditionOperator.In]: 'in',
    [FilterConditionOperator.NotIn]: '!in',
    [FilterConditionOperator.GTE]: '>=',
    [FilterConditionOperator.LTE]: '<=',
};

const convertConditionToQuery = (condition: IFilterCondition): string => {
    const operator = operatorsToQueryMap[condition.operator] || condition.operator;
    const values = condition.values.map((value: any) => (_.isString(value) ? `'${value}'` : `${value}`)).join(', ');

    return `${condition.name} ${operator} (${values})`;
};

const convertCompoundFilterItemToQuery = (item: ICompoundFilterItem): string => {
    if ((item as ICompoundFilterNode).logicalOperator) {
        const node = item as ICompoundFilterNode;
        const operandsQuery = node.operands.map(convertCompoundFilterItemToQuery).join(` ${node.logicalOperator} `);
        return `(${operandsQuery})`;
    } else {
        const condition = item as IFilterCondition;
        return convertConditionToQuery(condition);
    }
};

export const convertCompoundFilterToQuery = (compoundFilter: ICompoundFilter): string => {
    return convertCompoundFilterItemToQuery(compoundFilter.root);
};
