import React from 'react';
import SpinnerStyles from './Spinner.styles';
import { SpinnerProps } from './Spinner.types';
import { useTheme } from 'styled-components';

const Spinner: React.FC<SpinnerProps> = ({ size = 24, color, saturation, context }) => {
    const theme = useTheme();

    const strokeColor = React.useMemo(() => {
        if (color) return color;
        const saturationSuffix = saturation ? 'saturation' : 'default';
        if (context) return theme.palette[context].foregrounds.regular[saturationSuffix];
        return 'currentColor';
    }, [color, context, saturation, theme]);

    return (
        <SpinnerStyles.Svg viewBox='0 0 50 50' size={size}>
            <SpinnerStyles.CircleBackground cx={25} cy={25} r={20} />
            <SpinnerStyles.Circle cx={25} cy={25} r={20} color={strokeColor} />
        </SpinnerStyles.Svg>
    );
};

export default Spinner;
