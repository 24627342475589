import React from 'react';
import { Button, Icon, Stack, Dropdown, Tooltip } from 'common/design-system/components-v2';
import List from 'common/design-system/components-v2/List';
import { DrawerActionsStyle, IDrawerAction } from './Drawer.interface';

interface IDrawerActionsProps {
    actions: IDrawerAction[];
    actionsStyle?: DrawerActionsStyle;
}

const DrawerDropdownActions: React.FC<{ actions: IDrawerAction[] }> = ({ actions }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <Dropdown
            open={open}
            buttonProps={{ iconButton: true, iconProps: { name: 'more' } }}
            onStateChange={(state) => setOpen(state)}
        >
            <List onOptionClick={() => setOpen(false)} options={actions} />
        </Dropdown>
    );
};

const DrawerButtonActions: React.FC<{ actions: IDrawerAction[] }> = ({ actions }) => {
    return (
        <Stack spacing={1} direction={'row'} style={{ whiteSpace: 'nowrap' }}>
            {actions.map((action, index) => (
                <Tooltip key={index} content={action.tooltip}>
                    <Button key={index} onClick={action.onClick} variant={'contained'} color={'normal'} removePadding>
                        <Stack direction={'row'} alignItems={'center'} padding={2} spacing={1}>
                            {!!action.icon && typeof action.icon === 'string' && (
                                <Icon vendorNameOrPath={'action.icon'} size={16} />
                            )}
                            {!!action.icon && typeof action.icon !== 'string' && action.icon}
                            {action.label}
                        </Stack>
                    </Button>
                </Tooltip>
            ))}
        </Stack>
    );
};

const DrawerActions: React.FC<IDrawerActionsProps> = ({ actions, actionsStyle = DrawerActionsStyle.DROPDOWN }) => {
    return (
        <>
            {actionsStyle === DrawerActionsStyle.BUTTONS && <DrawerButtonActions actions={actions} />}
            {actionsStyle === DrawerActionsStyle.DROPDOWN && <DrawerDropdownActions actions={actions} />}
        </>
    );
};

export default DrawerActions;
