import { Width } from '../../../design-system/components-v2/Modal';
import React, { ReactElement } from 'react';
import { IListItemProps } from '../../../design-system/components-v2/List/List.types';

export type IDrawerIcon = string | JSX.Element;

export interface IDrawerOptions {
    hasHeaderLineSeparator?: boolean;
    disableSpacing?: boolean;
    width?: Width;
    suppressCloseOnEscape?: boolean;
    onCloseHandlerId?: string;
    suppressCloseAll?: boolean;
    actionsStyle?: DrawerActionsStyle;
}

export interface IDrawerAction extends IListItemProps {}

export interface IDrawerContent {
    component: React.FC<any>;
    componentProps?: any;
    title?: string;
    leftHeaderTrailingContent?: React.ReactNode;
    icon?: IDrawerIcon;
    rightHeaderContent?: React.ReactNode;
    actions?: IDrawerAction[];
}

export enum DrawerState {
    VISIBLE_LOADING = 'visibleLoading',
    VISIBLE_READY = 'visibleReady',
    VISIBLE_ERROR = 'error',
    HIDDEN = 'hidden',
}

export enum DrawerActionsStyle {
    BUTTONS = 'buttons',
    DROPDOWN = 'dropdown',
}

export type IDrawerHandlersIdMap = { [key: string]: string };

export interface IDrawerWidgetUrlParams {
    widgetId: string;
    widgetData?: string;
    handlersIdMap?: IDrawerHandlersIdMap;
}

export interface IDrawerContentProvider {
    id: string;
    getDrawerContent: (
        baseComponentData?: any,
        handlersIdMap?: IDrawerHandlersIdMap,
    ) => Promise<IDrawerContent | undefined>;
    options?: IDrawerOptions;
}

export interface DrawerContentProps {
    options?: IDrawerOptions;
    content?: ReactElement;
    title?: string;
    state: DrawerState;
    icon?: IDrawerIcon;
    rightHeaderContent?: React.ReactNode;
    onCloseClick: () => void;
    actions?: Array<IListItemProps>;
}
