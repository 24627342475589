import { getOrganizationalUnitService } from 'common/interface/data_services';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem } from './MultiSelectList/ListItemTypes/interfaces';
import { MultiSelectListComponent } from './MultiSelectList';

interface OUSelectionProps {
    preSelectedItems?: ListItem[];
    onValidate: Function;
    selectedOUsChanged: Function;
    platform?: string;
}

export const getShortOUPath = (longOUpath: string) => {
    const slashFirstIndex = longOUpath.indexOf('/');
    return longOUpath.slice(slashFirstIndex + 1);
};

export const OUSelectionComponent: React.FC<OUSelectionProps> = ({
    preSelectedItems,
    onValidate,
    selectedOUsChanged,
}) => {
    const { t } = useTranslation();
    const [ous, setOUs] = React.useState<ListItem[] | null>(null);
    const [showNoOUsMessage, setShowNoOUsMessage] = React.useState<boolean>();

    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        onValidate(selectedItems?.length);
        selectedOUsChanged && selectedOUsChanged(selectedItems);
        selectedItems.forEach((item) => {
            const match = ous?.find((_ou) => _ou.id === item.id);
            if (match) {
                match.selectedVersion = item.selectedVersion;
            }
        });
        ous && setOUs([...ous]);
    };
    const init = async () => {
        const allOrganizationalUnits = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath();
        const items: ListItem[] =
            allOrganizationalUnits?.map((ou) => {
                return {
                    id: ou.id,
                    name: getShortOUPath(ou.path) || '',
                    icon: '',
                };
            }) || [];
        if (items?.length) {
            setOUs(items);
        } else {
            setOUs(null);
            setShowNoOUsMessage(true);
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <span data-aid={'ou-selection'}>
            {showNoOUsMessage ? (
                <h3 data-aid={'no-ous-message'}>{t('GENERAL.NO_OUS')}</h3>
            ) : (
                <MultiSelectListComponent
                    preSelectedItems={preSelectedItems}
                    header={t('GENERAL.SELECT_OUS')}
                    items={ous}
                    onSelectedItemsChanged={selectedItemsChanged}
                />
            )}
        </span>
    );
};
