import React from 'react';
import { addAlpha, colors, lightPallete, darkPallete } from './colors';
import { DefaultTheme } from 'styled-components';
import { getColors } from './colors/index';
import { Mode } from './colors/colors.types';
import { GetForegroundColorParams } from './theme.types';

const spacing = 4;
const borderRadius = 4;
const disabledOpacity = 0.4;

const defaultFontProperties: React.CSSProperties = {
    fontFamily: 'Inter',
    fontWeight: 400,
    letterSpacing: '0px',
};

const applyTextTrim = (gapTop: number, gapBottom: number): any => {
    return {
        '&:before': {
            content: '""',
            display: 'block',
            height: '0',
            width: '0',
            marginTop: `-${gapTop}px`,
        },
        '&:after': {
            content: '""',
            display: 'block',
            height: '0',
            width: '0',
            marginBottom: `-${gapBottom}px`,
        },
    };
};

export type ColorsWithState = 'normal' | 'brandPrimary' | 'warning' | 'success' | 'alert';

export const theme = (mode: Mode = 'light'): DefaultTheme => ({
    units: 'px',
    breakpoints: {
        keys: ['sm', 'md', 'lg', 'xl'],
        values: { sm: 0, md: 1280, lg: 1440, xl: 1920 },
    },
    direction: 'ltr',
    palette: mode === 'light' ? { ...lightPallete, ...getColors(mode) } : { ...darkPallete, ...getColors(mode) },
    getForegroundColor: function (params: GetForegroundColorParams) {
        const { context, strength, saturation = false } = params;
        if (strength === 'invertedStrong') return this.palette[context]['foregrounds'].invertedStrong;
        return this.palette[context]['foregrounds'][strength][saturation ? 'saturation' : 'default'];
    },
    border: {
        radius: (num: number) => num * borderRadius,
        width: {
            standard: 1,
            medium: 2,
            large: 4,
            xlarge: 8,
        },
    },
    spacing: (num: number) => num * spacing,
    shadows: {
        0: 'none',
        1: `0px 0.0544px 3.944px 0px ${addAlpha(colors.grey['900'], 0.01)}, 0px 0.2432px 9.112px 0px ${addAlpha(colors.grey['900'], 0.01)}, 0px 0.6048px 20.808px 0px ${addAlpha(colors.grey['900'], 0.02)}, 0px 1.1776px 44.336px 0px ${addAlpha(colors.grey['900'], 0.02)}, 0px 2px 85px 0px ${addAlpha(colors.grey['900'], 0.03)}`,
        2: `0px 1px 8px 0px ${addAlpha(colors.grey['800'], 0.2)}`,
        3: `0px 2px 40px 0px ${addAlpha(colors.grey['800'], 0.24)}`,
        hover: `0px 0px 0px 3px ${addAlpha(mode === 'light' ? colors.grey[40] : colors.grey[700], 0.25)}`,
        focus: `0px 0px 0px 3px ${addAlpha(colors.blue['300'], 0.25)}`,
        focusThin: `0px 0px 0px 1px ${addAlpha(colors.blue['300'], 0.25)}`,
        focusAlert: `0px 0px 0px 3px ${addAlpha(colors.error['300'], 0.25)}`,
    },
    disabledOpacity,
    typography: {
        xs: {
            ...defaultFontProperties,
            fontSize: '10px',
            lineHeight: '16px',
            ...applyTextTrim(4, 3),
        },
        sm: {
            ...defaultFontProperties,
            fontSize: '12px',
            lineHeight: '20px',
            ...applyTextTrim(5, 6),
        },
        md: {
            ...defaultFontProperties,
            fontSize: '14px',
            lineHeight: '24px',
            ...applyTextTrim(7, 7),
        },
        lg: {
            ...defaultFontProperties,
            fontSize: '16px',
            lineHeight: '28px',
            ...applyTextTrim(8, 8),
        },
        xl: {
            ...defaultFontProperties,
            fontSize: '20px',
            lineHeight: '32px',
            ...applyTextTrim(8, 9),
        },
        '2xl': {
            ...defaultFontProperties,
            fontSize: '24px',
            lineHeight: '40px',
            ...applyTextTrim(10, 12),
        },
        '3xl': {
            ...defaultFontProperties,
            fontSize: '32px',
            lineHeight: '48px',
            ...applyTextTrim(11, 13),
        },
        '4xl': {
            ...defaultFontProperties,
            fontSize: '40px',
            lineHeight: '64px',
            ...applyTextTrim(17, 18),
        },
        '5xl': {
            ...defaultFontProperties,
            fontSize: '48px',
            lineHeight: '76px',
            ...applyTextTrim(20, 20),
        },
        h1: {
            ...defaultFontProperties,
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '32px',
        },
        h2: {
            ...defaultFontProperties,
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
        },
        subtitleLg: {
            ...defaultFontProperties,
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
        },
        hint: {
            ...defaultFontProperties,
            fontSize: '12px',
            lineHeight: '16px',
            fontStyle: 'italic',
        },
        bodyLg: {
            ...defaultFontProperties,
            fontSize: '14px',
            lineHeight: '24px',
        },
        body: {
            ...defaultFontProperties,
            fontSize: '12px',
            lineHeight: '16px',
        },
        body500: {
            ...defaultFontProperties,
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '16px',
        },
        bodyXs: {
            ...defaultFontProperties,
            fontSize: '10px',
            lineHeight: '16px',
        },
        codeLg: {
            ...defaultFontProperties,
            fontFamily: 'RobotoMono',
            fontSize: '14px',
            lineHeight: '16px',
        },
        codeMd: {
            ...defaultFontProperties,
            fontFamily: 'RobotoMono',
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    transitions: {
        easing: {
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
        },
    },
    zIndex: {
        mobileStepper: 1000,
        fab: 1050,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
});

export const getTextContrastColor = (color: string, theme: DefaultTheme): string => {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 190 ? theme.palette.text.normal : theme.palette.text.inverse;
};
