import { compareIgnoringCaseAndQuotes, escapeRegExp } from '../helpers/JsonView.utils';
import { Span } from '../helpers/JsonView.styles';
import { jsonColors } from '../helpers/JsonView.consts';
import React from 'react';
import { HighlightTextProps } from '../helpers/JsonView.types';

export const HighlightText: React.FC<HighlightTextProps> = (props) => {
    const { text, searchTerm, id, currentMatchId } = props;
    const parts = searchTerm ? text.split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi')) : [text];
    const isMatch = currentMatchId === id ? jsonColors.highlight : null;
    if (searchTerm && isMatch && compareIgnoringCaseAndQuotes(text, searchTerm)) {
        return (
            <Span id={id} backgroundColor={jsonColors.highlight}>
                {text}
            </Span>
        );
    } else if (searchTerm && parts.length > 0) {
        const highlightClass = isMatch ? jsonColors.highlight : jsonColors.highlightPartly;
        return (
            <>
                {parts.map((part, index) => (
                    <Span
                        id={id}
                        key={index}
                        backgroundColor={part.toLowerCase() === searchTerm.toLowerCase() ? highlightClass : ''}
                    >
                        {part}
                    </Span>
                ))}
            </>
        );
    }
    return <Span>{text}</Span>;
};
