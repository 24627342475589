import React from 'react';
import { IConditionValuesComponentProps } from '../FilterTree.interface';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../design-system/components-v2';

export const DefaultFreeTextSearcher: React.FC<IConditionValuesComponentProps> =
    ({ condition, filterProps, onValuesChange }) => {
        const { t } = useTranslation();

        return (
            <Input
                value={condition.values.length > 0 ? String(condition.values[0]) : ''}
                onChange={(e: any) => onValuesChange([e.target.value])}
                placeholder={filterProps.readOnly ? undefined : t('FILTER_TREE.INPUT_PLACEHOLDER')}
                readOnly={filterProps.readOnly}
                isError={!!(filterProps.displayErrors && condition.valuesErrorMsg)}
                fullWidth
            />
        );
    };
