import React, { useRef } from 'react';
import ComplexRow from './ComplexRow';
import SimpleRow from './SimpleRow';
import { isTypeArrayOrObject } from '../helpers/JsonView.utils';
import { IRowWrapperProps } from '../helpers/JsonView.types';

const RowWrapper: React.FC<IRowWrapperProps> = (props) => {
    const { item, searchTerm, level, collapseLevel = 2, currentMatchId } = props;
    const treeRef = useRef<HTMLDivElement>(null);
    return (
        <div ref={treeRef}>
            {isTypeArrayOrObject(item.value) ? (
                <ComplexRow
                    item={item}
                    searchTerm={searchTerm}
                    collapseLevel={collapseLevel}
                    level={level}
                    currentMatchId={currentMatchId}
                />
            ) : (
                <SimpleRow item={item} level={level} searchTerm={searchTerm} currentMatchId={currentMatchId} />
            )}
        </div>
    );
};

RowWrapper.displayName = 'RowWrapper';

export default RowWrapper;
