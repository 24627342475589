import React, { useEffect, useState } from 'react';
import './TagFilter.scss';
import { FILTER_EVENTS, FILTER_PANEL_CONSTS } from '../../FilterPanel.consts';
import { IExtendITagFilterProps } from '../DefaultFilters.interface';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { isFilterBoxContent, renderAccordionClearButton } from '../../filterUtils';
import { Button, Icon, TextField } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';

const TagFilter: React.FC<{ filterProps: IExtendITagFilterProps }> = ({ filterProps }) => {
    const { isMultiTags = false, key, title, searchTerm, displayType, onEvent, value, defaultValue } = filterProps;
    const { t } = useTranslation();
    const [tag, setTag] = useState(value[0]);
    const [tagList, setTagList] = useState(value);

    const onInput = (name: string, currValue: string, index = 0) => {
        if (isMultiTags) {
            const cloneTagList = [...tagList];
            const currentObject = { key: cloneTagList[index].key, value: cloneTagList[index].value };
            name === 'key' ? (currentObject.key = currValue) : (currentObject.value = currValue);
            cloneTagList[index] = currentObject;
            setTagList(cloneTagList);
            onEvent({
                action: FILTER_EVENTS.FILTER_CHANGED,
                filterKey: key,
                payload: cloneTagList,
            });
        } else {
            setTag({ ...tag, [name]: currValue });
            onEvent({
                action: FILTER_EVENTS.FILTER_CHANGED,
                filterKey: key,
                payload: [{ key: tag.key, value: tag.value, [name]: currValue }],
            });
        }
    };

    const onAddTagItem = () => {
        setTagList([...tagList, { key: '', value: '' }]);
    };

    const onRemoveTag = (index: number) => {
        const cloneTagList = [...tagList];
        cloneTagList.splice(index, 1);
        setTagList(cloneTagList);
        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: cloneTagList,
        });
    };

    useEffect(() => {
        if (isMultiTags) {
            setTagList(value || defaultValue);
        } else {
            setTag(value[0] || defaultValue);
        }
    }, [value, defaultValue, isMultiTags]);
    const renderTag = () => {
        return (
            <div className=''>
                {isMultiTags ? (
                    <div className=''>
                        {tagList?.map((tag, i) => (
                            <div key={tag.key} className='flex  mb-8 gap-6'>
                                <div className=''>
                                    {!i && (
                                        <label className='tag-label key-label'>
                                            {FILTER_PANEL_CONSTS.TAG_FILTER_KEY}
                                        </label>
                                    )}

                                    <TextField
                                        allowClear={false}
                                        value={tag.key}
                                        onChange={(e: any) => onInput('key', e.target.value, i)}
                                    />
                                </div>
                                <div className=''>
                                    {!i && <label className=''>{FILTER_PANEL_CONSTS.TAG_FILTER_VALUE}</label>}
                                    <div className='flex-center'>
                                        <TextField
                                            allowClear={false}
                                            value={tag.value}
                                            onChange={(e: any) => onInput('value', e.target.value, i)}
                                        />
                                        <span className='ml-[5px]' onClick={() => onRemoveTag(i)}>
                                            <Icon name='remove' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Button icon={'plus'} onClick={onAddTagItem} label={t('FILTER_PANEL.ADD')}></Button>
                    </div>
                ) : (
                    <>
                        <div className=''>
                            <label className='tag-label key-label'>{FILTER_PANEL_CONSTS.TAG_FILTER_KEY}</label>
                            <TextField value={tag.key} onChange={(e: any) => onInput('key', e.target.value)} />
                        </div>
                        <div className=''>
                            <label className=''>{FILTER_PANEL_CONSTS.TAG_FILTER_VALUE}</label>
                            <TextField value={tag.value} onChange={(e: any) => onInput('value', e.target.value)} />
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderTagBoxView = () => {
        const search = searchTerm.toLowerCase();
        const isTitle = searchTerm ? title.toLowerCase().includes(search) : true;
        return (
            isTitle && (
                <Accordion
                    title={title}
                    content={renderTag()}
                    onOpen={(elementRef: any) => {
                        onEvent({
                            action: FILTER_EVENTS.BOX_FILTER_OPEN,
                            filterKey: key,
                            payload: elementRef,
                        });
                    }}
                    optionsList={[
                        renderAccordionClearButton(() =>
                            onEvent({
                                action: FILTER_EVENTS.CLEAR_FILTERS,
                                filterKey: key,
                                payload: '',
                            }),
                        ),
                    ]}
                />
            )
        );
    };

    return (
        <div className='TagFilter' data-aid='tag-filter'>
            {isFilterBoxContent(displayType) ? renderTagBoxView() : renderTag()}
        </div>
    );
};

export default TagFilter;
