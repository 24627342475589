import './TreeFilter.scss';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { FILTER_EVENTS } from '../../FilterPanel.consts';
import { GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { IExtendITreeFilterProps, ITreeFilterObject } from '../DefaultFilters.interface';
import { isFilterBoxContent, isIncludeSearchTerm, renderAccordionClearButton } from '../../filterUtils';

const TreeFilter: React.FC<{ filterProps: IExtendITreeFilterProps }> = ({ filterProps }) => {
    const {
        isMultiSelect = false,
        initialData,
        title,
        onEvent,
        value,
        key,
        predefinedValue,
        searchTerm,
        displayType,
    } = filterProps;
    const gridApi = React.useRef<GridApi>();

    const filterDataBySearchTerm = () => {
        return initialData.filter(
            (node: ITreeFilterObject) =>
                isIncludeSearchTerm(searchTerm, node.name) || isIncludeSearchTerm(searchTerm, title),
        );
    };

    const filterDataBySearchTermMemo = useCallback(filterDataBySearchTerm, [initialData, searchTerm, title]);
    const [filteredData, setFilteredData] = useState(filterDataBySearchTerm());

    useEffect(() => {
        const displayedData = filterDataBySearchTermMemo();
        /* There is an issue with ag-grid - when grid is hidden from the screen it destroys the instance,
          but later, we don't have the ability to check if the instance is destroyed. AG-GRID has a ticket for it AG-684.
         meanwhile we used private member 'destroyCalled' */
        // @ts-ignore
        if (searchTerm && gridApi && !gridApi.destroyCalled) {
            gridApi.current = undefined;
        }
        setFilteredData(displayedData);
    }, [filterDataBySearchTermMemo, searchTerm, gridApi]);

    useEffect(() => {
        const expandAllTreePath = (node: IRowNode) => {
            node.setExpanded(true);
            if (node.level !== 0 && node.parent) {
                expandAllTreePath(node.parent);
            }
        };
        if (searchTerm) {
            gridApi?.current?.forEachNode((node) => {
                if (node?.key?.toLowerCase().includes(searchTerm.toLowerCase())) {
                    expandAllTreePath(node);
                }
            });
        }
    }, [gridApi, searchTerm]);

    const selectRows = useCallback(() => {
        gridApi?.current?.forEachNode((node) => {
            if (!node.data && value?.includes(predefinedValue)) {
                node.setSelected(true);
            } else if (node.data && value?.includes(node.data.id)) {
                node.setSelected(true);
            }
        });
    }, [gridApi, value, predefinedValue]);

    useEffect(() => {
        selectRows();
    }, [predefinedValue, value, gridApi, selectRows]);

    const defaultRow = (params: GridReadyEvent) => {
        gridApi.current = params.api;
        selectRows();
    };

    const onNodeSelected = () => {
        let payload = [];
        const redundantNodesIds = new Set();
        const getNodeLeaves = (node: IRowNode): IRowNode<any>[] => {
            const leaves: IRowNode<any>[] = [];
            const getLeavesRecursively = (currentNode: IRowNode) => {
                if (currentNode !== node) {
                    leaves.push(currentNode);
                }
                if (currentNode?.allChildrenCount) {
                    currentNode.childrenAfterGroup?.forEach(getLeavesRecursively);
                }
            };
            getLeavesRecursively(node);
            return leaves;
        };
        const buildMultiSelectPayload = (selectedNodes?: IRowNode[]) => {
            return (
                selectedNodes?.reduce((result: any, node) => {
                    const leaves = getNodeLeaves(node);
                    leaves.forEach((leaf) => {
                        if (!redundantNodesIds.has(leaf.data.id)) {
                            redundantNodesIds.add(leaf.data.id);
                        }
                    });
                    if (!redundantNodesIds.has(node.data.id)) {
                        result.push(node.data.id);
                    }
                    return result;
                }, []) || []
            );
        };
        if (isMultiSelect) {
            const selectedNodes = gridApi.current?.getSelectedNodes();
            payload = buildMultiSelectPayload(selectedNodes);
        } else {
            gridApi.current?.getSelectedRows().forEach((row) => {
                payload.push(row.id);
            });
        }
        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: payload,
        });
    };

    const getDataPath = (data: any) => {
        return data.path;
    };

    const renderTreeFilterBoxView = () => {
        const { isTitle, isContent } = filterBySearchContent();
        return (
            <div>
                {isContent || isTitle ? (
                    <Accordion
                        title={title}
                        content={renderTreeFilter()}
                        showContent={(isTitle || isContent) && searchTerm !== ''}
                        optionsList={[
                            renderAccordionClearButton(() =>
                                onEvent({
                                    action: FILTER_EVENTS.CLEAR_FILTERS,
                                    filterKey: key,
                                    payload: '',
                                }),
                            ),
                        ]}
                        onOpen={(elementRef: any) => {
                            onEvent({
                                action: FILTER_EVENTS.BOX_FILTER_OPEN,
                                filterKey: key,
                                payload: elementRef,
                            });
                        }}
                    />
                ) : (
                    <div></div>
                )}
            </div>
        );
    };

    const filterBySearchContent = () => {
        const isTitle = searchTerm ? isIncludeSearchTerm(searchTerm, title) : true;
        const isContent = searchTerm ? filteredData.length > 0 : true;

        return { isTitle, isContent };
    };

    const suppressRowClickSelection = !!isMultiSelect;
    const rowSelectionMode = isMultiSelect ? 'multiple' : 'single';
    const autoGroupColumnDef = useMemo(() => {
        return {
            cellRendererParams: {
                checkbox: !!isMultiSelect,
                suppressCount: true,
            },
        };
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const renderTreeFilter = () => {
        return (
            <div className='max-h-[300px] overflow-auto min-h-[50px] bg-content border'>
                <AgGridReact
                    domLayout='autoHeight'
                    rowHeight={24}
                    groupSelectsChildren={isMultiSelect}
                    treeData={true}
                    suppressRowClickSelection={suppressRowClickSelection}
                    rowData={filteredData}
                    rowSelection={rowSelectionMode}
                    onRowSelected={() => {
                        onNodeSelected();
                    }}
                    onGridReady={defaultRow}
                    getDataPath={getDataPath}
                    columnDefs={[]}
                    defaultColDef={{ flex: 1 }}
                    animateRows={true}
                    className='ag-theme-alpine ag-tree-data ag-header-none'
                    groupDefaultExpanded={1}
                    sideBar={null}
                    headerHeight={0}
                    suppressContextMenu={true}
                    autoGroupColumnDef={autoGroupColumnDef}
                />
            </div>
        );
    };

    return (
        <div className='TreeFilter'>
            {isFilterBoxContent(displayType) ? renderTreeFilterBoxView() : renderTreeFilter()}
        </div>
    );
};

export default TreeFilter;
