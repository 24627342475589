import { globalAddinContainer } from '../../extensibility/AddinContainer';
import { Addin } from '../../extensibility/AddinRegistry';
import { IFilterProps } from '../../components/FilterPanel/FilterPanelManager';
import {
    IEnvironmentsAction,
    IEnvironmentsFilterParams,
    IEnvironmentsTableCellRendererAddinContent,
    PATH_ENVIRONMENTS_PAGE_TABLE_ACTIONS,
    PATH_ENVIRONMENTS_PAGE_TABLE_CELL_RENDERERS,
    PATH_ENVIRONMENTS_PAGE_TABLE_COLUMNS,
    PATH_ENVIRONMENTS_PAGE_TABLE_FILTERS,
    PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_ID,
    PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_PLATFORM,
    PATH_ENVIRONMENTS_PAGE_TABLE_ADD_DATA_ROWS,
    IEnvironmentsFilter,
    PATH_ENVIRONMENTS_PAGE_TABLE_TABS,
    IEnvironmentsExport,
    PATH_ENVIRONMENTS_PAGE_TABLE_EXPORTS,
} from './Environments';
import { ColDef } from 'ag-grid-community';
import { Vendors } from 'common/consts/vendors';
import { ICloudAccount } from 'common/interface/data_services';
import { IEnvironment } from 'common/interface/environmentsTable';

export interface ETColDef<T = any> extends ColDef<T> {
    permission?: () => boolean;
    position?: number;
    id: string;
}

export interface CustomData {
    [key: string]: string | number | boolean | undefined | any[] | object;
}
export interface CustomDataById {
    id: string;
    customData: CustomData;
}

export interface CustomDataByPlatform {
    platforms: Vendors[];
    customData: CustomData;
}

export interface AddCustomDataById {
    isRelevant?: (selectedTab: string) => boolean;
    customData: (allCloudAccounts: ICloudAccount[]) => Promise<CustomDataById[]>;
}

export interface AddCustomDataByPlatform {
    isRelevant?: (selectedTab: string) => boolean;
    customData: (allCloudAccounts: ICloudAccount[]) => Promise<CustomDataByPlatform>;
}

export interface AddCustomRow {
    isRelevant?: (selectedTab: string) => boolean;
    getRows: () => Promise<IEnvironment[]>;
}

export interface Filters {
    fields?: { value: string; name: string }[];
    freeTextPhrase?: string;
}

export interface EnvironmentTab {
    id: string;
    label: string;
    columns: { id: string; overrides?: Partial<ETColDef<IEnvironment>> }[];
    filters: string[];
    actions: string[];
    defaultFilters?: Filters;
    exportOptions?: string[];
}

export const EnvironmentsTableRegistry = {
    addColumnDefAddins: (columnDefAddins: Addin<ETColDef>[]) => {
        globalAddinContainer.add<ETColDef>(
            PATH_ENVIRONMENTS_PAGE_TABLE_COLUMNS,
            columnDefAddins.map((columnDef) => ({ position: Infinity, ...columnDef })),
        );
    },

    addColumnDefs: (columnDefs: ETColDef[]) => {
        globalAddinContainer.addMap(PATH_ENVIRONMENTS_PAGE_TABLE_COLUMNS, columnDefs, 'id');
    },

    getColumnDefs: (): ETColDef[] => {
        return globalAddinContainer.get<ETColDef>(PATH_ENVIRONMENTS_PAGE_TABLE_COLUMNS);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_ENVIRONMENTS_PAGE_TABLE_FILTERS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IEnvironmentsFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_ENVIRONMENTS_PAGE_TABLE_FILTERS, filterId, filterParams);
    },

    getFilterDefs(filters: IEnvironmentsFilter[], params: IEnvironmentsFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = EnvironmentsTableRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    },

    addActions: (actionDefs: Addin<IEnvironmentsAction>[]) => {
        globalAddinContainer.add<IEnvironmentsAction>(PATH_ENVIRONMENTS_PAGE_TABLE_ACTIONS, actionDefs);
    },

    getActions: () => {
        return globalAddinContainer.get<IEnvironmentsAction>(PATH_ENVIRONMENTS_PAGE_TABLE_ACTIONS);
    },

    addExports: (actionDefs: Addin<IEnvironmentsExport>[]) => {
        globalAddinContainer.add<IEnvironmentsExport>(PATH_ENVIRONMENTS_PAGE_TABLE_EXPORTS, actionDefs);
    },

    getExports: () => {
        return globalAddinContainer.get<IEnvironmentsExport>(PATH_ENVIRONMENTS_PAGE_TABLE_EXPORTS);
    },

    addCellRendererAddins: (cellRendererAddins: Addin<IEnvironmentsTableCellRendererAddinContent>[]) => {
        globalAddinContainer.add<IEnvironmentsTableCellRendererAddinContent>(
            PATH_ENVIRONMENTS_PAGE_TABLE_CELL_RENDERERS,
            cellRendererAddins,
        );
    },

    addCustomDataById: (addDataAddin: Addin<AddCustomDataById>[]) => {
        globalAddinContainer.add<AddCustomDataById>(PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_ID, addDataAddin);
    },

    getCustomDataById: () => {
        return globalAddinContainer.get<AddCustomDataById>(PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_ID);
    },

    addCustomDataByPlatform: (addDataAddin: Addin<AddCustomDataByPlatform>[]) => {
        globalAddinContainer.add<AddCustomDataByPlatform>(PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_PLATFORM, addDataAddin);
    },

    getCustomDataByPlatform: () => {
        return globalAddinContainer.get<AddCustomDataByPlatform>(PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_PLATFORM);
    },

    addCustomRows: (addCustomRows: Addin<AddCustomRow>[]) => {
        globalAddinContainer.add<AddCustomRow>(PATH_ENVIRONMENTS_PAGE_TABLE_ADD_DATA_ROWS, addCustomRows);
    },

    getCustomRows: () => {
        return globalAddinContainer.get<AddCustomRow>(PATH_ENVIRONMENTS_PAGE_TABLE_ADD_DATA_ROWS);
    },

    addTabs: (tabs: EnvironmentTab[]) => {
        globalAddinContainer.addMap(PATH_ENVIRONMENTS_PAGE_TABLE_TABS, tabs, 'id');
    },

    getTabs: () => {
        return globalAddinContainer.get<EnvironmentTab>(PATH_ENVIRONMENTS_PAGE_TABLE_TABS);
    },
};
