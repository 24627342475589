import { buildPath, globalAddinContainer } from '../../extensibility/AddinContainer';
import { IEventOverviewPanelProvider } from './Events';
import { Addin } from '../../extensibility/AddinRegistry';
import { IProtectedAssetDetailProvider } from '../assets/ProtectedAssets';
import { InfoPaneFieldProps } from '../../components/InfoPane/InfoPane';
import { EventOverviewPanels } from './EventsConsts';

export const PATH_EVENT_OVERVIEW_PANELS = buildPath('events', 'event-overview');
const PATH_FINDING_ASSET_PANEL_FIELDS = buildPath(PATH_EVENT_OVERVIEW_PANELS, 'fields');

export const EventOverviewRegistry = {
    addPanels: (panels: Addin<IEventOverviewPanelProvider>[], id: EventOverviewPanels) => {
        const fullPath = buildPath(PATH_FINDING_ASSET_PANEL_FIELDS, id);
        globalAddinContainer.add<IEventOverviewPanelProvider>(fullPath, panels);
    },

    getPanels: (id: EventOverviewPanels): IEventOverviewPanelProvider[] => {
        const fullPath = buildPath(PATH_FINDING_ASSET_PANEL_FIELDS, id);
        return globalAddinContainer.get<IEventOverviewPanelProvider>(fullPath);
    },

    addFieldAddins: (fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[], panelId: string) => {
        const fullPath = buildPath(PATH_FINDING_ASSET_PANEL_FIELDS, panelId);
        globalAddinContainer.add<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath, fieldAddins);
    },

    getFields: (panelId: string): IProtectedAssetDetailProvider<InfoPaneFieldProps>[] => {
        const fullPath = buildPath(PATH_FINDING_ASSET_PANEL_FIELDS, panelId);
        return globalAddinContainer.get<IProtectedAssetDetailProvider<InfoPaneFieldProps>>(fullPath);
    },
};
