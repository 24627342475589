import { LogFields } from './Logs.interface';

export const LogTableConfigurationsFields = {
    envId: 'envId',
    tabId: 'tabId',
};

export const LOGS_OVERVIEW_DRAWER_ID = 'logs-overview-drawer';
export const LOGS_TABLE_ACCOUNT_ACTIVITY = 'logs-table-account-activity';
export const LOGS_TABLE = 'logs-table';
export const LOGS_FILTER_PANEL_ID = 'logs-filter-panel';

export enum eventStatus {
    Success = 'Success',
    Failure = 'Failure',
}

export enum LogFilterFields {
    eventStatus = LogFields.eventStatus,
    eventAws = LogFields.eventAws,
}

export const InternalCountryName = 'internal';
