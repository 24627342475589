import {
    IActiveFeatureInfo,
} from '../../../registries/ActiveFeatureManager/ActiveFeatures';
import { ActiveFeaturesRegistry } from '../../../registries/ActiveFeatureManager/ActiveFeaturesRegistry';
import { DEV_TOPIC_ID, isAfEnabled } from '../../../utils/debugUtils';
import { initializeDevModal } from '../Dev/DevModal.initialize';

export enum ErmComponentsActiveFeatures {
    DEV_MODAL_AF = 'DEV_MODAL_AF',
}

export const ErmComponentsAfMap: { [key in ErmComponentsActiveFeatures]: IActiveFeatureInfo } = {
    DEV_MODAL_AF: {
        key: 'dev-modal',
        description: 'If enabled, it allows opening dev modal',
        topic: DEV_TOPIC_ID,
        nonProd: true,
    },
};

export const isAfDevModal = () => isAfEnabled(ErmComponentsAfMap.DEV_MODAL_AF.key);

export const initializeErmComponentsActiveFeatures = () => {
    ActiveFeaturesRegistry.addActiveFeatureInfos(Object.values(ErmComponentsAfMap));
    initializeDevModal();
};
