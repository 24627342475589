import React, { useRef } from 'react';
import Popover from '../../design-system/components-v2/Popover';

export const ContextMenu = React.forwardRef<any, { children: any; render: Function }>((props, ref) => {
    const { children, render } = props;
    const contentRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    React.useImperativeHandle(ref, () => contentRef.current as any);

    const hideMenu = () => {
        setIsOpen(false);
    };

    const show = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setIsOpen(true);
    };

    React.useEffect(() => {
        return () => {
            setIsOpen(false);
        };
    }, []);

    return (
        <React.Fragment>
            {React.cloneElement(children, { ref: contentRef, onContextMenu: (event: any) => show(event) })}
            <Popover
                key={children}
                open={isOpen}
                anchorEl={contentRef.current}
                onClose={hideMenu}
                placement={'bottom'}
                maxHeight={Infinity}
            >
                {render()}
            </Popover>
        </React.Fragment>
    );
});
ContextMenu.displayName = 'ContextMenu';
