import styled, { css } from 'styled-components';
import { Theme } from '../../theme/theme.types';

import { Context } from '../../theme/colors/colors.types';

interface WrapperProps {
    variant: keyof Theme['typography'];
    color: keyof Theme['palette']['text'] | 'inherit';
    type: 'body' | 'key' | 'aside';
    textDecoration?: 'underline';
    ellipsis?: boolean;
    isNewVariant: boolean;
    context?: Context;
    saturation?: boolean;
}
const Wrapper = styled.p<WrapperProps>`
    ${({ theme, variant }) => css({ ...theme.typography[variant] })};
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.palette.text[color])};
    ${({ textDecoration }) =>
        textDecoration === 'underline' &&
        css`
            text-decoration: underline;
        `};

    ${({ theme, context, saturation }) =>
        context &&
        css`
            color: ${theme.getForegroundColor({ context, strength: 'regular', saturation })};
        `};
    ${({ type }) =>
        type === 'key' &&
        css`
            font-weight: 500;
        `};

    ${({ isNewVariant, ellipsis }) =>
        isNewVariant &&
        ellipsis &&
        css`
            max-width: 100%;

            & div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }
        `};

    ${({ isNewVariant, ellipsis }) =>
        !isNewVariant &&
        ellipsis &&
        css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        `};
`;

const TypographyStyles = {
    Wrapper,
};

export default TypographyStyles;
