import React, { useCallback, useState } from 'react';
import { Button, Label, Spinner, Stack, Typography } from '../../../../design-system/components-v2';
import { getCloudAccountsService, ICloudAccount } from '../../../../interface/data_services';
import { LoadingState } from '../../../../interface/general';
import { getHttpService } from '../../../../interface/services';

const PATH = 'view/common/all-cloud-accounts';

interface IFetchResult {
    accountsCount: number;
    durationMillis: number;
    requestId: string;
}

let counter = 1;
const getRequestId = () => {
    counter++;
    return `request-${counter}`;
};

export const DevModalExample1: React.FC = () => {
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING_SUCCEEDED);
    const [fetchResults, setFetchResults] = useState<IFetchResult[]>([]);

    const clearCache = useCallback(() => {
        getHttpService().clearCacheByPath(PATH);
        setLoadingState(LoadingState.LOADING_SUCCEEDED);
        setFetchResults([]);
    }, []);

    const clearFullCache = useCallback(() => {
        getHttpService().clearEntireCache();
        setLoadingState(LoadingState.LOADING_SUCCEEDED);
        setFetchResults([]);
    }, []);

    const getCacheSize = useCallback(() => {
        return getHttpService().getCacheSize();
    }, []);

    const fetchCloudAccounts = useCallback(async () => {
        setLoadingState(LoadingState.IS_LOADING);
        const before = new Date();
        try {
            const allCloudAccounts: ICloudAccount[] = await getCloudAccountsService().getAllCloudAccounts();
            const after = new Date();
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
            const result: IFetchResult = {
                accountsCount: allCloudAccounts.length,
                durationMillis: after.getTime() - before.getTime(),
                requestId: getRequestId(),
            };
            setFetchResults([...fetchResults, result]);
        } catch (err) {
            setFetchResults([]);
            setLoadingState(LoadingState.LOADING_FAILED);
        }
    }, [fetchResults]);

    return (
        <Stack spacing={2} direction={'column'}>
            <Label text={'Example 1: Check cache of getAllCloudAccounts'} size={'xl'} />
            <Stack spacing={4} direction={'row'} alignItems={'center'}>
                <Button color={'brandPrimary'} style={{ width: '200px' }} onClick={clearCache}>
                    Clear Cache
                </Button>
                <Button color={'brandPrimary'} style={{ width: '200px' }} onClick={fetchCloudAccounts}>
                    Fetch Cloud Accounts
                </Button>
                <Button color={'brandPrimary'} style={{ width: '200px' }} onClick={clearFullCache}>
                    Clear Full Cache ({getCacheSize()})
                </Button>
            </Stack>
            <Stack direction={'column'} spacing={2}>
                {loadingState === LoadingState.LOADING_SUCCEEDED &&
                    fetchResults.map((result: IFetchResult, index: number) => (
                        <Typography key={result.requestId}>
                            {index}. [{result.durationMillis} ms] count={result.accountsCount}
                        </Typography>
                    ))}
                {loadingState === LoadingState.LOADING_FAILED && (
                    <Typography color={'alert'}>(Fetch failed)</Typography>
                )}
                {loadingState === LoadingState.IS_LOADING && <Spinner />}
            </Stack>
        </Stack>
    );
};
