import { FC } from 'react';
import { IScoreTileProps } from './ScoreTile.type';
import { ScoreTileContentWrapper, ScoreTileIconWrapper, ScoreTileWrapper } from './ScoreTile.style';
import Icon from '../Icon';
import { useTheme } from 'styled-components';
import Typography from '../Typography';
import { calculateStyle } from './utils';

export const ScoreTile: FC<IScoreTileProps> = ({ context = 'default', size = 'md', title, score, icon }) => {
    const theme = useTheme();
    const { borderRadius, iconSize } = calculateStyle(size, theme);
    return (
        <ScoreTileWrapper $borderRadius={borderRadius} direction='row'>
            {icon && (
                <ScoreTileIconWrapper
                    $context={context}
                    data-aid='score-tile-icon'
                    alignItems='center'
                    justifyContent='center'
                    direction='row'
                >
                    <Icon name={icon} size={iconSize} context={context} />
                </ScoreTileIconWrapper>
            )}
            <ScoreTileContentWrapper $context={context}>
                {title && (
                    <Typography context={context} variant={size}>
                        {title}
                    </Typography>
                )}
                <Typography context={context} variant={size}>
                    {score}
                </Typography>
            </ScoreTileContentWrapper>
        </ScoreTileWrapper>
    );
};
