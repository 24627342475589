import styled, { css } from 'styled-components';
import Stack from '../Stack';
import { CheckboxLabelProps } from './Checkbox.types';

interface CheckboxWrapperProps {
    checked?: boolean;
    disabled?: boolean;
    size?: CheckboxLabelProps['size'];
}

const Wrapper = styled.div<CheckboxWrapperProps>`
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: fit-content;
    overflow: hidden;
    margin-left: -8px;

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `}
`;

const Input = styled.input`
    position: absolute;
    pointer-events: all;
    left: 4px;
    top: 4px;
    height: 24px;
    width: 24px;
    z-index: 1;
    appearance: initial;
    border: unset;
    background: transparent;
    cursor: pointer;
    outline: none;
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};

    &:checked {
        background: transparent;
        border: unset;
        cursor: pointer;
    }

    &:hover {
        box-shadow: ${({ theme }) => `inset ${theme.shadows.hover}`};
    }

    &:focus {
        box-shadow: ${({ theme }) => `inset ${theme.shadows.focus}`};
    }

    &:disabled {
        cursor: not-allowed;
        box-shadow: none;
    }
`;

const CheckboxLabelWrapper = styled(Stack)`
    width: 100%;
    max-width: fit-content;
`;

const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
    height: 16px;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    margin: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
    border-radius: ${({ theme }) => `${theme.border.radius(1)}${theme.units}`};
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.strong}`};
    position: relative;

    ${({ theme, disabled }) =>
        disabled &&
        css`
            opacity: ${theme.disabledOpacity};
        `}

    & svg {
        position: absolute;
        left: -1px;
        top: -1px;
    }
`;

const getPaddingTop = (size: CheckboxLabelProps['size']) => {
    switch (size) {
        case 'md':
            return '8px';
        case 'lg':
            return '4px';
        case 'xl':
            return '4px';
    }
};

interface LabelWrapperProps {
    hasChildren: boolean;
    disabled?: boolean;
    size: CheckboxLabelProps['size'];
}
const LabelWrapper = styled.div<LabelWrapperProps>`
    padding-top: ${({ size, hasChildren }) => (hasChildren ? 'unset' : getPaddingTop(size))};
    overflow: hidden;
    ${({ theme, disabled }) =>
        disabled &&
        css`
            opacity: ${theme.disabledOpacity};
        `}
`;

const CheckboxStyles = {
    Wrapper,
    Input,
    CheckboxLabelWrapper,
    CheckboxWrapper,
    LabelWrapper,
};

export default CheckboxStyles;
