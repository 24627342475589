import styled from 'styled-components';

const Wrapper = styled.div`
    pointer-events: none;
    opacity: 0.7;
    height: 32px;
`;

export default {
    Wrapper,
};
