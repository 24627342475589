import { Alert, Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const InfoBox = styled(Stack)<{ isError?: boolean }>`
    padding: 12px;
    min-width: 105px;
    border-radius: 10px;
    background-color: ${({ theme, isError }) =>
        `${theme.palette.severity[isError ? 'criticalBg' : 'informationalBg']}`};
    transition: background-color 0.2s ease;

    p {
        color: ${({ theme, isError }) => (isError ? `${theme.palette.high.foregrounds.regular.default}` : 'inherit')};
        transition: color 0.2s ease;
    }
`;

export const StickyAlert = styled(Alert)`
    position: sticky;
    top: 0;
    z-index: 1;
`;
