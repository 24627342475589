import { buildPath } from '../../extensibility/AddinContainer';

const PATH_ASSETS_ENVIRONMENTS_PREFIX = buildPath('assets', 'environments');
export const PATH_ENVIRONMENTS_GET_STARTED = buildPath(PATH_ASSETS_ENVIRONMENTS_PREFIX, 'get started');

export interface IEnvironmentsGetStarted {
    vendor: string | IVendorGetStarted;
    linkTo: string;
    isReact: boolean;
    title: string;
    cardIndex: number;
}

export interface IVendorGetStarted {
    icon: string;
}
