import React from 'react';
import { SelectOption, ISelectProps } from './Select.types';
import Popover from '../Popover/Popover';
import Input from '../Input/Input';
import List from '../List/List';
import { IListItemProps } from '../List/List.types';
import IconButton from '../IconButton/IconButton';
import Chip from '../Chip/Chip';
import ComponentOverflow from '../ComponentOverflow/ComponentOverflow';
import Label from '../Label/Label';
import Stack from '../Stack/Stack';
import { useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';

const getNoneOptionValue = (value: string) => ({ value, label: value });

const Select = React.forwardRef<HTMLInputElement, ISelectProps>((props, ref) => {
    const {
        fitDropDownToAnchorWidth = true,
        value,
        defaultValue,
        placeholder,
        clearable,
        isMulti,
        options,
        onChange,
        startAdornment,
        endAdornment,
        onInputChange,
        onInputKeyDown,
        disableInputFilter,
        isOptionDisabled,
        valueCellRenderer,
        id,
        onPopoverStateChange,
        ...restProps
    } = props;

    const isDisabled = restProps.disabled || restProps.readOnly;

    const indexedOptions = React.useMemo(() => {
        return options.reduce<{ [value: string]: SelectOption }>((acc, option) => {
            acc[option.value] = option;
            return acc;
        }, {});
    }, [options]);

    const { t } = useTranslation(getCompsI18nNS('common'));

    const inputRef = React.useRef<HTMLInputElement>(null);
    const inputWrapperRef = React.useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedOptions, setSelectedOption] = React.useState<SelectOption[]>(() => {
        if (isMulti) {
            if (defaultValue || value) {
                return (defaultValue || value || []).map((value) => indexedOptions[value] || getNoneOptionValue(value));
            }
        } else {
            if (defaultValue || value) {
                return [defaultValue || value].map(
                    (value) => indexedOptions[value || ''] || getNoneOptionValue(value || ''),
                );
            }
        }
        return [];
    });
    const [inputValue, setInputValue] = React.useState<string>('');

    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    React.useEffect(() => {
        if (value !== undefined) {
            if (isMulti) {
                setSelectedOption(value.map((value) => indexedOptions[value] || getNoneOptionValue(value)));
            } else {
                setSelectedOption([value].map((value) => indexedOptions[value] || getNoneOptionValue(value)));
            }
        }
    }, [isMulti, indexedOptions, value]);

    React.useEffect(() => {
        if (!isOpen) {
            setInputValue('');
            if (onInputChange) {
                onInputChange('');
            }
        } else {
            if (onPopoverStateChange) {
                onPopoverStateChange(isOpen);
            }
        }
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onInputChange, isOpen]);

    const focusInput = React.useCallback(() => {
        if (isDisabled) return;
        inputRef.current?.focus();
    }, [isDisabled]);

    React.useEffect(() => {
        if (isOpen) {
            focusInput();
        }
    }, [isOpen, selectedOptions, focusInput]);

    const handleStateChange = () => {
        setIsOpen((current) => {
            return !current;
        });
    };

    const handleInputClick = () => {
        setIsOpen(true);
    };

    const handleOnStartAdornmentClick = () => {
        if (!isMulti && !isDisabled) {
            handleStateChange();
        }
    };

    const handleOptionClick = React.useCallback(
        (option: IListItemProps) => {
            if (isMulti) {
                const selectedOptionsIndexInState = selectedOptions.findIndex(
                    (selectedOption) => selectedOption.value === option.value,
                );
                let newSelectedOptions = [];
                if (selectedOptionsIndexInState > -1) {
                    newSelectedOptions = selectedOptions.filter(
                        (selectedOption) => selectedOption.value !== option.value,
                    );
                } else {
                    newSelectedOptions = [...selectedOptions, option];
                }
                setSelectedOption(newSelectedOptions);
                onChange &&
                    onChange(
                        newSelectedOptions.map((selectedOption) => selectedOption.value),
                        newSelectedOptions,
                        { option, event: selectedOptionsIndexInState > -1 ? 'removed' : 'added' },
                    );
            } else {
                setSelectedOption([option]);
                onChange && onChange(option.value, option);
                setIsOpen(false);
            }
        },
        [isMulti, selectedOptions, onChange],
    );

    const handleOnClear = () => {
        setSelectedOption([]);
        if (isMulti) {
            onChange && onChange([], [], { option: null, event: 'clearAll' });
        } else {
            if (clearable) {
                onChange && onChange(undefined, null);
            }
        }
    };

    const selectedOptionLabel = React.useMemo(() => {
        if (selectedOptions.length === 0) return undefined;
        if (isMulti) {
            if (valueCellRenderer) {
                const Component = valueCellRenderer;
                return (
                    <Component
                        selectedOptions={selectedOptions}
                        focusInput={focusInput}
                        clearOption={handleOptionClick}
                    />
                );
            }
            return (
                <ComponentOverflow
                    ellipsisMinWidth={70}
                    components={selectedOptions.map((option) => (
                        <Chip
                            key={`option-${option.value}`}
                            size='sm'
                            label={option.label}
                            leadingIconProps={option.labelProps?.leadingIconProps}
                            trailIconProps={option.labelProps?.trailIconProps}
                            disableInteraction
                            onClick={focusInput}
                            closeButton={
                                isDisabled
                                    ? undefined
                                    : {
                                          onClick: () => handleOptionClick(option),
                                      }
                            }
                        />
                    ))}
                />
            );
        }
        if (valueCellRenderer) {
            const Component = valueCellRenderer;
            return <Component selectedOption={selectedOptions[0]} />;
        }
        return <Label {...selectedOptions[0].labelProps} text={selectedOptions[0].label} />;
    }, [isMulti, selectedOptions, valueCellRenderer, isDisabled, handleOptionClick, focusInput]);

    const filteredOptionsWithSelectedAndDisabled = React.useMemo<IListItemProps[]>(() => {
        const newOptions = options
            .filter((option) => {
                if (!inputValue || disableInputFilter) return true;
                if (option.itemType) return false;
                const existInLabel = option.label.toLowerCase().includes(inputValue.toLowerCase());
                const existInValue = option.value.toLowerCase().includes(inputValue.toLowerCase());
                return existInLabel || existInValue;
            })
            .map((option) => {
                const isSelected = selectedOptions.some((selectedOption) => selectedOption.value === option.value);
                return {
                    ...option,
                    disabled: option.disabled || (isOptionDisabled && isOptionDisabled(option, selectedOptions)),
                    selected: isSelected,
                };
            });
        if (newOptions.length === 0) {
            return [
                {
                    label: t('NO_RESULTS'),
                    value: 'no-results',
                    itemType: 'text',
                },
            ];
        }
        return newOptions;
    }, [disableInputFilter, t, options, selectedOptions, inputValue, isOptionDisabled]);

    const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        if (onInputChange) {
            onInputChange(e.target.value);
        }
    };

    const handleOnInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onInputKeyDown) {
            onInputKeyDown(e);
        }
    };

    return (
        <>
            <Input
                data-aid='dropdown-button'
                type='text'
                color='normal'
                id={id}
                {...restProps}
                placeholder={selectedOptions.length > 0 ? '' : placeholder}
                ref={inputRef}
                inputWrapperRef={inputWrapperRef}
                onClick={handleInputClick}
                isActive={isOpen}
                value={inputValue}
                onChange={handleOnInputChange}
                onKeyDown={handleOnInputKeyDown}
                startAdornment={
                    <Stack
                        alignItems='center'
                        direction='row'
                        spacing={2}
                        overflow='hidden'
                        onClick={handleOnStartAdornmentClick}
                    >
                        {startAdornment}
                        {selectedOptionLabel}
                    </Stack>
                }
                endAdornment={
                    <Stack alignItems='center' direction='row' spacing={2}>
                        {endAdornment}
                        {clearable && selectedOptions.length > 0 && (
                            <IconButton
                                onClick={handleOnClear}
                                iconProps={{ name: 'remove' }}
                                size={'small'}
                                disabled={isDisabled}
                                dataAid={`${restProps.dataAid ? `${restProps.dataAid}_clearAll` : 'select_clearAll'}`}
                            />
                        )}
                        <IconButton
                            iconProps={{ name: isOpen ? 'chevronUp' : 'chevronDown', size: 10 }}
                            size='small'
                            disabled={isDisabled}
                            onClick={handleStateChange}
                        />
                    </Stack>
                }
            />
            {!isDisabled && (
                <Popover
                    open={isOpen}
                    anchorEl={inputWrapperRef.current}
                    fitToAnchorWidth={fitDropDownToAnchorWidth}
                    onClose={handleStateChange}
                    placement={'bottom-start'}
                >
                    <List
                        withCheckbox={isMulti}
                        options={filteredOptionsWithSelectedAndDisabled}
                        onOptionClick={handleOptionClick}
                        data-aid={id}
                    />
                </Popover>
            )}
        </>
    );
});
Select.displayName = 'SelectV2';

export default Select;
