import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { IIntegrationsFilterParams, PATH_SETTINGS_PREFIX } from './Integrations';
import { IntegrationDataLoader, IntegrationsDefinition } from './consts';
import { Addin } from '../../../extensibility/AddinRegistry';
import { IFilterProps } from '../../../components/FilterPanel/FilterPanelManager';
import { IEnvironmentsFilter } from '../../assets/Environments';

export const PATH_INTEGRATIONS_OBJECT = buildPath(PATH_SETTINGS_PREFIX, 'integrations');
export const PATH_INTEGRATIONS_DATA_LOADERS = buildPath(PATH_SETTINGS_PREFIX, 'configurations-data-loaders');
export const PATH_INTEGRATIONS_PAGE_TABLE_FILTERS = buildPath(PATH_SETTINGS_PREFIX, 'sentinel-filter-def');

export const IntegrationsRegistry = {
    addIntegrationsDefinition: (integrations: Addin<IntegrationsDefinition>[]) => {
        globalAddinContainer.add(PATH_INTEGRATIONS_OBJECT, [...integrations]);
    },

    getIntegrationsDefinitions: (): IntegrationsDefinition[] => {
        return globalAddinContainer.get<IntegrationsDefinition>(PATH_INTEGRATIONS_OBJECT);
    },

    getConfigurationsDataLoaders: (): Addin<IntegrationDataLoader>[] => {
        return globalAddinContainer.getAddins<IntegrationDataLoader>(PATH_INTEGRATIONS_DATA_LOADERS);
    },

    addConfigurationDataLoader: (dataLoader: Addin<IntegrationDataLoader>) => {
        globalAddinContainer.add(PATH_INTEGRATIONS_DATA_LOADERS, [dataLoader]);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_INTEGRATIONS_PAGE_TABLE_FILTERS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IIntegrationsFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_INTEGRATIONS_PAGE_TABLE_FILTERS, filterId, filterParams);
    },

    getFilterDefs(filters: IEnvironmentsFilter[], params: IIntegrationsFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = IntegrationsRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    },
};
