import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgQuay = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 -10 256 256"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    ref={ref}
    {...props}
  >
    <path
      d="m200.134 0 55.555 117.514-55.555 117.518h-47.295l55.555-117.518L152.84 0h47.295ZM110.08 99.836l20.056-38.092-2.29-8.868L102.847 0H55.552l48.647 102.898 5.881-3.062Zm17.766 74.433-17.333-39.034-6.314-3.101-48.647 102.898h47.295l25-52.88v-7.883Z"
      fill="#40B4E5"
    />
    <path
      d="M152.842 235.032 97.287 117.514 152.842 0h47.295l-55.555 117.514 55.555 117.518h-47.295Zm-97.287 0L0 117.514 55.555 0h47.296L47.295 117.514l55.556 117.518H55.555Z"
      fill="#003764"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgQuay);
export default ForwardRef;
