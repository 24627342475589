import { Card, Spinner } from 'common/design-system/components-v2';
import { LoadingState } from 'common/interface/general';
import React, { JSX, useCallback } from 'react';
import { WidgetWrapperStyled } from './WidgetWrapper.styled';
import { t } from 'i18next';

interface ICardWrapperProps {
    children: React.ReactNode;
    loadingState: LoadingState;
    emptyStateComponent?: JSX.Element;
    title: string;
    error?: string;
}

const WidgetWrapper: React.FC<ICardWrapperProps> = ({ children, loadingState, title, error, emptyStateComponent }) => {
    const getWidgetContent = useCallback((): JSX.Element | null => {
        switch (loadingState) {
            case LoadingState.IS_LOADING:
                return (
                    <WidgetWrapperStyled.LoadingDiv>
                        <Spinner />
                    </WidgetWrapperStyled.LoadingDiv>
                );

            case LoadingState.LOADING_FAILED:
                return (
                    <WidgetWrapperStyled.EmptyOrFailureDiv>
                        {error || t('GENERAL.ERROR_OCCURRED')}
                    </WidgetWrapperStyled.EmptyOrFailureDiv>
                );

            case LoadingState.IS_EMPTY:
                if (emptyStateComponent) {
                    return emptyStateComponent;
                }
                return (
                    <WidgetWrapperStyled.EmptyOrFailureDiv>
                        {t('COMMON.NO_RESULTS')}
                    </WidgetWrapperStyled.EmptyOrFailureDiv>
                );

            case LoadingState.LOADING_SUCCEEDED:
                return <WidgetWrapperStyled.WidgetContentDiv>{children}</WidgetWrapperStyled.WidgetContentDiv>;

            // This is a fallback case, it should never be reached
            default:
                return null;
        }
    }, [loadingState, error, children, emptyStateComponent]);

    return (
        <WidgetWrapperStyled.TopDiv>
            <Card title={title}>{getWidgetContent()}</Card>
        </WidgetWrapperStyled.TopDiv>
    );
};

export default WidgetWrapper;
