import React, { forwardRef } from 'react';
import { ILabelProps } from './Label.types';
import Stack from '../Stack';
import Typography, { ITypographyProps } from '../Typography';
import Tooltip from '../Tooltip';
import LabelStyles from './Label.styles';
import Icon from '../Icon/Icon';
import { IStackProps } from '../Stack/Stack.types';

const Asterisk = (
    <svg data-aid='asterisk-svg' xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.49485 3.34382C4.44396 3.46669 4.50204 3.52512 4.62516 3.47414L5.99043 2.90862C6.10504 2.86114 6.23587 2.91531 6.28314 3.02943C6.3323 3.14812 6.27801 3.27932 6.1636 3.32672L4.79834 3.89224C4.67547 3.94312 4.67521 4.02552 4.79834 4.07651L6.1636 4.64203C6.27821 4.68951 6.33243 4.82034 6.28516 4.93442C6.236 5.05315 6.10482 5.10752 5.99042 5.06012L4.62516 4.4946C4.50229 4.44372 4.44386 4.50179 4.49484 4.62492L5.06036 5.99019C5.10784 6.10479 5.05367 6.23563 4.93956 6.28289C4.82086 6.33206 4.68966 6.27776 4.64226 6.16336L4.07674 4.79809C4.02586 4.67522 3.94346 4.67496 3.89247 4.79809L3.32695 6.16336C3.27948 6.27797 3.14864 6.33218 3.03456 6.28492C2.91583 6.23575 2.86146 6.10458 2.90886 5.99018L3.47438 4.62491C3.52526 4.50204 3.46719 4.44361 3.34406 4.4946L1.9788 5.06012C1.86419 5.10759 1.73336 5.05343 1.68609 4.93931C1.63692 4.82061 1.69122 4.68942 1.80562 4.64202L3.17089 4.0765C3.29376 4.02561 3.29402 3.94322 3.17089 3.89222L1.80562 3.3267C1.69102 3.27923 1.6368 3.1484 1.68406 3.03431C1.73323 2.91559 1.8644 2.86121 1.9788 2.90861L3.34407 3.47413C3.46694 3.52502 3.52537 3.46694 3.47438 3.34382L2.90886 1.97855C2.86139 1.86394 2.91556 1.73311 3.02967 1.68584C3.14837 1.63668 3.27956 1.69098 3.32696 1.80538L3.89248 3.17064C3.94337 3.29351 4.02576 3.29377 4.07676 3.17064L4.64228 1.80538C4.68975 1.69077 4.82058 1.63655 4.93467 1.68382C5.0534 1.73298 5.10777 1.86416 5.06037 1.97856L4.49485 3.34382Z'
            fill='#F24E4E'
        />
    </svg>
);

type TTypographyVariant = (size: ILabelProps['size']) => ITypographyProps['variant'];
const typographyVariant: TTypographyVariant = (size) => {
    switch (size) {
        case 'sm':
            return 'bodyXs';
        case 'xl':
            return 'subtitleLg';
        case 'lg':
            return 'bodyLg';
        default:
            return 'body';
    }
};

const Label = forwardRef<HTMLDivElement, ILabelProps>((props, ref) => {
    const {
        size = 'md',
        color = 'light',
        whiteSpace = 'nowrap',
        required = false,
        leadingIconProps,
        trailIconProps,
        tooltip,
        interactiveTooltip,
        text,
        disabled,
        tooltipIconSize,
        showDefaultTooltip = true,
        ...rest
    } = props;

    const stackProps = React.useMemo<Partial<IStackProps>>(() => {
        switch (size) {
            case 'sm':
                return {
                    spacing: 0.5,
                };
            default:
                return {
                    spacing: 1,
                };
        }
    }, [size]);

    const showTitleTooltip = !tooltip && showDefaultTooltip;

    return (
        <LabelStyles.Wrapper
            {...stackProps}
            alignItems='center'
            direction='row'
            color={color}
            disabled={disabled}
            whiteSpace={whiteSpace}
            ref={ref}
            {...rest}
        >
            {leadingIconProps && <Icon {...leadingIconProps} />}
            <LabelStyles.LabelWrapper direction='row'>
                <label>
                    <Typography
                        color={color}
                        variant={typographyVariant(size)}
                        type='key'
                        title={showTitleTooltip ? text.toString() : ''}
                        ellipsis
                    >
                        {text}
                    </Typography>
                </label>
                {required && Asterisk}
            </LabelStyles.LabelWrapper>
            {trailIconProps && <Icon {...trailIconProps} />}
            {tooltip && (
                <Tooltip content={tooltip} interactive={interactiveTooltip}>
                    <Stack justifyContent='center' alignItems='center' style={{ zIndex: 1 }}>
                        <Icon name='info' size={tooltipIconSize || 16} color='brandPrimary' />
                    </Stack>
                </Tooltip>
            )}
        </LabelStyles.Wrapper>
    );
});
Label.displayName = 'Label';

export default Label;
