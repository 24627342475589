import { initializeFilterPanelPage } from './components/FilterPanel/FilterPanelExamplePage/initialize';
import { getRuntimeEnvironmentType, RuntimeEnvironmentType } from './utils/RuntimeEnvironment';
import { initializeVendors } from './consts/vendors';
import { initializeFilterPanelSlice } from './components/FilterPanel/FilterPanelManager';
import { initializeProtectedAssets } from './components/ProtectedAssets/initialize';
import { initializeComponentsI18n } from './design-system/initialize.i18n';
import { initializeDebugActiveFeatures } from './utils/debugUtils';
import { initializeErmComponents } from './erm-components/ermComponents.initialize';
import { initializeErmComponentsActiveFeatures } from './erm-components/utils/ActiveFeatures/ermComponents.af';
import { initializeDrawerExamples } from './components/DrawerInfra/DrawerExample/DrawerExampleInitialize';

function debugInitialize() {
    initializeFilterPanelPage();
}

export default function initialize() {
    const envType: RuntimeEnvironmentType = getRuntimeEnvironmentType();
    if (envType === RuntimeEnvironmentType.LOCAL) {
        debugInitialize();
    }
    initializeFilterPanelSlice();
    initializeVendors();
    initializeProtectedAssets();
    initializeComponentsI18n();
    initializeDrawerExamples();
    initializeDebugActiveFeatures();
    initializeErmComponentsActiveFeatures();
    initializeErmComponents();
}
