import React from 'react';
import { FILTER_BOX_DISPLAY_TYPES, FILTER_DISPAY_TYPES, FILTER_EVENTS } from '../../FilterPanel.consts';
import { IExtendTextFilter } from '../DefaultFilters.interface';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { isIncludeSearchTerm } from '../../filterUtils';
import { Icon, Input } from 'common/design-system/components-v2';
import { ILastEvent } from '../../FilterPanel.interface';

const GeneralFreeText: React.FC<{ value: string; filterKey: string; onEvent: (lastEvent: ILastEvent) => any }> = ({
    value,
    filterKey,
    onEvent,
}) => {
    const { t } = useTranslation();
    const debounce = React.useRef<NodeJS.Timeout | null>(null);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (debounce.current) clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
            onEvent({ action: FILTER_EVENTS.FILTER_CHANGED, filterKey: filterKey, payload: text });
        }, 300);
    };

    return (
        <Input
            clearable
            fullWidth
            startAdornment={<Icon name='magnify' />}
            data-aid={`free-text-filter-${filterKey}`}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChange(event)}
            placeholder={t('FILTER_PANEL.SEARCH')}
        />
    );
};
export const FreeTextFilter: React.FC<{ filterProps: IExtendTextFilter }> = ({ filterProps }) => {
    const { t } = useTranslation();
    const { value, key, onEvent, displayType, title, searchTerm } = filterProps;

    const renderRowViewFreeText = () => {
        return (
            <div className='filter-panel__free-text-filter w-[120px] sm:w-[120px] xl:w-[200px]'>
                <GeneralFreeText value={value} filterKey={key} onEvent={onEvent}></GeneralFreeText>
            </div>
        );
    };

    const renderSeparateViewFreeText = () => {
        return (
            <div className='flex flex-column mb-7'>
                <div className='font-semibold mb-3'>{t('FILTER_PANEL.FREE_TEXT_FILTER.FREE_TEXT_LABEL')}:</div>
                <div className='inline-block border border-inherit mr-4 max-w-[170px] min-w-[120px] sm:max-w-[170px] xl:max-w-[200px]'>
                    <GeneralFreeText value={value} filterKey={key} onEvent={onEvent}></GeneralFreeText>
                </div>
            </div>
        );
    };

    const renderBoxViewFreeText = () => {
        return (
            shouldShowFilterBySearch() && (
                <Accordion
                    title={title}
                    content={<GeneralFreeText value={value} filterKey={key} onEvent={onEvent}></GeneralFreeText>}
                />
            )
        );
    };

    const renderView = () => {
        switch (displayType) {
            case FILTER_DISPAY_TYPES.SEPARATE_FIELDS:
                return renderSeparateViewFreeText();
            case FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1:
            case FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2:
                return renderBoxViewFreeText();
            case FILTER_DISPAY_TYPES.ROW:
                return renderRowViewFreeText();
        }
    };

    const shouldShowFilterBySearch = () => {
        return searchTerm ? isIncludeSearchTerm(searchTerm, title) || isIncludeSearchTerm(searchTerm, value) : true;
    };
    return <>{renderView()}</>;
};

export default FreeTextFilter;
