import { IDrawerHandlersIdMap, IDrawerWidgetUrlParams } from './Drawer.interface';
import { changeUrlParam } from '../../../utils/http';
import { drawerManager } from './DrawerManager';

export const DRAWER_URL_KEY = 'drawer';

export const changeDrawerUrlParam = (urlParams: IDrawerWidgetUrlParams) => {
    changeUrlParam(DRAWER_URL_KEY, urlParams);
};

export const removeDrawerUrlParam = () => {
    changeUrlParam(DRAWER_URL_KEY);
};

export const getDrawerUrlParamsStr = (search: string | undefined): string | undefined => {
    const searchParams = search ? new URLSearchParams(search) : undefined;
    return searchParams ? searchParams.get(DRAWER_URL_KEY) || undefined : undefined;
};

export const getDrawerUrlParams = (search: string | undefined): IDrawerWidgetUrlParams | undefined => {
    const drawerUrl: string | undefined = getDrawerUrlParamsStr(search);
    if (!drawerUrl) {
        return undefined;
    }
    try {
        return JSON.parse(drawerUrl);
    } catch (e) {
        return undefined;
    }
};

export const isEqualUrlParams = (
    obj1: IDrawerWidgetUrlParams | undefined,
    obj2: IDrawerWidgetUrlParams | undefined,
): boolean => {
    if (!obj1) {
        return !obj2;
    }

    if (!obj2) {
        return false;
    }

    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const showDrawer = (widgetId: string, widgetData?: any, eventHandlersIdMap?: IDrawerHandlersIdMap) => {
    drawerManager.showDrawer(widgetId, widgetData, eventHandlersIdMap);
};

export const closeDrawer = (closeAll?: boolean) => {
    drawerManager.closeTopDrawer(closeAll);
};
