import { getService } from '../extensibility/AddinContainer';
import { ICloudEntity } from '../module_interface/assets/ICloudEntity';
import { ICachingConfig } from './services';
import { AxiosError } from 'axios';
import { IAssignUser } from './user';
import { IVendorRegion } from '../consts/vendors';
import { GenericObject } from './general';

export const ORGANIZATIONAL_UNIT_SERVICE_ID = 'OrganizationalUnitService';

export interface IOrganizationalUnit {
    id: string;
    name: string;
    path: string;
    children?: IOrganizationalUnit[];
}

export interface IOrganizationalUnitAllChildrens extends IOrganizationalUnit {
    allChildrens: IOrganizationalUnit[];
}

export interface IOrganizationalUnitTreeNode {
    children: IOrganizationalUnitTreeNode[];
    item: IOrganizationalUnitData;
    parentId: string | null;
}

export interface IOrganizationalUnitData {
    accountId: number;
    id: string;
    name: string;
    path: string | string[] | null;
    parentId: string | null;
    created: string;
    updated: string;
    awsCloudAcountsCount: number;
    azureCloudAccountsCount: number;
    googleCloudAccountsCount: number;
    k8sCloudAccountsCount: number;
    shiftLeftCloudAccountsCount: number;
    alibabaCloudAccountsCount: number;
    containerRegistryAccountsCount: number;
    awsAggregatedCloudAcountsCount: number;
    azureAggregateCloudAccountsCount: number;
    googleAggregateCloudAccountsCount: number;
    k8sAggregateCloudAccountsCount: number;
    shiftLeftAggregateCloudAccountsCount: number;
    alibabaAggregateCloudAccountsCount: number;
    containerRegistryAggregateCloudAccountsCount: number;
    subOrganizationalUnitsCount: number;
    isRoot: boolean;
    isParentRoot: boolean;
    pathStr: string | null;
}

export interface IOrganizationalUnitService {
    getAllOrganizationalUnits(): Promise<IOrganizationalUnitTreeNode[]>;

    getOrganizationalUnitsView(useCache?: boolean): Promise<IOrganizationalUnit>;

    getAllOrganizationalUnitsFlat(): Promise<IOrganizationalUnit[]>;

    createOrganizationalUnit(name: string, parentId: string): Promise<string>;

    updateOrganizationalUnit(id: string, name: string, parentId: string): Promise<void>;

    deleteOrganizationalUnit(id: string, force: boolean): Promise<void>;

    getOrganizationalUnitPathById(id: string): Promise<IOrganizationalUnitTreeNode[] | null>;

    getOrganizationalUnitById(id: string): Promise<IOrganizationalUnit | null>;

    getAllOrganizationalUnitsFlatWithPath(includingRoot?: boolean): Promise<IOrganizationalUnit[]>;

    getAllOrganizationalUnitsFlatWithAllChildrens(includingRoot?: boolean): Promise<IOrganizationalUnitAllChildrens[]>;

    getAllOrganizationalUnitsFlatWithCounts(useCache?: boolean): Promise<IOrganizationalUnitData[]>;

    getFlatOrgUnitsBySelection(selectedIds: string[]): Promise<IOrganizationalUnitData[]>;

    getFlatOrgUnitIdsBySelection(selectedIds: string[]): Promise<string[]>;
}

export function getOrganizationalUnitService(): IOrganizationalUnitService {
    return getService<IOrganizationalUnitService>(ORGANIZATIONAL_UNIT_SERVICE_ID);
}

export const CLOUD_ACCOUNTS_SERVICE_ID = 'CloudAccountsService';

export interface ICloudAccount {
    creationDate: string;
    id: string;
    externalId: string;
    name: string;
    description?: string;
    platform: string;
    subplatform: string;
    organizationalUnitId: string;
    isLogicEnabled?: boolean;
    isLogicCloudTrailEnabled?: boolean;
    isLogicTrafficActivityOnboarded?: boolean;
    isLogicEventActivityOnboarded?: boolean;
    isCloudbotsOnboarded?: boolean;
    isLogicGuardDutyOnboarded?: boolean;
    logicUsedDataInBytes?: number;
    logicCloudTrailUsedDataInBytes?: number;
    logicFlowTimestamp?: string;
    logicTrailTimestamp?: string;
    credentialId: string;
    regions?: GenericObject<IVendorRegion>;
}

export interface IIntelligenceCloudAccount {
    cloudAccountId: string;
    accountId: string;
    accountName: string;
    flowlogsPool: null;
    cloudtrailPool: null;
    flowlogsEnabled: boolean;
    cloudtrailEnabled: boolean;
    guarddutyEnabled: boolean;
    isAlertEnabled: boolean;
    isAzureFlEnabled: boolean;
    isAzureActivityEnabled: boolean;
    isAlibabaActionTrailEnabled: boolean;
    isGcpActivityEnabled: boolean;
    isGcpFlEnabled: boolean;
    poolAzureFl: string;
    azureStorageAccountMapping: null;
    roleArn: null;
    roleExternalId: null;
    k8sFlowlogsEnabled: boolean;
    k8sCloudTrailEnabled: boolean;
    azureEncryptSecret: null;
}

export interface ICloudAccountsService {
    getAllCloudAccounts(useCache?: boolean): Promise<ICloudAccount[]>;

    getAllPlatforms(): Promise<any>;

    getAllCloudAccountsCount(): Promise<any>;

    getUnmonitoredCloudAccountsCount(): Promise<any>;

    getEnvironmentSyncStatus(
        useCache?: boolean,
        customHandleError?: (error: AxiosError<ICloudAccountSyncStatus[]>) => ICloudAccountSyncStatus[],
    ): Promise<ICloudAccountSyncStatus[]>;

    getCloudAccountByAccountId(cloudAccountId: string): Promise<ICloudAccount | null>;

    getIndexedCloudAccounts(): Promise<{ [cloudAccountId: string]: ICloudAccount }>;

    clearCache(): Promise<void>;

    subscribe(callback: (allCloudAccounts: ICloudAccount[]) => void): string;

    unsubscribe(subscribeKey: string): void;

    getStatsV2(useCache?: boolean): Promise<ICloudAccountStateV2>;

    getAllUsers(): Promise<IAssignUser[]>;
}

export function getCloudAccountsService(): ICloudAccountsService {
    return getService<ICloudAccountsService>(CLOUD_ACCOUNTS_SERVICE_ID);
}

export const ENTITY_SERVICE_ID = 'EntityService';

export interface IEntityService {
    getEntity(
        id: string,
        entityType: string,
        platform: string,
        cloudAccountId: string,
        cachingConfig?: ICachingConfig,
        customErrHandler?: (error: AxiosError<ICloudEntity>) => ICloudEntity,
    ): Promise<ICloudEntity | null>;
}

export interface IAggregationDataItem {
    displayName?: string;
    value: any;
    count: number;
}

export interface IGroupByDataItem {
    fieldName: string;
    fieldValue: string;
    nestedBuckets: IGroupByDataItem[];
    numberOfDocuments: number;
}

export interface ICloudAccountSyncStatus {
    id: string;
    platform: string;
    hasIssues: boolean;
}

export interface ICloudAccountStateV2 {
    awsCloudAccounts: CloudAccountStateV2AwsCloudAccount[];
    azureCloudAccounts: CloudAccountStateV2AzureCloudAccount[];
    ociCloudAccounts: CloudAccountStateV2OciCloudAccounts[];
    googleCloudAccounts: CloudAccountStateV2GoogleCloudAccount[];
    kubernetesAccounts: CloudAccountStateV2KubernetesAccount[];
}

interface CloudAccountStateV2Base {
    cloudAccountId: string;
    cloudAccountName: string;
    type: string;
    magellan: Magellan;
    accountId: number;
    timeStamp: string;
}

interface CloudAccountStateV2OciCloudAccounts extends CloudAccountStateV2Base {
    tenancyId: string;
    onlineVirtualMachinesCount: number;
    onlineBillableVirtualMachinesCount: number;
}

interface CloudAccountStateV2KubernetesAccount extends CloudAccountStateV2Base {
    projectId: string;
    nodesCount: number;
    inactiveNodesCount: number;
    billableNodesCount: number;
    premiumBillableNodesCount: number;
    podsCount: number;
}

interface CloudAccountStateV2GoogleCloudAccount extends CloudAccountStateV2Base {
    projectId: string;
    onlineVirtualMachinesCount: number;
    onlineBillableVirtualMachinesCount: number;
    firewallRuleCount: number;
    gkeCount: number;
}

interface CloudAccountStateV2AzureCloudAccount extends CloudAccountStateV2Base {
    securityGroupsCount: number;
    onlineVirtualMachinesCount: number;
    onlineBillableVirtualMachinesCount: number;
    loadBalancersCount: number;
    subscriptionId: string;
    tenantId: string;
    azureDbInstances: number;
    azureSqlInstances: number;
    nicsCount: number;
    acrCount: number;
    aksCount: number;
}

interface CloudAccountStateV2AwsCloudAccount extends CloudAccountStateV2Base {
    onlineInstancesCount: number;
    onlineBillableInstancesCount: number;
    onlineNonBillableInstancesCount: number;
    elbsCount: number;
    dbInstancesCount: number;
    billableDbInstancesCount: number;
    nonBillableDbInstancesCount: number;
    vpcsCount: number;
    naclsCount: number;
    subnetsCount: number;
    securityGroupsCount: number;
    lambdaFunctionsCount: number;
    cloudBillableLambdaFunctions: number;
    cloudLambdaFunctionProtectedFspCount: number;
    cloudLambdaFunctionProtectedCount: number;
    cloudLambdaFunctionLatestFspInvocationsCount: number;
    cloudLambdaFunctionProtected: boolean;
    albsCount: number;
    nlbsCount: number;
    redshiftsCount: number;
    iamUsersCount: number;
    iamRolesCount: number;
    kmsCount: number;
    s3BucketsCount: number;
    efsCount: number;
    hasIamSafe: boolean;
    isGovCloud: boolean;
    iamSafe: IamSafe;
    hybridAgents: number;
    externalAccountNumber: string;
    nicsCount: number;
    eksCount: number;
    ecrCount: number;
    isMagellanEnabled: boolean;
}

interface Magellan {
    isEnabled: number;
    isTrafficActivityOnboarded: number;
    isEventActivityOnboarded: number;
    totalUsageInGb: number;
    cloudtrailTotalUsageInGb: number;
}

interface IamSafe {
    restrictedIamUsersCount: number;
    restrictedIamRolesCount: number;
    protectedIamUsersCount: number;
    protectedIamRolesCount: number;
}
