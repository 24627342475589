import React, { forwardRef } from 'react';
import { TagsProps, Tag } from './Tags.types';
import { TextField } from '@dome9/berries/react-components';
import { Button, Stack } from 'common/design-system/components-v2';

const Tags: React.FunctionComponent<TagsProps> = forwardRef<HTMLDivElement, TagsProps>(
    ({ tags, onChange, errors }, ref) => {
        const handleDelete = (index: number) => {
            onChange(tags.filter((_, i) => i !== index));
        };

        const handleAdd = () => {
            onChange([...tags, { key: '', value: '' }]);
        };

        const handleTagChange = (index: number, tag: Tag) => {
            const newTags = [...tags];
            newTags[index] = tag;
            onChange(newTags);
        };

        return (
            <Stack spacing={2} ref={ref}>
                {tags.map((tag, index) => (
                    <Stack key={`tag-${index}`} spacing={2} direction='row' justifyContent='space-between'>
                        <Stack fullWidth>
                            <TextField
                                value={tag.key}
                                label={index === 0 ? 'Key' : undefined}
                                onChange={(e) => handleTagChange(index, { ...tag, key: e.target.value })}
                                allowClear={false}
                                state={errors?.[index]?.key ? 'error' : 'success'}
                                helper={errors?.[index]?.key}
                            />
                        </Stack>
                        <Stack fullWidth>
                            <TextField
                                value={tag.value}
                                label={index === 0 ? 'Value' : undefined}
                                onChange={(e) => handleTagChange(index, { ...tag, value: e.target.value })}
                                allowClear={false}
                                state={errors?.[index]?.value ? 'error' : 'success'}
                                helper={errors?.[index]?.value}
                            />
                        </Stack>
                        <Stack margin={index === 0 ? [5, 0, 0, 0] : undefined}>
                            <Button iconProps={{ name: 'delete' }} variant='text' onClick={() => handleDelete(index)} />
                        </Stack>
                    </Stack>
                ))}
                <Button onClick={handleAdd} iconProps={{ name: 'plus' }} dataAid='Add'>
                    Add
                </Button>
            </Stack>
        );
    },
);

Tags.displayName = 'Tags';
export default Tags;
