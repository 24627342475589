import React from 'react';
import i18n from 'i18next';
import {
    ColumnApi,
    ColumnState,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    ITooltipParams,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import Stack from '../Stack';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import TableStyles from './Table.styles';
import { ITableProps } from './Table.types';
import ColumnsSelect from './components/ColumnsSelect/ColumnsSelect';
import Button from '../Button/Button';
import GroupBySelect from './components/GroupBySelect/GroupBySelect';
import TooltipStyled from 'common/design-system/components-v2/Tooltip/Tooltip.styled';
import Dropdown from '../Dropdown/Dropdown';
import List from '../List/List';
import Actions from './components/Actions';
import Spinner from '../Spinner/Spinner';
import Footer from './components/Footer/Footer';
import Typography from '../Typography/Typography';
import ExportToCsv from './components/ExportToCsv/ExportToCsv';
import { ExportToCsvProps } from './components/ExportToCsv/ExportToCsv.types';
import { calculateExportToCsvProps } from './components/ExportToCsv/ExportToCsv.util';

const DefaultTooltip = ({ value, valueFormatted }: ITooltipParams) => {
    return <TooltipStyled.StyledTooltip>{valueFormatted || value}</TooltipStyled.StyledTooltip>;
};

const CustomLoadingOverlay = () => {
    return (
        <div data-aid='loading-overlay'>
            <Spinner size={48} context='info' saturation />
        </div>
    );
};

const CustomNoDataRenderer = () => {
    return i18n.t('NO_ITEMS', { ns: getCompsI18nNS('table') });
};

const CustomLoadingCellRenderer = (props: ICellRendererParams) => {
    const isEmpty = props.api.getDisplayedRowAtIndex(0)?.data === undefined;
    const isError = props.node?.failedLoad;

    let toRender: React.ReactNode = <Spinner size={16} context='info' saturation />;
    if (isError) {
        toRender = <Typography>{i18n.t('LOAD_ERROR', { ns: getCompsI18nNS('table') })}</Typography>;
    } else if (isEmpty) {
        toRender = <span />;
    }

    return (
        <Stack alignItems='center' justifyContent='center' fullWidth>
            {toRender}
        </Stack>
    );
};

const hasRowDataChanges = <T = any,>(oldRowData: T[], newRowData: T[]): boolean => {
    if (oldRowData.length !== newRowData.length) {
        return true;
    }

    return oldRowData.some((oldRow: T, index: number) => {
        const oldRowString: string = JSON.stringify(oldRow);
        const newRowString: string = JSON.stringify(newRowData[index]);
        return oldRowString !== newRowString;
    });
};

const defaultPageSize = 50;
const tableLocalStorageKey = 'tableColumnsState';
function Table<T = any>(props: React.PropsWithChildren<ITableProps<T>>) {
    const {
        tableId,
        saveColumnsState,
        pageSize = defaultPageSize,
        actions = [],
        exportButtons,
        exportToCsvProps,
        gridOptions,
        footer,
        disableColumnMenu = false,
        disableGrouping = false,
        appliedFilters,
        disableRowsResetOnRowDataChange,
        disableRowsResetOnFilterChange,
        isLoading,
        isFetching,
        currentRows,
        totalRows,
        footerSuffix,
        ellipsisRowGroup,
        gridThemeOverride,
    } = props;

    React.useEffect(() => {
        if (saveColumnsState && !tableId) {
            throw new Error('Table component: You must provide a tableId when saveColumnsState is true');
        }
    }, [tableId, saveColumnsState]);

    const LOCAL_STORAGE_COLUMNS_SAVED_STATE = `ENVIRONMENTS_TABLE_${tableId}__COLUMNS_STATE`;

    const {
        onGridReady,
        onSelectionChanged,
        defaultColDef,
        onSortChanged,
        onColumnResized,
        onColumnMoved,
        onColumnVisible,
        onColumnRowGroupChanged,
        onFilterChanged,
        ...restGridOptions
    } = gridOptions || {};

    const { t } = useTranslation(getCompsI18nNS('table'));
    const apiRef = React.useRef<GridApi>();
    const columnApiRef = React.useRef<ColumnApi>();
    const [selectedRows, setSelectedRows] = React.useState<T[]>([]);
    const [gridReady, setGridReady] = React.useState<boolean>(false);
    const [exportOpen, setExportOpen] = React.useState<boolean>(false);
    const [displayedColumnsCount, setDisplayedColumnsCount] = React.useState<number>(0);

    const appliedFiltersString = React.useRef<string>(appliedFilters ? JSON.stringify(appliedFilters) : '');
    const rowDataRef = React.useRef<T[]>(gridOptions?.rowData || []);
    const overlayDebounce = React.useRef<NodeJS.Timeout | null>(null);

    const loadingCellRenderer = React.useMemo(() => {
        return CustomLoadingCellRenderer;
    }, []);

    const loadingOverlayComponent = React.useMemo(() => {
        return CustomLoadingOverlay;
    }, []);

    const handleOnSelectionChanged = (params: SelectionChangedEvent<T>) => {
        const selectedItems = params.api.getSelectedRows();
        onSelectionChanged?.(params);
        setSelectedRows(selectedItems);
    };

    const getCurrentSavedState = React.useCallback<() => { [tableId: string]: ColumnState[] | undefined }>(() => {
        if (!tableId || !saveColumnsState) return;
        const columnsSavedState = localStorage.getItem(tableLocalStorageKey);
        const parsedColumnsSavedState = columnsSavedState ? JSON.parse(columnsSavedState) : {};
        return parsedColumnsSavedState;
    }, [saveColumnsState, tableId]);

    const saveColumnsStateToLocalStorage = React.useCallback(() => {
        if (!tableId || !saveColumnsState) return;
        const currentColumnsState = columnApiRef.current?.getColumnState();
        const columnsSavedState = getCurrentSavedState();
        columnsSavedState[LOCAL_STORAGE_COLUMNS_SAVED_STATE] = currentColumnsState;
        localStorage.setItem(tableLocalStorageKey, JSON.stringify(columnsSavedState));
    }, [LOCAL_STORAGE_COLUMNS_SAVED_STATE, getCurrentSavedState, saveColumnsState, tableId]);

    const loadSavedColumnsStateFromLocalStorage = React.useCallback(() => {
        if (!tableId || !saveColumnsState) return;
        const columnsSavedState = getCurrentSavedState();
        const columnsState = columnsSavedState[LOCAL_STORAGE_COLUMNS_SAVED_STATE];
        if (!columnsState) return;
        columnApiRef.current?.applyColumnState({ state: columnsState, applyOrder: true });
    }, [LOCAL_STORAGE_COLUMNS_SAVED_STATE, getCurrentSavedState, saveColumnsState, tableId]);

    const handleOnGridReady = React.useCallback(
        (params: GridReadyEvent<any>) => {
            params.api.closeToolPanel();
            apiRef.current = params.api;
            columnApiRef.current = params.columnApi;
            if (saveColumnsState) {
                loadSavedColumnsStateFromLocalStorage();
            }

            onGridReady?.(params);
            setGridReady(true);
        },
        [loadSavedColumnsStateFromLocalStorage, onGridReady, saveColumnsState],
    );

    const defaultGridOptions: GridOptions<any> = {
        animateRows: true,
        cacheBlockSize: pageSize,
        rowBuffer: 10,
        maxConcurrentDatasourceRequests: 1,
        infiniteInitialRowCount: 1,
        overlayNoRowsTemplate: i18n.t('NO_ITEMS', { ns: getCompsI18nNS('table') }),
        suppressContextMenu: true,
        blockLoadDebounceMillis: 100,
        defaultColDef: {
            tooltipComponent: DefaultTooltip,
            resizable: true,
            suppressMenu: true,
            sortable: false,
            minWidth: 60,
            enableRowGroup: false,
            ...(defaultColDef || {}),
        },
        rowGroupPanelShow: 'never',
        rowMultiSelectWithClick: false,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        skipHeaderOnAutoSize: true,
        enableRangeSelection: false,
        suppressCellFocus: true,
        suppressCopyRowsToClipboard: true,
        suppressRowClickSelection: true,
        suppressPropertyNamesCheck: true,
        detailRowAutoHeight: true,
        suppressDragLeaveHidesColumns: true,
        suppressBrowserResizeObserver: true,
        rowHeight: 48,
        onSelectionChanged: handleOnSelectionChanged,
        isRowSelectable: (node) => !node.group && !node.data?.['customData|unselectable'], // disable selection on group rows or rows with unselectable flag
        onSortChanged: (params) => {
            onSortChanged?.(params);
            params.api.deselectAll();
            saveColumnsStateToLocalStorage();
        },
        onColumnResized: (params) => {
            onColumnResized?.(params);
            saveColumnsStateToLocalStorage();
        },
        onColumnMoved: (params) => {
            onColumnMoved?.(params);
            saveColumnsStateToLocalStorage();
        },
        onColumnVisible: (params) => {
            onColumnVisible?.(params);
            saveColumnsStateToLocalStorage();
        },
        onColumnRowGroupChanged: (params) => {
            onColumnRowGroupChanged?.(params);
            params.api.deselectAll();
        },
        onFilterChanged: (params) => {
            onFilterChanged?.(params);
            if (!disableRowsResetOnFilterChange) {
                params.api.deselectAll();
            }
        },
        onDisplayedColumnsChanged: (params) => {
            setDisplayedColumnsCount(params.columnApi.getAllDisplayedColumns().length);
        },
        ...restGridOptions,
    };

    React.useEffect(() => {
        if (isLoading === undefined && isFetching === undefined) return;

        if (overlayDebounce.current) clearTimeout(overlayDebounce.current);

        const loading = isLoading || isFetching;
        overlayDebounce.current = setTimeout(
            () => {
                if (loading && currentRows === 0) {
                    apiRef.current?.showLoadingOverlay();
                } else if (currentRows === 0) {
                    apiRef.current?.showNoRowsOverlay();
                } else {
                    apiRef.current?.hideOverlay();
                }
            },
            loading ? 0 : 500,
        );
    }, [apiRef, isFetching, currentRows, isLoading]);

    React.useEffect(() => {
        const newRowData: T[] = gridOptions?.rowData || [];
        if (hasRowDataChanges(rowDataRef.current, newRowData) && !disableRowsResetOnRowDataChange) {
            apiRef.current?.deselectAll();
            rowDataRef.current = newRowData;
        }
    }, [gridOptions?.rowData, disableRowsResetOnRowDataChange]);

    React.useEffect(() => {
        const newAppliedFiltersString = appliedFilters ? JSON.stringify(appliedFilters) : '';
        if (newAppliedFiltersString !== appliedFiltersString.current) {
            apiRef.current?.deselectAll();
            appliedFiltersString.current = newAppliedFiltersString;
        }
    }, [appliedFilters]);

    const toolbarActive = React.useMemo(() => {
        return (
            gridReady &&
            (!disableGrouping ||
                actions.length > 0 ||
                !disableColumnMenu ||
                (exportButtons && exportButtons.length > 0))
        );
    }, [gridReady, actions, disableColumnMenu, disableGrouping, exportButtons]);

    const exportToCsvCalculatedProps: ExportToCsvProps | undefined = React.useMemo(() => {
        if (!exportToCsvProps) return undefined;
        return calculateExportToCsvProps(exportToCsvProps, currentRows, totalRows, displayedColumnsCount, columnApiRef);
    }, [exportToCsvProps, currentRows, totalRows, displayedColumnsCount]);

    return (
        <TableStyles.Wrapper ellipsisRowGroup={ellipsisRowGroup} fullHeight fullWidth data-aid='DS_Table'>
            {toolbarActive && (
                <Stack
                    justifyContent='space-between'
                    alignItems='center'
                    direction='row'
                    spacing={5}
                    padding={[2, 0]}
                    overflow='hidden'
                    data-aid='DS_Table_toolbar'
                >
                    <Actions
                        gridApi={apiRef.current}
                        columnApi={columnApiRef.current}
                        actions={actions}
                        selectedRows={selectedRows}
                    />
                    <Stack alignItems='center' direction='row' spacing={2}>
                        {!disableGrouping && (
                            <GroupBySelect
                                gridApi={apiRef.current}
                                columnApi={columnApiRef.current}
                                isLoading={isLoading}
                            />
                        )}
                        <Stack direction='row' alignItems='center'>
                            {!disableColumnMenu && (
                                <ColumnsSelect
                                    gridApi={apiRef.current}
                                    columnApi={columnApiRef.current}
                                    isLoading={isLoading}
                                />
                            )}
                            {exportButtons && exportButtons.length > 1 && (
                                <TableStyles.DropdownWrapper>
                                    <Dropdown
                                        label={t('TOOLBAR.EXPORT')}
                                        buttonProps={{
                                            iconProps: { name: 'export' },
                                            dataAid: 'export',
                                            disabled: isLoading,
                                            id: 'DS_Table_Export-btn',
                                        }}
                                        placement='bottom-start'
                                        onStateChange={(state) => setExportOpen(state)}
                                        open={exportOpen}
                                    >
                                        <List
                                            options={exportButtons.map((exportButton) => ({
                                                label: exportButton.label,
                                                value: exportButton.label,
                                                onClick: (e) => {
                                                    exportButton.onClick(e);
                                                    setExportOpen(false);
                                                },
                                                labelProps: { leadingIconProps: exportButton.icon },
                                                disabled: exportButton.disabled,
                                                tooltip: exportButton.tooltip,
                                            }))}
                                        />
                                    </Dropdown>
                                </TableStyles.DropdownWrapper>
                            )}
                            {exportButtons && exportButtons.length === 1 && (
                                <Button
                                    variant='text'
                                    disabled={exportButtons[0].disabled}
                                    onClick={exportButtons[0].onClick}
                                    iconProps={{ name: 'export' }}
                                    dataAid='export'
                                    id='DS_Table_Export-btn'
                                >
                                    {t('TOOLBAR.EXPORT')}
                                </Button>
                            )}
                            {exportToCsvCalculatedProps && <ExportToCsv {...exportToCsvCalculatedProps} />}
                        </Stack>
                    </Stack>
                </Stack>
            )}
            <TableStyles.GridContainer
                className='ag-theme-alpine relative'
                fullHeight
                fullWidth
                data-aid='table-component'
                gridThemeOverride={{ groupIndentSize: gridThemeOverride?.groupIndentSize }}
            >
                <AgGridReact
                    onGridReady={handleOnGridReady}
                    loadingOverlayComponent={loadingOverlayComponent}
                    loadingCellRenderer={loadingCellRenderer}
                    noRowsOverlayComponent={CustomNoDataRenderer}
                    gridOptions={defaultGridOptions}
                    rowData={gridOptions?.rowData}
                    columnDefs={gridOptions?.columnDefs}
                />
                <Footer
                    gridApi={apiRef.current}
                    columnApi={columnApiRef.current}
                    gridOptions={defaultGridOptions}
                    selectedRows={selectedRows}
                    currentRows={currentRows}
                    totalRows={totalRows}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    footerSuffix={footerSuffix}
                    customFooter={footer}
                />
            </TableStyles.GridContainer>
        </TableStyles.Wrapper>
    );
}
Table.displayName = 'Table';

export default Table;
