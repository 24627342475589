import React, { useState } from 'react';
import { FILTER_EVENTS, FILTERS_KEYS } from '../../FilterPanel.consts';
import SavedFiltersList from './SavedFilterList/SavedFilterList';
import SaveFilterForm from './SaveFilterForm/SaveFilterForm';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { IExtendSavedFiltersProps, ISavedFilter } from './SavedFilters.interface';
import { useTranslation } from 'react-i18next';
import { isFilterBoxContent, isIncludeSearchTerm } from '../../filterUtils';
import { getCustomizationService, getHttpService } from '../../../../interface/services';
import { Dropdown, Message } from 'common/design-system/components-v2';
import { MessageProps } from 'common/design-system/components-v2/Message/Message.types';

export const SavedFilters: React.FC<{ filterProps: IExtendSavedFiltersProps }> = ({ filterProps }) => {
    const { t } = useTranslation();
    const {
        value: selectedFilterID,
        currFilterData: savedFilters,
        onEvent,
        displayType,
        title,
        searchTerm,
        savedFiltersComponentName,
        filtersValues,
    } = filterProps;
    const [modalDetails, setModalDetails] = useState<MessageProps | undefined>(undefined);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isDeletingFilter, setIsDeletingFilter] = React.useState<boolean>(false);

    const deleteFilter = async (filterToDelete: ISavedFilter) => {
        setIsDeletingFilter(true);
        const { id, scope } = filterToDelete;
        const responseFromDelete = await getHttpService().request<any>('/customization/' + id, {
            method: 'DELETE',
        });

        if (!responseFromDelete) {
            const updatedList = Object.assign({}, savedFilters);
            const filtersForCategory = savedFilters[scope];
            const unremovedFilters = filtersForCategory.filter((filter: ISavedFilter) => {
                return filter.id !== id;
            });
            updatedList[scope] = unremovedFilters;
            onEvent({
                action: FILTER_EVENTS.HANDLER_CHANGED,
                filterKey: FILTERS_KEYS.SAVED_FILTERS,
                payload: { data: updatedList, value: id === selectedFilterID ? '' : selectedFilterID },
            });
        }
        closeSavedFiltersModal();
        setIsDeletingFilter(false);
    };

    const onFilterItemDeleted = async (filterToDelete: ISavedFilter) => {
        const { data } = filterToDelete;
        const messageProps: MessageProps = {
            title: data.name,
            text: t('FILTER_PANEL.SAVED_FILTERS.DELETE_MESSAGE'),
            onClose: closeSavedFiltersModal,
            onCancel: closeSavedFiltersModal,
            onConfirm: () => deleteFilter(filterToDelete),
            submitBtnText: t('FILTER_PANEL.SAVED_FILTERS.DELETE_BUTTON'),
        };
        setModalDetails(messageProps);
    };

    const closeSavedFiltersModal = () => {
        setModalDetails(undefined);
    };

    const onFilterItemAdded = async (name: string, scope: string) => {
        if (!name) {
            return;
        }

        let updatedList = { ...savedFilters };
        const existingFilter: ISavedFilter = updatedList[scope].find(
            (filter: ISavedFilter) => filter.data.name === name,
        );
        let existingFilterWasDeleted = false;
        let newFilterWasAdded = false;
        if (existingFilter) {
            await deleteFilter(existingFilter);
            existingFilterWasDeleted = true;
            updatedList = { ...savedFilters };
            const index: number = updatedList[scope].findIndex((filter: ISavedFilter) => filter.data.name === name);
            if (index >= 0) {
                updatedList[scope].splice(index, 1);
            }
        }

        const responseFromAdd = await getCustomizationService().setCustomization(
            savedFiltersComponentName,
            name,
            filtersValues,
            scope,
        );

        if (responseFromAdd) {
            newFilterWasAdded = true;
            updatedList[scope].push(responseFromAdd);
        }

        if (newFilterWasAdded || existingFilterWasDeleted) {
            onEvent({
                action: FILTER_EVENTS.HANDLER_CHANGED,
                filterKey: FILTERS_KEYS.SAVED_FILTERS,
                payload: { data: updatedList, value: responseFromAdd.id },
            });
        }
    };

    const onFilterItemSelected = (filterValues: ISavedFilter) => {
        onEvent({ action: FILTER_EVENTS.FILTER_CHANGED, filterKey: FILTERS_KEYS.ALL, payload: filterValues });
    };

    const isEmptyFiltersList = () => {
        let isEmpty = false;
        Object.keys(savedFilters).forEach((key) => {
            isEmpty = isEmpty || savedFilters[key].length > 0;
        });
        return isEmpty;
    };

    const renderFavorites = (opt?: any) => {
        return (
            <div className={`${opt?.classes}`}>
                {isEmptyFiltersList() && (
                    <SavedFiltersList
                        savedFiltersByCategories={savedFilters}
                        selectedFilterID={selectedFilterID}
                        onFilterItemSelected={onFilterItemSelected}
                        onFilterItemDeleted={onFilterItemDeleted}
                    />
                )}
                <SaveFilterForm onFilterAdded={onFilterItemAdded} />
            </div>
        );
    };

    const renderRowView = () => {
        return (
            <div className='filter-panel__favorites'>
                <Dropdown
                    placement='bottom-start'
                    open={isOpen}
                    onStateChange={(state) => setIsOpen(state)}
                    label={t('FILTER_PANEL.SAVED_FILTERS.BUTTON_TEXT')}
                    buttonProps={{
                        iconProps: { name: 'favorite' },
                        size: 'small',
                        dataAid: 'favorites',
                        id: 'FilterPanel-favorites',
                    }}
                    maxHeight={500}
                >
                    {renderFavorites({ classes: 'w-[250px]' })}
                </Dropdown>
            </div>
        );
    };

    const renderBoxViewFavorties = () => {
        return isIncludeSearchTerm(searchTerm!, title) && <Accordion title={title} content={renderFavorites()} />;
    };

    return (
        <>
            {isFilterBoxContent(displayType) ? renderBoxViewFavorties() : renderRowView()}
            {modalDetails && <Message {...modalDetails} isLoading={isDeletingFilter} />}
        </>
    );
};

export default SavedFilters;
