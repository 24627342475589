import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DrawerStyled } from './Drawer.styled';
import {
    DrawerState,
    IDrawerAction,
    IDrawerContent,
    IDrawerContentProvider,
    IDrawerHandlersIdMap,
    IDrawerIcon,
    IDrawerOptions,
} from './Drawer.interface';
import { useTranslation } from 'react-i18next';
import { IconButton, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { Icon } from '@dome9/berries/react-components';
import { ILightDrawerProps, LightDrawer } from './LightDrawer';
import { drawerManager } from './DrawerManager';
import { DrawerBus } from './DrawerBus';
import DrawerActions from './DrawerActions';
import i18n from 'i18next';

const getHeaderIcon = (icon: IDrawerIcon): JSX.Element => {
    if (typeof icon === 'string') {
        return <Icon name={icon as string} color={'undefined'} height={36} width={36} />;
    } else {
        return icon as JSX.Element;
    }
};

const DEFAULT_DRAWER_WIDTH = 'xl';
interface ILightDrawerClosedParams {
    closeAll?: boolean;
}

export const Drawer: React.FC<{
    options?: IDrawerOptions;
    content?: ReactElement;
    title?: string;
    leftHeaderTrailingContent?: React.ReactNode;
    state: DrawerState;
    icon?: IDrawerIcon;
    rightHeaderContent?: React.ReactNode;
    onClose: (closeAll?: boolean) => void;
    actions?: IDrawerAction[];
}> = ({ options, content, title, leftHeaderTrailingContent, state, icon, rightHeaderContent, onClose, actions }) => {
    const { t } = useTranslation();
    const isHeaderRelevant = !!(title || icon);
    const isRegisteredDrawerRef = useRef<boolean>(false);
    const drawerOptions = useMemo<IDrawerOptions>(() => options || {}, [options]);
    const [lightDrawerProps, setLightDrawerProps] = useState<ILightDrawerProps>();
    const lightDrawerClosedParamsRef = useRef<ILightDrawerClosedParams | undefined>();
    const [drawersCount, setDrawersCount] = useState<number>(drawerManager.getDrawersCount());

    const onDrawersStackChange = useCallback(() => {
        setDrawersCount(drawerManager.getDrawersCount());
    }, []);

    const onCloseLightDrawer = useCallback((closeAll?: boolean) => {
        lightDrawerClosedParamsRef.current = { closeAll };
        setLightDrawerProps(undefined);
    }, []);

    const openLightDrawer = useCallback(
        async (
            contentProvider: IDrawerContentProvider,
            widgetData?: any,
            handlersIdMap?: IDrawerHandlersIdMap,
        ): Promise<void> => {
            setLightDrawerProps({
                options: contentProvider.options,
                onClose: onCloseLightDrawer,
                state: DrawerState.VISIBLE_LOADING,
            });

            contentProvider
                .getDrawerContent(widgetData, handlersIdMap)
                .then((content: IDrawerContent | undefined) => {
                    if (!content) {
                        setLightDrawerProps({
                            options: contentProvider.options,
                            onClose: onCloseLightDrawer,
                            state: DrawerState.VISIBLE_ERROR,
                        });
                        return;
                    }
                    setLightDrawerProps({
                        content: {
                            title: content.title,
                            leftHeaderTrailingContent: content.leftHeaderTrailingContent,
                            icon: content.icon,
                            component: content.component,
                            componentProps: content.componentProps,
                            rightHeaderContent: content.rightHeaderContent,
                            actions: content.actions,
                        },
                        options: contentProvider.options,
                        onClose: onCloseLightDrawer,
                        state: DrawerState.VISIBLE_READY,
                    });
                })
                .catch((e) => {
                    console.error('Failed opening new light drawer. Error: ', e);
                    setLightDrawerProps({
                        options: contentProvider.options,
                        onClose: onCloseLightDrawer,
                        state: DrawerState.VISIBLE_ERROR,
                    });
                });
        },
        [onCloseLightDrawer],
    );

    const onCloseRequest = useCallback(
        (closeAll?: boolean) => {
            if (drawerOptions.onCloseHandlerId) {
                DrawerBus.sendCloseEvent(drawerOptions.onCloseHandlerId, closeAll);
                return;
            }

            onClose(closeAll);
        },
        [drawerOptions.onCloseHandlerId, onClose],
    );

    useEffect(() => {
        if (!lightDrawerProps && lightDrawerClosedParamsRef.current) {
            const closeAll: boolean | undefined = lightDrawerClosedParamsRef.current.closeAll;
            lightDrawerClosedParamsRef.current = undefined;
            drawerManager.onDrawerClosed(closeAll);
        }
    }, [lightDrawerProps]);

    useEffect(() => {
        if (state !== DrawerState.HIDDEN && !isRegisteredDrawerRef.current) {
            isRegisteredDrawerRef.current = true;
            drawerManager.onDrawerOpened({
                openLightDrawer,
                onCloseRequest,
                closeDrawer: onClose,
                onDrawersStackChange,
            });
        }
    }, [state, title, openLightDrawer, onCloseRequest, onClose, onDrawersStackChange]);

    const isDisabledActionsBar = state === DrawerState.VISIBLE_LOADING || state === DrawerState.HIDDEN;
    return (
        <>
            <DrawerStyled.TopModal
                width={drawerOptions.width ?? DEFAULT_DRAWER_WIDTH}
                isOpen={true}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => (drawerOptions.suppressCloseOnEscape ? null : onCloseRequest())}
            >
                <DrawerStyled.TopDiv data-aid='page-drawer'>
                    <DrawerStyled.ActionsBar>
                        <IconButton
                            disabled={isDisabledActionsBar}
                            variant='contained'
                            circleShape
                            iconProps={{ name: 'remove', size: 12 }}
                            onClick={() => onCloseRequest()}
                            tooltip={t('COMMON.CLOSE_PASCAL')}
                            dataAid='Drawer-close-btn'
                        />
                        {!drawerOptions.suppressCloseAll && drawersCount > 1 && (
                            <IconButton
                                disabled={isDisabledActionsBar}
                                variant='contained'
                                circleShape
                                iconProps={{ name: 'clearAll', size: 12 }}
                                onClick={() => drawerManager.closeAll()}
                                tooltip={t('COMMON.CLOSE_ALL_PASCAL')}
                                dataAid='Drawer-close-all-btn'
                            />
                        )}
                    </DrawerStyled.ActionsBar>
                    <DrawerStyled.BodyDiv>
                        {state === DrawerState.VISIBLE_READY && isHeaderRelevant && (
                            <DrawerStyled.HeaderDiv
                                data-aid={'drawer-header'}
                                data-aid2={title}
                                hasHeaderLineSeparator={drawerOptions.hasHeaderLineSeparator}
                                alignItems={'flex-start'}
                            >
                                <Stack direction='row' alignItems='center' spacing={2} flexWrap>
                                    {icon && getHeaderIcon(icon)}
                                    <Typography variant={'subtitleLg'}>{title}</Typography>
                                    {leftHeaderTrailingContent}
                                </Stack>
                                {rightHeaderContent && (
                                    <DrawerStyled.RightHeaderContentDiv>
                                        {rightHeaderContent}
                                    </DrawerStyled.RightHeaderContentDiv>
                                )}
                                {actions && actions.length > 0 && (
                                    <DrawerActions actions={actions} actionsStyle={options?.actionsStyle} />
                                )}
                            </DrawerStyled.HeaderDiv>
                        )}
                        {state === DrawerState.VISIBLE_READY && content && (
                            <DrawerStyled.ContentDiv
                                hasHeaderLineSeparator={drawerOptions.hasHeaderLineSeparator}
                                isHeaderRelevant={isHeaderRelevant}
                                disableSpacing={drawerOptions.disableSpacing}
                            >
                                <DrawerStyled.ContentComponentDiv>{content}</DrawerStyled.ContentComponentDiv>
                            </DrawerStyled.ContentDiv>
                        )}
                        {state === DrawerState.VISIBLE_LOADING && (
                            <DrawerStyled.LoadingDiv variant={'subtitleLg'}>
                                {t('GENERAL.LOADING')} <Spinner size={20} />
                            </DrawerStyled.LoadingDiv>
                        )}
                        {state === DrawerState.VISIBLE_ERROR && (
                            <Stack alignItems='center' justifyContent='center' fullWidth fullHeight>
                                <Typography color='alert' variant={'subtitleLg'}>
                                    {i18n.t('COMMON.ERROR_OCCURRED')}
                                </Typography>
                            </Stack>
                        )}
                    </DrawerStyled.BodyDiv>
                </DrawerStyled.TopDiv>
            </DrawerStyled.TopModal>
            {lightDrawerProps && <LightDrawer {...lightDrawerProps} />}
        </>
    );
};
