import { IActiveFeatureInfo } from '../../../registries/ActiveFeatureManager/ActiveFeatures';

export const CIEM_TOPIC_ID = 'CIEM';

export const KUSTO_TABLE_FEATURE: IActiveFeatureInfo = {
    key: 'ciem-kusto-table',
    description: 'If enabled, a CIEM-KUSTO tab will be added to the All Events tables',
    topic: CIEM_TOPIC_ID,
};

export const REACT_CIEM_DASHBOARD_FEATURE: IActiveFeatureInfo = {
    key: 'react-ciem-dashboard',
    description: 'If enabled, new react CIEM dashborad will be shown',
    topic: CIEM_TOPIC_ID,
};

export const IDENTITY_ACTIVE_FEATURES: IActiveFeatureInfo[] = [KUSTO_TABLE_FEATURE, REACT_CIEM_DASHBOARD_FEATURE];
