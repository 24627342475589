import { FC } from 'react';
import { Typography } from '../../../../../design-system/components-v2';

export const HelperTextComponent: FC<{ helpText: string }> = ({ helpText }) => {
    if (!helpText) {
        return null;
    }

    return <Typography variant={'bodyXs'}>{helpText}</Typography>;
};
