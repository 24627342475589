import IframeMessageModel from '../interface/IFrame.message.model';

import { getIsCloudInfra } from './RuntimeEnvironment';

export function emitMessageToCloudInfra(iframeMessage: IframeMessageModel) {
    if (getIsCloudInfra()) {
        try {
            const ancestorOrigin = new URL(document.referrer).hostname;
            window.top?.postMessage(iframeMessage, `https://${ancestorOrigin}`);
        } catch (error) {
            console.error(`Failed to post message to cloud infra, referrer: ${document.referrer}`, error);
        }
    }
}
