import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { Addin } from '../../../extensibility/AddinRegistry';

const PATH_DEV_MODAL_COMPONENTS = buildPath('dev-modal', 'components');

interface IDevModalTab {
    title: string;
    component: React.FC;
}

export const DevModalRegistry = {
    addTab: (tab: IDevModalTab) => {
        const addIn: Addin<IDevModalTab> = {
            id: tab.title,
            content: tab,
        };

        globalAddinContainer.add<IDevModalTab>(PATH_DEV_MODAL_COMPONENTS, [addIn]);
    },

    getTabs: (): IDevModalTab[] => {
        return globalAddinContainer.get<IDevModalTab>(PATH_DEV_MODAL_COMPONENTS);
    },
};
