/* eslint-disable react/prop-types */
import mapData from '@highcharts/map-collection/custom/world.geo.json';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MapModule from 'highcharts/modules/map';
import React, { useEffect, useRef, useState } from 'react';
import { generateColors } from '../Widgets/helpers';
import { useProfiler } from '@sentry/react';

MapModule(Highcharts);

const MapChart: React.FC<IGenericWidgetDataProps> = (props) => {
    useProfiler(`MapChart|${props.header}`);

    const data = props.data;
    const items = data?.items;
    const chartRef = useRef<any>();
    const [chartOptions, setChartOptions] = useState<any>();

    useEffect(() => {
        const convertedData = items?.map((item) => [item.key.toLocaleLowerCase() as string, item.value as number]);
        const colors = generateColors(6);
        const chartOptions = {
            credits: {
                enabled: false,
            },
            chart: {
                map: mapData,
            },
            title: {
                text: '',
            },
            mapNavigation: {
                enabled: true,
            },
            series: [
                {
                    data: convertedData,
                },
            ],
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
            },
            colorAxis: {
                min: 0,
                stops: [
                    [0, '#FAFAFA'],
                    [1, colors[5]],
                ],
            },
        };

        setChartOptions(chartOptions);
    }, [items]);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) chart.reflow();
    }, [chartRef]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
                style: {
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                    height: '100%',
                },
            }}
            options={chartOptions}
            constructorType={'mapChart'}
            ref={chartRef}
        />
    );
};

export default MapChart;
