import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IProtectedAssetFilterParams } from '../../module_interface/assets/ProtectedAssets';
import {
    FINDINGS_TABLE_ACTIONS_DEFS,
    FINDINGS_TABLE_COLUMN_DEFS,
    FINDINGS_TABLE_FILTER_DEFS,
    SECURITY_EVENTS_BY_RULES_COLUMN_DEFS,
} from '../../module_interface/intelligence/Intelligence.const';

export const FindingsTableRegistry = {
    addColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(FINDINGS_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(FINDINGS_TABLE_COLUMN_DEFS);
    },

    addEventsByRuleColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(SECURITY_EVENTS_BY_RULES_COLUMN_DEFS, columnDefs, idProperty);
    },

    getEventsByRuleColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(SECURITY_EVENTS_BY_RULES_COLUMN_DEFS);
    },

    addFilterDefs: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(FINDINGS_TABLE_FILTER_DEFS, filterAddins);
    },

    getFilterById: (filterId: string, filterParams?: IProtectedAssetFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(FINDINGS_TABLE_FILTER_DEFS, filterId, filterParams);
    },

    getFilterDefs: (): IFilterProps[] => {
        return globalAddinContainer.get<IFilterProps>(FINDINGS_TABLE_FILTER_DEFS, []);
    },

    addActions: (actionDefs: ITableAction[], idProperty: string) => {
        globalAddinContainer.addMap<ITableAction>(FINDINGS_TABLE_ACTIONS_DEFS, actionDefs, idProperty);
    },

    getActions: (): ITableAction[] => {
        return globalAddinContainer.get<ITableAction>(FINDINGS_TABLE_ACTIONS_DEFS);
    },
};
