import { KEYS_CONTAINS_EPOCH_TIME } from './JsonView.consts';
import { IEntry, INode, INodeList } from './JsonView.types';
import { formatDate } from 'common/utils/helpFunctions';

export const isArray = (item: INode): item is INode[] => {
    return Array.isArray(item);
};

export const isObject = (item: INode): item is object => {
    return Object.prototype.toString.call(item) === '[object Object]';
};

export const isTypeArrayOrObject = (item: INode): item is object | INode[] => {
    return isArray(item) || isObject(item);
};

const isNull = (item: INode): item is null => {
    return item === null;
};

const isNumber = (item: INode): item is number => {
    return typeof item === 'number';
};

const isBoolean = (item: INode): item is boolean => {
    return typeof item === 'boolean';
};

const isUndefined = (item: INode): item is undefined => {
    return typeof item === 'undefined';
};

const isFunction = (item: INode): item is Function => {
    return typeof item === 'function';
};

const isRegexp = (item: INode): item is RegExp => {
    return Object.prototype.toString.call(item) === '[object RegExp]';
};

export const isDateTime = (itemKey: string): boolean => {
    return KEYS_CONTAINS_EPOCH_TIME.includes(itemKey);
};

export const convertReadableDateTime = (item: IEntry): string => {
    const EPOCH_REGEXP = new RegExp('[1-9][0-9]{9,12}');
    const AWS_DEFAULT_EPOCH = -62135596800;
    const dateTime = item.value;
    if (KEYS_CONTAINS_EPOCH_TIME.includes(item.name)) {
        const epochDate = parseInt(item.value as string);
        const regexValue = epochDate.toString().match(EPOCH_REGEXP);
        if (regexValue && regexValue[0] === regexValue.input) {
            const date = new Date(epochDate * 1000);
            return `${formatDate(date)} (${item.value})`;
        }
        if (isNumber(item.value) && item.value === AWS_DEFAULT_EPOCH) {
            return `Never (${AWS_DEFAULT_EPOCH})`;
        }
        return `${dateTime}`;
    }
    return `${dateTime}`;
};

export const getType = (item: INode): string => {
    let t = Object.prototype.toString.call(item);
    const match = /(?!\[).+(?=\])/g;
    // @ts-ignore
    t = t.match(match)[0].split(' ')[1];
    return t.toLowerCase();
};

export const getItems = (item: INode): number => {
    if (isObject(item)) {
        return Object.keys(item).length;
    }
    if (isArray(item)) {
        return item.length;
    }
    return 0;
};

export const parseObjectToList = (data: INode): IEntry[] | string => {
    let start = 1;
    const parse = (param: INode | INodeList): IEntry[] | string => {
        const result: IEntry[] = [];
        if (isTypeArrayOrObject(param)) {
            const keys = Object.keys(param);
            keys.forEach((key: string, index: number) => {
                const _item = isArray(param) ? param[index] : param[key];
                const currentLine = start++;
                const entry: IEntry = {
                    id: randomString(10),
                    name: key,
                    line: currentLine,
                    type: getType(_item),
                    value: parse(_item),
                    showIndex: isObject(param),
                    lastLine: isTypeArrayOrObject(_item) ? start++ : null,
                    items: isTypeArrayOrObject(_item) ? getItems(_item) : 0,
                    needComma: keys.length !== index + 1,
                    level: 1,
                };
                result.push(entry);
            });
            return result;
        } else {
            return formatSimpleData(param);
        }
    };
    return parse(data);
};

export const formatSimpleData = (param: INode): string => {
    if (isNumber(param)) return param.toString();
    if (isNull(param)) return 'null';
    if (isUndefined(param)) return 'undefined';
    if (isBoolean(param)) return param.toString();
    if (isFunction(param)) return 'ƒ() {...}';
    if (isRegexp(param)) return param.toString();
    return `"${param.toString()}"`;
};

export const scrollToElementWithOffset = (element: Element, offset: number) => {
    const container = document.getElementById('json-container');
    if (container && element && element.getBoundingClientRect) {
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const relativeElementTop = elementRect.top - containerRect.top + container.scrollTop;
        container.scrollTo({
            top: relativeElementTop - offset,
            behavior: 'smooth',
        });
    }
};

export const scrollToMatch = (searchTerm: string): { matches: Element[]; ids: string[] } => {
    const container: Element | null = document.getElementById('json-container');
    if (!container) {
        console.error('Container with id "json-container" not found.');
        return { matches: [], ids: [] };
    }

    const findTextNodes = (node: Element): Element[] => {
        if (!node) return [];
        let matches: Element[] = [];

        if (node.nodeType === Node.TEXT_NODE && compareIgnoringCaseAndQuotes(node?.nodeValue, searchTerm)) {
            // Check if the trimmed text node exactly equals the searchTerm
            matches.push(node.parentNode as Element);
            // Adding the parent node of the text node to the matches
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            // Recursively traverse the child nodes
            node.childNodes.forEach((child) => {
                matches = matches.concat(findTextNodes(child as Element));
            });
        }
        return matches;
    };

    const matches = findTextNodes(container);
    const ids = matches.map((node) => node.id).filter((id) => id);

    if (matches.length > 0) {
        scrollToElementWithOffset(matches[0], 150);
    }
    return { matches, ids };
};

export const extractSearchableItems = (data: INode): { label: string; value: string }[] => {
    const itemSet = new Set<string>();
    const parse = (param: INode | INodeList) => {
        if (isObject(param)) {
            Object.entries(param).forEach(([key, value]) => {
                itemSet.add(key);
                parse(value);
            });
        } else if (isArray(param)) {
            param.forEach((value) => {
                if (value) {
                    itemSet.add(value.toString().toLowerCase());
                    parse(value);
                }
            });
        } else {
            if (param) {
                itemSet.add(param.toString().toLowerCase());
            }
        }
    };
    parse(data);
    return Array.from(itemSet).map((item) => ({ label: item, value: item }));
};

export const escapeRegExp = (value: string) => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
const sanitize = (str: string) => str.trim().replace(/^"|"$/g, '');

export const compareIgnoringCaseAndQuotes = (text: string | null, searchTerm: string) => {
    if (!text || !searchTerm) return false;
    const sanitizedText = sanitize(text);
    const sanitizedSearchTerm = sanitize(searchTerm);
    return sanitizedText.toLowerCase() === sanitizedSearchTerm.toLowerCase();
};

export const randomString = (length: number) =>
    [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
