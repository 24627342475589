import React, { forwardRef } from 'react';
import { ISelectAsync } from './SelectAsync.types';
import { SelectV2, Spinner } from '..';
import { SelectOption } from '../SelectV2/Select.types';

const getNoneOptionValue = (value: string) => ({ value, label: value });

const SelectAsync: React.FunctionComponent<ISelectAsync> = forwardRef<HTMLInputElement, ISelectAsync>((props, ref) => {
    const { loadOptions, creatable, options, isOptionDisabled, onChange, defaultValue, value, ...rest } = props;

    const indexedOptions = React.useMemo(() => {
        return options.reduce<{ [value: string]: SelectOption }>((acc, option) => {
            acc[option.value] = option;
            return acc;
        }, {});
    }, [options]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [asyncOptions, setAsyncOptions] = React.useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = React.useState<SelectOption[]>(() => {
        if (defaultValue || value) {
            return (defaultValue || value || []).map((value) => indexedOptions[value] || getNoneOptionValue(value));
        }
        return [];
    });

    React.useEffect(() => {
        if (value !== undefined) {
            setSelectedOptions(value.map((value) => indexedOptions[value] || getNoneOptionValue(value)));
        }
    }, [indexedOptions, value]);

    const debounceTimer = React.useRef<NodeJS.Timeout | null>(null);

    const handleInputStateChange = React.useCallback(
        async (value: string) => {
            if (debounceTimer.current) clearTimeout(debounceTimer.current);
            debounceTimer.current = setTimeout(async () => {
                if (value === '') {
                    setAsyncOptions([]);
                    return;
                }
                if (value.length < 2) return;
                setIsLoading(true);
                const newOptions = await loadOptions(value);
                setAsyncOptions(newOptions);
                setIsLoading(false);
            }, 500);
        },
        [loadOptions],
    );

    const handleOnKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (!creatable) return;
            if (event.key === 'Enter') {
                const inputValue = (event.target as HTMLInputElement).value;
                if (!inputValue.trim()) return;
                const newOption: SelectOption = { value: inputValue, label: inputValue };
                if (isOptionDisabled && isOptionDisabled(newOption, selectedOptions)) return;
                if (selectedOptions.some((option) => option.value === inputValue)) return;
                setSelectedOptions([...selectedOptions, { value: inputValue, label: inputValue }]);
                onChange &&
                    onChange(
                        [...selectedOptions, newOption].map((option) => option.value),
                        [...selectedOptions, newOption],
                        { option: newOption, event: 'added' },
                    );
            }
        },
        [creatable, selectedOptions, setSelectedOptions, isOptionDisabled, onChange],
    );

    const handleOnSelectChange = React.useCallback(
        (
            value: string[],
            options: SelectOption[],
            lastAction: { option: SelectOption | null; event: 'added' | 'removed' | 'clearAll' },
        ) => {
            onChange && onChange(value, options, lastAction);
            setSelectedOptions(options);
        },
        [onChange],
    );

    const fixedOptions = React.useMemo<SelectOption[]>(() => {
        if (isLoading) {
            return [
                { itemType: 'text', value: 'loading', label: 'Loading...' },
                ...selectedOptions.map((option) => ({ ...option, disabled: true })),
            ];
        }
        const relevantOptions = asyncOptions.length ? asyncOptions : options;
        const indexedOptions = relevantOptions.reduce<{ [value: string]: true }>((acc, option) => {
            acc[option.value] = true;
            return acc;
        }, {});
        const filteredSelectedOptions = selectedOptions.filter((option) => !indexedOptions[option.value]);
        return [...relevantOptions, ...filteredSelectedOptions];
    }, [isLoading, asyncOptions, options, selectedOptions]);

    return (
        <SelectV2
            ref={ref}
            {...rest}
            isMulti={true}
            onInputChange={handleInputStateChange}
            onInputKeyDown={handleOnKeyDown}
            onChange={handleOnSelectChange}
            isOptionDisabled={isOptionDisabled}
            disableInputFilter
            options={fixedOptions}
            value={selectedOptions.map((option) => option.value)}
            endAdornment={isLoading ? <Spinner size={16} /> : undefined}
        />
    );
});

SelectAsync.displayName = 'SelectAsync';
export default SelectAsync;
