import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { getEntityTypeValue } from 'common/components/ProtectedAssets/Renderers/ColumnDisplayValueGetters';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { IAsset } from 'common/assets/common.assets';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { isNil } from 'common/utils/helpFunctions';

const TypeCellRenderer: React.FC<
    ICellRendererParams & { onClick?: (typeByPlatform: string) => void; showIcon?: boolean }
> = (params) => {
    const typeByPlatform: string = params.data.typeByPlatform;
    const asset = getProtectedAssetsService().getAssetByType(typeByPlatform) as IAsset;
    const iconName = params.showIcon ? asset?.icon : undefined;
    const options: IIconLinkCellProps = {
        isLink: !isNil(params.onClick),
        iconProps: iconName ? { vendorNameOrPath: iconName } : undefined,
        onClick: params.onClick ? () => params.onClick!(typeByPlatform) : undefined,
        value: getEntityTypeValue(params.data?.typeByPlatform) ?? params.value,
    };

    CellToClipboardHandler(params, String(options.value));
    return <IconLinkCellRenderer {...options} />;
};

export default TypeCellRenderer;
