import { FC } from 'react';
import { I18nExclusion, rangeType } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionDateProps } from '../../helpers/exclusions.interfaces';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';
import { DatePicker } from 'common/design-system/components-v2';

const ExclusionByDate: FC<IExclusionDateProps> = ({ onChange, selectedOption, tooltip }) => {
    const { t } = useTranslation(I18nExclusion);
    const handleDateChange = (date?: IDateRange) => {
        onChange(date);
    };

    const formatRangeDate = () => {
        if (!selectedOption || !selectedOption.from || !selectedOption.to) return;
        return {
            from: new Date(selectedOption.from),
            to: new Date(selectedOption.to),
        };
    };

    return (
        <DatePicker
            label={t('MODAL.TOPICS.DATEPICKER.TITLE')}
            labelProps={{ tooltip: tooltip }}
            type={rangeType}
            value={formatRangeDate()}
            onChange={(date) => handleDateChange(date)}
            dataAid='exclusion-date-picker'
        />
    );
};

export default ExclusionByDate;
