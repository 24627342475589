import React, { forwardRef, useMemo } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import { StatusIconType } from '../Modal/StatusIcon';
import { MessageProps, MessageVariant } from './Message.types';
import { useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import Checkbox from '../Checkbox';
import { StyledStack } from './Message.style';
import { Spacing } from '../Stack/Stack.types';
import Stack from '../Stack';
import { getEventTracking } from 'common/interface/services';

export const messageVariantIcon: Record<MessageVariant, StatusIconType> = {
    inform: 'info-outline',
    warning: 'warning-outline',
    danger: 'error-outline',
    positive: 'success-outline',
};

const Message: React.FunctionComponent<MessageProps> = forwardRef<ReactModal, MessageProps>((props, ref) => {
    const {
        isOpen = true,
        title,
        text,
        isLoading = false,
        width = 'sm',
        children,
        cancelBtnText,
        cancelBtnDisabled,
        submitBtnText,
        submitBtnDisabled,
        variant,
        onCancel,
        onClose,
        onConfirm,
        checkboxProps,
        fullHeight,
        id,
        dataAid,
    } = props;
    const { t } = useTranslation(getCompsI18nNS('common'));
    const { label = t('DONT_SHOW_AGAIN'), onClick } = checkboxProps || {};

    React.useEffect(() => {
        try {
            getEventTracking().track('DS_Message-mount', {
                id,
            });
        } catch (error) {
            /* do nothing */
        }
        return () => {
            try {
                getEventTracking().track('DS_Message-unmount', {
                    id,
                });
            } catch (error) {
                /* do nothing */
            }
        };
    }, [id]);

    const margin = useMemo((): Spacing[] | undefined => {
        if (!checkboxProps) return undefined;
        if (children) return [0, 0, 0, 2];
        if (variant) return [0, 0, 0, 12];
        return undefined;
    }, [children, variant, checkboxProps]);

    return (
        <Modal.ModalDialog
            ref={ref}
            isOpen={isOpen}
            width={width}
            shouldCloseOnOverlayClick
            onRequestClose={onClose}
            id={id}
            dataAid={dataAid}
        >
            <Modal.ModalHeader
                hasSeparator={!!children}
                title={title}
                subTitle={text}
                icon={variant && messageVariantIcon[variant]}
                onClose={onClose}
            />
            {children && <Modal.ModalContent fullHeight={fullHeight}>{children}</Modal.ModalContent>}
            {(onCancel || onConfirm) && (
                <Modal.ModalFooter hasSeparator={!!children}>
                    <Stack direction='row' justifyContent='space-between' fullWidth margin={margin}>
                        {checkboxProps && <Checkbox label={label} onChange={onClick} />}
                        <StyledStack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                            {onCancel && (
                                <Button
                                    disabled={cancelBtnDisabled || isLoading}
                                    variant='text'
                                    onClick={onCancel}
                                    dataAid='cancel-button'
                                    id={`Message_cancel_btn-${id}`}
                                >
                                    {cancelBtnText || t('CANCEL')}
                                </Button>
                            )}
                            {onConfirm && (
                                <Button
                                    disabled={submitBtnDisabled || isLoading}
                                    color={variant === 'danger' ? 'alert' : 'brandPrimary'}
                                    loading={isLoading}
                                    onClick={onConfirm}
                                    dataAid='confirm-button'
                                    id={`Message_confirm_btn-${id}`}
                                >
                                    {submitBtnText || t('CONFIRM')}
                                </Button>
                            )}
                        </StyledStack>
                    </Stack>
                </Modal.ModalFooter>
            )}
        </Modal.ModalDialog>
    );
});
Message.displayName = 'Message';

export default Message;
