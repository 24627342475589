import { FC, useMemo } from 'react';
import { Stack } from '../../../design-system/components-v2';
import { ICustomTreeNode, ICustomTreeProps } from './CustomTree.interface';
import { enrichCustomTreeNode } from './CustomTree.utils';
import { CustomTreeNode } from './components/CustomTreeNode';

export const CustomTree: FC<ICustomTreeProps> = <T=any>(props: ICustomTreeProps<T>) => {
    const { root, settings } = props;
    const enrichedRoot: ICustomTreeNode<T> = useMemo(() => enrichCustomTreeNode(root), [root]);

    return (
        <Stack direction={'column'}>
            <CustomTreeNode node={enrichedRoot} settings={settings} lastChildFlagsArray={[]}/>
        </Stack>
    );
};
CustomTree.displayName = 'CustomTree';

export default CustomTree;