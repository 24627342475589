import styled from 'styled-components';
import { A } from 'common/components/Anchor/Anchor';

const StyledInnerA = styled(A)`
    word-break: break-word;
    &:hover {
        text-decoration: underline;
    }
`;

const StyledOuterA = styled.a`
    word-break: break-word;
    display: inline-flex;
    gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
    align-items: center;

    &:hover {
        text-decoration: underline;
    }
`;

const SmartTypographyStyled = {
    StyledInnerA,
    StyledOuterA,
};

export default SmartTypographyStyled;
