import React from 'react';
import { convertReadableDateTime, isDateTime } from '../helpers/JsonView.utils';
import { HighlightText } from './HighlightText';
import { NormalTreeProps } from '../helpers/JsonView.types';
import { JsonRow, Span } from '../helpers/JsonView.styles';
import { jsonColors, tagsStyleElements } from '../helpers/JsonView.consts';

const SimpleRow: React.FC<NormalTreeProps> = (props) => {
    const { item, searchTerm, currentMatchId } = props;
    const itemValue = isDateTime(item.name) ? convertReadableDateTime(item) : item.value.toString();
    const colorType = item.type.toString();
    const isTag = tagsStyleElements.indexOf(colorType) > -1;
    const isMatch = currentMatchId === `${item.id}-${item.name}` || currentMatchId === `${item.id}-${itemValue}`;
    return (
        <JsonRow backgroundColor={isMatch ? jsonColors.highlightBackground : jsonColors.transparent}>
            <Span color={jsonColors.content}>
                {item.showIndex && (
                    <Span color={jsonColors.key}>
                        <HighlightText
                            text={item.name}
                            searchTerm={searchTerm}
                            id={`${item.id}-${item.name}`}
                            currentMatchId={currentMatchId}
                        />
                        {': '}
                    </Span>
                )}
                <Span color={jsonColors[colorType]} isTag={isTag}>
                    <HighlightText
                        text={itemValue}
                        searchTerm={searchTerm}
                        id={`${item.id}-${itemValue}`}
                        currentMatchId={currentMatchId}
                    />
                </Span>
                <Span color={jsonColors.comma}>{item.needComma ? ',' : ''}</Span>
            </Span>
        </JsonRow>
    );
};

export default SimpleRow;
