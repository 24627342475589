import React, { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import ReactFlow, {
    applyEdgeChanges,
    applyNodeChanges,
    ControlButton,
    Controls,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    NodeMouseHandler,
    OnEdgesChange,
    OnNodesChange,
    ReactFlowProvider,
    useReactFlow,
    Viewport,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { GraphComponentProps } from '../Models/GraphComponentProps';
import { useAutoLayout } from '../Services/DirectionalLayout';
import GraphGrid from '../Components/GraphGrid';
import { calcGridPosition } from '../Services/GridUtils';
import FullScreenModalComponent from '../../FullScreenModal/FullScreenModalComponent';
import { Icon, Stack } from '../../../design-system/components-v2';
import { t } from 'i18next';

const defaultViewport: Viewport = { x: 0, y: 0, zoom: 1 };
const proOptions = { account: 'paid-pro', hideAttribution: true };

const FlowWrapper: React.FC<GraphComponentProps> = (props: GraphComponentProps) => {
    const {
        directionalLayoutModel,
        nodes,
        setNodes,
        edges,
        setEdges,
        onNodeDoubleClick,
        nodeTypes,
        edgeTypes,
        gridOptions,
        onNodeClick,
        onFullNodeClick,
        zoomLimits,
        fullScreenProps,
        onSelectionChange,
        zoomOnScroll,
        panOnScroll,
    } = props;

    useAutoLayout(directionalLayoutModel);
    const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState<boolean>(false);
    const [gridOutlines, setGridOutlines] = useState<DOMRect[]>([]);

    const { fitView } = useReactFlow();
    useEffect(() => {
        fitView({ duration: 400, maxZoom: 1 });

        if (gridOptions?.showGrid) {
            setTimeout(() => {
                setGridOutlines(calcGridPosition(gridOptions.gridLabels, gridOptions.nodeContainerClass));
            }, 400);
        }
    }, [edges, nodes, fitView, gridOptions]);

    function onNodesChange(changes: NodeChange[]): OnNodesChange {
        return setNodes((nodes: Node[]) => applyNodeChanges(changes, nodes));
    }

    function onEdgesChange(changes: EdgeChange[]): OnEdgesChange {
        return setEdges((edges: Edge[]) => applyEdgeChanges(changes, edges));
    }

    const onGraphNodeDoubleClick: NodeMouseHandler = (_: ReactMouseEvent, node: Node): void => {
        if (onNodeDoubleClick) {
            onNodeDoubleClick(node?.data?.srl);
        }
    };

    const onGraphNodeClick: NodeMouseHandler = (event: ReactMouseEvent, node: Node): void => {
        if (onNodeClick) {
            onNodeClick(node);
        }
        if (onFullNodeClick) {
            onFullNodeClick(event, node);
        }
    };

    const onGraphMoveEnd = (event: MouseEvent | TouchEvent) => {
        if (gridOptions?.showGrid && setGridOutlines && event) {
            setGridOutlines(calcGridPosition(gridOptions?.gridLabels, gridOptions?.nodeContainerClass));
        }
    };

    const onGraphZoom = () => {
        if (gridOptions?.showGrid && setGridOutlines) {
            setTimeout(() => {
                setGridOutlines(calcGridPosition(gridOptions?.gridLabels, gridOptions?.nodeContainerClass));
            }, 200);
        }
    };

    const onExpandGraphView = () => {
        if (!fullScreenProps || !fullScreenProps?.showFullScreenButton) return;
        setIsFullScreenModalOpen(true);
    };

    const onFullScreenClose = () => {
        setIsFullScreenModalOpen(false);
    };

    return (
        <>
            {fullScreenProps && (
                <FullScreenModalComponent
                    modalTitle={fullScreenProps.modalTitle}
                    onClose={onFullScreenClose}
                    isModalOpen={isFullScreenModalOpen}
                    component={fullScreenProps.component}
                    componentProps={fullScreenProps.componentProps}
                />
            )}
            <GraphGrid gridOptions={gridOptions} gridOutlines={gridOutlines} />
            <ReactFlow
                className='change-vendor-icons invert-svg'
                // @ts-ignore
                nodeTypes={nodeTypes ?? {}}
                edgeTypes={edgeTypes ?? {}}
                nodes={nodes}
                edges={edges}
                draggable={false}
                nodesDraggable={false}
                preventScrolling={false}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={onGraphNodeClick}
                defaultViewport={defaultViewport}
                proOptions={proOptions}
                onNodeDoubleClick={onGraphNodeDoubleClick}
                onMoveEnd={onGraphMoveEnd}
                minZoom={zoomLimits?.min}
                maxZoom={zoomLimits?.max}
                onSelectionChange={onSelectionChange}
                zoomOnScroll={zoomOnScroll}
                panOnScroll={panOnScroll}
            >
                <Controls onZoomIn={onGraphZoom} onZoomOut={onGraphZoom} showFitView={false}>
                    {fullScreenProps?.showFullScreenButton && (
                        <ControlButton onClick={onExpandGraphView}>
                            <Stack title={t('GENERAL.FULL_SCREEN')}>
                                <Icon name={'expand'} customColor={'black'} size={12} />
                            </Stack>
                        </ControlButton>
                    )}
                </Controls>
            </ReactFlow>{' '}
        </>
    );
};

const GraphComponent: React.FC<GraphComponentProps> = (props: GraphComponentProps) => {
    return (
        <ReactFlowProvider>
            <FlowWrapper {...props} />
        </ReactFlowProvider>
    );
};

export default GraphComponent;
