import { getPalette } from './palette';
import { foregrounds } from './foregrounds';
import { borders } from './borders';
import { backgrounds } from './backgrounds';
import { ColorsByContex, Mode } from './colors.types';

export const getColors = (mode: Mode = 'light'): ColorsByContex => {
    const palette = getPalette(mode);

    return {
        common: {
            black: '#000000',
            white: '#ffffff',
        },
        default: {
            borders: borders(palette).default,
            backgrounds: backgrounds(palette).default,
            foregrounds: foregrounds(palette).default,
            iconColor: foregrounds(palette).default.regular.default,
        },
        critical: {
            borders: borders(palette).critical,
            backgrounds: backgrounds(palette).critical,
            foregrounds: foregrounds(palette).critical,
            iconColor: foregrounds(palette).critical.regular.default,
        },
        high: {
            borders: borders(palette).high,
            backgrounds: backgrounds(palette).high,
            foregrounds: foregrounds(palette).high,
            iconColor: foregrounds(palette).high.regular.default,
        },
        medium: {
            borders: borders(palette).medium,
            backgrounds: backgrounds(palette).medium,
            foregrounds: foregrounds(palette).medium,
            iconColor: foregrounds(palette).medium.regular.default,
        },
        low: {
            borders: borders(palette).low,
            backgrounds: backgrounds(palette).low,
            foregrounds: foregrounds(palette).low,
            iconColor: foregrounds(palette).low.regular.default,
        },
        lowRisk: {
            borders: borders(palette).lowRisk,
            backgrounds: backgrounds(palette).lowRisk,
            foregrounds: foregrounds(palette).lowRisk,
            iconColor: foregrounds(palette).lowRisk.regular.default,
        },
        info: {
            borders: borders(palette).info,
            backgrounds: backgrounds(palette).info,
            foregrounds: foregrounds(palette).info,
            iconColor: foregrounds(palette).info.regular.default,
        },
        important: {
            borders: borders(palette).important,
            backgrounds: backgrounds(palette).important,
            foregrounds: foregrounds(palette).important,
            iconColor: foregrounds(palette).important.regular.default,
        },
    };
};
