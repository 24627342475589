import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltersValues } from './FilterPanel.interface';
import { RootState } from '../../services/store/store';

interface FilterPanelState {
    filterValuesKeys: { [key: string]: any };
}

const initialState: FilterPanelState = {
    filterValuesKeys: {} as { [key: string]: any },
};

type SetFilterValueParams = { filtersValues?: IFiltersValues; filterId?: string };

export const FilterPanelSlice = createSlice({
    name: 'FilterPanelSlice',
    initialState,
    reducers: {
        setFiltersValues: (state: FilterPanelState, action: PayloadAction<SetFilterValueParams>) => {
            if (action.payload.filtersValues && action.payload.filterId) {
                state.filterValuesKeys[action.payload.filterId] = action.payload.filtersValues;
            }
        },
    },
});

export const { setFiltersValues } = FilterPanelSlice.actions;

export const getFiltersValues = (state: RootState, filterId: string): IFiltersValues => {
    return state.FilterPanelSlice?.filterValuesKeys[filterId] || {};
};

export default FilterPanelSlice.reducer;
