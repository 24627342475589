import React from 'react';
import { isArray, parseObjectToList, isTypeArrayOrObject } from './helpers/JsonView.utils';
import RowWrapper from './Components/RowWrapper';
import { IEntry, JsonViewProps } from './helpers/JsonView.types';
import { JsonRenderWrapper } from './helpers/JsonView.styles';

const JsonRender: React.FC<JsonViewProps> = ({ data, searchTerm, collapseLevel, fontSize = 12, currentMatchId }) => {
    const list = React.useMemo(() => {
        if (isTypeArrayOrObject(data)) {
            return parseObjectToList(data);
        }
        return [];
    }, [data]);

    if (isTypeArrayOrObject(data)) {
        return (
            <JsonRenderWrapper id={'json-container'} fontSize={fontSize} lineHeight={1.4}>
                <p>{isArray(data) ? '[' : '{'}</p>
                {(list as IEntry[]).map((item, index) => (
                    <RowWrapper
                        key={index}
                        item={item}
                        searchTerm={searchTerm}
                        collapseLevel={collapseLevel}
                        currentMatchId={currentMatchId}
                    />
                ))}
                <p>{isArray(data) ? ']' : '}'}</p>
            </JsonRenderWrapper>
        );
    }
    return (
        <JsonRenderWrapper fontSize={fontSize} lineHeight={1.4}>
            {data?.toString() || ''}
        </JsonRenderWrapper>
    );
};

export default JsonRender;
