import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { IAssetInfo } from 'common/components/ProtectedAssets/AssetTabs';
import { GenericObject } from '../../interface/general';

interface IProtectedAssetTabsState {
    tabsMap: GenericObject<{ selectedTabId: number; openAssets: IAssetInfo[] }>;
}

const initialState: IProtectedAssetTabsState = {
    tabsMap: {},
};

export const protectedAssetTabsSlice = createSlice({
    name: 'protectedAssetTabs',
    initialState,
    reducers: {
        setOpenAssets: (
            state: IProtectedAssetTabsState,
            action: PayloadAction<{ pageId: string; assets: IAssetInfo[] }>,
        ) => {
            if (state.tabsMap[action.payload.pageId] === undefined) {
                state.tabsMap[action.payload.pageId] = { openAssets: [], selectedTabId: 0 };
            }
            state.tabsMap[action.payload.pageId].openAssets = action.payload.assets;
        },
        setSelectedTabId: (
            state: IProtectedAssetTabsState,
            action: PayloadAction<{ pageId: string; selectedTabId: number }>,
        ) => {
            if (state.tabsMap[action.payload.pageId] === undefined) {
                state.tabsMap[action.payload.pageId] = { openAssets: [], selectedTabId: 0 };
            }
            state.tabsMap[action.payload.pageId].selectedTabId = action.payload.selectedTabId;
        },
    },
});
export const { setOpenAssets, setSelectedTabId } = protectedAssetTabsSlice.actions;

export const getOpenAssets = (pageId: string) => {
    return (state: RootState): IAssetInfo[] | undefined => {
        const tabsMap = (state.protectedAssetTabs as IProtectedAssetTabsState).tabsMap;
        return tabsMap[pageId]?.openAssets;
    };
};
export const getSelectedTabId = (pageId: string) => {
    return (state: RootState): number | undefined => {
        const tabsMap = (state.protectedAssetTabs as IProtectedAssetTabsState).tabsMap;
        return tabsMap[pageId]?.selectedTabId;
    };
};

export default protectedAssetTabsSlice.reducer;
