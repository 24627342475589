import { numberWithCommas } from 'common/utils/helpFunctions';
import { useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import { DOWNLOAD_ROWS_LIMIT } from '../ExportToCsv.consts';

export const useExportToCsvTranslations = (
    rowsCount?: number,
    filteredRowsCount?: number,
    allColumnsCount?: number,
    visibleColumnsCount?: number,
) => {
    const { t } = useTranslation(getCompsI18nNS('table'), { keyPrefix: 'TOOLBAR.EXPORT_TO_CSV' });

    return {
        columnsTitle: t('COLUMNS.TITLE'),
        columnsLabel: t('COLUMNS.LABEL'),
        columnsVisible: t('COLUMNS.VISIBLE', { num: numberWithCommas(visibleColumnsCount ?? 0) }),
        columnsAll: t('COLUMNS.ALL', { num: numberWithCommas(allColumnsCount ?? 0) }),
        rowsTitle: t('ROWS.TITLE'),
        rowsLabel: t('ROWS.LABEL'),
        rowsFiltered: t('ROWS.FILTERED', { num: numberWithCommas(filteredRowsCount ?? 0) }),
        rowsAll: t('ROWS.ALL', { num: numberWithCommas(rowsCount ?? 0) }),
        deliveryLabel: t('DELIVERY.LABEL'),
        deliveryDownload: t('DELIVERY.DOWNLOAD'),
        deliveryEmail: t('DELIVERY.EMAIL'),
        helperDownload: t('DELIVERY.HELPER.DOWNLOAD', { limit: numberWithCommas(DOWNLOAD_ROWS_LIMIT) }),
        helperEmail: t('DELIVERY.HELPER.EMAIL', { limit: numberWithCommas(DOWNLOAD_ROWS_LIMIT) }),
        emailLabel: t('DELIVERY.EMAIL_LABEL'),
        errorOverLimit: t('ERRORS.OVER_LIMIT', { limit: numberWithCommas(DOWNLOAD_ROWS_LIMIT) }),
        errorInvalidEmail: t('ERRORS.INVALID_EMAIL'),
        exportCta: t('FOOTER_ACTIONS.EXPORT'),
        cancelCta: t('FOOTER_ACTIONS.CANCEL'),
    };
};
