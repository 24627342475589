import React from 'react';
import TooltipStyled from './Tooltip.styled';
import Tippy, { TippyProps } from '@tippyjs/react/headless';

const Tooltip: React.FunctionComponent<TippyProps> = (props) => {
    const { children, className, arrow = true, ...rest } = props;

    if (!props.content) return children;

    return (
        <Tippy
            {...rest}
            arrow={arrow}
            appendTo={document.body}
            render={(attrs) => (
                <TooltipStyled.StyledTooltip {...attrs} className={className}>
                    {props.content}
                    {arrow && <TooltipStyled.StyledArrow {...attrs} data-popper-arrow='' />}
                </TooltipStyled.StyledTooltip>
            )}
        >
            {children}
        </Tippy>
    );
};
export default Tooltip;
