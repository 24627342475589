import React, { ReactNode, useCallback, useMemo } from 'react';
import { ICustomTreeNode, ICustomTreeNodeProps, ICustomTreeRendererProps } from '../CustomTree.interface';
import { getCustomTreeNodeDepth, getCustomTreeNodeRenderer, getCustomTreeRowsGap } from '../CustomTree.utils';
import { Stack } from '../../../../design-system/components-v2';
import { CustomTreeIndentArea } from './CustomTreeIndentArea';

export const CustomTreeNode: React.FC<ICustomTreeNodeProps> = (props) => {
    const { node, lastChildFlagsArray, settings } = props;
    const depth: number = useMemo(() => getCustomTreeNodeDepth(node), [node]);
    const Renderer: React.FC<ICustomTreeRendererProps> = useMemo(() => getCustomTreeNodeRenderer(node, settings), [node, settings]);
    const getChildrenComponents = useCallback((): ReactNode[] => {
        if (!node.children) return [];
        const len: number = node.children.length;
        return node.children.map((child: ICustomTreeNode, index: number) => {
            const isLastChild = (index === len - 1);
            const flagsArray: boolean[] = [...lastChildFlagsArray, isLastChild];
            return (
                <CustomTreeNode node={child} settings={settings} key={child.id} lastChildFlagsArray={flagsArray}/>
            );
        });
    }, [node, settings, lastChildFlagsArray]);

    return (
        <Stack direction={'column'}>
            <Stack direction={'row'} alignItems={'center'} fullWidth>
                <CustomTreeIndentArea depth={depth} compProps={props}/>
                <Stack direction={'column'} style={{ paddingTop: `${getCustomTreeRowsGap(settings)}px` }} fullWidth>
                    <Renderer depth={depth} compProps={props}/>
                </Stack>
            </Stack>
            {getChildrenComponents()}
        </Stack>
    );
};