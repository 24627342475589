import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    Input,
    JsonViewer,
    Label,
    SelectV2,
    Stack,
    TextArea,
    Typography,
} from '../../../../design-system/components-v2';
import { SelectOption } from '../../../../design-system/components-v2/SelectV2/Select.types';
import { getHttpService } from '../../../../interface/services';
import FullSizeSpinner from '../../../custom/FullSize/FullSizeSpinner';

enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

const methodSelectOptions: SelectOption[] = [
    { value: HttpMethod.GET, label: 'GET' },
    { value: HttpMethod.POST, label: 'POST' },
    { value: HttpMethod.PUT, label: 'PUT' },
    { value: HttpMethod.DELETE, label: 'DELETE' },
];

enum ResponseType {
    DATA = 'DATA',
    ERROR = 'ERROR',
}

interface IHttpRunnerResponse {
    type: ResponseType;
    data: string;
}

export const HttpRunner: React.FC = () => {
    const [path, setPath] = useState<string>('');
    const [method, setMethod] = useState<string>(methodSelectOptions[0].value);
    const [body, setBody] = useState<string>('');
    const [useCache, setUseCache] = useState<boolean>(false);
    const [showResponse, setShowResponse] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<IHttpRunnerResponse | undefined>(undefined);

    function onSend() {
        setResponse(undefined);
        setIsLoading(true);
        getHttpService()
            .request<string>(
                path,
                {
                    method,
                    data: body,
                },
                {
                    cachingConfig: {
                        useCache,
                    },
                },
                (error) => {
                    throw error;
                },
            )
            .then((response: string) => {
                setResponse({
                    type: ResponseType.DATA,
                    data: showResponse ? response : 'Response is not shown as per the checkbox below',
                });
            })
            .catch((error: Error) => {
                setResponse({
                    type: ResponseType.ERROR,
                    data: error.message,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Stack spacing={2} direction={'column'}>
            <Stack direction={'row'} spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    <Label text={'Method:'} size={'lg'}></Label>
                    <SelectV2
                        options={methodSelectOptions}
                        onChange={(value) => {
                            setMethod(value);
                        }}
                        isMulti={false}
                        defaultValue={HttpMethod.GET}
                        value={method}
                    ></SelectV2>
                </Stack>
                <Stack direction={'row'}>
                    <Label text={'v2/'} size={'lg'}></Label>
                </Stack>
                <Input
                    clearable
                    fullWidth
                    value={path}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPath(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            onSend();
                        }
                    }}
                    placeholder={'api like: risk-management/toxic-graph?foo=bar'}
                ></Input>
            </Stack>
            <Stack
                direction={'row'}
                spacing={2}
                alignItems={'stretch'}
                justifyContent={'space-evenly'}
                margin={[2, 0, 2, 0]}
            >
                <Stack direction={'column'} spacing={2} fullWidth>
                    <Typography color={'strong'} variant={'lg'}>
                        Request Body
                    </Typography>
                    <TextArea
                        fullWidth
                        style={{ minHeight: '300px' }}
                        value={body}
                        onChange={(event) => {
                            setBody(event.target.value);
                        }}
                    ></TextArea>
                </Stack>
                {isLoading && <FullSizeSpinner />}
                {!isLoading && response?.type === ResponseType.ERROR && (
                    <Stack direction={'column'} spacing={2} fullWidth>
                        <Typography color={'alert'} variant={'lg'}>
                            Error
                        </Typography>
                        <TextArea
                            fullWidth
                            style={{ minHeight: '300px' }}
                            value={response.data}
                            readOnly={true}
                            isError={true}
                        ></TextArea>
                    </Stack>
                )}
                {!isLoading && response?.type === ResponseType.DATA && (
                    <Stack direction={'column'} spacing={1} fullWidth style={{ maxHeight: '324px' }}>
                        <Typography color={'strong'} variant={'lg'}>
                            Response Body
                        </Typography>
                        <JsonViewer showExpandAll={true} showSearch={true} data={response.data}></JsonViewer>
                    </Stack>
                )}
            </Stack>
            <Stack direction={'row'} spacing={2}>
                <Button label={'Send'} color={'brandPrimary'} onClick={onSend}></Button>
                <Checkbox
                    label={'Use Cache'}
                    checked={useCache}
                    onChange={(event) => {
                        setUseCache(event.target.checked);
                    }}
                ></Checkbox>
                <Checkbox
                    label={'Show Response'}
                    checked={showResponse}
                    onChange={(event) => {
                        setShowResponse(event.target.checked);
                    }}
                ></Checkbox>
            </Stack>
        </Stack>
    );
};
