import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    GcpEnvironmentData,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_GCP_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_GCP_TAB,
    PATH_ENVIRONMENT_PAGE_GCP_CHIP,
} from 'common/module_interface/assets/GcpEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { AddinBase } from './addinBase';
import { EnvironmentAction } from 'common/module_interface/assets/Environments';

export interface GcpEnvironmentAddingBase<T, T2 extends [GcpEnvironmentData] = [GcpEnvironmentData]>
    extends AddinBase<T, T2> {
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

function addDetailsPanelItems(item: Addin<GcpEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): GcpEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_DETAILS_PANEL));
}

function addTabs(item: Addin<GcpEnvironmentAddingBase<Tab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_TAB), item);
}

function getTabs(): GcpEnvironmentAddingBase<Tab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_TAB));
}

function addActions(item: Addin<GcpEnvironmentAddingBase<EnvironmentAction<[GcpEnvironmentData]>>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): GcpEnvironmentAddingBase<EnvironmentAction<[GcpEnvironmentData]>>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<GcpEnvironmentAddingBase<IChipProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_CHIP), item);
}

function getChips(): GcpEnvironmentAddingBase<IChipProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_GCP_CHIP));
}

export const GcpEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips,
};
