import { getUserService } from '../interface/services';
import dayjs from 'dayjs';
import { IActiveFeatureInfo } from '../registries/ActiveFeatureManager/ActiveFeatures';
import { ActiveFeaturesRegistry } from '../registries/ActiveFeatureManager/ActiveFeaturesRegistry';
import { isPermittedActiveFeatureKey } from 'common/registries/ActiveFeatureManager/ActiveFeatures.utils';

enum DebugActiveFeatures {
    DRAWER_EXAMPLE_ACTIVE_FEATURE = 'DRAWER_EXAMPLE_ACTIVE_FEATURE',
    ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE = 'ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE',
    SHOW_LOGGER_EVENTS_ACTIVE_FEATURE = 'SHOW_LOGGER_EVENTS_ACTIVE_FEATURE',
    SHOW_URL_LOG_ACTIVE_FEATURE = 'SHOW_URL_LOG_ACTIVE_FEATURE',
    DEBUG_FILTERS_ACTIVE_FEATURE = 'DEBUG_FILTERS_ACTIVE_FEATURE',
    DEBUG_ACTIONS_ACTIVE_FEATURE = 'DEBUG_ACTIONS_ACTIVE_FEATURE',
    SHOW_HIDDEN_ACTIVE_FEATURES = 'SHOW_HIDDEN_ACTIVE_FEATURES',
}

const EXAMPLES_TOPIC_ID = 'EXAMPLES';
const LOG_TOPIC_ID = 'LOG';
export const DEV_TOPIC_ID = 'DEV';

export const DebugActiveFeaturesMap: { [key in DebugActiveFeatures]: IActiveFeatureInfo } = {
    DRAWER_EXAMPLE_ACTIVE_FEATURE: {
        key: 'drawer-example',
        description: 'If enabled, a new menu will be added with a full Drawer example page',
        topic: EXAMPLES_TOPIC_ID,
    },

    ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE: {
        key: 'erm-components-examples',
        description: 'If enabled, a new menu will be added with erm-components examples',
        topic: EXAMPLES_TOPIC_ID,
    },

    SHOW_LOGGER_EVENTS_ACTIVE_FEATURE: {
        key: 'show-logger-events',
        description: 'If enabled, all the logger events will be printed to console',
        topic: LOG_TOPIC_ID,
    },

    SHOW_URL_LOG_ACTIVE_FEATURE: {
        key: 'show-url-log',
        description: 'If enabled, an event with url details will be printed to console upon each url change',
        topic: LOG_TOPIC_ID,
    },

    DEBUG_FILTERS_ACTIVE_FEATURE: {
        key: 'debug-filters',
        description: 'If enabled, some outputs from the filtering process will be printed to console',
        topic: DEV_TOPIC_ID,
    },

    DEBUG_ACTIONS_ACTIVE_FEATURE: {
        key: 'debug-actions',
        description: 'If enabled, it introduces some debug actions related to customization api',
        hidden: true,
        topic: DEV_TOPIC_ID,
    },
    SHOW_HIDDEN_ACTIVE_FEATURES: {
        key: 'hidden-active-features',
        description: 'If enabled, Active Features Manager page will show also hidden active features',
        topic: DEV_TOPIC_ID,
        hidden: true,
    },
};

const activeFeaturesMap: { [key: string]: boolean } = {};

//Do not remove, used for debugging
export function debugLog(...args: any[]) {
    const timestamp = dayjs(new Date()).format('DD/MM/YY HH:mm:ss.SSS');
    console.log(`### [${timestamp}]`, ...args);
}
export const isAfEnabled = (activeFeatureName: string): boolean => {
    if (!isPermittedActiveFeatureKey(activeFeatureName)) {
        activeFeaturesMap[activeFeatureName] = false;
    } else {
        const storageValue = localStorage.getItem(activeFeatureName);
        if (storageValue) {
            activeFeaturesMap[activeFeatureName] = storageValue.toLowerCase() === 'true';
        } else {
            const userService = getUserService();
            activeFeaturesMap[activeFeatureName] = userService ? userService.hasPermission([activeFeatureName]) : false;
        }
    }
    return activeFeaturesMap[activeFeatureName];
};

export const isAfShowLoggerEvents = () => isAfEnabled(DebugActiveFeaturesMap.SHOW_LOGGER_EVENTS_ACTIVE_FEATURE.key);
export const isAfPageDrawerExample = () => isAfEnabled(DebugActiveFeaturesMap.DRAWER_EXAMPLE_ACTIVE_FEATURE.key);
export const isAfErmComponentsExamples = () =>
    isAfEnabled(DebugActiveFeaturesMap.ERM_COMPONENTS_EXAMPLES_ACTIVE_FEATURE.key);

export const initializeDebugActiveFeatures = () => {
    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: DEV_TOPIC_ID,
        title: 'Dev',
        icon: 'log',
    });
    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: LOG_TOPIC_ID,
        title: 'Log',
        icon: 'log',
    });
    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: EXAMPLES_TOPIC_ID,
        title: 'Examples',
        icon: 'book',
    });
    ActiveFeaturesRegistry.addActiveFeatureInfos(Object.values(DebugActiveFeaturesMap));
};
