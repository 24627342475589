import { buildPath } from '../../extensibility/AddinContainer';

export const MAIN_MENU_ITEM_ID = 'assets';
export const MAIN_SECTION_ID = 'default';

const PATH_PROTECTED_ASSETS_PREFIX = buildPath('assets', 'protected assets');
export const PATH_PROTECTED_ASSETS = buildPath(PATH_PROTECTED_ASSETS_PREFIX, 'assets');
export const PATH_PROTECTED_ASSETS_PAGE = buildPath(PATH_PROTECTED_ASSETS_PREFIX, 'page');
export const PATH_PROTECTED_ASSETS_WIDGETS = buildPath(PATH_PROTECTED_ASSETS_PREFIX, 'widgets'); //These are the columns that will be shown in the protected assets page table
export const PATH_PROTECTED_ASSETS_PAGE_TABLE_COLUMNS = buildPath(PATH_PROTECTED_ASSETS_PAGE, 'columns');
export const PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS = buildPath(PATH_PROTECTED_ASSETS_PAGE, 'filters');
export const PATH_PROTECTED_ASSETS_WIDGETS_FILTERS = buildPath(PATH_PROTECTED_ASSETS_WIDGETS, 'filters');

export const PROTECTED_ASSETS_URL = 'protected-asset';
export enum PROTECTED_ASSET_COL_IDS {
    entity = 'entity',
}
