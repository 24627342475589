import { Tooltip } from '@dome9/berries/react-components';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CopiableLine, Icon, LevelIcon, Stack } from 'common/design-system/components-v2';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { LevelIconProps } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';

export interface IIconLinkCellProps {
    className?: string;
    style?: React.CSSProperties;
    iconProps?: IconProps;
    levelIconProps?: LevelIconProps;
    isLink: boolean;
    href?: string;
    onClick?: (event: SyntheticEvent) => void;
    value: string;
    tooltip?: string;
    isCopiable?: boolean;
}

enum ComponentType {
    URL_LINK,
    ACTION_LINK,
    TEXT,
}

const IconLinkCellRenderer: React.FC<IIconLinkCellProps> = (params) => {
    const { className, iconProps, levelIconProps, isLink, href, onClick, value, tooltip, style, isCopiable } = params;

    React.useEffect(() => {
        if (iconProps && levelIconProps) {
            throw new Error('You can only use one of iconProps and levelIconProps');
        }
    }, [iconProps, levelIconProps]);

    const componentType: ComponentType = useMemo(() => {
        if (isLink) {
            return href ? ComponentType.URL_LINK : ComponentType.ACTION_LINK;
        } else {
            return ComponentType.TEXT;
        }
    }, [isLink, href]);
    const textRef = useRef<HTMLDivElement>(null);
    const aRef = useRef<HTMLAnchorElement>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const getComponentBase = useCallback(() => {
        // TODO: this is bad behavior, it should use A or a component depends if its external or internal link - the user dont need to use the onClick function for internal links !!!
        switch (componentType) {
            case ComponentType.URL_LINK:
                return (
                    <a onClick={onClick} className='truncate link' href={href} data-aid='ag-grid-link' ref={aRef}>
                        {value}
                    </a>
                );

            case ComponentType.ACTION_LINK:
                return (
                    <div onClick={onClick} className='truncate link' data-aid='ag-grid-link' ref={textRef}>
                        {value}
                    </div>
                );

            case ComponentType.TEXT:
                return (
                    <div className='wrap truncate' ref={textRef}>
                        {value}
                    </div>
                );

            default:
                return (
                    <div className='wrap truncate' ref={textRef}>
                        {value}
                    </div>
                );
        }
    }, [componentType, href, onClick, value]);

    const getIcon = useCallback(() => {
        if (levelIconProps) {
            return <LevelIcon {...levelIconProps} />;
        }
        if (iconProps) {
            return <Icon {...iconProps} />;
        }
        return null;
    }, [iconProps, levelIconProps]);

    useEffect(() => {
        const updateIsTruncated = (element: HTMLElement | null) => {
            setShowTooltip(element ? element.scrollWidth > element.clientWidth : false);
        };
        updateIsTruncated(componentType === ComponentType.URL_LINK ? aRef.current : textRef.current);
    }, [componentType, isLink]);

    const getComponent = useCallback(() => {
        const componentBase = getComponentBase();
        if (isCopiable) {
            return <CopiableLine value={value}>{componentBase}</CopiableLine>;
        }
        return componentBase;
    }, [getComponentBase, isCopiable, value]);

    return (
        <Stack direction='row' alignItems='center' spacing={2} className={`flex ${className}`} style={style}>
            {getIcon()}
            {showTooltip ? <Tooltip content={tooltip ?? value}>{getComponent()}</Tooltip> : getComponent()}
        </Stack>
    );
};

export default IconLinkCellRenderer;
