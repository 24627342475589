import { buildPath } from '../../extensibility/AddinContainer';

export enum ConnectionStatus {
    connected = 'connected',
    notConnected = 'notConnected',
    partiallyConnected = 'partiallyConnected',
    canNotConnect = 'canNotConnect',
}

// general table related constants
export const TABLE = 'table';
export const COLUMNS = 'columns';
export const FILTERS = 'filters';
export const ACTIONS = 'actions';
export const FINDINGS_TABLE = 'findings-table';
export const CDR_DASHBOARD_FILTER_PANEL_ID = 'cdr-dashboard-filter-panel';
export const SECURITY_EVENTS_BY_RULE_TABLE_ID = 'security-events-by-rules';
export const FINDINGS_TABLE_COLUMN_DEFS = buildPath(FINDINGS_TABLE, COLUMNS);
export const FINDINGS_TABLE_FILTER_DEFS = buildPath(FINDINGS_TABLE, FILTERS);
export const FINDINGS_TABLE_ACTIONS_DEFS = buildPath(FINDINGS_TABLE, ACTIONS);
export const SECURITY_EVENTS_BY_RULES_COLUMN_DEFS = buildPath(FINDINGS_TABLE, COLUMNS, SECURITY_EVENTS_BY_RULE_TABLE_ID);

export enum OriginType {
    Intelligence = 'Intelligence',
    ComplianceEngine = 'Compliance Engine',
    Serverless = 'Serverless',
    ImageAssurance = 'Image Assurance',
    CIEM = 'CIEM',
}

