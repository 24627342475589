import { forwardRef } from 'react';
import Stack from '../Stack';
import { ISortableListProps } from './SortableList.types';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';
import Chip from '../Chip';

interface SortableChipProps {
    name: string;
    onClose: (name: string) => void;
}

const SortableChipContainer = styled.div<
    Pick<ReturnType<typeof useSortable>, 'isDragging' | 'transform' | 'transition'>
>`
    transition: ${({ transition }) => transition};
    transform: ${({ transform }) => CSS.Translate.toString(transform)};
    z-index: ${({ isDragging }) => (isDragging ? 1 : 0)};
`;

const SortableChip = ({ name, onClose }: SortableChipProps) => {
    const { attributes, setNodeRef, transform, transition, isDragging, listeners } = useSortable({ id: name });

    return (
        <SortableChipContainer
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            transition={transition}
            transform={transform}
            isDragging={isDragging}
        >
            <Chip
                leadingIconProps={{ name: 'drag' }}
                closeButton={{
                    onPointerDown: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.stopPropagation();
                        onClose(name);
                    },
                }}
                label={name}
            />
        </SortableChipContainer>
    );
};

const SortableList = forwardRef<HTMLDivElement, ISortableListProps>((props, ref) => {
    const { onChange, items } = props;
    const onDargEnd = ({ active, over }: DragEndEvent) => {
        if (over === null) {
            return;
        }
        if (active.id === over.id) {
            return;
        }
        const oldIx = items.findIndex((filter) => filter === active.id);
        const newIx = items.findIndex((filter) => filter === over.id);
        const newState = arrayMove(items, oldIx, newIx);
        onChange(newState);
    };
    const onCloseFn = (name: string) => {
        const ix = items.findIndex((item) => item === name);
        const newState = [...items.slice(0, ix), ...items.slice(ix + 1)];
        onChange(newState);
    };

    return (
        <DndContext onDragEnd={onDargEnd}>
            <SortableContext items={items}>
                <Stack ref={ref} alignItems='center' direction='row' spacing={4} padding={5}>
                    {items.map((item) => (
                        <SortableChip onClose={onCloseFn} key={item} name={item} />
                    ))}
                </Stack>
            </SortableContext>
        </DndContext>
    );
});

SortableList.displayName = 'SortableList';
export default SortableList;
