import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { LoadingState } from 'common/interface/general';

const debounce = (
    setLoadingState: Dispatch<SetStateAction<LoadingState>>,
    delay = 300,
): ((loadingState: LoadingState) => void) => {
    let timeout: any, pendingLoadingState: LoadingState | undefined;

    const executeNow = (loadingState: LoadingState) => {
        clearTimeout(timeout);
        pendingLoadingState = undefined;
        setLoadingState(loadingState);
    };

    const executeLater = (loadingState: LoadingState) => {
        if (pendingLoadingState === loadingState) {
            return;
        }
        clearTimeout(timeout);
        pendingLoadingState = loadingState;
        timeout = setTimeout(() => executeNow(loadingState), delay);
    };

    return (loadingState: LoadingState) => {
        // in case state is loading, execute the function after the delay, also prevent multiple calls with the same state
        if (loadingState === LoadingState.IS_LOADING) {
            executeLater(loadingState);
        } else {
            // in case state is not loading, execute the function immediately
            executeNow(loadingState);
        }
    };
};

const useLoadingStateWithDebounce = (): [LoadingState, (loadingState: LoadingState) => void] => {
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_INITIALIZING);
    const executeRef: any = useRef(debounce(setLoadingState));
    return [loadingState, executeRef.current];
};

export default useLoadingStateWithDebounce;
