import moment from 'moment-mini';
import { EDataItem, IDataItem, IExclusion, IInitFilters } from '../exclusions.type';

export const getEnvironmentName = (exclusion: IExclusion, data: IDataItem) => {
    if (!exclusion || !exclusion.cloudAccountIds) return '';
    const displayName: string[] = [];
    exclusion.cloudAccountIds.forEach((cloudAccountId) => {
        const account = data.allAccounts.find((account) => account.id === cloudAccountId);
        displayName.push(account ? `${account.name} (${account.externalId})` : '');
    });
    return displayName.join(', ');
};

export const generateFilterString = (exclusion: IExclusion) => {
    if (!exclusion) return '';
    const { logicExpressions } = exclusion;
    if (logicExpressions && logicExpressions.length) {
        return logicExpressions.join(', ');
    }
    return '';
};

export const generateDateRangeString = (exclusion: IExclusion) => {
    if (!exclusion || !exclusion.dateRange || !exclusion.dateRange.from || !exclusion.dateRange.to) return '';
    const { from, to } = exclusion.dateRange;
    return `${moment.utc(from).format('DD/MM/YYYY')} - ${moment.utc(to).format('DD/MM/YYYY')}`;
};

export const getRuleSetName = (exclusion: IExclusion, data: IDataItem) => {
    if (!exclusion) return '';
    return data.ruleSet.find((ruleSet) => ruleSet.id.toString() === exclusion.rulesetId.toString())?.name || '';
};

export const getRuleName = (exclusion: IExclusion) => {
    if (!exclusion || !exclusion.rules) return '';
    return exclusion.rules.map((rule) => rule.name).join(', ');
};

export const factorExclusion = (exclusion: IExclusion, data: IDataItem) => {
    return {
        ...exclusion,
        ruleSet: getRuleSetName(exclusion, data),
        ruleName: getRuleName(exclusion),
        environment: getEnvironmentName(exclusion, data),
        filter: generateFilterString(exclusion),
        dateRangeString: generateDateRangeString(exclusion),
    };
};

export const applyFilters = (key: EDataItem, items: IDataItem, initFilters?: IInitFilters) => {
    const isArray = Array.isArray(items);
    if (!initFilters || !initFilters[key]) {
        return isArray ? items : [items];
    }
    if (!isArray) return [items];
    const filter = initFilters[key];
    if (!filter) return items;
    return items.filter((item) =>
        Object.entries(filter).every(([filterKey, filterValues]) => {
            const itemValue = item[filterKey];
            return filterValues.has(itemValue);
        }),
    );
};

export const factorExclusions = (data: IDataItem): IExclusion[] => {
    const exclusions = data.exclusions;
    if (!exclusions) return [];
    return exclusions.map((exclusion) => factorExclusion(exclusion, data));
};
