import {
    FilterTreeItemType,
    IFilterTreeCondition,
    IFilterTreeFieldDefinition,
    IFilterTreeItem,
    IFilterTreeNode, IFilterTreeSettings,
} from './FilterTree.interface';
import { CompoundFilterLogicalOperator } from './CompoundFilter';
import { updateDeepErrorsInFilterTree } from './FilterTree.errors';

let idCounter = 0;
const getNewId = () => {
    idCounter++;
    return String(idCounter);
};

export const createTreeCondition = (parentNode: IFilterTreeNode, insertInIndex = 0): IFilterTreeCondition => {
    const condition: IFilterTreeCondition = {
        name: '',
        values: [],
        operator: undefined,
        id: getNewId(),
        parentNode,
        itemType: FilterTreeItemType.condition,
    };
    parentNode.childItems.splice(insertInIndex, 0, condition);
    return condition;
};

export const createTreeNode = (logicalOperator: CompoundFilterLogicalOperator, parentNode?: IFilterTreeNode, insertInIndex = 0): IFilterTreeNode => {
    const node: IFilterTreeNode = {
        logicalOperator,
        childItems: [],
        id: getNewId(),
        parentNode,
        itemType: FilterTreeItemType.node,
    };
    if (parentNode) {
        parentNode.childItems.splice(insertInIndex, 0, node);
    }
    return node;
};

const cloneTreeCondition = (condition: IFilterTreeCondition, clonedParentNode: IFilterTreeNode): IFilterTreeCondition => {
    return {
        name: condition.name,
        values: condition.values,
        operator: condition.operator,
        nameErrorMsg: condition.nameErrorMsg,
        operatorErrorMsg: condition.operatorErrorMsg,
        valuesErrorMsg: condition.valuesErrorMsg,
        parentNode: clonedParentNode,
        id: condition.id,
        itemType: FilterTreeItemType.condition,
    };
};

const cloneTreeItem = (item: IFilterTreeItem, clonedParentNode: IFilterTreeNode): IFilterTreeItem => {
    if (item.itemType === FilterTreeItemType.node) {
        return cloneTreeNode(item as IFilterTreeNode, clonedParentNode);
    }
    return cloneTreeCondition(item as IFilterTreeCondition, clonedParentNode);
};

const cloneTreeNode = (node: IFilterTreeNode, clonedParentNode?: IFilterTreeNode): IFilterTreeNode => {
    const clonedNode: IFilterTreeNode = {
        logicalOperator: node.logicalOperator,
        parentNode: clonedParentNode,
        id: node.id,
        childItems: [],
        itemType: FilterTreeItemType.node,
    };

    node.childItems.forEach(item => {
        clonedNode.childItems.push(cloneTreeItem(item, clonedNode));
    });
    return clonedNode;
};

export const cloneFilterTreeRoot = (root: IFilterTreeNode, filterDefs: IFilterTreeFieldDefinition[], updateErrors?: boolean, settings?: IFilterTreeSettings): IFilterTreeNode => {
    const clonedRoot: IFilterTreeNode = cloneTreeNode(root);
    if (updateErrors) {
        updateDeepErrorsInFilterTree(clonedRoot, filterDefs, settings);
    }
    return clonedRoot;
};