export const GENERAL_BOOLEAN_VALUES = {
    TRUE: 'true',
    FALSE: 'false',
    TRUE_CAPITAL_T: 'True',
    FALSE_CAPITAL_F: 'False',
};

export const OPERATORS = {
    AND: 'and',
    OR: 'or',
    EQ: '=',
};

// general constants
export const EMPTY_STRING = '';

export const FORBIDDEN_STATUS_CODE = 403;
