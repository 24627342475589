import { ColumnApi, ColumnVO, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { getHttpService, ICachingConfig } from 'common/interface/services';
import {
    AgGridSortModel,
    CGColDef,
    ColumnType,
    GroupByAdditionalFields,
    GroupByProperty,
    GroupByRequest,
    GroupByResponse,
    IAdditionalFieldsRequest,
    IProtectedAssetViewModel,
    ProtectedAssetsResponse,
    RequestFilter,
    SearchRequest,
} from './ProtectedAssetsTable.interface';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { CounterStatus } from './ProtectedAssetsTable';
import { Direction, SortingModel } from '../Findings/Findings.interface';
import {
    ASSETS_EXPORT_CSV_DOWNLOAD,
    ASSETS_EXPORT_CSV_EMAIL,
    ASSETS_GROUPING_URL,
    ASSETS_SEARCH_URL,
} from '../../module_interface/assets/AssetsConsts';

export interface IDataSourceConfig {
    filters: IFiltersValues;
    pageSize: number;
    externalAdditionalFields?: IAdditionalFieldsRequest;
    defaultSortModel?: SortingModel;
    aggregateMaxRiskScore?: boolean;
    filterEntitiesWithoutRiskScore?: boolean;
    cachingConfig?: ICachingConfig;
}

export enum AggregationLevel {
    ALL_BASE_FIELDS,
    NONE,
}

export interface ICustomAggregation {
    aggregationFields?: string[];
    optionalAggregationFields?: string[];
}

export type AggregationConfig = AggregationLevel | ICustomAggregation;
export const AssetAggregationCacheConfig: ICachingConfig = { useCache: true, dataAgeLimit: 60 };

export async function getDataFromServer(
    requestObject: SearchRequest,
    cachingConfig?: ICachingConfig,
): Promise<ProtectedAssetsResponse> {
    const serviceConfig = cachingConfig ? { cachingConfig } : undefined;
    return await getHttpService().request<ProtectedAssetsResponse>(
        ASSETS_SEARCH_URL,
        {
            data: requestObject,
            method: 'POST',
        },
        serviceConfig,
    );
}

export async function getAggregationsFromServer(
    mainAggregations?: string[],
    optionalAggregations?: string[],
    filter?: RequestFilter,
    cachingConfig?: ICachingConfig,
): Promise<Aggregations> {
    const response: ProtectedAssetsResponse = await getDataFromServer(
        {
            filter: filter || {},
            pageSize: 0,
            aggregations: mainAggregations || [],
            optionalAggregationFields: optionalAggregations || [],
        },
        cachingConfig,
    );
    return response.aggregations;
}

export async function getGroupByFromServer(
    requestObject: GroupByRequest,
    cachingConfig?: ICachingConfig,
): Promise<GroupByResponse> {
    const serviceConfig = cachingConfig ? { cachingConfig } : undefined;
    return await getHttpService().request<GroupByResponse>(
        ASSETS_GROUPING_URL,
        {
            data: requestObject,
            method: 'POST',
        },
        serviceConfig,
    );
}

export function getGroupByProperties(
    columns: ColumnVO[],
    sortField: SortingModel | undefined,
    aggregateMaxRiskScore = false,
): GroupByProperty[] {
    return columns.map((column) => {
        const direction = sortField?.direction || 0;
        return {
            property: column.field!,
            direction,
            maxSize: 1000,
            sortOption: 'BucketsCount',
            aggregateMaxRiskScore,
        };
    });
}

function getFieldDef(columnApi: ColumnApi, colId: string, byFieldName = false): CGColDef | undefined {
    if (byFieldName) {
        const columnsDefs = columnApi.getColumns();
        const foundByName = columnsDefs?.find((col) => col.getColDef().field === colId);
        if (foundByName) {
            return foundByName.getColDef();
        }
    }

    const column = columnApi.getColumn(colId);

    if (column === null) {
        return undefined;
    }

    return column.getColDef();
}

function getSortFieldName(columnApi: ColumnApi, colId: string): string | undefined {
    const column = columnApi.getColumn(colId);
    if (column === null) {
        return undefined;
    }
    const colDef = getFieldDef(columnApi, colId);
    return colDef?.sortField || colDef?.field || column.getColDef().field;
}

function getSortOrder(sortModels: AgGridSortModel[], columnApi: ColumnApi): SortingModel | undefined {
    if (sortModels.length === 0) {
        return undefined;
    }

    const fieldName = getSortFieldName(columnApi, sortModels[0].colId);
    if (fieldName) {
        let direction: Direction = 1;
        if (sortModels[0].sort === 'desc') {
            direction = -1;
        }
        return {
            direction,
            fieldName,
        };
    }
}

class SearchGroup {
    public filters: IFiltersValues;
    public pageSize: number;
    public searchAfter: string[];
    public currentRows: number;

    constructor(filters: IFiltersValues, pageSize: number) {
        this.filters = filters;
        this.pageSize = pageSize;
        this.searchAfter = [];
        this.currentRows = 0;
    }

    reset() {
        this.searchAfter = [];
        this.currentRows = 0;
    }
}

const DEFAULT_GROUP_KEY = JSON.stringify([]);

function getAggregatedProperties(rowGroupCols: ColumnVO[]): string {
    return JSON.stringify(rowGroupCols.map((col) => col.id));
}

export class Datasource implements IServerSideDatasource {
    private searchGroups: Map<string, SearchGroup>;
    private aggregatedData: GroupByResponse;
    private aggregatedProperties: string;
    private defaultSortModel?: SortingModel;
    private sortField: string;
    private externalAdditionalFields?: IAdditionalFieldsRequest;
    private aggregateMaxRiskScore: boolean;
    private filterEntitiesWithoutRiskScore?: boolean;
    private cachingConfig?: ICachingConfig;
    public totalCount: number | CounterStatus;

    private getDefaultSearchGroup(): SearchGroup {
        const searchGroup = this.searchGroups.get(DEFAULT_GROUP_KEY);
        if (!searchGroup) {
            throw new Error('Default search group was not set for datasource');
        }
        return searchGroup;
    }

    private getSearchGroup(groups: string[]): SearchGroup | undefined {
        return this.searchGroups.get(JSON.stringify(groups));
    }

    private addSearchGroup(groups: string[], searchGroup: SearchGroup): void {
        this.searchGroups.set(JSON.stringify(groups), searchGroup);
    }

    private getSortField() {
        return this.sortField;
    }

    private serializeSortField(field?: SortingModel) {
        return field ? JSON.stringify(field) : '';
    }

    private setSortField(sortField?: SortingModel) {
        if (sortField) {
            this.sortField = this.serializeSortField(sortField);
        }
    }

    public async exportCsv(isEmail = false, withCurrentFilter = false, recipents?: string[]): Promise<ArrayBuffer> {
        const requestURL = isEmail ? ASSETS_EXPORT_CSV_EMAIL : ASSETS_EXPORT_CSV_DOWNLOAD;
        const filters = this.getDefaultSearchGroup().filters;
        if (!withCurrentFilter) {
            delete filters.fields;
        }
        const requestObject: any = {
            searchRequest: {
                filter: filters,
                pageSize: 50,
                searchAfter: [],
                externalAdditionalFields: {
                    source: 'ThirdPartyMs',
                },
                filterEntitiesWithoutRiskScore: false,
                skipAggregations: true,
            },
        };

        if (isEmail) {
            requestObject.recipients = recipents;
        }
        return await getHttpService().post<ArrayBuffer>(requestURL, {
            data: requestObject,
            responseType: 'arraybuffer',
        });
    }

    private generateSearchGroup(groupKeys: string[], rowGroupCols: ColumnVO[], columnApi: ColumnApi): SearchGroup {
        const defaultSearchGroup = this.getDefaultSearchGroup();
        const filters = JSON.parse(JSON.stringify(defaultSearchGroup.filters));
        let fields = filters.fields ?? [];
        for (let i = 0; i < groupKeys.length && i < rowGroupCols.length; i++) {
            let fieldName = rowGroupCols[i].field;
            const fieldDef = getFieldDef(columnApi, rowGroupCols[i].id);
            fields = fields.filter((field: { name: string; value: string }) => {
                return field.name !== fieldName;
            });
            if (fieldDef?.columnType === ColumnType.Additional) {
                fieldName = `additionalFields|${fieldName}`;
            }
            fields.push({ name: fieldName, value: groupKeys[i] });
        }
        filters.fields = fields;
        return new SearchGroup(filters, defaultSearchGroup.pageSize);
    }

    constructor(config: IDataSourceConfig) {
        this.searchGroups = new Map<string, SearchGroup>();
        this.searchGroups.set(DEFAULT_GROUP_KEY, new SearchGroup(config.filters, config.pageSize));
        this.aggregatedData = [];
        this.aggregatedProperties = '';
        this.sortField = '';
        this.defaultSortModel = config.defaultSortModel;
        this.totalCount = CounterStatus.Pending;
        this.externalAdditionalFields = config.externalAdditionalFields;
        this.aggregateMaxRiskScore = config.aggregateMaxRiskScore ?? false;
        this.filterEntitiesWithoutRiskScore = config.filterEntitiesWithoutRiskScore ?? false;
        this.cachingConfig = config.cachingConfig;
    }

    public async getAdHokDataFromServer(
        aggregationConfig: AggregationConfig = AggregationLevel.ALL_BASE_FIELDS,
        pageSize = 0,
        requestFilter?: RequestFilter,
    ): Promise<ProtectedAssetsResponse> {
        const requestObject: SearchRequest = {
            filter: requestFilter || this.getDefaultSearchGroup().filters,
            pageSize,
            externalAdditionalFields: this.externalAdditionalFields,
            sorting: this.defaultSortModel,
            filterEntitiesWithoutRiskScore: this.filterEntitiesWithoutRiskScore,
        };
        if (typeof aggregationConfig === 'object') {
            const customAggregation = aggregationConfig as ICustomAggregation;
            requestObject.aggregations = customAggregation.aggregationFields;
            requestObject.optionalAggregationFields = customAggregation.optionalAggregationFields;
            const noBaseAggregations =
                customAggregation.aggregationFields && customAggregation.aggregationFields.length === 0;
            const noOptionalAggregation =
                !customAggregation.optionalAggregationFields ||
                customAggregation.optionalAggregationFields.length === 0;
            requestObject.skipAggregations = noBaseAggregations && noOptionalAggregation;
        } else {
            const aggregationLevel = aggregationConfig as AggregationLevel;
            switch (aggregationLevel) {
                case AggregationLevel.ALL_BASE_FIELDS:
                    requestObject.skipAggregations = false;
                    break;
                case AggregationLevel.NONE:
                    requestObject.skipAggregations = true;
                    break;
            }
        }

        try {
            const data = await getDataFromServer(requestObject, this.cachingConfig);
            this.totalCount = data.totalCount;
            return data;
        } catch (err) {
            this.totalCount = CounterStatus.Error;
            return {
                aggregations: {},
                assets: [],
                totalCount: CounterStatus.Error,
            };
        }
    }

    public async getAdHokGroupDataFromServer(
        propertiesList: GroupByProperty[],
        additionalFields?: GroupByAdditionalFields,
    ) {
        const payload: GroupByRequest = {
            filter: this.getDefaultSearchGroup().filters,
            propertiesList,
            filterEntitiesWithoutRiskScore: this.filterEntitiesWithoutRiskScore,
            additionalFields,
        };
        return await getGroupByFromServer(payload, this.cachingConfig);
    }

    public async getDataToExport({ withCurrentFilters = false }) {
        const filters = withCurrentFilters ? this.getDefaultSearchGroup().filters : {};
        const requestObject: SearchRequest = {
            filter: filters,
            pageSize: 1000,
            externalAdditionalFields: this.externalAdditionalFields,
            sorting: this.defaultSortModel,
            filterEntitiesWithoutRiskScore: this.filterEntitiesWithoutRiskScore,
            skipAggregations: true,
        };
        let lastResponse = await getDataFromServer(requestObject);
        let assets: IProtectedAssetViewModel[] = lastResponse.assets;
        const numberOfIterations = Math.ceil(lastResponse.totalCount / 1000);

        for (let i = 0; i < numberOfIterations - 1; i++) {
            requestObject.searchAfter = lastResponse.searchAfter;
            lastResponse = await getDataFromServer(requestObject);
            assets = assets.concat(lastResponse.assets);
        }
        return assets;
    }

    public getRows(params: IServerSideGetRowsParams): void {
        //If we know the searches are not going to return anything because initial data returned 0 total count, no point in making more calls to the server
        if (this.totalCount === 0) {
            params.success({
                rowData: [],
                rowCount: 0,
            });
            return;
        }
        if (params.request.rowGroupCols.length > 0) {
            this.handleGrouping(params);
        } else {
            this.handleSearch(params, this.getDefaultSearchGroup());
        }
    }

    private getAggregatedData(values: string[], columnApi: ColumnApi): any[] {
        const result: any[] = [];
        let currentDocument = this.aggregatedData;
        const rowTemplate: any = {};
        for (const value of values) {
            const aggregationBucket = currentDocument.find((document) => document.fieldValue === value);
            if (aggregationBucket === undefined) {
                return result;
            }
            rowTemplate[aggregationBucket.fieldName] = aggregationBucket.fieldValue;
            currentDocument = aggregationBucket.nestedBuckets;
        }
        const columns = columnApi.getAllDisplayedColumns();

        for (const document of currentDocument) {
            const row = Object.assign({}, rowTemplate);
            for (const column of columns) {
                const colDef: CGColDef = column.getColDef();
                if (colDef.groupFieldGetter && colDef.field) {
                    row[colDef.field] = colDef.groupFieldGetter(document);
                }
            }
            row[document.fieldName] = document.fieldValue;
            const parts = document.fieldName.split('|');
            if (parts.length === 2) {
                // Break name with '.' or '|' and create hierarchy
                const parentName = parts[0];
                const childName = parts[1];
                row[parentName] = {
                    [childName]: document.fieldValue,
                };
            }
            const fieldDef = getFieldDef(columnApi, document.fieldName, true);
            // If the field is an additional field, we need to add it to the additional fields array
            if (fieldDef?.columnType === ColumnType.Additional) {
                row.additionalFields = [{ name: document.fieldName, value: document.fieldValue }];
            }
            row.childCount = document.numberOfDocuments;
            row.isGrouped = true;
            if (document.representingDocument) {
                Object.assign(row, document.representingDocument);
            }
            result.push(row);
        }
        return result;
    }

    private handleSearch(params: IServerSideGetRowsParams, group: SearchGroup) {
        if (params.request.startRow === 0) {
            group.reset();
        }
        const sortModels: AgGridSortModel[] = params.request.sortModel;
        const payload: SearchRequest = {
            filter: group.filters,
            pageSize: group.pageSize,
            searchAfter: group.searchAfter,
            externalAdditionalFields: this.externalAdditionalFields,
            filterEntitiesWithoutRiskScore: this.filterEntitiesWithoutRiskScore,
            skipAggregations: true,
        };
        if (sortModels.length > 0) {
            const colId = sortModels[0].colId;
            const columnDef = getFieldDef(params.columnApi, colId);
            // In case of additional field sorting, we need to send the sort field to the server
            const sortOrder = getSortOrder(sortModels, params.columnApi);
            switch (columnDef?.columnType) {
                case ColumnType.Additional:
                    payload.additionalFields = {
                        source: columnDef.additionalFieldSource,
                        sortField: sortOrder,
                    };
                    break;
                default:
                    payload.sorting = sortOrder;
                    break;
            }
        } else {
            payload.sorting = this.defaultSortModel;
        }
        getDataFromServer(payload, this.cachingConfig)
            .then(async (data) => {
                group.searchAfter = data.searchAfter || [];
                group.currentRows += data.assets.length;
                const rowCount = group.currentRows === data.totalCount ? data.totalCount : -1;
                params.success({
                    rowData: data.assets,
                    rowCount,
                });
            })
            .catch((err) => {
                params.context = {
                    error: err,
                };
                params.fail();
                console.error(`Could not fetch data from server getting ${ASSETS_SEARCH_URL} - ${err}`);
            });
    }

    private handleGrouping(params: IServerSideGetRowsParams) {
        const rowGroupCols = params.request.rowGroupCols;
        const groupKeys = params.request.groupKeys;
        if (rowGroupCols.length !== groupKeys.length) {
            this.resetAllSearchGroups();
            this.getGroupsData(rowGroupCols, groupKeys, params);
        } else {
            let searchGroup = this.getSearchGroup(groupKeys);
            if (searchGroup === undefined) {
                searchGroup = this.generateSearchGroup(groupKeys, rowGroupCols, params.columnApi);
                this.addSearchGroup(groupKeys, searchGroup);
            }

            this.handleSearch(params, searchGroup);
        }
    }

    private modelChanged(rowGroupCols: ColumnVO[], columnApi: ColumnApi, sortModels: AgGridSortModel[]) {
        // This function checks if the sort / grouping model has changed.
        return (
            this.aggregatedProperties === getAggregatedProperties(rowGroupCols) &&
            this.getSortField() === this.serializeSortField(getSortOrder(sortModels, columnApi))
        );
    }

    private getGroupsData(rowGroupCols: ColumnVO[], groupKeys: string[], params: IServerSideGetRowsParams) {
        const aggregatedProperties = getAggregatedProperties(rowGroupCols);
        const sortModels: AgGridSortModel[] = params.request.sortModel;
        const sortOrder = getSortOrder(sortModels, params.columnApi);
        if (this.modelChanged(rowGroupCols, params.columnApi, params.request.sortModel)) {
            const rows = this.getAggregatedData(groupKeys, params.columnApi);
            params.success({
                rowData: rows.slice(params.request.startRow, params.request.endRow),
                rowCount: rows.length,
            });
        } else {
            const searchGroup = this.getDefaultSearchGroup();
            const payload: GroupByRequest = {
                filter: searchGroup.filters,
                propertiesList: getGroupByProperties(rowGroupCols, sortOrder, this.aggregateMaxRiskScore),
                filterEntitiesWithoutRiskScore: this.filterEntitiesWithoutRiskScore,
            };
            // In case of additional field grouping, we need to send the additional field source to the server
            params.request.rowGroupCols.forEach((field) => {
                const fieldDef = getFieldDef(params.columnApi, field.id);
                if (fieldDef?.additionalFieldSource) {
                    payload.additionalFields = {
                        source: fieldDef.additionalFieldSource,
                    };
                }
            });
            getGroupByFromServer(payload, this.cachingConfig)
                .then((data) => {
                    this.aggregatedProperties = aggregatedProperties;
                    this.setSortField(sortOrder);
                    this.aggregatedData = data;
                    const rows = this.getAggregatedData(groupKeys, params.columnApi);

                    params.success({
                        rowData: rows,
                        rowCount: rows.length,
                    });
                })
                .catch((err) => {
                    params.fail();
                    console.error(`Could not get grouping from server ${ASSETS_GROUPING_URL} - ${err}`);
                });
        }
    }

    private resetAllSearchGroups() {
        const defaultSearchGroup = this.getDefaultSearchGroup();
        this.searchGroups = new Map<string, SearchGroup>();
        this.searchGroups.set(
            DEFAULT_GROUP_KEY,
            new SearchGroup(defaultSearchGroup.filters, defaultSearchGroup.pageSize),
        );
    }
}
