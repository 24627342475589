import React, { useRef, useState } from 'react';
import { FilterTreeItemType, IFilterTreeCommonProps, IFilterTreeNode } from '../FilterTree.interface';
import { FilterStyled } from './FilterTree.styled';
import { FilterTreeItem } from './FilterTreeItem';
import { AddButtonsBox } from './AddButtonsBox';
import { Popover, Stack } from '../../index';
import _ from 'lodash';

export const NodeAddConditionButton: React.FC<{ node: IFilterTreeNode; filterProps: IFilterTreeCommonProps }> = ({
    node,
    filterProps,
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    return (
        <Stack direction={'column'}>
            <FilterStyled.AddConditionButton
                label={'Add Condition'}
                variant={'outlined'}
                color={'normal'}
                ref={buttonRef}
                iconProps={{ name: 'plus', size: 16 }}
                onClick={() => filterProps.api.onAddCondition(node, _.size(node.childItems))}
            />
        </Stack>
    );
};

export const NodeAddButton: React.FC<{ node: IFilterTreeNode; filterProps: IFilterTreeCommonProps }> = ({
    node,
    filterProps,
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    return (
        <Stack direction={'column'}>
            <FilterStyled.ChevronButton
                variant={'outlined'}
                color={'normal'}
                ref={buttonRef}
                iconProps={{ name: 'chevronDown', size: 16 }}
                onClick={() => setShowPopup(true)}
            />
            <Popover open={showPopup} anchorEl={buttonRef.current} onClose={() => setShowPopup(false)}>
                <AddButtonsBox
                    parentNode={node}
                    insertionIndex={_.size(node.childItems)}
                    filterProps={filterProps}
                    extraOnClick={() => setShowPopup(false)}
                />
            </Popover>
        </Stack>
    );
};

export const NodeAddBox: React.FC<{ node: IFilterTreeNode; filterProps: IFilterTreeCommonProps }> = ({
    node,
    filterProps,
}) => {
    return (
        <Stack direction={'row'}>
            <NodeAddConditionButton node={node} filterProps={filterProps} />
            <NodeAddButton node={node} filterProps={filterProps} />
        </Stack>
    );
};

export const FilterTreeNode: React.FC<{ node: IFilterTreeNode; filterProps: IFilterTreeCommonProps }> = ({
    node,
    filterProps,
}) => {
    return (
        <FilterStyled.TreeNodeContentDiv>
            <FilterTreeItem item={node} filterProps={filterProps} />
            <FilterStyled.SubItemsGroupDiv>
                {node.childItems.map((item) => {
                    if (item.itemType === FilterTreeItemType.node) {
                        return (
                            <FilterTreeNode node={item as IFilterTreeNode} filterProps={filterProps} key={item.id} />
                        );
                    }
                    return <FilterTreeItem item={item} filterProps={filterProps} key={item.id} />;
                })}
            </FilterStyled.SubItemsGroupDiv>
        </FilterStyled.TreeNodeContentDiv>
    );
};
