import { Aggregations, IFiltersValues } from '../../components/FilterPanel/FilterPanel.interface';
import { ILocalFilter } from './SimpleFilterPanel.interfaces';

export const computeAggregationCounts = <T>(filteredData: any, localFilters: ILocalFilter<T>[]): Aggregations => {
    for (const item of filteredData) {
        for (const filter of localFilters) {
            const { options, getOptionsFromDataItem } = filter;
            const option = getOptionsFromDataItem?.(item);

            let isOptionExist = false;
            options?.forEach((filterOption) => {
                if (option === filterOption.value) {
                    filterOption.count++;
                    isOptionExist = true;
                }
            });
            option !== undefined && !isOptionExist && filter.options?.push({ value: option, count: 1 });
        }
    }
    const filtersWithCounts = {} as { [key: string]: { count: number; value: string | number | boolean }[] };
    localFilters.forEach((filter) => {
        filtersWithCounts[filter.key] = filter.options!;
        filter.options = [];
    });
    return filtersWithCounts;
};

export const filterNameDataForFreeText = (item: { name: string }, text: string) => {
    return item?.name?.toLowerCase().includes(text.toLowerCase());
};

export const filterData = (options: any, item: any, shouldShowItem?: Function) => {
    if (options === '' || (Array.isArray(options) && options.length === 0)) {
        return true;
    } else if (Array.isArray(options)) {
        for (const option of options) {
            if (shouldShowItem?.(option, item)) {
                return true;
            }
        }
    } else return shouldShowItem?.(options, item);

    return false;
};

export const calcFilteredData = <T>(localFilters: ILocalFilter<T>[], filtersValues: IFiltersValues, rawData: any[]) =>
    rawData.filter((data: any) => {
        return (
            localFilters.filter((filter) => filterData(filtersValues[filter.key], data, filter.shouldShowDataItem))
                .length === localFilters.length
        );
    });
