import { useEffect } from 'react';
import useReactRouterQuery from './useReactRouterQuery';

const useModalFromUrl = () => {
    const {
        params: { modalType: modalTypeFromUrl },
        deleteParams,
    } = useReactRouterQuery();

    useEffect(() => {
        if (modalTypeFromUrl) {
            deleteParams(['modalType']);
        }
    }, [deleteParams, modalTypeFromUrl]);

    return { modalTypeFromUrl };
};

export default useModalFromUrl;
