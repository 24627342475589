import styled from 'styled-components';
import { Modal } from '../../../../../design-system/components-v2';
const TopModal = styled(Modal.ModalDialog)`
    
`;

const ContentDiv = styled(Modal.ModalContent)<{ isShortDialog?: boolean, minHeight?: number }>`
    height: ${props => props.isShortDialog ? undefined : '700px'};
    max-height: 100%;
    width: 100%;
    padding: 16px 24px;
    min-height: ${props => props.minHeight ? `${props.minHeight}px` : '300px'};
`;

export const DialogFormStyle = {
    TopModal,
    ContentDiv,
};
