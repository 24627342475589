import React from 'react';
import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Button, Spinner, Typography } from 'common/design-system/components-v2';
import FooterStyles from './Footer.styles';
import { Trans, useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';

export interface FooterProps<T> {
    gridApi: GridApi | undefined;
    columnApi: ColumnApi | undefined;
    gridOptions: GridOptions<T>;
    selectedRows: T[];
    currentRows?: string | number;
    totalRows?: string | number;
    footerSuffix?: string;
    isLoading?: boolean;
    isFetching?: boolean;
    customFooter?: string | React.ReactNode;
}

function Footer<T = any>(props: React.PropsWithChildren<FooterProps<T>>) {
    const { t } = useTranslation(getCompsI18nNS('table'));
    const {
        gridApi,
        selectedRows,
        gridOptions,
        currentRows,
        totalRows,
        footerSuffix,
        isLoading,
        isFetching,
        customFooter,
    } = props;

    const hasRowSelection = React.useMemo(() => {
        return !!gridOptions.columnDefs?.some((column: ColDef) => column.checkboxSelection);
    }, [gridOptions]);

    const showRows = React.useMemo(() => {
        const isDefined = currentRows !== undefined && totalRows !== undefined;
        const isEmpty = Number(currentRows) === 0 && Number(totalRows) === 0;
        return isDefined && !isEmpty;
    }, [currentRows, totalRows]);

    const showSpinner = React.useMemo(() => {
        return isFetching && !isLoading;
    }, [isLoading, isFetching]);

    if (!hasRowSelection && !currentRows && !totalRows && !customFooter) {
        return null;
    }

    return (
        <FooterStyles.Wrapper
            fullWidth
            padding={[2, 0, 0, 0]}
            spacing={6}
            direction='row'
            alignItems='center'
            data-aid='table-component_footer'
        >
            {hasRowSelection && (
                <Typography>
                    <Trans
                        components={{
                            boldText: (
                                <Typography type='key' elementType='span'>
                                    .
                                </Typography>
                            ),
                            deselectAll: (
                                <Button
                                    variant='text'
                                    color='brandPrimary'
                                    removePadding
                                    onClick={() => gridApi?.deselectAll()}
                                    dataAid='DS_Table-deselectAll'
                                >
                                    .
                                </Button>
                            ),
                        }}
                        i18nKey={`${getCompsI18nNS('table')}:TOOLBAR.SELECTED_ROWS`}
                        values={{ count: selectedRows.length }}
                    />
                </Typography>
            )}
            {customFooter ? (
                typeof customFooter === 'string' ? (
                    <Typography>{customFooter}</Typography>
                ) : (
                    customFooter
                )
            ) : (
                <>
                    {showRows && (
                        <Typography>
                            {t(`TOOLBAR.SHOWING_ROWS${totalRows === undefined ? '' : '__WITH_TOTAL'}`, {
                                currentRows: (currentRows || 0).toLocaleString(),
                                totalRows: (totalRows || 0).toLocaleString(),
                                suffix: footerSuffix || t('TOOLBAR.DEFAULT_SUFFIX'),
                            })}
                        </Typography>
                    )}
                    {showSpinner && <Spinner size={12} context='info' saturation />}
                </>
            )}
        </FooterStyles.Wrapper>
    );
}

export default Footer;
