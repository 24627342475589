import { buildPath } from './AddinContainer';

//Main menu
export const MENU_ITEMS = buildPath('mainMenu', 'items');

//SideBar
export const SIDEBAR_COMPONENTS = buildPath('subMenu', 'components');

//Global search
export const GLOBAL_SEARCH_SEARCHERS = buildPath('globalSearch', 'searchers');
