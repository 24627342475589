import './CaptchaValidationForm.scss';
import i18n from 'common/services/translations/translations';
import { getHttpService } from '../../interface/services';

const EMAIL_INVALID_FORMAT = 'EMAIL_INVALID_FORMAT';
const EMAIL_NON_ENTERPRISE = 'EMAIL_NON_ENTERPRISE';
const INVALID_RECAPTCHA = 'INVALID_RECAPTCHA';

class ClientMessageError extends Error {}

export const CaptchaValidationFormContentType = {
    FORGOT_PASSWORD: {
        title: i18n.t('FORMS.CAPTCHA_FORM.FORGOT_PASSWORD_TITLE'),
        subTitle: i18n.t('FORMS.CAPTCHA_FORM.FORGOT_PASSWORD_SUBTITLE'),
        action: (data: any) => {
            const httpService = getHttpService();
            return httpService.request('auth/reset-password-request', { method: 'POST', data }, { publicMode: true });
        },
    },
    REGISTER: {
        title: i18n.t('FORMS.CAPTCHA_FORM.REGISTER_TITLE'),
        subTitle: i18n.t('FORMS.CAPTCHA_FORM.REGISTER_SUBTITLE'),
        action: (data: any) => {
            const httpService = getHttpService();
            return httpService.request<any>(
                'account-registration/invite',
                { method: 'POST', data },
                { publicMode: true },
                (error) => {
                    const errorCode = error?.response?.status || -1;
                    if (errorCode === 400) {
                        const responseText = error.response?.data?.message;
                        let translated = responseText;
                        switch (responseText) {
                            case EMAIL_INVALID_FORMAT:
                                translated = i18n.t('FORMS.CAPTCHA_FORM.EMAIL_ERROR');
                                break;
                            case EMAIL_NON_ENTERPRISE:
                                translated = i18n.t('FORMS.CAPTCHA_FORM.EMAIL_ERROR2');
                                break;
                            case INVALID_RECAPTCHA:
                                translated = i18n.t('FORMS.CAPTCHA_FORM.CAPTCHA_INVALID');
                                break;
                        }
                        if (translated) {
                            throw new ClientMessageError(translated);
                        }
                    }
                    throw error;
                },
            );
        },
    },
};
