import React from 'react';
import { VulnerabilitiesProps, VulnerabilityItem } from './CountBySeverity.types';
import { CountWithSeverity, TypographySeverityCount, VulnerabilitiesWrapper } from './CountBySeverity.styled';
import { Stack, Typography, Tooltip } from '../index';
import { formatNumberShorthand } from 'common/utils/helpFunctions';
import { Context } from '../../theme/colors/colors.types';
import { capitalizeFirstLetter } from 'common/utils/functions';

const severityOrder: Array<Context> = ['critical', 'high', 'medium', 'low', 'info'];
const sortBySeverity = (a: VulnerabilityItem, b: VulnerabilityItem, sortOrder: 'ase' | 'des'): number => {
    const orderMultiplier = sortOrder === 'des' ? 1 : -1;
    return orderMultiplier * severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity);
};

const CountBySeverity: React.FC<VulnerabilitiesProps> = ({
    severityCounts,
    sortOrder = 'des',
    customWidth,
    isBigSize,
}) => (
    <VulnerabilitiesWrapper fullWidth alignItems={'center'} direction={'row'} spacing={1}>
        {severityCounts
            .sort((severityA, severityB) => sortBySeverity(severityA, severityB, sortOrder))
            .map((item) => (
                <Tooltip key={item.severity} disabled={Number(item.count) < 999} content={item.count} placement={'top'}>
                    <CountWithSeverity
                        spacing={3}
                        direction={'column'}
                        alignItems={'center'}
                        isBigSize={isBigSize}
                        severity={item.severity}
                        customWidth={customWidth}
                        count={item.count}
                    >
                        <Stack direction={'column'}>
                            {isBigSize && item.severity && (
                                <Typography
                                    type='aside'
                                    context={item.count ? item.severity : 'default'}
                                    variant='body500'
                                >
                                    {capitalizeFirstLetter(item.severity)}
                                </Typography>
                            )}
                            <TypographySeverityCount
                                type={'key'}
                                severity={item.severity}
                                count={item.count}
                                saturation
                                isClickable={Boolean(item.onClick)}
                            >
                                {item.count ? formatNumberShorthand(item.count) : 0}
                            </TypographySeverityCount>
                        </Stack>
                    </CountWithSeverity>
                </Tooltip>
            ))}
    </VulnerabilitiesWrapper>
);

export default CountBySeverity;
