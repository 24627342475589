import Icon from '../../Icon';
import Stack from '../../Stack';
import Typography from '../../Typography';
import StepStyles from './Step.styled';
import { StepProps } from '../Stepper.types';

interface StepPropsExtend extends StepProps {
    isCompleted: boolean;
    isCurrent: boolean;
    number: number;
}

const Step: React.FC<StepPropsExtend> = ({ number, title, isCompleted, isCurrent, ...rest }: StepPropsExtend) => {
    return (
        <StepStyles.StepWrapper {...rest}>
            <Stack direction='row' alignItems='center' spacing={4}>
                <StepStyles.StepCircle
                    data-testid={`step-${number + 1}-cricle`}
                    alignItems='center'
                    justifyContent='center'
                    isCompleted={isCompleted}
                    isCurrent={isCurrent}
                >
                    {isCompleted ? (
                        <Icon name={'check'} color='success' />
                    ) : (
                        <Typography variant='bodyLg' color={'inherit'}>
                            {number}
                        </Typography>
                    )}
                </StepStyles.StepCircle>
                <StepStyles.StepTitle
                    title={title}
                    variant='body'
                    color={'inherit'}
                    isCompleted={isCompleted}
                    isCurrent={isCurrent}
                    type='key'
                >
                    {title}
                </StepStyles.StepTitle>
            </Stack>
        </StepStyles.StepWrapper>
    );
};

export default Step;
