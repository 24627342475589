import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import RiskScoreBadge from '../../RiskScore/RiskScoreBadge';

const RiskScoreCellRenderer = React.forwardRef<any, ICellRendererParams>((params) => {
    let { value } = params;

    if (typeof value === 'string' && value !== '' && !isNaN(Number(value))) {
        value = Number(value);
    }
    if (typeof value === 'number') {
        return <RiskScoreBadge score={value} />;
    }

    CellToClipboardHandler(params, String(value));
    return <></>;
});
RiskScoreCellRenderer.displayName = 'RiskScoreCellRenderer';

export default RiskScoreCellRenderer;
