import { Button } from 'common/design-system/components-v2';
import React from 'react';
import i18n from 'i18next';

interface IExpandCollapseActionProps {
    expanded: number;
    groupsNo: number;
    onClick: (value: boolean) => void;
}

const ExpandCollapseAction: React.FC<IExpandCollapseActionProps> = ({ expanded, groupsNo, onClick }) => {
    return (
        <Button
            dataAid={'collapse-expand-action-button'}
            variant='text'
            iconProps={{ name: expanded ? 'chevronUp' : 'chevronDown' }}
            label={i18n.t(`COMMON.${expanded ? 'COLLAPSE_ALL' : 'EXPAND_ALL'}`)}
            disabled={!groupsNo}
            onClick={() => {
                onClick(!!expanded);
            }}
            id={'table_action_collapse-expand-action'}
            buttonAlignment='flex-start'
        />
    );
};

export default ExpandCollapseAction;
