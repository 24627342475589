import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IFieldInfo } from '../../../interface/general';
import { FindingsAlertType } from '../../Findings/Findings.interface';
import { IEvent } from '../../../module_interface/events/Events';
import IconLinkCellRenderer, { IIconLinkCellProps } from './IconLinkCellRenderer';
import { getEventsUrl } from '../../../module_interface/assets/utils';
import { logComponentClickedEvent } from '../../../utils/logUtils';
import { changeUrl, generateHref } from '../../../utils/http';
import { getSafeFindingSeverityInfo } from '../../../consts/FindingSeverity';
import { EventType } from '../../../module_interface/events/EventsConsts';

const EventTitleCellRender: React.FC<
    ICellRendererParams<IEvent> & { componentName?: string; filterFields?: IFieldInfo[] }
> = (params) => {
    if (!params.data) {
        return <div />;
    }

    const { componentName, filterFields } = params;
    const { entityExternalId, ruleName, severity } = params.data;
    const defaultFilterFields: IFieldInfo[] = [
        {
            name: 'entityExternalId',
            value: entityExternalId,
        },
        {
            name: 'severity',
            value: severity,
        },
        {
            name: 'alertType',
            value: FindingsAlertType.SECURITY,
        },
    ];

    const totalFilterFields: IFieldInfo[] = filterFields
        ? defaultFilterFields.concat(filterFields)
        : defaultFilterFields;
    const url = `${getEventsUrl(ruleName, totalFilterFields)}&eventType=${EventType.ALL}`;

    const severityInfo = getSafeFindingSeverityInfo(severity);

    const options: IIconLinkCellProps = {
        isLink: true,
        value: ruleName || '',
        href: generateHref(url),
        onClick: (event) => {
            event.preventDefault();
            if (componentName) {
                logComponentClickedEvent(componentName);
            }
            changeUrl(url);
        },
        levelIconProps: {
            iconProps: {
                name: 'securityEvent',
            },
            category: 'severity',
            level: severityInfo.key,
            size: 'md',
        },
    };
    return <IconLinkCellRenderer {...options} />;
};

export default EventTitleCellRender;
