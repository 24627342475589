import { getService } from '../../extensibility/AddinContainer';
import {
    IIdenticalRolesInfo,
    IIdenticalIdentitiesTreeNode,
} from 'common/module_interface/identity/IdenticalRoles.interfaces';
import { IModalsProps } from '../assets/Environments';
import { FunctionComponentElement } from 'react';

export const IDENTITY_SERVICE_ID = 'IdentityService';

export interface IIdentityService {
    fetchIdenticalRoles: (cloudAccountIds: string) => Promise<IIdenticalRolesInfo | undefined>;
    fetchIdenticalRolesForMultipleCloudAccounts: (
        cloudAccountIds: string[],
    ) => Promise<IIdenticalRolesInfo[] | undefined>;
    fetchIdenticalRolesTreeData: (cloudAccountId: string) => Promise<IIdenticalIdentitiesTreeNode[]>;
    getIdenticalRolesModalElement: (props: IModalsProps) => FunctionComponentElement<IModalsProps> | null;
}

export function getIdentityService(): IIdentityService {
    return getService<IIdentityService>(IDENTITY_SERVICE_ID);
}
