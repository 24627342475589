import { forwardRef } from 'react';
import ProgressBarStyles from './ProgressBar.styles';
import { ProgressBarProps } from './ProgressBar.types';
import Typography from '../Typography';
import './ProgressBar.scss';

const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>((props, ref) => {
    const { percentage, bold = false, singleAnimation = false, showPercentage = false } = props;

    return (
        <ProgressBarStyles.Container direction='row' spacing={2} alignItems='center' fullWidth>
            <ProgressBarStyles.Wrapper ref={ref} bold={bold} className='progress-bar'>
                <ProgressBarStyles.PercentageBar percentage={percentage} singleAnimation={singleAnimation} />
            </ProgressBarStyles.Wrapper>
            {showPercentage && <Typography>{percentage}%</Typography>}
        </ProgressBarStyles.Container>
    );
});

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
