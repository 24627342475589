import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from '../../../ag-grid/Renderers/IconLinkCellRenderer';
import { AllIconsName, IconProps, allIcons } from 'common/design-system/components-v2/Icon/Icon.types';

const getIconProps = (icon?: AllIconsName | string): IconProps | undefined => {
    if (!icon) return undefined;
    if (icon in allIcons) {
        return { name: icon as AllIconsName };
    }
    return { vendorNameOrPath: icon };
};

const IconCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const iconName = params.data.icon;
    const className =
        params.colDef && params.colDef.cellRendererParams ? params.colDef.cellRendererParams.className : '';
    const options: IIconLinkCellProps = {
        isLink: false,
        iconProps: getIconProps(iconName),
        value: params.data.displayName,
        className: className,
    };

    return <IconLinkCellRenderer {...options} />;
};

export default IconCellRenderer;
