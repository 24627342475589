import styled from 'styled-components';
import Stack from '../Stack';
import Icon from '../Icon';
import { AlertTypeToContext } from './Alert.types';
import { AlignItems } from '../Stack/Stack.types';

interface IWrapperProps {
    type: 'error' | 'warning' | 'success' | 'info';
    fullWidth?: boolean;
}

interface IStatusIconProps {
    alignIcon?: AlignItems;
}

const Wrapper = styled(Stack).attrs<IWrapperProps>(() => ({
    spacing: 1,
}))<IWrapperProps>`
    background-color: ${({ theme, type }) => theme.palette[AlertTypeToContext[type]].backgrounds.strength.weaker};
    color: ${({ theme, type }) => theme.palette[AlertTypeToContext[type]].foregrounds.regular.default};
    overflow: hidden;
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

const StatusIcon = styled(Icon).attrs(({ name }) => ({
    name: name,
}))<IStatusIconProps>`
    align-self: ${(props) => props.alignIcon || 'flex-start'};
`;

const IconWrapperClose = styled.div`
    align-self: flex-start;
    cursor: pointer;
`;

const AlertStyles = {
    Wrapper,
    IconWrapperClose,
    StatusIcon,
};

export default AlertStyles;
