import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgLvlExpand = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
<svg viewBox="0 0 22 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
     ref={ref}
     {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11 9.44995C11.4142 9.44995 11.75 9.11417 11.75 8.69995V3.51061L13.4697 5.23028C13.7626 5.52318 14.2374 5.52318 14.5303 5.23028C14.8232 4.93739 14.8232 4.46251 14.5303 4.16962L11.5303 1.16962C11.2374 0.876728 10.7626 0.876728 10.4697 1.16962L7.46967 4.16962C7.17678 4.46251 7.17678 4.93739 7.46967 5.23028C7.76256 5.52318 8.23744 5.52318 8.53033 5.23028L10.25 3.51061V8.69995C10.25 9.11417 10.5858 9.44995 11 9.44995Z"
          fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11 13.95C10.5858 13.95 10.25 14.2857 10.25 14.7L10.25 19.8893L8.53033 18.1696C8.23744 17.8767 7.76256 17.8767 7.46967 18.1696C7.17678 18.4625 7.17678 18.9374 7.46967 19.2303L10.4697 22.2303C10.7626 22.5232 11.2374 22.5232 11.5303 22.2303L14.5303 19.2303C14.8232 18.9374 14.8232 18.4625 14.5303 18.1696C14.2374 17.8767 13.7626 17.8767 13.4697 18.1696L11.75 19.8893L11.75 14.7C11.75 14.2857 11.4142 13.95 11 13.95Z"
          fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.25 11.7C0.25 11.2857 0.585786 10.95 1 10.95L21 10.95C21.4142 10.95 21.75 11.2857 21.75 11.7C21.75 12.1142 21.4142 12.45 21 12.45L1 12.45C0.585786 12.45 0.25 12.1142 0.25 11.7Z"
          fill="currentColor"/>
</svg>
);
const ForwardRef = forwardRef(SvgLvlExpand);
export default ForwardRef;
