import { useContext } from 'react';
import ReactSelect, {
    ClearIndicatorProps,
    components,
    ControlProps,
    DropdownIndicatorProps,
    MultiValue,
    MultiValueGenericProps,
    OptionProps,
} from 'react-select';
import { ThemeContext } from 'styled-components';
import { OptionType, SelectProps } from './Select.types';
import {
    getControlStyling,
    getIndicatorContainerStyling,
    getIndicatorSeparatorStyling,
    getInputStyling,
    getMenuListStyling,
    getMenuStyling,
    getOptionStyling,
    getPlaceholderStyling,
    getSingleValueStyling,
} from './Select.styles';
import Chip from '../Chip';
import Checkbox from '../Checkbox';
import Stack from '../Stack/Stack';
import Icon from '../Icon/Icon';
import { IconProps } from '../Icon/Icon.types';
import IconButton from '../IconButton/IconButton';

const MultiComponent = (props: MultiValueGenericProps<OptionType>) => {
    return (
        <Chip
            size='xs'
            label={props.data.label}
            closeButton={{
                disabled: false,
                onClick: () => {
                    props.selectProps.onChange(
                        (props.selectProps.value as MultiValue<OptionType>).filter(
                            (selected) => selected.value !== props.data.value,
                        ),
                        { action: 'remove-value', removedValue: props.data },
                    );
                    props.selectProps.onMenuClose();
                },
            }}
        />
    );
};

const CustomOption = (props: OptionProps<OptionType>) => {
    return props.isMulti ? (
        <components.Option {...props}>
            <Checkbox
                labelProps={{ size: 'md', leadingIconProps: props.data.iconProps && props.data.iconProps }}
                checked={props.isSelected}
                label={props.data.label}
            />
        </components.Option>
    ) : (
        <components.Option {...props}>
            {props.data.iconProps && <Icon {...props.data.iconProps} />}
            {props.data.label}
        </components.Option>
    );
};

const CustomDropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon name='chevronDown' size={10} />
        </components.DropdownIndicator>
    );
};

const CustomClearIndicator = (props: ClearIndicatorProps<OptionType>) => {
    return (
        <components.ClearIndicator {...props}>
            <IconButton iconProps={{ name: 'remove', size: 16 }} size='small' />
        </components.ClearIndicator>
    );
};

const CustomControlContainer = (props: ControlProps<OptionType>, startIcon?: IconProps) => {
    if (!props.isMulti) {
        const selectedValue = props.getValue()[0];
        const { children, ...rest } = props;
        return (
            <components.Control {...rest}>
                <Stack spacing={2} direction='row' alignItems='center' fullWidth>
                    {selectedValue && selectedValue.iconProps && <Icon {...selectedValue.iconProps} />}
                    {children}
                </Stack>
            </components.Control>
        );
    }
    if (!startIcon) return <components.Control {...props} />;
    const { children, ...rest } = props;
    return (
        <components.Control {...rest}>
            <Stack spacing={2} direction='row' alignItems='center' fullWidth>
                <Icon {...startIcon} />
                {children}
            </Stack>
        </components.Control>
    );
};

const Select = ({
    options,
    isDisabled,
    isError,
    isReadonly,
    isMulti,
    value,
    onChange,
    placeholder,
    startIconProps,
    clearable,
    ...rest
}: SelectProps) => {
    const theme = useContext(ThemeContext)!;

    return (
        <ReactSelect
            {...rest}
            menuPortalTarget={document.body}
            isMulti={isMulti}
            value={value}
            unstyled
            placeholder={placeholder}
            hideSelectedOptions={false}
            isClearable={clearable}
            closeMenuOnSelect={!isMulti}
            onChange={onChange}
            blurInputOnSelect
            isDisabled={isDisabled || isReadonly}
            components={{
                Control: (props) => CustomControlContainer(props, startIconProps),
                ClearIndicator: CustomClearIndicator,
                MultiValue: MultiComponent,
                Option: CustomOption,
                DropdownIndicator: CustomDropdownIndicator,
            }}
            styles={{
                menuPortal: (base) => ({ ...base, zIndex: 1000 }),
                control: (styles) => ({
                    ...styles,
                    ...getControlStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                placeholder: (styles) => ({
                    ...styles,
                    ...getPlaceholderStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                input: (styles) => ({
                    ...styles,
                    ...getInputStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                singleValue: (styles) => ({
                    ...styles,
                    ...getSingleValueStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                indicatorsContainer: (styles) => ({
                    ...styles,
                    ...getIndicatorContainerStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                // @ts-ignore Boris: I put this because of some type mismatch. Please fix.
                dropdownIndicator: (styles, props) => ({
                    ...styles,
                    transform: `rotate(${props.selectProps.menuIsOpen ? 180 : 0}deg)`,
                    cursor: 'pointer',
                }),
                indicatorSeparator: (styles) => ({
                    ...styles,
                    ...getIndicatorSeparatorStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                // @ts-ignore Boris: I put this because of some type mismatch. Please fix.
                clearIndicator: (styles) => ({
                    ...styles,
                    padding: `${theme.spacing(1)}${theme.units}`,
                    cursor: 'pointer',
                }),
                menu: (styles) => ({
                    ...styles,
                    ...getMenuStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                menuList: (styles) => ({
                    ...styles,
                    ...getMenuListStyling({ isDisabled, isError, isReadonly }, theme),
                }),
                option: (styles, { isSelected, isDisabled, isMulti }) => ({
                    ...styles,
                    ...getOptionStyling({ isSelected, isDisabled, isMulti }, theme),
                }),
                // @ts-ignore Boris: I put this because of some type mismatch. Please fix.
                valueContainer: (styles) => ({
                    ...styles,
                    gap: `${theme.spacing(2)}${theme.units}`,
                }),
            }}
            options={options}
        />
    );
};

export default Select;
