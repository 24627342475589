import React from 'react';
import FilterPanel from 'common/components/FilterPanel/FilterPanel';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultDateFilter,
    renderDefaultFreeTextFilter,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import FilterPanelPlaceholderStyled from './FilterPanelPlaceholder.styled';
import { DEFAULT_RANGES_VALUES } from '../DefaultFilters/DateFilter/DateFilter.consts';
import { IRenderedFilter, IRenderedHandler } from '../FilterPanel.interface';

interface FilterPanelPlaceholderProps {
    hideDateFilter?: boolean;
}

export const FilterPanelPlaceholder: React.FC<FilterPanelPlaceholderProps> = (props) => {
    const { hideDateFilter } = props;

    const filters = React.useMemo<(IRenderedHandler | IRenderedFilter)[]>(() => {
        const defaults = [
            renderAddFilter({ key: FILTERS_KEYS.ADD_FILTER, isLoading: true }),
            renderClearAll({ key: FILTERS_KEYS.CLEAR_BUTTON }),
            renderSavedFilters({ key: FILTERS_KEYS.SAVED_FILTERS, savedFilters: [] }),
            renderDefaultFreeTextFilter({ key: FILTERS_KEYS.FREE_TEXT, title: '', value: '' }),
        ];
        if (!hideDateFilter) {
            defaults.push(
                renderDefaultDateFilter({
                    key: FILTERS_KEYS.DATE_PICKER,
                    defaultValue: DEFAULT_RANGES_VALUES.ALL,
                    options: [
                        DEFAULT_RANGES_VALUES.ONE_DAY,
                        DEFAULT_RANGES_VALUES.WEEK,
                        DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                        DEFAULT_RANGES_VALUES.ALL,
                    ],
                    title: '',
                    limitations: { maxDaysSelected: Infinity, maxDaysBack: Infinity },
                    value: DEFAULT_RANGES_VALUES.ALL,
                }),
            );
        }
        return defaults;
    }, [hideDateFilter]);

    return (
        <FilterPanelPlaceholderStyled.Wrapper>
            <FilterPanel
                filterPanelProps={{
                    filters,
                    onFilterPanelChange: () => new Promise(() => null),
                    containerQuery: {},
                }}
            />
        </FilterPanelPlaceholderStyled.Wrapper>
    );
};

export default FilterPanelPlaceholder;
