import React from 'react';
import { FILTER_DISPAY_TYPES, FILTER_TYPES } from '../FilterPanel.consts';
import { IFilterPanelView } from '../FilterPanel.interface';

export const FilterPanelSeparateFields: React.FC<{ filterViewProps: IFilterPanelView }> = ({ filterViewProps }) => {
    const { filters, filtersValues, onEvent, lastEvent, filtersUtils, handlersValues } = filterViewProps;
    return (
        <div className='my-6 flex flex-column'>
            {filters.map((filter) => {
                const { filterProps, displayTypes } = filter;
                return (
                    <React.Fragment key={filterProps.key}>
                        {displayTypes.includes(FILTER_DISPAY_TYPES.SEPARATE_FIELDS) && (
                            <filter.filterElement
                                filterProps={{
                                    ...filterProps,
                                    value:
                                        filter.type === FILTER_TYPES.FILTER ||
                                        filter.type === FILTER_TYPES.FILTER_OUTSIDE
                                            ? filtersValues[filterProps.key]
                                            : handlersValues[filterProps.key].value,
                                    currFilterData:
                                        filter.type === FILTER_TYPES.FILTER ||
                                        filter.type === FILTER_TYPES.FILTER_OUTSIDE
                                            ? lastEvent.result && lastEvent.result[filterProps.key]
                                            : handlersValues[filterProps.key].data,
                                    filtersUtils: filtersUtils,
                                    onEvent,
                                    lastEvent,
                                    displayType: FILTER_DISPAY_TYPES.SEPARATE_FIELDS,
                                }}
                                key={filterProps.key}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
export default FilterPanelSeparateFields;
