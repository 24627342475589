import React from 'react';
import { IconComponentProps, allIcons, IconProps } from './Icon.types';
import { useTheme } from 'styled-components';
import { isImagePath } from '../../../utils/helpFunctions';
import { Icon as VendorIcon } from '@dome9/berries/react-components';

const defaultIconSize = 16;

const IconComponent = React.forwardRef<SVGSVGElement, IconComponentProps>((props, ref) => {
    const { name, size = defaultIconSize, color, customColor, context, saturation = false, ...rest } = props;
    const theme = useTheme();
    const CompoIcon = allIcons[name];
    const saturationSuffix = saturation ? 'saturation' : 'default';
    const contextColor = context && theme.palette[context].foregrounds.regular[saturationSuffix];

    const svgStyles: React.CSSProperties = {
        color: customColor || (color && theme.palette.surface[color]) || contextColor || 'currentColor',
        minHeight: `${size}px`,
        minWidth: `${size}px`,
        width: `${size}px`,
        height: `${size}px`,
    };

    if (!CompoIcon) return null;

    return <CompoIcon ref={ref} style={svgStyles} {...rest} />;
});
IconComponent.displayName = 'Icon';

const Icon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
    if ('vendorNameOrPath' in props && 'name' in props) {
        throw new Error('You can not use both "name" and "vendorNameOrPath" props while using Icon component');
    }
    if ('vendorNameOrPath' in props) {
        const { vendorNameOrPath, size = defaultIconSize, color, ...rest } = props;
        const isImage = isImagePath(vendorNameOrPath);
        if (isImage) {
            return (
                <img
                    ref={ref as any} // any here because of the conflict between img and svg ref types
                    alt={`${vendorNameOrPath} icon`}
                    className={rest.className}
                    style={{ width: `${size}px`, height: `${size}px` }}
                    src={vendorNameOrPath}
                />
            );
        }
        return <VendorIcon name={vendorNameOrPath} size={size} color={color} {...rest} ref={ref as any} />; // any here because of the conflict between berries and svg ref types
    }
    return <IconComponent {...props} ref={ref} />;
});
Icon.displayName = 'Icon';

export default Icon;
