import React, { forwardRef, useState, useRef } from 'react';
import { InputBase, InputWrapper, TooltipIconWrapper } from './Input.styled';
import { InputProps } from './Input.types';
import Stack from '../Stack/Stack';
import InputLabel from '../InputLabel';
import IconButton from '../IconButton/IconButton';
import Tooltip from '../Tooltip/Tooltip';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        label,
        labelProps,
        helperText,
        required,
        placeholder,
        onChange,
        onFocus,
        onBlur,
        clearable,
        startAdornment,
        endAdornment,
        type,
        tooltip,
        interactiveTooltip,
        direction,
        readOnly,
        disabled,
        isError,
        isActive: isActiveProp,
        fullWidth = false,
        value,
        defaultValue,
        wrapperStyles,
        wrapperClassName,
        inputStyles,
        inputClassName,
        wrapperRef,
        inputWrapperRef,
        inputSize = 'normal',
        dataAid,
        ...rest
    } = props;

    const [currentValue, setCurrentValue] = useState(value || defaultValue || '');
    const [isActive, setIsActive] = useState(isActiveProp || false);
    const [inputType, setInputType] = useState<InputProps['type']>(type || 'text');
    const inputRef = useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (type) {
            setInputType(type);
        }
    }, [type]);

    React.useEffect(() => {
        if (value !== undefined) {
            setCurrentValue(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (ref) {
            if (typeof ref === 'function') {
                ref(inputRef.current);
            } else {
                ref.current = inputRef.current;
            }
        }
    }, [ref]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        onChange?.(event);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        setIsActive(true);
        onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        setIsActive(false);
        onBlur?.(event);
    };

    const handleOnClear = () => {
        if (inputRef.current) {
            if (inputRef.current.value === '') return;
            const event = new CustomEvent<React.ChangeEvent<HTMLInputElement>>('change', { bubbles: true });
            inputRef.current.dispatchEvent(event);
            inputRef.current.value = '';
            handleChange(event as any);
        }
    };

    const handleOnPlusOrMinusClick = (type: 'plus' | 'minus') => {
        if (inputRef.current) {
            const event = new CustomEvent<React.ChangeEvent<HTMLInputElement>>('change', { bubbles: true });
            type === 'plus' ? inputRef.current.stepUp() : inputRef.current.stepDown();
            inputRef.current.dispatchEvent(event);
            handleChange(event as any);
        }
    };

    return (
        <Stack
            data-aid='input-text'
            spacing={2}
            alignItems={'baseline'}
            direction={direction === 'horizontal' ? 'row' : 'column'}
            fullWidth={fullWidth}
            style={wrapperStyles}
            className={wrapperClassName}
            ref={wrapperRef}
        >
            {label && <InputLabel text={label} required={required} {...labelProps} />}
            <Stack fullWidth justifyContent={'flex-start'} spacing={2} direction={'column'}>
                <InputWrapper
                    spacing={inputSize === 'normal' ? 2 : 4}
                    data-aid='input-wrapper'
                    direction='row'
                    alignItems='center'
                    fullWidth={fullWidth}
                    readOnly={readOnly}
                    isActive={isActive}
                    disabled={disabled}
                    isError={isError}
                    inputSize={inputSize}
                    type={inputType}
                    ref={inputWrapperRef}
                >
                    {startAdornment && startAdornment}
                    <InputBase
                        ref={inputRef}
                        value={currentValue}
                        type={inputType}
                        aria-rowcount={4}
                        aria-multiline={true}
                        readOnly={readOnly}
                        multiple={true}
                        onChange={handleChange}
                        placeholder={placeholder}
                        disabled={disabled}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        isError={isError}
                        style={inputStyles}
                        className={inputClassName}
                        data-aid={dataAid}
                        {...rest}
                    />
                    {clearable && currentValue && (
                        <IconButton
                            onClick={handleOnClear}
                            iconProps={{ name: 'remove', size: 16 }}
                            size={'small'}
                            disabled={readOnly}
                            dataAid='input-clear-button'
                        />
                    )}
                    {tooltip && (
                        <Tooltip content={tooltip} interactive={interactiveTooltip} placement={'top'}>
                            <TooltipIconWrapper>
                                <Icon name='info' />
                            </TooltipIconWrapper>
                        </Tooltip>
                    )}
                    {endAdornment && endAdornment}
                    {type === 'number' && (
                        <ButtonGroup
                            size='small'
                            direction='column'
                            iconButton
                            removeBorder
                            removeBorderRadius
                            removeDivider
                            options={[
                                {
                                    iconProps: { name: 'chevronUp', size: 10 },
                                    onClick: () => handleOnPlusOrMinusClick('plus'),
                                    dataAid: 'input-number-plus-button',
                                },
                                {
                                    iconProps: { name: 'chevronDown', size: 10 },
                                    onClick: () => handleOnPlusOrMinusClick('minus'),
                                    dataAid: 'input-number-minus-button',
                                },
                            ]}
                        />
                    )}
                    {type === 'password' && (
                        <IconButton
                            iconProps={{ name: inputType === 'password' ? 'eye' : 'eyeOff' }}
                            onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}
                            size='small'
                            dataAid='input-password-toggle-button'
                        />
                    )}
                </InputWrapper>
                {helperText && (
                    <Typography color={isError ? 'alert' : 'strong'} variant={'xs'}>
                        {helperText}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
});

Input.displayName = 'Input';

export default Input;
