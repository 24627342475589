import { GenericObject, IFieldInfo } from '../../interface/general';
import React from 'react';
import { KustoEventFields } from './KustoEvent.const';
import { ICommonEvent } from '../../module_interface/events/Events.interface';
import { IFindingComment } from '../Findings/Findings.interface';

import { IEvent, SysEventType } from '../../module_interface/events/Events';
import { CommonEventFields } from '../../module_interface/events/EventsConsts';

export interface IKustoEvent extends ICommonEvent {
    [KustoEventFields.additionalFieldsMap]: GenericObject<string>;
    [KustoEventFields.cloudAccountName]: string;
    [KustoEventFields.comments]?: IFindingComment[];
    [KustoEventFields.count]?: number;
    [KustoEventFields.logType]: string;
    [KustoEventFields.magellanNetworkSrcAddress]: string;
    [KustoEventFields.magellanNetworkSrcCountryCode]: string;
    [KustoEventFields.mitreDict]: GenericObject<string[]>;
    [KustoEventFields.mitreTactic]: string;
    [KustoEventFields.mitreTechnique]: string;
    [KustoEventFields.ruleLogicHash]: string;
    [KustoEventFields.statusId]: number;
    [KustoEventFields.statusName]: string;
    [KustoEventFields.numberOfRows]: number;
    [CommonEventFields.sysEventType]: SysEventType.kusto;
}

export type IPartialKustoEvent = Partial<IKustoEvent>;

export interface IKustoCountEvent extends IPartialKustoEvent {
    [KustoEventFields.count]: number;
}

export interface IRightHeaderPanelComponent {
    id: string;
    isRelevant?: (finding: IEvent) => boolean;
    component: React.FC<any>;
    componentProps?: any;
}

export interface IAdditionalFilterFieldInfo extends IFieldInfo {
    operator?: string;
    arrayJoinOperator?: string;
    converterFn: (field: IAdditionalFilterFieldInfo) => string | undefined;
}

export enum KustoLogType {
    Network = 'network',
    Account = 'account',
}
