import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef } from 'react';
import { compareTimeFunction, generateColors } from '../Widgets/helpers';
import { IGenericWidgetDataItemSet, IGenericWidgetDataSetProps } from 'common/module_interface/overview/Interface';
import { baseTooltipStyle } from './Utils';
import { useProfiler } from '@sentry/react';

interface ISeriesDataItem {
    name: string;
    data: any[];
    color?: string;
}

const getLineChartOptions = (
    items: IGenericWidgetDataItemSet[],
    options: any,
    chartRef: React.MutableRefObject<any>,
) => {
    const categories = [] as string[];
    for (const item of items) {
        if (item.value && item.value.length > 0 && Array.isArray(item.value)) {
            item.value.forEach((v: any) => {
                if (!categories.includes(v.key)) {
                    categories.push(v.key);
                }
            });
        }
    }
    categories.sort(compareTimeFunction);
    const series = items?.reduce((acc: ISeriesDataItem[], item, index) => {
        const colors = generateColors(index + 1);
        if (item.value && item.value.length > 0 && Array.isArray(item.value)) {
            acc.push({
                name: item.key,
                data: item.value.map((item) => [new Date(item.key).getTime(), item.value]),
                color: colors[index],
            });
        }
        return acc;
    }, []);

    return {
        credits: {
            enabled: false,
        },
        chart: {
            type: 'spline',
        },
        series: series,
        title: {
            text: '',
        },
        yAxis: {
            title: '',
        },
        xAxis: {
            type: 'datetime',
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                events: {
                    click: (event: any) => {
                        const seriesIndex = event.point.series.index;
                        const pointIndex = event.point.index;

                        const relevantItem = items?.[seriesIndex]?.value?.[pointIndex];
                        if (relevantItem && relevantItem.onclick) {
                            relevantItem.onclick();
                        }
                    },
                },
            },
        },
        legend: {
            enabled: true,
        },
        tooltip: {
            headerFormat: '{point.x:%e. %b}<br>',
            style: baseTooltipStyle,
        },
    };
};

const LineSetChart: React.FC<IGenericWidgetDataSetProps> = (props) => {
    useProfiler(`LineSetChart|${props.header}`);

    const items = props?.data?.data?.items;
    const chartRef = useRef<any>();
    const options = props?.data?.data?.options;

    const chartOptions = items && items.length && getLineChartOptions(items, options, chartRef);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) chart.reflow();
    }, [chartRef]);

    return (
        <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{
                style: {
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                    height: '100%',
                },
            }}
        />
    );
};
export default LineSetChart;
