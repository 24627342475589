import { get, isEmpty } from 'lodash';
import { EMPTY_STRING, OPERATORS } from 'common/consts/GeneralConsts';
import { KustoEventFields } from 'common/components/KustoEvents/KustoEvent.const';
import { IFindingFilters } from 'common/module_interface/intelligence/Intelligence.interface';
import { isString } from 'common/utils/helpFunctions';
import { GSL_CLAUSES } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { IFieldInfo } from 'common/interface/general';
import { ColumnApi } from 'ag-grid-community';
import { IAdditionalFilterFieldInfo } from 'common/components/KustoEvents/KustoEvent.interface';
import {
    convertFieldInfoListUiToModel,
    convertFieldNameListUiToModel,
    convertFieldNameUiToModel,
} from '../../erm-components/utils/Convertors/convertors';
import { kustoEventMappersByUiName } from './KustoEventService';

interface IQueryFilters {
    queryFilter: string;
    mitreFilter: string;
    mitreLikeFilter: string;
}

export const computeFilterString = (params: IFindingFilters): string => {
    const { queryFilter, freeTextFilter, additionalFilterFieldInfo } = params;
    const additionalFieldFiltersString: string | undefined = additionalFilterFieldInfo
        ?.map((filterFieldInfo: IAdditionalFilterFieldInfo) => {
            return filterFieldInfo.converterFn(filterFieldInfo);
        })
        .filter(Boolean)
        .join(` ${OPERATORS.AND} `);
    const filterString: string = [additionalFieldFiltersString, queryFilter, freeTextFilter]
        .filter((filter) => !!filter)
        .join(` ${OPERATORS.AND} `);
    const where: string = !isEmpty(filterString) ? GSL_CLAUSES.WHERE : EMPTY_STRING;
    return `${where} ${filterString}`.trim();
};

export const updateMitreLikeGslFilters = (inputField: string, filters: string[], value: string) => {
    if (inputField === EMPTY_STRING) {
        const mitreDictFieldName: string = convertFieldNameUiToModel(
            kustoEventMappersByUiName,
            KustoEventFields.mitreDict,
        );
        filters.push(`(${mitreDictFieldName} = '')`);
    } else {
        filters.push(value);
    }
};

export const freeTextToGslFormat = (columnApi?: ColumnApi, freeText?: string): string | undefined => {
    if (!freeText || freeText.length < 3) {
        return EMPTY_STRING;
    }
    // replace single quote (') with an escaped single quote (\\')
    freeText = freeText.replaceAll("'", "\\'");

    const freeTextFieldQueries = columnApi
        ?.getColumns()
        ?.filter((column) => {
            const colDef = column?.getColDef();
            return get(colDef, 'freeTextField', false);
        })
        .map((column) => {
            const colDef = column?.getColDef();
            if (!colDef.field) {
                return '';
            }
            const modelName = convertFieldNameUiToModel(kustoEventMappersByUiName, colDef.field);
            return `${modelName} like '%${freeText}%'`;
        });

    const freeTextFilterString = freeTextFieldQueries?.join(` ${OPERATORS.OR} `);
    return freeTextFilterString ? `(${freeTextFilterString})` : EMPTY_STRING;
};

/**
 * The function generates a like filter string from the tactic and technique filters.
 * @param filterValuesMap - A map of filters and their values
 * @returns A like filter string from the tactic and technique filters.
 */
export const getMitreLikeGslFilter = (filterValuesMap: Map<string, string[]>) => {
    const mitreTacticModelName = convertFieldNameUiToModel(kustoEventMappersByUiName, KustoEventFields.mitreTactic);
    const mitreTechniqueModelName = convertFieldNameUiToModel(
        kustoEventMappersByUiName,
        KustoEventFields.mitreTechnique,
    );
    const mitreDictModelName: string = convertFieldNameUiToModel(kustoEventMappersByUiName, KustoEventFields.mitreDict);

    let mitreLikeFilter = EMPTY_STRING;
    const mitreTactic: string[] | undefined = filterValuesMap.get(mitreTacticModelName);
    const mitreTechnique: string[] | undefined = filterValuesMap.get(mitreTechniqueModelName);
    const mitreLikeFilters: string[] = [];
    if (mitreTactic && mitreTechnique) {
        // if both tactic and technique filters are present, create a filter for each combination of tactic and technique
        for (const tactic of mitreTactic) {
            for (const technique of mitreTechnique) {
                const tacticValue = tactic.replaceAll("'", "\\'");
                const techniqueValue = technique.replaceAll("'", "\\'");
                updateMitreLikeGslFilters(
                    tacticValue,
                    mitreLikeFilters,
                    `(${mitreDictModelName}['${tacticValue}'] like '%${techniqueValue}%')`,
                );
            }
        }
        // remove duplicate filters
        const uniqueMitreLikeFilters = mitreLikeFilters.reduce((accumulator: string[], currentValue: string) => {
            if (!accumulator.includes(currentValue)) {
                accumulator.push(currentValue);
            }
            return accumulator;
        }, []);
        mitreLikeFilter = uniqueMitreLikeFilters.join(` ${OPERATORS.OR} `) ?? EMPTY_STRING;
    } else if (mitreTactic) {
        // if only tactic filter is present, create a filter for each tactic
        for (const tactic of mitreTactic) {
            const tacticValue = tactic.replaceAll("'", "\\'");
            updateMitreLikeGslFilters(
                tacticValue,
                mitreLikeFilters,
                `(${mitreDictModelName}['${tacticValue}'] like '%%')`,
            );
        }
        mitreLikeFilter = mitreLikeFilters.join(` ${OPERATORS.OR} `) ?? EMPTY_STRING;
    } else if (mitreTechnique) {
        // if only technique filter is present, create a filter for each technique
        for (const technique of mitreTechnique) {
            const techniqueValue = technique.replaceAll("'", "\\'");
            updateMitreLikeGslFilters(
                techniqueValue,
                mitreLikeFilters,
                `(${mitreDictModelName} like '%${techniqueValue}%')`,
            );
        }
        mitreLikeFilter = mitreLikeFilters.join(` ${OPERATORS.OR} `) ?? EMPTY_STRING;
    }
    return mitreLikeFilter;
};

export const filterValuesToGslRequestQueryFilters = (
    filterFields?: IFieldInfo[],
    mitreFields?: string[],
): IQueryFilters => {
    if (isEmpty(filterFields))
        return {
            queryFilter: EMPTY_STRING,
            mitreFilter: EMPTY_STRING,
            mitreLikeFilter: EMPTY_STRING,
        };

    const filterValuesMap = new Map<string, string[]>();
    if (filterFields) {
        const modelFilterFields: IFieldInfo[] = convertFieldInfoListUiToModel(kustoEventMappersByUiName, filterFields);
        modelFilterFields.forEach((filter: { name: string; value: any }) => {
            const name = filter.name;
            const value = filter.value;
            if (filterValuesMap.has(name)) {
                filterValuesMap.get(name)?.push(value);
            } else {
                filterValuesMap.set(name, [value]);
            }
        });
    }

    const filters: string[] = [];
    const modelMitreFields: string[] | undefined = mitreFields
        ? convertFieldNameListUiToModel(kustoEventMappersByUiName, mitreFields)
        : undefined;
    const mitreFilters: string[] = [];
    filterValuesMap.forEach((values, key) => {
        const currFilterEntries = values.map((value: any) => {
            // replace single quote (') with an escaped single quote (\\')
            if (isString(value)) {
                value = value.replaceAll("'", "\\'");
            }
            return `${key} = '${value}'`;
        });
        if (modelMitreFields?.includes(key)) {
            mitreFilters.push(`(${currFilterEntries.join(` ${OPERATORS.OR} `)})`);
        } else {
            filters.push(`(${currFilterEntries.join(` ${OPERATORS.OR} `)})`);
        }
    });
    const queryFilter = filters.join(` ${OPERATORS.AND} `) ?? EMPTY_STRING;
    const mitreFilter = mitreFilters.join(` ${OPERATORS.AND} `) ?? EMPTY_STRING;
    const mitreLikeFilter = getMitreLikeGslFilter(filterValuesMap);
    return { queryFilter, mitreFilter, mitreLikeFilter };
};
