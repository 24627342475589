import React, { useEffect, useState } from 'react';
import { isArray } from '../helpers/JsonView.utils';
import RowWrapper from './RowWrapper';
import { HighlightText } from './HighlightText';
import { ComplexTreeProps } from '../helpers/JsonView.types';
import { JsonRow, JsonP, JsonFeet, Span, SpanChevron } from '../helpers/JsonView.styles';
import { jsonColors } from '../helpers/JsonView.consts';
import Icon from '../../../../Icon';

const ComplexRow: React.FC<ComplexTreeProps> = (props) => {
    const { id, name, value, type, showIndex, needComma, items } = props.item;
    const { level = 1, searchTerm, collapseLevel, currentMatchId } = props;
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (value && isArray(value) && level <= collapseLevel && value.length > 0) {
            setVisible(true);
        } else setVisible(false);
    }, [level, value, collapseLevel]);
    const isMatch = currentMatchId === `${id}-${name}`;
    return (
        <JsonRow>
            <JsonP
                onClick={() => setVisible(!visible)}
                backgroundColor={isMatch ? jsonColors.highlightBackground : jsonColors.transparent}
            >
                <Span color={jsonColors.content}>
                    {showIndex && (
                        <Span color={jsonColors.key}>
                            <SpanChevron visible={visible}>
                                <Icon name='chevronRight' size={8} />
                            </SpanChevron>
                            <HighlightText
                                text={name}
                                searchTerm={searchTerm}
                                id={`${id}-${name}`}
                                currentMatchId={currentMatchId}
                            />
                            {': '}
                        </Span>
                    )}
                    <Span color={jsonColors.pt}>{type === 'array' ? '[' : '{'}</Span>
                </Span>
                {!visible && (
                    <Span color={jsonColors.pt}>
                        {type === 'array' ? '...]' : '...}'}
                        <Span color={jsonColors.items}> ({items} items)</Span>
                        {needComma ? ',' : ''}
                    </Span>
                )}
            </JsonP>
            <div style={{ display: visible ? 'block' : 'none' }}>
                {value &&
                    isArray(value) &&
                    value.map((item, index) => (
                        <RowWrapper
                            key={index}
                            level={level + 1}
                            item={item}
                            searchTerm={searchTerm}
                            collapseLevel={collapseLevel}
                            currentMatchId={currentMatchId}
                        />
                    ))}
                <JsonFeet>
                    <Span color={jsonColors.pt}>
                        {type === 'array' ? ']' : '}'}
                        {needComma ? ',' : ''}
                    </Span>
                </JsonFeet>
            </div>
        </JsonRow>
    );
};

export default ComplexRow;
