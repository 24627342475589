import styled from 'styled-components';

const Overlay = styled.div<{ withOverlay: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  ${({ withOverlay }) =>
        withOverlay &&
  `
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    z-index: 999999;
   `
}
`;

export const FullPageLoaderStyled = {
    Overlay
};