import React, { useCallback, useMemo } from 'react';
import { IFilterTreeCommonProps, IFilterTreeCondition, IFilterTreeFieldDefinition } from '../FilterTree.interface';
import { FilterStyled } from './FilterTree.styled';
import { useTranslation } from 'react-i18next';
import { FilterConditionOperator, IFilterConditionValue } from '../FilterCondition';
import { SelectOption } from '../../SelectV2/Select.types';
import { SelectV2, Stack } from '../../index';
import { useDraggable } from '@dnd-kit/core';
import { useTheme } from 'styled-components';

export const FilterTreeCondition: React.FC<{
    condition: IFilterTreeCondition;
    filterProps: IFilterTreeCommonProps;
}> = ({ condition, filterProps }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: condition.id,
    });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          }
        : undefined;
    const filterDef = useMemo(
        () => filterProps.filterDefinitions.find((def) => def.name === condition.name),
        [condition.name, filterProps.filterDefinitions],
    );

    const nameOptions: SelectOption[] = useMemo(() => {
        return filterProps.filterDefinitions.map((def) => {
            return {
                label: def.header,
                value: def.name,
            };
        });
    }, [filterProps.filterDefinitions]);

    const opOptions: SelectOption[] = useMemo(() => {
        const supportedOperators: FilterConditionOperator[] = filterDef
            ? filterDef.conditionOperatorsInfo.operators
            : [];
        return supportedOperators.map((op) => {
            return {
                label: t(`FILTER_CONDITION_OPERATOR.${op}`),
                value: String(op),
            };
        });
    }, [filterDef, t]);

    const onNameChange = useCallback(
        (newName: string) => {
            const newFilterDef: IFilterTreeFieldDefinition | undefined = filterProps.filterDefinitions.find(
                (def) => def.name === newName,
            );
            const newCond: IFilterTreeCondition = {
                ...condition,
                name: newName,
                operator: newFilterDef ? newFilterDef.conditionOperatorsInfo.defaultOperator : undefined,
                values: [],
            };
            filterProps.api.onConditionChange(newCond);
        },
        [condition, filterProps.api, filterProps.filterDefinitions],
    );

    const onOperatorChange = useCallback(
        (operator: FilterConditionOperator) => {
            const newCond = {
                ...condition,
                operator,
            };
            filterProps.api.onConditionChange(newCond);
        },
        [condition, filterProps],
    );

    const onValuesChange = useCallback(
        (values: IFilterConditionValue[]) => {
            const newCond: IFilterTreeCondition = {
                ...condition,
                values,
            };
            filterProps.api.onConditionChange(newCond);
        },
        [condition, filterProps],
    );

    return (
        <FilterStyled.ConditionDiv ref={setNodeRef} style={style}>
            <FilterStyled.DragHandle {...listeners} {...attributes} />
            <Stack direction={'row'} spacing={4} fullWidth>
                <FilterStyled.SelectContainer width={'160px'}>
                    <SelectV2
                        fullWidth
                        value={condition.name}
                        onChange={(value: string) => onNameChange(value)}
                        placeholder={t('FILTER_TREE.FIELD_PLACEHOLDER')}
                        options={nameOptions}
                        isError={!!(filterProps.displayErrors && condition.nameErrorMsg)}
                        isMulti={false}
                        style={{ background: theme.palette.surface.primary }}
                    />
                </FilterStyled.SelectContainer>
                <FilterStyled.SelectContainer width={'110px'}>
                    <SelectV2
                        fullWidth
                        value={condition.operator}
                        onChange={(value: string) => onOperatorChange(value as FilterConditionOperator)}
                        placeholder={''}
                        options={opOptions}
                        isError={!!(filterProps.displayErrors && filterDef && condition.operatorErrorMsg)}
                        disabled={!filterDef}
                        isMulti={false}
                        style={{ background: theme.palette.surface.primary }}
                    />
                </FilterStyled.SelectContainer>
                {filterDef && (
                    <filterDef.conditionValuesComponent
                        onValuesChange={onValuesChange}
                        condition={condition}
                        filterProps={filterProps}
                    />
                )}
                {!filterDef && (
                    <FilterStyled.InputDiv
                        fullWidth
                        style={{ flexGrow: '1', background: theme.palette.surface.primary }}
                        placeholder={t('FILTER_TREE.VALUE_PLACEHOLDER')}
                        disabled
                    />
                )}
            </Stack>
        </FilterStyled.ConditionDiv>
    );
};
