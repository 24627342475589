import { useCallback, useEffect, useMemo, useState } from 'react';
import { SimpleGenericPage } from 'common/SimpleTableFilterPage/SimpleGenericPage';
import { IDataItem, ITableRegistry } from 'common/SimpleTableFilterPage/interfaces';
import { getAppLanguage } from '../../../Globalpersist.reducer';
import { useSelector } from 'react-redux';
import { IRuleset } from '../../interface/ruleset';
import { ILocalFilter } from '../../SimpleTableFilterPage/SimpleFilterPanel/SimpleFilterPanel.interfaces';
import { ITableAction } from '../../design-system/components-v2/Table/Table.types';
import { getDeleteItemTableAction } from '../../SimpleTableFilterPage/SimpleTable/DefaultTableActions';
import { Message } from '../../design-system/components-v2';
import { MessageProps } from '../../design-system/components-v2/Message/Message.types';
import { useTranslation } from 'react-i18next';
import { defaultRulesetsDataService, IExtendRuleset, IRunAssessment } from './DefaultRulesetsDataService';

export const COMMON_RULESETS_TABLE_ACTIONS = {
    ADD_RULESET: 'ADD_RULESET',
    DELETE_RULESET: 'DELETE_RULESET',
    CLONE_RULESET: 'CLONE_RULESET',
};

export interface IRulesetsPageDataService {
    getAll: (useCache: boolean) => Promise<Partial<IRuleset>[]>;
    deleteItem: (item: IExtendRuleset, translationKey: string) => Promise<void>;
    runAssessment: (assessment: IRunAssessment, isMultipleEnvironments: boolean) => Promise<string | void>;
}

export interface IRulesetsPageProps<T extends IExtendRuleset> {
    moduleID: string;
    moduleRegistry: ITableRegistry;
    translationKey: string;
    localFilters: ILocalFilter<T>[];
    pageFiltersIds: { id: string }[];
    dataService?: IRulesetsPageDataService;
    extraTableActions?: ITableAction[];
    hiddenCommonActionsIDs?: string[];
}

export interface IRulesetsModalConfig extends Partial<MessageProps> {
    title: string;
    isOpen: boolean;
}

export const RulesetsPage = (pageProps: IRulesetsPageProps<IExtendRuleset>) => {
    const {
        moduleRegistry,
        translationKey,
        localFilters,
        pageFiltersIds,
        extraTableActions = [],
        dataService = defaultRulesetsDataService,
        hiddenCommonActionsIDs = [],
    } = pageProps;
    const [rulesets, setRulesets] = useState<Partial<IRuleset>[]>([]);
    const appLanguage = useSelector(getAppLanguage);
    const [modalConfig, setModalConfig] = useState({ isOpen: false } as IRulesetsModalConfig);
    const { t } = useTranslation(translationKey);

    const init = useCallback(
        async (useCache: boolean) => {
            const allRulesets = await dataService.getAll(useCache);
            setRulesets(allRulesets);
        },
        [dataService],
    );

    useEffect(() => {
        void init(true);
    }, [appLanguage, init]);

    const openDeleteRulesetesModal = useCallback(
        (selectedRows: IExtendRuleset[]) => {
            setModalConfig({
                ...modalConfig,
                isOpen: true,
                title: t('MODALS.DELETE_MODAL.TITLE', { rulesetName: selectedRows[0].name }),
                text: t('MODALS.DELETE_MODAL.TEXT', { rulesetName: selectedRows[0].name }),
                submitBtnText: t('MODALS.DELETE_MODAL.CONFIRM_BUTTON_TEXT'),
                variant: 'danger',
                onConfirm: () => {
                    dataService.deleteItem(selectedRows[0], translationKey);
                    setModalConfig({ ...modalConfig, isOpen: false });
                    init(false);
                },
                onCancel: () => {
                    setModalConfig({ ...modalConfig, isOpen: false });
                },
            });
        },
        [dataService, init, modalConfig, t, translationKey],
    );

    const commonRulesetsActions: ITableAction[] = useMemo(() => {
        const deleteRulesetActions = getDeleteItemTableAction<IExtendRuleset>({
            translationKey,
            getIsActionHidden: () => hiddenCommonActionsIDs.includes(COMMON_RULESETS_TABLE_ACTIONS.DELETE_RULESET),
            onActionClicked: (selectedRows) => openDeleteRulesetesModal(selectedRows),
            getIsActionDisabled: (selectedRows) => selectedRows.length !== 1 || selectedRows[0].numberOfPolicies > 0,
        });
        return [deleteRulesetActions];
    }, [hiddenCommonActionsIDs, openDeleteRulesetesModal, translationKey]);

    return (
        <>
            <SimpleGenericPage
                items={rulesets as unknown as IDataItem[]}
                pagination={false}
                filterId={`{$moduleID}-filters`}
                tableRegistry={moduleRegistry}
                savedFiltersComponentName={`{$moduleID}-filters`}
                translationKey={translationKey}
                localFilters={localFilters}
                filterIds={pageFiltersIds}
                actions={[...commonRulesetsActions, ...extraTableActions]}
            />
            {modalConfig.isOpen && (
                <Message onClose={() => setModalConfig({ ...modalConfig, isOpen: false })} {...modalConfig}>
                    {modalConfig.children}
                </Message>
            )}
        </>
    );
};
