import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LegalNotesFooter.scss';

export const LegalNotesFooter: React.FC<{}> = () => {
    const [currentYear, setCurrentYear] = useState<number>();
    const { t } = useTranslation();
    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
    }, [currentYear]);

    return (
        <div className='copyrights'>
            {t('GENERAL.COPYRIGHT.LINE1')}
            <a className='link' target='_blank' rel='noopener noreferrer' href='https://dome9.com'>
                {t('GENERAL.COPYRIGHT.LINE2_LINK')}
            </a>
            <span> {t('GENERAL.COPYRIGHT.LINE3', { currentYear })}</span>
            <a
                className='link'
                rel='nofollow noopener noreferrer'
                href='https://supportcenter.checkpoint.com/supportcenter/portal'
                target='_blank'
            >
                {t('GENERAL.COPYRIGHT.SUPPORT_CENTER_LINK')}
            </a>
        </div>
    );
};
