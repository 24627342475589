import { isArray, isNil, isString } from '../../utils/helpFunctions';
import { IFieldInfo } from '../../interface/general';
import { FilterValueType, ICompactFilterItem } from './ermComponents.filters';
import { CommonRegExp } from './ermComponents.consts';

export const scrollToComponent = (ref: HTMLInputElement) => {
    ref.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
    });
};

const fieldValueToStringArray = (value: any): string[] => {
    if (isNil(value)) {
        return [];
    }

    if (isArray(value)) {
        return (value as any[]).map((val: any) => String(val));
    }

    return [String(value)];
};

export const getCompactFilterFields = (filterFields?: IFieldInfo[]): ICompactFilterItem[] | undefined => {
    if (!filterFields) {
        return undefined;
    }

    const compactFieldsMap: { [key: string]: ICompactFilterItem } = {};
    filterFields.forEach((field: IFieldInfo) => {
        const name = field.name;
        const value = field.value;
        let handledRange = false;
        if (value && isString(value)) {
            const result = value.match(CommonRegExp.NUMERIC_RANGE);
            if (result) {
                handledRange = true;
                compactFieldsMap[name] = {
                    name,
                    value: {
                        min: Number(result[1]),
                        max: Number(result[3]),
                    },
                    valueType: FilterValueType.NUMERIC_RANGE,
                };
            }
        }
        if (!handledRange) {
            const existingCompactField: ICompactFilterItem = compactFieldsMap[name];
            if (!existingCompactField) {
                compactFieldsMap[name] = {
                    name,
                    value: fieldValueToStringArray(field.value),
                    valueType: FilterValueType.STRING_LIST,
                };
            } else {
                if (existingCompactField.valueType === FilterValueType.STRING_LIST) {
                    existingCompactField.value = [...(existingCompactField.value as string[]), ...fieldValueToStringArray(field.value)];
                }
            }
        }
    });

    return Object.values(compactFieldsMap);
};
