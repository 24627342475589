import React from 'react';
import { Icon } from '@dome9/berries/react-components';
import { StatusMessageContent, StatusMessageWrapper } from './StatusMessagePanel.styled';
import { iStatusMessagePanelProps, StatusType } from './StatusMessagePanel.types';

const StatusMessagePanel: React.FC<iStatusMessagePanelProps> = (params) => {
    const icon = params.type === StatusType.SUCCESS ? 'checkCircle' : params.type;

    return (
        <StatusMessageWrapper type={params.type}>
            <Icon name={icon} color={params.type} size={16} />
            <StatusMessageContent>{params.text}</StatusMessageContent>
        </StatusMessageWrapper>
    );
};

export default StatusMessagePanel;
