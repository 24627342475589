import { getVendor, Vendors } from '../../../consts/vendors';
import { GslSource } from '../Gsl/GslService.const';

export enum LogType {
    ACCOUNT_ACTIVITY = 'accountActivity',
    NETWORK_ACTIVITY = 'networkActivity'
}

interface ILogSourceInfo {
    id: GslSource;
    type: LogType;
    platform: Vendors;
    platformAlias?: Vendors;
}

enum LogGslSource {
    cloudtrail = GslSource.cloudtrail,
    azurect = GslSource.azurect,
    k8sct = GslSource.kubernetescloudtrail,
    gcpct = GslSource.gcpct,
    vpcfl = GslSource.flowlogs,
    azurefl = GslSource.azurefl,
    k8s = GslSource.kubernetes,
    gcpfl = GslSource.gcpfl,
}

export const LogsSourcesMap: { [key in LogGslSource]: ILogSourceInfo } = {
    cloudtrail: {
        id: GslSource.cloudtrail,
        type: LogType.ACCOUNT_ACTIVITY,
        platform: Vendors.AWS,
    },
    azurect: {
        id: GslSource.azurect,
        type: LogType.ACCOUNT_ACTIVITY,
        platform: Vendors.AZURE,
    },
    k8sct: {
        id: GslSource.kubernetescloudtrail,
        type: LogType.ACCOUNT_ACTIVITY,
        platform: Vendors.KUBERNETES,
    },
    gcpct: {
        id: GslSource.gcpct,
        type: LogType.ACCOUNT_ACTIVITY,
        platform: Vendors.GCP,
        platformAlias: Vendors.GOOGLE,
    },
    vpcfl: {
        id: GslSource.flowlogs,
        type: LogType.NETWORK_ACTIVITY,
        platform: Vendors.AWS,
    },
    azurefl: {
        id: GslSource.azurefl,
        type: LogType.NETWORK_ACTIVITY,
        platform: Vendors.AZURE,
    },
    k8s: {
        id: GslSource.kubernetes,
        type: LogType.NETWORK_ACTIVITY,
        platform: Vendors.KUBERNETES,
    },
    gcpfl: {
        id: GslSource.gcpfl,
        type: LogType.NETWORK_ACTIVITY,
        platform: Vendors.GCP,
        platformAlias: Vendors.GOOGLE,
    },
};

const getAllLogSourcesInfos = (): ILogSourceInfo[] => {
    return Object.values(LogsSourcesMap);
};

const isLogSourcePlatformEqual = (platformOrPlatformAlias: string, logSourceInfo: ILogSourceInfo): boolean => {
    const vendor = getVendor(platformOrPlatformAlias)?.name as unknown as Vendors;
    if (!vendor) return false;
    return logSourceInfo.platform === vendor || logSourceInfo.platformAlias === vendor;
};

export const getGslSourceByPlatformAndType = (platformOrPlatformAlias: string, type: LogType): GslSource | undefined => {
    const logsSourceInfo = getAllLogSourcesInfos().find((logSourceInfo) =>
        isLogSourcePlatformEqual(platformOrPlatformAlias, logSourceInfo) && logSourceInfo.type === type);
    return logsSourceInfo?.id;
};
