import { isObject } from './helpFunctions';

const SESSION_KEY = `session-${new Date().getTime()}`;
const SESSION_DATA_CONTAINER_ID = '--CONTAINER--ID--';

interface ISessionDataContainer {
    sessionKey?: string;
    sessionDataContainerId: string;
    data: any;
}

export const removeSessionData = (key: string) => {
    localStorage.removeItem(key);
};

export const saveSessionData = (data: any, key: string, currentSessionOnly?: boolean) => {
    try {
        const dataContainer: ISessionDataContainer = {
            sessionKey: currentSessionOnly ? SESSION_KEY : undefined,
            sessionDataContainerId: SESSION_DATA_CONTAINER_ID,
            data,
        };
        const dataStr = JSON.stringify(dataContainer);
        localStorage.setItem(key, dataStr);
    } catch (err) {
        localStorage.removeItem(key);
        return;
    }
};

export const loadSessionData = (key: string): any => {
    const dataStr: string | null = localStorage.getItem(key);
    if (!dataStr) {
        return dataStr;
    }

    try {
        let data: any;
        const item: any = JSON.parse(dataStr);
        if (isObject(item) && item.sessionDataContainerId === SESSION_DATA_CONTAINER_ID) {
            if (!item.sessionKey || item.sessionKey === SESSION_KEY) {
                data = item.data;
            } else {
                localStorage.removeItem(key);
                data = undefined;
            }
        } else {
            data = item;
        }
        return data;
    } catch (err) {
        localStorage.removeItem(key);
        return undefined;
    }
};
