import React, { useCallback, useState } from 'react';
import { IAsset } from '../../../assets/common.assets';
import { AssetTypeRowStyled, ExampleStyled } from './DrawerExample.styled';
import { closeDrawer } from '../Drawer/Drawer.utils';
import { getAssets } from '../../../assets/Assets.service';
import { getNotificationsService } from '../../../interface/services';
import { NotificationType } from '../../../interface/notifications';
import { useDrawerHandler } from '../Drawer/UseDrawerHandler';
import {
    CLOSE_DRAWER_LARGE_TEXT_VIEWER,
    EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID,
    EXAMPLE_RESTART_LIST_HANDLER_ID,
    EXAMPLE_REVERSE_LIST_HANDLER_ID,
    showAssetDisplayNameDrawer,
    showAssetTypeNameDrawer,
} from './DrawerExampleInitialize';
import { globalModelUtils } from '../../GlobalModals/GlobalModals';
import { useCloseDrawerHandler } from '../Drawer/UseCloseDrawerHandler';

const fakeBrokenAsset: IAsset = {
    type: 'BROKEN',
    displayName: 'Fake Broken Asset',
    platform: 'aws',
    icon: '',
    isGenericObject: true,
    typeByPlatform: 'aws|broken',
    getUrl: () => null,
    idMap: {
        cloudEntityIdFieldName: '',
        protectedAssetIdFieldName: '',
        caseSensitive: false,
    },
};

const fakeSlowAsset: IAsset = {
    type: 'SLOW',
    displayName: 'Fake Slow Loading Asset',
    platform: 'aws',
    icon: '',
    isGenericObject: true,
    typeByPlatform: 'aws|slow',
    getUrl: () => null,
    idMap: {
        cloudEntityIdFieldName: '',
        protectedAssetIdFieldName: '',
        caseSensitive: false,
    },
};

const getItems = (): IAsset[] => {
    const allAssets: IAsset[] = getAssets();
    return [...allAssets.slice(0, 20), fakeBrokenAsset, fakeSlowAsset];
};

const DrawerExamplePage: React.FC = () => {
    const [assetTypes, setAssetTypes] = useState<IAsset[]>(getItems());

    const handleReverseListEvent = useCallback(
        (date: Date) => {
            const newAssetTypes = assetTypes.reverse();
            setAssetTypes(newAssetTypes);
            closeDrawer();
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: 'Table order Reversed',
                text: `Table rows were reversed at: ${date}`,
            });
        },
        [assetTypes],
    );

    const handleRestartListEvent = useCallback((date: Date) => {
        setAssetTypes(getItems());
        closeDrawer();
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Type List Reloaded',
            text: `All types restarted at: ${date}`,
        });
    }, []);

    const handleDeleteTypeEvent = useCallback(
        (typeByPlatform: string) => {
            const remainingAssetTypes = assetTypes.filter((assetType) => assetType.typeByPlatform !== typeByPlatform);
            setAssetTypes(remainingAssetTypes);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                title: 'Type Deleted',
                text: `Type ${typeByPlatform} was deleted from list`,
            });
        },
        [assetTypes],
    );

    const getHeader = useCallback(() => {
        return (
            <AssetTypeRowStyled.RowHeaderDiv key='header'>
                <AssetTypeRowStyled.CellHeaderDiv width={350}>{'Name'}</AssetTypeRowStyled.CellHeaderDiv>
                <AssetTypeRowStyled.CellHeaderDiv>{'Type'}</AssetTypeRowStyled.CellHeaderDiv>
                <AssetTypeRowStyled.CellHeaderDiv width={150}>{'Platform'}</AssetTypeRowStyled.CellHeaderDiv>
            </AssetTypeRowStyled.RowHeaderDiv>
        );
    }, []);

    const getRow = useCallback((assetType: IAsset) => {
        return (
            <AssetTypeRowStyled.RowSimpleDiv key={assetType.typeByPlatform}>
                <AssetTypeRowStyled.CellLinkDiv
                    width={350}
                    onClick={() => showAssetDisplayNameDrawer(assetType)}
                    color='purple'
                >
                    <AssetTypeRowStyled.CellIcon vendorNameOrPath={assetType.icon} />
                    {assetType.displayName}
                </AssetTypeRowStyled.CellLinkDiv>
                <AssetTypeRowStyled.CellLinkDiv onClick={() => showAssetTypeNameDrawer(assetType)} color='blue'>
                    {assetType.type}
                </AssetTypeRowStyled.CellLinkDiv>
                <AssetTypeRowStyled.CellTextDiv onClick={() => showAssetDisplayNameDrawer(assetType)} width={150}>
                    {assetType.platform?.toUpperCase()}
                </AssetTypeRowStyled.CellTextDiv>
            </AssetTypeRowStyled.RowSimpleDiv>
        );
    }, []);

    useDrawerHandler(EXAMPLE_REVERSE_LIST_HANDLER_ID, handleReverseListEvent);
    useDrawerHandler(EXAMPLE_RESTART_LIST_HANDLER_ID, handleRestartListEvent);
    useDrawerHandler(EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID, handleDeleteTypeEvent);
    useCloseDrawerHandler(CLOSE_DRAWER_LARGE_TEXT_VIEWER, (closeAll?: boolean) => {
        globalModelUtils.showConfirmationModal({
            title: 'Close Drawer',
            text: 'Are you sure you want to close this drawer?',
            onConfirm: () => closeDrawer(closeAll),
        });
    });

    return (
        <div className='bg-aside flex flex-1 flex-col'>
            <ExampleStyled.TopDiv>
                <ExampleStyled.DisclaimerDiv>
                    <p>
                        <strong>Disclaimer</strong>: This is not a real page in the system.{' '}
                    </p>
                    <p>
                        Rather, this page was created with the solely purpose of{' '}
                        <strong>displaying the new Drawer infrastructure</strong>, on top of a simple, fake example.
                    </p>
                    <p>
                        {' '}
                        This page, along with the corresponding menu on the left, will be visible only when the proper
                        Active Flag ("page-drawer") is set.
                    </p>
                </ExampleStyled.DisclaimerDiv>
                <ExampleStyled.TableDiv>
                    {getHeader()}
                    {assetTypes.map((assetType: IAsset) => getRow(assetType))}
                </ExampleStyled.TableDiv>
            </ExampleStyled.TopDiv>
        </div>
    );
};

export default DrawerExamplePage;
