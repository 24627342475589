import { ColumnApi } from 'ag-grid-community';
import {
    IncludeColumns,
    IncludeRows,
    ExportMethod,
    RadioName,
    UseExportToCsvTranslations,
    OnRadioChange,
    ExportToCsvProps,
} from './ExportToCsv.types';
import { DataTestIds } from './ExportToCsv.consts';

export const getFormRadioOptions = (type: RadioName, translations: UseExportToCsvTranslations) => {
    switch (type) {
        case RadioName.Columns:
            return [
                {
                    label: translations.columnsVisible,
                    value: IncludeColumns.Visible,
                    isHidden: false,
                    name: RadioName.Columns,
                    dataAid: DataTestIds.columnsVisible,
                },
                {
                    label: translations.columnsAll,
                    value: IncludeColumns.All,
                    isHidden: false,
                    name: RadioName.Columns,
                    dataAid: DataTestIds.columnsAll,
                },
            ];
        case RadioName.Rows:
            return [
                {
                    label: translations.rowsFiltered,
                    value: IncludeRows.Filtered,
                    isHidden: false,
                    name: RadioName.Rows,
                    dataAid: DataTestIds.rowsFiltered,
                },
                {
                    label: translations.rowsAll,
                    value: IncludeRows.All,
                    isHidden: false,
                    name: RadioName.Rows,
                    dataAid: DataTestIds.rowsAll,
                },
            ];
        case RadioName.Method:
            return [
                {
                    label: translations.deliveryDownload,
                    value: ExportMethod.DirectDownload,
                    isHidden: false,
                    name: RadioName.Method,
                    dataAid: DataTestIds.methodDirect,
                },
                {
                    label: translations.deliveryEmail,
                    value: ExportMethod.ByEmail,
                    isHidden: false,
                    name: RadioName.Method,
                    dataAid: DataTestIds.methodEmail,
                },
            ];
        default:
            return [];
    }
};

export const handleChangeRadioValue = (onRadioChange: OnRadioChange) => {
    const handleChangeRowsRadio = (value: string) => {
        onRadioChange(RadioName.Rows, value as IncludeRows);
    };

    const handleChangeColumnsRadio = (value: string) => {
        onRadioChange(RadioName.Columns, value as IncludeColumns);
    };

    const handleChangeMethodRadio = (value: string) => {
        onRadioChange(RadioName.Method, value as ExportMethod);
    };

    return { handleChangeRowsRadio, handleChangeColumnsRadio, handleChangeMethodRadio };
};

export const calculateExportToCsvProps = (
    exportToCsvProps: ExportToCsvProps,
    currentRows: number | string | undefined,
    totalRows: number | string | undefined,
    displayedColumnsCount: number,
    columnApiRef: React.MutableRefObject<ColumnApi | undefined>,
): ExportToCsvProps => {
    const { filteredRowsCount, rowsCount, visibleColumnsCount, allColumnsCount, ...rest } = exportToCsvProps;
    const isNumeric = (value: number | string | undefined): value is number => typeof value === 'number';
    return {
        filteredRowsCount: filteredRowsCount ?? (isNumeric(currentRows) ? currentRows : 0),
        rowsCount: rowsCount ?? (isNumeric(totalRows) ? totalRows : 0),
        visibleColumnsCount: visibleColumnsCount ?? displayedColumnsCount,
        allColumnsCount: allColumnsCount ?? columnApiRef.current?.getColumns()?.length,
        ...rest,
    };
};
