import styled, { css } from 'styled-components';
import Stack from '../../Stack';
import Typography from '../../Typography';

interface StepProps {
    isCompleted: boolean;
    isCurrent: boolean;
}

const StepWrapper = styled(Stack)`
    &:not(:last-child) {
        &::after {
            content: '';
            display: block;
            flex-grow: 1;
            margin: 4px 15px;
            height: 32px;
            width: 2px;
            min-height: 10px;
            background: ${({ theme }) => theme.palette.surface.tertiary};
            border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
        }
    }
`;

const StepCircle = styled(Stack)<StepProps>`
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    ${({ isCurrent, isCompleted, theme }) =>
        !isCurrent &&
        !isCompleted &&
        css`
            border: 2px solid ${theme.palette.surface.secondary};
            background-color: ${theme.palette.surface.secondary};
            color: ${theme.palette.text.normal};
        `}
    ${({ isCurrent, theme }) =>
        isCurrent &&
        css`
            border: 2px solid ${theme.palette.border.information};
            background-color: ${theme.palette.border.information};
            color: ${theme.palette.common.white};
        `}

  ${({ isCurrent, isCompleted, theme }) =>
        !isCurrent &&
        isCompleted &&
        css`
            border: 2px solid ${theme.palette.surface.success};
            color: ${theme.palette.surface.success};
        `}
`;

const StepTitle = styled(Typography)<StepProps>`
    text-overflow: ellipsis;
    overflow: hidden;
    ${({ isCurrent, isCompleted, theme }) =>
        !isCurrent &&
        !isCompleted &&
        css`
            color: ${theme.palette.text.normal};
        `}
    ${({ isCurrent, theme }) =>
        isCurrent &&
        css`
            color: ${theme.palette.border.information};
        `}

    ${({ isCurrent, isCompleted, theme }) =>
        !isCurrent &&
        isCompleted &&
        css`
            color: ${theme.palette.surface.success};
        `}
`;

const StepStyles = {
    StepWrapper,
    StepCircle,
    StepTitle,
};

export default StepStyles;
