import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    AwsEnvironmentToolbarActions,
    AwsEnvironmentData,
    AwsEnvironmentDataExtraData,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_CHIP,
    PATH_ENVIRONMENT_PAGE_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_TAB,
    PATH_ENVIRONMENT_PAGE_CUSTOM,
} from 'common/module_interface/assets/AwsEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { AddinBase } from './addinBase';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { ReactNode } from 'react';

export interface AwsEnvironmentAddingBase<
    T,
    T2 extends [AwsEnvironmentData, AwsEnvironmentDataExtraData] = [AwsEnvironmentData, AwsEnvironmentDataExtraData],
> extends AddinBase<T, T2> {
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

export interface CustomModalContent {
    content: ReactNode;
    name: string;
}

function addDetailsPanelItems(item: Addin<AwsEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): AwsEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_DETAILS_PANEL));
}

function addTabs(item: Addin<AwsEnvironmentAddingBase<Tab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_TAB), item);
}

function getTabs(): AwsEnvironmentAddingBase<Tab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_TAB));
}

function addActions(item: Addin<AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<AwsEnvironmentAddingBase<IChipProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CHIP), item);
}

function getChips(): AwsEnvironmentAddingBase<IChipProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CHIP));
}
function addModalCustomContent(item: Addin<AwsEnvironmentAddingBase<CustomModalContent>>[]) {
    return globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CUSTOM), item);
}
function getModalCustomContent(): AwsEnvironmentAddingBase<CustomModalContent>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CUSTOM));
}

export const AwsEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips,
    addModalCustomContent,
    getModalCustomContent,
};
