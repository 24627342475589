import { changeUrl } from 'common/utils/http';
import React from 'react';
import { useLocation } from 'react-router-dom';

export interface ChangeParamPayload {
    paramKey: string;
    newValue: string;
}
type DefaultParams = { [key: string]: string };
interface UseReactRouterQuery<T = DefaultParams> {
    params: T;
    deleteParams: (paramKeys: string[]) => void;
    changeParam: (paramKey: string, newValue: string, isReplace?: boolean) => void;
    changeParams: (params: ChangeParamPayload[], isReplace?: boolean) => void;
}

function useReactRouterQuery<T = DefaultParams>(): UseReactRouterQuery<T> {
    const { search } = useLocation();

    const deleteParams = React.useCallback((paramKeys: string[]) => {
        const searchParams = new URLSearchParams(window.location.search);
        paramKeys.forEach((paramKey) => searchParams.delete(paramKey));
        const newUrl = decodeURIComponent(`${window.location.pathname}?${searchParams.toString()}`);
        changeUrl(newUrl, true);
    }, []);

    const changeParams = React.useCallback((params: ChangeParamPayload[], isReplace = true) => {
        const searchParams = new URLSearchParams(window.location.search);
        params.forEach(({ paramKey, newValue }) => {
            searchParams.delete(paramKey);
            searchParams.append(paramKey, encodeURIComponent(newValue));
        });
        const newUrl = decodeURIComponent(`${window.location.pathname}?${searchParams.toString()}`);
        changeUrl(newUrl, isReplace);
    }, []);

    const changeParam = React.useCallback(
        (paramKey: string, newValue: string, isReplace = true) => {
            changeParams([{ paramKey, newValue }], isReplace);
        },
        [changeParams],
    );

    const params = React.useMemo(() => {
        const paramsKeyValue: DefaultParams = {};
        const params = new URLSearchParams(search);

        for (const [key, value] of params) {
            paramsKeyValue[key] = value;
        }

        return paramsKeyValue;
    }, [search]);

    return { params: params as T, deleteParams, changeParam, changeParams };
}

export default useReactRouterQuery;
