import React from 'react';
import { GeneralErrorPage, GeneralErrorPageProps } from '../GeneralErrorsPage/GeneralErrorsPage';

const withAccountDeletedText = (WrappedComponent: React.FC<GeneralErrorPageProps>) => {
    return (props: GeneralErrorPageProps) => {
        const {
            titleKey = 'SETTINGS.ACCOUNT_DELETED.MESSAGE_TITLE',
            firstMessageKey = 'SETTINGS.ACCOUNT_DELETED.MESSAGE_FIRST',
            secondMessageKey = 'SETTINGS.ACCOUNT_DELETED.MESSAGE_SECOND',
        } = props;

        return (
            <WrappedComponent
                titleKey={titleKey}
                firstMessageKey={firstMessageKey}
                secondMessageKey={secondMessageKey}
            />
        );
    };
};

const AccountDeletedPage = withAccountDeletedText(GeneralErrorPage);
export default AccountDeletedPage;
