import { CommonEventFields } from 'common/module_interface/events/EventsConsts';

export const FINDING_ASSET_PANEL_ID = 'intelligence-asset-panel';

export enum KustoEventFields {
    additionalFieldsMap = 'additionalFieldsMap',
    cloudAccountName = 'cloudAccountName',
    comments = 'comments',
    count = 'count',
    isArchived = 'isArchived',
    logType = 'logType',
    magellanNetworkSrcAddress = 'magellanNetworkSrcAddress',
    magellanNetworkSrcCountryCode = 'magellanNetworkSrcCountryCode',
    mitreDict = 'mitreDict',
    mitreTactic = 'mitreTactic',
    mitreTechnique = 'mitreTechnique',
    ruleLogicHash = 'ruleLogicHash',
    statusId = 'statusId',
    statusName = 'statusName',
    numberOfRows = 'numberOfRows',
    id = 'id',
}

export enum FindingStatusCode {
    OPEN = 0,
    CLOSE = 1,
}

export enum KustoEventFilterFields {
    EntityTypeByEnvironmentType = CommonEventFields.entityTypeByEnvironmentType,
    CloudAccountType = CommonEventFields.cloudAccountType,
    OrganizationalUnitId = CommonEventFields.organizationalUnitId,
    CloudAccountId = CommonEventFields.cloudAccountId,
    Severity = CommonEventFields.severity,
    EntityName = CommonEventFields.entityName,
    Region = CommonEventFields.region,
    RuleName = CommonEventFields.ruleName,
    BundleName = CommonEventFields.bundleName,
    Action = CommonEventFields.action,
    Remediation = CommonEventFields.remediation,
    Category = CommonEventFields.category,
    IsExcluded = CommonEventFields.isExcluded,
    Origin = CommonEventFields.origin,
    OwnerUserName = CommonEventFields.ownerUserName,
    Acknowledged = CommonEventFields.acknowledged,
    StatusId = KustoEventFields.statusId,
    MitreTactic = KustoEventFields.mitreTactic,
    MitreTechnique = KustoEventFields.mitreTechnique,
    LogType = KustoEventFields.logType,
}

export enum LogType {
    Network = 'networkActivity',
    Account = 'accountActivity',
}
