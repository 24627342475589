import styled from 'styled-components';
import Stack from '../Stack/Stack';
import Button from '../Button/Button';
import { ITableProps } from './Table.types';

const Wrapper = styled(Stack)<Pick<ITableProps, 'ellipsisRowGroup'>>`
    .ag-header-cell,
    .ag-header-group-cell {
        padding: ${({ theme }) => `0px ${theme.spacing(4)}${theme.units}`};
    }
    .ag-selection-checkbox {
        padding-left: ${({ theme }) =>
            `${theme.spacing(1)}${theme.units}`}; // this is to align the checkbox with the top checkbox
    }
    .ag-row-hover .actions-cell {
        display: flex;
    }
    .ag-row-hover:before {
        background: ${({ theme }) => theme.palette.componentStates.ghostHover};
    }
    .ag-row {
        transition: none;
        border-radius: 8px;
    }

    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border-right: unset;
    }

    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
        border-left: unset;
    }

    .ag-pinned-left-header {
        border-left: unset;
    }

    .ag-pinned-right-header {
        border-left: unset;
    }

    .ag-theme-alpine.ag-theme-alpine.ag-theme-alpine {
        .ag-row-hover {
            background: ${({ theme }) => theme.palette.componentStates.ghostHover};

            a {
                color: inherit;
                text-decoration: underline;
            }
        }

        .ag-row-hover:before {
            background: ${({ theme }) => theme.palette.componentStates.ghostHover};
        }
    }

    .ag-pinned-left-cols-container {
        border-right-width: 0;

        .ag-row {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .ag-pinned-right-cols-container {
        border-left-width: 0;

        .ag-row {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    .ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper {
        .ag-row {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    .ag-center-cols-clipper:has(+ .ag-pinned-right-cols-container:not(.ag-hidden)) {
        .ag-row {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .ag-cell.ag-cell-last-left-pinned.ag-cell-value {
        border-right-width: 0;
    }

    .ag-cell.ag-cell-last-right-pinned.ag-cell-value {
        border-left-width: 0;
    }

    .ag-row-selected:before {
        background: ${({ theme }) => theme.palette.componentStates.ghostActive};
    }
    .ag-row-hover.ag-row-selected:before {
        background: ${({ theme }) => theme.palette.componentStates.ghostDoubleActive};
    }

    .ag-cell-wrapper {
        height: 100%;
    }

    .ag-cell-wrapper.ag-row-group {
        align-items: center;
    }

    ${({ ellipsisRowGroup }) =>
        !ellipsisRowGroup &&
        `.ag-row:has(.ag-row-group) {
            .ag-cell-wrapper {
                overflow: visible;
            }
            .ag-cell-value {
                overflow: visible;
            }
            .ag-row-group {
                width: fit-content;
                }
            }`}

    && .ag-checkbox-input-wrapper.ag-checkbox-input-wrapper.ag-checkbox-input-wrapper.ag-checkbox-input-wrapper {
        height: 16px;
        width: 16px;
        min-width: 16px;
        max-width: 16px;
        border-radius: ${({ theme }) => `${theme.border.radius(1)}${theme.units}`};
        border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.strong}`};
        overflow: hidden;
        &::after {
            content: '';
            margin: 0 !important;
            height: 16px;
            width: 16px;
            mask: unset;
        }
        &.ag-checked {
            border: unset;
            &::after {
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwMDVfNTIxOTIpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00IDBDMS43OTA4NiAwIDAgMS43OTA4NiAwIDRWMTJDMCAxNC4yMDkxIDEuNzkwODYgMTYgNCAxNkgxMkMxNC4yMDkxIDE2IDE2IDE0LjIwOTEgMTYgMTJWNEMxNiAxLjc5MDg2IDE0LjIwOTEgMCAxMiAwSDRaIiBmaWxsPSIjMzU5NEZGIi8+CjxwYXRoIGQ9Ik0xMi41MzUxIDUuMjUwMjFDMTIuODY1MyA1LjU4MzgzIDEyLjg2NTMgNi4xMjQ3MyAxMi41MzUxIDYuNDU4MzVMOC43NjQ1NCAxMC4yNjcxQzguMDQ3OTQgMTAuOTkxIDYuODg2ODggMTAuOTkzNiA2LjE2NzE3IDEwLjI3MjhMNC4yNTAzMSA4LjM1MzI5QzMuOTE4NjEgOC4wMjExMyAzLjkxNjI4IDcuNDgwMjMgNC4yNDUxIDcuMTQ1MTZDNC41NzM5MyA2LjgxMDEgNS4xMDkzOSA2LjgwNzc1IDUuNDQxMDkgNy4xMzk5MUw3LjM1Nzk1IDkuMDU5NDZDNy40MTYzMSA5LjExNzkgNy41MTA0NSA5LjExNzcgNy41Njg1NSA5LjA1OUwxMS4zMzkxIDUuMjUwMjFDMTEuNjY5MyA0LjkxNjYgMTIuMjA0OCA0LjkxNjYgMTIuNTM1MSA1LjI1MDIxWiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xMDA1XzUyMTkyIj4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=);
            }
        }
        &.ag-indeterminate {
            border: unset;
            &::after {
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwMDlfNTM5OTgpIj4KPHBhdGggZD0iTTAgNEMwIDEuNzkwODYgMS43OTA4NiAwIDQgMEgxMkMxNC4yMDkxIDAgMTYgMS43OTA4NiAxNiA0VjEyQzE2IDE0LjIwOTEgMTQuMjA5MSAxNiAxMiAxNkg0QzEuNzkwODYgMTYgMCAxNC4yMDkxIDAgMTJWNFoiIGZpbGw9IiMzNTk0RkYiLz4KPHBhdGggZD0iTTQgOEM0IDcuNDQ3NzIgNC40NDc3MiA3IDUgN0gxMUMxMS41NTIzIDcgMTIgNy40NDc3MiAxMiA4QzEyIDguNTUyMjggMTEuNTUyMyA5IDExIDlINUM0LjQ0NzcyIDkgNCA4LjU1MjI4IDQgOFoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTAwOV81Mzk5OCI+CjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgcng9IjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==);
            }
        }
    }
`;

const Footer = styled(Stack)`
    border-top: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
`;

const GridContainer = styled(Stack)<ITableProps>`
    &.ag-theme-alpine.ag-theme-alpine.ag-theme-alpine {
        ${({ gridThemeOverride }) =>
            gridThemeOverride?.groupIndentSize && `--ag-row-group-indent-size: ${gridThemeOverride.groupIndentSize};`}
     }
    }
`;

const DropdownWrapper = styled.div`
    white-space: nowrap;
    .dropdown--export-select {
        border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
        box-shadow: ${({ theme }) => theme.shadows[2]};
    }
`;

const ExportButton = styled(Button)`
    && {
        max-width: unset;
    }
`;

const TableStyles = {
    ExportButton,
    Wrapper,
    Footer,
    DropdownWrapper,
    GridContainer,
};

export default TableStyles;
