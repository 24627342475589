import React, { useCallback, useMemo } from 'react';
import { IConditionValueOption, IConditionValuesComponentProps } from '../FilterTree.interface';
import { useTranslation } from 'react-i18next';
import { FilterStyled } from '../FilterTreeComponent/FilterTree.styled';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { SelectV2 } from 'common/design-system/components-v2';
import { IFilterConditionValue } from '../FilterCondition';

interface IRealValueOption extends SelectOption {
    realValue: IFilterConditionValue;
}

export const getRealValuesOptions = (conditionsOptions: IConditionValueOption[]): IRealValueOption[] => {
    return conditionsOptions.map(conditionOption => ({
        ...conditionOption,
        value: String(conditionOption.value),
        realValue: conditionOption.value,
    }));
};

export const SingleValueSelector: React.FC<IConditionValuesComponentProps> =
    ({ condition, filterProps, onValuesChange }) => {
        const filterDef = useMemo(() => filterProps.filterDefinitions.find(def => def.name === condition.name), [condition.name, filterProps.filterDefinitions]);
        const conditionOptions: IConditionValueOption[] = useMemo(() => filterDef?.valueOptions || [], [filterDef?.valueOptions]);
        const selectOptions: IRealValueOption[] = useMemo(() => getRealValuesOptions(conditionOptions), [conditionOptions]);
        const { t } = useTranslation();

        const onSelectionChange = useCallback((option: SelectOption | undefined) => {
            onValuesChange([option ? (option as IRealValueOption).realValue : '']);
        }, [onValuesChange]);

        let value = condition.values.length === 0 ? '' : String(condition.values[0]);
        if (!value && selectOptions.length === 1) {
            value = String(selectOptions[0].realValue);
            onSelectionChange(selectOptions[0]);
        }
        return (
            <FilterStyled.StretchDiv>
                <SelectV2
                    fullWidth
                    value={value}
                    onChange={(value: string | undefined, option: SelectOption | null) => onSelectionChange(option || undefined)}
                    placeholder={filterProps.readOnly ? undefined : t('FILTER_TREE.VALUES_PLACEHOLDER')}
                    options={selectOptions}
                    clearable
                    isError={!!(filterProps.displayErrors && condition.valuesErrorMsg)}
                    readOnly={filterProps.readOnly}
                    isMulti={false}
                />
            </FilterStyled.StretchDiv>
        );
    };
