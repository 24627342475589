import styled, { css } from 'styled-components';
import Stack from '../Stack';
import { LabelColor } from './Label.types';

interface IWrapperProps {
    color: LabelColor;
    disabled?: boolean;
    whiteSpace: 'nowrap' | 'normal' | 'pre-line';
}
const Wrapper = styled(Stack)<IWrapperProps>`
    color: ${({ theme, color }) => (color === 'inherit' ? 'inherit' : theme.palette.text[color])};
    overflow: hidden;
    width: fit-content;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    label,
    span {
        overflow: hidden;
        white-space: ${({ whiteSpace }) => whiteSpace};
    }

    ${({ disabled, theme }) =>
        disabled &&
        css`
            opacity: ${theme.disabledOpacity};
        `}
`;

const LabelWrapper = styled(Stack)`
    flex: 1;
    overflow: hidden;
`;

const Labeltyles = {
    Wrapper,
    LabelWrapper,
};

export default Labeltyles;
