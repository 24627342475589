export interface AddinBase<ResponseType = any, ArgsType extends any[] = any[]> {
    id: string;
    isRelevant?: (...args: ArgsType) => Promise<boolean> | boolean;
    getValue: (...args: ArgsType) => Promise<ResponseType> | ResponseType;
}

export interface ResolveAddinBaseGetterResponse<ResponseType, ArgsType extends any[]> {
    value?: ResponseType;
    addin: AddinBase<ResponseType, ArgsType>;
}
export interface ResolveAddinBaseGetter<ResponseType, ArgsType extends any[]> {
    addins: AddinBase<ResponseType, ArgsType>[]; // addins to resolve
    additionalData: ArgsType; // additional data to pass to the addin functions
    abortController?: AbortController; // abort controller to abort the process
    setter: (response: ResolveAddinBaseGetterResponse<ResponseType, ArgsType>) => void; // called for each resolved addin
    onComplete?: (response: ResolveAddinBaseGetterResponse<ResponseType, ArgsType>[]) => void; // called when all addins are resolved
}
export function resolveAddinBaseGetter<ResponseType, ArgsType extends any[]>(
    params: ResolveAddinBaseGetter<ResponseType, ArgsType>,
): void {
    const { addins, additionalData, abortController, setter, onComplete } = params;

    const addinsValues: ResolveAddinBaseGetterResponse<ResponseType, ArgsType>[] = [];

    const checkIfNotAborted = () => {
        if (abortController?.signal.aborted) {
            return true;
        }
        return false;
    };

    const setValue = (response: ResolveAddinBaseGetterResponse<ResponseType, ArgsType>) => {
        setter(response);
        if (!onComplete) return;

        addinsValues.push(response);
        if (addinsValues.length === addins.length) {
            onComplete(addinsValues);
        }
    };

    addins.forEach(async (addin) => {
        if (checkIfNotAborted()) return;

        try {
            const isRelevant = await (addin.isRelevant ? addin.isRelevant(...additionalData) : true);
            if (!isRelevant || checkIfNotAborted()) return;

            const value = await addin.getValue(...additionalData);
            if (checkIfNotAborted()) return;

            setValue({ value, addin });
        } catch (error) {
            console.error(`Error resolving addin ${addin.id}:`, error);
            setValue({ addin });
        }
    });
}
