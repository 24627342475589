import { IElasticEvent, IElasticEventModel } from './ElasticEvent.interface';
import { SysEventType } from '../events/Events';

export const getElasticEventFromModel = (serverItem: IElasticEventModel): IElasticEvent => {
    const elasticEvent: IElasticEvent = { ...serverItem };
    elasticEvent.sysEventType = SysEventType.elastic;
    return elasticEvent;
};

export const getElasticEventListFromModels = (serverItems: IElasticEventModel[]): IElasticEvent[] => {
    return serverItems.map((serverItem) => getElasticEventFromModel(serverItem));
};
