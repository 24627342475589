import { IPolicy, IShallowRuleset, IVendorRuleset, POLICY_SERVICE } from '../../interface/policy';
import { getUniqueObjectsByField } from '../../utils/objectUtils';
import { Vendors } from '../../consts/vendors';
import addinContainer, { getService } from '../../extensibility/AddinContainer';
import {
    getHttpService,
    getPolicyService,
    getShiftLeftService,
    IPolicyService,
    IRulesetService,
    RULESET_SERVICE_ID,
} from '../../interface/services';
import { ListItem } from './MultiSelectList/ListItemTypes/interfaces';
import { isLocalhost } from '../../utils/RuntimeEnvironment';
import { changeUrl, getProdEnvironmentBaseUrl } from '../../utils/http';
import { IFRAME_TAGS } from '../../interface/IFrame.message.model';
import { CardItem } from './Card';

export const SHIFTLEFT_RELATED_PLATFORMS = [
    Vendors.SHIFT_LEFT,
    Vendors.TERRAFORM,
    Vendors.CFT,
    Vendors.IMAGE_ASSURANCE,
    Vendors.SOURCE_CODE_ASSURANCE,
];

export const getFixedVendorIcon = (iconName?: string) => {
    switch (iconName) {
        case Vendors.CONTAINER_REGISTRY:
            return 'containers-registry';
        case Vendors.IMAGE_ASSURANCE:
            return 'image-protection';
        case Vendors.GCP:
        case Vendors.GOOGLE:
            return 'gcp';
        case Vendors.OCI:
            return 'oracle';
        case Vendors.KUBERNETES_RUNTIME_ASSURANCE:
            return 'kubernetes';
        case Vendors.SHIFT_LEFT:
        case Vendors.SOURCE_CODE_ASSURANCE:
            return 'shiftleft';
        case Vendors.CFT: {
            const baseUrl = isLocalhost ? getProdEnvironmentBaseUrl() : window.location.origin + '/v2/';
            return (
                baseUrl + 'app-assets/vendor-icons/old-pack/AWSSimpleIcons/ManagementTools/AWS-CloudFormation_Stack.svg'
            );
        }
        default:
            return iconName || '';
    }
};
export const getRulesets = async (vendorsRulesetList: IVendorRuleset[]) => {
    const _rulesets = [];
    for (const vendorRuleset of vendorsRulesetList) {
        const rulesets = await vendorRuleset.getRulesets();
        _rulesets.push(...rulesets);
    }

    const items: ListItem[] =
        _rulesets?.map((ruleset) => {
            return {
                id: ruleset.id,
                name: ruleset.name || '',
                icon: getFixedVendorIcon(ruleset.icon),
                availableVersions: ruleset.availableVersions,
                versionEnabled: true,
                platform: ruleset.platform,
            };
        }) || [];

    return getUniqueObjectsByField(items, 'name');
};

export const getPolicyRulesets = async () => {
    let rulesets = await getService<IRulesetService>(RULESET_SERVICE_ID).getAllRulesets();
    rulesets = rulesets.map((ruleset) => {
        return {
            ...ruleset,
            cloudVendor: ruleset.cloudVendor,
            availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
        };
    });
    const shallowRulesets: IShallowRuleset[] = rulesets.map((ruleset) => {
        return {
            id: ruleset.id.toString(),
            name: ruleset.name,
            icon: ruleset.cloudVendor,
            description: ruleset.description,
            availableVersions: ruleset.availableVersions,
            platform: ruleset.cloudVendor,
        };
    });
    return shallowRulesets;
};

export const saveUpdatePolicies = async (policies: IPolicy[], update = false): Promise<boolean> => {
    const shiftLeftRelatedPlatforms = [
        Vendors.SHIFT_LEFT,
        Vendors.TERRAFORM,
        Vendors.CFT,
        Vendors.IMAGE_ASSURANCE,
        Vendors.SOURCE_CODE_ASSURANCE,
    ];
    const imageAssurancePolicies = policies.filter((policy: IPolicy) =>
        shiftLeftRelatedPlatforms.includes(policy.rulesetPlatform as Vendors),
    );
    const platformPolicies = policies.filter(
        (policy: IPolicy) => !shiftLeftRelatedPlatforms.includes(policy.rulesetPlatform as Vendors),
    );
    let platformRequestPromise = null;
    let imageAdmissionRequestPromise = null;
    if (update) {
        platformRequestPromise = platformPolicies?.length
            ? getPolicyService().updatePolicy(platformPolicies)
            : Promise.resolve();
        imageAdmissionRequestPromise = imageAssurancePolicies?.length
            ? getShiftLeftService().updateShiftLeftPolicy(imageAssurancePolicies)
            : Promise.resolve();
    } else {
        platformRequestPromise = platformPolicies?.length
            ? getPolicyService().setPolicy(platformPolicies)
            : Promise.resolve();
        imageAdmissionRequestPromise = imageAssurancePolicies?.length
            ? getShiftLeftService().setShiftLeftPolicy(imageAssurancePolicies)
            : Promise.resolve();
    }
    try {
        const responses = await Promise.all([platformRequestPromise, imageAdmissionRequestPromise]);
        if (responses.some((policies) => policies?.some((policy) => policy?.errorMessage))) {
            return false;
        } else {
            return true;
        }
    } catch {
        return false;
    }
};

export const WorkloadSupportedPlatforms: CardItem[] = [
    {
        id: Vendors.KUBERNETES,
        title: Vendors.KUBERNETES,
        icon: 'kubernetes',
    },
    {
        id: Vendors.SHIFT_LEFT,
        title: Vendors.SHIFT_LEFT,
        icon: 'shiftleft',
    },
    {
        id: Vendors.CONTAINER_REGISTRY,
        title: 'Container Registry',
        icon: 'containers-registry',
    },
    {
        id: Vendors.AWS,
        title: 'Amazon Web Services',
        icon: 'aws',
    },
    {
        id: Vendors.AZURE,
        title: 'Microsoft Azure',
        icon: 'azure',
    },
];

export interface PoliciesModulesMap {
    moduleName: string;
    policies: IPolicy[];
}
export const getAllPolicies = async (
    useCache = false,
    errorCodeIgnoreList?: number[],
): Promise<PoliciesModulesMap[]> => {
    const allPolicies: PoliciesModulesMap[] = [];
    const policyServicesObj = addinContainer.get<{ moduleName: string; service: IPolicyService }>(POLICY_SERVICE);
    const settledResults = await Promise.allSettled(
        policyServicesObj.map((policyServiceObj) =>
            policyServiceObj.service.getPolicies(useCache, errorCodeIgnoreList),
        ),
    );
    settledResults.forEach((result, index) => {
        if (result.status === 'fulfilled') {
            allPolicies.push({
                moduleName: policyServicesObj[index].moduleName,
                policies: result.value,
            });
        }
    });
    return allPolicies;
};

export const finishFlow = (returnUrl: string) => {
    getHttpService().clearCacheByTag(IFRAME_TAGS.CLEAR_CACHE.POLICIES);
    getPolicyService().clearCache();
    changeUrl(returnUrl);
};
