import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { ICustomComponentItem, IOnFieldChanged, IItemsMap, IFullErrorInfo } from '../../CustomForm.interface';
import ItemComponentBottomArea from '../helperComponents/ItemComponentBottomArea';

export interface ICustomItemComponentProps {
    item: ICustomComponentItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    itemsMap: IItemsMap;
    isInner?: boolean;
}

export const CustomItemComponent: FC<ICustomItemComponentProps> = ({
    item,
    onFieldChanged,
    showErrors,
    errorInfo,
    skipTitle,
    isInner,
    itemsMap,
}) => {
    const { state } = item;
    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <item.Component
                item={item}
                onChange={onFieldChanged}
                isError={!!(showErrors && errorInfo)}
                errorInfo={errorInfo}
                showErrors={showErrors}
                itemsMap={itemsMap}
            />
            <ItemComponentBottomArea state={state} showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};
