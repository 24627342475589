import React from 'react';
import { Select } from '@dome9/berries/react-components';
import i18n from 'i18next';
import { SingleValue } from 'react-select';

export interface ISelectOption {
    label?: string | null;
    value?: string | null;
}

export const listWidgetAdditionalFields: React.FC<any> = ({ widget, updateWidgetCallback }) => {
    const widgetTypeOptions = [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
    ];
    const selectedLimit = { value: widget.limit || 10 };
    const handleChangeWidgetType = (option: any) => {
        widget.limit = option.value;
        updateWidgetCallback(widget);
    };
    return (
        <div>
            <div className={'font-semibold mb-2'}>Items Count</div>
            <Select
                required={false}
                value={widgetTypeOptions?.filter((option) => option.value === selectedLimit.value) as any} //TODO: fix this
                onChange={(option: SingleValue<ISelectOption>) => handleChangeWidgetType(option)}
                closeMenuOnSelect={true}
                placeholder={i18n.t('GENERAL.LOADING')}
                isSearchable={true}
                options={widgetTypeOptions}
            />
        </div>
    );
};
