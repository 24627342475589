import {
    getNumberFromString,
    getSafeStringArrayFromString,
    getSafeStringFromStringArray,
    getStringFromBoolean,
    getStringFromNumber,
    isBoolean,
} from '../types';
import {
    IGslBin, IGslCount,
    IGslFilter,
    IGslFreeTextFilter,
    IGslSort,
    IGslTimeFilter,
} from '../../../components/gsl/GslService.interface';
import { ICompoundFilter, ICompoundFilterItem, ICompoundFilterNode } from '../../custom/FilterTree/CompoundFilter';
import { IFilterCondition, IFilterConditionValue } from '../../custom/FilterTree/FilterCondition';
import {
    IFieldMappersMap,
    IItemContentConvertor,
    IModelUiMappingInfo,
    IModelUiValueConvertorsPair,
} from './convertors.interface';
import { IFieldInfo, IValueCount } from '../../../interface/general';
import { Aggregations } from '../../../components/FilterPanel/FilterPanel.interface';

export const convertItem = <S, T>(modelItem: S, convertors: IItemContentConvertor<S, T>[]): T => {
    const uiItem: Partial<T> = {};
    convertors.forEach(convert => convert(modelItem, uiItem));
    return uiItem as T;
};

export enum FieldValueType {
    NUMBER,
    BOOLEAN,
    OBJECT,
    ARRAY,
    ANY,
    STRING,
}

export const isStringModelField = (fieldMapper?: IModelUiMappingInfo): boolean => {
    return fieldMapper?.modelFieldType ? (fieldMapper.modelFieldType === FieldValueType.STRING) : true;
};

export const createItemContentConvertorByMappers = <S, T>(mappers: IModelUiMappingInfo[]): IItemContentConvertor<S, T> => {
    return (modelItem: S, uiItem: Partial<T>) => {
        mappers.forEach((fieldMapper: IModelUiMappingInfo) => {
            let modelValue: any = modelItem[fieldMapper.modelField as keyof S];
            if (isStringModelField(fieldMapper) && (modelValue === null)) {
                modelValue = undefined;
            }
            if (modelValue !== undefined) {
                const uiField = fieldMapper.uiField || fieldMapper.modelField;
                uiItem[uiField as keyof T] = fieldMapper.convertorsPair ? fieldMapper.convertorsPair.convertValueModelToUi(modelValue) : modelValue;
            }
        });
    };
};

// Convertor Pairs (for field mapper)
export const booleanToStringConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: isBoolean,
    convertValueUiToModel: getStringFromBoolean,
};

export const numberToStringConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: getNumberFromString,
    convertValueUiToModel: getStringFromNumber,
};

export const safeStringToStringArrayConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: getSafeStringArrayFromString,
    convertValueUiToModel: getSafeStringFromStringArray,
};

export const convertAnythingToUndefined = () => undefined;

export const convertFieldNameUiToModel = (uiMappersMap: IFieldMappersMap ,name: string): string => {
    const mapper: IModelUiMappingInfo | undefined = uiMappersMap[name as string];
    return mapper ? mapper.modelField : name;
};

export const convertFieldNameListUiToModel = (uiMappersMap: IFieldMappersMap ,names: string[]): string[] => {
    return names.map(name => {
        return convertFieldNameUiToModel(uiMappersMap, name);
    });
};

export const convertFieldInfoUiToModelByFieldMapper = (fieldInfo: IFieldInfo, mapper: IModelUiMappingInfo): IFieldInfo => {
    return {
        name: mapper.modelField,
        value: mapper.convertorsPair ? mapper.convertorsPair.convertValueUiToModel(fieldInfo.value) : fieldInfo.value,
    };
};

const convertFieldInfoUiToModel = (uiMappersMap: IFieldMappersMap, fieldInfo: IFieldInfo): IFieldInfo => {
    const mapper: IModelUiMappingInfo | undefined = uiMappersMap[fieldInfo.name];
    return mapper ? convertFieldInfoUiToModelByFieldMapper(fieldInfo, mapper) : fieldInfo;
};

export const convertFieldInfoListUiToModel = (uiMappersMap: IFieldMappersMap, fields: IFieldInfo[]): IFieldInfo[] => {
    return fields.map((fieldInfo: IFieldInfo) => {
        return convertFieldInfoUiToModel(uiMappersMap, fieldInfo);
    });
};

export const convertFieldValuesUiToModel = (uiMappersMap: IFieldMappersMap ,uiName: string, values?: any[]): any[] | undefined => {
    if (!values) {
        return undefined;
    }

    const mapper: IModelUiMappingInfo | undefined = uiMappersMap[uiName];
    if (!mapper?.convertorsPair) {
        return values;
    }

    return values.map(value => mapper.convertorsPair?.convertValueUiToModel(value));
};

export const convertTimeFilterUiToModel = (uiMappersMap: IFieldMappersMap, timeFilter?: IGslTimeFilter): IGslTimeFilter | undefined => {
    if (!timeFilter) {
        return undefined;
    }

    return {
        ...timeFilter,
        field: convertFieldNameUiToModel(uiMappersMap, timeFilter.field),
    };
};

export const convertFreeTextFilterUiToModel = (uiMappersMap: IFieldMappersMap, freeTextFilter?: IGslFreeTextFilter): IGslFreeTextFilter | undefined => {
    if (!freeTextFilter) {
        return undefined;
    }

    return {
        ...freeTextFilter,
        fields: convertFieldNameListUiToModel(uiMappersMap, freeTextFilter.fields),
    };
};

export const convertCompoundFilterUiToModel = (uiMappersMap: IFieldMappersMap, compoundFilter?: ICompoundFilter): ICompoundFilter | undefined => {
    if (!compoundFilter) {
        return undefined;
    }

    const convertItem = (item: ICompoundFilterItem): ICompoundFilterItem => {
        if ((item as ICompoundFilterNode).logicalOperator !== undefined) {
            return convertNode(item as ICompoundFilterNode);
        }
        return convertCond(item as IFilterCondition);
    };

    const convertNode = (node: ICompoundFilterNode): ICompoundFilterNode => ({
        ...node,
        operands: node.operands.map(convertItem),
    });

    const convertCond = (cond: IFilterCondition): IFilterCondition => ({
        ...cond,
        name: cond.name ? convertFieldNameUiToModel(uiMappersMap, cond.name) : cond.name,
        values: convertFieldValuesUiToModel(uiMappersMap, cond.name, cond.values) as IFilterConditionValue[],
    });

    return {
        root: convertNode(compoundFilter.root),
    };
};

export const convertGslSortUiToModel = (uiMappersMap: IFieldMappersMap, sortList?: IGslSort[]): IGslSort[] | undefined => {
    if (!sortList) {
        return undefined;
    }

    return sortList.map(sort => ({
        ...sort,
        fieldName: convertFieldNameUiToModel(uiMappersMap, sort.fieldName),
    }));
};

export const convertAggregationCountersModalToUi = (mapper: IModelUiMappingInfo, modelCounters: IValueCount[]): IValueCount[] => {
    const convertorsPair: IModelUiValueConvertorsPair | undefined = mapper?.convertorsPair;
    if (!convertorsPair) {
        return modelCounters;
    }

    return modelCounters.map((counter: IValueCount) => {
        return {
            ...counter,
            value: convertorsPair.convertValueModelToUi(counter.value)
        };
    });
};

export const convertAggregationsModelToUi = (modelMappersMap: IFieldMappersMap, modelAggregations: Aggregations): Aggregations => {
    const uiAggregations: Aggregations = {};
    Object.keys(modelAggregations).forEach((modelField: string) => {
        const mapper = modelMappersMap[modelField];
        if (!mapper) {
            uiAggregations[modelField] = modelAggregations[modelField];
        } else {
            uiAggregations[mapper.uiField] = convertAggregationCountersModalToUi(mapper, modelAggregations[modelField]);
        }
    });

    return uiAggregations;
};

export const convertKustoFieldNameListUiToModel = (mappersByUiName: { [key: string]: IModelUiMappingInfo },names: string[]): string[] => {
    return convertFieldNameListUiToModel(mappersByUiName, names);
};

export const convertKustoAggregationsModelToUi = (mappersByModelName: { [key: string]: IModelUiMappingInfo },modelAggregations: Aggregations): Aggregations => {
    return convertAggregationsModelToUi(mappersByModelName, modelAggregations);
};

export const getAllKustoConvertorsFromModelItemToUi = <S, T>(mappers: IModelUiMappingInfo[]): IItemContentConvertor<S, T>[] => [
    createItemContentConvertorByMappers<S, T>(mappers),
];

export const convertKustoItemListModelToUi = <S, T>(mappers: IModelUiMappingInfo[], serverItems: S[]): T[] => {
    return serverItems.map(serverItem => convertKustoItemByConvertorsModelToUi(mappers, serverItem, getAllKustoConvertorsFromModelItemToUi<S, T>(mappers)));
};

const convertKustoItemByConvertorsModelToUi = <S, T>(mappers: IModelUiMappingInfo[], serverItem: S, convertors: IItemContentConvertor<S, T>[]): T => {
    const finalConvertors: IItemContentConvertor<S, T>[] = convertors || getAllKustoConvertorsFromModelItemToUi<S, T>(mappers);
    return convertItem<S, T>(serverItem, finalConvertors);
};

export const convertGslFilterUiToModel = (mappersByUiName: { [key: string]: IModelUiMappingInfo }, filter: IGslFilter): IGslFilter => {
    return {
        ...filter,
        freeTextFilter: convertFreeTextFilterUiToModel(mappersByUiName, filter.freeTextFilter),
        timeFilter: convertTimeFilterUiToModel(mappersByUiName, filter.timeFilter),
        compoundFilter: convertCompoundFilterUiToModel(mappersByUiName, filter.compoundFilter),
    };
};

export const getKustoFieldMapperByUiName = (mappersByUiName: { [key: string]: IModelUiMappingInfo },uiName: string): IModelUiMappingInfo => {
    return mappersByUiName[uiName];
};

export const convertGslBinsListUiToModel = (mappersByUiName: { [key: string]: IModelUiMappingInfo },bins?: IGslBin[]): IGslBin[] | undefined => {
    return bins?.map(bin => ({
        ...bin,
        field: convertFieldNameUiToModel(mappersByUiName, bin.field),
    }));
};

export const convertGslCountUiToModel = (mappersByUiName: { [key: string]: IModelUiMappingInfo },count: IGslCount): IGslCount => {
    return {
        ...count,
        fields: count.fields ? convertKustoFieldNameListUiToModel(mappersByUiName, count.fields) : undefined,
        bins: convertGslBinsListUiToModel(mappersByUiName, count.bins),
        distinctCountField: count.distinctCountField ? convertFieldNameUiToModel(mappersByUiName, count.distinctCountField) : undefined,
    };
};
