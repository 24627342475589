import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';

export const DefaultTextCellRender: React.FC<ICellRendererParams> = (params) => {
    const value: any = params.value;
    if (!value) {
        return null;
    }
    const strValue = String(value);
    const options: IIconLinkCellProps = {
        isLink: false,
        value: strValue,
    };

    CellToClipboardHandler(params, strValue);
    return <IconLinkCellRenderer {...options} />;
};
