import { FC, useEffect, useState } from 'react';
import { SelectV2 } from 'common/design-system/components-v2';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionRulesetProps } from '../../helpers/exclusions.interfaces';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { IRuleset } from 'common/interface/ruleset';
import {
    createRulesetList,
    fetchRulesets,
    getRulesetDetails,
    handleFetchingError,
} from '../../helpers/exclusions.utils';

const ExclusionByRuleset: FC<IExclusionRulesetProps> = ({
    selectedOption,
    initialSelectedOption,
    onChange,
    onRulesetDetailsChange,
    isSaveClicked,
    formValidations,
    onClose,
    filterType,
    tooltip,
}) => {
    const { t } = useTranslation(I18nExclusion);
    const [rulesetList, setRulesetList] = useState<SelectOption[]>([]);
    const [rulesetsFromApi, setRulesetsFromApi] = useState<IRuleset[]>([]);

    useEffect(() => {
        const handleCreateRulesetList = async (rulesetsFromApi: IRuleset[]) => {
            const filterByType = () => rulesetsFromApi.filter((ruleset) => ruleset.cloudVendor === filterType);
            const ruleSetlist = filterType ? filterByType() : rulesetsFromApi;
            const updatedRulesetList = await createRulesetList(ruleSetlist);
            updatedRulesetList && setRulesetList(updatedRulesetList);
        };
        const handleFetchRulesets = async () => {
            try {
                const rulesetData = await fetchRulesets();
                rulesetData && setRulesetsFromApi(rulesetData);
                rulesetData && (await handleCreateRulesetList(rulesetData));
            } catch (error) {
                handleFetchingError(t, onClose);
            }
        };
        handleFetchRulesets();
    }, [filterType, onClose, t]);

    useEffect(() => {
        const handlePreselectedOption = () => {
            const matchingRuleset: IRuleset | undefined = rulesetsFromApi.find(
                (ruleset) => ruleset.id === initialSelectedOption,
            );
            if (matchingRuleset) {
                onChange(matchingRuleset.name);
                onRulesetDetailsChange && onRulesetDetailsChange(matchingRuleset);
            } else {
                onChange('');
                onRulesetDetailsChange && onRulesetDetailsChange(null);
            }
        };
        handlePreselectedOption();
    }, [rulesetList, initialSelectedOption, onChange, onRulesetDetailsChange, rulesetsFromApi]);

    const handleRulesetChange = (newValue: string) => {
        onChange(newValue);
        const rulesetDetailsFromUtil = getRulesetDetails(rulesetsFromApi, newValue);
        rulesetDetailsFromUtil && onRulesetDetailsChange && onRulesetDetailsChange(rulesetDetailsFromUtil);
    };

    return (
        <SelectV2
            label={t('MODAL.TOPICS.RULESET.TITLE')}
            labelProps={{ tooltip: tooltip }}
            onChange={(value: string) => handleRulesetChange(value)}
            isMulti={false}
            options={rulesetList}
            placeholder={t('MODAL.GENERAL.SELECT')}
            value={selectedOption}
            data-aid='exclusions-ruleset-select'
            fullWidth
            required
            isError={!!(isSaveClicked && formValidations)}
            helperText={isSaveClicked && formValidations ? formValidations[0]?.message : undefined}
        />
    );
};
export default ExclusionByRuleset;
