import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import {
    DrawerState,
    IDrawerAction,
    IDrawerContent,
    IDrawerIcon,
    IDrawerOptions,
    IDrawerWidgetUrlParams,
} from './Drawer.interface';
import { useLocation } from 'react-router-dom';
import { getDrawerUrlParams, isEqualUrlParams, removeDrawerUrlParam } from './Drawer.utils';
import { DrawerRegistry } from './DrawerRegistry';
import { Drawer } from './Drawer';
import { drawerManager } from './DrawerManager';
import { debugLog } from '../../../utils/debugUtils';

export interface IDrawerDetails {
    title?: string;
    leftHeaderTrailingContent?: React.ReactNode;
    icon?: IDrawerIcon;
    widget?: ReactElement;
    actions?: IDrawerAction[];
    rightHeaderContent?: React.ReactNode;
}

export const UrlDrawer: React.FC = () => {
    const [drawerDetails, setDrawerDetails] = useState<IDrawerDetails | undefined>();
    const [options, setOptions] = useState<IDrawerOptions>();
    const [drawerState, setDrawerState] = useState<DrawerState>(DrawerState.HIDDEN);
    const urlParamsRef = useRef<IDrawerWidgetUrlParams | undefined>();
    const location = useLocation();

    const onDrawerOpened = useCallback((contentProps: IDrawerDetails) => {
        setDrawerDetails(contentProps);
        setDrawerState(DrawerState.VISIBLE_READY);
    }, []);

    const onUrlDrawerClosed = useCallback(() => {
        setDrawerState(DrawerState.HIDDEN);
        setDrawerDetails(undefined);
        urlParamsRef.current = undefined;
        debugLog('CLOSED URL DRAWER ', drawerDetails?.title);
        drawerManager.hardReset();
    }, [drawerDetails]);

    const closeDrawer = useCallback(() => {
        setDrawerState(DrawerState.HIDDEN);
        removeDrawerUrlParam();
    }, []);

    const onClose = useCallback(() => {
        closeDrawer();
    }, [closeDrawer]);

    const handleLocationChanges = useCallback(
        (search: string | undefined) => {
            const params: IDrawerWidgetUrlParams | undefined = getDrawerUrlParams(search);
            if (isEqualUrlParams(params, urlParamsRef.current)) {
                return;
            }
            urlParamsRef.current = params;
            if (!params) {
                onUrlDrawerClosed();
                return;
            }

            if (!params.widgetId) {
                removeDrawerUrlParam();
                return;
            }

            setDrawerState(DrawerState.VISIBLE_LOADING);
            const contentProvider = DrawerRegistry.getContentProvider(params.widgetId);
            if (!contentProvider) {
                closeDrawer();
                return;
            }
            setOptions(contentProvider.options);
            contentProvider
                .getDrawerContent(params.widgetData, params.handlersIdMap)
                .then((content: IDrawerContent | undefined) => {
                    if (!content) {
                        setDrawerDetails(undefined);
                        setDrawerState(DrawerState.VISIBLE_ERROR);
                        return;
                    }
                    onDrawerOpened({
                        title: content.title,
                        leftHeaderTrailingContent: content.leftHeaderTrailingContent,
                        icon: content.icon,
                        widget: React.createElement(content.component, content.componentProps),
                        rightHeaderContent: content.rightHeaderContent,
                        actions: content.actions,
                    });
                })
                .catch((e) => {
                    console.error('Failed opening drawer. Error: ', e);
                    setDrawerDetails(undefined);
                    setDrawerState(DrawerState.VISIBLE_ERROR);
                });
        },
        [closeDrawer, onUrlDrawerClosed, onDrawerOpened],
    );

    useEffect(() => {
        handleLocationChanges(location.search);
    }, [location, handleLocationChanges]);

    if (drawerState === DrawerState.HIDDEN) {
        return null;
    }

    return (
        <Drawer
            options={options}
            content={drawerDetails?.widget}
            title={drawerDetails?.title}
            leftHeaderTrailingContent={drawerDetails?.leftHeaderTrailingContent}
            icon={drawerDetails?.icon}
            state={drawerState}
            rightHeaderContent={drawerDetails?.rightHeaderContent}
            actions={drawerDetails?.actions}
            onClose={onClose}
        />
    );
};
