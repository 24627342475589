import styled, { css, keyframes } from 'styled-components';
import { getPalette } from 'common/design-system/theme/colors/palette';
import { ProgressBarProps } from './ProgressBar.types';
import Stack from '../Stack';

const animation = (percentage?: number) => keyframes`
    0% {
        width: 0;
    }
    100% {
        width: ${percentage !== undefined ? `${percentage}%` : '100%'};
    }
`;

const Container = styled(Stack)`
    container: progress-bar-container / inline-size;
`;

const Wrapper = styled.div<{ bold?: boolean }>`
    display: flex;
    align-items: center;
    height: ${({ bold }) => (bold ? '8px' : '4px')};
    width: 100%;
    background-color: ${() => getPalette().slate.opaque[60]};
    border-radius: 4px;
    overflow: hidden;
`;

const PercentageBar = styled.div<ProgressBarProps>`
    height: 100%;
    background-color: ${({ theme }) =>
        `${theme.getForegroundColor({ context: 'info', strength: 'regular', saturation: true })}`};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: ${({ percentage, singleAnimation }) =>
        singleAnimation && percentage && percentage > 0 && percentage < 100
            ? `2px solid ${getPalette().slate.opaque[10]}`
            : 'none'};

    width: ${({ percentage, singleAnimation }) => (singleAnimation ? `${percentage}%` : '100%')};
    animation: ${({ percentage, singleAnimation }) =>
        singleAnimation
            ? css`
                  ${animation(percentage)} 2s ease-out forwards
              `
            : css`
                  ${animation()} 2s infinite linear
              `};
`;

const ProgressBarStyles = {
    Wrapper,
    PercentageBar,
    Container,
};

export default ProgressBarStyles;
