import { IServiceAccount } from './services';
import { CloudGuardRole, INewServiceAccountResponse } from '../module_interface/settings/integrations/Integrations';

export const SERVICE_ACCOUNTS_SERVICE_ID = 'service_accounts_service_id';

export interface IServiceAccountsService {
    getServiceAccounts: (useCache: boolean) => Promise<IServiceAccount[]>;
    addServiceAccount: (name?: string, roleIds?: number[], useCache?: boolean) => Promise<INewServiceAccountResponse>;
    deleteServiceAccounts: (roleId: string) => Promise<void>;
    editServiceAccounts: (payload: IAccountsServiceItem) => Promise<IAccountsServiceItem>;
    getServiceAccountRoles: (useCache?: boolean) => Promise<CloudGuardRole[]>;
}

export interface IAccountsServiceItem {
    name?: string;
    roleIds?: number[];
    dateCreated?: string;
    apiKeyId?: string;
    lastUsed?: string;
    id?: string;
    actions?: { text: string }[];
}
