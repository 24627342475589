import { OptionType } from 'common/design-system/components-v2/Select';
import { ExclusionModuleType, IExclusion, IFieldFilter, IRuleOption } from 'common/interface/exclusion';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { IRuleset } from 'common/interface/ruleset';
import { Vendors } from 'common/consts/vendors';

export interface RuleOption extends OptionType {
    ruleLogicHash: string;
}

export interface IExclusionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    exclusion: IExclusion | null;
    sourceType?: ExclusionModuleType;
    filterType?: string;
}

interface IExclusionBaseProps {
    onClose: () => void;
    tooltip?: string;
}

export interface IExclusionRulesetProps extends IExclusionBaseProps {
    formValidations?: { message: string; type: string }[];
    selectedOption?: string;
    initialSelectedOption?: number;
    isSaveClicked?: boolean;
    onChange: (value: string) => void;
    onRulesetDetailsChange: (value: IRuleset | null) => void;
    filterType?: string;
}

export interface IExclusionCommentProps {
    formValidations?: { message: string; type: string }[];
    isEnabled?: boolean;
    selectedOption?: string;
    onChange: (value: string) => void;
    isSaveClicked?: boolean;
    tooltip?: string;
}

export interface IExclusionOrganizationalUnitProps extends IExclusionBaseProps {
    isEnabled?: boolean;
    selectedRuleset?: string;
    selectedOption?: string[];
    initialSelectedOption?: string[] | [] | null | undefined;
    onChange: (value: string[]) => void;
}

export interface IExclusionEnvironmentProps extends IExclusionBaseProps {
    isEnabled?: boolean;
    selectedRuleset?: string;
    selectedOption?: string[];
    initialSelectedOption?: string[] | [] | null | undefined;
    selectedRulesetDetails?: IRuleset | CiemSelectOption | null;
    onChange: (value: string[]) => void;
}

export interface IExclusionRegionProps extends IExclusionBaseProps {
    isEnabled?: boolean;
    selectedRuleset?: string;
    selectedOption?: string[];
    initialSelectedOption?: string[] | null | undefined;
    selectedRulesetDetails?: IRuleset | CiemSelectOption | null;
    onChange: (value: string[]) => void;
}

export interface IExclusionDateProps {
    selectedOption?: IDateRange;
    onChange: (value: IDateRange | undefined) => void;
    tooltip?: string;
}

export interface IExclusionRuleProps extends IExclusionBaseProps {
    isEnabled?: boolean;
    selectedRuleset?: string;
    selectedOption?: string[];
    initialSelectedOption?: IRuleOption[] | null | undefined;
    selectedRulesetDetails?: IRuleset | null;
    onChange: (value: string[]) => void;
}

export interface ExclusionEntityInputProps {
    isEnabled?: boolean;
    selectedRuleset?: string;
    selectedOption?: string[];
    formValidations?: { message: string; type: string }[];
    onChange: (value: string | null) => void;
    filterFields?: IFieldFilter[] | null;
    helperText?: string;
    handleGsl?: (value: string) => string;
    isSelectionGroup?: boolean;
    limitSelection?: number;
    tooltip?: string;
}

export interface IExclusionAccountNumberProps {
    isEnabled?: boolean;
    selectedRuleset?: string;
    selectedOption?: string[];
    onChange: (value: string) => void;
    tooltip?: string;
}

export interface IExclusionTagsProps {
    isEnabled?: boolean;
    selectedOption?: string[] | ITags[];
    initialSelectedOption?: string[];
    onChange: (value: ITags[]) => void;
    tooltip?: string;
}

export interface IExclusionSeveritiesProps {
    isEnabled?: boolean;
    selectedRuleset?: string;
    selectedOption?: string[];
    initialSelectedOption?: string[] | null | undefined;
    onChange: (value: string[]) => void;
    tooltip?: string;
}

export interface IExclusionFindingTypeProps {
    initialSelectedOption?: string[] | null | undefined;
    onChange: (value: string[]) => void;
    tooltip?: string;
}

export interface ICloudAccount {
    creationDate: string;
    id: string;
    externalId: string;
    name: string;
    description?: string;
    platform: string;
    subplatform: string;
    organizationalUnitId: string;
    isLogicEnabled?: boolean;
    isLogicCloudTrailEnabled?: boolean;
    isLogicTrafficActivityOnboarded?: boolean;
    isLogicEventActivityOnboarded?: boolean;
    isCloudbotsOnboarded?: boolean;
    isLogicGuardDutyOnboarded?: boolean;
    logicUsedDataInBytes?: number;
    logicCloudTrailUsedDataInBytes?: number;
    logicFlowTimestamp?: string;
    logicTrailTimestamp?: string;
    credentialId: string;
}

export interface IExclusionValidation {
    ruleset?: string;
    comment?: string;
    requiredAdditionalInfo?: boolean;
}

export interface ITags {
    key: string;
    value: string;
}

export enum EntityTypesEnum {
    byName = 'byName',
    byID = 'byID',
}

export interface IExclusionConfig {
    selectedComment?: string;
    ouEnvironmentRadioButton?: string;
    selectedOrganizationalUnit?: string[];
    selectedEnvironment?: string[];
    selectedRegion?: string[];
    selectedDateRange?: IDateRange;
    selectedRule?: string[];
    selectedEntityName?: string;
    selectedAccountNumber?: string;
    selectedTags?: ITags[];
    selectedSeverities?: string[];
    exclusionId?: string;
    selectedRulesetDetails?: IRuleset | CiemSelectOption | null;
    entityLogic?: string;
    findingTypeLogic?: string[] | null;
}

export interface IExclusionSavePayload {
    cloudAccountType?: string;
    comment?: string;
    id?: string | null;
    logicExpressions: string[];
    rulesetId?: number;
    organizationalUnitIds?: string[] | null;
    regions?: string[] | null;
    rules?: { logicHash: string }[] | null;
    severities?: string[] | null;
    dateRange?: IDateRange | null;
    platform?: string;
    cloudAccountIds?: string[] | null;
}

export interface RuntimeFormValue {
    applyTo: 'thisPodGroupOnly' | 'allPodsInCluster';
}

export interface ServerlessFormValue {
    ruleScope: 'thisFunctionOnly' | 'allFunctionsInAccount' | 'thisFunctionAppOnly';
}

export interface ExceptionPayloadJson {
    Payloads: { pattern: string; callStackInfo: string }[];
    Name: string;
    PayloadsType: string;
    Target: string;
    PayloadsPatternType?: string;
    WorkloadExternalIds?: Array<string>;
}

export interface CiemSelectOption extends SelectOption {
    id: number;
    cloudVendor: Vendors;
}

export interface ExceptionPayloadsResponse {
    exceptionPayloads?: ExceptionPayloadJson;
    isIORule: boolean;
    isSignatureRule: boolean;
    isStringRule: boolean;
    isMalwareEventRule: boolean;
}

export interface IState {
    selectedRuleset: string | undefined;
    selectedRulesetDetails: IRuleset | null;
    selectedComment: string;
    ouEnvironmentRadioButton: string;
    selectedOrganizationalUnit: string[];
    selectedEnvironment: string[];
    selectedRegion: string[];
    selectedDateRange: IDateRange | undefined;
    selectedRule: string[];
    selectedAccountNumber: string;
    selectedTags: ITags[];
    selectedSeverities: string[];
    entityLogic: string | null;
    findingTypeLogic: string[] | null;
    exclusionId?: string;
}
