import styled from 'styled-components';

const PreTag = styled.div.attrs(() => ({
    className: 'p-6 flex-1',
}))`
    border-radius: 0.3em;
    border: 1px solid #00000017;
    background-color: #f5f5f5;
    color: black;
`;

export default PreTag;
