import { DrawerRegistry } from '../Drawer/DrawerRegistry';
import { DrawerActionsStyle, IDrawerAction, IDrawerContent, IDrawerHandlersIdMap } from '../Drawer/Drawer.interface';
import { IReactPageRoute } from '../../../interface/routing';
import { addMainMenuItem } from '../../../helpers/menu';
import { isAfPageDrawerExample } from '../../../utils/debugUtils';
import { createElement, lazy } from 'react';
import { DrawerBus } from '../Drawer/DrawerBus';
import { Icon } from 'common/design-system/components-v2';
import { IAsset } from '../../../assets/common.assets';
import { showDrawer } from '../Drawer/Drawer.utils';
import { sleep } from '../../../utils/functions';
import { DrawerExampleCounter, DrawerExampleTypeViewer, DrawerLargeExampleTextViewer } from './DrawerExampleComponents';
import { getAppRootRegistry } from 'common/interface/services';

const DRAWER_EXAMPLE_PAGE_URL = '/risk-management/drawer-example';
export const EXAMPLE_REVERSE_LIST_HANDLER_ID = 'EXAMPLE_REVERSE_LIST_HANDLER_ID';
export const EXAMPLE_RESTART_LIST_HANDLER_ID = 'EXAMPLE_RESTART_LIST_HANDLER_ID';
export const EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID = 'EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID';
export const CLOSE_DRAWER_LARGE_TEXT_VIEWER = 'CLOSE_DRAWER_LARGE_TEXT_VIEWER';

export interface IExampleTypeViewerData {
    assetType: IAsset;
    title: string;
    icon?: string;
}

export interface IExampleCounterViewerData {
    assetType: IAsset;
    count: number;
}

export enum ExampleListOperationEnum {
    REVERSE,
    RESTART,
}

export const DRAWER_EXAMPLE_TYPE_VIEWER = {
    key: 'DRAWER_EXAMPLE_TYPE_VIEWER_KEY',
    eventTypes: {
        delete: 'delete',
    },
};

export const DRAWER_LARGE_TEXT_VIEWER = {
    key: 'DRAWER_LARGE_TEXT_VIEWER_KEY',
    eventTypes: {
        reverse: 'reverse',
        restart: 'restart',
    },
};

export const DRAWER_EXAMPLE_COUNTER_KEY = 'DRAWER_EXAMPLE_COUNTER_KEY';

export const showAssetTypeNameDrawer = (assetType: IAsset) => {
    const widgetData: IExampleTypeViewerData = {
        assetType,
        title: assetType.typeByPlatform?.toUpperCase() || 'Unknown',
        icon: assetType?.icon,
    };
    showDrawer(DRAWER_EXAMPLE_TYPE_VIEWER.key, widgetData, {
        [DRAWER_EXAMPLE_TYPE_VIEWER.eventTypes.delete]: EXAMPLE_DELETE_TYPE_EVENT_HANDLER_ID,
    });
};

export const showAssetDisplayNameDrawer = (assetType: IAsset) => {
    showDrawer(DRAWER_LARGE_TEXT_VIEWER.key, assetType, {
        [DRAWER_LARGE_TEXT_VIEWER.eventTypes.restart]: EXAMPLE_RESTART_LIST_HANDLER_ID,
        [DRAWER_LARGE_TEXT_VIEWER.eventTypes.reverse]: EXAMPLE_REVERSE_LIST_HANDLER_ID,
    });
};

let counter = 0;
export const showCounterDrawer = (assetType: IAsset) => {
    counter++;
    const drawerData: IExampleCounterViewerData = {
        assetType,
        count: counter,
    };
    showDrawer(DRAWER_EXAMPLE_COUNTER_KEY, drawerData);
};

const actions: IDrawerAction[] = [
    {
        label: 'Edit',
        value: 'edit',
        labelProps: { leadingIconProps: { name: 'edit' } },
        onClick: () => alert('Edit'),
        tooltip: 'Edit Example',
    },
    {
        label: 'Upload',
        value: 'add',
        icon: <Icon size={16} name={'upload'} />,
        labelProps: {
            leadingIconProps: { name: 'upload' },
        },
        onClick: () => alert('Upload'),
    },
];

export const initializeDrawerExamples = () => {
    DrawerRegistry.addContentProvider({
        id: DRAWER_EXAMPLE_TYPE_VIEWER.key,
        getDrawerContent: async (
            typeData: IExampleTypeViewerData,
            handlersIdMap?: IDrawerHandlersIdMap,
        ): Promise<IDrawerContent> => {
            const onDelete = (typeByPlatform: string) => {
                DrawerBus.sendEvent(handlersIdMap![DRAWER_EXAMPLE_TYPE_VIEWER.eventTypes.delete], typeByPlatform);
            };

            if (typeData.assetType.type === 'BROKEN') {
                throw new Error('Broken type');
            }

            if (typeData.assetType.type === 'SLOW') {
                await sleep(5000);
            }

            const result = {
                title: typeData.title,
                leftHeaderTrailingContent: 'Left Header Trailing Content',
                icon: createElement(Icon, { size: 32, vendorNameOrPath: typeData.icon || 'web' }),
                component: DrawerExampleTypeViewer,
                actions: actions,
                componentProps: { assetType: typeData.assetType, onDelete },
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'lg',
            suppressCloseAll: true,
        },
    });

    DrawerRegistry.addContentProvider({
        id: DRAWER_LARGE_TEXT_VIEWER.key,
        getDrawerContent: (assetType: IAsset, handlersIdMap?: IDrawerHandlersIdMap): Promise<IDrawerContent> => {
            const onRestart = (date: Date) => {
                DrawerBus.sendEvent(handlersIdMap![DRAWER_LARGE_TEXT_VIEWER.eventTypes.restart], {
                    listOperation: ExampleListOperationEnum.RESTART,
                    date,
                });
            };

            const onReverse = (date: Date) => {
                DrawerBus.sendEvent(handlersIdMap![DRAWER_LARGE_TEXT_VIEWER.eventTypes.reverse], {
                    listOperation: ExampleListOperationEnum.REVERSE,
                    date,
                });
            };

            const result: IDrawerContent = {
                title: assetType.displayName,
                leftHeaderTrailingContent: 'Left Header Trailing Content',
                actions: actions,
                icon: 'web',
                component: DrawerLargeExampleTextViewer,
                componentProps: { assetType, onRestart, onReverse },
            };
            return Promise.resolve(result);
        },
        options: {
            hasHeaderLineSeparator: true,
            width: 'xxl',
            onCloseHandlerId: CLOSE_DRAWER_LARGE_TEXT_VIEWER,
            actionsStyle: DrawerActionsStyle.BUTTONS,
        },
    });

    DrawerRegistry.addContentProvider({
        id: DRAWER_EXAMPLE_COUNTER_KEY,
        getDrawerContent: (counterData: IExampleCounterViewerData): Promise<IDrawerContent> => {
            const result = {
                title: 'Drawer ' + counterData.count,
                component: DrawerExampleCounter,
                componentProps: { assetType: counterData.assetType, count: counterData.count },
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'md',
        },
    });

    addMainMenuItem({
        id: 'drawer-example',
        position: 0,
        icon: 'microscope',
        label: 'Drawer Examples',
        permission: () => isAfPageDrawerExample(),
        sections: [
            {
                id: 'default',
                position: 0,
                items: [
                    {
                        id: 'drawer example',
                        position: 0,
                        label: 'Drawer Example',
                        state: DRAWER_EXAMPLE_PAGE_URL,
                        permission: () => isAfPageDrawerExample(),
                    },
                ],
            },
        ],
    });

    const examplePage = (): IReactPageRoute => {
        return {
            condition: isAfPageDrawerExample(),
            component: lazy(() => import('./DrawerExamplePage')),
            path: DRAWER_EXAMPLE_PAGE_URL,
        };
    };

    getAppRootRegistry().addRoutes([{ id: 'DRAWER_EXAMPLE_PAGE', content: examplePage }]);
};
