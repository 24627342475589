import { FC, useRef } from 'react';
import { IFullErrorInfo } from '../../CustomForm.interface';
import { Stack, Typography } from '../../../../../design-system/components-v2';

export interface IErrorComponentProps {
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
}

export const ErrorComponent: FC<IErrorComponentProps> = ({ showErrors, errorInfo }) => {
    const ref = useRef<HTMLInputElement>(null);
    if (errorInfo) {
        errorInfo.componentRef = ref;
    }

    if (!errorInfo) {
        return null;
    }

    return (
        <Stack fullWidth ref={ref}>
            {showErrors && (
                <Typography variant={'bodyXs'} color={'alert'}>
                    {errorInfo.errorMessage}
                </Typography>
            )}
        </Stack>
    );
};
