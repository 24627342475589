import { IMultiSelectorItem, IMultiSelectorItemsMap, IMultiSelectorEntity } from './MultiSelector.interface';
import { MouseEvent } from 'react';

export const calcLabelFromEntity = (entity: IMultiSelectorEntity) => entity.label || entity.name || entity.id;

const getDescendantItems = (item: IMultiSelectorItem, allItemsMap: IMultiSelectorItemsMap): IMultiSelectorItem[] => {
    const directChilds: IMultiSelectorItem[] = (item.childIds || []).map((id) => allItemsMap[id]);
    const allDescendents: IMultiSelectorItem[] = [...directChilds];
    directChilds.forEach((child) => {
        allDescendents.push(...getDescendantItems(child, allItemsMap));
    });
    return allDescendents;
};

const getHighLevelItemOrDescendants = (
    item: IMultiSelectorItem,
    lowLevelMap: IMultiSelectorItemsMap,
    allItemsMap: IMultiSelectorItemsMap,
): IMultiSelectorItem[] => {
    if (lowLevelMap[item.id]) {
        return [item];
    }
    if (!item.childIds) {
        return [];
    }
    const highLevelItems: IMultiSelectorItem[] = [];
    item.childIds.forEach((id) => {
        const child: IMultiSelectorItem = allItemsMap[id];
        highLevelItems.push(...getHighLevelItemOrDescendants(child, lowLevelMap, allItemsMap));
    });
    return highLevelItems;
};

export const getHighLevelItems = (
    lowLevelItems: IMultiSelectorItem[],
    allItems: IMultiSelectorItem[],
): IMultiSelectorItem[] => {
    const rootItem: IMultiSelectorItem | undefined = allItems.find((item) => !item.parentId);
    if (!rootItem) {
        return [];
    }
    const itemsMap = selectorItemsToMap(allItems);
    const selectionMap = selectorItemsToMap(lowLevelItems);
    return getHighLevelItemOrDescendants(rootItem, selectionMap, itemsMap);
};

export const getLowLevelItemAndDescendants = (
    item: IMultiSelectorItem,
    highLevelMap: IMultiSelectorItemsMap,
    allItemsMap: IMultiSelectorItemsMap,
): IMultiSelectorItem[] => {
    if (highLevelMap[item.id]) {
        return [item, ...getDescendantItems(item, allItemsMap)];
    }
    if (!item.childIds) {
        return [];
    }
    const lowLevelItems: IMultiSelectorItem[] = [];
    item.childIds.forEach((id) => {
        const child: IMultiSelectorItem = allItemsMap[id];
        lowLevelItems.push(...getLowLevelItemAndDescendants(child, highLevelMap, allItemsMap));
    });
    return lowLevelItems;
};

export const getLowLevelItems = (
    highLevelItems: IMultiSelectorItem[],
    allItems: IMultiSelectorItem[],
): IMultiSelectorItem[] => {
    const rootItem: IMultiSelectorItem | undefined = allItems.find((item) => !item.parentId);
    if (!rootItem) {
        return [];
    }
    const itemsMap = selectorItemsToMap(allItems);
    const selectionMap = selectorItemsToMap(highLevelItems);
    return getLowLevelItemAndDescendants(rootItem, selectionMap, itemsMap);
};

const addCompletedParentsToSelection = (
    item: IMultiSelectorItem,
    selectionMap: IMultiSelectorItemsMap,
    allItemsMap: IMultiSelectorItemsMap,
) => {
    const parent: IMultiSelectorItem | undefined = item.parentId ? allItemsMap[item.parentId] : undefined;
    if (parent && !!parent.childIds) {
        const isParentCompleted = parent.childIds.every((id) => !!selectionMap[id]);
        if (isParentCompleted) {
            selectionMap[parent.id] = parent;
            addCompletedParentsToSelection(parent, selectionMap, allItemsMap);
        }
    }
};

export const calcTreeItemsOnSelection = (
    selectedItem: IMultiSelectorItem,
    prevSelectedItems: IMultiSelectorItem[],
    allItems: IMultiSelectorItem[],
): IMultiSelectorItem[] => {
    const itemsMap = selectorItemsToMap(allItems);
    const selectionMap = selectorItemsToMap(prevSelectedItems);
    selectionMap[selectedItem.id] = selectedItem;

    const descendents: IMultiSelectorItem[] = getDescendantItems(selectedItem, itemsMap);
    descendents.forEach((descendantItem) => (selectionMap[descendantItem.id] = descendantItem));
    addCompletedParentsToSelection(selectedItem, selectionMap, itemsMap);

    return Object.values(selectionMap);
};

export const removeParentsFromSelection = (
    item: IMultiSelectorItem,
    selectionMap: IMultiSelectorItemsMap,
    allItemsMap: IMultiSelectorItemsMap,
) => {
    const parent: IMultiSelectorItem | undefined = item.parentId ? allItemsMap[item.parentId] : undefined;
    if (parent) {
        delete selectionMap[parent.id];
        removeParentsFromSelection(parent, selectionMap, allItemsMap);
    }
};

export const calcTreeItemsOnDeselection = (
    deselectedItem: IMultiSelectorItem,
    prevSelectedItems: IMultiSelectorItem[],
    allItems: IMultiSelectorItem[],
): IMultiSelectorItem[] => {
    const itemsMap = selectorItemsToMap(allItems);
    const selectionMap = selectorItemsToMap(prevSelectedItems);
    delete selectionMap[deselectedItem.id];

    const descendents: IMultiSelectorItem[] = getDescendantItems(deselectedItem, itemsMap);
    descendents.forEach((descendantItem) => delete selectionMap[descendantItem.id]);
    removeParentsFromSelection(deselectedItem, selectionMap, itemsMap);

    return Object.values(selectionMap);
};

export const findItemsByIds = (items: IMultiSelectorItem[], ids?: string[]): IMultiSelectorItem[] => {
    if (!ids) {
        return [];
    }
    const itemsMap = selectorItemsToMap(items);
    return ids.filter((id) => itemsMap[id]).map((id) => itemsMap[id]);
};

export const selectorItemsToMap = (items: IMultiSelectorItem[]): IMultiSelectorItemsMap => {
    const itemsMap: IMultiSelectorItemsMap = {};
    items.forEach((item: IMultiSelectorItem) => (itemsMap[item.id] = item));
    return itemsMap;
};

const dataItemToItem = (entity: IMultiSelectorEntity, labelsPath: string[], parentId?: string): IMultiSelectorItem => {
    return {
        id: entity.id,
        label: calcLabelFromEntity(entity),
        iconName: entity.iconName,
        labelsPath,
        parentId,
    };
};

const collectTreeItems = (
    items: IMultiSelectorItem[],
    entity: IMultiSelectorEntity,
    parentItem?: IMultiSelectorItem,
) => {
    const parentPath = parentItem ? parentItem.labelsPath : [];
    const currItem: IMultiSelectorItem = dataItemToItem(
        entity,
        [...parentPath, calcLabelFromEntity(entity)],
        parentItem?.id,
    );
    items.push(currItem);
    if (parentItem) {
        parentItem.childIds = parentItem.childIds || [];
        parentItem.childIds.push(currItem.id);
    }
    if (entity.children) {
        entity.children.forEach((child: IMultiSelectorEntity) => {
            collectTreeItems(items, child, currItem);
        });
    }
};
export const getAllTreeItems = (root: IMultiSelectorEntity): IMultiSelectorItem[] => {
    const items: IMultiSelectorItem[] = [];
    collectTreeItems(items, root);
    return items;
};

export const getAllListItems = (dataItems: IMultiSelectorEntity[]): IMultiSelectorItem[] => {
    return dataItems.map((entity: IMultiSelectorEntity) => dataItemToItem(entity, [calcLabelFromEntity(entity)]));
};

export const getMatchingItems = (allItems: IMultiSelectorItem[], filterText?: string) => {
    const allItemsMap = selectorItemsToMap(allItems);
    const matchingIdsMap: IMultiSelectorItemsMap = {};
    if (filterText) {
        const lowerFilter = filterText.toLowerCase();
        allItems.forEach((item: IMultiSelectorItem) => {
            if (item.label.toLowerCase().includes(lowerFilter)) {
                matchingIdsMap[item.id] = item;
                let parentId = item.parentId;
                while (parentId && !matchingIdsMap[parentId]) {
                    const parentItem = allItemsMap[parentId];
                    matchingIdsMap[parentId] = parentItem;
                    parentId = parentItem.parentId;
                }
            }
        });
        return allItems.filter((item: IMultiSelectorItem) => matchingIdsMap[item.id]);
    } else {
        return [...allItems];
    }
};

export const ignoreEvent = (event: MouseEvent<any>) => {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
};

export const getSemiSelectedIdsMap = (
    allItems: IMultiSelectorItem[],
    selectedItemsMap: IMultiSelectorItemsMap,
): IMultiSelectorItemsMap => {
    const allItemsMap = selectorItemsToMap(allItems);
    const semiSelectedMap: IMultiSelectorItemsMap = {};
    Object.values(selectedItemsMap).forEach((item: IMultiSelectorItem) => {
        let currItem = item;
        while (currItem.parentId && !selectedItemsMap[currItem.parentId] && !semiSelectedMap[currItem.parentId]) {
            currItem = allItemsMap[currItem.parentId];
            semiSelectedMap[currItem.id] = currItem;
        }
    });
    return semiSelectedMap;
};
