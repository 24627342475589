import { AssetUrlParams } from '../../../assets/common.assets';
import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { CellToClipboardHandler } from '../../ProtectedAssets/ProtectedAssetsTable';
import EntityLink from '../../ProtectedAssets/Renderers/EntityLink';
import { generateAssetPageUrl } from '../../../module_interface/assets/utils';
import { getProtectedAssetsService } from '../../../module_interface/assets/ProtectedAssets';

const EntityNameCellRender: React.FC<
    ICellRendererParams & { componentName?: string; baseUrl: string; extraQueryParams?: any }
> = (params) => {
    const { entityType, entityName, entityExternalId, cloudAccountId, entityDome9Id } = params.data;
    const iAsset = getProtectedAssetsService().getProtectedAssetByTypeAndDome9Id(entityType, entityDome9Id);
    // fallback in case there is no entity name
    const entityNameOrId = entityName || entityExternalId;
    if (!iAsset) {
        return entityNameOrId;
    }

    const urlParams: AssetUrlParams = {
        asset: iAsset,
        entityId: entityExternalId,
        cloudAccountId,
        dome9Id: entityDome9Id,
        platform: iAsset.platform,
        entityType,
        typeNumber: iAsset?.typeNumber,
    };
    const assetUrl = iAsset?.getUrl(urlParams) ?? null;
    if (assetUrl === null) {
        return entityNameOrId;
    }

    const finalUrl = generateAssetPageUrl(iAsset, assetUrl, params.baseUrl, params.extraQueryParams);
    CellToClipboardHandler(params, String(entityNameOrId));
    return <EntityLink entityName={entityNameOrId} url={finalUrl} containerName={params.componentName} />;
};

export default EntityNameCellRender;
