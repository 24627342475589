import { FC } from 'react';
import { IConfiguration } from './Integrations';
import { SENTRA_INTEGRATION_ID } from '../../RiskManagement/integrations/sentraIntegration/SentraIntegration.consts';
import { CYERA_INTEGRATION_ID } from '../../RiskManagement/integrations/cyeraintegration/CyeraIntegration.consts';
import { FILTERS_KEYS } from '../../../components/FilterPanel/FilterPanel.consts';

export const integrationCategory = {
    COLLABORATIONS_AND_MESSAGING: 'CATEGORIES.COLLABORATIONS_AND_MESSAGING',
    TICKETING_SYSTEM: 'CATEGORIES.TICKETING_SYSTEM',
    PLATFORMS_CLOUD_PROVIDERS: 'CATEGORIES.PLATFORMS_CLOUD_PROVIDERS',
    DSPM: 'CATEGORIES.DSPM',
    SSO_CI: 'CATEGORIES.SSO_CI',
    SSO_CG: 'CATEGORIES.SSO_CG',
    CLOUD_SERVICES: 'CATEGORIES.CLOUD_SERVICES',
    THIRD_PARTY: 'CATEGORIES.THIRD_PARTY',
    EVENTS_AND_LOGGING: 'CATEGORIES.EVENTS_AND_LOGGING',
    SYSTEM_AUDIT: 'CATEGORIES.SYSTEM_AUDIT',
    VULNERABILITY_SECURITY_SCANNER: 'CATEGORIES.VULNERABILITY_SECURITY_SCANNER',
};

export const INTEGRATIONS_IDS = {
    SNS: 'SNS',
    SNS_AUDIT: 'snsAudit',
    TENABLE: 'tenable',
    TEAMS: 'Teams',
    GENERIC_WEBHOOK: 'Webhook',
    SPLUNK: 'Splunk',
    SERVICE_NOW: 'ServiceNow',
    SLACK: 'Slack',
    QRADAR: 'QRadar',
    SUMO_LOGIC: 'SumoLogic',
    PAGER_DUTY: 'PagerDuty',
    JIRA: 'Jira',
    GCP_SECURITY_COMMAND_CENTER: 'GcpSecurityCommandCenter',
    AZURE_DEFENDER_FOR_CLOUD: 'AzureDefender',
    AWS_SECURITY_HUB: 'AwsSecurityHub',
    EMAIL: 'Email',
    SENTRA: SENTRA_INTEGRATION_ID,
    CYERA: CYERA_INTEGRATION_ID,
    MICROSOFT_SENTINEL: 'MicrosoftSentinel',
};

export const SENTINEL_FILTER_PANEL_ID = 'SentinelFilter';

export const SENTINEL_FILTER_FIELDS = {
    ORGANIZATIONAL_UNIT: 'organizationalUnit',
    SEVERITY: 'severity',
    CLOUD_ACCOUNT_TYPE: 'cloudAccountType',
    CLOUD_ACCOUNT_ID: 'cloudAccountId_calc',
    SHOW_EXCLUDED: 'showExcluded',
    SHOW_ACKNOWLEDGED: 'showAcknowledged',
    ENTITY_TYPE: 'entityType',
    ACTION: 'action',
    ENTITY_ID: 'entityExternalId',
    REGION: 'region',
    CATEGORY: 'category',
    ASSIGNEE: 'ownerUserName',
    RULESET_NAME: 'bundleName',
    SOURCE: 'origin',
};

export const FILTER_PANEL_LIST_OBJECT = [
    { id: FILTERS_KEYS.ADD_FILTER },
    { id: SENTINEL_FILTER_FIELDS.ORGANIZATIONAL_UNIT },
    { id: SENTINEL_FILTER_FIELDS.SEVERITY },
    { id: SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_TYPE },
    { id: SENTINEL_FILTER_FIELDS.CLOUD_ACCOUNT_ID },
    { id: SENTINEL_FILTER_FIELDS.SHOW_EXCLUDED },
    { id: SENTINEL_FILTER_FIELDS.SHOW_ACKNOWLEDGED },
    { id: SENTINEL_FILTER_FIELDS.ENTITY_TYPE },
    { id: SENTINEL_FILTER_FIELDS.ACTION },
    { id: SENTINEL_FILTER_FIELDS.ENTITY_ID },
    { id: SENTINEL_FILTER_FIELDS.REGION },
    { id: SENTINEL_FILTER_FIELDS.CATEGORY },
    { id: SENTINEL_FILTER_FIELDS.ASSIGNEE },
    { id: SENTINEL_FILTER_FIELDS.RULESET_NAME },
    { id: SENTINEL_FILTER_FIELDS.SOURCE },
];

export interface IntegrationDataLoader {
    getData: () => Promise<IConfiguration[]>;
}

export interface IntegrationsDefinitionWrapper {
    id: string;
    content: IntegrationsDefinition;
    permission?: () => boolean;
    hidden?: (() => boolean) | boolean;
}

export interface IntegrationsDefinition {
    id?: string;
    title: string;
    icon: string;
    category: string;
    configurationCount?: number;
    hasIntegrationPayload?: boolean;
    hasOutputTypeSelection?: boolean;
    configurationUrl?: string;
    howToConfigureLink?: string;
    testFormatType?: ITestFormatTypeKey;
    onClick?: Function;
    configurationComponent?: FC<any>;
    onDelete?: (configurationID: string) => Promise<any>;
    onSave?: (name: string, configurationObj: any) => Promise<any>;
    SpecialMessage?: FC<{}>;
    hidden?: (() => boolean) | boolean;
}

export interface ICategoryIntegrationObject {
    [key: string]: IntegrationsDefinition[];
}

export interface IntegrationsSearchBarDefinition {
    type: 'text';
    placeholder: string;
    onChange?: any;
}

export interface SentinelInputType {
    [key: string]: string | string[];
}

export const INTEGRATION_DRAWER = {
    key: 'integrationDrawer',
    eventTypes: {
        configurationChanged: 'configurationChanged',
    },
};

export const CONFIGURATION_DRAWER = {
    key: 'configurationDrawer',
    eventTypes: {
        configurationChanged: 'configurationChanged',
    },
};

export const CONFIGURATION_CHANGED_HANDLER = 'CONFIGURATION_CHANGED_HANDLER';
export const CONFIGURATION_DRAWER_VIEWS = {
    LIST_VIEW: 'listView',
};

export const I18nIntegrations = 'integrations';

export const URL_ENDPOINT_PROTOCOL = 'https://';

export const AUTHENTICATION_TYPE = {
    BASIC: 'Basic',
    BasicAuth: 'BasicAuth',
    NO_AUTHENTICATION: 'No Authentication',
    QRADAR: 'QRadar',
};

export const TEST_FORMAT_TYPE = {
    JSON_WITH_FULL_ENTITY: 'JsonWithFullEntity',
    SPLUNK_BASIC: 'SplunkBasic',
    SERVICE_NOW: 'ServiceNow',
    QRADAR: 'QRadar',
    JSON_FIRST_LEVEL_ENTITY: 'JsonFirstLevelEntity',
    JIRA: 'Jira',
};

export type TEST_FORMAT_TYPE_KEY = keyof typeof TEST_FORMAT_TYPE;
export type ITestFormatTypeKey = (typeof TEST_FORMAT_TYPE)[TEST_FORMAT_TYPE_KEY];

export enum COMPONENT_TEXT_COLOR {
    SUCCESS = 'success',
    ALERT = 'alert',
}

export enum SUBMIT_STATUS_RESPONSE {
    SUCCESS = 'success',
    FAIL_TEST = 'failTest',
    FAIL_SAVE = 'failSave',
}

export enum INTEGRATION_PAGE_GROUP_TYPE {
    INTEGRATION = 'integration',
    CONFIGURATION = 'configuration',
}

export const CONFIGURATION_CHANGED_HANDLER_ID = 'CONFIGURATION_CHANGED_HANDLER';

export enum BUTTON_STATE {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export const HOW_TO_CONFIGURE_URLS = {
    SNS: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=integrate_sns',
    TENABLE:
        'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=tenable',
    TEAMS: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=teams',
    SPLUNK: 'https://support.checkpoint.com/results/sk/sk161974',
    SERVICE_NOW:
        'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=service_now',
    SLACK: 'https://support.checkpoint.com/results/sk/sk166433',
    QRADAR: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=qradar',
    SUMO_LOGIC:
        'https://supportcenter.checkpoint.com/supportcenter/portal?eventSubmit_doGoviewsolutiondetails=&solutionid=sk162613',
    PAGER_DUTY: 'https://support.checkpoint.com/results/sk/sk144573',
    JIRA: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=jira',
    GCP_SECURITY_COMMAND_CENTER: 'https://support.checkpoint.com/results/sk/sk154412',
    AZURE_DEFENDER_FOR_CLOUD:
        'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=defender',
    AWS_SECURITY_HUB:
        'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=SECURITY_HUB',
    SENTRA: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=sentra',
};

export enum INTEGRATION_PERMISSION_TYPES {
    IS_READ_WRITE = 'isReadWrite',
    IS_READ_ONLY = 'isReadOnly',
    IS_DISABLED = 'isDisabled',
}
