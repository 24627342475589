import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

export const Wrapper = styled(Stack)`
    max-height: 300px;
`;

const SavedFilterListStyled = {
    Wrapper,
};

export default SavedFilterListStyled;
