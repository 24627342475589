import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

export enum FilterConditionOperator {
    Equals = 'eq',
    NotEquals = '!eq',
    GT = 'gt',
    GTE = 'gte',
    LT = 'lt',
    LTE = 'lte',
    Has = 'has',
    NotHas = '!has',
    Contains = 'contains',
    NotContains = '!contains',
    In = 'in',
    NotIn = '!in',
    Between = 'between',
    NotBetween = '!between',
    HasAny = 'has_any',
    NotHasAny = '!has_any',
    Unknown = 'unknown',
}

export type IFilterConditionValue = string | number | boolean;

export interface IFilterCondition {
    name: string;
    values: IFilterConditionValue[];
    operator: FilterConditionOperator;
}

export interface IFilterConditionTester {
    testFilter: (data: any, selectedFilterValues: IFilterConditionValue[]) => boolean;
}

export interface IFilterTimeRange {
    from: Date;
    to: Date;
}

export const isEmptyCondition = (condition: IFilterCondition): boolean => {
    if (!condition.values) {
        return true;
    }
    return condition.values.every((value) => !value);
};

const getTimeRange = (condValues: any[]): IFilterTimeRange | undefined => {
    if (condValues.length < 1 || condValues.length > 2) {
        return undefined;
    }

    if (condValues.length === 1) {
        const timeGap = Number(condValues[0]);
        const to: Date = new Date();
        const from: Date = new Date(to.getTime() - timeGap);
        return {
            from,
            to,
        };
    }

    return {
        from: new Date(condValues[0]),
        to: new Date(condValues[1]),
    };
};

const defaultTesterNotSupportedYet: IFilterConditionTester = {
    testFilter: () => {
        return false;
    },
};

export const FilterConditionTestersMap: { [key in FilterConditionOperator]: IFilterConditionTester } = {
    [FilterConditionOperator.Contains]: {
        testFilter: (propData: any, condValues: IFilterConditionValue[]) => {
            if (!condValues) {
                return true;
            }
            const strData = String(propData);
            return condValues.some((val) => strData.includes(String(val).trim()));
        },
    },

    [FilterConditionOperator.NotContains]: {
        testFilter: (propData: any, condValues: IFilterConditionValue[]) => {
            if (!condValues) {
                return true;
            }
            return !FilterConditionTestersMap[FilterConditionOperator.Contains].testFilter(propData, condValues);
        },
    },

    [FilterConditionOperator.In]: {
        testFilter: (propData: any, condValues: IFilterConditionValue[]) => {
            if (!condValues) {
                return true;
            }
            const dataList = valueToArray(propData);
            return dataList.some((singleData) => condValues.includes(singleData));
        },
    },

    [FilterConditionOperator.NotIn]: {
        testFilter: (propData: any, condValues: IFilterConditionValue[]) => {
            if (!condValues) {
                return true;
            }
            return !FilterConditionTestersMap[FilterConditionOperator.In].testFilter(propData, condValues);
        },
    },

    [FilterConditionOperator.Between]: {
        testFilter: (propData: any, condValues: IFilterConditionValue[]) => {
            if (!condValues) {
                return true;
            }
            const timeRange: IFilterTimeRange | undefined = getTimeRange(condValues);
            if (!timeRange) {
                return false;
            }

            const dataDate: dayjs.Dayjs = dayjs(propData);
            if (timeRange.from) {
                const fromDayjs = dayjs(timeRange.from);
                if (dataDate !== fromDayjs && !dataDate.isAfter(fromDayjs)) {
                    return false;
                }
            }
            if (timeRange.to) {
                const toDayjs = dayjs(timeRange.to);
                if (dataDate !== toDayjs && !dataDate.isBefore(toDayjs)) {
                    return false;
                }
            }
            return true;
        },
    },

    [FilterConditionOperator.NotBetween]: {
        testFilter: (propData: any, condValues: IFilterConditionValue[]) => {
            if (!condValues) {
                return true;
            }
            return !FilterConditionTestersMap[FilterConditionOperator.Between].testFilter(propData, condValues);
        },
    },
    [FilterConditionOperator.Equals]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.NotEquals]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.GT]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.GTE]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.LT]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.LTE]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.Has]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.NotHas]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.HasAny]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.NotHasAny]: defaultTesterNotSupportedYet,
    [FilterConditionOperator.Unknown]: defaultTesterNotSupportedYet,
};

export interface IFilterConditionsContainer {
    conditions: IFilterCondition[];
}

const valueToArray = (value: any): any[] => {
    if (isNil(value)) {
        return [];
    }
    return isArray(value) ? (value as any[]) : [value];
};
