import styled from 'styled-components';

const Wrapper = styled.div`
    margin: 40px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
    height: 40px;
    display: flex;
    background: #2e3f58;
    padding: 0 10px 0 30px;
    position: relative;
    z-index: 100;

    .title {
        text-transform: uppercase;
        font-size: 15px;
        color: #fff;
        flex-grow: 1;
        line-height: 40px;
    }
    .close-wizard {
        padding: 10px;
        cursor: pointer;
    }
`;

const Body = styled.div`
    display: flex;
    height: calc(100vh - 250px);

    .container {
        flex: 1;
        padding: 30px;
        overflow: auto;
        ::slotted(*) {
            height: 100%;
        }
    }

    .sidebar {
        background-color: #2e3f58;
        width: 188px;
        text-transform: uppercase;
        padding: 30px 20px 30px 30px;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .step-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            max-height: 95px;

            .step {
                max-height: 30px;
                color: #aab4c4;
                display: flex;
                align-items: center;

                .step-number {
                    display: flex;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 2px solid;
                    border-color: #aab4c4;
                    flex-shrink: 0;
                    font-size: 15px;
                    align-items: center;
                    justify-content: center;

                    &.done {
                        background-color: #fff;
                    }
                }

                .step-name {
                    font-size: 11px;
                    padding-left: 10px;
                }

                &.active,
                &.used {
                    .step-name {
                        color: #fff;
                    }
                    .step-number {
                        background-color: #fff;
                        color: #2e3f58;
                        fill: #2e3f58;
                        border-color: #fff;
                    }
                }
            }

            .step-line {
                border: 1px solid;
                border-color: #aab4c4;
                width: 1px;
                min-height: 10px;
                max-height: 60px;
                margin: 3px 0 3px 14px;
                flex: 1;
                &.active {
                    border-color: #fff;
                }
            }
        }
    }
`;

const Footer = styled.div`
    display: flex;
    background-color: #f7f7f7;
    width: 100%;

    .footer-sidebar {
        width: 188px;
        background-color: #2e3f58;
    }
    .footer-actions {
        flex: 1;
        display: flex;
        height: 50px;
        width: 100%;
        padding: 10px;
        border-top: 1px solid #d2d5d9;
        justify-content: flex-end;
        gap: 10px;
    }
`;

export default {
    Wrapper,
    Header,
    Body,
    Footer,
};
