import React from 'react';
import { getGridLines, getRightMostNodePosition } from '../Services/GridUtils';
import { GridOptions } from '../Models/GridOptions';

const GraphGrid: React.FC<{ gridOptions: GridOptions | undefined; gridOutlines: DOMRect[] }> = (props) => {
    const { gridOptions, gridOutlines } = props;
    const gridCustomBoundariesMargin = 5;

    if (!gridOutlines.length || !gridOptions) return;

    const gridLines = getGridLines(gridOutlines, gridOptions.graphContainerClass, gridOptions.gridLabels);
    if (!gridLines) return;

    // this is the position of the right most element of the graph, in order to know where the graph ends
    const graphEndX = getRightMostNodePosition();
    if (!graphEndX) return;

    const { gridLinePositions, y1, y2, containerLeftMargin } = gridLines;

    return (
        <>
            {/*Vertical lines separating columns*/}
            <svg xmlns='http://www.w3.org/2000/svg' height='5' style={{ overflow: 'visible' }} className={'grid-lines'}>
                {gridLinePositions.map((x, index) => (
                    <line key={`line-${index}`} x1={x} y1={y1} x2={x} y2={y2} stroke='#ddd' strokeWidth='1' />
                ))}
            </svg>
            {/*Horizontal line under the labels*/}
            <svg height='5' style={{ overflow: 'visible' }}>
                <line
                    x1={
                        gridOutlines[0].x -
                        containerLeftMargin -
                        gridOptions.gridLabelsMargin -
                        gridCustomBoundariesMargin
                    }
                    y1={y1 + gridOptions.gridLabelsMargin}
                    x2={
                        graphEndX -
                        containerLeftMargin +
                        gridOptions.nodeSize +
                        gridOptions.gridLabelsMargin +
                        gridCustomBoundariesMargin
                    }
                    y2={y1 + gridOptions.gridLabelsMargin}
                    stroke='#ddd'
                    strokeWidth='1'
                />
            </svg>
            {/*Labels text for each column*/}
            <svg xmlns='http://www.w3.org/2000/svg' height='5' style={{ overflow: 'visible' }}>
                {gridOptions.gridLabels.map((label, index) => (
                    <text
                        key={`label-${index}`}
                        className={`font-medium text-xl grid-label-${label}`}
                        x={gridOutlines[index].x - containerLeftMargin + gridOutlines[index].width / 2}
                        y={y1}
                        fill={'#bbb'}
                        textAnchor='middle'
                    >
                        {label}
                    </text>
                ))}
            </svg>
        </>
    );
};

export default GraphGrid;
