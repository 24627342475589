import { FilterConditionTestersMap, IFilterCondition } from './FilterCondition';
import { isCompoundFilterCondition } from './FilterTree.convertors';

export type IGenericItem = { [key: string]: any };

export enum CompoundFilterLogicalOperator {
    AND = 'and',
    OR = 'or',
}

export interface ICompoundFilterNode {
    logicalOperator: CompoundFilterLogicalOperator;
    operands: ICompoundFilterItem[];
}

export type ICompoundFilterItem = ICompoundFilterNode | IFilterCondition;

export interface ICompoundFilter {
    root: ICompoundFilterNode;
}

export type ICompoundFilterConditionTester = <T extends IGenericItem = IGenericItem>(
    dataItem: T,
    condition: IFilterCondition,
) => boolean;

export const getLogicalFilterFlatConditions = (item: ICompoundFilterItem): IFilterCondition[] => {
    const conditions: IFilterCondition[] = [];
    if (isCompoundFilterCondition(item)) {
        conditions.push(item as IFilterCondition);
    } else {
        const node = item as ICompoundFilterNode;
        node.operands.forEach((anItem) => {
            conditions.push(...getLogicalFilterFlatConditions(anItem));
        });
    }
    return conditions;
};

export const convertToServerFilter = (compoundFilter?: ICompoundFilter): ICompoundFilterNode | undefined => {
    if (!compoundFilter) {
        return undefined;
    }

    if (compoundFilter.root.operands.length > 0) {
        return compoundFilter.root;
    }

    return undefined;
};

export const isMatchingCondition = <T extends IGenericItem = IGenericItem>(
    conditionTester: ICompoundFilterConditionTester,
    condition: IFilterCondition,
    dataItem: T,
): boolean => {
    return conditionTester(dataItem, condition);
};

export const isMatchingItem = <T extends IGenericItem = IGenericItem>(
    conditionTester: ICompoundFilterConditionTester,
    item: ICompoundFilterItem,
    dataItem: T,
): boolean => {
    if ((item as ICompoundFilterNode).logicalOperator) {
        return isMatchingNode(conditionTester, item as ICompoundFilterNode, dataItem);
    }

    return isMatchingCondition(conditionTester, item as IFilterCondition, dataItem);
};

export const isMatchingNode = <T extends IGenericItem = IGenericItem>(
    conditionTester: ICompoundFilterConditionTester,
    node: ICompoundFilterNode,
    dataItem: T,
): boolean => {
    if (node.logicalOperator === CompoundFilterLogicalOperator.AND) {
        return node.operands.every((operand) => isMatchingItem(conditionTester, operand, dataItem));
    }
    return node.operands.some((operand) => isMatchingItem(conditionTester, operand, dataItem));
};

export const applyCompoundFilter = <T extends IGenericItem = IGenericItem>(
    conditionTester: ICompoundFilterConditionTester,
    compoundFilter?: ICompoundFilter,
    dataItems?: T[],
): T[] => {
    if (!dataItems) {
        return [];
    }

    if (!compoundFilter) {
        return dataItems;
    }

    return dataItems.filter((dataItem) => isMatchingNode(conditionTester, compoundFilter.root, dataItem));
};

export const simpleCompoundFilterTester = <T extends IGenericItem = IGenericItem>(
    dataItem: T,
    condition: IFilterCondition,
) => {
    if (condition.values.length === 0) {
        return false;
    }

    const propData = dataItem[condition.name];
    return FilterConditionTestersMap[condition.operator].testFilter(propData, condition.values);
};
