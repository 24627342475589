/* eslint-disable react/prop-types */
import { Separator } from '../../Separator';
import Stack from '../../Stack';
import { ActionContainer } from './ModalFooter.styles';
import { ModalFooterProps } from './ModalFooter.types';

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
    const { children, hasSeparator = true } = props;
    return (
        <>
            {hasSeparator && <Separator />}
            <Stack alignItems='flex-end' spacing={3} padding={4} fullWidth>
                <ActionContainer justifyContent='center' alignItems='flex-end' direction='row' spacing={3} fullWidth>
                    {children}
                </ActionContainer>
            </Stack>
        </>
    );
};

export default ModalFooter;
