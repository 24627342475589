import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';

export const CHECK_POINT_URLS = {
    HOME: {
        CG: 'https://www.checkpoint.com/',
        CI: 'https://www.checkpoint.com/',
    },
    HELP_CENTER: {
        CG: 'https://supportcenter.checkpoint.com/supportcenter/portal',
        CI: 'https://supportcenter.checkpoint.com/supportcenter/portal',
    },
    TERMS: {
        CG: 'https://www.checkpoint.com/support-services/software-license-agreement-limited-hardware-warranty/',
        CI: 'https://www.checkpoint.com/support-services/software-license-agreement-limited-hardware-warranty/',
    },
    API_V2: {
        CG: 'https://docs.cgn.portal.checkpoint.com/reference/introduction',
        CI: 'https://docs.cgn.portal.checkpoint.com/reference/introduction',
    },
    ERM_CONTROLS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=erm_controls',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=erm_controls',
    },
    AWS_ADD_CLOUD_ACCOUNT_TROUBLESHOOTING: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=aws_add_cloud_account_troubleshooting',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=AWS_ADD_CLOUD_ACCOUNT_TROUBLESHOOTING',
    },
    AZURE_KEY_VAULT_PERMISSION_MANUAL: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=azure_key_vault_permission_manual',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=AZURE_KEY_VAULT_PERMISSION_MANUAL',
    },
    COMPLIANCE_ENGINE_GSL: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=compliance_engine_gsl',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=COMPLIANCE_ENGINE_GSL',
    },
    COMPLIANCE_ENGINE_CFT_ASSESSMENT: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=compliance_engine_cft_assessment',
        CI: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=compliance_engine_cft_assessment',
    },
    COMPLIANCE_EXCLUSION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=compliance_exclusion',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=COMPLIANCE_EXCLUSION',
    },
    CONTINUOUS_COMPLIANCE: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=continuous_compliance',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=CONTINUOUS_COMPLIANCE_SNS_INTEGRATION',
    },
    CONTINUOUS_COMPLIANCE_INTEGRATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=continuous_compliance_integration',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=CONTINUOUS_COMPLIANCE_INTEGRATION',
    },
    CONTINUOUS_COMPLIANCE_SNS_INTEGRATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Integrations/AWS-SNS-Integration.htm?cshid=integrate_sns',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=integrate_sns',
    },
    CIRCUIT_BREAKER: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=misconfigured_notification',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=misconfigured_notification',
    },
    CLOUD_BOTS: {
        CG: 'https://cloudbots.dome9.com',
        CI: 'https://cloudbots.dome9.com',
    },
    CONVERT_CONNECTION_FROM_USER_TO_ROLE: {
        CG: 'https://supportcenter.checkpoint.com/supportcenter/portal?eventSubmit_doGoviewsolutiondetails=&solutionid=sk159912&partition=General&product=CloudGuard',
        CI: 'https://supportcenter.checkpoint.com/supportcenter/portal?eventSubmit_doGoviewsolutiondetails=&solutionid=sk159912&partition=General&product=CloudGuard',
    },
    DYNAMIC_ACCESS_LEASING: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=dynamic_access_leasing',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=DYNAMIC_ACCESS_LEASING',
    },
    GSL_KNOWLEDGE_BASE: {
        CG: 'https://gsl.dome9.com',
        CI: 'https://gsl.dome9.com',
    },
    HELP_CENTER_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=help_center_documentation',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm',
    },
    RELEASE_NOTES: {
        CG: 'https://dome9-security.atlassian.net/wiki/spaces/RN/pages/784400389/Release+Notes',
        CI: 'https://dome9-security.atlassian.net/wiki/spaces/RN/pages/784400389/Release+Notes',
    },
    SNS_EVENTS_INTEGRATION_HOW_TO: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=integrate_sns',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=integrate_sns',
    },
    SSO: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=sso',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=SSO',
    },
    STATUS: {
        CG: 'https://status.dome9.com',
        CI: 'https://status.dome9.com',
    },
    TERRAFORM_PROVIDER: {
        CG: 'https://registry.terraform.io/providers/dome9/dome9/latest/docs',
        CI: 'https://registry.terraform.io/providers/dome9/dome9/latest/docs',
    },
    VPC_FLOW_LOGS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=vpc_flow_logs',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=VPC_FLOW_LOGS',
    },
    FINDING_ALERTS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=finding_alerts',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=FINDING_ALERTS',
    },
    FINDING_ALERTS_QA: {
        CG: 'https://supportcenter.checkpoint.com/supportcenter/portal?eventSubmit_doGoviewsolutiondetails=&solutionid=sk144593',
        CI: 'https://supportcenter.checkpoint.com/supportcenter/portal?eventSubmit_doGoviewsolutiondetails=&solutionid=sk144593',
    },
    CONFIGURE_ALERTS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=configure_alerts',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=CONFIGURE_ALERTS',
    },
    IAM_SAFETY: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=iam_safety',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=IAM_SAFETY',
    },
    SECURITY_HUB: {
        CG: 'https://supportcenter.checkpoint.com/supportcenter/portal?eventSubmit_doGoviewsolutiondetails=&solutionid=sk144592&partition=General&product=CloudGuard',
        CI: 'https://supportcenter.checkpoint.com/supportcenter/portal?eventSubmit_doGoviewsolutiondetails=&solutionid=sk144592&partition=General&product=CloudGuard',
    },
    ORGANIZATIONAL_UNIT: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=organizational_unit',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=ORGANIZATIONAL_UNIT',
    },
    REMEDIATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=remediation',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=REMEDIATION',
    },
    USERS_ROLES_PERMISSIONS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=users_roles_permissions',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=USERS_ROLES_PERMISSIONS',
    },
    MFA: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Settings/Security-Features.htm?cshid=MFA#MFA',
        CI: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Settings/Security-Features.htm?cshid=MFA#MFA',
    },
    WHAT_IS_BILLABLE: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=what_is_billable',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=WHAT_IS_BILLABLE',
    },
    K8S_REGISTRY_ONBOARDING: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Getting-Started/OnboardRegistry.htm',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Getting-Started/OnboardRegistry.htm',
    },
    K8S_REGISTRY_ONBOARDING_NEXUS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_nexus',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_nexus',
    },
    ONBOARDING_AWS_ELASTIC_CONTAINER_REGISTRY_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Assets/Onboard-R-ECR.htm',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Assets/Onboard-R-ECR.htm',
    },
    APP_CLIENT_ID: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=acr_auth',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Assets/Onboard-R-ACR.htm?cshid=acr_auth#ACR_auth',
    },
    JFROG_ARTIFACTORY_PULL_SECRET_NAME: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_jfrog',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Assets/Container_Registry/Onboard-R-Jfrog.htm',
    },
    HARBOR_ARTIFACTORY_PULL_SECRET_NAME: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_harbor',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Assets/Container_Registry/Onboard-R-Harbor.htm',
    },
    POSTURE_MANAGEMENT: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=Posture_Management',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=Posture_Management',
    },
    ADMISSION_CONTROL: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=admission_control',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=admission_control',
    },
    THREAT_INTELLIGNCE: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=k8s_intl',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=k8s_intl',
    },
    IMAGE_ASSURANCE: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=image_assurance',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=image_assurance',
    },
    KUBERNETES_RUNTIME_PROTECTION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=k8s_runtime',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=k8s_runtime',
    },
    CONTAINER_REGISTRY_SCANNING: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_scanning',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_scanning',
    },
    ACR_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_acr',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_acr',
    },
    ECR_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_ecr',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_ecr',
    },
    GCR_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_gcr',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_gcr',
    },
    GAR_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_gar',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_gar',
    },
    ARTIFACTORY_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_jfrog',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_jfrog',
    },
    HARBOR_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_harbor',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_harbor',
    },
    SONATYPE_NEXUS_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_nexus',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_nexus',
    },
    GITHUB_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_github',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_github',
    },
    AGENTLESS_WORKLOAD_POSTURE: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=awp',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=awp',
    },
    DOUBLE_ONBOARDING_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=1002',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=1002',
    },
    AWP_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=awp',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=awp',
    },
    SCAN_STATUS_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=image_status',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=image_status',
    },
    IMAGE_SCAN_ON_DEMAND: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=image_status',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Workload-Protection/Image_Assurance/Image-Assurance-Images.htm?cshid=on_demand#On-demand',
    },
    SHIFTLEFT_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/Scan_Engine_SL-v2/02-Scan_Engine_Configuration-v2.htm#Create_envir',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=image_scan_v2',
    },
    AWS_CUSTOM_ROLE: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=aws_custom_role',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=aws_custom_role',
    },
    ERRORS_TROUBLESHOOTING_IN_IMAGESCAN_AGENT: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Workload-Protection/Image_Assurance/Image-Assurance-Agent-Status.htm?tocpath=Workload%20Protection%20%7CVulnerabilities%20%7C_____5',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Workload-Protection/Image_Assurance/Image-Assurance-Agent-Status.htm?cshid=agent_status',
    },
    CLUSTER_VERSION_STATUS_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=version_deprecate',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=version_deprecate',
    },
    SBOM_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=sbom',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=sbom',
    },
    AZURE_SINGLE_ONBOARDING_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Getting-Started/Onboarding-Azure-single-account.htm?tocpath=Assets%20%7CEnvironments%20%7COnboarding%20Azure%20Subscriptions%20%7C_____1',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Getting-Started/Onboarding-Azure-single-account.htm?tocpath=Assets%20%7CEnvironments%20%7COnboarding%20Azure%20Subscriptions%20%7C_____1',
    },
    AZURE_ORG_ONBOARDING_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Assets/Azure/Onboarding-Azure-Organizations.htm?tocpath=Assets%20%7CEnvironments%20%7COnboarding%20Azure%20Subscriptions%20%7C_____2',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Assets/Azure/Onboarding-Azure-Organizations.htm?tocpath=Assets%20%7CEnvironments%20%7COnboarding%20Azure%20Subscriptions%20%7C_____2',
    },
    GCP_SINGLE_ONBOARDING_DOCS: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Getting-Started/Onboarding-single-GCP-project.htm?tocpath=Getting%20Started%20with%20CloudGuard%20%7COnboarding%20Cloud%20Environments%20%7C_____3',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Getting-Started/Onboarding-single-GCP-project.htm?tocpath=Getting%20Started%20with%20CloudGuard%20%7COnboarding%20Cloud%20Environments%20%7C_____3',
    },
    GCP_ORG_ONBOARDING_DOCS: {
        CG: '',
        CI: '',
    },
    QUAY_PULL_SECRET_NAME: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=registry_quay',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=registry_quay',
    },
    DOCKER_HUB_PULL_SECRET_NAME: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Assets/Container_Registry/Onboard-R-Docker.htm',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Assets/Container_Registry/Onboard-R-Docker.htm',
    },
    QUAY_CONTAINER_REGISTRY_DOCUMENTATION: {
        CG: 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Assets/Container_Registry/Onboard-R-Quay.htm?tocpath=Assets',
        CI: 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Assets/Container_Registry/Onboard-R-Quay.htm?Highlight=quay',
    },
};
export const getCheckpointUrl = (urlKey: keyof typeof CHECK_POINT_URLS) => {
    return getIsCloudInfra() ? CHECK_POINT_URLS[urlKey]?.CI : CHECK_POINT_URLS[urlKey]?.CG;
};
