import React, { ReactNode, useMemo } from 'react';
import { ICustomTreeRendererProps } from '../CustomTree.interface';
import { CustomTreeIndentBox } from './CustomTreeIndentBox';
import { Stack } from '../../../../design-system/components-v2';

export const CustomTreeIndentArea: React.FC<ICustomTreeRendererProps> = (props) => {
    const { depth, compProps } = props;
    const { node } = compProps;
    const allCells: ReactNode[] = useMemo(() => {
        const cells: ReactNode[] = [];
        for (let i = 0; i < depth; i++) {
            cells.push(<CustomTreeIndentBox key={`${node.id}-cell-${i}`} renderProps={props} indentLevel={i}/>);
        }
        return cells;
    }, [depth, node.id, props]);

    return (
        <Stack direction={'row'} alignItems={'center'} fullHeight>{allCells}</Stack>
    );
};