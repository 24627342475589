import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { LoadingState } from 'common/interface/general';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { CUSTOM_FORM_DRAWER_EVENT_HANDLER_ID } from './CustomForm.consts';
import { FormStyled } from './CustomForm.styled';
import { Button, Spinner, Stack, Typography } from '../../../design-system/components-v2';
import { getFirstErrorRef, getFullErrorsInfo } from './CustomForm.validations';
import {
    cloneItemsMap,
    createInitialItemsMap,
    fixSelectedRadioOption,
    getActiveItemsMap,
    getActiveValuesJson,
} from './CustomForm.values';
import { IComponentRef } from '../../utils/ermComponents.interface';
import { scrollToComponent } from '../../utils/ermComponents.utils';
import FullSizeSpinner from '../FullSize/FullSizeSpinner';
import { createItemComponents } from './CustomForm.components';
import {
    IFieldItem,
    IItem,
    IItemsMap,
    IRadioItem,
    IItemChanges,
    IBaseItemState,
    IFullCustomFormProps,
    IFullFormDetails,
    IRadioSelectionInfo,
    IFormParams,
    IFullErrorsInfo,
} from './CustomForm.interface';
import { globalEventBus } from '../../utils/EventBus/eventBus';
import { useCloseDrawerHandler } from '../../../components/DrawerInfra/Drawer/UseCloseDrawerHandler';
import useLoadingStateWithDebounce from '../../hooks/useLoadingStateWithDebounce';

export const CustomForm: FC<IFullCustomFormProps> = ({ formProps, data, onClose }) => {
    const { t } = useTranslation();
    const paramsRef = useRef<IFormParams | undefined>();
    const [itemsMap, setItemsMap] = useState<IItemsMap>({});
    const [fullErrorsInfo, setFullErrorsInfo] = useState<IFullErrorsInfo>({ errors: [], errorsMap: {} });
    const initialValuesJsonRef = useRef<string>('');
    const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [pendingFieldChanges, setPendingFieldChanges] = useState<IFieldItem>();
    const [pendingRadioSelectionChanges, setPendingRadioSelectionChanges] = useState<IRadioSelectionInfo>();
    const [loadingState, setLoadingState] = useLoadingStateWithDebounce();
    const isFormDataChanged = useCallback(
        () => paramsRef.current && initialValuesJsonRef.current !== getActiveValuesJson(paramsRef.current, itemsMap),
        [itemsMap],
    );

    const closeForm = useCallback(
        (closeAll?: boolean) => {
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
            onClose(closeAll);
        },
        [onClose, setLoadingState],
    );

    const onCancel = useCallback(
        (closeAll?: boolean) => {
            if (isFormDataChanged()) {
                globalModelUtils.showConfirmationModal({
                    title: t('CUSTOM_FORM.DISCARD_DIALOG.TITLE'),
                    text: t('CUSTOM_FORM.DISCARD_DIALOG.TEXT'),
                    variant: 'warning',
                    onConfirm: () => closeForm(closeAll),
                    submitBtnText: t('CUSTOM_FORM.DISCARD_DIALOG.SUBMIT_BUTTON_TEXT'),
                    cancelBtnText: t('CUSTOM_FORM.DISCARD_DIALOG.CANCEL_BUTTON_TEXT'),
                });
            } else {
                closeForm(closeAll);
            }
        },
        [closeForm, isFormDataChanged, t],
    );

    const updateAllErrorInfos = async (relevantItemsMap: IItemsMap) => {
        if (paramsRef.current) {
            setFullErrorsInfo(await getFullErrorsInfo(paramsRef.current, relevantItemsMap));
        }
    };

    const handleErrors = useCallback(() => {
        const firstErrorRef: IComponentRef | undefined = getFirstErrorRef(fullErrorsInfo.errors);
        if (firstErrorRef?.current) {
            scrollToComponent(firstErrorRef.current);
        }
    }, [fullErrorsInfo.errors]);

    const onSubmit = useCallback(async () => {
        setWasSubmitted(true);
        if (fullErrorsInfo.errors.length > 0) {
            handleErrors();
            return;
        }

        if (!paramsRef.current) {
            return;
        }
        setIsSaving(true);
        const activeItemsMap: IItemsMap = getActiveItemsMap(paramsRef.current, itemsMap);
        formProps
            .submitData(activeItemsMap, data)
            .then(() => {
                if (formProps.getSubmitSuccessNotification) {
                    const text = formProps.getSubmitSuccessNotification(activeItemsMap, data);
                    getNotificationsService().addNotification({
                        type: NotificationType.SUCCESS,
                        text,
                    });
                }
                closeForm();
                if (formProps.eventHandlerId) {
                    globalEventBus.sendEvent(formProps.eventHandlerId);
                }
            })
            .catch((error: any) => {
                console.error('Failed editing form data with error:', error);
                if (formProps.getSubmitFailedNotification) {
                    const text = formProps.getSubmitFailedNotification(activeItemsMap, String(error), data);
                    getNotificationsService().addNotification({
                        type: NotificationType.ERROR,
                        text,
                    });
                }
            })
            .finally(() => {
                setIsSaving(false);
            });
    }, [fullErrorsInfo.errors.length, formProps, itemsMap, data, handleErrors, closeForm]);

    const handlePendingFieldChanges = useCallback(async () => {
        const newItem: IFieldItem | undefined = pendingFieldChanges;
        if (!newItem || !paramsRef.current) {
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
            return;
        }
        const oldItem: IFieldItem = itemsMap[newItem.name] as IFieldItem;
        const dupItemsMap: IItemsMap = cloneItemsMap(itemsMap, newItem);
        const activeItemsMap: IItemsMap = getActiveItemsMap(paramsRef.current, dupItemsMap);

        try {
            const allItems: IItem[] = Object.values(dupItemsMap);
            for (let i = 0; i < allItems.length; i++) {
                const item: IItem = allItems[i];
                if (item.getNewItemState) {
                    const changeDetails: IItemChanges = {
                        newItem,
                        oldItem,
                    };
                    const newState: IBaseItemState | undefined = await item.getNewItemState(
                        item as any,
                        changeDetails,
                        activeItemsMap,
                    );
                    if (newState) {
                        item.state = newState;
                    }
                }
            }
            fixSelectedRadioOption(dupItemsMap);
            await updateAllErrorInfos(dupItemsMap);
            setItemsMap(dupItemsMap);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
            setPendingFieldChanges(undefined);
        } catch (error) {
            console.error(error);
            setLoadingState(LoadingState.LOADING_FAILED);
            setPendingFieldChanges(undefined);
            globalModelUtils.showErrorModal({
                text: t('CUSTOM_FORM.NOTIFICATIONS.LOADING_FAILED'),
            });
        }
    }, [itemsMap, pendingFieldChanges, setLoadingState, t]);

    const onFieldChanged = useCallback(
        async (newItem: IFieldItem) => {
            setLoadingState(LoadingState.IS_LOADING);
            setPendingFieldChanges(newItem);
        },
        [setLoadingState],
    );

    const handlePendingRadioChanges = useCallback(async () => {
        try {
            const radioInfo: IRadioSelectionInfo | undefined = pendingRadioSelectionChanges;
            if (!radioInfo) {
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
                return;
            }
            const oldRadioItem = itemsMap[radioInfo.radioName] as IRadioItem;
            if (!oldRadioItem || oldRadioItem.options.length === 0) {
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
                setPendingRadioSelectionChanges(undefined);
                return;
            }

            const newRadioItem: IRadioItem = {
                ...oldRadioItem,
                state: {
                    ...oldRadioItem.state,
                    selectedName: radioInfo.selectedName,
                },
            };
            const dupItemsMap: IItemsMap = cloneItemsMap(itemsMap, newRadioItem);
            await updateAllErrorInfos(dupItemsMap);
            setItemsMap(dupItemsMap);
            setPendingRadioSelectionChanges(undefined);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (error) {
            console.error(error);
            setLoadingState(LoadingState.LOADING_FAILED);
            setPendingRadioSelectionChanges(undefined);
            globalModelUtils.showErrorModal({
                text: t('CUSTOM_FORM.NOTIFICATIONS.LOADING_FAILED'),
            });
        }
    }, [itemsMap, pendingRadioSelectionChanges, setLoadingState, t]);

    const onRadioSelectionChanged = useCallback(
        (radioName: string, fieldName: string) => {
            setPendingRadioSelectionChanges({
                radioName,
                selectedName: fieldName,
            });
            setLoadingState(LoadingState.IS_LOADING);
        },
        [setLoadingState],
    );

    const createAllComponents = useCallback((): ReactNode[] => {
        if (!paramsRef.current) {
            return [];
        }
        const formDetails: IFullFormDetails = {
            params: paramsRef.current,
            itemsMap,
            fullErrorsInfo,
            wasSubmitted,
            onFieldChanged,
            onRadioSelectionChanged,
        };

        return createItemComponents(formDetails);
    }, [fullErrorsInfo, itemsMap, onFieldChanged, onRadioSelectionChanged, wasSubmitted]);

    const loadInitialData = useCallback(async () => {
        try {
            const topItems: IItem[] = await formProps.getItems(data);
            const itemsMap: IItemsMap = createInitialItemsMap(topItems);
            paramsRef.current = {
                topNames: topItems.map((item) => item.name),
                disableAutoValidation: formProps.disableAutoValidation,
                additionalData: data,
                now: new Date(),
            };
            setItemsMap(itemsMap);
            initialValuesJsonRef.current = getActiveValuesJson(paramsRef.current, itemsMap);
            await updateAllErrorInfos(itemsMap);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (e) {
            console.error(e);
            setLoadingState(LoadingState.LOADING_FAILED);
            globalModelUtils.showErrorModal({
                text: t('CUSTOM_FORM.NOTIFICATIONS.LOADING_FAILED'),
            });
        }
    }, [data, formProps, setLoadingState, t]);

    useEffect(() => {
        void loadInitialData();
    }, [loadInitialData]);

    useEffect(() => {
        if (pendingFieldChanges) {
            void handlePendingFieldChanges();
        }
    }, [handlePendingFieldChanges, pendingFieldChanges]);

    useEffect(() => {
        if (pendingRadioSelectionChanges) {
            void handlePendingRadioChanges();
        }
    }, [handlePendingRadioChanges, pendingRadioSelectionChanges]);

    useCloseDrawerHandler(CUSTOM_FORM_DRAWER_EVENT_HANDLER_ID, (closeAll?: boolean) => {
        onCancel(closeAll);
    });

    if (loadingState === LoadingState.IS_INITIALIZING) {
        return <FullSizeSpinner />;
    }

    return (
        <FormStyled.TopDiv>
            {loadingState === LoadingState.IS_LOADING && (
                <FormStyled.LoadingOverlay>
                    <Spinner size={32} color={'white'} />
                </FormStyled.LoadingOverlay>
            )}
            {loadingState === LoadingState.LOADING_FAILED && (
                <Typography variant={'h1'} color={'alert'}>
                    {t('GENERAL.ERROR_OCCURRED')}
                </Typography>
            )}
            <FormStyled.Content spacing={5} fullHeight>
                <FormStyled.TopAreaDiv direction={'column'} spacing={5}>
                    {createAllComponents()}
                </FormStyled.TopAreaDiv>
                <FormStyled.ButtonsDiv>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button variant='text' onClick={() => onCancel()} disabled={isSaving} dataAid='Cancel'>
                            {t('CUSTOM_FORM.BUTTONS.CANCEL')}
                        </Button>
                        <Button color='brandPrimary' onClick={onSubmit} disabled={isSaving}>
                            {isSaving ? t('CUSTOM_FORM.BUTTONS.SAVING') : t('CUSTOM_FORM.BUTTONS.SAVE')}
                        </Button>
                    </Stack>
                </FormStyled.ButtonsDiv>
            </FormStyled.Content>
        </FormStyled.TopDiv>
    );
};
