import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { FILTER_BOX_DISPLAY_TYPES, FILTER_DISPAY_TYPES, FILTER_EVENTS, FILTER_TYPES } from '../FilterPanel.consts';
import { IFilterPanelView, ILastEvent } from '../FilterPanel.interface';
import './FilterPanelBox.scss';
import { useTranslation } from 'react-i18next';
import { TextField } from '@dome9/berries/react-components';
import { debounce } from '../../../utils/functions';
import { getBoxPosition } from '../filterUtils';

export const FilterPanelBox: React.FC<{
    filterViewProps: IFilterPanelView;
}> = ({ filterViewProps }) => {
    const { t } = useTranslation();
    const { filters, filtersValues, onEvent, lastEvent, defaultSearchTerm, filtersUtils, handlersValues } =
        filterViewProps;
    const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
    const boxRef = useRef(null);
    const filtersSearchInputRef = useRef<any>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const position = useMemo(() => getBoxPosition(filterViewProps.lastEvent.payload.target, 400), []);

    useEffect(() => {
        filtersSearchInputRef?.current?.focus();
        const handleKeyDown = (event: any) => {
            const isMouseClickedOutsideBox = (event: MouseEvent) => {
                return (
                    (event?.target as Element)?.closest('html') &&
                    !(event.target as Element)?.closest('.filterPanelBox')
                );
            };

            const isMouseClickedOnButton = (event: MouseEvent) => {
                const button = filterViewProps.lastEvent.payload.target;
                return button && event.target !== filterViewProps.lastEvent.payload.target;
            };

            if (
                (event instanceof MouseEvent && isMouseClickedOutsideBox(event) && isMouseClickedOnButton(event)) ||
                (event instanceof KeyboardEvent && event.code === 'Escape')
            ) {
                onEvent({
                    action: FILTER_EVENTS.SHOW_VIEW,
                    filterKey: FILTER_DISPAY_TYPES.BOX,
                    payload: { searchTerm: '', view: FILTER_DISPAY_TYPES.BOX, showview: false },
                });
            }
        };

        document.addEventListener('mousedown', handleKeyDown);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousedown', handleKeyDown);
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearSearchTerm = () => {
        setSearchTerm('');
    };

    const onBoxViewEvent = ({ action, filterKey, payload }: ILastEvent) => {
        switch (action) {
            case FILTER_EVENTS.BOX_FILTER_OPEN: {
                const scrollBoxContainer = document.getElementsByClassName('scrollBox')[0];
                if (payload.action === FILTER_EVENTS.BOX_FILTER_SECTION_OPENED) {
                    setTimeout(() => {
                        // this setTimeout is for delay the scrolling to after the section is expanded
                        scrollBoxContainer.scrollTo({
                            left: 0,
                            top: payload.elementRef.current.offsetTop,
                            behavior: 'smooth',
                        });
                    });
                }
                break;
            }

            default:
                onEvent({ action, filterKey, payload });
        }
    };

    return (
        <div
            ref={boxRef}
            style={{ top: position.top + 3 }}
            className='filterPanelBox shadow-lg shadow bg-content flex flex-col border border-strong'
            data-aid='add-filter-dropdown'
        >
            <div className='bg-aside p-7 border-b'>
                <TextField
                    ref={filtersSearchInputRef}
                    allowClear={true}
                    onClear={clearSearchTerm}
                    icon='magnify'
                    value={searchTerm}
                    onChange={debounce((event: any) => setSearchTerm(event?.target?.value))}
                    placeholder={t('FILTER_PANEL.SEARCH_FILTERS')}
                />
            </div>
            <div className=' scrollBox relative overflow-auto flex-1 py-5' data-aid='add-filter-dropdown'>
                <div className=''>
                    {filters.map((item) => {
                        const { filterProps, displayTypes } = item;
                        return (
                            <Fragment key={filterProps.key}>
                                {displayTypes.includes(FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1) && (
                                    <item.filterElement
                                        filterProps={{
                                            ...filterProps,
                                            value:
                                                item.type === FILTER_TYPES.FILTER ||
                                                item.type === FILTER_TYPES.FILTER_OUTSIDE
                                                    ? filtersValues[filterProps.key]
                                                    : handlersValues[filterProps.key].value,
                                            currFilterData:
                                                item.type === FILTER_TYPES.FILTER ||
                                                item.type === FILTER_TYPES.FILTER_OUTSIDE
                                                    ? lastEvent.result && lastEvent.result[filterProps.key]
                                                    : handlersValues[filterProps.key].data,
                                            onEvent: onBoxViewEvent,
                                            lastEvent,
                                            filtersUtils: filtersUtils,
                                            displayType: FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1,
                                            searchTerm,
                                        }}
                                        key={filterProps.key}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </div>
                <div className='border-b my-5 mx-7'></div>
                <div className=''>
                    {filters.map((item) => {
                        const { filterProps, displayTypes } = item;
                        return (
                            <Fragment key={filterProps.key}>
                                {displayTypes.includes(FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2) && (
                                    <item.filterElement
                                        filterProps={{
                                            ...filterProps,
                                            value:
                                                item.type === FILTER_TYPES.FILTER ||
                                                item.type === FILTER_TYPES.FILTER_OUTSIDE
                                                    ? filtersValues[filterProps.key]
                                                    : handlersValues[filterProps.key].value,
                                            currFilterData:
                                                item.type === FILTER_TYPES.FILTER ||
                                                item.type === FILTER_TYPES.FILTER_OUTSIDE
                                                    ? lastEvent.result && lastEvent.result[filterProps.key]
                                                    : handlersValues[filterProps.key].data,
                                            onEvent: onBoxViewEvent,
                                            lastEvent,
                                            filtersUtils: filtersUtils,
                                            displayType: FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2,
                                            searchTerm,
                                        }}
                                        key={filterProps.key}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
            <div className='bg-aside border-t flex p-6 flex-0'>
                {filters.map((item) => {
                    const { filterProps, displayTypes } = item;
                    return (
                        displayTypes.includes(FILTER_BOX_DISPLAY_TYPES.BOX_BUTTON) && (
                            <item.filterElement
                                filterProps={{
                                    ...filterProps,
                                    onEvent: onBoxViewEvent,
                                    lastEvent,
                                    displayType: FILTER_BOX_DISPLAY_TYPES.BOX_BUTTON,
                                    searchTerm,
                                }}
                                key={filterProps.key}
                            />
                        )
                    );
                })}
            </div>
        </div>
    );
};
export default FilterPanelBox;
