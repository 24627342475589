import { Stack, Typography } from 'common/design-system/components-v2';
import React from 'react';
import i18n from 'i18next';
import { Theme } from '../../../design-system/theme/theme.types';

const FullSizeError: React.FC<{ text?: string, variant?: keyof Theme['typography'] }> = ({ text, variant = 'body' }) =>
    <Stack alignItems='center' justifyContent='center' fullWidth fullHeight>
        <Typography color="alert" variant={variant}>{text || i18n.t('COMMON.ERROR_OCCURRED')}</Typography>
    </Stack>;

export default FullSizeError;