import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import './RangeFilter.scss';
import { IExtendRangeFilterProps } from '../DefaultFilters.interface';
import { FILTER_EVENTS } from '../../FilterPanel.consts';
import { isFilterBoxContent, isIncludeSearchTerm, renderAccordionClearButton } from '../../filterUtils';
import Accordion from '../../GeneralComponents/Accordion/Accordion';

const MultiRangeSlider: FC<{ filterProps: IExtendRangeFilterProps }> = ({ filterProps }) => {
    const { min, max, value, onEvent, key, title, searchTerm, displayType, step = 1 } = filterProps;
    const range = useRef<HTMLDivElement>(null);

    const [minVal, setMinVal] = useState(value.min);
    const [maxVal, setMaxVal] = useState(value.max);

    useEffect(() => {
        setMinVal(value.min);
        setMaxVal(value.max);
    }, [value]);

    // Convert to percentage
    const getPercent = useCallback((value: number) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

    function setSliderStyle(minValue: number, maxValue: number) {
        const minPercent = getPercent(minValue);
        const maxPercent = getPercent(maxValue);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }

    const onMinValueChange = (newMinValue: number) => {
        setSliderStyle(newMinValue, value.max);
        setMinVal(newMinValue);
        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: { min: newMinValue, max: value.max },
        });
    };

    const onMaxValueChange = (newMaxValue: number) => {
        setSliderStyle(value.min, newMaxValue);
        setMaxVal(newMaxValue);
        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: { min: value.min, max: newMaxValue },
        });
    };

    const renderGeneralRangeFilter = () => {
        const minPercent = getPercent(value.min);
        const maxPercent = getPercent(value.max);
        return (
            <div className='multi-range-slide-container'>
                <input
                    type='range'
                    min={min}
                    max={max}
                    value={minVal}
                    step={step}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newValue = Math.min(Number(event.target.value), value.max - 1);
                        onMinValueChange(newValue);
                    }}
                    className='thumb thumb--left'
                />
                <input
                    type='range'
                    min={min}
                    max={max}
                    step={step}
                    value={maxVal}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newValue = Math.max(Number(event.target.value), value.min + 1);
                        onMaxValueChange(newValue);
                    }}
                    className='thumb thumb--right'
                />
                <div className='slider'>
                    <div className='slider__track'></div>
                    <div
                        ref={range}
                        style={{ left: `${minPercent}%`, width: `${maxPercent - minPercent}%` }}
                        className='slider__range'
                    ></div>
                    <div className='slider__left-value'>{minVal}</div>
                    <div className='slider__right-value'>{maxVal}</div>
                </div>
            </div>
        );
    };

    const renderBoxViewRangeFilter = () => {
        return (
            shouldShowFilterBySearch() && (
                <Accordion
                    title={title}
                    content={<div style={{ padding: '13px' }}>{renderGeneralRangeFilter()}</div>}
                    optionsList={[
                        renderAccordionClearButton(() =>
                            onEvent({
                                action: FILTER_EVENTS.CLEAR_FILTERS,
                                filterKey: key,
                                payload: '',
                            }),
                        ),
                    ]}
                    onOpen={(elementRef: any) => {
                        onEvent({
                            action: FILTER_EVENTS.BOX_FILTER_OPEN,
                            filterKey: key,
                            payload: elementRef,
                        });
                    }}
                />
            )
        );
    };

    const shouldShowFilterBySearch = () => {
        return searchTerm ? isIncludeSearchTerm(searchTerm, title) : true;
    };

    return (
        <div className='range-slider-filter' data-aid='range-filter'>
            {isFilterBoxContent(displayType) ? renderBoxViewRangeFilter() : renderGeneralRangeFilter()}
        </div>
    );
};

export default MultiRangeSlider;
