import React, { useEffect, useState } from 'react';
import { Stack } from 'common/design-system/components-v2';
import Card, { CardProps } from './Card';

interface Props {
    items: CardProps[];
    header: string;
    onSelectedItemChanged: Function;
    selectedItemId?: string;
}

const CardsSelectComponent: React.FC<Props> = ({ selectedItemId, header, items, onSelectedItemChanged }) => {
    const [selectedItem, setSelectedItem] = useState<CardProps>(items[0]);
    const onItemClicked = (item: CardProps) => {
        onSelectedItemChanged(item);
        setSelectedItem(item);
    };

    useEffect(() => {
        if (items?.length) {
            if (selectedItemId) {
                const item = items.find((item) => item.id === selectedItemId);
                item && setSelectedItem(item);
            } else {
                setSelectedItem(items[0]);
            }
        }
    }, [items, selectedItemId]);

    return (
        <Stack>
            <h3>{header}</h3>
            <Stack spacing={10} direction={'row'} flexWrap={true}>
                {items?.map((item) => (
                    <div data-aid='card' key={item.title} onClick={() => onItemClicked(item)}>
                        <Card
                            selected={item.id === selectedItem?.id}
                            id={item.id}
                            title={item.title}
                            icon={item.icon || ''}
                        />
                    </div>
                ))}
            </Stack>
        </Stack>
    );
};

export default CardsSelectComponent;
