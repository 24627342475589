import React, { forwardRef } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import Stack from '../Stack/Stack';
import { CardProps } from './Card.types';
import { CardWrapper, Header } from './Card.styles';

const Card: React.FC<CardProps> = forwardRef<HTMLDivElement, CardProps>(
    ({ smallSize, tooltip, subtitle, children, title, iconProps, rightContent, fullWidth }, ref) => (
        <CardWrapper fullHeight ref={ref} smallSize={smallSize} direction={'column'} fullWidth={fullWidth}>
            <Header smallSize={smallSize} alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
                <Stack spacing={1} alignItems={'center'} direction={'column'}>
                    <Stack padding={[2, 0]} spacing={1} fullWidth alignItems={'center'} direction={'row'}>
                        {iconProps && <Icon {...iconProps} />}
                        <Typography color={'strong'} variant={'body500'}>
                            {title}
                        </Typography>
                        {tooltip && (
                            <Tooltip content={tooltip}>
                                <Icon size={10} name={'info'} />
                            </Tooltip>
                        )}
                    </Stack>
                    {subtitle && <Typography variant={'body'}>{subtitle}</Typography>}
                </Stack>
                {rightContent ? rightContent : null}
            </Header>
            {children && <div className={'content'}>{children}</div>}
        </CardWrapper>
    ),
);

Card.displayName = 'Card';

export default Card;
