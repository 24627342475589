import React from 'react';
import { LinkProps } from './Link.types';
import LinkStyled from './Link.styled';
import CopiableLine from '../CopiableLine/CopiableLine';
import IconButton from '../IconButton/IconButton';
import { getEventTracking } from 'common/interface/services';

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { internalUrl, externalUrl, removeExternalIcon = false, copyText, children, dataAid, id } = props;

    React.useEffect(() => {
        if (!internalUrl && !externalUrl) {
            console.error('internalUrl or externalUrl is required.');
        }
        if (internalUrl && externalUrl) {
            console.warn('Both internalUrl and externalUrl are provided. Only internalUrl will be used.');
        }
    }, [internalUrl, externalUrl]);

    const handleOnLinkClick = React.useCallback(() => {
        try {
            const isChildrenString = typeof children === 'string';
            getEventTracking().track('DS_Link_click', {
                id: id,
                label: isChildrenString ? children : 'JSX',
                internalUrl,
                externalUrl,
            });
        } catch (error) {
            /* do nothing */
        }
    }, [children, id, internalUrl, externalUrl]);

    const Content = React.useMemo(() => {
        if (internalUrl) {
            return (
                <LinkStyled.StyledInnerA
                    href={internalUrl}
                    ref={ref}
                    dataAid={dataAid}
                    id={id}
                    onClick={handleOnLinkClick}
                >
                    {children}
                </LinkStyled.StyledInnerA>
            );
        }
        if (externalUrl) {
            return (
                <LinkStyled.StyledOuterA
                    ref={ref}
                    href={externalUrl}
                    target='_blank'
                    rel='noreferrer'
                    data-aid={dataAid}
                    id={id}
                    onClick={handleOnLinkClick}
                >
                    {children}
                    {!removeExternalIcon && <IconButton iconProps={{ name: 'externalLink', size: 10 }} size='small' />}
                </LinkStyled.StyledOuterA>
            );
        }
        return null;
    }, [children, internalUrl, externalUrl, ref, dataAid, id, removeExternalIcon, handleOnLinkClick]);

    if (copyText) {
        return <CopiableLine value={copyText}>{Content}</CopiableLine>;
    }

    return Content;
});

Link.displayName = 'Link';

export default Link;
