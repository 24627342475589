export const removeEmptyKeys = (obj: any): any => {
    // Check if the object is an object
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    // Remove empty keys recursively
    return Object.keys(obj)
        .filter((key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== '')
        .reduce((result: any, key: any) => {
            result[key] = removeEmptyKeys(obj[key]);
            return result;
        }, {});
};

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
    // Check if the objects are both objects
    obj1 = removeEmptyKeys(obj1);
    obj2 = removeEmptyKeys(obj2);
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }

    // Check if the objects have the same keys
    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();
    if (keys1.length !== keys2.length || !keys1.every((key, index) => key === keys2[index])) {
        return false;
    }

    // Check if the objects have the same values
    return keys1.every((key) => {
        if (obj1[key] === undefined || obj1[key] === null || obj1[key] === '') {
            return true;
        }
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            return areObjectsEqual(obj1[key], obj2[key]);
        }
        return obj1[key] === obj2[key];
    });
};

export const deepCloneObject = (object: { [key: string]: any }) => {
    const objectAsString = JSON.stringify(object);
    return JSON.parse(objectAsString);
};

export const getUniqueValues = (arrayWithDuplicates: any[]) => {
    const uniqueValues = new Set(arrayWithDuplicates);
    return [...uniqueValues];
};

export const getUniqueObjectsByField = (objects: any[], field: string) => {
    return objects.reduce((uniqueObjects, currentObject) => {
        // Check if there is already an object with the same value for the specified field
        const existingObject = uniqueObjects.find((obj: any) => obj[field] === currentObject[field]);
        if (!existingObject) {
            // If not found, add the current object to the unique objects array
            uniqueObjects.push(currentObject);
        }
        return uniqueObjects;
    }, []);
};
