import React, { useCallback, useEffect } from 'react';
import FilterTree from 'common/erm-components/custom/FilterTree';
import { ICompoundFilter } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import {
    Alert,
    Command,
    DatePicker,
    RadioButton,
    SelectV2,
    Stack,
    Typography,
} from 'common/design-system/components-v2';
import { IFilterChangeEventInfo } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import EventsTableWidget from 'common/erm-components/custom/EventsTableWidget/EventsTableWidget';
import { getKustoEventService } from 'common/module_interface/intelligence/intelligence';
import { GslQueryFilterExampleStyled as QueryExampleStyled } from './GslQueryFilterExample.styled';
import { IKustoEvent } from 'common/components/KustoEvents/KustoEvent.interface';
import {
    aggregationOptionsExample, compoundFilterExample, countOptionsExample,
    defaultLimit,
    filterDefsExample,
    GslQueryMode,
    sortOptionsExample,
} from './GslQueryFilterExample.const';
import { IFindingGslFilter } from 'common/module_interface/intelligence/Gsl/FindingGslService.interface';
import { IGslCount, IGslFilter, IGslSort } from 'common/components/gsl/GslService.interface';
import { isNilOrEmpty } from 'common/utils/helpFunctions';
import { createGetAggregationsQuery, createGetCountQuery, createGetItemsQuery } from '../../../components/gsl/GslServiceQueries';
import { DateTimeRange } from 'common/components/Findings/Findings.interface';
import Spinner from 'common/design-system/components-v2/Spinner';
import { GslSource } from '../../../module_interface/intelligence/Gsl/GslService.const';
import { getColumnsOverrideDefs } from './GslQueryFilterExample.columns';
import { DateRange } from 'react-day-picker';
import { IDateRange } from '../../../design-system/components-v2/DatePicker/DatePicker.types';

interface IGetGslResultsParams {
    queryMode: GslQueryMode;
    filter: IFindingGslFilter;
    limit: string;
    sort?: IGslSort[];
    aggregation?: string[];
    count?: IGslCount;
}

const GslQueryFilterExample: React.FC = () => {
    const [compoundFilter, setCompoundFilter] = React.useState<ICompoundFilter>(compoundFilterExample);
    const [gslQuery, setGslQuery] = React.useState<string>('');
    const [error, setError] = React.useState<string>('');
    const [gslTableResults, setGslTableResults] = React.useState<IKustoEvent[] | undefined>();
    const [gslRawResults, setGslRawResults] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [limit, setLimit] = React.useState<string>(defaultLimit);
    const [sort, setSort] = React.useState<string[]>();
    const [queryMode, setQueryMode] = React.useState<GslQueryMode>(GslQueryMode.Items);
    const [aggregation, setAggregation] = React.useState<string[]>();
    const [count, setCount] = React.useState<string>();
    const [dateRange, setDateRange] = React.useState<DateRange | undefined>(
        {
            from: new Date(Date.now() - 24 * 60 * 60 * 1000),
            to: new Date(),
        }
    );

    const onFilterChange = (filterInfo: IFilterChangeEventInfo) => {
        if (filterInfo.hasErrors) {
            setError('Please construct a legal gsl query');
            setGslQuery('');
            return;
        }
        if (!filterInfo || !filterInfo.filter) {
            setGslQuery('');
            return;
        }
        setError('');
        setCompoundFilter(filterInfo.filter);
    };

    const onTimeRangeChange = (dateRange: IDateRange | undefined) => {
        setDateRange(dateRange);
    };

    const getGslResults = async (getGslResultsParams: IGetGslResultsParams) => {
        try {
            const { queryMode, filter, limit, sort, aggregation, count } = getGslResultsParams;
            let tableResults, rawResults;
            const gslFilter: IGslFilter = { source: GslSource.alerts, ...filter };
            switch(queryMode) {
                case GslQueryMode.Items:
                    tableResults = await getKustoEventService().getKustoEvents(filter, Number(limit), sort);
                    setGslQuery(createGetItemsQuery(gslFilter, Number(limit), sort));
                    setGslTableResults(tableResults);
                    setGslRawResults(undefined);
                    break;
                case GslQueryMode.Aggregation:
                    if (!aggregation) return;
                    rawResults = await getKustoEventService().getKustoEventFacets(filter,aggregation);
                    setGslQuery(createGetAggregationsQuery(gslFilter, aggregation));
                    setGslRawResults(JSON.stringify(rawResults));
                    setGslTableResults(undefined);
                    break;
                case GslQueryMode.Count:
                    if (!count) return;
                    rawResults = await getKustoEventService().getKustoCountEvents(filter, count);
                    setGslQuery(createGetCountQuery(gslFilter, count));
                    setGslRawResults(JSON.stringify(rawResults));
                    setGslTableResults(undefined);
                    break;
            }
            setError('');
        }
        catch {
            setError('Error occurred while getting gsl results');
            setGslQuery('');
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (!compoundFilter || !dateRange?.from || !dateRange?.to) {
            setError('Gsl Filter is required');
            setIsLoading(false);
            return;
        }

        const gslSort: IGslSort[] | undefined = isNilOrEmpty(sort)? undefined : sort.map((sortItem) : IGslSort => {
            const [ fieldName, direction ] = sortItem.split(' ');
            return { fieldName, direction } as IGslSort;
        });

        const gslCount: IGslCount | undefined = isNilOrEmpty(count) ? undefined : { field: count } as IGslCount;

        const gslAggregation: string[] | undefined = isNilOrEmpty(aggregation) ? undefined : aggregation;

        const timeRangeInUnix: DateTimeRange = {};
        if (dateRange && dateRange.from && dateRange.to) {
            timeRangeInUnix.from = dateRange.from.getTime().toString();
            timeRangeInUnix.to = dateRange.to.getTime().toString();
        }

        const gslFilter: IFindingGslFilter = {
            compoundFilter: compoundFilter,
            timeFilter: {
                field: 'starttime',
                range: timeRangeInUnix,
            },
        };

        void getGslResults({ queryMode, filter: gslFilter, limit,
            sort: gslSort, aggregation: gslAggregation, count: gslCount });
        setIsLoading(false);
    }, [compoundFilter, limit, sort, aggregation, count, dateRange, queryMode]);

    const getSelectorsByMode = useCallback(() => {
        switch (queryMode) {
            case GslQueryMode.Items:
                return (
                    <QueryExampleStyled.SelectWrapperDiv>
                        <SelectV2 key={'sort'} onChange={(value) => setSort(value)} options={sortOptionsExample}
                            placeholder={'Select sort field'} clearable fullWidth isMulti={true} value={sort}/>
                        <SelectV2 key={'limit'} onChange={(value) => setLimit(value || defaultLimit)}
                            isMulti={false} options={[{ value: '10', label: '10' },
                                { value: '50', label: '50' },
                                { value: '100', label: '100' }
                            ]} placeholder={'Select limit'} clearable fullWidth value={limit}/>
                    </QueryExampleStyled.SelectWrapperDiv>
                );
            case GslQueryMode.Aggregation:
                return (
                    <QueryExampleStyled.SelectWrapperDiv>
                        <SelectV2 key={'aggregation'} onChange={(value) => setAggregation(value)}
                            options={aggregationOptionsExample} placeholder={'Select aggregation field'}
                            clearable fullWidth isMulti={true} value={aggregation} isError={aggregation?.length === 0}/>
                    </QueryExampleStyled.SelectWrapperDiv>
                );
            case GslQueryMode.Count:
                return (
                    <QueryExampleStyled.SelectWrapperDiv>
                        <SelectV2 key={'count'} onChange={(value) => setCount(value)} isMulti={false}
                            options={countOptionsExample} placeholder={'Select count field'}
                            clearable fullWidth value={count} isError={count === ''}/>
                    </QueryExampleStyled.SelectWrapperDiv>
                );
        }
    }, [queryMode, sort, limit, aggregation, count]);

    const getResults = useCallback(() => {
        if (error && !isLoading) return <Alert type={'error'}>{error}</Alert>;

        const queryInfo = (
            <Stack spacing={2}>
                <Typography variant={'bodyLg'}>GSL filters definitions:
                    <Command text={JSON.stringify(compoundFilter)} />
                </Typography>
                <Typography variant={'bodyLg'}>GSL final Query:
                    <Command text={gslQuery} />
                </Typography>
            </Stack>);

        if (!isLoading && gslTableResults) return (
            <Stack spacing={1}>
                { queryInfo }
                <Typography variant={'bodyLg'}>Table Results:</Typography>
                <QueryExampleStyled.TableDiv>
                    <EventsTableWidget items={gslTableResults || []} 
                        isLoading={isLoading} colOverride={getColumnsOverrideDefs()} />
                </QueryExampleStyled.TableDiv>
            </Stack>
        );
        else if (!isLoading && gslRawResults) return (
            <Stack spacing={1}>
                { queryInfo }
                <Typography variant={'bodyLg'}>Raw JSON Results:</Typography>
                <Command text={gslRawResults} />
            </Stack>
        );
    }, [compoundFilter, gslQuery, error, gslTableResults, gslRawResults, isLoading]);

    return (
        <Stack margin={5} spacing={5}>
            <Typography variant={'h2'}>Gsl Query Examples</Typography>
            <Stack spacing={4}>
                <QueryExampleStyled.QueryConfigurationsWrapperDiv>
                    <QueryExampleStyled.CompoundFilterWrapperDiv>
                        <Typography variant={'bodyLg'}>Compound Filter: </Typography>
                        <FilterTree compoundFilter={compoundFilter} displayErrors={true}
                            filterDefinitions={filterDefsExample} onFilterChange={onFilterChange}/>
                    </QueryExampleStyled.CompoundFilterWrapperDiv>
                    <QueryExampleStyled.TimeFilterWrapperDiv>
                        <Typography variant={'bodyLg'}>Time range: </Typography>
                        <DatePicker type={'range'} onChange={onTimeRangeChange} value={dateRange as DateRange} width={'300px'} />
                    </QueryExampleStyled.TimeFilterWrapperDiv>
                    <QueryExampleStyled.QueryOptionsWrapperDiv direction={'row'} spacing={10}>
                        <RadioButton key={'items'} label={'Items'} 
                            onChange={() => setQueryMode(GslQueryMode.Items)} checked={queryMode === GslQueryMode.Items}/>
                        <RadioButton key={'aggregation'} label={'Aggregation'} 
                            onChange={() => setQueryMode(GslQueryMode.Aggregation)} checked={queryMode === GslQueryMode.Aggregation}/>
                        <RadioButton key={'count'} label={'Count'} 
                            onChange={() => setQueryMode(GslQueryMode.Count)} checked={queryMode === GslQueryMode.Count}/>
                    </QueryExampleStyled.QueryOptionsWrapperDiv>
                    { getSelectorsByMode() }
                </QueryExampleStyled.QueryConfigurationsWrapperDiv>
                { isLoading && <Stack alignItems={'center'}><Spinner /></Stack>}
                { getResults() }
            </Stack>
        </Stack>
    );
};

export default GslQueryFilterExample;
