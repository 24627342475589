import React, { ReactNode, useMemo } from 'react';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import WidgetActivityGauge from 'common/components/Widgets/ActivityGauge/ActivityGauge';
import { GaugeStyle } from './StatusGauge.styled';
import Link from 'common/erm-components/basic/Link/Link';
import { TitleDisplayType } from 'common/components/Highcharts/ActivityGaugeChart';

const REG_EXP = /(\[[^\]]+\]\([^)]+\))/;
const REG_EXP_INNER = /\[([^\]]+)\]\(([^)]+)\)/;
const TextComponent: React.FC<{ text: string }> = ({ text }) => {
    const parts: string[] = text.split(REG_EXP);
    const components: ReactNode[] = [];
    parts.forEach((part: string, index: number) => {
        if (part.match(REG_EXP)) {
            const innerParts: string[] | null = part.match(REG_EXP_INNER);
            if (innerParts?.length === 3) {
                const label = innerParts[1]?.trim();
                const href = innerParts[2];
                components.push(
                    <Link externalUrl={href} key={String(index) + '-link'} textProps={{ text: label }} />
                );
            } else {
                components.push(<span key={String(index) + '-simple-match'}>{part}</span>);
            }
        } else {
            components.push(<span key={String(index) + '-simple'}>{part}</span>);
        }
    });

    return (
        <GaugeStyle.MessageItem direction={'row'}>
            {components}
        </GaugeStyle.MessageItem>
    );
};

export const StatusGauge: React.FC<{ value: number, outOf: number, color: string, messages: string[], titleDisplayType?: TitleDisplayType }> = ({ value, outOf, color, messages, titleDisplayType }) => {
    const options = useMemo(() => {
        return {
            useCustomColor: true,
            suppressLegend: true,
            titleDisplayType,
            titleStyle: {
                fontSize: '12px',
                fontWeight: 'bold',
            }
        };
    }, [titleDisplayType]);

    const items: IGenericWidgetDataItem[] = useMemo(() => [
        {
            key: 'value',
            value,
            color,
        },
        {
            key: 'outOf',
            value: outOf,
        },
    ], [color, outOf, value]);

    return (
        <GaugeStyle.TopDiv>
            <GaugeStyle.GaugeDiv>
                <WidgetActivityGauge data={{ items, options }} />
            </GaugeStyle.GaugeDiv>
            <GaugeStyle.LabelContainer>
                <GaugeStyle.MessagesBody>
                    {messages.map((text, index) =>
                        <GaugeStyle.MessageTypography key={index}>
                            <TextComponent text={text} />
                        </GaugeStyle.MessageTypography>
                    )}
                </GaugeStyle.MessagesBody>
            </GaugeStyle.LabelContainer>
        </GaugeStyle.TopDiv>
    );
};
