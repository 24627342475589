import React from 'react';
import { buildPath } from '../extensibility/AddinContainer';
import { IEvent } from '../module_interface/events/Events';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';
import { Vendors } from '../consts/vendors';

const PATH_EXCLUSION_PREFIX = 'exclusions';
export interface IExclusionModal {
    id: string;
    component: React.ComponentType<any>;
}
export interface IActionEnabler {
    enabler?: (event: IEvent) => boolean;
}
export const PATH_EVENTS_EXCLUSION_ENABLER = buildPath(PATH_EXCLUSION_PREFIX, 'events', 'enabler');
export const PATH_EXCLUSION_MODAL = buildPath(PATH_EXCLUSION_PREFIX, 'modals');

export enum ExclusionModuleType {
    CIEM = 'CIEM',
    CDR = 'Magellan',
    CSPM = 'ComplianceEngine',
    ImageAssurance = 'ImageAssurance',
    ContainersRuntimeProtection = 'ContainersRuntimeProtection',
    Serverless = 'Serverless',
}

export interface IExclusion {
    id?: string;
    platform?: string;
    rules?: IRuleOption[] | null;
    logicExpressions?: string[];
    logic?: string | [];
    regions?: string[] | null;
    severities?: string[] | null;
    rulesetId?: number;
    cloudAccountId?: string | null;
    cloudAccountIds?: string[] | null;
    organizationalUnitIds?: string[] | null;
    comment?: string;
    dateRange?: IDateRange | null;
    cloudAccountType?: string;
    ruleName?: string;
    finding?: IEvent;
}

export interface IRuleOption {
    logicHash?: string;
    id?: string;
    name?: string;
    rlmId?: string | null;
}

export interface IRegion {
    id: string;
    description: string;
}

export interface IOrganizationalUnit {
    id: string;
    name: string;
    path: string;
    parentId: string;
}

export interface IRule {
    category: string;
    cloudbots: any;
    complianceTag: string;
    controlTitle: string;
    description: string;
    domain: string;
    isDefault: boolean;
    labels: string[];
    logic: string;
    logicHash: string;
    name: string;
    priority: string;
    remediation: string;
    rlmId: any;
    ruleId: string;
    severity: string;
}

export interface IFieldFilter {
    name: string;
    value: string;
}

export interface IRuntimeRequestPayload {
    vendor: string;
    cloudAccountId: string;
    requestPayload: unknown;
}

export interface CiemSelectOption {
    id: number;
    cloudVendor: Vendors;
    label: string;
    value: string;
}
