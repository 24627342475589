export interface IDataCenter {
    id: number;
    icon: string;
    label: string;
    urls: string[];
    externalApi: string;
    authApi: string;
    dataCenter: string;
    location: DataCenterLocation;
    awsName: string;
    hidden?: boolean;
}

export enum DataCenterLocation {
    AP1 = 'ap1',
    US1 = 'us1',
    EU1 = 'eu1',
    AP2 = 'ap2',
    AP3 = 'ap3',
    CA1 = 'ca1',
    CN1 = 'cn1',
}

const DATA_CENTERS: IDataCenter[] = [
    {
        id: 1,
        icon: 'world',
        label: 'Singapore Data Center',
        urls: ['secure.ap1.dome9.com'],
        externalApi: 'https://api.ap1.dome9.com',
        authApi: 'https://prod-auth.ap1.dome9.com',
        dataCenter: 'apse1',
        location: DataCenterLocation.AP1,
        awsName: 'ap-southeast-1',
    },
    {
        id: 2,
        icon: 'world',
        label: 'United States Data Center',
        urls: [
            'secure.dome9.com',
            'local.dome9.com',
            'secure.us1.cgn.portal.checkpoint.com',
            'secure.falconetix.com',
            'local.falconetix.com',
        ],
        externalApi: 'https://api.dome9.com',
        authApi: 'https://auth.dome9.com',
        dataCenter: 'us1',
        location: DataCenterLocation.US1,
        awsName: 'us-east-1',
    },
    {
        id: 3,
        icon: 'world',
        label: 'Ireland Data Center',
        urls: [
            'secure.eu1.dome9.com',
            'local.eu1.dome9.com',
            'secure.eu1.cgn.portal.checkpoint.com',
            'secure.eu1.falconetix.com',
            'local.eu1.falconetix.com',
        ],
        externalApi: 'https://api.eu1.dome9.com',
        authApi: 'https://prod-auth.eu1.dome9.com',
        dataCenter: 'euwe1',
        location: DataCenterLocation.EU1,
        awsName: 'eu-west-1',
    },
    {
        id: 4,
        icon: 'world',
        label: 'Australia Data Center',
        urls: ['secure.ap2.dome9.com', 'local.ap2.dome9.com', 'secure.ap2.cgn.portal.checkpoint.com'],
        externalApi: 'https://api.ap2.dome9.com',
        authApi: 'https://prod-auth.ap2.dome9.com',
        dataCenter: 'apse2',
        location: DataCenterLocation.AP2,
        awsName: 'ap-southeast-2',
    },
    {
        id: 5,
        icon: 'world',
        label: 'India Data Center',
        urls: ['secure.ap3.dome9.com', 'local.ap3.dome9.com', 'secure.ap3.cgn.portal.checkpoint.com'],
        externalApi: 'https://api.ap3.dome9.com',
        authApi: 'https://prod-auth.ap3.dome9.com',
        dataCenter: 'apso1',
        location: DataCenterLocation.AP3,
        awsName: 'ap-south-1',
    },
    {
        id: 6,
        icon: 'world',
        label: 'Canada Data Center',
        urls: ['secure.cace1.dome9.com', 'secure.cace1.cgn.portal.checkpoint.com'],
        externalApi: 'https://api.cace1.dome9.com',
        authApi: 'https://prod-auth.cace1.dome9.com',
        dataCenter: 'cace1',
        location: DataCenterLocation.CA1,
        awsName: 'ca-central-1',
    },
    {
        id: 7,
        icon: 'world',
        label: 'China Data Center',
        urls: ['secure.cloudguard.com.cn', 'secure.falconetix.cn'],
        externalApi: 'https://api.placeHolder.dome9.com',
        authApi: 'https://prod-auth.placeHolder.dome9.com',
        dataCenter: 'cnnw1',
        location: DataCenterLocation.CN1,
        awsName: 'cn_northwest_1',
    },
];
export default DATA_CENTERS;
