import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

interface StringValueObject {
    [key: string]: string | number | undefined;
}

export const generateAndDownloadCsv = (mappedData: StringValueObject[], sectionIdentifier: string) => {
    if (mappedData.length === 0) {
        return;
    }
    const allRows = [Object.keys(mappedData[0]).join(',')];
    mappedData.forEach((row) => {
        allRows.push(Object.values(row).join(','));
    });
    const fileName = `Dome9_${sectionIdentifier}_${dayjs(new Date()).format('YYYY MM DD hh:mm A').replace(/\s+/g, '_').replace(/,/g, '')}.csv`;
    const csvStr = allRows.join('\n');
    fileDownload(csvStr, fileName);
};
