import React, { useCallback, useMemo } from 'react';
import { IFilterTreeCommonProps, IFilterTreeNode } from '../FilterTree.interface';
import { CompoundFilterLogicalOperator } from '../CompoundFilter';
import { FilterStyled } from './FilterTree.styled';
import { useTranslation } from 'react-i18next';
import { SelectV2, Stack } from '../../index';
import { SelectOption } from '../../SelectV2/Select.types';
import { NodeAddBox } from './FilterTreeNode';
import { isNil } from '../../../../utils/helpFunctions';
import { useDraggable } from '@dnd-kit/core';
import { useTheme } from 'styled-components';

export const FilterTreeLogicalOperator: React.FC<{
    node: IFilterTreeNode;
    filterProps: IFilterTreeCommonProps;
}> = ({ node, filterProps }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: node.id,
    });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          }
        : undefined;
    const opOptions: SelectOption[] = useMemo(() => {
        return Object.values(CompoundFilterLogicalOperator).map((op) => {
            return {
                label: t(`FILTER_TREE.LOGICAL_OPERATOR.${op.toUpperCase()}`),
                value: String(op),
            };
        });
    }, [t]);

    const onOperatorChange = useCallback(
        (logicalOperator: CompoundFilterLogicalOperator) => {
            filterProps.api.onLogicalOperatorChange(node, logicalOperator);
        },
        [filterProps, node],
    );

    return (
        <FilterStyled.LogicalOperatorDiv ref={setNodeRef} style={style}>
            <FilterStyled.LogicalOperatorSelectorDiv>
                <Stack direction={'row'}>
                    {!isNil(node.parentNode) && <FilterStyled.DragHandle {...listeners} {...attributes} />}
                    <SelectV2
                        width={'80px'}
                        value={node.logicalOperator}
                        onChange={(value: string | null) => onOperatorChange(value as CompoundFilterLogicalOperator)}
                        placeholder={''}
                        options={opOptions}
                        isError={!!(filterProps.displayErrors && node.errorMsg)}
                        isMulti={false}
                        style={{ background: theme.palette.surface.primary }}
                    />
                </Stack>
            </FilterStyled.LogicalOperatorSelectorDiv>
            <NodeAddBox node={node} filterProps={filterProps} />
            <FilterStyled.StretchDiv />
        </FilterStyled.LogicalOperatorDiv>
    );
};
