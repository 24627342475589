import React from 'react';
import './FilterListMenu.scss';
import { Dropdown } from '@dome9/berries/react-components';
import { Button } from 'common/design-system/components-v2';

export interface IFilterListMenuProps {
    children: JSX.Element;
}

export const FilterListMenu: React.FC<IFilterListMenuProps> = (props: IFilterListMenuProps) => {
    return (
        <Dropdown
            customButton={<Button iconButton variant='text' iconProps={{ name: 'more', size: 12 }} />}
            classNames='filter-list-menu'
            placement='right-start'
            render={() => props.children}
            dataAid='filter-options'
        ></Dropdown>
    );
};
