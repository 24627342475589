import { forwardRef } from 'react';
import WizardContentStyles from './WizardContent.styled';
import { WizardContentProps } from './WizardContent.types';
import Stepper from '../../Stepper';

const WizardContent = forwardRef<HTMLDivElement, WizardContentProps>((props, ref) => {
    const { children, stepperProps } = props;

    return (
        <WizardContentStyles.Wrapper ref={ref} direction='row' padding={6} spacing={6}>
            <WizardContentStyles.StepperWrapper>
                <Stepper {...stepperProps} />
            </WizardContentStyles.StepperWrapper>
            <WizardContentStyles.ContentWrapper>{children}</WizardContentStyles.ContentWrapper>
        </WizardContentStyles.Wrapper>
    );
});

WizardContent.displayName = 'WizardContent';

export default WizardContent;
