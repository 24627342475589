import { FC, useMemo } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import MultiSelectorTreeDropdown from '../../../MultiSelector/MultiSelectorTreeDropdown';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { getItemSelectPlaceholder } from '../../CustomForm.values';
import { IFullErrorInfo, IOnFieldChanged, IOrgUnitsItem } from '../../CustomForm.interface';
import ItemComponentBottomArea from '../helperComponents/ItemComponentBottomArea';

export interface IOrgUnitsSelectComponentProps {
    item: IOrgUnitsItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const OrgUnitsItemComponent: FC<IOrgUnitsSelectComponentProps> = ({
    item,
    onFieldChanged,
    errorInfo,
    showErrors,
    skipTitle,
    isInner,
}) => {
    const { state } = item;

    const orgUnitIds: string[] = useMemo(() => {
        const childrenOrgUnits = state.orgUnitsRoot?.children?.map((ou) => ou.id) || [];
        return state.orgUnitsRoot ? [state.orgUnitsRoot.id, ...childrenOrgUnits] : [];
    }, [state.orgUnitsRoot]);

    const nonSelectableIds: string[] = useMemo(() => {
        if (state.nonSelectableIdsFilter) {
            return orgUnitIds?.filter(state.nonSelectableIdsFilter) || [];
        }
        return [];
    }, [orgUnitIds, state.nonSelectableIdsFilter]);

    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <MultiSelectorTreeDropdown
                organizationalUnitsRoot={state.orgUnitsRoot}
                settings={{ maxHeight: '200px', minHeight: '150px' }}
                readOnly={state.disabled || state.readonly}
                selectedIds={state.value}
                placeholder={getItemSelectPlaceholder(item)}
                onSelectionChanged={(orgUnitIds) => onFieldChanged({ ...item, state: { ...state, value: orgUnitIds } })}
                nonSelectableIds={nonSelectableIds}
                nonSelectableReason={state.nonSelectableReason}
            />
            <ItemComponentBottomArea state={state} showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};
