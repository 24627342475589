import { IColumnUsageDef } from '../../../interface/general';
import { IProtectedAssetFilter } from '../../assets/ProtectedAssets';
import { AllIconsName } from '../../../design-system/components-v2/Icon/Icon.types';
import { Aggregations } from '../../../components/FilterPanel/FilterPanel.interface';
import { IFiltersInitialData } from '../../../interface/events';

export interface ILogFieldInfo {
    label: string,
    field: string,
    decorator?: Function,
}

export interface ILogFieldsSection {
    fields: ILogFieldInfo[];
    title: string;
}

export interface ILogTablePlatformTab {
    id: string;
    label: string;
    platform: string;
    icon: AllIconsName;
    columns: IColumnUsageDef[];
    filters: IProtectedAssetFilter[];
}

export enum LogFields {
    eventTime = 'eventTime',
    eventName = 'eventName',
    eventType = 'eventType',
    eventAws = 'eventAws',
    cloudGuardEvent = 'cloudGuardEvent',
    identityName = 'identityName',
    identityId = 'identityId',
    identityUserAgent = 'identityUserAgent',
    identityRegion = 'identityRegion',
    identityType = 'identityType',
    issuerName = 'issuerName',
    issuerId = 'issuerId',
    issuerType = 'issuerType',
    issuerRegion = 'issuerRegion',
    targetName = 'targetName',
    targetId = 'targetId',
    targetNamespace = 'targetNamespace',
    targetType = 'targetType',
    eventStatus = 'eventStatus',
    sourceIp = 'sourceIp',
    sourceCountry = 'sourceCountry',
    sourceCountryCode = 'sourceCountryCode',
    sourceIsMalicious = 'sourceIsMalicious',
    count = 'count',
}

export interface ILog {
    [LogFields.eventTime]: string;
    [LogFields.eventName]: string;
    [LogFields.eventType]: string;
    [LogFields.eventAws]: string;
    [LogFields.cloudGuardEvent]: string;
    [LogFields.identityName]: string;
    [LogFields.identityId]: string;
    [LogFields.identityUserAgent]: string;
    [LogFields.identityRegion]: string;
    [LogFields.identityType]: string;
    [LogFields.issuerName]: string;
    [LogFields.issuerId]: string;
    [LogFields.issuerType]: string;
    [LogFields.issuerRegion]: string;
    [LogFields.targetName]: string;
    [LogFields.targetId]: string;
    [LogFields.targetNamespace]: string;
    [LogFields.targetType]: string;
    [LogFields.eventStatus]: string;
    [LogFields.sourceIp]: string;
    [LogFields.sourceCountry]: string;
    [LogFields.sourceCountryCode]: string;
    [LogFields.sourceIsMalicious]: boolean;
    [LogFields.count]: number;
}

export type IPartialLog = Partial<ILog>;

export interface ICountLog extends IPartialLog {
    [LogFields.count]: number;
}

export interface ILogTableFilterParams {
    aggregations: Aggregations;
    filtersInitialData?: IFiltersInitialData;
    savedFiltersComponentName: string;
    recentlyUsedComponentName: string;
}
