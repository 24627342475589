import dayjs from 'dayjs';
import i18n from 'i18next';

export const getDateOnlyDateFormat = (date: Date): string => {
    return dayjs(date).format(i18n.t('COMMON.DATE_FORMATS.DATE_ONLY_FORMAT'));
};

export const isPassedDate = (date?: Date): boolean => {
    if (!date) {
        return false;
    }

    return dayjs(date).isBefore(dayjs());
};
