import React from 'react';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';

export interface SimpleTextProps {
    value: string;
    style?: React.CSSProperties;
}

const SimpleText: React.FC<SimpleTextProps> = ({ value, style }) => {
    const options: IIconLinkCellProps = {
        isLink: false,
        value,
        style,
    };

    return <IconLinkCellRenderer {...options} />;
};

export default SimpleText;
