import React from 'react';
import { IJsonToolbar } from './JsonRender/helpers/JsonView.types';
import Stack from '../../Stack';
import ButtonGroup from '../../ButtonGroup';
import Select from '../../SelectV2';
import IconButton from '../../IconButton';
import Typography from '../../Typography';

const JsonToolbar: React.FC<IJsonToolbar> = (props) => {
    const {
        selectOptions,
        matches,
        currentMatchIndex,
        showFont,
        showSearch,
        showExpandAll,
        onFontIncrease,
        onFontDecrease,
        onSelectOpen,
        onSelectChange,
        onNextMatch,
        onPrevMatch,
        onCollapseAll,
        onExpandAll,
    } = props;
    const selectArgs = {
        interactiveTooltip: true,
        placeholder: 'Search...',
        onChange: onSelectChange,
        onPopoverStateChange: onSelectOpen,
        fullWidth: false,
        startAdornment: (
            <div>
                <IconButton color='normal' iconProps={{ name: 'magnify' }} size='small' />
            </div>
        ),
        endAdornment:
            matches.length > 0 ? (
                <div>
                    <Typography variant='body'>
                        {currentMatchIndex + 1}/{matches.length}
                    </Typography>
                </div>
            ) : null,
        options: selectOptions,
        style: { width: '250px' },
    };

    return (
        <Stack fullWidth direction={'row'} justifyContent='flex-start' spacing={2}>
            {showFont && (
                <ButtonGroup
                    dataAid='font-size'
                    options={[
                        {
                            label: '',
                            iconProps: { name: 'minus' },
                            tooltip: 'Decrease Font size',
                            onClick: onFontDecrease,
                        },
                        {
                            label: '',
                            iconProps: { name: 'plus' },
                            tooltip: 'Increase Font size',
                            onClick: onFontIncrease,
                        },
                    ]}
                />
            )}
            {showExpandAll && (
                <ButtonGroup
                    dataAid='expand-json'
                    options={[
                        {
                            label: '',
                            iconProps: { name: 'lvlShrink' },
                            tooltip: 'Collapse All',
                            onClick: onCollapseAll,
                        },
                        { label: '', iconProps: { name: 'lvlExpand' }, tooltip: 'Expand All', onClick: onExpandAll },
                    ]}
                />
            )}
            {showSearch && (
                <Stack direction={'row'} alignItems={'flex-start'} spacing={2}>
                    <Select isMulti={false} {...selectArgs} />
                    {matches.length > 1 && (
                        <ButtonGroup
                            dataAid='select-match'
                            options={[
                                {
                                    label: '',
                                    iconProps: { name: 'arrowUp' },
                                    tooltip: 'Previous Match',
                                    onClick: onPrevMatch,
                                },
                                {
                                    label: '',
                                    iconProps: { name: 'arrowDown' },
                                    tooltip: 'Next Match',
                                    onClick: onNextMatch,
                                },
                            ]}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default JsonToolbar;
