import dayjs from 'dayjs';
import { DEFAULT_RANGES_VALUES } from '../../components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { isArray, isObject } from '../../utils/helpFunctions';
import { FilterConditionOperator } from '../custom/FilterTree/FilterCondition';
import { getHttpService, ICachingConfig, IHttpServiceConfig } from '../../interface/services';
import { AxiosRequestConfig } from 'axios';
import { generalApiError } from '../../utils/http';

const canConsiderAsNow = (date: dayjs.Dayjs) => {
    const now = dayjs();
    const diffFromNow = now.diff(date, 'seconds');
    return (diffFromNow < 60);
};
export const SECONDS_IN_DAY = 60 * 60 * 24;
const getDurationToSave = (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs): string | undefined => {
    const durationSeconds = toDate.diff(fromDate, 'seconds');
    if (durationSeconds !== (DEFAULT_RANGES_VALUES.ALL.count * SECONDS_IN_DAY)) {
        // Only duration that is not "ALL" duration is considered a filter
        return String(durationSeconds);
    }
    return undefined;
};
const CALCULATED_DURATION_FIELD_NAME = 'calculatedDuration';
const CREATED_TIME = 'createdTime';
export const payloadCacheFixer = (payload: any): any => {

    if (!payload) {
        return payload;
    }

    const newPayload: any = { ...payload };
    if (!newPayload.filter) {
        // In case filter is empty, we remove the filter field from the payload
        delete newPayload.filter;
        return newPayload;
    }

    if (isArray(newPayload.filter)) {
        if (!newPayload.filter.length) {
            // In case filter list is empty, we remove the filter field from the payload
            delete newPayload.filter;
            return newPayload;
        }

        const fields = payload.filter;
        const createdTimeField = fields.find((field: any) => field.name === CREATED_TIME);
        // In case filter is a list of fields with an item called 'createdTime', we might convert/remove it according to the following policy:
        // 1. We only consider to make a change if the field has a time-range that ends "now" (or close enough to now so we can consider it as now)
        // 2. In case it ends now, we convert the time range to duration and keep a new field with the duration instead of the time range
        // 3. There are cases when time-range is actually ALL. In those cases the duration will return as undefined, and we will simply remove the time-range field
        if (createdTimeField && (createdTimeField.operator === FilterConditionOperator.Between) && isArray(createdTimeField.values) && (createdTimeField.values.length === 2)) {
            const toDate = dayjs(createdTimeField.values[1]);
            if (canConsiderAsNow(toDate)) {
                // convert time-range to duration if "toDate" is now
                const fromDate = dayjs(createdTimeField.values[0]);
                const fieldsToSave = fields.filter((field: any) => field.name !== CREATED_TIME);
                const duration = getDurationToSave(fromDate, toDate);
                if (duration !== undefined) {
                    fieldsToSave.push({
                        name: CALCULATED_DURATION_FIELD_NAME,
                        value: duration,
                    });
                }

                if (fieldsToSave.length) {
                    newPayload.filter = fieldsToSave;
                } else {
                    delete newPayload.filter;
                }
                return newPayload;
            }
        }
        return newPayload;
    }

    if (isObject(newPayload.filter)) {
        if (isArray(newPayload.filter.fields) && (newPayload.filter.fields.length === 0)) {
            delete newPayload.filter.fields;
        }
        if (Object.keys(newPayload.filter).length === 0) {
            // In case filter map is empty, we remove the filter field from the payload
            delete newPayload.filter;
            return newPayload;
        }

        if (isObject(payload.filter.creationTime) && payload.filter.creationTime.from && payload.filter.creationTime.to) {
            // In case filter is a map-object with field 'creationTime', we might convert/remove that field.
            // See details regarding the convert/remove policy above
            const toDate = dayjs(payload.filter.creationTime.to);
            if (canConsiderAsNow(toDate)) {
                // convert time-range to duration if "toDate" is now
                const fromDate = dayjs(payload.filter.creationTime.from);
                const filterToSave: any = { ...payload.filter };
                delete filterToSave.creationTime;
                const duration = getDurationToSave(fromDate, toDate);
                if (duration !== undefined) {
                    filterToSave[CALCULATED_DURATION_FIELD_NAME] = duration;
                }

                if (Object.keys(filterToSave).length) {
                    newPayload.filter = filterToSave;
                } else {
                    delete newPayload.filter;
                }
            }
        }
        return newPayload;
    }

    return newPayload;
};

export enum CACHE_TIMOUTS {
    SHORT = 1 * 60,
    MEDIUM = 3 * 60,
    LONG = 6 * 60,
    VERY_LONG = 10 * 60,
}

export const sendHttpRequest = <T>(apiUrl: string, requestObject: AxiosRequestConfig, dataAgeLimit: number = CACHE_TIMOUTS.MEDIUM, tags?: string[]): Promise<T> => {
    return getHttpService().request<T>(apiUrl, requestObject, getCacheService(dataAgeLimit, tags), generalApiError);
};
export const sendHttpRequestNoCache = <T>(apiUrl: string, requestObject: AxiosRequestConfig): Promise<T> => {
    return getHttpService().request<T>(apiUrl, requestObject, { cachingConfig: { useCache: false } }, generalApiError);
};
export const getCacheConfig = (dataAgeLimit = CACHE_TIMOUTS.MEDIUM, tags?: string[]): ICachingConfig => {
    return {
        useCache: true,
        dataAgeLimit,
        preparePayloadForCache: payloadCacheFixer,
        tags,
    };
};
export const getCacheService = (dataAgeLimit = CACHE_TIMOUTS.MEDIUM, tags?: string[]): IHttpServiceConfig => {
    return {
        cachingConfig: getCacheConfig(dataAgeLimit, tags),
    };
};