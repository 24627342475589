import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useReturnUrl = () => {
    const [returnUrl, setReturnUrl] = useState<string>('');
    const location = useLocation();
    useEffect(() => {
        const querystring = new URLSearchParams(location.search);
        const returnUrl = querystring.get('returnUrl');
        setReturnUrl(returnUrl || '');
    }, [location]);
    return returnUrl;
};
