import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgProtectionSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.5 4.554V2.5c-2 0-4-1-5.5-2-1.5 1-3.5 2-5.5 2V9c0 4 5.5 6.5 5.5 6.5s.423-.193 1.035-.556"
      stroke="currentColor"
      strokeOpacity={0.9}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.561 6.555c.321-.873 1.557-.873 1.878 0l.12.329a1 1 0 0 0 1.11.64l.345-.06c.917-.158 1.535.912.939 1.627l-.225.268a1 1 0 0 0 0 1.281l.225.27c.596.714-.022 1.784-.94 1.625l-.344-.06a1 1 0 0 0-1.11.641l-.12.329c-.321.873-1.557.873-1.878 0l-.12-.329a1 1 0 0 0-1.11-.64l-.345.06c-.917.158-1.535-.912-.939-1.627l.225-.269a1 1 0 0 0 0-1.28l-.225-.27c-.596-.714.022-1.784.94-1.625l.344.06a1 1 0 0 0 1.11-.641l.12-.329Z"
      stroke="currentColor"
      strokeOpacity={0.9}
    />
    <path
      d="M12.846 9.386a1.5 1.5 0 1 1-2.772 1.148 1.5 1.5 0 0 1 2.772-1.148Z"
      stroke="currentColor"
      strokeOpacity={0.9}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgProtectionSettings);
export default ForwardRef;
