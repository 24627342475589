import { Modal } from 'common/design-system/components-v2';
import React, { useMemo } from 'react';
import { FullScreenModal } from './FullScreenModal.styled';

export interface IFullScreenModalComponentProps {
    modalTitle: string;
    isModalOpen: boolean;
    onClose: () => void;
    component: React.FC<any>;
    componentProps?: { [key: string]: any };
}

const FullScreenModalComponent: React.FC<IFullScreenModalComponentProps> = ({
    modalTitle,
    isModalOpen,
    onClose,
    component,
    componentProps,
}) => {
    const handleCloseModal = () => {
        onClose();
    };

    const content = useMemo(() => {
        const finalProps = {
            ...(componentProps || {}),
            isFullScreen: true,
        };
        return React.createElement(component, finalProps);
    }, [component, componentProps]);

    return (
        <Modal.ModalDialog
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={true}
            fullWidth={true}
        >
            <Modal.ModalHeader title={modalTitle} onClose={handleCloseModal} />
            <Modal.ModalContent fullHeight={true}>
                <FullScreenModal.ComponentWrapper>{content}</FullScreenModal.ComponentWrapper>
            </Modal.ModalContent>
        </Modal.ModalDialog>
    );
};

export default FullScreenModalComponent;
