import { FILTERS_API_OBJECT_KEYS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { EFilterComps, IExclusion, IGlobalStringObject } from '../exclusions.type';
import { buildFilterPropsByProperty, buildValueCountArray } from './utils';

export const getFilterPanelElementsList = (exclusions: IExclusion[], savedFilters?: IGlobalStringObject[]) => [
    {
        filterProps: { key: FILTERS_KEYS.ADD_FILTER },
        renderFunction: renderAddFilter,
    },
    {
        filterProps: {
            key: 'ruleSet',
            title: 'RuleSet',
            initialData: buildValueCountArray('ruleSet', exclusions),
            displayMapping: buildFilterPropsByProperty('ruleSet', exclusions),
        },
        renderFunction: renderMultiSelectFilter,
    },
    {
        filterProps: {
            key: 'environment',
            title: 'Environment/OU',
            initialData: buildValueCountArray('environment', exclusions),
            displayMapping: buildFilterPropsByProperty('environment', exclusions),
        },
        renderFunction: renderMultiSelectFilter,
    },
    {
        filterProps: {
            key: 'platform',
            title: 'Platform',
            initialData: buildValueCountArray('platform', exclusions),
            displayMapping: buildFilterPropsByProperty('platform', exclusions),
        },
        renderFunction: renderMultiSelectFilter,
    },
    {
        filterProps: {
            key: 'severities',
            title: 'Severity',
            initialData: buildValueCountArray('severities', exclusions),
            displayMapping: buildFilterPropsByProperty('severities', exclusions),
        },
        renderFunction: renderMultiSelectFilter,
    },
    {
        filterProps: {
            key: FILTERS_KEYS.CLEAR_BUTTON,
        },
        renderFunction: renderClearAll,
    },
    {
        filterProps: {
            key: FILTERS_KEYS.FREE_TEXT,
            title: 'Free Text',
        },
        keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
        renderFunction: renderDefaultFreeTextFilter,
    },
    {
        filterProps: {
            savedFilters: savedFilters ?? [],
            selectedFilterID: 'savedFilters',
            key: FILTERS_KEYS.SAVED_FILTERS,
            savedFiltersComponentName: EFilterComps.Save,
        },
        renderFunction: renderSavedFilters,
    },
];

export const getAggregations = async (): Promise<Aggregations> => {
    return {};
};
