import { Input } from 'common/design-system/components-v2';
import { IDashboardWidget, IGenericWidgetSettingsProps } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

const SummaryWidgetsSettings: React.FC<IGenericWidgetSettingsProps> = ({ widget, updateWidgetCallback }) => {
    const { t } = useTranslation('overview');
    // Deep clone the widget to avoid mutating the original.
    const initializeWidgetClone = () => {
        const clonedWidget = deepCloneObject(widget);
        clonedWidget.options = clonedWidget.options || { summarySubtitle: '' };
        clonedWidget.options.thresholds = clonedWidget.options.thresholds || [
            { color: '#000000', min: 0, max: 1 },
            { color: '#56b40a', min: 2, max: false },
        ];
        return clonedWidget;
    };

    const [widgetClone, setWidgetClone] = useState<IDashboardWidget>(initializeWidgetClone);
    const [moreThanNumber, setMoreThanNumber] = useState<number>(widgetClone?.options?.thresholds?.[0]?.max || 1);
    const [moreThanColor, setMoreThanColor] = useState<string>(
        widgetClone?.options?.thresholds?.[0]?.color || '#000000',
    );
    const [lessThanColor, setLessThanColor] = useState<string>(
        widgetClone?.options?.thresholds?.[1]?.color || '#56b40a',
    );

    const updateWidget = (updatedWidget: IDashboardWidget) => {
        setWidgetClone(updatedWidget); // Update the local state
        updateWidgetCallback(updatedWidget); // Notify parent with updated widget
    };

    const handleChangeColor = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const newColor = event.target.value;
        const updatedWidget = deepCloneObject(widgetClone);

        if (updatedWidget.options.thresholds?.[index]) {
            updatedWidget.options.thresholds[index].color = newColor;
        }

        if (index === 0) setMoreThanColor(newColor);
        if (index === 1) setLessThanColor(newColor);

        updateWidget(updatedWidget);
    };

    const handleChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
        const newMax = event.target.valueAsNumber;
        const updatedWidget = deepCloneObject(widgetClone);

        updatedWidget.options.thresholds[0].max = newMax;
        updatedWidget.options.thresholds[1].min = newMax + 1;

        setMoreThanNumber(newMax);
        updateWidget(updatedWidget);
    };

    useEffect(() => {
        const thresholds = widgetClone.options.thresholds || [];
        setMoreThanColor(thresholds[0]?.color || '#000000');
        setLessThanColor(thresholds[1]?.color || '#56b40a');
    }, [widgetClone]);

    const defaultColors = ['#56B40A', '#B1DBFF', '#FFD546', '#FFB72D', '#FF9126', '#F24E4E', '#E45785'];
    const colors = widgetClone.options.thresholds?.[0]?.colorPlate || defaultColors;
    colors.unshift('#000000');

    return (
        <div>
            <div className='font-semibold'>{t('WIDGETS.THRESHOLDS', { ns: 'overview' })}</div>
            <div className='flex'>
                <div className='flex-1 mr-10'>
                    <div className='flex justify-between items-center mt-6 mr-6'>
                        <div className='flex-1'>
                            <input
                                value={moreThanColor}
                                className='w-[50px]'
                                onChange={(option: any) => handleChangeColor(option, 0)}
                                type='color'
                                list='presets'
                            ></input>
                            <datalist id='presets'>
                                {colors.map((color) => (
                                    <option key={v4()} value={color}>
                                        {color}
                                    </option>
                                ))}
                            </datalist>
                        </div>
                        <div className='flex-1 mx-4 '>0</div>
                        <div className='flex-1 mx-2'>-</div>
                        <div className='flex-1 z-0 max-w-[100px]'>
                            <Input
                                type='number'
                                onChange={handleChangeNumber}
                                value={moreThanNumber?.toString() || '0'}
                                fullWidth
                                min={0}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-1'>
                    <div className='flex justify-between items-center mt-6'>
                        <div className='flex-1'>
                            <input
                                value={lessThanColor}
                                className='w-[50px]'
                                onChange={(option: any) => handleChangeColor(option, 1)}
                                type='color'
                                list='presets'
                            ></input>
                            <datalist id='presets'>
                                {colors.map((color) => (
                                    <option key={v4()} value={color}>
                                        {color}
                                    </option>
                                ))}
                            </datalist>
                        </div>
                        <div className='flex-1 z-0 w-[120px] text-right'>{moreThanNumber + 1}</div>
                        <div className='flex-1 mx-4'>-</div>
                        <div className='flex-1  ml-6 text-right'>{t('WIDGETS.INFINITY', { ns: 'overview' })}</div>
                    </div>
                </div>
            </div>
            <div className='font-semibold mb-2 mt-6'>{t('DASHBOARD.SUBTITLE', { ns: 'overview' })}</div>
        </div>
    );
};

export default SummaryWidgetsSettings;
