import { Context } from 'common/design-system/theme/colors/colors.types';
import styled, { css } from 'styled-components';

const getStylesBySize = (size: IWrapperProps['size']) => {
    switch (size) {
        case 'xs':
            return {
                size: 16,
                paddingSpacing: 0.5,
                borderRadiusSpacing: 1,
            };
        case 'sm':
            return {
                size: 24,
                paddingSpacing: 1,
                borderRadiusSpacing: 1.5,
            };
        case 'md':
            return {
                size: 32,
                paddingSpacing: 2,
                borderRadiusSpacing: 2,
            };
        case 'lg':
            return {
                size: 40,
                paddingSpacing: 2.5,
                borderRadiusSpacing: 2.5,
            };
        case 'xl':
            return {
                size: 48,
                paddingSpacing: 3,
                borderRadiusSpacing: 3,
            };
        case '2xl':
            return {
                size: 64,
                paddingSpacing: 4,
                borderRadiusSpacing: 3,
            };
        case '3xl':
            return {
                size: 96,
                paddingSpacing: 6,
                borderRadiusSpacing: 4,
            };
    }
};

interface IWrapperProps {
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
    color: string;
    backgroundColor: string;
    circleShape?: boolean;
    withBorder?: boolean;
    context?: Context;
}

const Wrapper = styled.div<IWrapperProps>`
    color: ${({ color }) => color};
    background-color: ${({ backgroundColor }) => backgroundColor};

    border-radius: ${({ theme, size }) =>
        `${theme.border.radius(getStylesBySize(size).borderRadiusSpacing)}${theme.units}`};
    padding: ${({ theme, size }) => `${theme.spacing(getStylesBySize(size).paddingSpacing)}${theme.units}`};
    height: ${({ theme, size }) => `${getStylesBySize(size).size}${theme.units}`};
    width: ${({ theme, size }) => `${getStylesBySize(size).size}${theme.units}`};
    min-height: ${({ theme, size }) => `${getStylesBySize(size).size}${theme.units}`};
    min-width: ${({ theme, size }) => `${getStylesBySize(size).size}${theme.units}`};

    ${({ circleShape }) =>
        circleShape &&
        css`
            border-radius: 50%;
        `};

    ${({ withBorder, theme }) =>
        withBorder &&
        css`
            margin: 1px;
            outline: ${theme.border.width.standard}${theme.units} solid ${theme.palette.onSurface.reverse};
        `}
    ${({ theme, context }) =>
        context &&
        css`
            color: ${theme.getForegroundColor({ context, saturation: true, strength: 'regular' })};
            background-color: ${theme.palette[context].backgrounds.strength.weaker};
        `};
`;

const LevelIconStyles = {
    Wrapper,
};

export default LevelIconStyles;
