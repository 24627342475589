import styled from 'styled-components';
import { Width } from './ModalDialog.types';

interface IOveralyProps {
    customZIndex?: number;
}
export const Overaly = styled.div<IOveralyProps>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${({ theme }) => theme.palette.onSurface.overlay};
    border-radius: ${({ theme }) => theme.border.radius(2)}px;
    backdrop-filter: blur(3px);
    opacity: 0;
    transition-property: background-color, opacity;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${({ customZIndex }) => customZIndex || 999};
    &.overlay-after {
        opacity: 1;
    }
    &.overlay-before {
        opacity: 0;
    }
`;

const getModalWidth = (width: Width) => {
    switch (width) {
        case 'sm':
            return 400;
        case 'md':
            return 512;
        case 'lg':
            return 800;
        case 'xl':
            return 960;
        case 'xxl':
            return 1200;
    }
};

interface IContentProps {
    width: Width;
    fullWidth?: boolean;
}
export const Content = styled.div<IContentProps>`
    background-color: ${({ theme }) => theme.palette.surface.primary};
    outline: 0;
    max-height: 90%;
    transition: all 500ms ease-in-out;
    transform: translateY(100px);
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows[2]};
    max-width: ${({ width, theme, fullWidth }) => (fullWidth ? '100%' : `${getModalWidth(width)}${theme.units}`)};
    &.content-after {
        transform: translateY(-0px);
    }
    &.content-before {
        background-color: transparent;
        transform: translateY(0);
    }
    &.modal {
        width: 90%;
    }
`;
