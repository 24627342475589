import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { IRowNode } from 'ag-grid-community/dist/lib/interfaces/iRowNode';
import { SelectorStyled } from './MultiSelector.styled';
import { IMultiSelectorItem, IMultiSelectorItemsMap, IMultiSelectorSettings } from './MultiSelector.interface';
import {
    calcTreeItemsOnDeselection,
    calcTreeItemsOnSelection,
    getHighLevelItems,
    getLowLevelItems,
    getMatchingItems,
    getSemiSelectedIdsMap,
    ignoreEvent,
    selectorItemsToMap,
} from './MultiSelector.utils';
import { MultiSelectorItemCellRenderer } from './MultiSelectorItemCellRenderer';

const MultiSelector: React.FC<{
    allItems: IMultiSelectorItem[];
    filter?: string;
    highLevelSelectedItems?: IMultiSelectorItem[];
    nonSelectableIds?: string[];
    onSelectionChanged: (selItems: IMultiSelectorItem[]) => void;
    settings?: IMultiSelectorSettings;
    isTree: boolean;
    nonSelectableReason?: string;
}> = ({
    allItems,
    filter,
    highLevelSelectedItems = [],
    nonSelectableIds,
    onSelectionChanged,
    settings,
    isTree,
    nonSelectableReason,
}) => {
    const forceSelectionRef = useRef<boolean>(false);
    const [gridApi, setGridApi] = useState<GridApi>();
    const selectedItems = useMemo(
        () => (isTree ? getLowLevelItems(highLevelSelectedItems, allItems) : highLevelSelectedItems),
        [allItems, highLevelSelectedItems, isTree],
    );
    const selectedItemsMap: IMultiSelectorItemsMap = useMemo(() => selectorItemsToMap(selectedItems), [selectedItems]);
    const semiSelectedIdsMap: IMultiSelectorItemsMap = useMemo(
        () => getSemiSelectedIdsMap(allItems, selectedItemsMap),
        [allItems, selectedItemsMap],
    );
    const nonSelectableIdsSet: Set<string> | undefined = useMemo(
        () => (nonSelectableIds ? new Set<string>(nonSelectableIds) : undefined),
        [nonSelectableIds],
    );

    const onGridReady = useCallback((params: GridReadyEvent) => {
        setGridApi(params.api);
    }, []);

    const getDataPath = useCallback((data: IMultiSelectorItem) => data.labelsPath, []);

    const markSelectedNodes = (api: GridApi, selItems: IMultiSelectorItem[]) => {
        const selMap = selectorItemsToMap(selItems);
        const selected: IRowNode<IMultiSelectorItem>[] = [];
        const deselected: IRowNode<IMultiSelectorItem>[] = [];
        api.forEachNode((rowNode: IRowNode<IMultiSelectorItem>) => {
            const itemId = rowNode.data?.id;
            if (itemId && selMap[itemId]) {
                selected.push(rowNode);
            } else {
                deselected.push(rowNode);
            }
        });
        api.setNodesSelected({ nodes: selected, newValue: true });
        api.setNodesSelected({ nodes: deselected, newValue: false });
    };

    const onRowSelected = useCallback(
        (rowEvent: RowSelectedEvent<IMultiSelectorItem>) => {
            if (!rowEvent.event) {
                return;
            }
            const item = rowEvent.node.data;
            if (!item) {
                return;
            }
            if (rowEvent.node.isSelected()) {
                if (!selectedItemsMap[item.id]) {
                    if (isTree) {
                        const enrichedItems = calcTreeItemsOnSelection(item, selectedItems, allItems);
                        onSelectionChanged(getHighLevelItems(enrichedItems, allItems));
                    } else {
                        const selItems = [...selectedItems, item];
                        onSelectionChanged(selItems);
                    }
                }
            } else {
                if (selectedItemsMap[item.id]) {
                    if (isTree) {
                        const reducedItems = calcTreeItemsOnDeselection(item, selectedItems, allItems);
                        onSelectionChanged(getHighLevelItems(reducedItems, allItems));
                    } else {
                        const selItems = selectedItems.filter((oldItem: IMultiSelectorItem) => oldItem.id !== item.id);
                        onSelectionChanged(selItems);
                    }
                }
            }
        },
        [selectedItemsMap, isTree, selectedItems, allItems, onSelectionChanged],
    );

    const isRowSelectable = useCallback(
        (node: IRowNode<IMultiSelectorItem>) => {
            return node.data?.id && !nonSelectableIdsSet?.has(node.data?.id);
        },
        [nonSelectableIdsSet],
    );

    const onRowDataUpdated = useCallback(() => {
        if (gridApi && forceSelectionRef.current) {
            forceSelectionRef.current = false;
            markSelectedNodes(gridApi, selectedItems);
        }
    }, [gridApi, selectedItems]);

    useEffect(() => {
        const applyFilter = (filterText?: string) => {
            if (!gridApi) {
                return;
            }
            const matchingItems = getMatchingItems(allItems, filterText);
            forceSelectionRef.current = true;
            gridApi.setRowData(matchingItems);
        };

        if (gridApi) {
            applyFilter(filter);
        }
    }, [gridApi, filter, allItems]);

    useEffect(() => {
        if (gridApi) {
            markSelectedNodes(gridApi, selectedItems);
        }
    }, [gridApi, selectedItems]);

    const gridOptions = useMemo(() => {
        const commonOptions: any = {
            isRowSelectable,
            onRowSelected,
            rowHeight: 32,
            treeData: isTree,
            rowSelection: 'multiple',
            onRowDataUpdated,
            rowMultiSelectWithClick: true,
            onGridReady,
            sideBar: null,
            headerHeight: 0,
            suppressContextMenu: true,
            rowBuffer: 5,
        };

        if (isTree) {
            return {
                ...commonOptions,
                domLayout: 'autoHeight',
                defaultColDef: { checkboxSelection: true, flex: 1 },
                getDataPath: getDataPath,
                columnDefs: [],
                className: 'ag-theme-alpine ag-header-none ag-tree-data',
                groupDefaultExpanded: 1,
                autoGroupColumnDef: {
                    cellRendererParams: {
                        suppressCount: true,
                        innerRenderer: MultiSelectorItemCellRenderer,
                        semiSelectedIdsMap,
                        selectedItemsMap,
                        nonSelectableIdsSet,
                        nonSelectableReason,
                    },
                },
            };
        }

        return {
            ...commonOptions,
            defaultColDef: {
                checkboxSelection: true,
                flex: 1,
                cellRenderer: MultiSelectorItemCellRenderer,
                cellRendererParams: {
                    semiSelectedIdsMap,
                    selectedItemsMap,
                    nonSelectableIdsSet,
                    nonSelectableReason,
                },
            },
            columnDefs: [{}],
            className: 'ag-theme-alpine ag-header-none',
        };
    }, [
        getDataPath,
        isRowSelectable,
        isTree,
        nonSelectableIdsSet,
        nonSelectableReason,
        onGridReady,
        onRowDataUpdated,
        onRowSelected,
        selectedItemsMap,
        semiSelectedIdsMap,
    ]);

    return (
        <SelectorStyled.TopDiv options={settings} onMouseDown={ignoreEvent}>
            <AgGridReact {...gridOptions} />
        </SelectorStyled.TopDiv>
    );
};

export default MultiSelector;
