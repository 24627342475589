import styled, { DefaultTheme, Interpolation, css } from 'styled-components';
import { getTextContrastColor, ColorsWithState } from '../../theme/theme';
import Typography from '../Typography';
import { ButtonSize, ButtonVariants } from './Button.types';
import { Context } from 'common/design-system/theme/colors/colors.types';

const resolvePadding = (theme: DefaultTheme, size: ButtonSize, iconButton: boolean, removePadding?: boolean) => {
    if (removePadding) return 0;
    if (iconButton) {
        switch (size) {
            case 'small':
                return `${theme.spacing(0.5)}${theme.units}`;
            case 'medium':
                return `${theme.spacing(1) + 1}${theme.units}`;
        }
    }

    switch (size) {
        case 'small':
            return `${theme.spacing(1) - 1}${theme.units}`;
        case 'medium':
            return `${theme.spacing(2) - 1}${theme.units} ${theme.spacing(4) - 1}${theme.units}`;
        case 'large':
            return `${theme.spacing(4) - 1}${theme.units}`;
    }
};

type type = 'background' | 'foreground' | 'border';
type state = 'default' | 'hover' | 'focus';

const resolveColor = (
    theme: DefaultTheme,
    variant: ButtonVariants,
    type: type,
    state: state,
    isActive: boolean,
    color: ColorsWithState,
    context?: Context,
): string => {
    if (!context) {
        switch (variant) {
            case 'contained': {
                switch (type) {
                    case 'background': {
                        if (isActive) {
                            return theme.palette.surfaceStates[`${color}Active`];
                        }
                        switch (state) {
                            case 'default':
                                return theme.palette.surface[color];
                            case 'hover':
                                return theme.palette.surfaceStates[`${color}Hover`];
                            case 'focus':
                                return theme.palette.surfaceStates[`${color}Focus`];
                        }
                        break;
                    }
                    case 'foreground': {
                        return getTextContrastColor(
                            resolveColor(theme, variant, 'background', state, isActive, color, context),
                            theme,
                        );
                    }
                    case 'border': {
                        switch (state) {
                            case 'focus': {
                                return theme.palette.onSurface.reverse;
                            }
                            default: {
                                return resolveColor(theme, variant, 'background', state, isActive, color, context);
                            }
                        }
                    }
                }
                break;
            }
            case 'text': {
                switch (type) {
                    case 'background': {
                        if (isActive) {
                            if (state === 'hover') {
                                return theme.palette.componentStates.ghostDoubleActive;
                            }
                            return theme.palette.componentStates.ghostActive;
                        }
                        switch (state) {
                            case 'default':
                                return 'transparent';
                            case 'hover':
                                return theme.palette.componentStates.ghostHover;
                            case 'focus':
                                return theme.palette.componentStates.ghostFocus;
                        }
                        break;
                    }
                    case 'foreground': {
                        return theme.palette.onSurface[color];
                    }
                    case 'border': {
                        return 'transparent';
                    }
                }
                break;
            }
            case 'outlined':
            case 'wired': {
                switch (type) {
                    case 'background': {
                        return resolveColor(theme, 'text', 'background', state, isActive, color, context);
                    }
                    case 'foreground': {
                        return resolveColor(theme, 'text', 'foreground', state, isActive, color, context);
                    }
                    case 'border': {
                        if (isActive) {
                            return theme.palette.surfaceStates[`${color}Active`];
                        }
                        switch (state) {
                            case 'default':
                                return resolveColor(theme, variant, 'foreground', 'default', isActive, color, context);
                            case 'hover':
                                return theme.palette.surfaceStates[`${color}Hover`];
                            case 'focus':
                                return theme.palette.onSurface.reverse;
                        }
                        break;
                    }
                }
                break;
            }
        }
    }

    if (context) {
        switch (variant) {
            case 'contained': {
                switch (type) {
                    case 'background': {
                        if (isActive) {
                            return theme.palette[context].backgrounds.strength.accentStrongest;
                        }
                        switch (state) {
                            case 'default': {
                                return theme.palette[context].backgrounds.strength.accentRegular;
                            }
                            case 'hover': {
                                return theme.palette[context].backgrounds.strength.accentStrong;
                            }
                            case 'focus': {
                                return theme.palette[context].backgrounds.strength.accentStronger;
                            }
                        }
                        break;
                    }
                    case 'foreground': {
                        return theme.palette[context].foregrounds.invertedStrong;
                    }
                    case 'border': {
                        switch (state) {
                            case 'focus': {
                                return theme.palette.onSurface.reverse;
                            }
                            default: {
                                return resolveColor(theme, variant, 'background', state, isActive, color, context);
                            }
                        }
                    }
                }
                break;
            }
            case 'text': {
                switch (type) {
                    case 'background': {
                        if (isActive) {
                            return theme.palette[context].backgrounds.strength.weaker;
                        }
                        switch (state) {
                            case 'default':
                                return 'transparent';
                            case 'hover':
                                return theme.palette[context].backgrounds.strength.weakest;
                            case 'focus':
                                return theme.palette[context].backgrounds.strength.weakest;
                        }
                        break;
                    }
                    case 'foreground': {
                        return theme.palette[context].foregrounds.regular.default;
                    }
                    case 'border': {
                        return 'transparent';
                    }
                }
                break;
            }
            case 'outlined': {
                switch (type) {
                    case 'background': {
                        return resolveColor(theme, 'text', 'background', state, isActive, color, context);
                    }
                    case 'foreground': {
                        return theme.palette[context].foregrounds.regular.saturation;
                    }
                    case 'border': {
                        return theme.palette[context].foregrounds.regular.saturation;
                    }
                }
                break;
            }
            case 'wired': {
                switch (type) {
                    case 'background': {
                        if (isActive) {
                            return theme.palette[context].backgrounds.strength.weak;
                        }
                        switch (state) {
                            case 'default':
                                return theme.palette[context].backgrounds.strength.weakest;
                            case 'hover':
                                return theme.palette[context].backgrounds.strength.weaker;
                            case 'focus':
                                return theme.palette[context].backgrounds.strength.weaker;
                        }
                        break;
                    }
                    case 'foreground': {
                        return resolveColor(theme, 'text', 'foreground', state, isActive, color, context);
                    }
                    case 'border': {
                        return theme.palette[context].borders.regular;
                    }
                }
                break;
            }
        }
    }

    return '';
};

const getBulkColors = (
    theme: DefaultTheme,
    variant: ButtonVariants,
    state: state,
    isActive: boolean,
    color: ColorsWithState,
    context?: Context,
): Interpolation<object> => {
    return css`
        color: ${resolveColor(theme, variant, 'foreground', state, isActive, color, context)};
        background: ${resolveColor(theme, variant, 'background', state, isActive, color, context)};
        border-color: ${resolveColor(theme, variant, 'border', state, isActive, color, context)};
    `;
};

interface IWrapperProps {
    disabled?: boolean;
    variant: ButtonVariants;
    color: ColorsWithState;
    size: ButtonSize;
    iconButton: boolean;
    active?: boolean;
    disableHoverEffect?: boolean;
    fullWidth?: boolean;
    removePadding?: boolean;
    context?: Context;
}
const Wrapper = styled.button<IWrapperProps>`
    width: 100%;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    padding: ${({ theme, size, iconButton, removePadding }) => resolvePadding(theme, size, iconButton, removePadding)};
    gap: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    border-width: ${({ theme }) => `${theme.border.width.standard}${theme.units}`};
    border-style: solid;
    outline: none;

    & svg {
        color: currentColor;
    }

    ${({ theme, variant, color, context, active, disabled }) => css`
        ${getBulkColors(theme, variant, 'default', false, color, context)}

        ${!disabled &&
        css`
            &:hover {
                ${getBulkColors(theme, variant, 'hover', false, color, context)}
            }
            &&:focus-visible {
                ${getBulkColors(theme, variant, 'focus', false, color, context)}
                box-shadow: ${theme.shadows.focus};
            }
            &&:active {
                ${getBulkColors(theme, variant, 'default', true, color, context)}
            }
            ${active &&
            css`
                ${getBulkColors(theme, variant, 'default', true, color, context)}
            `}
        `}
    `}

    ${({ theme, disabled }) =>
        disabled &&
        css`
            box-shadow: ${theme.shadows[0]};
            opacity: ${theme.disabledOpacity};
            cursor: not-allowed;
        `}

  ${({ disableHoverEffect }) =>
        disableHoverEffect &&
        css`
            &:hover {
                background: transparent;
            }
        `}
`;

const Label = styled(Typography)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const ButtonStyles = {
    Wrapper,
    Label,
};

export default ButtonStyles;
