import styled, { css } from 'styled-components';

const TwinWidgetDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

const TitleActionDiv = styled.div`
    display: flex;
    cursor: pointer;
    & > svg {
        outline: none;
    }
`;

const ContentDiv = styled.div<{ isTwin: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 45px;
    height: 100%;
    width: ${(props) => (props.isTwin ? '50%' : '100%')};
    overflow: hidden;
    align-items: end;
`;

const SpinnerDiv = styled.div`
    display: flex;
    align-items: end;
    padding: 10px 5px;
`;

const BodyDiv = styled.div`
    display: flex;
    align-items: center;
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    align-items: center;
`;

const CounterDiv = styled.div<{ isTwin: boolean; isClickable: boolean }>`
    font-size: 16px;

    ${(props) =>
        props.isClickable &&
        css`
            &:hover {
                text-decoration-line: underline;
                cursor: pointer;
            }
        `};

    @media (min-width: 1100px) {
        font-size: ${(props) => (props.isTwin ? '16px' : '26px')};
        padding-bottom: 0;
        ${(props) =>
            props.isClickable &&
            css`
                &:hover {
                    text-decoration-line: underline;
                    text-decoration-thickness: ${props.isTwin ? '1px' : '2px'};
                    cursor: pointer;
                }
            `};
    }
`;

export const AssetWidgetStyled = {
    TwinWidgetDiv,
    ContentDiv,
    BodyDiv,
    TextDiv,
    CounterDiv,
    TitleActionDiv,
    SpinnerDiv,
};
