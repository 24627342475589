import { changeUrl, generateHref } from 'common/utils/http';
import React, { useEffect, useState } from 'react';
import { getVendor } from 'common/consts/vendors';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { getCloudAccountsService } from 'common/interface/data_services';
import { CloudAccount } from 'common/interface/environments';
import i18next from 'i18next';
import { logComponentClickedEvent } from '../../../utils/logUtils';
import { getEnvironmentDisplayNameById } from '../AssetUtils';

export interface EnvironmentLinkProps {
    cloudAccountId: string;
    onDisplayTextChanged?: (text: string) => void;
    containerName?: string;
    isIcon?: boolean;
}

const EnvironmentLink: React.FC<EnvironmentLinkProps> = ({
    cloudAccountId,
    onDisplayTextChanged,
    containerName,
    isIcon = true,
}) => {
    const [cloudAccount, setCloudAccount] = useState<CloudAccount | null>(null);
    const [displayText, setDisplayText] = useState<string>(i18next.t('COMMON.PROTECTED_ASSETS_TABLE.LOADING'));
    const [isShowLink, setIsShowLink] = useState(false);
    useEffect(() => {
        async function updateDisplayValue() {
            const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId);
            setCloudAccount(cloudAccount);
            const environmentValue = await getEnvironmentDisplayNameById(cloudAccountId);
            setIsShowLink(environmentValue !== null);
            let tempDisplayText;
            if (environmentValue === null) {
                tempDisplayText = i18next.t('COMMON.PROTECTED_ASSETS_TABLE.NO_DATA');
            } else {
                tempDisplayText = environmentValue;
            }
            setDisplayText(tempDisplayText);

            if (onDisplayTextChanged) {
                onDisplayTextChanged(tempDisplayText);
            }
        }

        updateDisplayValue();
    }, [cloudAccountId, onDisplayTextChanged]);

    if (!isShowLink || cloudAccount === null) {
        return <div>{displayText}</div>;
    }

    const platform = getVendor(cloudAccount.platform);
    if (platform === null) {
        return <></>;
    }

    const url = `/cloud-account/${platform.uri}/${cloudAccountId}`;
    const options: IIconLinkCellProps = {
        iconProps: isIcon ? { vendorNameOrPath: platform.icon } : undefined,
        isLink: true,
        value: displayText ?? '',
        href: generateHref(url),
        onClick: (event: any) => {
            if (platform && displayText) {
                event.preventDefault();
                if (containerName) {
                    logComponentClickedEvent(containerName, { field: 'environment' });
                }
                changeUrl(url);
            }
        },
    };

    return <IconLinkCellRenderer {...options} />;
};

export default EnvironmentLink;
