import React from 'react';
import Link from 'common/erm-components/basic/Link/Link';
import { ICellRendererParams } from 'ag-grid-enterprise';

export const ExternalLinkRenderer: React.FC<ICellRendererParams> = (params) => {
    if (params.value === undefined) {
        return null;
    }
    const { url, label } = params.value;
    return <Link externalUrl={url} textProps={{ text: label }} />;
};

export default ExternalLinkRenderer;
