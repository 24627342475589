import { getLoggerService } from '../interface/services';
import { URL_PREFIX } from './history';
import { DebugActiveFeaturesMap, isAfEnabled } from './debugUtils';

enum LOG_EVENT_TYPES {
    PAGE_ENTERED = 'pageEntered',
    COMPONENT_CLICKED = 'componentClicked',
    URL_CHANGED = 'urlChanged',
}

interface IUrlInfo {
    urlPath: string;
    urlSearch: string;
}

export const UNKNOWN_LOG_VALUE = '(unknown)';
const getCurrentUrlInfo = (): IUrlInfo => {
    const re = new RegExp(`^${URL_PREFIX}`);
    return {
        urlPath: window?.location?.pathname?.replace(re, ''),
        urlSearch: window?.location?.search?.replace(/^\?/, ''),
    };
};

export const logEvent = (eventType: string, data?: Object) => {
    const urlInfo = getCurrentUrlInfo();
    void getLoggerService().info({
        eventType,
        urlPath: urlInfo.urlPath,
        urlSearch: urlInfo.urlSearch,
        ...(data || {}),
    });
    if (isAfEnabled(DebugActiveFeaturesMap.SHOW_URL_LOG_ACTIVE_FEATURE.key)) {
        const decodedUrl =
            decodeURIComponent(window?.location?.pathname || '') + decodeURIComponent(window?.location?.search || '');
        console.info('URL Info: ', decodedUrl);
    }
};

export const logComponentClickedEvent = (componentName: string, data?: Object) => {
    logEvent(LOG_EVENT_TYPES.COMPONENT_CLICKED, {
        componentName,
        ...(data || {}),
    });
};
export const logPageEnteredEvent = () => {
    logEvent(LOG_EVENT_TYPES.PAGE_ENTERED);
};

export const logUrlChangedEvent = () => {
    logEvent(LOG_EVENT_TYPES.URL_CHANGED);
};

export const logAndRunOnComponentClick = (onClick: Function, componentName?: string) => {
    if (componentName) {
        logComponentClickedEvent(componentName);
    }
    onClick();
};
