import { getAccountService, getUserService } from '../interface/services';
import { USER_PERMISSION_TYPES } from '../consts/UserPermissions';

const getUserInfo = () => getUserService().getUser();

const getIntegrationUserPermission = (): USER_PERMISSION_TYPES => {
    const user = getUserInfo();
    if (
        user?.calculatedPermissions?.manage?.length ||
        user?.calculatedPermissions?.notifications?.length ||
        user?.isSuperUser ||
        user?.isOwner
    ) {
        return USER_PERMISSION_TYPES.READ_WRITE;
    }
    if (user?.calculatedPermissions?.view?.length || user?.calculatedPermissions?.policies?.length || user?.isAuditor) {
        return USER_PERMISSION_TYPES.READ_ONLY;
    }
    return USER_PERMISSION_TYPES.DISABLED;
};
export const integrationNotificationEnabled = () => getIntegrationUserPermission() !== USER_PERMISSION_TYPES.DISABLED;
export const integrationNotificationReadOnly = () => getIntegrationUserPermission() === USER_PERMISSION_TYPES.READ_ONLY;

const getPolicyUserPermission = (): USER_PERMISSION_TYPES => {
    const user = getUserInfo();
    if (
        user?.calculatedPermissions?.manage?.length ||
        user?.calculatedPermissions?.policies?.length ||
        user?.isSuperUser ||
        user?.isOwner
    ) {
        return USER_PERMISSION_TYPES.READ_WRITE;
    }
    if (
        user?.calculatedPermissions?.notifications?.length ||
        user?.calculatedPermissions?.view?.length ||
        user?.isAuditor
    ) {
        return USER_PERMISSION_TYPES.READ_ONLY;
    }
    return USER_PERMISSION_TYPES.DISABLED;
};
export const isPolicyEnabled = () => getPolicyUserPermission() !== USER_PERMISSION_TYPES.DISABLED;

const getRulesetPermission = (): USER_PERMISSION_TYPES => {
    const user = getUserInfo();
    if (
        user?.calculatedPermissions?.manage?.length ||
        user?.calculatedPermissions?.rulesets?.length ||
        user?.isSuperUser ||
        user?.isOwner
    ) {
        return USER_PERMISSION_TYPES.READ_WRITE;
    }
    if (
        user?.calculatedPermissions?.view?.length ||
        user?.calculatedPermissions?.policies?.length ||
        user?.calculatedPermissions?.alertActions?.length ||
        user?.isAuditor
    ) {
        return USER_PERMISSION_TYPES.READ_ONLY;
    }
    return USER_PERMISSION_TYPES.DISABLED;
};
export const isRulesetEnabled = () => getRulesetPermission() !== USER_PERMISSION_TYPES.DISABLED;

const getPermissions = (): USER_PERMISSION_TYPES => {
    const user = getUserInfo();
    if (
        user?.calculatedPermissions?.manage?.length ||
        user?.calculatedPermissions?.alertActions?.length ||
        user?.isSuperUser ||
        user?.isOwner
    ) {
        return USER_PERMISSION_TYPES.READ_WRITE;
    }
    if (user?.calculatedPermissions?.view?.length || user?.isAuditor) {
        return USER_PERMISSION_TYPES.READ_ONLY;
    }
    return USER_PERMISSION_TYPES.DISABLED;
};

const getRemediationPermission = (): USER_PERMISSION_TYPES => {
    return getPermissions();
};
export const isRemediationEnabled = () => getRemediationPermission() !== USER_PERMISSION_TYPES.DISABLED;

const getExclusionsPermission = (): USER_PERMISSION_TYPES => {
    return getPermissions();
};
export const isExclusionsEnabled = () => getExclusionsPermission() !== USER_PERMISSION_TYPES.DISABLED;

const getCredentialsPermission = (): USER_PERMISSION_TYPES => {
    const user = getUserInfo();
    if (user?.calculatedPermissions?.manage?.length || user?.isSuperUser || user?.isOwner) {
        return USER_PERMISSION_TYPES.READ_WRITE;
    }
    if (
        user?.calculatedPermissions?.view?.length ||
        user?.calculatedPermissions?.notifications?.length ||
        user?.calculatedPermissions?.policies?.length ||
        user?.calculatedPermissions?.rulesets?.length ||
        user?.calculatedPermissions?.alertActions?.length ||
        user?.calculatedPermissions?.onBoarding?.length ||
        user?.isAuditor
    ) {
        return USER_PERMISSION_TYPES.READ_ONLY;
    }
    return USER_PERMISSION_TYPES.DISABLED;
};
export const isCredentialsEnabled = () => getCredentialsPermission() !== USER_PERMISSION_TYPES.DISABLED;

export const isSpectralEnabled = (): boolean => {
    const user = getUserInfo();
    const managePermissions =
        user?.calculatedPermissions?.manage?.find((item: string) => item.startsWith('210') || item === '') !==
            undefined ||
        user?.isSuperUser ||
        user?.isOwner;
    const viewPermissions =
        user?.calculatedPermissions?.view?.find((item: string) => item.startsWith('210') || item === '') !==
            undefined || user?.isAuditor;
    return managePermissions || viewPermissions;
};

export const checkAnyPermissions = () => {
    const user = getUserInfo();
    return (
        Object.values(user?.calculatedPermissions || {}).some((arr) => arr.length > 0) ||
        user?.isSuperUser ||
        user?.isOwner ||
        user?.isAuditor
    );
};

const getNotificationAssociations = (): USER_PERMISSION_TYPES => {
    const user = getUserInfo();
    const hasNotificationAssociations = (permissions: { [key: string]: string[] }): boolean => {
        const managePermissions = permissions?.manage?.find((item: string) => item.startsWith('200') || item === '');
        const viewPermissions = permissions?.view?.find((item: string) => item.startsWith('200') || item === '');
        const policyPermissions = permissions?.policies?.length;
        return !!(managePermissions || viewPermissions || policyPermissions);
    };

    if (
        hasNotificationAssociations(user?.calculatedPermissions) ||
        user?.isSuperUser ||
        user?.isOwner ||
        user?.isAuditor
    ) {
        return USER_PERMISSION_TYPES.READ_WRITE;
    }

    return USER_PERMISSION_TYPES.DISABLED;
};
export const isNotificationAssociationsEnabled = () => getNotificationAssociations() !== USER_PERMISSION_TYPES.DISABLED;

export const isCheckpointEmployee = async () => {
    const checkPointEmployee = 'CheckpointEmployee';
    const license = await getAccountService().getLicense();
    return license?.planName === checkPointEmployee;
};
