import styled from 'styled-components';
import Stack from '../Stack';

export const Wrapper = styled(Stack).attrs(() => ({
    spacing: 1,
}))`
    min-height: auto;
`;

export const Container = styled(Stack).attrs(() => ({
    spacing: 1,
}))`
    width: 100%;
`;

export const Line = styled.div`
    width: 100%;
    height: ${({ theme }) => `${theme.border.width.standard}${theme.units}`};
    background-color: ${({ theme }) => `${theme.palette.border.light}`};
`;
