import styled from 'styled-components';
import { Stack, Typography } from '../index';
import { Context } from '../../theme/colors/colors.types';
import { VulnerabilitiesProps, VulnerabilityItem } from './CountBySeverity.types';

type SeverityProps = Pick<VulnerabilitiesProps, 'isBigSize' | 'customWidth'>;
type VulnerabilityProps = Pick<VulnerabilityItem, 'severity' | 'count'>;

interface CountWithSeverityProps extends SeverityProps, VulnerabilityProps {}

export const CountWithSeverity = styled(Stack)<CountWithSeverityProps>`
    width: ${({ customWidth, isBigSize }) => (customWidth ? customWidth : isBigSize ? '72px' : '35px')};
    height: ${({ customWidth, isBigSize }) => (customWidth ? customWidth : isBigSize ? '40px' : '25px')};
    background-color: ${({ theme, severity, count }) =>
        count ? theme.palette[severity].backgrounds.strength.weaker : theme.palette['default'].backgrounds.default};
    align-items: flex-start;
    justify-content: center;
    border-radius: ${({ theme }) => `${theme.border.radius(1)}${theme.units}`};
    padding: 8px;
`;

export const TypographySeverityCount = styled(Typography)<{
    severity: Context;
    count: number | null;
    isClickable: boolean;
}>`
    &:hover {
        text-decoration: ${({ count, isClickable }) =>
            count !== null && count > 0 && isClickable ? 'underline' : 'none'};
        cursor: ${({ count, isClickable }) => (count !== null && count > 0 && isClickable ? 'pointer' : 'default')};
    }
    color: ${({ theme, severity, count }) =>
        count
            ? theme.palette[severity].foregrounds.regular.default
            : theme.palette['default'].foregrounds.regular.default};
`;

export const VulnerabilitiesWrapper = styled(Stack)`
    flex: 1;
`;
