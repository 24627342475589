import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IActiveFeatureInfo, IActiveFeatureTopic } from './ActiveFeatures';
import { Addin } from 'common/extensibility/AddinRegistry';

const PATH_ACTIVE_FEATURES_INFO = buildPath('active features', 'info');
const PATH_ACTIVE_FEATURES_TOPICS = buildPath(PATH_ACTIVE_FEATURES_INFO, 'topics');
export const ActiveFeaturesRegistry = {
    getAllActiveFeaturesInfos: () => globalAddinContainer.get<IActiveFeatureInfo>(PATH_ACTIVE_FEATURES_INFO),

    addActiveFeatureInfos: (activeFeatureInfos: IActiveFeatureInfo[]) => {
        const activeFeatureItemsAddIns: Addin<IActiveFeatureInfo>[] = activeFeatureInfos.map((item) => ({
            id: item.key,
            content: item,
        }));
        globalAddinContainer.add<IActiveFeatureInfo>(PATH_ACTIVE_FEATURES_INFO, activeFeatureItemsAddIns);
    },

    addActiveFeatureTopic: (activeFeatureTopic: IActiveFeatureTopic) => {
        globalAddinContainer.add<IActiveFeatureTopic>(PATH_ACTIVE_FEATURES_TOPICS, [
            { id: activeFeatureTopic.key, content: activeFeatureTopic },
        ]);
    },

    getAllActiveFeatureTopics: () => globalAddinContainer.get<IActiveFeatureTopic>(PATH_ACTIVE_FEATURES_TOPICS),
};
