import React from 'react';
import { ICustomTreeRendererProps } from '../CustomTree.interface';
import { Label, Stack } from '../../../../design-system/components-v2';
import { AllIconsName } from '../../../../design-system/components-v2/Icon/Icon.types';

export const CustomTreeLabelRenderer: React.FC<ICustomTreeRendererProps> = (props) => {
    const { compProps } = props;
    const { node } = compProps;
    const { data } = node;
    const iconName: AllIconsName = (data.icon || 'web') as AllIconsName;
    const text: string = data.text || data.name || node.id;

    return (
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} padding={2}
            style={{ height: '30px', border: '1px solid #ddd', borderRadius: '4px', width: 'fit-content' }}>
            <Label leadingIconProps={{ name: iconName }} text={text} />
        </Stack>
    );
};