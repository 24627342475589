import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from '../../design-system/components-v2';
import { IMessageReturnType, MessageProps } from '../../design-system/components-v2/Message/Message.types';
import {
    CustomFormDialog,
    IFullCustomFormDialogProps,
} from '../../erm-components/custom/CustomForm/components/CustomFormDialog/CustomFormDialog';
import { ICustomFormProps } from '../../erm-components/custom/CustomForm/CustomForm.interface';
import { DevModalDialog } from '../../erm-components/utils/Dev/DevModalDialog/DevModalDialog';

export interface GlobalConfirmModalProps extends Omit<MessageProps, 'onClose'> {
    onClose?: () => IMessageReturnType;
}

interface IGlobalErrorModalProps {
    title?: string;
    text: string;
    onClose?: () => void;
}

export interface IGlobalDevModalProps {
    onClose?: () => void;
}

export interface ICustomFormDialogProps {
    formProps: ICustomFormProps;
    data?: any;
}

interface IGlobalModelUtils {
    showConfirmationModal: (modalProps: GlobalConfirmModalProps, hideCancelButton?: boolean) => void;
    showErrorModal: (modalProps: IGlobalErrorModalProps) => void;
    showCustomFormModal: (modalProps: ICustomFormDialogProps) => void;
    showDevModal: () => void;
}

export const globalModelUtils: IGlobalModelUtils = {
    showConfirmationModal: () => null,
    showErrorModal: () => null,
    showCustomFormModal: () => null,
    showDevModal: () => null,
};

export const GlobalModals: React.FC = () => {
    const [confirmModalProps, setConfirmModalProps] = useState<MessageProps>();
    const [customFormModalProps, setCustomFormModalProps] = useState<IFullCustomFormDialogProps>();
    const [devModalProps, setDevModalProps] = useState<IGlobalDevModalProps>();

    const { t } = useTranslation();

    const closeConfirmModal = useCallback(() => {
        setConfirmModalProps(undefined);
    }, []);

    const closeCustomFormModal = useCallback(() => {
        setCustomFormModalProps(undefined);
    }, []);

    const fixOnClosingCallback = useCallback(
        (closeModal: () => void, origCallback?: () => IMessageReturnType): (() => void) => {
            if (!origCallback) {
                return closeModal;
            }
            return () => {
                const keepModalOpen = origCallback();
                if (!keepModalOpen) {
                    closeModal();
                }
            };
        },
        [],
    );

    const showConfirmationModal = useCallback(
        (modalProps: GlobalConfirmModalProps, hideCancelButton?: boolean) => {
            const finalModalProps: MessageProps = {
                ...modalProps,
                onConfirm: fixOnClosingCallback(closeConfirmModal, modalProps.onConfirm),
                onClose: fixOnClosingCallback(closeConfirmModal, modalProps.onClose),
                onCancel:
                    modalProps.onCancel || !hideCancelButton
                        ? fixOnClosingCallback(closeConfirmModal, modalProps.onCancel)
                        : undefined,
            };
            setConfirmModalProps(finalModalProps);
        },
        [fixOnClosingCallback, closeConfirmModal],
    );

    const showCustomFormModal = useCallback(
        (modalProps: ICustomFormDialogProps) => {
            const finalModalProps: IFullCustomFormDialogProps = {
                ...modalProps,
                onClose: closeCustomFormModal,
            };
            setCustomFormModalProps(finalModalProps);
        },
        [closeCustomFormModal],
    );

    useEffect(() => {
        globalModelUtils.showConfirmationModal = (modalProps: GlobalConfirmModalProps, hideCancelButton?: boolean) => {
            showConfirmationModal(modalProps, hideCancelButton);
        };

        globalModelUtils.showErrorModal = (modalProps: IGlobalErrorModalProps) => {
            showConfirmationModal(
                {
                    ...modalProps,
                    onConfirm: modalProps.onClose,
                    onClose: modalProps.onClose,
                    title: modalProps.title || t('COMMON.ERROR_OCCURRED'),
                    submitBtnText: t('COMMON.CLOSE_PASCAL'),
                    variant: 'danger',
                },
                true,
            );
        };

        globalModelUtils.showCustomFormModal = (modalProps: ICustomFormDialogProps) => {
            showCustomFormModal(modalProps);
        };

        globalModelUtils.showDevModal = () => {
            setDevModalProps({
                onClose: () => setDevModalProps(undefined),
            });
        };
    });

    return (
        <>
            {confirmModalProps && <Message {...confirmModalProps} />}
            {customFormModalProps && <CustomFormDialog {...customFormModalProps} />}
            {devModalProps && <DevModalDialog {...devModalProps} />}
        </>
    );
};
