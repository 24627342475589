import React, { useEffect } from 'react';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import { getCloudAccountsService } from 'common/interface/data_services';
import { CloudAccount } from 'common/interface/environments';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { TextIconItem } from 'common/components/policy/MultiSelectList/ListItemTypes/TextIconItem';
import { MultiSelectListComponent } from 'common/components/policy/MultiSelectList';
import { getFixedVendorIcon } from './utils';

export const resolveDisplayName = (account: CloudAccount) => {
    if (account.name && !account.externalId) {
        return `${account.name} (${account.id})`;
    } else if (!account.name && !account.externalId) {
        return account.id;
    } else if (account.externalId === account.name) {
        return account.externalId;
    } else {
        return `${account.name} (${account.externalId})`;
    }
};
export interface PlatformEnvironmentsSelectionProps {
    onValidate: Function;
    platform?: Vendors;
    selectedEnvironmentsChanged: Function;
    preSelectedItems?: ListItem[];
}
export const EnvironmentsSelectionComponent: React.FC<PlatformEnvironmentsSelectionProps> = ({
    preSelectedItems,
    onValidate,
    platform = Vendors.GENERIC,
    selectedEnvironmentsChanged,
}) => {
    const { t } = useTranslation();

    const [environments, setEnvironments] = React.useState<ListItem[] | null>(null);

    const init = async () => {
        const allEnvironments = await getCloudAccountsService().getAllCloudAccounts();
        let _platform = platform === Vendors.GCP ? Vendors.GOOGLE : platform;
        const SHIFTLEFT_RELATED_PLATFORMS = [
            Vendors.SHIFT_LEFT,
            Vendors.TERRAFORM,
            Vendors.CFT,
            Vendors.SOURCE_CODE_ASSURANCE,
        ];
        _platform = SHIFTLEFT_RELATED_PLATFORMS.includes(_platform) ? Vendors.SHIFT_LEFT : _platform;
        let matchedEnvironments = [];
        if (_platform === Vendors.IMAGE_ASSURANCE) {
            const supportedEnvironments = [
                Vendors.KUBERNETES,
                Vendors.SHIFT_LEFT,
                Vendors.CONTAINER_REGISTRY,
                Vendors.AWS,
            ];
            matchedEnvironments = allEnvironments.filter((environment) =>
                supportedEnvironments.includes(environment.platform as Vendors),
            );
        } else {
            matchedEnvironments = allEnvironments.filter((environment) => environment.platform === _platform);
        }
        const items: ListItem[] =
            matchedEnvironments?.map((environment) => {
                return {
                    id: environment.id,
                    name: resolveDisplayName(environment),
                    icon: getFixedVendorIcon(environment.platform),
                    platform: platform === Vendors.CONTAINER_REGISTRY ? 'containers-registry' : environment.platform,
                };
            }) || [];
        setEnvironments(items);
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        onValidate(selectedItems?.length);
        selectedEnvironmentsChanged(selectedItems);
        selectedItems.forEach((item) => {
            const match = environments?.find((_environment) => _environment.id === item.id);
            if (match) {
                match.selectedVersion = item.selectedVersion;
            }
        });
        environments && setEnvironments([...environments]);
    };
    useEffect(() => {
        if (environments?.length && preSelectedItems?.length) {
            preSelectedItems.forEach((preSelectedItem) => {
                const match = environments?.find((_environment) => _environment.id === preSelectedItem.id);
                if (match) {
                    match.selectedVersion = preSelectedItem.selectedVersion;
                }
            });
            setEnvironments([...environments]);
        }
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preSelectedItems, environments?.length]);

    return (
        <span data-aid={'environments-selection'}>
            <MultiSelectListComponent
                preSelectedItems={preSelectedItems}
                itemTemplate={TextIconItem}
                header={t('POLICY.SELECT_ENVIRONMENTS')}
                items={environments}
                onSelectedItemsChanged={selectedItemsChanged}
            />
        </span>
    );
};
