interface IDrawerEventHandler {
    id: string;
    handleEvent: (event: any) => void;
}

interface ICloseDrawerEventHandler {
    id: string;
    handleCloseEvent: (closeAll?: boolean) => void;
}

class DrawerMessagesBus {
    private readonly eventHandlersMap: { [key: string]: IDrawerEventHandler } = {};
    private readonly closeEventHandlersMap: { [key: string]: ICloseDrawerEventHandler } = {};

    public registerEventHandler(eventHandlerId: string, handleEvent: (event?: any) => void) {
        this.eventHandlersMap[eventHandlerId] = {
            id: eventHandlerId,
            handleEvent,
        };
    }

    public unregisterEventHandler(eventHandlerId: string) {
        if (this.eventHandlersMap[eventHandlerId]) {
            delete this.eventHandlersMap[eventHandlerId];
        }
    }

    public sendEvent(eventHandlerId: string, event?: any) {
        const eventHandler = this.eventHandlersMap[eventHandlerId];
        if (eventHandler) {
            eventHandler.handleEvent(event);
        }
    }

    public registerCloseEventHandler(closeEventHandlerId: string, handleCloseEvent: (closeAll?: boolean) => void) {
        this.closeEventHandlersMap[closeEventHandlerId] = {
            id: closeEventHandlerId,
            handleCloseEvent,
        };
    }

    public unregisterCloseEventHandler(closeEventHandlerId: string) {
        if (this.closeEventHandlersMap[closeEventHandlerId]) {
            delete this.closeEventHandlersMap[closeEventHandlerId];
        }
    }

    public sendCloseEvent(closeEventHandlerId: string, closeAll?: boolean) {
        const closeEventHandler: ICloseDrawerEventHandler = this.closeEventHandlersMap[closeEventHandlerId];
        if (closeEventHandler) {
            closeEventHandler.handleCloseEvent(closeAll);
        }
    }
}
export const DrawerBus: DrawerMessagesBus = new DrawerMessagesBus();
