import styled from 'styled-components';
import Stack from '../../Stack';

const Wrapper = styled(Stack)``;

const WizardFooterStyles = {
    Wrapper,
};

export default WizardFooterStyles;
