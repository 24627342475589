import React, { useEffect, useState } from 'react';
import {
    DayPickerRangeProps,
    DayPickerSingleProps,
    SelectSingleEventHandler,
    SelectRangeEventHandler,
} from 'react-day-picker';
import { IDatePickerProps, IDateRange } from './DatePicker.types';
import Popover from '../Popover/Popover';
import Input from '../Input/Input';
import IconButton from '../IconButton/IconButton';
import Stack from '../Stack/Stack';
import DatePickerBase from './DatePicker.base';

const DatePicker = React.forwardRef<HTMLInputElement, IDatePickerProps>((props, ref) => {
    const { value, type, onChange, datePickerProps, ...rest } = props;

    const inputRef = React.useRef<HTMLInputElement>(null);
    const inputWrapperRef = React.useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<IDateRange | Date | undefined>(value);

    React.useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const focusInput = React.useCallback(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        if (isOpen) {
            focusInput();
        }
    }, [isOpen, focusInput]);

    const handleStateChange = () => {
        setIsOpen((current) => {
            return !current;
        });
    };

    const handleInputClick = () => {
        setIsOpen(true);
    };

    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const inputValueFormatted = React.useMemo(() => {
        if (type === 'single' && selectedValue instanceof Date) {
            return selectedValue ? formatDate(selectedValue) : '';
        } else if (type === 'range' && !(selectedValue instanceof Date)) {
            const from = selectedValue?.from && formatDate(selectedValue.from);
            const to = selectedValue?.to && formatDate(selectedValue.to);
            return from && to ? `${from} - ${to}` : '';
        }
        return '';
    }, [type, selectedValue]);

    const handleSingleDateSelected: SelectSingleEventHandler = React.useCallback(
        (day, selectedDay, activeModifiers, event) => {
            if (type !== 'single') return;
            setSelectedValue(day);
            onChange(day, event);
        },
        [onChange, type],
    );

    const handleRangeDateSelected: SelectRangeEventHandler = React.useCallback(
        (range, selectedDay, activeModifiers, event) => {
            if (type !== 'range') return;
            setSelectedValue(range);
            onChange(range, event);
        },
        [onChange, type],
    );

    const dayPicketProps = React.useMemo<DayPickerRangeProps | DayPickerSingleProps | undefined>(() => {
        if (type === 'single') {
            return {
                mode: 'single',
                selected: selectedValue ? (selectedValue as Date) : undefined,
                onSelect: handleSingleDateSelected,
                defaultMonth: selectedValue ? (selectedValue as Date) : undefined,
                ...(datePickerProps || {}),
            };
        } else if (type === 'range') {
            return {
                mode: 'range',
                selected: selectedValue ? (selectedValue as IDateRange) : undefined,
                onSelect: handleRangeDateSelected,
                defaultMonth: selectedValue ? (selectedValue as IDateRange).to : undefined,
                ...(datePickerProps || {}),
            };
        }
    }, [handleRangeDateSelected, handleSingleDateSelected, selectedValue, type, datePickerProps]);

    const handleOnInputChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value !== '') return; // ignore input change - allow only clear - clearable prop send onChange with empty value
            setSelectedValue(undefined);
            onChange(undefined, e);
        },
        [onChange],
    );

    const handleOnInputBlur = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '') {
                setSelectedValue(undefined);
                onChange(undefined, e);
                return;
            }
            if (type === 'single') {
                const date = new Date(e.target.value);
                if (date instanceof Date && !isNaN(date.getTime())) {
                    setSelectedValue(date);
                    onChange(date, e);
                }
            } else {
                const [from, to] = e.target.value.trim().split('-');
                const fromDate = new Date(from);
                const toDate = new Date(to);
                if (
                    fromDate instanceof Date &&
                    toDate instanceof Date &&
                    !isNaN(fromDate.getTime()) &&
                    !isNaN(toDate.getTime())
                ) {
                    setSelectedValue({ from: fromDate, to: toDate });
                    onChange({ from: fromDate, to: toDate }, e);
                }
            }
        },
        [onChange, type],
    );

    return (
        <>
            <Input
                data-aid='date-picker-input'
                color='normal'
                ref={inputRef}
                inputWrapperRef={inputWrapperRef}
                onClick={handleInputClick}
                isActive={isOpen}
                value={inputValueFormatted}
                clearable
                onChange={handleOnInputChange}
                onBlur={handleOnInputBlur}
                placeholder={type === 'single' ? 'MM/DD/YYYY' : 'MM/DD/YYYY - MM/DD/YYYY'}
                {...rest}
                startAdornment={
                    <Stack
                        alignItems='center'
                        direction='row'
                        spacing={2}
                        overflow='hidden'
                        onClick={handleStateChange}
                    >
                        <IconButton iconProps={{ name: 'calendar' }} size='small' />
                    </Stack>
                }
                endAdornment={
                    <Stack alignItems='center' direction='row' spacing={2}>
                        <IconButton
                            iconProps={{ name: isOpen ? 'chevronUp' : 'chevronDown', size: 10 }}
                            size='small'
                            onClick={handleStateChange}
                        />
                    </Stack>
                }
            />
            <Popover
                open={isOpen}
                anchorEl={inputWrapperRef.current}
                onClose={handleStateChange}
                maxHeight={500}
                placement={'bottom-start'}
            >
                <DatePickerBase disabled={props.disabled} {...dayPicketProps} />
            </Popover>
        </>
    );
});
DatePicker.displayName = 'DatePicker';

export default DatePicker;
