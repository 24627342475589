import React from 'react';
import { PropStyled } from './PropertyBox.styled';
import PropertyBox from './PropertyBox';

const TextPropertyBox: React.FC<{ title: string; value: string; onClick?: Function }> = ({ title, value, onClick }) => {
    const getContent = () => {
        if (onClick) {
            return <PropStyled.LinkValueDiv onClick={() => onClick()}>{value}</PropStyled.LinkValueDiv>;
        } else {
            return <PropStyled.TextValueDiv>{value}</PropStyled.TextValueDiv>;
        }
    };

    return (
        <PropStyled.TopDiv>
            <PropertyBox title={title} content={getContent()} />
        </PropStyled.TopDiv>
    );
};

export default TextPropertyBox;
