export const DOWNLOAD_ROWS_LIMIT = 10000;

export const DataTestIds = {
    columnsVisible: 'columns-visible',
    columnsAll: 'columns-all',
    rowsFiltered: 'rows-filtered',
    rowsAll: 'rows-all',
    methodDirect: 'method-direct',
    methodEmail: 'method-email',
    rowsInfo: 'rows-info',
    columnsInfo: 'columns-info',
    confirmButton: 'confirm-button',
    emailInput: 'email-input',
};
