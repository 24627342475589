import React, { useState } from 'react';
import { CollapseSectionProps } from './CollapseSection.types';
import {
    CollapseSectionContent,
    CollapseSectionHeader,
    CollapseSectionWrapper,
    Media,
    ToggleArrow,
} from './CollapseSection.styled';
import { Icon } from '@dome9/berries/react-components';

const CollapseSection: React.FC<CollapseSectionProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <CollapseSectionWrapper>
            <CollapseSectionHeader onClick={() => setIsOpen(!isOpen)}>
                <Media title={title} icon='book' size='sm' iconSize='24' />
                <ToggleArrow isOpen={isOpen}>
                    <Icon name={'chevron-down'} size={12} />
                </ToggleArrow>
            </CollapseSectionHeader>
            <CollapseSectionContent isOpen={isOpen}>{children}</CollapseSectionContent>
        </CollapseSectionWrapper>
    );
};

export default CollapseSection;
