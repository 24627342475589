const targetsKeys = {
    Host: 'Host',
    File: 'File',
    Api: 'Api',
    Process: 'Process',
    Ip: 'Ip',
    Http: 'Http',
    Io: 'Io',
    Signature: 'Signature',
    Network: 'Network',
    PodsGroupId: 'PodsGroupId',
    Malware: 'Malware',
};

const targets = {
    [targetsKeys.Host]: 'Host',
    [targetsKeys.File]: 'File',
    [targetsKeys.Api]: 'AWS Api',
    [targetsKeys.Process]: 'Process',
    [targetsKeys.Ip]: 'IP',
    [targetsKeys.Http]: 'Http',
    [targetsKeys.Io]: 'Input',
    [targetsKeys.Signature]: 'Signature',
    [targetsKeys.Network]: 'Network',
    [targetsKeys.PodsGroupId]: 'PODS_GROUP_ID',
    [targetsKeys.Malware]: 'Malware',
};

export const RULES_AND_EXCLUSIONS = {
    TARGETS: {
        Host: targets.Host,
        File: targets.File,
        Api: targets.Api,
        Process: targets.Process,
        Ip: targets.Ip,
        Http: targets.Http,
        Io: targets.Io,
        Signature: targets.Signature,
        Malware: targets.Malware,
    },
    NETWORK_CATEGORIES_BY_VENDOR: {
        aws: [targets.Ip, targets.Host, targets.Http],
        azure: [targets.Ip, targets.Host, targets.Http],
        kubernetes: [targets.Host],
    },
};

export const EXCLUSIONS = {
    UI_TARGETS: {
        [targetsKeys.Api]: targets.Api,
        [targetsKeys.Network]: targets.Network,
        [targetsKeys.Process]: targets.Process,
        [targetsKeys.File]: targets.File,
        [targetsKeys.Io]: targets.Io,
        [targetsKeys.Signature]: targets.Signature,
        [targetsKeys.PodsGroupId]: targets.PodsGroupId,
        [targetsKeys.Malware]: targets.Malware,
    },
    UI_TARGETS_BY_PROVIDER: {
        aws: [targetsKeys.Api, targetsKeys.Network, targetsKeys.Process, targetsKeys.File, targetsKeys.Io],
        kubernetes: [targetsKeys.Process, targetsKeys.Network, targetsKeys.Malware],
        azure: [targetsKeys.Network, targetsKeys.Process, targetsKeys.File, targetsKeys.Io],
    },
    IO_FEATURES: {
        Lfi: 'Local File Inclusion',
        Xss: 'Cross Site Scripting',
        Sqli: 'Sql Injection',
        Cmdi: 'Command Injection',
        Codei: 'Code Injection',
        Xxe: 'Xml External Entity',
        Nosqli: 'No Sql Injection',
        Csvi: 'Csv Injection',
    },
};

export const ADDITIONAL_FIELDS_NAMES = {
    EXCEPTION_PAYLOADS_STRING: 'ServerlessExceptionPayloadsString',
    EXCEPTION_PAYLOADS_IO_FEATURE: 'ServerlessExceptionPayloadsIoFeature',
    EXCEPTION_PAYLOADS_SIGNATURE: 'ServerlessExceptionPayloadsSignatureName',
    EXCEPTION_PAYLOADS_MALWARE_FILE_PATH: 'ServerlessExceptionPayloadsMalwareFilepath',
    KUBERNETES_POD_ID: 'ServerlessKubernetesPodId',
    KUBERNETES_POD_NAME: 'ServerlessKubernetesPodName',
    AZURE_FUNCTION_NAME: 'AzureFunctionName',
};

interface PayloadPatternTypes {
    STRING: string;
    IOFEATURE: string;
    SIGNATURENAME: string;
    MALWAREFILEPATH: string;
    [key: string]: string;
}

export const PAYLOAD_PATTERN_TYPES = {
    STRING: 'String',
    IOFEATURE: 'IoFeature',
    SIGNATURENAME: 'SignatureName', // have to be without underscore!,
    MALWAREFILEPATH: 'MalwareFilepath',
} as PayloadPatternTypes;
