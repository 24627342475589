import React from 'react';
import { Label, Stack } from '../../../design-system/components-v2';
import CustomTree from '../../custom/CustomTree/CustomTree';
import { customTreeExampleRoot } from './CustomTreeExample.data';
import { CustomTreeLabelRenderer } from '../../custom/CustomTree/renderers/CustomTreeLabelRenderer';
import { ICustomTreeRendererProps } from '../../custom/CustomTree/CustomTree.interface';

const FancyRenderer: React.FC<ICustomTreeRendererProps> = (props) => {
    const { compProps } = props;
    const { node } = compProps;
    const { data } = node;
    const text: string = data.text || data.name || node.id;

    return (
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} padding={4}
            style={{ height: '40px', border: '1px solid darkBlue', background: 'lightcyan', borderRadius: '4px', width: 'fit-content' }}>
            <Label leadingIconProps={{ name: 'shieldChecked' }} text={text} color={'strong'} />
            {'==>'}
            <Label leadingIconProps={{ name: 'statusSuccess' }} text={'Better name'} color={'strong'} />
        </Stack>
    );
};

const CustomTreeExamplePage: React.FC = () => {
    return (
        <Stack direction={'row'} padding={4} fullHeight fullWidth>
            <Stack direction={'column'} padding={2} fullHeight>
                DEFAULT
                <CustomTree root={customTreeExampleRoot} />
            </Stack>
            <Stack direction={'column'} padding={2} fullHeight>
                BIG GAP + DEFAULT LABEL
                <CustomTree root={customTreeExampleRoot}
                    settings={{
                        rowsGap: 30,
                        defaultRenderer: CustomTreeLabelRenderer
                    }}
                />
            </Stack>
            <Stack direction={'column'} padding={2} fullHeight>
                SPECIFIED FANCY + LABEL (MIX)
                <CustomTree root={customTreeExampleRoot}
                    settings={{
                        renderersMap: {
                            'fancy': FancyRenderer,
                            'label': CustomTreeLabelRenderer,
                        },
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default CustomTreeExamplePage;