import { useState, useCallback } from 'react';

interface UseMutateOptions<TData, TError> {
    onSuccess?: (data: TData) => void;
    onError?: (error: TError) => void;
}

export function useMutate<
    TData = unknown,
    TError = unknown,
    T extends (...args: any[]) => any = (...args: any[]) => any,
>(promiseFn: T, options: UseMutateOptions<TData, TError>): { mutate: T; isLoading: boolean } {
    const [isLoading, setIsLoading] = useState(false);

    const mutate: any = useCallback(
        async (...params: any[]) => {
            setIsLoading(true);
            try {
                const data = await promiseFn(...params);
                setIsLoading(false);
                options?.onSuccess?.(data);
            } catch (error) {
                setIsLoading(false);
                options?.onError?.(error as TError);
            }
        },
        [promiseFn, options],
    );

    return { mutate, isLoading };
}

export default useMutate;
