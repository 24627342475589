import React from 'react';
import { ChipWrapper, TabsWrapper } from './Tabs.styled';
import { Props } from './Types';
import { Chip, List } from 'common/design-system/components-v2';

const Tabs: React.FC<Props> = ({ headers, selectedTab = 0, onTabChange }) => {
    const handleOnTabChange = React.useCallback(
        (tabIndex: number) => {
            if (onTabChange) {
                onTabChange(tabIndex);
            }
        },
        [onTabChange],
    );

    return (
        <TabsWrapper padding={3} fullHeight>
            <List
                data-aid='entity-viewer-tabs-list'
                options={headers.map((header, index) => ({
                    label: header.label,
                    value: index.toString(),
                    selected: selectedTab === index,
                    onClick: () => handleOnTabChange(index),
                    endAdornment: header.chipLabel && (
                        <ChipWrapper>
                            <Chip
                                size='xs'
                                label={header.chipLabel}
                                color='brandPrimary'
                                disableInteraction
                                withBorder
                            />
                        </ChipWrapper>
                    ),
                }))}
            />
        </TabsWrapper>
    );
};

export default Tabs;
