export const CUSTOM_FORM_DRAWER_EVENT_HANDLER_ID = 'CUSTOM_FORM_DRAWER_EVENT_HANDLER_ID';

export const TAG_KEY = 'key';
export const TAG_VALUE = 'value';
export const WILDCARD_MARK = '%';

export enum FormItemType {
    simple,
    field,
    radio,
    box,
}

export enum FormFieldType {
    input,
    checkbox,
    textArea,
    multiInput,
    singleSelect,
    multiSelect,
    orgUnits,
    dateRange,
    singleDate,
    tags,
    lazySelect,
    custom,
}

export enum FormValueType {
    singleString,
    multiString,
    any,
}

export enum FormSimpleItemType {
    label,
    divider,
}

interface ICommonLabelItemProps {
    itemType: FormItemType.simple,
    simpleItemType: FormSimpleItemType.label,
}

export const commonLabelItemProps: ICommonLabelItemProps = {
    itemType: FormItemType.simple,
    simpleItemType: FormSimpleItemType.label,
};

interface ICommonDividerItemProps {
    itemType: FormItemType.simple,
    simpleItemType: FormSimpleItemType.divider,
}

export const commonDividerItemProps: ICommonDividerItemProps = {
    itemType: FormItemType.simple,
    simpleItemType: FormSimpleItemType.divider,
};

interface ICommonInputFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.input,
    valueType: FormValueType.singleString,
}

export const commonInputItemProps: ICommonInputFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.input,
    valueType: FormValueType.singleString,
};

interface ICommonCheckboxFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.checkbox,
    valueType: FormValueType.singleString,
}

export const commonCheckboxItemProps: ICommonCheckboxFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.checkbox,
    valueType: FormValueType.singleString,
};

interface ICommonTextAreaFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.textArea,
    valueType: FormValueType.singleString,
}

export const commonTextAreaItemProps: ICommonTextAreaFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.textArea,
    valueType: FormValueType.singleString,
};

interface ICommonMultiInputFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.multiInput,
    valueType: FormValueType.multiString,
}

export const commonMultiInputItemProps: ICommonMultiInputFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.multiInput,
    valueType: FormValueType.multiString,
};

interface ICommonMultiSelectFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.multiSelect,
    valueType: FormValueType.multiString,
}

export const commonMultiSelectFieldItemProps: ICommonMultiSelectFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.multiSelect,
    valueType: FormValueType.multiString,
};

interface ICommonSingleSelectFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.singleSelect,
    valueType: FormValueType.singleString,
}

export const commonSingleSelectFieldItemProps: ICommonSingleSelectFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.singleSelect,
    valueType: FormValueType.singleString,
};

interface ICommonOrgUnitsFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.orgUnits,
    valueType: FormValueType.multiString,
}

export const commonOrgUnitsFieldItemProps: ICommonOrgUnitsFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.orgUnits,
    valueType: FormValueType.multiString,
};

interface ICommonCustomComponentFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.custom,
    valueType: FormValueType.any,
}

export const commonCustomComponentFieldItemProps: ICommonCustomComponentFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.custom,
    valueType: FormValueType.any,
};

interface ICommonSingleDateFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.singleDate,
    valueType: FormValueType.singleString,
}

export const commonSingleDateFieldItemProps: ICommonSingleDateFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.singleDate,
    valueType: FormValueType.singleString,
};

interface ICommonDateRangeFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.dateRange,
    valueType: FormValueType.multiString,
}

export const commonDateRangeFieldItemProps: ICommonDateRangeFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.dateRange,
    valueType: FormValueType.multiString,
};

interface ICommonLazySelectFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.lazySelect,
    valueType: FormValueType.multiString,
}

export const commonLazySelectFieldItemProps: ICommonLazySelectFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.lazySelect,
    valueType: FormValueType.multiString,
};

interface ICommonTagsFieldItemProps {
    itemType: FormItemType.field,
    fieldType: FormFieldType.tags,
    valueType: FormValueType.multiString,
}

export const commonTagsFieldItemProps: ICommonTagsFieldItemProps = {
    itemType: FormItemType.field,
    fieldType: FormFieldType.tags,
    valueType: FormValueType.multiString,
};

interface ICommonRadioItemProps {
    itemType: FormItemType.radio,
}

export const commonRadioItemProps: ICommonRadioItemProps = {
    itemType: FormItemType.radio,
};

interface ICommonBoxItemProps {
    itemType: FormItemType.box,
}

export const commonBoxItemProps: ICommonBoxItemProps = {
    itemType: FormItemType.box,
};
