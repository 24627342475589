import 'common/components/FilterPanel/DefaultFilters/TreeFilter/TreeFilter.scss';
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { TreeDataWrapper, TreeDataBox, ToggleArrow, StyledPopover } from './TreeData.styles';
import { RowClickedEvent } from 'ag-grid-community';
import { Icon, Label } from '@dome9/berries/react-components';
import { TreeDataProps, TreeDataOption } from './TreeData.types';
import { Typography } from 'common/design-system/components-v2';

const TreeData: React.FunctionComponent<TreeDataProps> = ({
    label,
    options,
    value,
    placeholder,
    onChange,
    disabled,
    isLoading,
}) => {
    const [changeError, setChangeError] = React.useState<boolean>(false);
    const [selectOpen, setSelectOpen] = React.useState<boolean>(false);
    const divRef = React.useRef<HTMLDivElement>(null);

    const toggleSelectState = () => {
        if (disabled || isLoading) return;
        setSelectOpen((current) => !current);
    };

    const handleOnChange = async (row: RowClickedEvent<TreeDataOption>) => {
        if (!row.data) return;
        if (changeError) {
            setChangeError(false);
        }
        const updateResponse = await onChange(row.data.id);
        if (!updateResponse) {
            setChangeError(true);
            return;
        }
        row.node.setSelected(true);
        setSelectOpen(false);
    };

    const selectedItemName = React.useMemo(() => {
        return options.find((item) => item.id === value)?.name || placeholder || '';
    }, [options, value, placeholder]);

    return (
        <TreeDataWrapper ref={divRef}>
            {label && <Label label={label} />}
            <StyledPopover
                onClickOutside={() => setSelectOpen(false)}
                visible={selectOpen}
                placement='bottom-start'
                width={divRef.current?.clientWidth || 200}
                arrow={false}
                interactive={true}
                appendTo={() => document.body}
                content={
                    <AgGridReact
                        rowSelection='single'
                        rowHeight={24}
                        domLayout='autoHeight'
                        defaultColDef={{ width: (divRef.current?.clientWidth || 200) - 50 }}
                        className='ag-theme-alpine ag-tree-data ag-header-none'
                        rowData={options}
                        treeData={true}
                        sideBar={null}
                        onRowClicked={(row) => {
                            handleOnChange(row);
                        }}
                        animateRows={true}
                        groupDefaultExpanded={1}
                        headerHeight={0}
                        columnDefs={[]}
                        getDataPath={(row) =>
                            Array.isArray(row.path) ? row.path : row.path ? row.path.split('/') : []
                        }
                        autoGroupColumnDef={{
                            cellRendererParams: {
                                suppressCount: true,
                            },
                        }}
                        suppressContextMenu={true}
                    />
                }
            >
                <TreeDataBox isOpen={selectOpen} onClick={toggleSelectState}>
                    <Typography>{selectedItemName}</Typography>
                    <ToggleArrow isOpen={selectOpen}>
                        <Icon name={'chevron-down'} size={12} />
                    </ToggleArrow>
                </TreeDataBox>
            </StyledPopover>
        </TreeDataWrapper>
    );
};

export default TreeData;
