import globalAddinContainer, { getService } from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import {
    PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS,
    PATH_EVENTS_TABLE_PAGE_TABLE_FILTERS,
    PATH_EVENTS_TABLE_PAGE_TABLE_ACTIONS,
    PATH_EVENTS_TABLE_PAGE_TABLE_EXPORTS,
    PATH_EVENTS_TABLE_PAGE_TABLE_CELL_RENDERERS,
    PATH_EVENTS_TABLE_PAGE_TABLE_TABS,
    IEventTableFilterParams,
    IEventTableFilter,
    IEventTableExport,
    IEventTableCellRendererAddinContent,
    EventsTableTab,
    EventsTableColDef,
    EventsTableTabComponent,
} from '../../interface/events';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IEvent, SysEventType } from './Events';
import { ISearchFilterViewModel } from 'common/components/Findings/Findings.interface';
import { EventType } from './EventsConsts';
import { ChangeParamPayload } from 'common/hooks/useReactRouterQuery';

export const EventsTableRegistry = {
    addColumnDefAddins: (columnDefAddins: Addin<EventsTableColDef>[]) => {
        globalAddinContainer.add<EventsTableColDef>(
            PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS,
            columnDefAddins.map((columnDef) => ({ position: Infinity, ...columnDef })),
        );
    },

    addColumnDefs: (columnDefs: EventsTableColDef[]) => {
        globalAddinContainer.addMap(PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS, columnDefs, 'id');
    },

    getColumnDefs: (): EventsTableColDef[] => {
        return globalAddinContainer.get<EventsTableColDef>(PATH_EVENTS_TABLE_PAGE_TABLE_COLUMNS);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_EVENTS_TABLE_PAGE_TABLE_FILTERS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IEventTableFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_EVENTS_TABLE_PAGE_TABLE_FILTERS, filterId, filterParams);
    },

    getFilterDefs(filters: IEventTableFilter[], params: IEventTableFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = EventsTableRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    },

    addActions: (actionDefs: Addin<ITableAction<IEvent>>[]) => {
        globalAddinContainer.add<ITableAction>(PATH_EVENTS_TABLE_PAGE_TABLE_ACTIONS, actionDefs);
    },

    getActions: () => {
        return globalAddinContainer.get<ITableAction<IEvent>>(PATH_EVENTS_TABLE_PAGE_TABLE_ACTIONS);
    },

    addExports: (actionDefs: Addin<IEventTableExport>[]) => {
        globalAddinContainer.add<IEventTableExport>(PATH_EVENTS_TABLE_PAGE_TABLE_EXPORTS, actionDefs);
    },

    getExports: () => {
        return globalAddinContainer.get<IEventTableExport>(PATH_EVENTS_TABLE_PAGE_TABLE_EXPORTS);
    },

    addCellRendererAddins: (cellRendererAddins: Addin<IEventTableCellRendererAddinContent>[]) => {
        globalAddinContainer.add<IEventTableCellRendererAddinContent>(
            PATH_EVENTS_TABLE_PAGE_TABLE_CELL_RENDERERS,
            cellRendererAddins,
        );
    },

    addTabs: (tabs: Addin<EventsTableTab | EventsTableTabComponent>[]) => {
        globalAddinContainer.add(PATH_EVENTS_TABLE_PAGE_TABLE_TABS, tabs);
    },

    getTabs: () => {
        return globalAddinContainer.get<EventsTableTab | EventsTableTabComponent>(PATH_EVENTS_TABLE_PAGE_TABLE_TABS);
    },
};

export interface EventsTableWidgetProps {
    disablePadding?: boolean;
    tableIdPrefix?: string;
    relevantTabs?: string[];
    defaultFilters?: ISearchFilterViewModel;
    hiddenFilters?: string[];
    hiddenColumns?: string[];
}

export interface EventsTableFilters {
    freeText?: string;
    fields?: { name: string; value: (string | number)[] | string }[];
    datePicker?: {
        from: string;
        to: string;
    };
}

export interface EventsTableWidget {
    getMainTable: () => React.LazyExoticComponent<React.FunctionComponent<EventsTableWidgetProps>>;
    getMainTableLink: (eventType: EventType, filters?: string | EventsTableFilters) => string;
    getParamsStringForEventsTab: (
        type: string,
        eventType: EventType,
        filters?: string | EventsTableFilters,
    ) => ChangeParamPayload[];
    openEventDrawer: (eventId: string, sysEventType: SysEventType) => void;
}

export enum IWebappIframeServicePlacement {
    DRAWER = 'drawer',
    MODAL = 'modal',
    REACT_AND_ANGULAR = 'reactAndAngular',
}

export const EVENTS_TABLE_WIDGET_ID = 'EventsTableWidget';
export function getEventsTableWidgetService(): EventsTableWidget {
    return getService<EventsTableWidget>(EVENTS_TABLE_WIDGET_ID);
}
