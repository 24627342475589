import { IDrawerContentProvider, IDrawerHandlersIdMap, IDrawerWidgetUrlParams } from './Drawer.interface';
import { DrawerRegistry } from './DrawerRegistry';
import { changeDrawerUrlParam } from './Drawer.utils';

interface IDrawerParams {
    openLightDrawer: (
        contentProvider: IDrawerContentProvider,
        widgetData?: any,
        handlersIdMap?: IDrawerHandlersIdMap,
    ) => Promise<void>;
    onCloseRequest: (closeAll?: boolean) => void;
    closeDrawer: (closeAll?: boolean) => void;
    onDrawersStackChange: () => void;
}

class DrawerManager {
    private readonly drawersStack: IDrawerParams[] = [];

    private hasDrawers(): boolean {
        return this.drawersStack.length > 0;
    }

    public getDrawersCount(): number {
        return this.drawersStack.length;
    }

    private getTopDrawerParams(): IDrawerParams | undefined {
        if (this.hasDrawers()) {
            return this.drawersStack[this.drawersStack.length - 1];
        }
    }

    private fireDrawersStackChange(): void {
        this.drawersStack.forEach((drawer) => drawer.onDrawersStackChange());
    }

    public showDrawer(widgetId: string, widgetData?: any, handlersIdMap?: IDrawerHandlersIdMap) {
        const topDrawerParams: IDrawerParams | undefined = this.getTopDrawerParams();
        if (!topDrawerParams) {
            const urlParams: IDrawerWidgetUrlParams = {
                widgetId,
                widgetData,
                handlersIdMap: handlersIdMap,
            };
            changeDrawerUrlParam(urlParams);
            return;
        }

        const contentProvider: IDrawerContentProvider | undefined = DrawerRegistry.getContentProvider(widgetId);
        if (!contentProvider) {
            return;
        }
        void topDrawerParams.openLightDrawer(contentProvider, widgetData, handlersIdMap);
    }

    public onDrawerOpened(drawerInfo: IDrawerParams) {
        this.drawersStack.push(drawerInfo);
        this.fireDrawersStackChange();
    }

    public onDrawerClosed(closeAll?: boolean) {
        if (!this.hasDrawers()) {
            return;
        }
        this.drawersStack.pop();
        this.fireDrawersStackChange();
        if (closeAll) {
            this.closeAll();
        }
    }

    public closeTopDrawer(closeAll?: boolean) {
        const topDrawerInfo: IDrawerParams | undefined = this.getTopDrawerParams();
        if (topDrawerInfo) {
            topDrawerInfo.closeDrawer(closeAll);
        }
    }

    public closeAll() {
        const topDrawerInfo: IDrawerParams | undefined = this.getTopDrawerParams();
        if (topDrawerInfo) {
            topDrawerInfo.onCloseRequest(true);
        }
    }

    public hardReset() {
        this.drawersStack.length = 0;
    }
}

export const drawerManager: DrawerManager = new DrawerManager();
