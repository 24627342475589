/* eslint-disable react/prop-types */
import Area from '../../Highcharts/Area';
import { IGenericWidgetData, IGenericWidgetDataSet } from '../../../module_interface/overview/Interface';

export interface IAreaWidget {
    data: IGenericWidgetData | IGenericWidgetDataSet;
}

const AreaWidget: React.FC<IAreaWidget> = ({ data }) => {
    const className = data?.className;
    return (
        <div className={`@container flex flex-1 ${className}`}>
            <div className={`flex flex-col @[300px]:flex-row gap-5 py-6 px-8 flex-1 ${className}`}>
                <Area data={data} />
            </div>
        </div>
    );
};

export default AreaWidget;
