import { useCallback, useMemo, useRef, useState, useEffect, FC } from 'react';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Table } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { CellClickedEvent, RowSelectedEvent } from 'ag-grid-community/dist/lib/events';
import { IDataItem, IGenericTableProps } from '../interfaces';

const SimpleGenericTable: FC<IGenericTableProps> = ({
    actions = [],
    translationKey,
    tableRegistry,
    onInnerGridReady,
    pageSize,
    pagination,
    data,
    allItemsCount,
    rowSelectionMode = 'single',
    onItemClicked,
    exportButtons,
}) => {
    const { t } = useTranslation(translationKey);
    const apiRef = useRef<GridApi>();
    const [, setSelectedRows] = useState<IDataItem[]>([]);

    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setRowData(data);
        }
    }, [data]);

    const columnDefs = useMemo<ColDef[]>(() => {
        return tableRegistry.getColumnDefs();
    }, [tableRegistry]);

    const handleCellClicked = (event: CellClickedEvent) => {
        onItemClicked && onItemClicked(event.data);
    };

    const onSelectionChanged = (event: RowSelectedEvent) => {
        const selectedItems: IDataItem[] = event.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const onGridReady = useCallback(
        (params: GridReadyEvent) => {
            apiRef.current = params.api;
            apiRef.current.setRowData(data);
            onInnerGridReady(params);
        },
        [data, onInnerGridReady],
    );

    const gridOptions: GridOptions = {
        pagination: pagination,
        columnDefs,
        rowSelection: rowSelectionMode,
        onGridReady,
        getRowId: (params) => params.data.id!,
        onCellClicked: handleCellClicked,
        onRowSelected: onSelectionChanged,
        isRowSelectable: () => {
            return true;
        },
    };

    return (
        <>
            <Table
                pageSize={pageSize}
                disableGrouping={true}
                gridOptions={gridOptions}
                actions={actions}
                exportButtons={exportButtons}
                footer={t('TABLE.FOOTER', {
                    currentCount: data.length.toLocaleString(),
                    totalCount: allItemsCount.toLocaleString(),
                })}
            />
        </>
    );
};

export default SimpleGenericTable;
