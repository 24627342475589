import { getService } from '../../../extensibility/AddinContainer';

export const MAGELLAN_SCHEMA_SERVICE_ID = 'magellanSchemaService';

export type SchemaAllTypesResponse = {};

export interface IMagellanSchemaService {
    getAllTypes(): Promise<SchemaAllTypesResponse>;
}

export function getMagellanSchemaService(): IMagellanSchemaService {
    return getService<IMagellanSchemaService>(MAGELLAN_SCHEMA_SERVICE_ID);
}
