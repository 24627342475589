import { forwardRef } from 'react';
import { IInputLabelProps } from './InputLabel.types';
import Label from '../Label';
import InputLabelStyles from './InputLabel.styles';

const InputLabel = forwardRef<HTMLDivElement, IInputLabelProps>((props, ref) => {
    const { subText, ...rest } = props;

    return (
        <InputLabelStyles.Wrapper spacing={2} ref={ref}>
            <Label {...rest} color='strong' />
            {subText && (
                <InputLabelStyles.SubText variant='xs' color='light'>
                    {subText}
                </InputLabelStyles.SubText>
            )}
        </InputLabelStyles.Wrapper>
    );
});
InputLabel.displayName = 'InputLabel';

export default InputLabel;
