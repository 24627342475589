import { useExportToCsvTranslations } from './hooks/useExportToCsvTranslations';

export enum ExportMethod {
    DirectDownload = 'directDownload',
    ByEmail = 'byEmail',
}

export enum IncludeColumns {
    Visible = 'visible',
    All = 'all',
}

export enum IncludeRows {
    Filtered = 'filtered',
    All = 'all',
}

export enum ErrorType {
    OverLimit = 'overLimit',
    Email = 'email',
}

export enum RadioName {
    Columns = 'columns',
    Rows = 'rows',
    Method = 'method',
}

export interface OnExportParams {
    includeColumns: IncludeColumns;
    includeRows: IncludeRows;
    exportMethod: ExportMethod;
    email: string;
}

export interface ExportToCsvProps {
    /** The number of rows in the table */
    rowsCount?: number;
    /** The number of rows after filtering */
    filteredRowsCount?: number;
    /** The number of columns in the table */
    allColumnsCount?: number;
    /** The number of visible columns in the table */
    visibleColumnsCount?: number;
    /** The title of the modal */
    modalTitle?: string;
    /** Whether the modal should close after exporting */
    closeModalAfterExport?: boolean;
    //* The text for the export button */
    exportBtnText?: string;
    /** The function to call when exporting */
    onExport: (params: OnExportParams) => void;
}

export type OnRadioChange = <T extends IncludeColumns | IncludeRows | ExportMethod>(name: RadioName, value: T) => void;

export interface FormProps {
    error: ErrorType | null;
    rowsCount: number;
    filteredRowsCount: number;
    allColumnsCount: number;
    visibleColumnsCount: number;
    includeColumns: IncludeColumns;
    includeRows: IncludeRows;
    exportMethod: ExportMethod;
    email: string;
    isOverLimit: boolean;
    onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onRadioChange: OnRadioChange;
}

export interface FooterProps {
    onClose: () => void;
    onExport: () => void;
}

export type UseExportToCsvTranslations = ReturnType<typeof useExportToCsvTranslations>;
