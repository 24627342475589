import { IApplicationReducer } from 'common/interface/redux';

import FilterPanelExamplePageReducer from './FilterPanelExamplePage.reducer';
import { getAppRootRegistry } from 'common/interface/services';
import { lazy } from 'react';

export function initializeFilterPanelPage() {
    const reducers: IApplicationReducer[] = [
        { name: 'FilterPanelExamplePageSlice', reducer: FilterPanelExamplePageReducer, isBlackList: true },
    ];
    getAppRootRegistry().addReducers(reducers, 'name');

    const filterPanelExamplePageRoute = () => {
        return {
            condition: true,
            component: lazy(() => import('./FilterPanelExamplePage')),
            path: '/debug/filterpanelexamplepage',
        };
    };
    getAppRootRegistry().addRoutes([{ id: 'Filter panel example page', content: filterPanelExamplePageRoute }]);
}
