import { useEffect } from 'react';
import { getStoreService } from '../interface/services';
import { setIsReactPageWithAngular } from '../../App.reducer';

export function useReactWithAngular(isAngular: boolean) {
    useEffect(() => {
        const dispatch = getStoreService().dispatch;
        dispatch(setIsReactPageWithAngular(isAngular));
        return () => {
            dispatch(setIsReactPageWithAngular(false));
        };
    }, [isAngular]);
}
