import React from 'react';
import { JSONTree } from 'react-json-tree';

export interface jsonViewerProps {
    json: any;
    componentProps?: any;
}

const jsonViewerTheme = {
    scheme: 'bright',
    author: '',
    base00: 'white',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: 'green',
    base0C: '#a1efe4',
    base0D: 'blue',
    base0E: '#ae81ff',
    base0F: '#cc6633',
};

const JsonViewer: React.FC<jsonViewerProps> = (props) => {
    return <JSONTree {...props.componentProps} data={props.json} theme={{ extend: jsonViewerTheme }} />;
};
export default JsonViewer;
