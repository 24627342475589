import { Tag } from '../../../components/Tags/Tags.types';
import {
    IFieldItem,
    IFormParams,
    IItem,
    IItemsMap,
    IRadioItem,
    IRadioOption,
    IBoxItem,
} from './CustomForm.interface';
import i18n from 'i18next';
import { FormItemType, FormValueType } from './CustomForm.consts';
import { IDateRange } from '../../../design-system/components-v2/DatePicker/DatePicker.types';

export const capitalizeFirstLetter = (str: string): string => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const getItemLabel = (item: IItem): string => {
    return item.state.label || capitalizeFirstLetter(item.name);
};

const getItemDefaultPlaceholder = (item: IFieldItem, trans: string): string => {
    return i18n.t(trans, { title: getItemLabel(item) });
};

export const getItemSelectPlaceholder = (item: IFieldItem): string => {
    return item.state.placeholder || getItemDefaultPlaceholder(item, 'CUSTOM_FORM.MISC.SELECT');
};

export const getItemEnterPlaceholder = (item: IFieldItem): string => {
    return item.state.placeholder || getItemDefaultPlaceholder(item, 'CUSTOM_FORM.MISC.ENTER');
};

export const getTrimmedSingleString = (value?: string): string | undefined => {
    return value ? value.trim() : value;
};

export const getSafeTrimmedSingleString = (value?: string): string => {
    return getTrimmedSingleString(value) || '';
};

export const getTrimmedMultiString = (values?: string[]): string[] | undefined => {
    if (values) {
        return values.map(val => getSafeTrimmedSingleString(val));
    }
};

export const getSingleValue = (item?: IItem): string | undefined => {
    if (!item || (item.itemType !== FormItemType.field)) {
        return undefined;
    }

    if (item.valueType === FormValueType.singleString) {
        return getTrimmedSingleString(item.state.value as string);
    }
};

export const getTrimmedSingleValue = (item?: IItem): string | undefined => {
    return getTrimmedSingleString(getSingleValue(item));
};

export const getSafeTrimmedSingleValue = (item?: IItem): string => {
    return getTrimmedSingleValue(item) || '';
};

export const getMultiStringItemValue = (item?: IItem): string[] | undefined => {
    if (!item || (item.itemType !== FormItemType.field)) {
        return undefined;
    }

    const fieldItem: IFieldItem = item as IFieldItem;
    if (fieldItem.valueType === FormValueType.multiString) {
        return getTrimmedMultiString(item.state.value as string[]);
    }
};

export const getTrimmedMultiValue = (item?: IItem): string[] | undefined => {
    return getTrimmedMultiString(getMultiStringItemValue(item));
};

export const getSafeTrimmedMultiValue = (item?: IItem): string[] => {
    if (!item) {
        return [];
    }

    return getTrimmedMultiValue(item) || [];
};

export const isEmptyItemValue = (item?: IItem): boolean => {
    if (!item || (item.itemType !== FormItemType.field)) {
        return true;
    }

    switch (item.valueType) {
        case FormValueType.singleString:
            return !getSingleValue(item);

        case FormValueType.multiString:
            return !getMultiStringItemValue(item)?.length;

        default:
            return true;
    }
};

const getTrimmedItemValue = (item?: IItem): any | undefined => {
    if (!item || (item.itemType !== FormItemType.field)) {
        return undefined;
    }

    switch (item.valueType) {
        case FormValueType.singleString:
            return getTrimmedSingleValue(item);

        case FormValueType.multiString:
            return getTrimmedMultiValue(item);

        default:
            return item.state.value;
    }
};

export const getRadioOptionItems = (option: IRadioOption, itemsMap: IItemsMap): IItem[] => {
    return option.subItems.map(subItem => itemsMap[subItem.name]).filter(subItem => !!subItem);
};

export const getBoxSubItems = (box: IBoxItem, itemsMap: IItemsMap): IItem[] => {
    return box.subItems.map(subItem => itemsMap[subItem.name]).filter(subItem => !!subItem);
};

export const getVisibleRadioOptionItems = (option: IRadioOption, itemsMap: IItemsMap): IItem[] => {
    return getRadioOptionItems(option, itemsMap).filter(item => !item.state.hidden);
};

export const getRowSubItems = (boxItem: IBoxItem, itemsMap: IItemsMap): IItem[] => {
    return boxItem.subItems.map(subItem => itemsMap[subItem.name]).filter(subItem => !!subItem);
};

export const getVisibleRowSubItems = (boxItem: IBoxItem, itemsMap: IItemsMap): IItem[] => {
    return getRowSubItems(boxItem, itemsMap).filter(item => !item.state.hidden);
};

const getVisibleRadioOptions = (item: IRadioItem, itemsMap: IItemsMap): IRadioOption[] => {
    return item.options.filter(option => {
        const visibleItems: IItem[] = getVisibleRadioOptionItems(option, itemsMap);
        return visibleItems.length > 0;
    });
};

const getFirstActiveRadioOption = (radioItem: IRadioItem, itemsMap: IItemsMap): IRadioOption | undefined => {
    const visibleOptions: IRadioOption[] = getVisibleRadioOptions(radioItem, itemsMap);
    if (visibleOptions.length === 0) {
        return undefined;
    }
    const { options } = radioItem;
    for (const option of options) {
        const subItems: IItem[] = getRadioOptionItems(option, itemsMap);
        for (let j = 0; j < subItems.length; j++) {
            const subItem: IItem = subItems[j];
            if ((subItem.itemType === FormItemType.field) && !isEmptyItemValue(subItem)) {
                return option;
            }
        }
    }
};

const getBestRadioOptionSelectedName = (radioItem: IRadioItem, itemsMap: IItemsMap): string | undefined => {
    const visibleOptions: IRadioOption[] = getVisibleRadioOptions(radioItem, itemsMap);
    if (visibleOptions.length === 0) {
        return undefined;
    }

    if (radioItem.state.selectedName) {
        const selectedOption: IRadioOption | undefined = visibleOptions.find(option => option.name === radioItem.state.selectedName);
        if (selectedOption) {
            return radioItem.state.selectedName;
        }
    }

    const firstActiveOption: IRadioOption | undefined = getFirstActiveRadioOption(radioItem, itemsMap);
    return firstActiveOption?.name || visibleOptions[0].name;
};

export const fixSelectedRadioOption = (itemsMap: IItemsMap) => {
    Object.values(itemsMap).forEach(item => {
        if (item.itemType === FormItemType.radio) {
            item.state.selectedName = getBestRadioOptionSelectedName(item, itemsMap);
        }
    });
};

export const isRadioHasOptionsWithMultiFields = (radioItem: IRadioItem) => {
    return radioItem.options.some(option => option.subItems.length > 1);
};

const updateActiveItems = (params: IFormParams, itemsMap: IItemsMap, currItem: IItem, relevantItems: IItem[]) => {
    if (currItem.state.hidden) {
        return;
    }

    switch (currItem.itemType) {
        case FormItemType.radio:
            if (currItem.state.selectedName && (currItem.options.length > 0)) {
                const selectedOption: IRadioOption | undefined = currItem.options.find(option => option.name === currItem.state.selectedName);
                if (selectedOption) {
                    relevantItems.push(...getVisibleRadioOptionItems(selectedOption, itemsMap));
                }
            }
            break;

        case FormItemType.box:
            getBoxSubItems(currItem, itemsMap).forEach(subItem => {
                updateActiveItems(params, itemsMap, subItem, relevantItems);
            });
            break;

        default:
            relevantItems.push(currItem);
            break;
    }
};

const getActiveItems = (params: IFormParams, itemsMap: IItemsMap): IItem[] => {
    const relevantItems: IItem[] = [];
    params.topNames.forEach(name => {
        updateActiveItems(params, itemsMap, itemsMap[name], relevantItems);
    });
    return relevantItems.filter(item => (item && !item.state.hidden && !item.state.disabled));
};

export const getActiveItemsMap = (params: IFormParams, itemsMap: IItemsMap): IItemsMap => {
    const map: IItemsMap = {};
    getActiveItems(params, itemsMap).forEach(item => {
        map[item.name] = item;
    });
    return map;
};

export const getActiveTrimmedValues = (params: IFormParams, itemsMap: IItemsMap): any[] =>
    getActiveItems(params, itemsMap).filter(item => !isEmptyItemValue(item)).map(item => getTrimmedItemValue(item));

export const getActiveValuesJson = (params: IFormParams, itemsMap: IItemsMap): string =>
    JSON.stringify(getActiveTrimmedValues(params, itemsMap));

export const convertValuesToDates = (values?: string[]): IDateRange => {
    const finalValues: string[] = values ?? [];
    return {
        from: ((finalValues.length > 0) && finalValues[0]) ? new Date(finalValues[0]) : undefined,
        to: ((finalValues.length > 1) && finalValues[1]) ? new Date(finalValues[1]) : undefined,
    };
};

export const convertValueToDate = (value?: string): Date | undefined => {
    if (!value) {
        return undefined;
    }

    return new Date(value);
};

export const convertDatesToValues = (rangeDates: IDateRange): string[] => {
    const from: string | undefined = rangeDates.from ? String(rangeDates.from) : undefined;
    const to: string | undefined = rangeDates.to ? String(rangeDates.to) : undefined;

    if (from) {
        if (to) {
            return [from, to];
        } else {
            return [from];
        }
    }

    if (to) {
        return ['', to];
    }

    return [];
};


export const convertDateToValue = (date?: Date): string | undefined => {
    if (!date) {
        return undefined;
    }

    return String(date);
};

export const convertValuesToTags = (strings?: string[]): Tag[] => {
    if (!strings) {
        return [];
    }
    const tags: Tag[] = [];
    let tag: Tag | undefined;
    strings.forEach(str => {
        if (!tag) {
            tag = {
                key: str,
                value: '',
            };
            tags.push(tag);
        } else {
            tag.value = str;
            tag = undefined;
        }
    });
    return tags;
};

export const convertTagsToValues = (tags?: Tag[]): string[] => {
    if (!tags) {
        return [];
    }
    const strings: string[] = [];
    tags.forEach(tag => {
        strings.push(tag.key);
        strings.push(tag.value);
    });
    return strings.length > 0 ? strings : [];
};

const updateItemsMap = (item: IItem, itemsMap: IItemsMap) => {
    itemsMap[item.name] = item;
    switch (item.itemType) {
        case FormItemType.radio:
            item.options.forEach((option: IRadioOption) => {
                option.subItems.forEach(subItem => {
                    updateItemsMap(subItem, itemsMap);
                });
            });
            break;

        case FormItemType.box:
            item.subItems.forEach(subItem => {
                updateItemsMap(subItem, itemsMap);
            });
            break;
    }
};

export const createInitialItemsMap = (topItems: IItem[]): IItemsMap => {
    const itemsMap: IItemsMap = {};
    topItems.forEach((item: IItem) => {
        updateItemsMap(item, itemsMap);
    });
    fixSelectedRadioOption(itemsMap);
    return itemsMap;
};

export const cloneItemsMap = (itemsMap: IItemsMap, newItem?: IItem): IItemsMap => {
    const dupItemsMap: IItemsMap = { ...itemsMap };
    if (newItem) {
        dupItemsMap[newItem.name] = newItem;
    }
    return dupItemsMap;
};
