import { FC, ReactNode, useCallback, useMemo } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { IOnFieldChanged, IFullErrorInfo, IMultiInputItem } from '../../CustomForm.interface';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Input, Stack } from '../../../../../design-system/components-v2';
import ItemComponentBottomArea from '../helperComponents/ItemComponentBottomArea';

export interface IMultiInputItemComponentProps {
    item: IMultiInputItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const MultiInputItemComponent: FC<IMultiInputItemComponentProps> = ({
    item,
    onFieldChanged,
    showErrors,
    errorInfo,
    skipTitle,
}) => {
    const { state } = item;
    const values: string[] = useMemo(() => state.value || [], [state.value]);
    const subErrors: IFullErrorInfo[] | undefined = useMemo(() => errorInfo?.subErrors, [errorInfo?.subErrors]);
    const disabled: boolean = useMemo(() => !!(state.disabled || state.readonly), [state.disabled, state.readonly]);
    const { t } = useTranslation();

    const onChangeValues = useCallback(
        (values: string[]) => {
            onFieldChanged({ ...item, state: { ...state, value: values } });
        },
        [item, onFieldChanged, state],
    );

    const getInputError = useCallback(
        (index: number): IFullErrorInfo | undefined => {
            if (!subErrors) {
                return undefined;
            }
            return subErrors.find((errorInfo) => errorInfo.name === String(index));
        },
        [subErrors],
    );

    const onValueChange = useCallback(
        (text: string, index: number) => {
            if (index > values.length - 1) {
                return;
            }

            const dupValues: string[] = [...values];
            dupValues[index] = text;
            onChangeValues(dupValues);
        },
        [onChangeValues, values],
    );

    const onValueAdd = useCallback(() => {
        const dupValues: string[] = [...values];
        dupValues.push('');
        onChangeValues(dupValues);
    }, [onChangeValues, values]);

    const onValueRemove = useCallback(
        (index: number) => {
            if (index > values.length - 1) {
                return;
            }
            const dupValues: string[] = [...values];
            dupValues.splice(index, 1);
            onChangeValues(dupValues);
        },
        [onChangeValues, values],
    );

    const createValueRow = useCallback(
        (index: number): ReactNode => {
            if (index > values.length - 1) {
                return;
            }
            const text = values[index];
            const error: IFullErrorInfo | undefined = getInputError(index);
            return (
                <Stack direction={'row'} fullWidth alignItems={'flex-start'} spacing={2} key={`${item}.input${index}`}>
                    <FormStyled.MultiInputEntryContainer direction={'column'} fullWidth>
                        <Input
                            fullWidth
                            placeholder={t('CUSTOM_FORM.COMPONENTS.MULTI_INPUT_VALUE_PLACEHOLDER')}
                            onChange={(event) => onValueChange(event.target.value, index)}
                            value={text}
                            disabled={disabled}
                            isError={!!(showErrors && error)}
                        />
                        <ItemComponentBottomArea state={state} showErrors={showErrors} errorInfo={errorInfo} />
                        {state.charsLimit !== undefined && (
                            <FormStyled.TextCounter>
                                {state.value?.length || 0} / {state.charsLimit}
                            </FormStyled.TextCounter>
                        )}
                    </FormStyled.MultiInputEntryContainer>
                    <IconButton
                        iconProps={{ name: 'remove', size: 16 }}
                        disabled={disabled}
                        style={{ flexGrow: 0, paddingTop: '7px' }}
                        size='small'
                        onClick={() => onValueRemove(index)}
                    />
                </Stack>
            );
        },
        [disabled, errorInfo, getInputError, item, onValueChange, onValueRemove, showErrors, state, t, values],
    );

    return (
        <FormStyled.TopItem widthInfo={state.widthInfo}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            {values.length > 0 && (
                <Stack direction={'column'} spacing={3} fullWidth padding={[2, 0, 0, 0]}>
                    {values.map((value, index) => createValueRow(index))}
                </Stack>
            )}
            <Button
                variant={'contained'}
                disabled={disabled}
                iconProps={{ name: 'plus' }}
                onClick={onValueAdd}
            ></Button>
        </FormStyled.TopItem>
    );
};
