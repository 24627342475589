import { FC, useEffect, useMemo, useState } from 'react';
import { Modal, Stack, Tabs } from '../../../../design-system/components-v2';
import { IGlobalDevModalProps } from '../../../../components/GlobalModals/GlobalModals';
import { DevModalRegistry } from '../DevModalRegistry';
import { ITabItemProps } from '../../../../design-system/components-v2/Tabs/Tabs.types';

const DEV_MODAL_SELECTED_TAB_STORAGE_KEY = 'dev-modal-selected-tab';

export const DevModalDialog: FC<IGlobalDevModalProps> = ({ onClose }) => {
    const devModalTabs = useMemo(() => {
        return DevModalRegistry.getTabs();
    }, []);
    const [selectedTabName, setSelectedTabName] = useState<string | undefined>(undefined);
    const tabOptions: ITabItemProps[] = useMemo(() => {
        return devModalTabs.map((tab) => {
            return {
                label: tab.title,
                value: tab.title,
            };
        });
    }, [devModalTabs]);

    useEffect(() => {
        const storedTabTitle = localStorage.getItem(DEV_MODAL_SELECTED_TAB_STORAGE_KEY) ?? undefined;
        const storedTab =
            storedTabTitle !== undefined ? devModalTabs.find((tab) => tab.title === storedTabTitle) : undefined;
        if (storedTab) {
            setSelectedTabName(storedTab.title);
        } else {
            setSelectedTabName(devModalTabs[0].title);
        }
    }, [devModalTabs]);

    const InnerComponent: React.FC = useMemo((): React.FC => {
        const selectedTabComponent = devModalTabs.find((tab) => tab.title === selectedTabName)?.component;
        return selectedTabComponent ? selectedTabComponent : () => null;
    }, [selectedTabName, devModalTabs]);

    return (
        <Modal.ModalDialog isOpen={true} width={'xl'} shouldCloseOnOverlayClick={false} onRequestClose={onClose}>
            <Modal.ModalHeader title={'Dev Modal'} onClose={onClose} />
            <Modal.ModalContent fullHeight>
                <Stack
                    fullWidth
                    style={{
                        height: '30px',
                    }}
                >
                    <Tabs
                        onTabSelected={(e: ITabItemProps) => {
                            localStorage.setItem(DEV_MODAL_SELECTED_TAB_STORAGE_KEY, String(e.value));
                            setSelectedTabName(String(e.value));
                        }}
                        tabs={tabOptions}
                        selectedTab={selectedTabName}
                    />
                </Stack>
                <Stack style={{ height: '450px' }}>
                    <InnerComponent />
                </Stack>
            </Modal.ModalContent>
        </Modal.ModalDialog>
    );
};
