import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getColors } from '../Widgets/helpers';
import { IGenericWidgetDataItem, IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
import { baseTooltipStyle } from './Utils';
import { useProfiler } from '@sentry/react';

export const getColumnBarChartOptions = (
    items: IGenericWidgetDataItem[],
    chartType: string,
    chartRef: React.MutableRefObject<any>,
) => {
    return {
        series: [
            {
                colors: getColors(items),
                colorByPoint: true,
                groupPadding: 0,
                data: items?.map((item) => [item.key, item.value]),
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.y:.1f}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Verdana, sans-serif',
                    },
                },
            },
        ],

        colors: getColors(items),
        chart: {
            type: chartType,
            backgroundColor: 'transparent',
            style: {
                fontFamily: 'Overpass',
            },
            pointCount: 1,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            column: {
                allowPointSelect: true,
                dataLabels: {
                    enabled: true,
                },
            },
            series: {
                cursor: 'pointer',
                events: {
                    click: (event: any) => {
                        const itemKey = event.point.options.name;
                        const item = items.filter((item) => item.key === itemKey);
                        item.length > 0 && item[0].onclick && item[0].onclick();
                    },
                },
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
            },
        },
        xAxis: {
            type: 'category',
            labels: {
                rotation: -45,
                style: {
                    fontSize: '10px',
                    fontFamily: 'Verdana, sans-serif',
                },
            },
            tickLength: 0,
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        title: {
            align: 'left',
            style: {
                fontSize: '16px',
            },
            text: '',
        },
        tooltip: {
            enabled: true,
            pointFormat: '<b>{point.y}</b>',
            style: baseTooltipStyle,
        },
    };
};

const ColumnChart: React.FC<IGenericWidgetDataProps> = (props) => {
    useProfiler(`ColumnChart|${props.header}`);
    const items = props?.data?.items;
    const chartRef = useRef<any>();
    const [chartWidth, setChartWidth] = useState<null | number | string>(null);
    const chartOptions = useMemo(
        () => items && (props.customOptions || getColumnBarChartOptions(items, 'column', chartRef)),
        [items, props.customOptions],
    );

    const containerProps = useMemo(() => {
        return {
            style: {
                minWidth: chartWidth,
                display: 'flex',
                position: 'relative',
                height: '100%',
                width: '100%',
            },
        };
    }, [chartWidth]);
    useEffect(() => {
        const chart = chartRef.current?.chart;
        const width = (chart.pointCount || 1) * 30;
        setChartWidth(width || '100%');
        if (chart) chart.reflow();
    }, [chartRef]);

    return (
        <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={containerProps}
        />
    );
};
export default ColumnChart;
