import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSfp = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2.5 2.5c2 0 4-1 5.5-2 1.5 1 3.5 2 5.5 2V9c0 4-5.5 6.5-5.5 6.5S2.5 13 2.5 9V2.5Z"
      stroke="currentColor"
      strokeOpacity={0.9}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 6.5h3L10 8m-.5-3.5C8.5 4.5 8 4.5 7 7S5.5 9.5 4.5 9.5M10 8l1.5 1.5M10 8l1.5-1.5M10 8 8.5 9.5"
      stroke="currentColor"
      strokeOpacity={0.9}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSfp);
export default ForwardRef;
