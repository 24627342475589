import { AssessmentCloudAccountType, Vendors } from '../consts/vendors';

export const EnvironmentCodes: any = {
    1: 'AWS',
    3: 'AWSGOV',
    6: 'AWSCHINA',
    7: 'AZURE',
    9: 'AZURECHINA',
    10: 'GOOGLE',
    11: 'KUBERNETES',
    12: 'TERRAFORM',
    13: 'GENERIC',
    14: 'KUBERNETESRUNTIMEASSURANCE',
    15: 'SHIFTLEFT',
    20: 'DOCKER',
    18: 'ALIBABA',
    19: 'CFT',
    22: 'OCI',
};

export function getIconNameByEnvironmentCode(code: number): string {
    return EnvironmentCodes[code]?.toLowerCase();
}

export const getAccountTypeNumberById = (platformId: Vendors): number => {
    let _platform = platformId.toLowerCase();
    _platform = _platform === 'gcp' ? 'google' : _platform;
    for (const cloudAccountType in AssessmentCloudAccountType) {
        const type = AssessmentCloudAccountType[cloudAccountType];
        if (type.toLowerCase() === _platform) {
            return +cloudAccountType;
        }
    }
    return 0;
};
