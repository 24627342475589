import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IDefaultRouteInfo } from './Interface';

const PATH_OVERVIEW_DASHBOARDS = buildPath('overview-dashboards');
const PATH_OVERVIEW_DASHBOARDS_GLOBAL_DEFAULT_ROUTE = buildPath(
    PATH_OVERVIEW_DASHBOARDS,
    'default-global-dashboard-route',
);

export const OverviewDashboardsRegistry = {
    addDefaultGlobalDashboardRoute: (id: string, routeInfo: IDefaultRouteInfo, position: number) => {
        globalAddinContainer.add<IDefaultRouteInfo>(PATH_OVERVIEW_DASHBOARDS_GLOBAL_DEFAULT_ROUTE, [
            {
                id,
                content: routeInfo,
                position,
            },
        ]);
    },

    getDefaultGlobalDashboardRoute: (): string | undefined => {
        const routeInfos: IDefaultRouteInfo[] = globalAddinContainer.get<IDefaultRouteInfo>(
            PATH_OVERVIEW_DASHBOARDS_GLOBAL_DEFAULT_ROUTE,
        );
        for (let i = 0; i < routeInfos.length; i++) {
            const routeInfo: IDefaultRouteInfo = routeInfos[i];
            if (!routeInfo.hasRoutePermissions || routeInfo.hasRoutePermissions()) {
                return routeInfo.route;
            }
        }
    },
};
