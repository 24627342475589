import React, { useMemo } from 'react';
import { IWelcomeItem, IWelcomePageObj, IWelcomePageSection } from './WelcomePage.interface';
import './WelcomePage.scss';
import WelcomePageCard from './WelcomePageCard';
import { A } from 'common/components/Anchor/Anchor';
import Carousel from '../Carousel/Carousel';
import useDocumentSize from '../../hooks/useDocumentSize';
import { getUserService } from '../../interface/services';

export const WelcomePage: React.FC<IWelcomePageObj> = (welcomePageObj: IWelcomePageObj) => {
    const { title, subtitle, sections } = welcomePageObj;
    const { width } = useDocumentSize();
    const { itemsToDisplay, gap } = useMemo(() => {
        if (width >= 1450) return { itemsToDisplay: 3, gap: 30 };
        if (width >= 960) return { itemsToDisplay: 2, gap: 20 };
        return { itemsToDisplay: 1, gap: 10 };
    }, [width]);

    return (
        <div
            className='WelcomePage px-7 lg:p-8 lg:py-6 xl:px-9 xl:py-8 !absolute inset-0 lg:media--xs'
            // style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className='mb-10'>
                <div className='text-2xl font-light uppercase xl:text-3xl'>{title}</div>
                <div className='max-w-[70ch]'>{subtitle}</div>
            </div>
            {sections.map((section: IWelcomePageSection, sectionIndex: number) => {
                const { items, title, subtitle, carousel } = section;
                const filteredItems = items.filter((item: IWelcomeItem) => {
                    if (typeof item.permission === 'function') {
                        return item.permission();
                    } else if (Array.isArray(item.permission)) {
                        return getUserService().hasPermission(item.permission);
                    } else {
                        return true;
                    }
                });
                return (
                    <div key={`welcome-page-section-${sectionIndex}-${title}`} className='mb-7 lg:mb-9 xl:mb-10'>
                        <div className='mb-5 lg:mb-7 xl:mb-8'>
                            <div className='font-bold'>{title}</div>
                            {subtitle && <div className=''>{subtitle}</div>}
                        </div>
                        {carousel ? (
                            <Carousel itemsToDisplay={itemsToDisplay} gap={gap}>
                                {items.map((item: IWelcomeItem, cardIndex: number) => {
                                    const { title, linkTo } = item;
                                    const url = linkTo || '/';
                                    return (
                                        <div
                                            className='relative'
                                            key={`welcome-page-card-${sectionIndex}-${cardIndex}-${title}`}
                                        >
                                            {linkTo ? (
                                                <A href={url}>
                                                    <WelcomePageCard {...item} />
                                                </A>
                                            ) : (
                                                <WelcomePageCard {...item} />
                                            )}
                                        </div>
                                    );
                                })}
                            </Carousel>
                        ) : (
                            <div className='mb-8 grid gap-2 sm:gap-7 xl:gap-9 lg:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
                                {filteredItems.map((item: IWelcomeItem, cardIndex: number) => {
                                    const { title, linkTo } = item;
                                    const url = linkTo || '/';
                                    return (
                                        <div
                                            className='relative'
                                            key={`welcome-page-card-${sectionIndex}-${cardIndex}-${title}`}
                                        >
                                            {linkTo ? (
                                                <A href={url}>
                                                    <WelcomePageCard {...item} />
                                                </A>
                                            ) : (
                                                <WelcomePageCard {...item} />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default WelcomePage;
