import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { IOnFieldChanged, IFullErrorInfo, ICheckboxItem } from '../../CustomForm.interface';
import { Checkbox } from '../../../../../design-system/components-v2';
import { getStringFromBoolean, isBoolean } from '../../../../utils/types';
import ItemComponentBottomArea from '../helperComponents/ItemComponentBottomArea';

export interface ICheckboxItemComponentProps {
    item: ICheckboxItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const CheckboxItemComponent: FC<ICheckboxItemComponentProps> = ({
    item,
    onFieldChanged,
    showErrors,
    errorInfo,
    skipTitle,
    isInner,
}) => {
    const { state } = item;
    return (
        <FormStyled.TopCheckItem isInner={isInner} widthInfo={state.widthInfo}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <Checkbox
                checked={isBoolean(state.value)}
                onChange={(e) =>
                    onFieldChanged({ ...item, state: { ...state, value: getStringFromBoolean(e.target.checked) } })
                }
                disabled={state.disabled || state.readonly}
            />
            <ItemComponentBottomArea state={state} showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopCheckItem>
    );
};
