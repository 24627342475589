import { FC, useEffect, useState } from 'react';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/design-system/components-v2';
import { IExclusionAccountNumberProps } from '../../helpers/exclusions.interfaces';
import { formatAccountNumber } from 'common/components/exclusions/helpers/exclusions.utils';

const ExclusionByAccountNumber: FC<IExclusionAccountNumberProps> = ({
    isEnabled,
    selectedRuleset,
    onChange,
    selectedOption,
    tooltip,
}) => {
    const { t } = useTranslation(I18nExclusion);
    const [accountNumber, setAccountNumber] = useState<string>();

    useEffect(() => {
        const handlePreselectedOption = () => {
            if (selectedOption) {
                const formattedAccountNumber = formatAccountNumber(selectedOption);
                if (formattedAccountNumber) {
                    setAccountNumber(formattedAccountNumber);
                    onChange(formattedAccountNumber);
                }
            }
        };
        handlePreselectedOption();
    }, [selectedOption, onChange]);

    const handleAccountNumberChange = (value: string) => {
        setAccountNumber(value);
        onChange(value);
    };

    return (
        <Input
            label={t('MODAL.TOPICS.ACCOUNT_VENDOR_ID.TITLE')}
            labelProps={{ tooltip: tooltip }}
            type='text'
            value={accountNumber}
            disabled={!isEnabled || !selectedRuleset}
            placeholder={t('MODAL.GENERAL.TYPE_HERE')}
            onChange={(e) => handleAccountNumberChange(e.target.value)}
            tooltip={tooltip}
            dataAid='exclusion-account-number'
        />
    );
};
export default ExclusionByAccountNumber;
