import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import SimpleText from 'common/components/ProtectedAssets/Renderers/SimpleText';
import { theme } from 'common/design-system/theme/theme';
import i18n from 'i18next';
import { getDateOnlyDateFormat, isPassedDate } from 'common/erm-components/utils/dates';

export const ExpirationDateCellRender: React.FC<ICellRendererParams> = (params) => {
    const expirationDate: Date | undefined = params.value;
    if (!expirationDate) {
        return null;
    }

    const expired = isPassedDate(expirationDate);
    const style: React.CSSProperties = {
        color: expired ? theme().palette.text.alert : undefined,
    };
    const dateText = getDateOnlyDateFormat(expirationDate);
    const suffix = expired ? i18n.t('COMMON.EXPIRED_SUFFIX') : '';
    const fullText = `${dateText}${suffix}`;

    return <SimpleText value={fullText} style={style} />;
};