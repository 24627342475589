import React from 'react';
import { changeUrl } from '../../utils/http';
import { isNil } from '../../utils/helpFunctions';

const RedirectComponent: React.FC<{ to: string; ignorePreviousSearchParams: boolean }> = ({
    to,
    ignorePreviousSearchParams,
}) => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const baseURL = window.location.origin;
    if (ignorePreviousSearchParams || currentSearchParams.size === 0) {
        changeUrl(to);
    } else {
        if (currentSearchParams.size > 0) {
            const urlObject = new URL(to, baseURL);
            const searchParamsInNewURL = new URLSearchParams(urlObject.search);
            if (searchParamsInNewURL.size > 0) {
                for (const key of searchParamsInNewURL.keys()) {
                    const paramValue = searchParamsInNewURL.get(key);
                    if (!isNil(paramValue)) {
                        currentSearchParams.set(key, paramValue);
                    }
                }
            }
            changeUrl(urlObject.pathname + `?${currentSearchParams.toString()}`);
        }
    }
    return <div></div>;
};

export default RedirectComponent;
