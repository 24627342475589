import { Notification, NOTIFICATIONS_TARGET_IDS_DTO_MAPPER, PromiseNotification } from './notifications';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getService } from '../extensibility/AddinContainer';
import { ICloudAccount, IUser, IUserAccount } from './user';
import { Rule } from './RulesManagement';
import { IItemPermissions } from './account';
import { IAssessment, IRuleset } from './ruleset';
import { ENTITY_SERVICE_ID, IEntityService, ICloudAccount as ICloudAccountExclusions } from './data_services';
import { IMenuSectionItem } from './menu';
import IframeMessageModel from './IFrame.message.model';
import dayjs from 'dayjs';
import { IComplianceNotification } from './complianceNotification';
import { IPolicy } from './policy';
import {
    IGetAllConfigurationsModel,
    IIntegrationsOrganizationalUnit,
} from '../module_interface/settings/integrations/Integrations';
import { IExclusion, IRegion, IOrganizationalUnit, IRule, IFieldFilter, IRuntimeRequestPayload } from './exclusion';
import { ITenableConfiguration } from '../module_interface/settings/integrations/configurations.interface';
import { IUsersService, USERS_SERVICE_ID } from './userService';
import { IServiceAccountsService, SERVICE_ACCOUNTS_SERVICE_ID } from './serviceAccountService';
import { IRolesService, ROLES_SERVICE_ID } from './rolesService';
import { IEmailNotifications, ISettings } from '../module_interface/settings/emailNotifications/EmailPageInterfaces';
import { ISecurityGroupsService, SECURITY_GROUPS_SERVICE_ID } from './securityGroupsService';
import { AGENTS_SERVICE_ID, IAgentsService } from './agentsService';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IReactPageRoute } from './routing';
import { IApplicationReducer } from './redux';

export const STORE_SERVICE_ID = 'StoreService';

export function getStoreService(): IStoreService {
    return getService<IStoreService>(STORE_SERVICE_ID);
}

/**
 * Use this service to access the Redux store and the dispatch function
 */
export interface IStoreService {
    readonly store: any;
    readonly state: any;

    dispatch(action: any): void;

    /*
    Convenience function to get the state and the dispatch function simultaneously
     */
    getReduxTools(): {
        dispatch: (action: any) => void;
        state: any;
    };
}

export const ALERTS_SERVICE_ID = 'AlertsService';

export function getNotificationsService(): INotificationService {
    return getService<INotificationService>(ALERTS_SERVICE_ID);
}

export function getEntityService(): IEntityService {
    return getService<IEntityService>(ENTITY_SERVICE_ID);
}

/***
 * Use this service to display alerts (toasts) to the user
 */
export interface INotificationService {
    addNotification(alert: Notification): void;

    clearAllNotifications(): void;

    success(title: string, text: string): void;

    error(title: string, text: string): void;

    info(title: string, text: string): void;

    warning(title: string, text: string): void;

    runPromise(promiseNotification: PromiseNotification): void;
}

export const HTTP_SERVICE_ID = 'HttpService';

export function getHttpService(): IHttpService {
    return getService<IHttpService>(HTTP_SERVICE_ID);
}

export const HTTP_SERVICE_API_ID = 'HttpApiService';

export function getHttpApiService(): IHttpService {
    return getService<IHttpService>(HTTP_SERVICE_API_ID);
}

export type CachePayloadPreparationFunction<P> = (payload: P) => P;

export const DEFAULT_MIN_TIME_BETWEEN_REFRESH_AHEAD = 30;

export interface ICacheEntry<T = any, P = any> {
    uniqueKey: string;
    route: string;
    payload?: P;
    isRemoved?: boolean;
    promise: Promise<AxiosResponse<T>>;
    tags?: string[];
    creationTime: dayjs.Dayjs;
    resolutionTime?: dayjs.Dayjs;
    alternativeEntry?: ICacheEntry<T, P>;
}

export interface ICacheLookupResult<T> {
    entry: ICacheEntry<T>;
    isValid: boolean;
    remaining?: number;
}

export interface LoggingConfig {
    useLogging: boolean;
}

export interface ICachingConfig<P = any> {
    useCache: boolean;
    allowRefreshAhead?: boolean;
    tags?: string[];
    dataAgeLimit?: number; //Time in seconds for which the cache is valid
    preparePayloadForCache?: CachePayloadPreparationFunction<P>; //Fix payload to make it stable for cache, e.g. remove filter field creationTime
}

/***
 * Use this service to make calls to the API server.
 */
export interface IHttpServiceConfig<P = any> {
    path?: string;
    requestObject?: AxiosRequestConfig;
    publicMode?: boolean;
    returnAsAxiosResponse?: boolean;
    loggingConfig?: LoggingConfig;
    cachingConfig?: ICachingConfig<P>;
    errorCodeIgnoreList?: number[];
}

export interface IHttpService {
    /**
     * Same as request but presets the method to POST
     */
    post<T>(
        path: string,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T>;

    post<T>(serviceConfig: IHttpServiceConfig): Promise<T>;

    /**
     * Same as request but presets the method to PUT
     */
    put<T>(
        path: string,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T>;

    put<T>(serviceConfig: IHttpServiceConfig): Promise<T>;

    /**
     * Same as request but presets the method to GET
     */
    get<T>(
        path: string,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T>;

    get<T>(serviceConfig: IHttpServiceConfig): Promise<T>;

    /**
     * Same as request but presets the method to DELETE
     */
    delete<T>(
        path: string,
        requestObject?: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T>;

    delete<T>(serviceConfig: IHttpServiceConfig): Promise<T>;

    /***
     * Makes an HTTPS call to the API server
     * @param path - The relative path in the server to call (e.g. /myApi/data)
     * @param requestObject - The Axios request configuration. You can change the target server from there by assigning
     * a value to baseURL. If no value is assigned the api server baseURL is used.
     * @param {Boolean} serviceConfig.publicMode - publicMode: If true, assumes that the caller does not need to login for the target API (e.g. forgot password).
     * @param {Boolean} serviceConfig.returnAsAxiosResponse - returnAsAxiosResponse: If true, The response will be full AxiosResponse, other wise you will get the data only.
     * @param customHandleError - Will call this function with the error from the HTTPS call instead of using the default handler.
     */
    request<T>(
        path: string,
        requestObject: AxiosRequestConfig,
        serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T>;

    /**
     * Returns true if the user is logged in
     */
    getIsSessionAlive(): Promise<boolean>;

    /***
     * Clear cache by tag. multiple routes could have the same tag.
     */
    clearCacheByTag(tag: string, method?: string, skipSendToWebapp?: boolean): void;

    /***
     * Clear cache by path.
     */
    clearCacheByPath(path: string, method?: string): void;

    /***
     * Clear the entire cache.
     */
    clearEntireCache(): void;

    /***
     * Clear the entire cache.
     */
    getCacheSize(): number;

    /***
     * Check if the App has connectivity to the API server
     */
    checkApiConnectivity(): Promise<boolean>;
}

/***
 * Use this service to do user related things like getting permissions, roles, etc
 */

export const USER_SERVICE_ID = 'UserService';

export function getUserService(): IUserService {
    return getService<IUserService>(USER_SERVICE_ID);
}

export interface IUserService {
    hasPermission(permissions: IItemPermissions): boolean;

    hasAnyPermission(permissions: IItemPermissions): boolean;

    getIsSuperUser(): boolean;

    getUser(): IUser;

    getAccount(): IUserAccount;

    getCloudguardAccount(): ICloudAccount;
}

export const BREADCRUMBS_SERVICE_ID = 'BreadcrumbsService';

export function getBreadcrumbsService(): IBreadcrumbsService {
    return getService<IBreadcrumbsService>(BREADCRUMBS_SERVICE_ID);
}

export interface IBreadcrumbsService {
    setBreadcrumbsLastChildren(lastChildren?: Array<string>): void;

    getBreadcrumbsLastChildren(): Array<string> | undefined;
}

export const RULES_MANAGEMENT_SERVICE_ID = 'RulesManagementService';

export interface IRuleManagementService {
    getRulesViewsFromServer(): Promise<Rule[]>;

    getRulesFromServer(): Promise<Rule[]>;

    getIndividualRuleFromServer(id: string): Promise<Rule>;

    deleteRuleOnServer(id: string): void;

    postRuleToServer(rule: Rule): void;

    editRuleOnServer(rule: Rule): Promise<Rule>;
}

export function getRuleManagementService(): IRuleManagementService {
    return getService<IRuleManagementService>(RULES_MANAGEMENT_SERVICE_ID);
}

export enum LogLevel {
    //Trace,
    Debug = 1,
    Info = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
}

export const LOG_LEVEL_NAMES = ['Trace', 'Debug', 'Info', 'Warning', 'Error', 'Critical'];

export const LOGGER_SERVICE_ID = 'LoggerService';

export function getLoggerService(): ILoggerService {
    return getService<ILoggerService>(LOGGER_SERVICE_ID);
}

export interface ILoggerService {
    log(level: LogLevel, message: string | string[], action?: string, tags?: string[]): Promise<void>;

    debug(message: any, action?: string, tags?: string[]): Promise<void>;

    info(message: any, action?: string, tags?: string[]): Promise<void>;

    warning(message: any, action?: string, tags?: string[]): Promise<void>;

    error(message: any, action?: string, tags?: string[]): Promise<void>;

    critical(message: any, action?: string, tags?: string[]): Promise<void>;
}

export const RULESET_SERVICE_ID = 'RulesetService';

export function getRulesetService(): IRulesetService {
    return getService<IRulesetService>(RULESET_SERVICE_ID);
}

export interface IRulesetService {
    getAllRulesets(useCache?: boolean): Promise<IRuleset[]>;
    deleteRuleset(rulesetID: string | number): Promise<AxiosResponse<any>>;
}

export const ASSESSMENT_SERVICE_ID = 'AssessmentService';

export function getAssessmentService(): IAssessmentService {
    return getService<IAssessmentService>(ASSESSMENT_SERVICE_ID);
}

export interface IAssessmentService {
    runAssessment(data: IAssessment, useCache?: boolean): Promise<{ id: string }>;
    runAssessmentMultipleEnvironments(data: IAssessment, useCache?: boolean): Promise<{ id: string }>;
}

export const SHIFTLEFT_SERVICE_ID = 'shiftLeft_service_id';

export interface IShiftLeftService {
    getPolicies(useCache?: boolean, errorCodeIgnoreList?: number[]): Promise<IPolicy[]>;

    setShiftLeftPolicy(data: IPolicy[]): Promise<IPolicy[]>;

    updateShiftLeftPolicy(data: IPolicy[]): Promise<IPolicy[]>;
}
export function getShiftLeftService(): IShiftLeftService {
    return getService<IShiftLeftService>(SHIFTLEFT_SERVICE_ID);
}

export const POLICY_SERVICE_ID = 'PolicyService';
export const SERVERLESS_POLICY_SERVICE_ID = 'ServerlessPolicyService';
export const COMPLIANCE_NOTIFICATION_SERVICE_ID = 'ComplianceNotificationService';

export function getPolicyService(): IPolicyService {
    return getService<IPolicyService>(POLICY_SERVICE_ID);
}

export function getServerlessPolicyService() {
    return getService<IPolicyService>(SERVERLESS_POLICY_SERVICE_ID);
}

export function getComplianceNotificationsService(): IComplianceNotificationsService {
    return getService<IComplianceNotificationsService>(COMPLIANCE_NOTIFICATION_SERVICE_ID);
}

export interface IComplianceNotificationsService {
    getNotifications(useCache?: boolean): Promise<IComplianceNotification[]>;
}
export interface ISetPolicyResponse {
    errorMessage: string;
    id: string;
}
export interface IPolicyService {
    getCompliancePolicies(useCache?: boolean): Promise<IPolicy[]>;
    setPolicy(data: IPolicy[]): Promise<ISetPolicyResponse[]>;
    updatePolicy(data: IPolicy[]): Promise<ISetPolicyResponse[]>;
    getPolicy(id: string, useCache?: boolean): Promise<IPolicy | undefined>;
    deletePolicy(policyId: string): Promise<ISetPolicyResponse[]>;
    getPolicies(useCache?: boolean, errorCodeIgnoreList?: number[]): Promise<IPolicy[]>;
    clearCache(): void;
}

export const ACCOUNT_SERVICE_ID = 'AccountService';

export function getAccountService(): IAccountService {
    return getService<IAccountService>(ACCOUNT_SERVICE_ID);
}

export interface IAccount {
    roles?: string[];
    accountName?: string;
    accountId?: number;
}
//This service will get you account related data
export interface IAccountService {
    getLicense(useCache?: boolean): Promise<ILicense>;

    hasMspActivated(): Promise<boolean>;

    hasActiveFeature(activeFeature: string): boolean;

    getAccountAndRoles(useCache?: boolean): Promise<IAccount[]>;
}

export type ILicenseFeatureTier = 'Trial' | 'Basic' | 'Advanced' | 'Premium';

export interface ILicense {
    planName: string;
    agents: number;
    instances: number;
    users: number;
    trialEnd: string;
    trialExtensionCount: number;
    maxTrialExtensionsAllowed: number;
    featureTier: ILicenseFeatureTier;
    maxClarityEntriesAllowed: number;
    clarityEntriesCount: number;
    networkEnabled: boolean;
    complianceEnabled: boolean;
    complianceUpdatedBy: string;
    complianceUpdatedDate?: string;
    canUpdateAccountFeatures: boolean;
    iamsafeEnabled: boolean;
    enterpriseEnabled: boolean;
    enterpriseUpdatedBy: string;
    enterpriseUpdatedDate: string;
    fimEnabled: boolean;
    allowedUsers?: number;
    poAssets?: number;
    agentsEnabled: boolean;
}

export const SIDEBAR_SERVICE_ID = 'SideBarService';

export interface ISideBarService {
    setVisibility(isVisible: boolean): void;

    loadComponent(componentId: string): void;
}

export function getSideBarService(): ISideBarService {
    return getService<ISideBarService>(SIDEBAR_SERVICE_ID);
}

export const CUSTOMIZATION_SERVICE_ID = 'CustomizationService';

export type IComponent = 'switch-role';

export type IScope = 'Private' | 'Public';

export interface ICustomzationObject<T> {
    data: T;
    id: string;
    component: IComponent;
    scope: IScope;
}

export type ICustomzationResponse<T> = ICustomzationObject<T>[];

export interface ICustomizationService {
    getCustomization(componentName: string): Promise<ICustomzationResponse<any>>;

    setCustomization(componentName: string, key: string, data: any, scope?: string): Promise<ICustomzationObject<any>>;

    deleteCustomization(id: string): Promise<ICustomzationResponse<any>>;

    getKeyData<T>(key: string): Promise<T | undefined>;

    setKeyData<T>(key: string, data: T): Promise<T | undefined>;

    deleteKeyData(key: string): Promise<any>;
}

export function getCustomizationService(): ICustomizationService {
    return getService<ICustomizationService>(CUSTOMIZATION_SERVICE_ID);
}

/////////////// menu service /////////////////

export const MENU_SERVICE_ID = 'MenuService';

export interface IMenuService {
    addMenuItems(mainMenuItemId: string, sectionId: string, items: IMenuSectionItem[]): void;

    removeExternalMenuItems(itemsIds: string[]): void;
}

export function getMenuService(): IMenuService {
    return getService<IMenuService>(MENU_SERVICE_ID);
}

export const WEBAPP_IFRAME_SERVICE_ID = 'WebappIframeService';

export interface IWebappViewState {
    id: string;
    context: string;
    type: string;
    metaData: { [key: string]: any };
}

export interface IWebappIframeService {
    IFrameElement: HTMLIFrameElement;
    setSrc: (src: string) => void;
    setVisibility: (isVisible: boolean) => void;
    emitMessage: (iframeMessage: IframeMessageModel) => void;
    navigate: (relativePath: string) => void;
    setDefaultWrapperRef: (ref: HTMLDivElement) => void;
    clearDefaultWrapperRef: () => void;
    setOverlayWrapperRef: (ref: HTMLDivElement, placement: IWebappIframeServicePlacement) => void;
    clearOverlayWrapperRef: () => void;

    setViewState: (viewState: IWebappViewState) => void;
    removeViewState: () => void;
    acknowledgeViewState: (viewState: IWebappViewState) => void;
}

export enum IWebappIframeServicePlacement {
    DRAWER = 'drawer',
    MODAL = 'modal',
    REACT_AND_ANGULAR = 'reactAndAngular',
}

export function getWebAppIframeService(): IWebappIframeService {
    return getService<IWebappIframeService>(WEBAPP_IFRAME_SERVICE_ID);
}

export const LOCAL_STORAGE_SERVICE_ID = 'localStorageService';

export interface ILocalStorageService {
    getFromLocalStorage: (key: string, pageID: string) => any;
    setToLocalStorage: (key: string, pageID: string, data: any) => void;
}

export function getLocalStorageService(): ILocalStorageService {
    return getService<ILocalStorageService>(LOCAL_STORAGE_SERVICE_ID);
}

export interface ISystemSnsConfiguration {
    name: string;
    configurationObj: ISnsConfigurationObj;
}

export interface ISnsConfigurationObj {
    snsTopicArn: string | null | undefined;
}

export const INTEGRATIONS_SERVICE_ID = 'integrationsServiceID';

export interface IIntegrationsService {
    getConfigurationById: (configurationId: string) => Promise<any>;
    getAllConfigurationsSlim: (useCache?: boolean) => Promise<IGetAllConfigurationsModel>;
    getAllConfigurations: (useCache?: boolean) => Promise<IGetAllConfigurationsModel>;
    validateTenableConfiguration: (tenableExternalAccountNumber: string) => Promise<any>;
    deleteTenableConfiguration: (tenableExternalAccountNumber: string) => Promise<any>;
    getTenableConfiguration: (useCache?: boolean) => Promise<ITenableConfiguration | null>;
    saveTenableConfiguration: (name: string, accessKey: string, secretKey: string, useCache?: boolean) => Promise<any>;
    getSystemSnsConfiguration: (useCache?: boolean) => Promise<ISystemSnsConfiguration[] | null>;
    postSystemSnsConfiguration: (input: string, useCache?: boolean) => Promise<any>;
    deleteSystemSnsConfiguration: () => Promise<any>;
    testGenericWebhookService: (
        endpointUrl: string,
        authType: string,
        userName: string,
        password: string,
        ignoreCertificateValidation: boolean,
        testFormatType?: string,
    ) => Promise<any>;
    saveConfiguration: (name: string, type: string, configurationObj: any, id?: string) => Promise<any>;
    deleteConfiguration: (configurationId: string) => Promise<any>;
    testSnsService: (topicArn: string) => Promise<string>;
    getAzureCloudAccounts: (useCache?: boolean) => Promise<any>;
    getGcpCloudAccounts: (useCache?: boolean) => Promise<any>;
    getSingleGcpCloudAccount: (accountId: string, useCache?: boolean) => Promise<any>;
    testGcpCloudSecuritySource: (
        CloudSecuritySourceConfig: string,
        accountId?: string,
        useCache?: boolean,
    ) => Promise<any>;
    getAwsCloudAccount: (useCache?: boolean) => Promise<any>;
    testSecurityHub: (cloudAccountId: string, region: string) => Promise<any>;
    awsAccountRegion: (useCache?: boolean) => Promise<any>;
    getInUseConfigurations: (useCache?: boolean) => Promise<any>;
    getRegisteredConfigurationsData: () => Promise<any>;
    getAllFindingsAggregations: (useCache?: boolean) => Promise<any>;
    getOrganizationalUnits: (useCache?: boolean) => Promise<IIntegrationsOrganizationalUnit>;
}

export function getIntegrationsService(): IIntegrationsService {
    return getService<IIntegrationsService>(INTEGRATIONS_SERVICE_ID);
}

export const NOTIFICATION_PAGE_SERVICE_ID = 'notificationPageService';

export interface integrationsIssue {
    id: string;
    intgrationType: string;
    errorCode: string;
    errorDescription: string;
    update: Date;
    numOfTries: number;
}
export interface CircuitBreakerStatus {
    notificationId: string;
    integrationsIssues: integrationsIssue[];
}

export interface ISlimNotification {
    createdAt: string;
    description: string;
    id: string;
    name: string;
}
export const ROLE_SERVICE_ID = 'roleService';
export interface AccessPolicy {
    name: string;
    description: string;
    port: string;
    protocol: string;
    normallyOpen: boolean;
    id: string;
    serverId: number;
    allowedIps: string[];
}
export interface Status {
    reportedIp: string;
    platform: string;
    osVersion: string;
    lastAgentContact: string;
    agentState: string;
    agentVersion: string;
}

export interface FimStatus {
    scannerState: string;
    scanRequested: string;
    baseLineCounter: number;
    lastScanStarted: string;
    lastScanEnded: string;
}
export interface IAgent {
    id: number;
    name: string;
    securityGroupIds: number[];
    fimPolicyId: number;
    status: Status;
    cloudMetadata: string;
    fimStatus: FimStatus;
    accessPolicy: AccessPolicy[];
    fimDisabled: boolean;
}

export interface IRole {
    id: number;
    name: string;
    description: string;
    users: string[];
    serviceAccounts: string[];
    permissions?: { [key: string]: string[] };
}

export interface IRoleService {
    getRoles: (useCache?: boolean) => Promise<IRole[]>;
    saveRole: (role: IRole) => Promise<void>;
    deleteRole: (roleId: string) => Promise<void>;
}

export function getRoleService(): IRoleService {
    return getService<IRoleService>(ROLE_SERVICE_ID);
}

export interface Services {
    inbound?: InboundEntityOrOutboundEntity[] | null;
    outbound?: InboundEntityOrOutboundEntity[] | null;
}
export interface InboundEntityOrOutboundEntity {
    inbound: boolean;
    id: string;
    name: string;
    description?: null;
    protocolType: string;
    port: string;
    openForAll: boolean;
    scope?: ScopeEntity[] | null;
    icmpType?: null;
    icmpv6Type?: null;
}
export interface ScopeEntity {
    type: string;
    data: Data;
}
export interface Data {
    cidr: string;
    note: string;
}

export interface ISecurityGroup {
    securityGroupId: number;
    services: Services;
    externalId: string;
    isProtected: boolean;
    securityGroupName: string;
    description: string;
    vpcId: string;
    vpcName?: null;
    regionId: string;
    cloudAccountId: string;
    cloudAccountName: string;
}

export interface IServiceAccount {
    apiKeyId: string;
    dateCreated: string;
    id: string;
    lastUsed: string;
    name: string;
    roleIds: number[];
    rolesNames?: string[];
}

export interface INotificationPageService {
    getWebhookJiraTokens: (useCache?: boolean) => Promise<any[]>;
    getAllNotifications: (useCache?: boolean) => Promise<any[]>;
    getAllNotificationsSlim: (useCache?: boolean, errorCodeIgnoreList?: number[]) => Promise<ISlimNotification[]>;
    getNotificationById: (notificationId?: string) => Promise<any>;
    getAllNotificationsCircuitBreakers: (
        useCache?: boolean,
        errorCodeIgnoreList?: number[],
    ) => Promise<CircuitBreakerStatus[]>;
    saveNotification: (notification: any, useCache?: boolean) => Promise<string>;
    deleteNotification: (notificationId: string) => Promise<void>;
    testJiraNotification: (configurations: any) => Promise<any>;
    clearIntegrationIssueInCircuitBreaker: (
        notificationId: string,
        integrationType: keyof typeof NOTIFICATIONS_TARGET_IDS_DTO_MAPPER,
    ) => void;
}

export function getNotificationPageService(): INotificationPageService {
    return getService<INotificationPageService>(NOTIFICATION_PAGE_SERVICE_ID);
}

export const EXCLUSIONS_SERVICE_ID = 'exclusionsService';

export interface IExclusionsService {
    getExclusionList: (useCache?: boolean) => Promise<IExclusion[]>;
    getRuleSets: (useCache?: boolean) => Promise<IRuleset[]>;
    getEnvironment: (useCache?: boolean) => Promise<ICloudAccountExclusions[]>;
    getOrganizationalUnit: (useCache?: boolean) => Promise<IOrganizationalUnit[]>;
    getVendorRegions: (vendor: string, useCache?: boolean) => Promise<IRegion[]>;
    getRulesForRuleset: (rulesetId: number, useCache?: boolean) => Promise<{ rules: IRule[] }>;
    saveExclusionCspm: (exclusion: any, useCache?: boolean) => Promise<any>;
    saveExclusionCiem: (exclusion: any, useCache?: boolean) => Promise<any>;
    saveExclusionRuntimeServerless: (exclusionConfig: IRuntimeRequestPayload, useCache?: boolean) => Promise<any>;
    getProtectedAssets: (freeText: string, fields?: IFieldFilter[] | null) => Promise<any>;
}

export function ExclusionsService(): IExclusionsService {
    return getService<IExclusionsService>(EXCLUSIONS_SERVICE_ID);
}

export const EVENT_TRACKING_SERVICE_ID = 'EventTrackingService';

export interface IEventTracking {
    track: (eventName: string, properties?: { [params: string]: string | number | boolean | undefined }) => void;
    pageView: () => void;
}

export function getEventTracking(): IEventTracking {
    return getService<IEventTracking>(EVENT_TRACKING_SERVICE_ID);
}

export function getUsersService(): IUsersService {
    return getService<IUsersService>(USERS_SERVICE_ID);
}

export function getRolesService(): IRolesService {
    return getService<IRolesService>(ROLES_SERVICE_ID);
}

export function getAgentsService(): IAgentsService {
    return getService<IAgentsService>(AGENTS_SERVICE_ID);
}

export function getSecurityGroupsService(): ISecurityGroupsService {
    return getService<ISecurityGroupsService>(SECURITY_GROUPS_SERVICE_ID);
}
export function getServiceAccountsService(): IServiceAccountsService {
    return getService<IServiceAccountsService>(SERVICE_ACCOUNTS_SERVICE_ID);
}

export const EMAIL_NOTIFICATION_PAGE_SERVICE_ID = 'emailNotificationPageService';
export interface IEmailNotificationPageService {
    getSettingsList: (useCache?: boolean) => Promise<ISettings>;
    getSelectedAccountsList: (useCache?: boolean) => Promise<{ cloudAccounts: string[] }>;
    updateEmailNotifications: (emailNotificationsSettings: IEmailNotifications, useCache?: boolean) => Promise<void>;
    updateSelectedAccountsList: (
        cloudAccounts: { cloudAccounts: string[] },
        useCache?: boolean,
    ) => Promise<{ cloudAccounts: string[] }>;
    selectAllCloudAccounts: (useCache?: boolean) => Promise<void>;
}
export function EmailNotificationPageService(): IEmailNotificationPageService {
    return getService<IEmailNotificationPageService>(EMAIL_NOTIFICATION_PAGE_SERVICE_ID);
}

export const USER_PAGE_SERVICE_ID = 'userPageService';
export interface IUserPageService {
    resetPasswordRequestByEmail: (payload: { email: string }) => Promise<void>;
    inviteUserIamSafety: (payload: { id: number }) => Promise<void>;
    connectUserToSso: (user: IUser) => Promise<void>;
    disableUserMfa: (payload: { userId: number }) => Promise<void>;
    setAsAccountOwner: (payload: { userId: number }) => Promise<void>;
    revokeApiKey: (payload: { userId: number }) => Promise<void>;
}
export function UserPageService(): IUserPageService {
    return getService<IUserPageService>(USER_PAGE_SERVICE_ID);
}

export const APP_ROOT_REGISTRY = 'AppRootRegistry';
export interface IAppRootRegistry {
    addRoutes: (route: Addin<IReactPageRoute>[]) => void;
    getRoutes: () => IReactPageRoute[];
    addAfterLoginEvents: (fns: Addin<(...args: any[]) => void>[]) => void;
    getAfterLoginEvents: () => ((...args: any[]) => void)[];
    addReducers: (reducers: IApplicationReducer[], id: string) => void;
    getReducers: () => IApplicationReducer[];
}
export function getAppRootRegistry(): IAppRootRegistry {
    return getService<IAppRootRegistry>(APP_ROOT_REGISTRY);
}
