export const FINDINGS_SEARCH_URL = 'Compliance/Finding/search';
export const FINDINGS_GROUPING_URL = 'Compliance/Finding/GroupsByProperties';
export const FINDINGS_AGGREGATIONS_URL = 'Compliance/Finding/searchWithCustomAggregations';
export const POSTURE_FINDINGS_PAGE_URL = 'posture-findings';
export const SECURITY_EVENTS_PAGE_URL = 'threat-and-security-events';
export const EVENTS_URL = '/alerts/findings';
export const EVENTS_TEMP_URL = '/alerts/findings-new';
export const EVENTS_LEGACY_URL = '/alerts/findings-old';

export const EVENTS_URL_POSTURE_FINDINGS = '/posture-findings';
export const EVENTS_URL_CDR = '/cdr-events';
export const EVENTS_URL_THREAT_AND_SECURITY = '/threat-and-security-events';

export const EVENT_TYPE_URL_KEY = 'eventType';

export const EVENTS_TABLE_ID = 'events-main-table';
export const EVENTS_DRAWER_ID = 'events-drawer-table';

export enum EventType {
    CDR = 'cdr',
    POSTURE_FINDINGS = 'postureFindings',
    THREAT_AND_SECURITY = 'threatAndSecurity',
    CIEM = 'ciem',
    CIEM_KUSTO = 'ciemKusto',
    ALL = 'all',
    VULNERABILITIES = 'vulnerabilities',
}

export const FINDINGS_ORIGINS = {
    INTELLIGENCE: 'Intelligence',
};

export const ColumnTypes = {
    SELECTION: 'selection',
    RULE_NAME: 'ruleName',
    SEVERITY: 'severity',
    CREATED_TIME: 'createdTime',
    CLOUD_ACCOUNT_ID: 'cloudAccountId',
    DESCRIPTION: 'description',
    PLATFORM: 'platform',
    BUNDLE_NAME: 'bundleName',
    ORIGIN: 'origin',
    MITRE: 'mitre',
    REMEDIATION: 'remediation',
    ASSIGNEE: 'assignee',
    ACTION: 'action',
    ENTITY_NAME: 'entityName',
    ENTITY_TYPE: 'entityType',
};

export enum CommonEventFields {
    acknowledged = 'acknowledged',
    action = 'action',
    additionalFields = 'additionalFields',
    alertId = 'alertId',
    alertType = 'alertType',
    assessmentHistoryId = 'assessmentHistoryId',
    bundleId = 'bundleId',
    bundleName = 'bundleName',
    category = 'category',
    cloudAccountExternalId = 'cloudAccountExternalId',
    cloudAccountId = 'cloudAccountId',
    cloudAccountType = 'cloudAccountType',
    comments = 'comments',
    createdTime = 'createdTime',
    description = 'description',
    entityExternalId = 'entityExternalId',
    entityDome9Id = 'entityDome9Id',
    entityJson = 'entityJson',
    entityName = 'entityName',
    entityNetwork = 'entityNetwork',
    entityObject = 'entityObject',
    entityTags = 'entityTags',
    entityType = 'entityType',
    entityTypeByEnvironmentType = 'entityTypeByEnvironmentType',
    findingKey = 'findingKey',
    findingLabels = 'findingLabels',
    id = 'id',
    isExcluded = 'isExcluded',
    labels = 'labels',
    labelsTooltip = 'labelsTooltip',
    lastSeenTime = 'lastSeenTime',
    magellan = 'magellan',
    occurrences = 'occurrences',
    origin = 'origin',
    organizationalUnitId = 'organizationalUnitId',
    organizationalUnitPath = 'organizationalUnitPath',
    ownerUserName = 'ownerUserName',
    region = 'region',
    remediation = 'remediation',
    remediationActions = 'remediationActions',
    rulesets = 'rulesets',
    rulesetDescription = 'rulesetDescription',
    ruleId = 'ruleId',
    ruleLogic = 'ruleLogic',
    ruleName = 'ruleName',
    rLMRuleId = 'rLMRuleId',
    scanId = 'scanId',
    severityLevel = 'severityLevel',
    severity = 'severity',
    status = 'status',
    sysEventType = 'sysEventType',
    tag = 'tag',
    timeStamp = 'timeStamp',
    version = 'version',
    updatedTime = 'updatedTime',
}

export const EVENT_DETAILS_DRAWER_KEY = {
    key: 'EVENT_DETAILS_DRAWER_KEY',
    eventTypes: {
        delete: 'delete',
    },
};

export enum EventOverviewPanels {
    CDR_UPPER_PANEL = 'cdrUpperPanel',
    CDR_RIGHT_PANEL = 'cdrRightPanel',
    CDR_CONTENT_PANEL = 'cdrContent',
    CDR_ELASTIC_UPPER_PANEL = 'cdrElasticUpperPanel',
    CDR_ELASTIC_RIGHT_PANEL = 'cdrElasticRightPanel',
    CDR_ELASTIC_CONTENT_PANEL = 'cdrElasticContent',
}
