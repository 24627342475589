import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import FindingSeverityBox from 'common/components/SeverityBox/FindingSeverityBox';
import { isNil } from 'common/utils/helpFunctions';
import {
    FindingSeverityEnum,
    getFindingSeverities,
    IFindingSeverityCounters,
    IFindingSeverityInfo,
} from 'common/consts/FindingSeverity';
import { SortOrder } from 'common/interface/general';
import { Stack } from 'common/design-system/components-v2';

const SeverityCountersCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const severityCounters = params.value as IFindingSeverityCounters;
    if (isNil(severityCounters)) {
        CellToClipboardHandler(params, '');
        return <></>;
    }
    CellToClipboardHandler(
        params,
        `${severityCounters.critical};${severityCounters.high};${severityCounters.medium};${severityCounters.low}`,
    );

    const createSeverityBox = (severityInfo: IFindingSeverityInfo) => {
        const count = severityCounters[severityInfo.key as keyof IFindingSeverityCounters] ?? 0;
        return (
            <FindingSeverityBox
                number={count}
                severity={severityInfo.key}
                size={'tiny'}
                key={severityInfo.key}
            ></FindingSeverityBox>
        );
    };

    return (
        <Stack direction='row' alignItems='center' fullHeight>
            {getFindingSeverities(SortOrder.descending, FindingSeverityEnum.low).map(
                (severityInfo: IFindingSeverityInfo) => createSeverityBox(severityInfo),
            )}
        </Stack>
    );
};

export default SeverityCountersCellRenderer;
