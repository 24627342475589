import { forwardRef, useEffect, useState, useRef } from 'react';
import { ISelectionSectionRadioProps, DirectionEnum } from './GroupSelection.types';
import RadioButton from '../RadioButton/RadioButton';
import Stack from '../Stack/Stack';
import InputLabel from '../InputLabel/InputLabel';
import Typography from '../Typography/Typography';

const GroupSelection = forwardRef<HTMLDivElement, ISelectionSectionRadioProps>((props, ref) => {
    const {
        onChange,
        value,
        defaultValue,
        options,
        label,
        labelProps,
        required,
        direction = DirectionEnum.column,
        helperText,
        isError,
    } = props;
    const [selectedValue, setSelectedValue] = useState<string | undefined>(value || defaultValue);
    const onChangeRef = useRef(onChange);

    useEffect(() => {
        if (value) {
            setSelectedValue(value);
        }
    }, [value]);

    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    const handleSelectionChange = (newValue: string) => {
        setSelectedValue(newValue);
        onChangeRef.current?.(newValue);
    };

    return (
        <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={2} ref={ref}>
            {label && <InputLabel text={label} {...labelProps} required={required} />}
            <Stack
                direction={direction}
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={direction === 'row' ? 6 : 0}
            >
                {options
                    .filter((option) => !option.isHidden)
                    .map(({ value, ...rest }) => (
                        <RadioButton
                            key={value}
                            checked={selectedValue === value}
                            onChange={() => handleSelectionChange(value)}
                            {...rest}
                        />
                    ))}
            </Stack>
            {helperText && (
                <Typography color={isError ? 'alert' : 'strong'} variant={'xs'}>
                    {helperText}
                </Typography>
            )}
        </Stack>
    );
});

GroupSelection.displayName = 'GroupSelection';

export default GroupSelection;
