import styled, { css } from 'styled-components';

export const ExpandablePanel = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    ${(props) =>
        props.theme &&
        css`
            i {
                margin-right: ${props.theme.spacing(1)}${props.theme.units};
            }
        `};
`;

export const HighlightedExpandablePanelWrapper = styled(ExpandablePanel)<{ isHighlighted: boolean }>`
    ${(props) =>
        props.isHighlighted &&
        css`
            margin-bottom: ${props.theme.spacing(2)}${props.theme.units};

            i {
                margin-bottom: ${props.theme.spacing(0.5)}${props.theme.units};
            }
        `};
`;
