import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgInProgress = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
        <path
            d='m1.438 4 1.731 1M5 3.17 4 1.437M7.5 2.5v-2M2.5 7.5h-2'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            opacity={0.8}
            d='m1.438 11 1.73-1M4 13.562l1-1.731'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            opacity={0.6}
            d='M7.5 12.5v2M11 13.562l-1-1.73'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            opacity={0.2}
            d='m11 1.438-1 1.731M11.83 5l1.732-1'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            opacity={0.4}
            d='M12.5 7.5h2M13.562 11l-1.731-1'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
const ForwardRef = forwardRef(SvgInProgress);
export default ForwardRef;
