import { forwardRef } from 'react';
import Step from '../Stepper/Step/Step';
import StepperStyles from './Stepper.styled';
import { StepperProps } from './Stepper.types';

const Stepper = forwardRef<HTMLDivElement, StepperProps>(({ steps, currentStep }, ref) => {
    return (
        <StepperStyles.StepperBox ref={ref}>
            {steps.map((step, index) => (
                <Step
                    key={index}
                    data-aid={`step-${index + 1}`}
                    number={index + 1}
                    title={step.title}
                    isCompleted={index < currentStep}
                    isCurrent={index === currentStep}
                />
            ))}
        </StepperStyles.StepperBox>
    );
});

Stepper.displayName = 'Stepper';
export default Stepper;
