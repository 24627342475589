import { Media as MediaBerries } from '@dome9/berries/react-components';
import styled from 'styled-components';

export const CollapseSectionWrapper = styled.div`
    background: white;
    padding: 10px 15px;
    border: 1px solid #e8e9eb;
    border-radius: 8px;
`;

export const CollapseSectionHeader = styled.div`
    display: flex;
    gap: 8px;
    cursor: pointer;
    justify-content: space-between;
`;

export const ToggleArrow = styled.div<{ isOpen: boolean }>`
    display: flex;
    align-items: center;
    transition: transform 0.2s linear;
    transform: rotate(${(props) => (props.isOpen ? '-180deg' : '0deg')});
`;

export const CollapseSectionContent = styled.div<{ isOpen: boolean }>`
    gap: 12px;
    box-sizing: border-box;
    overflow: auto;
    transition:
        max-height 0.2s linear,
        padding-top 0.2s linear,
        opacity 0.2s linear;
    max-height: ${(props) => (props.isOpen ? '300px' : '0px')};
    padding-top: ${(props) => (props.isOpen ? '15px' : '0px')};
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
`;

export const Media = styled(MediaBerries)<{ fontWeight?: string }>`
    & .media__title {
        margin-top: unset;
        font-weight: ${(props) => props.fontWeight};
        font-size: 13px;
    }
`;
