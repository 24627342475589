import { IFlatTreeFilterObject, ITreeFilterObject } from './DefaultFilters/DefaultFilters.interface';
import { FILTER_BOX_DISPLAY_TYPES } from './FilterPanel.consts';
import { GenericObject } from 'common/interface/general';
import { Button } from 'common/design-system/components-v2';
import { getFiltersValues } from './FilterPanel.reducer';
import { getStoreService } from '../../interface/services';
import { IFiltersValues } from './FilterPanel.interface';

export const boldSearchTerm = (searchTerm: string, textParam: string | number | boolean | null) => {
    const text = String(textParam);
    const indexOfSearchTerm = text.toLowerCase().indexOf(searchTerm.toLowerCase());
    const term = text.substr(indexOfSearchTerm, searchTerm.length);
    return text.replace(term, '<span class="font-bold">' + term + '</span>');
};

export const getBoxPosition = (target: any, elementHeight: number) => {
    if (target) {
        const targetPosition = target.getBoundingClientRect();
        if (window.innerHeight > targetPosition.bottom + elementHeight) {
            return { top: targetPosition.bottom };
        } else {
            return { top: targetPosition.top - elementHeight };
        }
    } else return {};
};

export const isIncludeSearchTerm = (searchTerm: string, options: string | Array<string>): boolean => {
    searchTerm = searchTerm.toLowerCase();
    if (!Array.isArray(options)) {
        return isIncludeSearchTerm(searchTerm, [options]);
    }
    return options.some((value) => String(value).toLowerCase().includes(searchTerm));
};

export const flattenTree = (nodeTree: ITreeFilterObject): IFlatTreeFilterObject[] => {
    const { id, path, name } = nodeTree;
    const items = [{ id, path: [path], name }];
    const createTree = (node: ITreeFilterObject, parentPath: string[]) => {
        const itemPath = [...parentPath, node.name];
        items.push({ name: node.name, id: node.id, path: itemPath });
        node.children?.forEach((child: ITreeFilterObject) => {
            createTree(child, itemPath);
        });
    };
    nodeTree.children?.forEach((rootNode: ITreeFilterObject) => {
        createTree(rootNode, [nodeTree.name]);
    });

    return items;
};

export const isFilterBoxContent = (displayType: string) => {
    return (
        displayType === FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1 || displayType === FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_2
    );
};

export const renderAccordionClearButton = (onClearButtonClicked: Function) => {
    return (
        <Button
            key={1}
            iconButton
            variant='text'
            iconProps={{ name: 'remove', size: 10 }}
            onClick={() => onClearButtonClicked()}
        />
    );
};

export const encodeUrlObject = (urlObject: GenericObject<any>) => {
    const supportedChars: GenericObject<string> = { '%22': '"', '%3A': ':', '%2C': ',' };
    const stringifiedObject = JSON.stringify(urlObject);
    const regex = new RegExp(`${Object.keys(supportedChars).join('|')}`, 'g');
    return encodeURIComponent(stringifiedObject)
        .replace(regex, (matched) => supportedChars[matched])
        .replaceAll('.', '%2E');
};

export const getStoredFilterValuesById = (filterId: string): IFiltersValues => {
    return getFiltersValues(getStoreService().state, filterId);
};
