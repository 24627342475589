import { ITableAction } from '../../design-system/components-v2/Table/Table.types';
import i18n from 'i18next';
import { AllIconsName } from '../../design-system/components-v2/Icon/Icon.types';

export interface IDefaultActionProps<T> {
    translationKey?: string;
    getIsActionDisabled?: (selectedRows: T[]) => boolean;
    onActionClicked?: (selectedRows: T[]) => void;
    getIsActionHidden?: (selectedRows?: T[]) => boolean;
}

export interface IGenericTableActionsProps<T> extends IDefaultActionProps<T> {
    id: string;
    icon: AllIconsName;
    name: string;
    onActionClicked?: (selectedRows: T[]) => void;
}

export const getAddItemTableAction = <T,>({
    translationKey,
    onActionClicked,
    getIsActionDisabled,
    getIsActionHidden,
}: IDefaultActionProps<T>): ITableAction => {
    return {
        id: 'add',
        name: i18n.t('BUTTONS.ADD', { ns: translationKey }),
        buttonProps: {
            iconProps: { name: 'plus' },
        },
        isActionHidden: (selectedRows: T[]) => (getIsActionHidden ? getIsActionHidden(selectedRows) : false),
        callback: (selectedRows: T[]) => onActionClicked && onActionClicked(selectedRows),
        isActionDisabled: (selectedRows: T[]) => (getIsActionDisabled ? getIsActionDisabled(selectedRows) : false),
    };
};

export const getDeleteItemTableAction = <T,>({
    translationKey,
    onActionClicked,
    getIsActionDisabled,
    getIsActionHidden,
}: IDefaultActionProps<T>): ITableAction => {
    return {
        id: 'add',
        name: i18n.t('BUTTONS.DELETE', { ns: translationKey }),
        buttonProps: {
            iconProps: { name: 'delete' },
        },
        isActionHidden: (selectedRows: T[]) => (getIsActionHidden ? getIsActionHidden(selectedRows) : false),
        callback: (selectedRows: T[]) => onActionClicked && onActionClicked(selectedRows),
        isActionDisabled: (selectedRows: T[]) => (getIsActionDisabled ? getIsActionDisabled(selectedRows) : false),
    };
};

export const getGenericTableAction = <T,>({
    id,
    icon,
    name,
    onActionClicked,
    getIsActionDisabled,
}: IGenericTableActionsProps<T>): ITableAction => {
    return {
        id: id,
        name: name,
        buttonProps: {
            iconProps: { name: icon },
        },
        callback: (selectedRows: T[]) => onActionClicked && onActionClicked(selectedRows),
        isActionDisabled: (selectedRows: T[]) => (getIsActionDisabled ? getIsActionDisabled(selectedRows) : false),
    };
};
