import { StatusTypes } from 'common/common_types';
import React, { FunctionComponent } from 'react';
import { GenericObject } from '../../interface/general';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export interface IDashboard {
    creatingUserId?: number;
    creationDate?: string;
    displayHiddenWidgets?: boolean;
    id: string;
    isTemplate?: boolean;
    name: string;
    pageId?: string;
    updateDate?: string;
    updatingUserId?: number;
    isPublic?: boolean;
    sections?: IDashboardSection[];
    isLocalDashboard?: boolean;
    isPinned?: boolean;
}

export interface IDashboardWidgetOptionsSizes {
    height: number;
    width: number;
    x?: number;
    y?: number;
}

export enum NavigateOption {
    PROTECTED_ASSET = 'Protected Assets',
    ENVIRONMENT = 'Environment',
}

export interface IWidgetConfigFilterState {
    name: string;
    value: string | number;
}

export interface IAlertTrendOptionsItem {
    selected: string[]; // empty array means all
    selectedName: string;
    title: string;
    widgetConfigFilterState: IWidgetConfigFilterState[];
    type: string;
    isEmpty?: boolean;
}

export interface IDashboardWidgetOptions {
    id?: string;
    sizes?: IDashboardWidgetOptionsSizes;
    navigateOption?: string;
    binSize?: string;
    alertsOptions?: {
        stackByKey: string;
        addScrollbar: boolean;
    };
    alertTrendOptions?: IAlertTrendOptionsItem[];
    compliance?: IDashboardWidgetComplianceObject[];
    summarySubtitle?: string;
    thresholds?: {
        color: string;
        colorPlate?: string[];
        max: number;
        min: number;
        disableColorPicker?: boolean;
    }[];
}

export interface IDashboardWidgetComplianceObject {
    type: string;
    selected: string;
    rulesetId: number;
    platform?: string;
    legendType?: string;
    selectedName?: string;
    rulesetName?: string;
}

export enum DashboardWidgetTypes {
    Top = 'top',
    Accounts = 'accounts',
    Pie = 'pie',
    StackedColumn = 'stackedColumn',
    Column = 'column',
    ActivityGauge = 'activityGauge',
    Environment = 'environment',
    Area = 'area',
    Gauge = 'gauge',
    DataGrid = 'dataGrid',
    Bar = 'bar',
    Latest = 'latest',
    Bottom = 'bottom',
    Trend = 'trend',
    TrendChangeSummary = 'trend-change-summary',
    TrendLineChangeSummary = 'trend-line-with-change-summary',
    Grid = 'grid',
    Map = 'map',
    MapLinks = 'map-links',
    Summary = 'summary',
}

export interface IDashboardWidget {
    id: string; //TODO: this need to generated in BE
    aggregation?: string | null;
    cloudAccountId?: string | null;
    dataSourceName: string;
    description?: string;
    filterState?: any;
    gslFilter?: string;
    hideOnNoData?: boolean;
    limit?: number;
    options: IDashboardWidgetOptions;
    timeFrame?: number;
    title?: string;
    type: DashboardWidgetTypes;
    position?: number;
    sectionId: string;
    dashboardId: string;
    renderer?: React.FC<{ data: any }>;
    dataGetter?: Function;
    dataId?: string;
}

export interface IDashboardSection {
    description?: string;
    displayHiddenWidgets?: boolean;
    isExpanded?: boolean;
    options?: any; // TODO: I don't know what should be here
    title: string;
    widgets?: IDashboardWidget[];
    position?: number;
    id: string;
    dashboardId: string;
}

export interface IGenericWidgetSettingsProps {
    widget: IDashboardWidget;
    updateWidgetCallback: Function;
    onFilterPanelAsyncChangeFinished?: Function;
    onFilterPanelAsyncChangeStarted?: Function;
}

export interface IWidgetDataConfiguration {
    dataId?: string;
    notSupported?: boolean;
    dataIdAliases?: string[];
    type: DashboardWidgetTypes;
    getData?: Function;
    widgetComponent: React.FC<any>;
    settingsComponent?: React.FC<IGenericWidgetSettingsProps>;
    displayName?: string;
}

export interface IAddinWidgetsDataSource {
    dataSourceName: string;
    dataField?: { displayName: string; path: string };
    widgets: IWidgetDataConfiguration[];
}

export interface IAggregationSettings {
    [key: string]: {
        dataId: string;
        dataIdAliases?: string[];
        displayName: string;
        dataGetter?: Function;
        stackColumnName?: string;
    };
}

export interface IWidgetsSettings {
    [key: string]: {
        component: FunctionComponent<any>;
        displayName: string;
        additionalFields?: React.FC<any>;
        dataOrder?: string;
        dataConvertor?: Function;
        key?: string;
    };
}

export interface IGenericWidgetDataItemBase {
    icon?: AllIconsName | string;
    key: string;
    onclick?: Function;
    color?: string;
}

export interface IGenericWidgetDataPropsBase {
    status?: StatusTypes;
    title?: string;
    subtitle?: string;
    className?: string;
    subElement?: any;
}

export interface IGenericWidgetDataItemSet extends IGenericWidgetDataItemBase {
    value: IGenericWidgetDataItem[];
}
export interface IGenericWidgetDataItem extends IGenericWidgetDataItemBase {
    value: string | number;
    isHidden?: boolean;
    tooltip?: string | number;
    subtitle?: string;
    category?: string;
}

export interface IGenericWidgetData extends IGenericWidgetDataPropsBase {
    items?: IGenericWidgetDataItem[];
    options?: any;
}

export interface IGenericWidgetDataSet extends IGenericWidgetDataPropsBase {
    items?: IGenericWidgetDataItemSet[];
    options?: any;
    additionalInformation?: any;
    data?: IGenericWidgetDataSet;
    widget?: any;
}

export interface IGenericWidgetDataProps {
    data: IGenericWidgetData;
    widget?: any;
    header?: string;
    customOptions?: GenericObject<any>;
}

export interface IGenericWidgetDataSetProps {
    data: IGenericWidgetDataSet;
    widget?: any;
    header?: string;
}

export interface IDefaultRouteInfo {
    route: string;
    hasRoutePermissions?: () => boolean;
}
