import { IRole } from './services';
import { IUser } from './user';

export const ROLES_SERVICE_ID = 'roles_service_id';

export interface IBasicRole {
    name: string;
    description: string;
    users: string[];
    serviceAccounts: string[];
    permissions: {
        access: string[];
        manage: string[];
        view: string[];
        create: string[];
        crossAccountAccess: string[];
        rulesets: string[];
        notifications: string[];
        policies: string[];
        alertActions: string[];
        onBoarding: string[];
    };
}

export interface IRolesService {
    getRoles: (useCache?: boolean) => Promise<IRole[]>;
    addRole: (role: IBasicRole) => Promise<IRole>;
    deleteRole: (roleId: string) => Promise<void>;
    getUsers: (useCache?: boolean) => Promise<IUser[]>;
    updateUser: (user: IUser) => Promise<IUser>;
    updateRole: (role: IRole) => Promise<IRole>;
}
