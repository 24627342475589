import React from 'react';
import { GeneralErrorPage, GeneralErrorPageProps } from '../GeneralErrorsPage/GeneralErrorsPage';

const withNoPermissionsText = (WrappedComponent: React.FC<GeneralErrorPageProps>) => {
    return (props: GeneralErrorPageProps) => {
        const {
            titleKey = 'SETTINGS.NO_PERMISSION.MESSAGE_TITLE',
            firstMessageKey = 'SETTINGS.NO_PERMISSION.MESSAGE_FIRST',
            secondMessageKey = 'SETTINGS.NO_PERMISSION.MESSAGE_SECOND',
        } = props;

        return (
            <WrappedComponent
                titleKey={titleKey}
                firstMessageKey={firstMessageKey}
                secondMessageKey={secondMessageKey}
            />
        );
    };
};

const NoPermissionPage = withNoPermissionsText(GeneralErrorPage);

export default NoPermissionPage;
