import {
    getGslService,
    IKustoEventService,
    KUSTO_EVENT_SERVICE_ID,
} from 'common/module_interface/intelligence/intelligence';
import {
    IKustoCountEvent,
    IKustoEvent,
    IPartialKustoEvent,
    KustoLogType,
} from 'common/components/KustoEvents/KustoEvent.interface';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IGslCount, IGslFilter, IGslJoin, IGslSort } from 'common/components/gsl/GslService.interface';
import { IFindingGslFilter } from 'common/module_interface/intelligence/Gsl/FindingGslService.interface';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { GslSource } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { IGslRunRequest } from 'common/module_interface/intelligence/Intelligence.interface';
import {
    findingModelAlertTypeCodeToString,
    findingModelAlertTypeStringToCode,
    IFindingComment,
    IFindingModelAlertTypeCode,
    IFindingModelAlertTypeString,
    IFindingModelStatus,
} from 'common/components/Findings/Findings.interface';
import { isEmpty } from 'lodash';
import { getNumberFromString, isValidJSON } from 'common/erm-components/utils/types';
import {
    booleanToStringConvertorsPair,
    convertAnythingToUndefined,
    convertGslCountUiToModel,
    convertGslFilterUiToModel,
    convertGslSortUiToModel,
    convertItem,
    convertKustoAggregationsModelToUi,
    convertKustoFieldNameListUiToModel,
    createItemContentConvertorByMappers,
    FieldValueType,
    numberToStringConvertorsPair,
    safeStringToStringArrayConvertorsPair,
} from 'common/erm-components/utils/Convertors/convertors';
import { GenericObject, IFieldInfo } from 'common/interface/general';
import { CommonEventFields } from 'common/module_interface/events/EventsConsts';
import { KustoEventFields, LogType } from 'common/components/KustoEvents/KustoEvent.const';
import { SysEventType } from 'common/module_interface/events/Events';
import { CompoundFilterLogicalOperator } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { FilterConditionOperator } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { getServerTypeByPlatform, getTypeByPlatform } from 'common/assets/common.assets';
import {
    IItemContentConvertor,
    IModelUiMappingInfo,
    IModelUiValueConvertorsPair,
} from '../../erm-components/utils/Convertors/convertors.interface';
import { GslCommonFields } from '../gsl/GslCommonFields';

export enum KustoEventModelFields {
    acknowledge = 'acknowledge',
    action = 'action',
    additionalField = 'additionalField',
    alertType = 'alertType',
    assessmentHistoryId = 'assessmentHistoryId',
    archive = 'archive',
    category = 'category',
    comments = 'comments',
    count = GslCommonFields.count,
    env_cloudAccountId = 'env_cloudAccountId',
    env_cloudAccountName = 'env_cloudAccountName',
    env_cloudAccountId_name = 'env_cloudAccountId_name',
    env_cloudAccountType = 'env_cloudAccountType',
    env_externalAccountId = 'env_externalAccountId',
    entity_fullEntityData = 'entity_fullEntityData',
    entity_id = 'entity_id',
    entity_dome9Id = 'entity_dome9Id',
    entity_name = 'entity_name',
    entity_region = 'entity_region',
    entity_type = 'entity_type',
    findingKey = 'findingKey',
    id = 'id',
    isExcluded = 'isExcluded',
    labels = 'labels',
    log_type = 'log_type',
    magellan_alertWindowStartTime = 'magellan_alertWindowStartTime',
    magellan_alertWindowEndTime = 'magellan_alertWindowEndTime',
    magellan_network_src_id = 'magellan_network_src_id',
    magellan_network_dst_id = 'magellan_network_dst_id',
    magellan_network_dst_address = 'magellan_network_dst_address',
    magellan_network_src_address = 'magellan_network_src_address',
    magellan_activity_identity_id = 'magellan_activity_identity_id',
    magellan_activity_target_id = 'magellan_activity_target_id',
    min_starttime = 'min_starttime',
    mitre_dict = 'mitre_dict',
    mitre_tactic = 'mitre_tactic',
    mitre_technique = 'mitre_technique',
    env_organizationalUnitId = 'env_organizationalUnitId',
    env_organizationalUnitPath = 'env_organizationalUnitPath',
    origin_resolved = 'origin_resolved',
    owner = 'owner',
    numberOfRows = 'numberOfRows',
    remediationActions = 'remediationActions',
    rule_description = 'rule_description',
    rule_ruleId = 'rule_ruleId',
    rule_logic = 'rule_logic',
    rule_logicHash = 'rule_logicHash',
    rule_name = 'rule_name',
    rule_remediation = 'rule_remediation',
    bundle_description = 'bundle_description',
    bundle_id = 'bundle_id',
    bundle_name = 'bundle_name',
    rule_severity = 'rule_severity',
    rule_severity_name = 'rule_severity_name',
    starttime = 'starttime',
    status = 'status',
    status_name = 'status_name',
    src_countrycode = 'src_countrycode',
    type_by_platform = 'type_by_platform',
    updateTime = 'updateTime',
}

interface IKustoCountEventModel extends IPartialKustoEventModel {
    [KustoEventModelFields.count]: number;
}

interface IKustoEventModel {
    [KustoEventModelFields.acknowledge]: string;
    [KustoEventModelFields.action]: string;
    [KustoEventModelFields.additionalField]?: GenericObject<string>;
    [KustoEventModelFields.alertType]: string;
    [KustoEventModelFields.archive]: boolean;
    [KustoEventModelFields.assessmentHistoryId]: string;
    [KustoEventModelFields.bundle_description]?: string;
    [KustoEventModelFields.bundle_id]: string;
    [KustoEventModelFields.bundle_name]: string;
    [KustoEventModelFields.category]?: string;
    [KustoEventModelFields.comments]?: string;
    [KustoEventModelFields.count]?: number;
    [KustoEventModelFields.env_cloudAccountId]: string;
    [KustoEventModelFields.env_cloudAccountId_name]: string;
    [KustoEventModelFields.env_cloudAccountName]: string;
    [KustoEventModelFields.env_externalAccountId]: string;
    [KustoEventModelFields.entity_dome9Id]: string;
    [KustoEventModelFields.entity_fullEntityData]: string;
    [KustoEventModelFields.entity_id]: string;
    [KustoEventModelFields.entity_name]: string;
    [KustoEventModelFields.entity_region]?: string;
    [KustoEventModelFields.entity_type]: string;
    [KustoEventModelFields.env_organizationalUnitId]: string;
    [KustoEventModelFields.env_organizationalUnitPath]: string;
    [KustoEventModelFields.findingKey]: string;
    [KustoEventModelFields.id]: string;
    [KustoEventModelFields.isExcluded]: boolean;
    [KustoEventModelFields.labels]: string;
    [KustoEventModelFields.log_type]: string;
    [KustoEventModelFields.magellan_alertWindowEndTime]?: string;
    [KustoEventModelFields.magellan_alertWindowStartTime]?: string;
    [KustoEventModelFields.magellan_network_dst_address]?: string;
    [KustoEventModelFields.magellan_network_dst_id]?: string;
    [KustoEventModelFields.magellan_network_src_address]?: string;
    [KustoEventModelFields.magellan_network_src_id]?: string;
    [KustoEventModelFields.magellan_activity_identity_id]?: string;
    [KustoEventModelFields.magellan_activity_target_id]?: string;
    [KustoEventModelFields.min_starttime]?: string;
    [KustoEventModelFields.mitre_dict]: string;
    [KustoEventModelFields.mitre_tactic]: string;
    [KustoEventModelFields.mitre_technique]: string;
    [KustoEventModelFields.numberOfRows]: string;
    [KustoEventModelFields.origin_resolved]: string;
    [KustoEventModelFields.owner]: string;
    [KustoEventModelFields.remediationActions]: string;
    [KustoEventModelFields.rule_description]?: string;
    [KustoEventModelFields.rule_ruleId]: string;
    [KustoEventModelFields.rule_logic]: string;
    [KustoEventModelFields.rule_logicHash]: string;
    [KustoEventModelFields.rule_name]: string;
    [KustoEventModelFields.rule_remediation]?: string;
    [KustoEventModelFields.rule_severity]: string;
    [KustoEventModelFields.rule_severity_name]: string;
    [KustoEventModelFields.starttime]: string;
    [KustoEventModelFields.status]: string;
    [KustoEventModelFields.status_name]: string;
    [KustoEventModelFields.src_countrycode]?: string;
    [KustoEventModelFields.type_by_platform]: string;
    [KustoEventModelFields.updateTime]: string;
}

type IPartialKustoEventModel = Partial<IKustoEventModel>;

const ModelToUiLogTypeMap: { [key in KustoLogType]: string } = {
    account: LogType.Account,
    network: LogType.Network,
};

// Convert Full Model Item to UI Item
const convertMagellanFromModelItemToUi = (
    kustoEventModelItem: IPartialKustoEventModel,
    kustoEventUiItem: IPartialKustoEvent,
) => {
    const startTime: string | undefined = kustoEventModelItem.magellan_alertWindowStartTime;
    const endTime: string | undefined = kustoEventModelItem.magellan_alertWindowEndTime;
    if (startTime && endTime) {
        kustoEventUiItem.magellan = {
            alertWindowStartTime: startTime,
            alertWindowEndTime: endTime,
        };
    }
};

const convertAdditionalItemsMapFromModelItemToUi = (modelItem: IPartialKustoEventModel, uiItem: IPartialKustoEvent) => {
    uiItem.additionalFieldsMap = modelItem.additionalField;
};

const getAllKustoConvertorsFromModelItemToUi = (): IItemContentConvertor<
    IPartialKustoEventModel,
    IPartialKustoEvent
>[] => [
    createItemContentConvertorByMappers<IPartialKustoEventModel, IPartialKustoEvent>(kustoEventModelToEventMappers),
    convertMagellanFromModelItemToUi,
    convertAdditionalItemsMapFromModelItemToUi,
];

// Convert Model Value => UI
const convertKustoArchiveModelToUiStatus = (isArchive: boolean): IFindingModelStatus => {
    return isArchive ? IFindingModelStatus.Archived : IFindingModelStatus.Active;
};

const convertKustoCommentsModelToUi = (comments: string): IFindingComment[] => {
    if (isEmpty(comments) || !isValidJSON(comments)) return [];
    const parsedComments: string[] = JSON.parse(comments);
    return parsedComments.map((comment) => {
        const tokens: string[] = comment.split(' | ');
        if (tokens.length === 4) {
            const [userName, timestamp, action, text] = tokens;
            return { timestamp, userName, action, text } as IFindingComment;
        }
        const [userName, timestamp, text] = tokens;
        return { timestamp, userName, text } as IFindingComment;
    });
};

const convertKustoLogTypeModelToUi = (logType: string): string => {
    return ModelToUiLogTypeMap[logType as keyof typeof ModelToUiLogTypeMap] || '';
};

const convertKustoAdditionalFieldsModelToUi = (additionalFields: GenericObject<string>) => {
    return Object.entries(additionalFields).map(([name, value]) => ({ name, value }));
};

const convertKustoAlertTypeModelToUi = (modelAlertType: string): IFindingModelAlertTypeString => {
    const alertTypeCode: IFindingModelAlertTypeCode =
        getNumberFromString(modelAlertType) || IFindingModelAlertTypeCode.Task;
    return findingModelAlertTypeCodeToString(alertTypeCode);
};

// Convert UI Value => Model
const convertEventStatusUiToModelArchive = (status: IFindingModelStatus): boolean => {
    return status === IFindingModelStatus.Archived;
};

const convertKustoCommentsUiToModel = (commentsObjects: IFindingComment[]): string => {
    const lines: string[] = commentsObjects.map(
        (commentObject: IFindingComment) =>
            `${commentObject.userName} | ${commentObject.timestamp} | ${commentObject.action} | ${commentObject.text}`,
    );
    return JSON.stringify(lines);
};

const convertKustoAdditionalFieldsUiToModel = (fields: IFieldInfo[]) => {
    const additionalFields: GenericObject<string> = {};
    fields.forEach((field: IFieldInfo) => {
        additionalFields[field.name] = field.value;
    });
    return additionalFields;
};

const convertKustoAlertTypeUiToModel = (alertType: IFindingModelAlertTypeString): string => {
    const alertTypeCode: IFindingModelAlertTypeCode =
        findingModelAlertTypeStringToCode(alertType) || IFindingModelAlertTypeCode.Task;
    return String(alertTypeCode);
};

const convertTypeByPlatformModelToUi = (typeByPlatform: string): string => {
    const [platform, type] = typeByPlatform.split('|');
    return getTypeByPlatform(platform, type);
};

const convertTypeByPlatformUiToModel = (typeByPlatform: string): string => {
    const [platform, type] = typeByPlatform.split('|');
    return getServerTypeByPlatform(platform, type);
};

const convertKustoLogTypeUiToModel = (logType: string): string => {
    return (
        Object.keys(ModelToUiLogTypeMap).find(
            (serverLogType) => ModelToUiLogTypeMap[serverLogType as KustoLogType] === logType,
        ) || ''
    );
};

const entityObjectConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: convertAnythingToUndefined,
    convertValueUiToModel: convertAnythingToUndefined,
};

const commentConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: convertKustoCommentsModelToUi,
    convertValueUiToModel: convertKustoCommentsUiToModel,
};

const additionalFieldsConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: convertKustoAdditionalFieldsModelToUi,
    convertValueUiToModel: convertKustoAdditionalFieldsUiToModel,
};

const alertTypeConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: convertKustoAlertTypeModelToUi,
    convertValueUiToModel: convertKustoAlertTypeUiToModel,
};

const typeByPlatformConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: convertTypeByPlatformModelToUi,
    convertValueUiToModel: convertTypeByPlatformUiToModel,
};

const logTypeConvertorsPair: IModelUiValueConvertorsPair = {
    convertValueModelToUi: convertKustoLogTypeModelToUi,
    convertValueUiToModel: convertKustoLogTypeUiToModel,
};

interface IKustoEventFieldMappingInfo extends IModelUiMappingInfo {
    modelField: keyof IKustoEventModel;
    uiField: keyof IKustoEvent;
}

const convertKustoItemByConvertorsModelToUi = (
    serverItem: IPartialKustoEventModel,
    convertors: IItemContentConvertor<IPartialKustoEventModel, IPartialKustoEvent>[],
): IPartialKustoEvent => {
    const finalConvertors: IItemContentConvertor<IPartialKustoEventModel, IPartialKustoEvent>[] =
        convertors || getAllKustoConvertorsFromModelItemToUi();
    const kustoEvent: IPartialKustoEvent = convertItem<IPartialKustoEventModel, IPartialKustoEvent>(
        serverItem,
        finalConvertors,
    );
    kustoEvent.sysEventType = SysEventType.kusto;
    return kustoEvent;
};

const convertKustoItemListModelToUi = <T extends IPartialKustoEvent = IKustoEvent>(
    serverItems: IPartialKustoEventModel[],
): T[] => {
    return serverItems.map((serverItem) =>
        convertKustoItemByConvertorsModelToUi(serverItem, getAllKustoConvertorsFromModelItemToUi()),
    ) as T[];
};

const kustoEventModelToEventMappers: IKustoEventFieldMappingInfo[] = [
    {
        modelField: KustoEventModelFields.acknowledge,
        uiField: CommonEventFields.acknowledged,
        convertorsPair: booleanToStringConvertorsPair,
    },
    { modelField: KustoEventModelFields.action, uiField: CommonEventFields.action },
    {
        modelField: KustoEventModelFields.additionalField,
        uiField: CommonEventFields.additionalFields,
        modelFieldType: FieldValueType.OBJECT,
        convertorsPair: additionalFieldsConvertorsPair,
    },
    {
        modelField: KustoEventModelFields.alertType,
        uiField: CommonEventFields.alertType,
        convertorsPair: alertTypeConvertorsPair,
    },
    {
        modelField: KustoEventModelFields.archive,
        uiField: CommonEventFields.status,
        modelFieldType: FieldValueType.BOOLEAN,
        convertorsPair: {
            convertValueModelToUi: convertKustoArchiveModelToUiStatus,
            convertValueUiToModel: convertEventStatusUiToModelArchive,
        },
    },
    { modelField: KustoEventModelFields.assessmentHistoryId, uiField: CommonEventFields.assessmentHistoryId },
    { modelField: KustoEventModelFields.bundle_description, uiField: CommonEventFields.rulesetDescription },
    {
        modelField: KustoEventModelFields.bundle_id,
        uiField: CommonEventFields.bundleId,
        convertorsPair: numberToStringConvertorsPair,
    },
    { modelField: KustoEventModelFields.bundle_name, uiField: CommonEventFields.bundleName },
    { modelField: KustoEventModelFields.category, uiField: CommonEventFields.category },
    {
        modelField: KustoEventModelFields.comments,
        uiField: KustoEventFields.comments,
        convertorsPair: commentConvertorsPair,
    },
    { modelField: KustoEventModelFields.count, uiField: KustoEventFields.count },
    { modelField: KustoEventModelFields.env_cloudAccountId, uiField: CommonEventFields.cloudAccountId },
    { modelField: KustoEventModelFields.env_cloudAccountName, uiField: KustoEventFields.cloudAccountName },
    { modelField: KustoEventModelFields.env_cloudAccountId_name, uiField: CommonEventFields.cloudAccountType },
    { modelField: KustoEventModelFields.env_externalAccountId, uiField: CommonEventFields.cloudAccountExternalId },
    {
        modelField: KustoEventModelFields.env_organizationalUnitId,
        uiField: CommonEventFields.organizationalUnitId,
    },
    {
        modelField: KustoEventModelFields.env_organizationalUnitPath,
        uiField: CommonEventFields.organizationalUnitPath,
    },
    {
        modelField: KustoEventModelFields.entity_fullEntityData,
        uiField: CommonEventFields.entityObject,
        convertorsPair: entityObjectConvertorsPair,
    },
    { modelField: KustoEventModelFields.entity_id, uiField: CommonEventFields.entityExternalId },
    { modelField: KustoEventModelFields.entity_dome9Id, uiField: CommonEventFields.entityDome9Id },
    { modelField: KustoEventModelFields.entity_name, uiField: CommonEventFields.entityName },
    { modelField: KustoEventModelFields.entity_region, uiField: CommonEventFields.region },
    { modelField: KustoEventModelFields.entity_type, uiField: CommonEventFields.entityType },
    { modelField: KustoEventModelFields.findingKey, uiField: CommonEventFields.findingKey },
    { modelField: KustoEventModelFields.id, uiField: CommonEventFields.id },
    { modelField: KustoEventModelFields.isExcluded, uiField: CommonEventFields.isExcluded },
    {
        modelField: KustoEventModelFields.labels,
        uiField: CommonEventFields.findingLabels,
        convertorsPair: safeStringToStringArrayConvertorsPair,
    },
    {
        modelField: KustoEventModelFields.log_type,
        uiField: KustoEventFields.logType,
        convertorsPair: logTypeConvertorsPair,
    },
    {
        modelField: KustoEventModelFields.magellan_network_src_address,
        uiField: KustoEventFields.magellanNetworkSrcAddress,
    },
    { modelField: KustoEventModelFields.min_starttime, uiField: CommonEventFields.lastSeenTime },
    { modelField: KustoEventModelFields.mitre_dict, uiField: KustoEventFields.mitreDict },
    { modelField: KustoEventModelFields.mitre_tactic, uiField: KustoEventFields.mitreTactic },
    { modelField: KustoEventModelFields.mitre_technique, uiField: KustoEventFields.mitreTechnique },
    { modelField: KustoEventModelFields.numberOfRows, uiField: KustoEventFields.numberOfRows },
    { modelField: KustoEventModelFields.origin_resolved, uiField: CommonEventFields.origin },
    { modelField: KustoEventModelFields.owner, uiField: CommonEventFields.ownerUserName },
    {
        modelField: KustoEventModelFields.remediationActions,
        uiField: CommonEventFields.remediationActions,
        convertorsPair: safeStringToStringArrayConvertorsPair,
    },
    { modelField: KustoEventModelFields.rule_description, uiField: CommonEventFields.description },
    { modelField: KustoEventModelFields.rule_ruleId, uiField: CommonEventFields.ruleId },
    { modelField: KustoEventModelFields.rule_logic, uiField: CommonEventFields.ruleLogic },
    { modelField: KustoEventModelFields.rule_logicHash, uiField: KustoEventFields.ruleLogicHash },
    { modelField: KustoEventModelFields.rule_name, uiField: CommonEventFields.ruleName },
    { modelField: KustoEventModelFields.rule_remediation, uiField: CommonEventFields.remediation },
    {
        modelField: KustoEventModelFields.rule_severity,
        uiField: CommonEventFields.severityLevel,
        convertorsPair: numberToStringConvertorsPair,
    },
    { modelField: KustoEventModelFields.rule_severity_name, uiField: CommonEventFields.severity },
    { modelField: KustoEventModelFields.starttime, uiField: CommonEventFields.createdTime },
    {
        modelField: KustoEventModelFields.status,
        uiField: KustoEventFields.statusId,
        convertorsPair: numberToStringConvertorsPair,
    },
    { modelField: KustoEventModelFields.status_name, uiField: KustoEventFields.statusName },
    { modelField: KustoEventModelFields.src_countrycode, uiField: KustoEventFields.magellanNetworkSrcCountryCode },
    {
        modelField: KustoEventModelFields.type_by_platform,
        uiField: CommonEventFields.entityTypeByEnvironmentType,
        convertorsPair: typeByPlatformConvertorsPair,
    },
    { modelField: KustoEventModelFields.updateTime, uiField: CommonEventFields.updatedTime },
];

// TODO: this should not be exported, after refactoring finding table datasource, this should be local
export const kustoEventMappersByModelName: { [key: string]: IKustoEventFieldMappingInfo } = {};
export const kustoEventMappersByUiName: { [key: string]: IKustoEventFieldMappingInfo } = {};
kustoEventModelToEventMappers.forEach((mapper: IKustoEventFieldMappingInfo) => {
    kustoEventMappersByModelName[mapper.modelField] = mapper;
    kustoEventMappersByUiName[mapper.uiField] = mapper;
});

class KustoEventService implements IKustoEventService {
    public async getKustoEvents(
        filter: IFindingGslFilter,
        limit?: number,
        sortList?: IGslSort[],
        join?: IGslJoin,
    ): Promise<IKustoEvent[]> {
        const sortListModel = convertGslSortUiToModel(kustoEventMappersByUiName, sortList);
        const gslFilter: IGslFilter = {
            ...filter,
            source: GslSource.alerts,
        };
        const modelFilter: IGslFilter = convertGslFilterUiToModel(kustoEventMappersByUiName, gslFilter);

        const serverItems: IKustoEventModel[] = await getGslService().getItems<IKustoEventModel>({
            filter: modelFilter,
            limit,
            sort: sortListModel,
            join,
            options: filter.organizationalUnits ? { organizationalUnitIds: filter.organizationalUnits } : undefined,
        });
        return convertKustoItemListModelToUi(serverItems);
    }

    public async getKustoEventsByRequest(request: IGslRunRequest): Promise<IKustoEvent[]> {
        const serverItems: IKustoEventModel[] = await getGslService().getItemsByRequest<IKustoEventModel>(request);
        return convertKustoItemListModelToUi(serverItems);
    }

    public async getKustoEventById(eventId: string, joinMitre = true): Promise<IKustoEvent> {
        const filter: IFindingGslFilter = {
            compoundFilter: {
                root: {
                    logicalOperator: CompoundFilterLogicalOperator.OR,
                    operands: [
                        {
                            name: KustoEventModelFields.id,
                            values: [eventId],
                            operator: FilterConditionOperator.In,
                        },
                        {
                            name: KustoEventModelFields.findingKey,
                            values: [eventId],
                            operator: FilterConditionOperator.In,
                        },
                    ],
                },
            },
        };

        const join: IGslJoin | undefined = joinMitre ? { source: GslSource.mitre } : undefined;

        const gslFilter: IGslFilter = {
            source: GslSource.alerts,
            ...filter,
        };

        const serverItems: IKustoEventModel[] = await getGslService().getItems<IKustoEventModel>({
            filter: gslFilter,
            limit: 1,
            join,
        });
        if (serverItems.length === 0) {
            throw new Error('Could not find event by id: ' + eventId);
        }
        return convertKustoItemListModelToUi(serverItems)[0];
    }

    public async getKustoEventFacets(
        filter: IFindingGslFilter,
        facetNames: string[],
        join?: IGslJoin,
    ): Promise<Aggregations> {
        const modelFacets: string[] = convertKustoFieldNameListUiToModel(kustoEventMappersByUiName, facetNames);
        const gslFilter: IGslFilter = {
            ...filter,
            source: GslSource.alerts,
        };
        const modelFilter: IGslFilter = convertGslFilterUiToModel(kustoEventMappersByUiName, gslFilter);
        const modelAggregations: Aggregations = await getGslService().getAggregations(
            {
                filter: modelFilter,
                join,
                options: filter.organizationalUnits ? { organizationalUnitIds: filter.organizationalUnits } : undefined,
            },
            modelFacets,
        );
        return convertKustoAggregationsModelToUi(kustoEventMappersByModelName, modelAggregations);
    }

    public async getKustoCountEvents(
        filter: IFindingGslFilter,
        count: IGslCount,
        join?: IGslJoin,
        sortList?: IGslSort[],
        limit?: number,
    ): Promise<IKustoCountEvent[]> {
        const modelCount: IGslCount = convertGslCountUiToModel(kustoEventMappersByUiName, count);
        const sortListModel = convertGslSortUiToModel(kustoEventMappersByUiName, sortList);
        const gslFilter: IGslFilter = {
            ...filter,
            source: GslSource.alerts,
        };
        const modelFilter: IGslFilter = convertGslFilterUiToModel(kustoEventMappersByUiName, gslFilter);
        const serverItems: IKustoCountEventModel[] = await getGslService().getCount(
            {
                filter: modelFilter,
                join,
                sort: sortListModel,
                limit,
                options: filter.organizationalUnits ? { organizationalUnitIds: filter.organizationalUnits } : undefined,
            },
            modelCount,
        );
        return convertKustoItemListModelToUi<IKustoCountEvent>(serverItems);
    }
}

export default function initializeKustoEventService() {
    globalAddinContainer.addService(KUSTO_EVENT_SERVICE_ID, new KustoEventService());
}
