import styled, { css } from 'styled-components';
import { InputWrapperType, InputProps } from './Input.types';
import Stack from '../Stack/Stack';

export const InputBase = styled.input<Pick<InputProps, 'isError' | 'readOnly'>>`
    all: unset;
    width: 0;
    text-overflow: ellipsis;
    line-height: 16px;
    flex: 1;
    min-width: 20px;
    background: ${({ theme, readOnly }) => (readOnly ? theme.palette.surface.secondary : 'transparent')};
    color: ${({ theme }) => theme.palette.text.strong};
    padding: ${({ theme }) => `${theme.spacing(1)}px 0`};

    &::placeholder {
        color: ${({ theme, isError }) => (isError ? theme.palette.text.alert : theme.palette.text.light)};
    }

    ${({ readOnly }) =>
        readOnly &&
        css`
            pointer-events: none;
        `};
`;

export const InputWrapper = styled(Stack)<InputWrapperType>`
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '240px')};
    padding: ${({ theme }) => `${theme.spacing(1) - 1}px ${theme.spacing(2)}px`};
    overflow: hidden;

    ${({ theme, inputSize, fullWidth }) =>
        inputSize === 'large' &&
        css`
            border-radius: ${`${theme.border.radius(3)}${theme.units}`};
            padding: ${`${theme.spacing(2) - 1}${theme.units} ${theme.spacing(2)}${theme.units} ${theme.spacing(2) - 1}${theme.units} ${theme.spacing(3)}${theme.units}`};
            max-width: ${fullWidth ? '100%' : '364px'};
        `}

    ${({ theme, type, inputSize }) =>
        type === 'number' &&
        css`
            ${inputSize === 'large' &&
            css`
                padding: ${`${theme.spacing(1.5) - 1}${theme.units} ${theme.spacing(2)}${theme.units} ${theme.spacing(1.5) - 1}${theme.units} ${theme.spacing(3)}${theme.units}`};
            `};
            ${inputSize === 'normal' &&
            css`
                padding: ${theme.spacing(0.5) - 1}${theme.units} ${theme.spacing(2)}${theme.units};
            `};
        `}
  
    &:hover {
        box-shadow: ${({ theme, disabled, readOnly }) => (!disabled && !readOnly ? theme.shadows.focus : 'none')};
        transition: box-shadow 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    background: ${({ theme, readOnly }) =>
        readOnly ? theme.palette.surface.secondary : theme.palette.surface.primary};

    ${({ isActive }) =>
        isActive &&
        css`
            border: ${({ theme }) => `${theme.border.width.standard}px solid ${theme.palette.surface.brandPrimary}`};
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            box-shadow: none;
            border: ${({ theme }) => `${theme.border.width.standard}px solid ${theme.palette.border.light}`};
        `};

    ${({ isError }) =>
        isError &&
        css`
            &:hover {
                box-shadow: ${({ theme }) => (isError ? theme.shadows.focusAlert : 'none')};
            }
            & {
                border: ${({ theme }) => `${theme.border.width.standard}px solid ${theme.palette.surface.alert}`};
            }
        `};

    ${({ disabled, theme }) =>
        disabled &&
        css`
            opacity: ${theme.disabledOpacity};
            pointer-events: none;
        `};

    /* Hide the native arrows */
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
    }
`;

export const TooltipIconWrapper = styled(Stack)`
    color: ${({ theme }) => theme.palette.onSurface.brandPrimary};
`;
