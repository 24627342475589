import { buildPath } from '../../extensibility/AddinContainer';
import { GenericObject } from '../../interface/general';
import { IOrganizationalUnit } from 'common/interface/data_services';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import React from 'react';
import { ICustomzationResponse } from '../../interface/services';
import { ICellRendererParams } from 'ag-grid-community';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IButtonProps } from 'common/design-system/components-v2/Button/Button.types';
import { IEnvironment } from 'common/interface/environmentsTable';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export const MAIN_MENU_ITEM_ID = 'assets';
export const MAIN_SECTION_ID = 'default';

const PATH_ENVIRONMENTS_PREFIX = buildPath('environments', 'environment_assets');
export const PATH_ENVIRONMENTS = buildPath(PATH_ENVIRONMENTS_PREFIX, 'assets');
export const PATH_ENVIRONMENTS_PAGE = buildPath(PATH_ENVIRONMENTS_PREFIX, 'page');
export const PATH_ENVIRONMENTS_PAGE_TABLE_COLUMNS = buildPath(PATH_ENVIRONMENTS_PAGE, 'columns');
export const PATH_ENVIRONMENTS_PAGE_TABLE_FILTERS = buildPath(PATH_ENVIRONMENTS_PAGE, 'filters');
export const PATH_ENVIRONMENTS_PAGE_TABLE_ACTIONS = buildPath(PATH_ENVIRONMENTS_PAGE, 'actions');
export const PATH_ENVIRONMENTS_PAGE_TABLE_EXPORTS = buildPath(PATH_ENVIRONMENTS_PAGE, 'exports');
export const PATH_ENVIRONMENTS_PAGE_TABLE_TABS = buildPath(PATH_ENVIRONMENTS_PAGE, 'tabs');
export const PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_ID = buildPath(PATH_ENVIRONMENTS_PAGE, 'addDataById');
export const PATH_ENVIRONMENTS_PAGE_TABLE_DATA_BY_PLATFORM = buildPath(PATH_ENVIRONMENTS_PAGE, 'addDataByPlatform');
export const PATH_ENVIRONMENTS_PAGE_TABLE_ADD_DATA_ROWS = buildPath(PATH_ENVIRONMENTS_PAGE, 'addDataRows');
export const PATH_ENVIRONMENTS_PAGE_TABLE_CELL_RENDERERS = buildPath(PATH_ENVIRONMENTS_PAGE, 'renderers');
export const PATH_ENVIRONMENTS_WIDGETS_FILTERS = buildPath(PATH_ENVIRONMENTS_PREFIX, 'widgets_filters');
export const PATH_ENVIRONMENTS_FILTERS_DEFINITIONS = buildPath(PATH_ENVIRONMENTS_PREFIX, 'filters_definitions');

export interface IEnvironmentsTableCellRendererAddinContent {
    renderer: React.FC<ICellRendererParams>;
    params: GenericObject<any>;
}

export interface IEnvironmentsFilter {
    id: string;
    filterProps?: GenericObject<any>;
    position?: number;
}

export interface IEnvironmentsAction extends ITableAction {}

export interface IEnvironmentsActionKey {
    id: string;
}

export interface IEnvironmentsExport {
    id: string;
    name: string;
    icon?: IButtonProps['iconProps'];
    callback: (allData: IEnvironment[], filteredData: IEnvironment[]) => void | Promise<void>;
    isActionDisabled?: (allData: IEnvironment[], filteredData: IEnvironment[]) => boolean;
}

export interface IFiltersInitialData {
    recentlyUsed?: ICustomzationResponse<any>;
    savedFilters?: ICustomzationResponse<any>;
    organizationalUnits?: IOrganizationalUnit;
}

export interface IEnvironmentsFilterParams {
    aggregations: Aggregations;
    filtersInitialData?: IFiltersInitialData;
    savedFiltersComponentName: string;
    recentlyUsedComponentName: string;
}

export enum StatusCellRenderer_Status {
    success = 'success',
    error = 'error',
    warning = 'warning',
    unsecured = 'unsecured',
    none = 'none',
}

export enum StatusCellRenderer_StatusType {
    syncStatus = 'syncStatus',
    workloadStatus = 'workloadStatus',
}

export enum FeatureStatusRenderer_Type {
    AccountActivity = 'accountActivity',
    GuardDuty = 'guardDuty',
    Cloudbots = 'cloudbots',
    IamSafety = 'iamSafety',
    TrafficActivity = 'trafficActivity',
    Serverless = 'serverless',
    Agentless = 'agentless',
}

// Single Environment

export interface EnvironmentAction<ArgsType extends any[]> {
    label: string;
    icon?: AllIconsName;
    callback: (...args: ArgsType) => void;
    disabled?: (...args: ArgsType) => boolean;
}

export interface IModalsProps {
    cloudAccountId: string;
    closeModal: () => void;
}
