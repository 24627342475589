import React, { useCallback } from 'react';
import EntityHeaderStyled from './EntityHeader.styled';
import { EntityHeaderProps } from './EntityHeader.types';
import Stack from '../Stack/Stack';
import LevelIcon from '../LevelIcon/LevelIcon';
import Tooltip from '../Tooltip/Tooltip';
import Chip from '../Chip/Chip';
import BlockInfo from '../BlockInfo/BlockInfo';
import Typography from '../Typography/Typography';
import Link from '../Link/Link';

const EntityHeader: React.FC<EntityHeaderProps> = ({
    levelIcon,
    title,
    titleUrl,
    titleTooltip,
    titleProps,
    titleChip,
    blocks,
    blocksMaxWidth,
    titleRightElements,
    chips,
    disableShowMore,
    onShowMoreStateChange,
    initialShowMoreState,
    dataAid,
}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(!!disableShowMore || !!initialShowMoreState);

    const toggleState = useCallback(() => {
        setIsOpen((current) => {
            const toggledState = !current;
            onShowMoreStateChange?.(toggledState);
            return toggledState;
        });
    }, [onShowMoreStateChange]);

    const titleElement = React.useMemo(() => {
        if (titleUrl) {
            return (
                <Link internalUrl={titleUrl}>
                    <Typography variant='subtitleLg' color='strong' {...titleProps}>
                        {title}
                    </Typography>
                </Link>
            );
        }
        return (
            <Typography variant='subtitleLg' color='strong' {...titleProps}>
                {title}
            </Typography>
        );
    }, [title, titleProps, titleUrl]);

    return (
        <Stack data-aid={dataAid}>
            <Stack direction='row' alignItems='center' spacing={4}>
                <EntityHeaderStyled.TitleIconWrapper direction='row' alignItems='center' spacing={3}>
                    {levelIcon && <LevelIcon {...levelIcon} />}
                    {title && (
                        <EntityHeaderStyled.TitleWrapper>
                            <Tooltip content={titleTooltip || title}>{titleElement}</Tooltip>
                        </EntityHeaderStyled.TitleWrapper>
                    )}
                    {titleChip && <Chip disableInteraction {...titleChip} />}
                    {!disableShowMore && (
                        <EntityHeaderStyled.ToggleButton
                            data-aid={'show-more-toggle'}
                            isOpen={isOpen}
                            iconProps={{ name: 'chevronRight', size: 10 }}
                            variant='text'
                            onClick={toggleState}
                        >
                            {isOpen ? 'Hide Details' : 'Show Details'}
                        </EntityHeaderStyled.ToggleButton>
                    )}
                </EntityHeaderStyled.TitleIconWrapper>
                <Stack spacing={4} direction='row' alignItems='center' data-aid={'top-right-container'}>
                    {chips && chips.length > 0 && (
                        <Stack direction='row-reverse' justifyContent='flex-end' spacing={2}>
                            {chips.map((restChipProps, index) => (
                                <Chip {...restChipProps} size='md' key={`chip-${index}`} />
                            ))}
                        </Stack>
                    )}
                    {titleRightElements}
                </Stack>
            </Stack>
            {blocks.length > 0 && (
                <EntityHeaderStyled.BlockContainer
                    isOpen={isOpen}
                    disablePaddingTop={disableShowMore && !title}
                    data-aid={'blocks-container'}
                >
                    {blocks.map((block, blockIndex) => (
                        <BlockInfo
                            key={`block-${blockIndex}`}
                            maxWidth={block.maxWidth || blocksMaxWidth || 200}
                            {...block}
                        />
                    ))}
                </EntityHeaderStyled.BlockContainer>
            )}
        </Stack>
    );
};

export default EntityHeader;
