import React from 'react';
import { IFilterTreeCommonProps, IFilterTreeNode } from '../FilterTree.interface';
import { useTranslation } from 'react-i18next';
import { CompoundFilterLogicalOperator } from '../CompoundFilter';
import { Button, Stack } from '../../index';

export const AddButtonsBox: React.FC<{
    parentNode: IFilterTreeNode;
    insertionIndex: number;
    filterProps: IFilterTreeCommonProps;
    extraOnClick: () => void;
}> = ({ parentNode, insertionIndex, filterProps, extraOnClick }) => {
    const { t } = useTranslation();

    return (
        <Stack direction={'column'} spacing={0}>
            <Button
                variant={'text'}
                label={t('FILTER_TREE.ACTIONS.ADD_AND_GROUP')}
                onClick={() => {
                    filterProps.api.onAddNode(parentNode, insertionIndex, CompoundFilterLogicalOperator.AND);
                    extraOnClick();
                }}
                key={'and'}
            />
            <Button
                variant={'text'}
                label={t('FILTER_TREE.ACTIONS.ADD_OR_GROUP')}
                onClick={() => {
                    filterProps.api.onAddNode(parentNode, insertionIndex, CompoundFilterLogicalOperator.OR);
                    extraOnClick();
                }}
                key={'or'}
            />
        </Stack>
    );
};
