import { useEffect, useState } from 'react';
import { IExclusion, IUseFilterProps } from '../exclusions.type';

export const useFilter = ({ data, filterBy }: IUseFilterProps) => {
    const [filtered, setFiltered] = useState<IExclusion[]>([]);
    useEffect(() => {
        if (!data || !data.length) return;
        if (!filterBy) {
            setFiltered(data);
            return;
        }
        const filteredData = data.filter((item) => {
            return Object.entries(filterBy).every(([key, value]) => {
                if (!value) return true;
                if (key === 'free-text') {
                    return Object.values(item).some((itemValue) => {
                        if (typeof itemValue === 'object') return false;
                        return itemValue.toString().toLowerCase().includes(value.toLowerCase());
                    });
                }
                if (Array.isArray(value)) {
                    if (!value.length) return true;
                    const itemValue = item[key as keyof IExclusion];
                    if (!Array.isArray(itemValue)) return value.includes(itemValue);
                    return value.every((val) => itemValue.includes(val));
                }
                if (typeof value === 'object') {
                    return false;
                }
                return item[key as keyof IExclusion].toLowerCase() === value.toLowerCase();
            });
        });
        setFiltered(filteredData);
    }, [filterBy, data]);
    return filtered;
};
