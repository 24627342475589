import React, { useEffect, useReducer, useState } from 'react';
import './MultiSelectFilter.scss';
import { FILTER_EVENTS } from '../../FilterPanel.consts';
import { IExtendMultiSelectFilter, IFilterSubCategory, IMultiSelectFilterSortInfo } from '../DefaultFilters.interface';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { boldSearchTerm, isFilterBoxContent, isIncludeSearchTerm } from '../../filterUtils';
import { useTranslation } from 'react-i18next';
import { FilterListMenu } from '../../GeneralComponents/FilterListMenu/FilterListMenu';
import { Button } from '@dome9/berries/react-components';
import { Checkbox, Counter, Stack, Icon } from 'common/design-system/components-v2';

const PAGINATION_NUMBER = 100;
const MAX_ITEMS_SIZE_WITHOUT_GROUPING = 250;
enum SortType {
    COUNT = 'COUNT',
    NAME = 'NAME',
    INVERSE = 'INVERSE',
    CUSTOM = 'CUSTOM',
}
export const MultiSelectFilter: React.FC<{ filterProps: IExtendMultiSelectFilter }> = ({ filterProps }) => {
    const { t } = useTranslation();
    const {
        currFilterData,
        initialData,
        onEvent,
        value,
        key,
        title,
        searchTerm,
        displayType,
        displayMapping,
        customSortInfo,
        itemCountGroupOption,
    } = filterProps;

    const [itemsLimit, setItemsLimit] = useState(MAX_ITEMS_SIZE_WITHOUT_GROUPING);
    const [currSubCategories, setCurrSubCategories] = useState<Array<IFilterSubCategory>>([]);
    const [sorting, dispatch] = useReducer(
        (state: any, action: any) => {
            switch (action.type) {
                case SortType.COUNT: {
                    return { ...state, sortingType: SortType.COUNT };
                }
                case SortType.NAME: {
                    return { ...state, sortingType: SortType.NAME };
                }
                case SortType.INVERSE: {
                    return { ...state, isInverse: !state.isInverse };
                }
                case SortType.CUSTOM: {
                    return { ...state, sortingType: SortType.CUSTOM };
                }
            }
        },
        { sortingType: customSortInfo ? SortType.CUSTOM : SortType.COUNT, isInverse: false },
    );
    const multiSelectMenu = [
        <FilterListMenu key={1}>
            <div className='list list--hoverable py-5'>
                <React.Fragment>
                    <div onClick={() => onSortByNameClicked()} className='list__item'>
                        <Checkbox checked={sorting.sortingType === SortType.NAME}>
                            <label className='check-box-label inline-flex-center-space-between'>
                                {t('FILTER_PANEL.FILTERS_MENU_OPTIONS.SORT_BY_NAME')}
                            </label>
                        </Checkbox>
                    </div>
                    <div onClick={() => onSortByCountClicked()} className='list__item'>
                        <Checkbox checked={sorting.sortingType === SortType.COUNT}>
                            <label className='check-box-label inline-flex-center-space-between'>
                                {t('FILTER_PANEL.FILTERS_MENU_OPTIONS.SORT_BY_COUNT')}
                            </label>
                        </Checkbox>
                    </div>
                    {customSortInfo && (
                        <div onClick={() => onSortByCustomClicked(customSortInfo)} className='list__item'>
                            <Checkbox checked={sorting.sortingType === SortType.CUSTOM}>
                                <label className='check-box-label inline-flex-center-space-between'>
                                    {customSortInfo.label}
                                </label>
                            </Checkbox>
                        </div>
                    )}
                    <div className='border-b mx-8 my-5' />
                    <div onClick={() => onReverseOrderClicked()} className='list__item'>
                        <Checkbox checked={sorting.isInverse}>
                            <label className='check-box-label inline-flex-center-space-between'>
                                {t('FILTER_PANEL.FILTERS_MENU_OPTIONS.REVERSE')}
                            </label>
                        </Checkbox>
                    </div>
                    <div className='border-b mx-8 my-5' />
                    <div onClick={() => onSelectAllClicked()} className='list__item'>
                        {t('FILTER_PANEL.FILTERS_MENU_OPTIONS.SELECT_ALL')}
                    </div>
                    <div onClick={() => onInverseSelectionClicked()} className='list__item'>
                        {t('FILTER_PANEL.FILTERS_MENU_OPTIONS.INVERT')}
                    </div>
                    <div onClick={() => onFilterClearClicked()} className='list__item'>
                        {t('FILTER_PANEL.FILTERS_MENU_OPTIONS.CLEAR')}
                    </div>
                </React.Fragment>
            </div>
        </FilterListMenu>,
    ];

    useEffect(() => {
        const subCategories = initialData
            ? initialData.map((dataItem: { value: any }) => {
                  let count = 0;
                  let isChecked = false;
                  currFilterData?.forEach((item: { value: any; count: number }) => {
                      if (item.value === dataItem.value) {
                          count = item.count;
                      }
                  });
                  if (value) {
                      value.forEach((val: string) => {
                          val === dataItem.value && (isChecked = true);
                      });
                  }

                  return {
                      value: dataItem.value,
                      title: displayMapping?.[dataItem.value]?.displayText || dataItem.value,
                      count,
                      isChecked,
                      isAllOption: false,
                      icon: displayMapping?.[dataItem.value]?.icon || '',
                      newIcon: displayMapping?.[dataItem.value]?.newIcon || '',
                      color: displayMapping?.[dataItem.value]?.color || '',
                      displayComponent: displayMapping?.[dataItem.value]?.displayComponent || undefined,
                      displayComponentProps: displayMapping?.[dataItem.value]?.displayComponentProps || undefined,
                  } as IFilterSubCategory;
              })
            : [];

        subCategories.sort(sortByTitleComparer);
        subCategories.sort(sortByCountComparer);
        subCategories.sort((a: { isChecked: any }, b: { isChecked: any }) => Number(b.isChecked) - Number(a.isChecked));
        if (customSortInfo) {
            subCategories.sort(customSortInfo.comparer);
        }
        const allItemIndex = subCategories.findIndex((category: { isAllOption: any }) => category.isAllOption);

        if (allItemIndex >= 0) {
            const allItem = subCategories[allItemIndex];
            subCategories.splice(allItemIndex, 1);
            subCategories.unshift(allItem);
        }

        setCurrSubCategories(subCategories);
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, currFilterData, displayMapping, initialData, key]);

    const onHandleCategoryClicked = (subCategory: IFilterSubCategory) => {
        const payload = [] as any[];
        const cloneSubCategories = [...currSubCategories];
        const index = cloneSubCategories.findIndex((item: IFilterSubCategory) => item.value === subCategory.value);
        cloneSubCategories[index].isChecked = !subCategory.isChecked;
        const isAllOptionItemIndex = cloneSubCategories.findIndex((item) => item.isAllOption);
        if (isAllOptionItemIndex >= 0) {
            //checking if all categories selected
            const itemsCheckedList = cloneSubCategories.filter((item) => item.isChecked && !item.isAllOption);
            const isAllItemsSelected = itemsCheckedList.length === currSubCategories.length - 1;
            cloneSubCategories[isAllOptionItemIndex].isChecked = isAllItemsSelected;
        }

        cloneSubCategories.forEach((item) => {
            if (item.isChecked) {
                payload.push(item.value);
            }
        });

        setCurrSubCategories(cloneSubCategories);

        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: payload,
        });
    };

    const sortByCountComparer = (a: IFilterSubCategory, b: IFilterSubCategory) => {
        return Number(b.count) - Number(a.count);
    };

    const sortByTitleComparer = (a: IFilterSubCategory, b: IFilterSubCategory) => {
        return a?.title?.localeCompare?.(b.title);
    };

    const onSortByCountClicked = () => {
        const subCategoriesAfterSort = currSubCategories.sort(sortByCountComparer);
        setCurrSubCategories([...subCategoriesAfterSort]);
        dispatch({ type: SortType.COUNT });
    };

    const onSortByCustomClicked = (sortInfo: IMultiSelectFilterSortInfo) => {
        const subCategoriesAfterSort = currSubCategories.sort(sortInfo.comparer);
        setCurrSubCategories([...subCategoriesAfterSort]);
        dispatch({ type: SortType.CUSTOM });
    };

    const onSortByNameClicked = () => {
        const subCategoriesAfterSort = currSubCategories.sort(sortByTitleComparer);
        setCurrSubCategories([...subCategoriesAfterSort]);
        dispatch({ type: SortType.NAME });
    };

    const onReverseOrderClicked = () => {
        const subCategoriesAfterReverse = currSubCategories.reverse();
        setCurrSubCategories([...subCategoriesAfterReverse]);
        dispatch({ type: SortType.INVERSE });
    };

    const onSelectAllClicked = () => {
        const cloneSubCategories = [...currSubCategories];
        const payload = [] as any[];
        cloneSubCategories.forEach((item) => {
            payload.push(item.value);
        });
        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: payload,
        });
    };

    const onInverseSelectionClicked = () => {
        const cloneSubCategories = [...currSubCategories];
        const payload = [] as any[];
        cloneSubCategories.forEach((item) => {
            if (!item.isChecked) {
                payload.push(item.value);
            }
        });
        onEvent({
            action: FILTER_EVENTS.FILTER_CHANGED,
            filterKey: key,
            payload: payload,
        });
    };

    const onFilterClearClicked = () => {
        onEvent({
            action: FILTER_EVENTS.CLEAR_FILTERS,
            filterKey: key,
            payload: '',
        });
    };

    const RenderItem = (subCategory: IFilterSubCategory, searchTerm = '') => {
        const subCategoryTitle = String(subCategory.title);
        const key = `${subCategory.value}-${subCategoryTitle}`;
        const DisplayComponent = subCategory?.displayComponent;
        const displayComponentProps = subCategory?.displayComponentProps;
        const shouldRenderDisplayComponent = !subCategory?.isAllOption && subCategory?.displayComponent;
        return (
            <React.Fragment key={key}>
                {isIncludeSearchTerm(searchTerm, subCategory.title) && (
                    <Stack
                        key={key}
                        data-aid2='sub-category-row'
                        data-aid={subCategoryTitle.toLowerCase()}
                        direction='row'
                        spacing={2}
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Checkbox
                            checked={subCategory?.isChecked}
                            onChange={() => onHandleCategoryClicked(subCategory)}
                            id={`FilterPanel_MultiSelect-${subCategory.title}`}
                        >
                            <div className='flex items-center flex-1'>
                                {shouldRenderDisplayComponent && (
                                    <div className={'truncate title-wrapper'} style={{ cursor: 'pointer' }}>
                                        {DisplayComponent && <DisplayComponent {...displayComponentProps} />}
                                    </div>
                                )}
                                {!shouldRenderDisplayComponent && (
                                    <>
                                        {!subCategory?.isAllOption && subCategory?.icon && (
                                            <div className='shrink-0 ml-[5px] w-[21px]'>
                                                <Icon vendorNameOrPath={subCategory.icon} />
                                            </div>
                                        )}
                                        {!subCategory?.isAllOption && subCategory?.newIcon && (
                                            <div className='shrink-0 ml-[5px] w-[21px]'>
                                                <Icon name={subCategory.newIcon} />
                                            </div>
                                        )}
                                        {!subCategory?.isAllOption && subCategory?.color && (
                                            <div className='shrink-0 ml-[5px]  w-[21px]'>
                                                <div
                                                    className='h-[15px] w-[10px]'
                                                    style={{ backgroundColor: subCategory.color }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={'truncate title-wrapper'}
                                            dangerouslySetInnerHTML={{
                                                __html: boldSearchTerm(searchTerm, subCategory?.title),
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </Checkbox>
                        {!subCategory?.isAllOption && !!subCategory?.count && (
                            <Counter data-aid='aggregation-counter' count={subCategory.count} />
                        )}
                    </Stack>
                )}
            </React.Fragment>
        );
    };

    const renderMultiSelect = (searchTerm = '') => {
        const maxGroupedItemsCount = () => {
            return Math.max(
                currSubCategories.filter((item: any) => item.count && isIncludeSearchTerm(searchTerm, item.title))
                    .length,
                currSubCategories.filter((item: any) => !item.count && isIncludeSearchTerm(searchTerm, item.title))
                    .length,
            );
        };

        function showCountedHeader(currSubCategories: Array<IFilterSubCategory>) {
            return currSubCategories.filter((item) => item.count && isIncludeSearchTerm(searchTerm, item.title)).length;
        }
        function showNonCountedHeader(currSubCategories: Array<IFilterSubCategory>) {
            return currSubCategories.filter((item) => !item.count && isIncludeSearchTerm(searchTerm, item.title))
                .length;
        }

        return (
            <div className='checkbox-filter'>
                {itemCountGroupOption?.enableGrouping &&
                currSubCategories.length > MAX_ITEMS_SIZE_WITHOUT_GROUPING &&
                !currSubCategories.filter((a) => a.isChecked).length ? (
                    <>
                        {showCountedHeader(currSubCategories) ? (
                            <div data-aid='top-items' className='font-semibold mb-6'>
                                {itemCountGroupOption.countedItemsHeader}
                            </div>
                        ) : (
                            ''
                        )}
                        {currSubCategories
                            .filter(
                                (item: IFilterSubCategory) =>
                                    String(item.title).toLowerCase().includes(searchTerm) && item.count,
                            )
                            .slice(0, itemsLimit)
                            .map((subCategory: IFilterSubCategory) => RenderItem(subCategory, searchTerm))}
                        {showNonCountedHeader(currSubCategories) ? (
                            <div data-aid='additional-items' className='font-semibold my-6'>
                                {itemCountGroupOption.nonCountedItemsHeader}
                            </div>
                        ) : (
                            ''
                        )}
                        {currSubCategories
                            .filter(
                                (item: IFilterSubCategory) =>
                                    String(item.title).toLowerCase().includes(searchTerm) && !item.count,
                            )
                            .slice(0, itemsLimit)
                            .map((subCategory: IFilterSubCategory) => RenderItem(subCategory, searchTerm))}
                    </>
                ) : (
                    <>
                        {currSubCategories
                            .filter((item: IFilterSubCategory) =>
                                String(item.title).toLowerCase().includes(searchTerm.toLowerCase()),
                            )
                            .slice(0, itemsLimit)
                            .map((subCategory: IFilterSubCategory) => RenderItem(subCategory, searchTerm))}
                    </>
                )}
                {itemsLimit <= maxGroupedItemsCount() && (
                    <Button onClick={() => setItemsLimit(itemsLimit + PAGINATION_NUMBER)} dataAid='Show more'>
                        {t('FILTER_PANEL.SHOW_MORE')}
                    </Button>
                )}
            </div>
        );
    };

    const renderMultiSelectBoxView = () => {
        const { isTitle, isContent } = filterBySearchContent();
        const term = isContent ? searchTerm : '';
        return (
            (isContent || isTitle) && (
                <Accordion
                    title={title}
                    content={renderMultiSelect(term)}
                    showContent={(isTitle || isContent) && searchTerm !== ''}
                    optionsList={multiSelectMenu}
                    onOpen={(elementRef: any) => {
                        onEvent({
                            action: FILTER_EVENTS.BOX_FILTER_OPEN,
                            filterKey: key,
                            payload: elementRef,
                        });
                    }}
                />
            )
        );
    };

    const filterBySearchContent = () => {
        const isTitle = searchTerm ? isIncludeSearchTerm(searchTerm, title) : true;
        const isContent = searchTerm
            ? !!currSubCategories.filter((subCategory) =>
                  isIncludeSearchTerm(searchTerm, [subCategory.title, subCategory.value]),
              ).length
            : true;

        return { isTitle, isContent };
    };

    return <>{isFilterBoxContent(displayType) ? renderMultiSelectBoxView() : renderMultiSelect()}</>;
};

export default MultiSelectFilter;
