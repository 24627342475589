import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export const ACTIVE_FEATURE_PAGE_MANAGER_KEY = 'af-admin-page';

export interface IActiveFeatureInfo {
    key: string;
    description: string;
    hidden?: boolean;
    nonProd?: boolean;
    topic: string;
}

export interface IActiveFeatureStatusInfo extends IActiveFeatureInfo {
    isActive: boolean;
    serverActive: boolean;
    localStorageActive: boolean;
}

export interface IActiveFeatureTopic {
    key: string;
    title: string;
    icon: AllIconsName;
}
