import {
    IDateFilterOption,
    IRangeFilterValue,
} from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';

export enum FilterValueType {
    STRING,
    STRING_LIST,
    NUMERIC_RANGE,
    DATE_RANGE,
}

export type ICompactFilterValue = string | string[] | IRangeFilterValue | IDateFilterOption;

export interface ICompactFilterItem {
    name: string;
    value: ICompactFilterValue;
    valueType: FilterValueType;
}
