import styled, { DefaultTheme, css } from 'styled-components';
import Stack from '../Stack/Stack';
import Button from '../Button/Button';
import { ColorsWithState } from 'common/design-system/theme/theme';

interface IWrapperProps {
    removeBorder?: boolean;
    removeBorderRadius?: boolean;
    value: string;
}
const Wrapper = styled(Stack)<IWrapperProps>`
    width: fit-content;
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};

    ${({ removeBorder }) =>
        removeBorder &&
        css`
            border: unset;
        `}

    ${({ removeBorderRadius }) =>
        removeBorderRadius &&
        css`
            border-radius: unset;
        `}
`;

const toggleButtonSelectedButtonStyles = (theme: DefaultTheme) => css`
    background: ${theme.palette.surface.brandLight};

    &:hover {
        background: ${theme.palette.componentStates.ghostHover};
        color: ${theme.palette.text.strong};
    }
`;

const toggleButtonButtonStyles = (theme: DefaultTheme) => css`
    &:hover {
        background: ${theme.palette.componentStates.ghostHover};
        color: ${theme.palette.text.strong};
    }
`;

interface IButtonStyledProps {
    selected: boolean;
    direction: 'row' | 'column';
    color: ColorsWithState;
    toggleButton: boolean;
}
const ButtonStyled = styled(Button)<IButtonStyledProps>`
    & {
        border: unset;
        max-width: unset;
    }
    ${({ theme, selected, direction, toggleButton }) =>
        !selected &&
        css`
            ${toggleButton && toggleButtonButtonStyles(theme)}

            ${direction === 'row' &&
            css`
                &:first-child {
                    border-radius: ${({ theme }) =>
                        `${theme.border.radius(2)}${theme.units} 0 0 ${theme.border.radius(2)}${theme.units}`};
                }
                &:last-child {
                    border-radius: ${({ theme }) =>
                        `0 ${theme.border.radius(2)}${theme.units} ${theme.border.radius(2)}${theme.units} 0`};
                }
                &:not(:first-child):not(:last-child) {
                    border-radius: 0;
                }
            `}

    ${direction === 'column' &&
            css`
                &:first-child {
                    border-radius: ${({ theme }) =>
                        `${theme.border.radius(2)}${theme.units} ${theme.border.radius(2)}${theme.units} 0 0`};
                }
                &:last-child {
                    border-radius: ${({ theme }) =>
                        `0 0 ${theme.border.radius(2)}${theme.units} ${theme.border.radius(2)}${theme.units}`};
                }
                &:not(:first-child):not(:last-child) {
                    border-radius: 0;
                }
            `}
        `}

    ${({ theme, selected, color, toggleButton }) =>
        selected &&
        css`
            position: relative;

            ${toggleButton && toggleButtonSelectedButtonStyles(theme)}

            &:before {
                content: '';
                position: absolute;
                top: -1px;
                right: -1px;
                bottom: -1px;
                left: -1px;
                background: transparent;
                border: ${`${theme.border.width.standard}${theme.units} solid ${theme.palette.onSurface[color]}`};
                border-radius: ${`${theme.border.radius(2)}${theme.units}`};
            }
        `}
`;

interface IDividerStyledProps {
    direction: 'row' | 'column';
}
const Divider = styled.div<IDividerStyledProps>`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: ${({ direction }) => (direction === 'row' ? '1px' : '100%')};
        height: ${({ direction }) => (direction === 'row' ? 'auto' : '1px')};
        top: 0;
        bottom: 0;
        left: 0;
        background: ${({ theme }) => theme.palette.border.normal};
        transform: translateX(${({ direction }) => (direction === 'row' ? '-50%' : 'unset')});
    }
`;

const ButtonGroupStyles = {
    Wrapper,
    ButtonStyled,
    Divider,
};

export default ButtonGroupStyles;
