import { getVendor, IVendor, Vendors } from 'common/consts/vendors';
import { CLOUD_BOTS_ON_DEMAND_REMEDIATION, EndPointType, FIX_IT } from './CloudBots.const';
import { buildPath } from 'common/extensibility/AddinContainer';
import { CloudBotsRegistry } from './CloudBotsRegistry';
import { ICloudBot } from './CloudBots.interface';
import { IEvent } from 'common/module_interface/events/Events';

export const getCloudBotVendors = (): Vendors[] => {
    const vendors: Set<Vendors> = CloudBotsRegistry.getCloudBots().reduce((vendors: Set<Vendors>, cloudBot: ICloudBot) => {
        cloudBot.platform.forEach((vendor: Vendors) => vendors.add(vendor));
        return vendors;
    }, new Set<Vendors>());
    return Array.from(vendors);
};

export const getEndPointType = (finding?: IEvent): EndPointType => {
    const vendor: IVendor | null = finding?.cloudAccountType ? getVendor(finding.cloudAccountType) : null;
    return vendor?.name === Vendors.AWS ? EndPointType.SNS : EndPointType.HTTP;
};

export const getFixItCustomizationKey = (tableId: string): string => {
    return buildPath(tableId, CLOUD_BOTS_ON_DEMAND_REMEDIATION, FIX_IT);
};
