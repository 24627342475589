import styled, { css } from 'styled-components';
import Button from '../Button';

interface IWrapperProps {
    circleShape?: boolean;
}
const Wrapper = styled(Button)<IWrapperProps>`
    ${({ circleShape }) =>
        circleShape &&
        css`
            border-radius: 50%;
        `};
`;

const TypographyStyles = {
    Wrapper,
};

export default TypographyStyles;
