import React from 'react';

export function useLocalStorage<T>(defaultValue: T, storageKey: string): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [state, setState] = React.useState<T>(() => {
        const itemFromStorage = localStorage.getItem(storageKey);
        return (itemFromStorage ? JSON.parse(itemFromStorage) : defaultValue) as T;
    });

    React.useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(state));
    }, [state, storageKey]);

    return [state, setState];
}
