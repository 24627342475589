import { CounterStatus } from '../../../components/ProtectedAssets/ProtectedAssetsTable';
import { t } from 'i18next';
import { Typography } from '../../../design-system/components-v2';
import { Trans } from 'react-i18next';
import React from 'react';

interface ITableCounterProps {
    counters: {
        totalCount: CounterStatus,
        currentCount: CounterStatus,
    }
}

const TableFooterCounter: React.FC<ITableCounterProps> = ({ counters }) => {
    if (counters.currentCount === CounterStatus.Error || counters.totalCount === CounterStatus.Error) {
        return t('COMMON.PROTECTED_ASSETS_TABLE.COUNTER_ERROR');
    }
    if (counters.currentCount === CounterStatus.Pending || counters.totalCount === CounterStatus.Pending) {
        return t('COMMON.PROTECTED_ASSETS_TABLE.LOADING');
    }

    return <Typography>
        <Trans
            components={{ boldText: <Typography elementType='span' style={{ fontWeight: 500 }}>.</Typography> }}
            i18nKey='COMMON.PROTECTED_ASSETS_TABLE.RESULTS'
            values={{ count: counters.currentCount, totalCount: counters.totalCount }}
        />
    </Typography>;
};

export default TableFooterCounter;
