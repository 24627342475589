import { useCallback, useEffect, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';
import { useTranslation } from 'react-i18next';
import { FILTER_EVENTS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IGenericFilterPanelProps } from '../interfaces';
import { computeAggregationCounts } from './simpleFilterPanelFunctions';

const SimpleGenericFilterPanel = <T,>({
    filterId,
    savedFiltersComponentName,
    tableRegistry,
    translationKey,
    filterIds,
    localFilters,
    tableData,
    rawData,
    isLoading,
    onFilterChange,
}: IGenericFilterPanelProps<T>) => {
    const [filterElement, setFilterElement] = useState(<div></div>);
    const { t } = useTranslation(translationKey);

    const getFiltersInitialData = useCallback(async () => {
        const savedFilters = await getHttpService().request<ICustomzationResponse<any>>(
            `customization?component=${savedFiltersComponentName}`,
            {
                method: 'GET',
            },
        );

        return { savedFilters };
    }, [savedFiltersComponentName]);

    useEffect(() => {
        const onFilterEvent = (action: string, filtersValues: IFiltersValues) => {
            if (action === FILTER_EVENTS.FILTER_CHANGED) {
                onFilterChange(filtersValues);
            }
        };

        async function renderFilterPanel() {
            const filtersInitialData = await getFiltersInitialData();
            const aggregations = computeAggregationCounts(rawData, localFilters);
            const filterPanelElementsList = tableRegistry.getFilterDefs(filterIds, {
                aggregations: aggregations || {},
                filtersInitialData: filtersInitialData || {},
                savedFiltersComponentName,
            });

            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                // @ts-ignore
                getAggregations: () => computeAggregationCounts(tableData, localFilters),
                shouldBuildObjectForAPI: false,
                filterId: filterId,
                onFilterChangeCallBack: onFilterEvent,
            });
            setFilterElement(filterPanel);
        }

        if (!isLoading) {
            renderFilterPanel();
        }
    }, [
        tableData,
        isLoading,
        t,
        rawData,
        onFilterChange,
        getFiltersInitialData,
        localFilters,
        tableRegistry,
        filterIds,
        savedFiltersComponentName,
        filterId,
    ]);

    return <>{filterElement}</>;
};

export default SimpleGenericFilterPanel;
