import { ICommonEventComment } from '../../module_interface/events/Events.interface';
import { Aggregations } from '../FilterPanel/FilterPanel.interface';
import { IElasticEvent, IElasticEventModel } from '../../module_interface/elasticEvents/ElasticEvent.interface';
import { CommonEventFields } from '../../module_interface/events/EventsConsts';

export enum FindingsDataSource {
    FINDING = 'Finding',
    ARCHIVE = 'Archive',
}

export enum FindingSearchFields {
    DOME9_ID = CommonEventFields.entityDome9Id,
    ALERT_TYPE = CommonEventFields.alertType,
    CREATED_TIME = CommonEventFields.createdTime,
    SEVERITY = CommonEventFields.severity,
    BUCKETS_COUNT = 'bucketsCount',
    ID = CommonEventFields.id,
    ORIGIN = CommonEventFields.origin,
}

export enum FindingsAlertType {
    SECURITY = 0,
    FINDINGS = 1,
}

export type Direction = -1 | 0 | 1;

export type SortingModel = {
    fieldName?: string;
    direction?: Direction;
};
export type FilterField = {
    name: string;
    value?: string | number;
    isHidden?: boolean;
};
export type DateTimeRange = {
    from?: string;
    to?: string;
};

export interface ISearchFilterViewModel {
    freeTextPhrase?: string;
    fields?: FilterField[];
    filterFields?: FilterField[];
    creationTime?: DateTimeRange;
    onlyCIEM?: boolean;
    onlyExternal?: boolean;
    hasRemediation?: boolean;
}

export interface ISearchRequestViewModel {
    pageSize?: number;
    skipAggregations?: boolean;
    sorting?: SortingModel;
    multiSorting?: SortingModel[];
    filter?: ISearchFilterViewModel;
    searchAfter?: string[];
    dataSource: FindingsDataSource;
    lowAggregationsSize?: boolean;
}

export interface ISearchWithCustomAggregationsRequestViewModel extends ISearchRequestViewModel {
    aggregations?: string[];
}

export interface IFindingPaginationModel {
    searchRequest: ISearchRequestViewModel;
    findings: IElasticEventModel[];
    totalFindingsCount: number;
    aggregations: Aggregations;
    searchAfter?: string[];
}

export interface IFindingPaginationViewModel extends Omit<IFindingPaginationModel, 'findings'> {
    findings: IElasticEvent[];
}

export interface IFindingComment extends ICommonEventComment {
    action: string;
}

export interface IRulesetDataModel {
    rulesetId: string;
    rulesetName: string;
    rLMRulesetId: string;
    AssessmentHistoryId: string;
}

export interface IEntityObjectModel {
    type?: string;
    id?: string;
    name?: string;
    accountNumber?: string;
    region?: string;
    dome9Id?: string;
    arn?: string;
    'risk-score': number;
    scannedAsset: ScannedAsset;
    files: Array<File>;
    vulnerabilityStats?: VulnerabilityStats;
    severity?: string;
}

interface VulnerabilityStats {
    cve: { all: number; critical: number; high: number; medium: number; low: number };
    cveFixable: { all: number; critical: number; high: number; medium: number; low: number };
    secret: { all: number; critical: number; high: number; medium: number; low: number };
    threat: { all: number; critical: number; high: number; medium: number; low: number };
}

interface ScannedAsset {
    additionalFields: {
        imageDigest: string;
        imageId: string;
    };
    entityName: string;
    entityType: string;
    environmentId: string;
    externalId: string;
    id: string;
    operatingSystem: string;
    platform: string;
}

interface Content {
    lines: Array<number>;
    payload: string;
    'payload-sha256': string;
}

interface File {
    contents: Array<Content>;
    'file-path': string;
    md5: string;
}

export interface IMagellanModel {
    alertWindowStartTime: string;
    alertWindowEndTime: string;
}

export enum IFindingModelOriginString {
    ComplianceEngine = 'ComplianceEngine',
    Magellan = 'Magellan',
    Intelligence = 'Intelligence',
    Serverless = 'Serverless',
    KubernetesRuntimeAssurance = 'KubernetesRuntimeAssurance',
    KubernetesImageScanning = 'KubernetesImageScanning',
    ImageAssurance = 'ImageAssurance',
    SourceCodeAssurance = 'SourceCodeAssurance',
    KubernetesImage = 'KubernetesImage',
    ExternalFindingSource = 'ExternalFindingSource',
    ContainersRuntimeProtection = 'ContainersRuntimeProtection',
    Tenable = 'Tenable',
    InfrastructureAsCode = 'InfrastructureAsCode', // SHIFTLEFT
    Qualys = 'Qualys',
    CIEM = 'CIEM',
    Incident = 'Incident',
    AwsGuardDuty = 'AwsGuardDuty',
    AwsInspector = 'AwsInspector',
    ServerlessSecurityAnalyzer = 'ServerlessSecurityAnalyzer',
    MagellanAwsGuardDuty = 'MagellanAwsGuardDuty',
    Agentless = 'Agentless',
}

export enum IFindingModelOriginCode {
    ComplianceEngine = 0,
    Magellan = 1,
    Intelligence = 1,
    Serverless = 2,
    ImageAssurance = 7,
    SourceCodeAssurance = 8,
    InfrastructureAsCode = 9, // SHIFTLEFT
    CIEM = 10,
    Incident = 11,
    AwsInspector = 50,
    ServerlessSecurityAnalyzer = 51,
    ExternalFindingSource = 100,
    Qualys = 101,
    Tenable = 102,
    AwsGuardDuty = 103,
    KubernetesImageScanning = 104,
    KubernetesImage = 104,
    KubernetesRuntimeAssurance = 105,
    ContainersRuntimeProtection = 106,
    MagellanAwsGuardDuty = 191,
    Agentless = 123,
}

export enum IFindingModelCloudAccountType {
    Aws = 'Aws',
    Azure = 'Azure',
    Google = 'Google',
    Kubernetes = 'Kubernetes',
    Terraform = 'Terraform',
    Generic = 'Generic',
    KubernetesRuntimeAssurance = 'KubernetesRuntimeAssurance',
    ShiftLeft = 'ShiftLeft',
    SourceCodeAssurance = 'SourceCodeAssurance',
    ImageAssurance = 'ImageAssurance',
    Alibaba = 'Alibaba',
    Cft = 'Cft',
    ContainerRegistry = 'ContainerRegistry',
    Ers = 'Ers',
    Oci = 'Oci',
    CIEM = 'CIEM',
}

export enum IFindingModelCloudAccountTypeCode {
    Aws = 1,
    Azure = 7,
    Google = 10,
    Kubernetes = 11,
    Terraform = 12,
    Generic = 13,
    KubernetesRuntimeAssurance = 14,
    ShiftLeft = 15,
    SourceCodeAssurance = 16,
    ImageAssurance = 17,
    Alibaba = 18,
    Cft = 19,
    ContainerRegistry = 20,
    Ers = 21,
    Oci = 22,
    CIEM = 30,
}

export const findingModelOriginStringToCode = (originStr: IFindingModelOriginString): IFindingModelOriginCode => {
    type IFindingModelOriginStringKey = keyof typeof IFindingModelOriginString;
    const enumKey = Object.keys(IFindingModelOriginString)[
        Object.values(IFindingModelOriginString).indexOf(originStr)
    ] as IFindingModelOriginStringKey;
    return IFindingModelOriginCode[enumKey];
};

export const findingModelOriginCodeToString = (originCode: IFindingModelOriginCode): IFindingModelOriginString => {
    type IFindingModelOriginCodeKey = keyof typeof IFindingModelOriginCode;
    const enumKey = Object.keys(IFindingModelOriginCode)[
        Object.values(IFindingModelOriginCode).indexOf(originCode)
    ] as IFindingModelOriginCodeKey;
    return IFindingModelOriginString[enumKey];
};

export enum IFindingModelActionString {
    Detect = 'Detect',
    Prevent = 'Prevent',
}

export enum IFindingModelActionCode {
    Detect = 0,
    Prevent = 1,
}

export const findingModelActionStringToCode = (originStr: IFindingModelActionString): IFindingModelActionCode => {
    type IFindingModelActionStringKey = keyof typeof IFindingModelActionString;
    const enumKey = Object.keys(IFindingModelActionString)[
        Object.values(IFindingModelActionString).indexOf(originStr)
    ] as IFindingModelActionStringKey;
    return IFindingModelActionCode[enumKey];
};

export const findingModelActionCodeToString = (originCode: IFindingModelActionCode): IFindingModelActionString => {
    type IFindingModelActionCodeKey = keyof typeof IFindingModelActionCode;
    const enumKey = Object.keys(IFindingModelActionCode)[
        Object.values(IFindingModelActionCode).indexOf(originCode)
    ] as IFindingModelActionCodeKey;
    return IFindingModelActionString[enumKey];
};

export enum IFindingModelAlertTypeString {
    SecurityEvent = 'SecurityEvent',
    Task = 'Task',
}

export enum IFindingModelAlertTypeCode {
    SecurityEvent = 0,
    Task = 1,
}

export const findingModelAlertTypeStringToCode = (
    originStr: IFindingModelAlertTypeString,
): IFindingModelAlertTypeCode => {
    type IFindingModelAlertTypeStringKey = keyof typeof IFindingModelAlertTypeString;
    const enumKey = Object.keys(IFindingModelAlertTypeString)[
        Object.values(IFindingModelAlertTypeString).indexOf(originStr)
    ] as IFindingModelAlertTypeStringKey;
    return IFindingModelAlertTypeCode[enumKey];
};

export const findingModelAlertTypeCodeToString = (
    originCode: IFindingModelAlertTypeCode,
): IFindingModelAlertTypeString => {
    type IFindingModelAlertTypeCodeKey = keyof typeof IFindingModelAlertTypeCode;
    const enumKey = Object.keys(IFindingModelAlertTypeCode)[
        Object.values(IFindingModelAlertTypeCode).indexOf(originCode)
    ] as IFindingModelAlertTypeCodeKey;
    return IFindingModelAlertTypeString[enumKey];
};

export enum EventCategory {
    ScanSummary = 'ScanSummary',
    Package = 'Package',
    MaliciousFile = 'MaliciousFile',
    MaliciousURL = 'MaliciousURL',
    ImageScan = 'ImageScan',
    InsecureContent = 'InsecureContent',
    LogIDS = 'LogIDS',
}

export enum IFindingModelStatus {
    Active = 'Active',
    Archived = 'Archived',
}

export const findingModelCloudAccountTypeStringToCode = (
    originStr: IFindingModelCloudAccountType,
): IFindingModelCloudAccountTypeCode => {
    type IFindingModelCloudAccountTypeKey = keyof typeof IFindingModelCloudAccountType;
    const enumKey = Object.keys(IFindingModelCloudAccountType)[
        Object.values(IFindingModelCloudAccountType).indexOf(originStr)
    ] as IFindingModelCloudAccountTypeKey;
    return IFindingModelCloudAccountTypeCode[enumKey];
};

export enum EventLabel {
    CIEM = 'CIEM',
}
