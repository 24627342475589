import styled, { css } from 'styled-components';
import Typography from '../Typography/Typography';
import Stack from '../Stack/Stack';

interface BlockWrapperProps {
    maxWidth?: number;
    isInfoStringOrNumber: boolean;
    fullWidth?: boolean;
}
const BlockWrapper = styled.div<BlockWrapperProps>`
    position: relative;
    & > p:first-child {
        margin-bottom: 4px;
    }
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

interface StringOrNumberWrapperProps {
    maxWidth?: number;
}
const StringOrNumberText = styled(Typography)<StringOrNumberWrapperProps>`
    overflow: hidden;
    ${({ maxWidth, theme }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth}${theme.units};
            text-overflow: ellipsis;
            white-space: nowrap;
        `}
`;

const ComponentWrapper = styled(Stack)<StringOrNumberWrapperProps>`
    overflow: hidden;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    ${({ maxWidth, theme }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth}${theme.units};
        `}
`;

const BlcokInfoStyles = {
    BlockWrapper,
    StringOrNumberText,
    ComponentWrapper,
};

export default BlcokInfoStyles;
