import React from 'react';
import EmptyStateStyles from './EmptyState.styles';
import { EmptyStateProps } from './EmptyState.types';
import { Stack, Icon, Button } from 'common/design-system/components-v2';
import { A } from 'common/components/Anchor/Anchor';
import { generateHref } from 'common/utils/http';

const EmptyState: React.FunctionComponent<EmptyStateProps> = (props) => {
    const {
        customColor,
        iconName = 'notFound',
        iconSize = 128,
        fontVariant = 'subtitleLg',
        label,
        description,
        links,
        minHeight,
        buttons,
    } = props;
    return (
        <Stack fullHeight fullWidth justifyContent='center' alignItems='center'>
            <EmptyStateStyles.Wrapper minHeight={minHeight}>
                <Stack spacing={2} alignItems='center'>
                    <Icon name={iconName} size={iconSize} customColor={customColor} />
                    <EmptyStateStyles.TypographyWithStlyes variant={fontVariant} elementType='h1'>
                        {label}
                    </EmptyStateStyles.TypographyWithStlyes>
                    {description && (
                        <EmptyStateStyles.TypographyWithStlyes variant='bodyLg'>
                            {description}
                        </EmptyStateStyles.TypographyWithStlyes>
                    )}
                    {(links || buttons) && (
                        <EmptyStateStyles.LinksWrapper spacing={2} direction='row' justifyContent='center'>
                            {links?.map((link, index) =>
                                link.newTab || link.outsideLink ? (
                                    <a
                                        key={`emptyState-link-${index}`}
                                        target={link.newTab ? '_blank' : undefined}
                                        href={link.outsideLink ? link.url : generateHref(link.url)}
                                        rel='noreferrer'
                                    >
                                        <EmptyStateStyles.LinkButton
                                            iconProps={link.icon ? { size: 10, ...link.icon } : undefined}
                                            {...(link.buttonProps || {})}
                                            variant={link.outsideLink ? 'text' : link.buttonProps?.variant}
                                            asLink={!!link.outsideLink}
                                        >
                                            {link.label}
                                        </EmptyStateStyles.LinkButton>
                                    </a>
                                ) : (
                                    <A href={link.url} key={`emptyState-link-${index}`}>
                                        <EmptyStateStyles.LinkButton
                                            iconProps={link.icon ? { size: 10, ...link.icon } : undefined}
                                            {...(link.buttonProps || {})}
                                            variant={link.outsideLink ? 'text' : link.buttonProps?.variant}
                                            asLink={!!link.outsideLink}
                                        >
                                            {link.label}
                                        </EmptyStateStyles.LinkButton>
                                    </A>
                                ),
                            ) ?? null}
                            {buttons?.map((buttonProps, index) => (
                                <Button key={`button-${index}`} {...buttonProps} />
                            )) ?? null}
                        </EmptyStateStyles.LinksWrapper>
                    )}
                </Stack>
            </EmptyStateStyles.Wrapper>
        </Stack>
    );
};

export default EmptyState;
