import { Palette, Borders } from './colors.types';

export const borders = (palette: Palette): Borders => ({
    default: {
        strong: palette.slate.alpha[60],
        regular: palette.slate.alpha[50],
        weak: palette.slate.alpha[40],
    },
    critical: {
        strong: palette.magenta.alpha[60],
        regular: palette.magenta.alpha[50],
        weak: palette.magenta.alpha[40],
    },
    high: {
        strong: palette.red.alpha[60],
        regular: palette.red.alpha[50],
        weak: palette.red.alpha[40],
    },
    medium: {
        strong: palette.orange.alpha[60],
        regular: palette.orange.alpha[50],
        weak: palette.orange.alpha[40],
    },
    low: {
        strong: palette.yellow.alpha[60],
        regular: palette.yellow.alpha[50],
        weak: palette.yellow.alpha[40],
    },
    lowRisk: {
        strong: palette.green.alpha[60],
        regular: palette.green.alpha[50],
        weak: palette.green.alpha[40],
    },
    info: {
        strong: palette.blue.alpha[60],
        regular: palette.blue.alpha[50],
        weak: palette.blue.alpha[40],
    },
    important: {
        strong: palette.purple.alpha[60],
        regular: palette.purple.alpha[50],
        weak: palette.purple.alpha[40],
    },
});
