import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const BodyDiv = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding:  ${props => props.padding || '20px'};
  overflow: auto;
`;

export const PageStyled = {
    TopDiv,
    BodyDiv,
};
