import React from 'react';
import { WidgetCardStyled } from './WidgetCard.styled';
import { LoadingState } from '../../../interface/general';
import { toDataAid } from '../../../utils/helpFunctions';
import { useTranslation } from 'react-i18next';
import { logAndRunOnComponentClick } from '../../../utils/logUtils';
import TooltipWrapper from '../../Widgets/TooltipWrapper/TooltipWrapper';
import { Icon } from '@dome9/berries/react-components';
import { Spinner } from 'common/design-system/components-v2';

interface IWidgetCard {
    onTitleClick?: Function | null;
    titleTipTooltip?: string;
    title: string;
    content: any;
    classNames?: { container?: string; title?: string };
    tooltip?: React.ReactNode;
    titleInfoElements?: React.ReactNode[];
    loadingState?: LoadingState;
}

const WidgetCard: React.FC<IWidgetCard> = ({
    title,
    titleTipTooltip,
    content,
    onTitleClick,
    classNames = {},
    tooltip,
    titleInfoElements,
    loadingState,
}) => {
    const { t } = useTranslation();
    const finalOnTitleClick = onTitleClick
        ? () => logAndRunOnComponentClick(onTitleClick, title + '-header')
        : undefined;

    return (
        <WidgetCardStyled.CardDiv
            data-aid={toDataAid(title)}
            className={`bg-content flex flex-1 flex-col border widget-card ${classNames.container}`}
        >
            <TooltipWrapper tooltip={tooltip}>
                <>
                    <WidgetCardStyled.HeaderDiv
                        className={`flex items-center border-b px-7 py-4 font-medium leading-[22px] flex-0 cursor-default ${classNames.title ?? ''}`}
                    >
                        <WidgetCardStyled.LeftHeaderBoxDiv>
                            <WidgetCardStyled.TitleDiv>{title}</WidgetCardStyled.TitleDiv>
                            {titleInfoElements?.map((reactNode, index) => {
                                return <React.Fragment key={index}>{reactNode}</React.Fragment>;
                            })}
                        </WidgetCardStyled.LeftHeaderBoxDiv>
                        {finalOnTitleClick && (
                            <WidgetCardStyled.TitleActionDiv onClick={() => finalOnTitleClick()}>
                                <TooltipWrapper tooltip={titleTipTooltip}>
                                    <Icon color='primary' name='tips' size={16} />
                                </TooltipWrapper>
                            </WidgetCardStyled.TitleActionDiv>
                        )}
                    </WidgetCardStyled.HeaderDiv>
                    {loadingState === LoadingState.IS_LOADING && (
                        <WidgetCardStyled.SpinnerDiv>
                            <Spinner />
                        </WidgetCardStyled.SpinnerDiv>
                    )}
                    {loadingState === LoadingState.LOADING_FAILED && (
                        <WidgetCardStyled.FailureDiv>{t('COMMON.ERROR_OCCURRED')}</WidgetCardStyled.FailureDiv>
                    )}
                    {(!loadingState || loadingState === LoadingState.LOADING_SUCCEEDED) && content}
                </>
            </TooltipWrapper>
        </WidgetCardStyled.CardDiv>
    );
};

export default WidgetCard;
