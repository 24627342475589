import i18next from 'i18next';
import { Tag } from '../../../components/Tags/Tags.types';
import { FormFieldType, FormItemType, TAG_KEY, TAG_VALUE } from './CustomForm.consts';
import {
    convertValuesToTags,
    getActiveItemsMap,
    getTrimmedSingleValue,
    getItemLabel, getSafeTrimmedMultiValue,
    isEmptyItemValue,
} from './CustomForm.values';
import { IComponentRef } from '../../utils/ermComponents.interface';
import {
    IBaseInputFieldItem, IFormParams, IFullErrorInfo, IFullErrorsInfo,
    IInputItem,
    IItem, IItemErrorsMap, IItemsMap, IMultiInputItem,
    ITagsItem,
} from './CustomForm.interface';

export const checkCharsLimit = (item: IBaseInputFieldItem): IFullErrorInfo | undefined => {
    const charsLimit: number | undefined = item.state.charsLimit;
    if (charsLimit === undefined) {
        return undefined;
    }
    const value: string | undefined = getTrimmedSingleValue(item as IItem);
    if (!value) {
        return undefined;
    }
    if (value.length > charsLimit) {
        return {
            name: item.name,
            errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.VALUE_LENGTH_EXCEEDED_LIMIT', { charsLimit }),
        };
    }
};

export const checkRequiredValue = (item: IItem): IFullErrorInfo | undefined => {
    if (isEmptyItemValue(item)) {
        return {
            name: item.name,
            errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.DATA_REQUIRED', { label: getItemLabel(item) }),
        };
    }
};

export const checkForbiddenValue = (item: IInputItem): IFullErrorInfo | undefined => {
    if (!item.state.forbiddenOtherValues) {
        return undefined;
    }
    const value: string | undefined = getTrimmedSingleValue(item);
    if (!value) {
        return undefined;
    }
    if (item.state.forbiddenOtherValues.some(existingValue => existingValue === value)) {
        return {
            name: item.name,
            errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.VALUE_ALREADY_EXISTS', { label: getItemLabel(item) }),
        };
    }
};

export const checkTags = (item: ITagsItem): IFullErrorInfo | undefined => {
    const values: string[] = getSafeTrimmedMultiValue(item);
    if (values.length === 0) {
        return undefined;
    }
    const subErrors: IFullErrorInfo[] = [];
    const tags: Tag[] = convertValuesToTags(values);
    tags.forEach((tag: Tag, index: number) => {
        const tagErrors: IFullErrorInfo[] = [];
        if (!tag.key?.trim()) {
            tagErrors.push({
                name: TAG_KEY,
                errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.MISSING_TAG_KEY'),
            });
        }
        if (!tag.value?.trim()) {
            tagErrors.push({
                name: TAG_VALUE,
                errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.MISSING_TAG_VALUE'),
            });
        }
        if (tagErrors.length > 0) {
            subErrors.push({
                name: String(index),
                errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.INVALID_TAGS'),
                subErrors: tagErrors,
            });
        }
    });
    if (subErrors.length > 0) {
        return {
            name: item.name,
            errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.INVALID_TAGS', { label: getItemLabel(item) }),
            subErrors,
        };
    }
};

export const checkMultiInput = (item: IMultiInputItem): IFullErrorInfo | undefined => {
    const values: string[] = getSafeTrimmedMultiValue(item);
    if (values.length === 0) {
        return undefined;
    }
    const subErrors: IFullErrorInfo[] = [];
    values.forEach((text: string, index: number) => {
        if (!text) {
            subErrors.push({
                name: String(index),
                errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.MISSING_MULTI_INPUT_VALUE'),
            });
        }
    });
    if (subErrors.length > 0) {
        return {
            name: item.name,
            errorMessage: i18next.t('CUSTOM_FORM.VALIDATIONS.MISSING_MULTI_INPUT_VALUES', { label: getItemLabel(item) }),
            subErrors,
        };
    }
};

export const checkAutoValidations = (item: IItem): IFullErrorInfo[] | undefined => {
    if (item.state.isRequired) {
        const error: IFullErrorInfo | undefined = checkRequiredValue(item);
        if (error) {
            return [error];
        }
    }

    if (item.itemType === FormItemType.field) {
        if ((item.fieldType === FormFieldType.input) || (item.fieldType === FormFieldType.textArea)) {
            const error: IFullErrorInfo | undefined = checkCharsLimit(item as IBaseInputFieldItem);
            if (error) {
                return [error];
            }
        }

        if (item.fieldType === FormFieldType.input) {
            const error: IFullErrorInfo | undefined = checkForbiddenValue(item as IInputItem);
            if (error) {
                return [error];
            }
        } else if (item.fieldType === FormFieldType.tags) {
            const error: IFullErrorInfo | undefined = checkTags(item as ITagsItem);
            if (error) {
                return [error];
            }
        } else if (item.fieldType === FormFieldType.multiInput) {
            const error: IFullErrorInfo | undefined = checkMultiInput(item as IMultiInputItem);
            if (error) {
                return [error];
            }
        }
    }
};

export const checkItemValidations = async (item: IItem, activeItemsMap: IItemsMap): Promise<IFullErrorInfo | undefined> => {
    if (!item.checkValidation) {
        return undefined;
    }

    const errorMessage: string | undefined = await item.checkValidation(item, activeItemsMap);
    if (errorMessage) {
        return {
            name: item.name,
            errorMessage,
        };
    }
};


export const getFullErrorsInfo = async (params: IFormParams, itemsMap: IItemsMap): Promise<IFullErrorsInfo> => {
    const activeItemsMap: IItemsMap = getActiveItemsMap(params, itemsMap);
    const errors: IFullErrorInfo[] = [];
    const flatItems: IItem[] = Object.values(itemsMap);
    for (let i = 0; i < flatItems.length; i++) {
        const item: IItem = flatItems[i];
        let error: IFullErrorInfo | undefined;
        if (item.itemType === FormItemType.field) {
            if (item.checkValidation) {
                error = await checkItemValidations(item, activeItemsMap);
            }
            if (!error && !params.disableAutoValidation && !item.ignoreAutoValidations) {
                const autoErrors: IFullErrorInfo[] | undefined = checkAutoValidations(item);
                if (autoErrors) {
                    errors.push(...autoErrors);
                }
            }
        } else {
            if (item.checkValidation) {
                error = await checkItemValidations(item, activeItemsMap);
            }
        }
        if (error) {
            errors.push(error);
        }
    }

    const errorsMap: IItemErrorsMap = {};
    errors.forEach(error => errorsMap[error.name] = error);
    return {
        errors,
        errorsMap,
    };
};

export const getErrorRef = (error: IFullErrorInfo): IComponentRef | undefined => {
    if (error.subErrors) {
        return getFirstErrorRef(error.subErrors);
    }
    return error.componentRef;
};

export const getFirstErrorRef = (errors: IFullErrorInfo[]): IComponentRef | undefined => {
    for (let i = 0; i < errors.length; i++) {
        const ref: IComponentRef | undefined = getErrorRef(errors[i]);
        if (ref) {
            return ref;
        }
    }
};


