import styled from 'styled-components';

const TopDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
`;

export const BoxesRowStyled = {
    TopDiv,
};
