import { addAlpha } from '../colors';
import { Mode, Palette } from './colors.types';

const lightPalette: Palette = {
    slate: {
        alpha: {
            10: addAlpha('#1E2D48', 0.2),
            20: addAlpha('#003DA8', 0.4),
            30: addAlpha('#003899', 0.7),
            40: addAlpha('#003BA3', 0.9),
            50: addAlpha('#002566', 0.14),
            60: addAlpha('#002566', 0.18),
            70: addAlpha('#0A285C', 0.21),
            80: addAlpha('#0A285C', 0.24),
            90: addAlpha('#052761', 0.28),
            100: addAlpha('#0C2145', 0.55),
            200: addAlpha('#0B2146', 0.61),
            300: addAlpha('#0B1F42', 0.66),
            400: addAlpha('#0C2145', 0.72),
            500: addAlpha('#0D2144', 0.78),
            600: addAlpha('#0D2144', 0.83),
            700: addAlpha('#0C2145', 0.9),
            800: addAlpha('#0B1F42', 0.95),
            900: '#0D2040',
        },
        opaque: {
            10: '#ffffff',
            20: '#F1F4F9',
            30: '#EDF1F7',
            40: '#E9EEF6',
            50: '#DCE1EA',
            60: '#D3D9E4',
            70: '#CCD4E0',
            80: '#C3CBDA',
            90: '#BAC3D4',
            100: '#77859C',
            200: '#6A7890',
            300: '#5E6B82',
            400: '#505E77',
            500: '#41516C',
            600: '#354764',
            700: '#243757',
            800: '#162C50',
            900: '#091E42',
        },
    },
    blue: {
        alpha: {
            10: addAlpha('#003570', 0.2),
            20: addAlpha('#003570', 0.4),
            30: addAlpha('#003570', 0.7),
            40: addAlpha('#003570', 0.9),
            50: addAlpha('#003570', 0.14),
            60: addAlpha('#003570', 0.18),
            70: addAlpha('#003570', 0.21),
            80: addAlpha('#003570', 0.24),
            90: addAlpha('#003570', 0.28),
            100: addAlpha('#003570', 0.55),
            200: addAlpha('#003570', 0.61),
            300: addAlpha('#003570', 0.66),
            400: addAlpha('#003570', 0.72),
            500: addAlpha('#003570', 0.78),
            600: addAlpha('#003570', 0.83),
            700: addAlpha('#003570', 0.9),
            800: addAlpha('#003570', 0.95),
            900: '#003570',
        },
        opaque: {
            10: '#F0F7FF',
            20: '#E0EFFF',
            30: '#D1E7FF',
            40: '#BDDBFF',
            50: '#A8D1FF',
            60: '#8AC0FF',
            70: '#75B5FF',
            80: '#61ACFF',
            90: '#4DA1FF',
            100: '#3392FF',
            200: '#2585F4',
            300: '#0D77F2',
            400: '#0B6BDA',
            500: '#0A60C2',
            600: '#0957AE',
            700: '#004DA3',
            800: '#00438F',
            900: '#003A7A',
        },
    },
    purple: {
        alpha: {
            10: addAlpha('#3C0066', 0.05),
            20: addAlpha('#3C0066', 0.1),
            30: addAlpha('#3C0066', 0.15),
            40: addAlpha('#3C0066', 0.2),
            50: addAlpha('#3C0066', 0.25),
            60: addAlpha('#3C0066', 0.3),
            70: addAlpha('#3C0066', 0.35),
            80: addAlpha('#3C0066', 0.4),
            90: addAlpha('#3C0066', 0.45),
            100: addAlpha('#3C0066', 0.6),
            200: addAlpha('#3C0066', 0.65),
            300: addAlpha('#3C0066', 0.7),
            400: addAlpha('#3C0066', 0.75),
            500: addAlpha('#3C0066', 0.8),
            600: addAlpha('#3C0066', 0.85),
            700: addAlpha('#3C0066', 0.9),
            800: addAlpha('#3C0066', 0.95),
            900: '#3C0066',
        },
        opaque: {
            10: '#F9F0FF',
            20: '#F1DBFF',
            30: '#E6C7FF',
            40: '#DFB3FF',
            50: '#D79EFF',
            60: '#CE8AFF',
            70: '#C575FF',
            80: '#BD61FF',
            90: '#B44DFF',
            100: '#A933FF',
            200: '#9D25F4',
            300: '#920DF2',
            400: '#840BDA',
            500: '#7700CC',
            600: '#6800B2',
            700: '#580099',
            800: '#4B0080',
            900: '#3C0066',
        },
    },
    magenta: {
        alpha: {
            10: addAlpha('#660021', 0.5),
            20: addAlpha('#660021', 0.1),
            30: addAlpha('#660021', 0.15),
            40: addAlpha('#660021', 0.2),
            50: addAlpha('#660021', 0.25),
            60: addAlpha('#660021', 0.3),
            70: addAlpha('#660021', 0.35),
            80: addAlpha('#660021', 0.4),
            90: addAlpha('#660021', 0.45),
            100: addAlpha('#660021', 0.6),
            200: addAlpha('#660021', 0.65),
            300: addAlpha('#660021', 0.7),
            400: addAlpha('#660021', 0.75),
            500: addAlpha('#660021', 0.8),
            600: addAlpha('#660021', 0.85),
            700: addAlpha('#660021', 0.9),
            800: addAlpha('#660021', 0.95),
            900: '#660021',
        },
        opaque: {
            10: '#FFF0F4',
            20: '#FFE0EB',
            30: '#FFCCDD',
            40: '#FFB8CF',
            50: '#FFA3C2',
            60: '#FF8FB4',
            70: '#FF7AA7',
            80: '#FF6699',
            90: '#FF4785',
            100: '#F53D7A',
            200: '#F4256A',
            300: '#F20D59',
            400: '#DA0B50',
            500: '#C20A47',
            600: '#B2003B',
            700: '#990033',
            800: '#80002A',
            900: '#660022',
        },
    },
    red: {
        alpha: {
            10: addAlpha('#661400', 0.5),
            20: addAlpha('#661400', 0.1),
            30: addAlpha('#661400', 0.15),
            40: addAlpha('#661400', 0.2),
            50: addAlpha('#661400', 0.25),
            60: addAlpha('#661400', 0.3),
            70: addAlpha('#661400', 0.35),
            80: addAlpha('#661400', 0.4),
            90: addAlpha('#661400', 0.45),
            100: addAlpha('#661400', 0.6),
            200: addAlpha('#661400', 0.65),
            300: addAlpha('#661400', 0.7),
            400: addAlpha('#661400', 0.75),
            500: addAlpha('#661400', 0.8),
            600: addAlpha('#661400', 0.85),
            700: addAlpha('#661400', 0.9),
            800: addAlpha('#661400', 0.95),
            900: '#661400',
        },
        opaque: {
            10: '#FFF3F0',
            20: '#FFE2DB',
            30: '#FFD2C7',
            40: '#FFC2B2',
            50: '#FFB19E',
            60: '#FFA18A',
            70: '#FFA18A',
            80: '#FF9175',
            90: '#FF8161',
            100: '#FF5C33',
            200: '#FF471A',
            300: '#FF3300',
            400: '#E52E00',
            500: '#CC2900',
            600: '#B22400',
            700: '#991F00',
            800: '#801A00',
            900: '#661400',
        },
    },
    orange: {
        alpha: {
            10: addAlpha('#663300', 0.5),
            20: addAlpha('#663300', 0.1),
            30: addAlpha('#663300', 0.15),
            40: addAlpha('#663300', 0.2),
            50: addAlpha('#663300', 0.25),
            60: addAlpha('#663300', 0.3),
            70: addAlpha('#663300', 0.35),
            80: addAlpha('#663300', 0.4),
            90: addAlpha('#663300', 0.45),
            100: addAlpha('#663300', 0.6),
            200: addAlpha('#663300', 0.65),
            300: addAlpha('#663300', 0.7),
            400: addAlpha('#663300', 0.75),
            500: addAlpha('#663300', 0.8),
            600: addAlpha('#663300', 0.85),
            700: addAlpha('#663300', 0.9),
            800: addAlpha('#663300', 0.95),
            900: '#663300',
        },
        opaque: {
            10: '#FFF7F0',
            20: '#FFEDDB',
            30: '#FFE2C7',
            40: '#FFD9B2',
            50: '#FFCF9E',
            60: '#FFC58A',
            70: '#FFBA75',
            80: '#FFAF61',
            90: '#FFA54D',
            100: '#FF9933',
            200: '#FF8C1A',
            300: '#FF8000',
            400: '#E57200',
            500: '#CC6500',
            600: '#B25900',
            700: '#994D00',
            800: '#804200',
            900: '#663500',
        },
    },
    yellow: {
        alpha: {
            10: addAlpha('#4D4000', 0.5),
            20: addAlpha('#4D4000', 0.1),
            30: addAlpha('#4D4000', 0.15),
            40: addAlpha('#4D4000', 0.2),
            50: addAlpha('#4D4000', 0.25),
            60: addAlpha('#4D4000', 0.3),
            70: addAlpha('#4D4000', 0.35),
            80: addAlpha('#4D4000', 0.4),
            90: addAlpha('#4D4000', 0.45),
            100: addAlpha('#4D4000', 0.6),
            200: addAlpha('#4D4000', 0.65),
            300: addAlpha('#4D4000', 0.7),
            400: addAlpha('#4D4000', 0.75),
            500: addAlpha('#4D4000', 0.8),
            600: addAlpha('#4D4000', 0.85),
            700: addAlpha('#4D4000', 0.9),
            800: addAlpha('#4D4000', 0.95),
            900: '#4D4000',
        },
        opaque: {
            10: '#FFF9E0',
            20: '#FDF6D3',
            30: '#FCF3C5',
            40: '#F9EEB8',
            50: '#F8EAA5',
            60: '#F6E593',
            70: '#F5E17F',
            80: '#F7E069',
            90: '#F6DB56',
            100: '#F2CC0D',
            200: '#E5BF00',
            300: '#CCAA00',
            400: '#B29400',
            500: '#998000',
            600: '#806A00',
            700: '#665500',
            800: '#4D4000',
            900: '#332B00',
        },
    },
    green: {
        alpha: {
            10: addAlpha('#004D00', 0.5),
            20: addAlpha('#004D00', 0.1),
            30: addAlpha('#004D00', 0.15),
            40: addAlpha('#004D00', 0.2),
            50: addAlpha('#004D00', 0.25),
            60: addAlpha('#004D00', 0.3),
            70: addAlpha('#004D00', 0.35),
            80: addAlpha('#004D00', 0.4),
            90: addAlpha('#004D00', 0.45),
            100: addAlpha('#004D00', 0.6),
            200: addAlpha('#004D00', 0.65),
            300: addAlpha('#004D00', 0.7),
            400: addAlpha('#004D00', 0.75),
            500: addAlpha('#004D00', 0.8),
            600: addAlpha('#004D00', 0.85),
            700: addAlpha('#004D00', 0.9),
            800: addAlpha('#004D00', 0.95),
            900: '#004D00',
        },
        opaque: {
            10: '#F1FDF1',
            20: '#E4FBE4',
            30: '#CEF8CE',
            40: '#BCF5BC',
            50: '#AAF3AA',
            60: '#98F098',
            70: '#87EE87',
            80: '#75EB75',
            90: '#63E963',
            100: '#2DD22D',
            200: '#28BD28',
            300: '#1EAE1E',
            400: '#179C17',
            500: '#0F8A0F',
            600: '#077907',
            700: '#006600',
            800: '#004D00',
            900: '#003300',
        },
    },
};

const darkPalette: Palette = {
    slate: {
        opaque: {
            10: '#091e42',
            20: '#15294b',
            30: '#243757',
            40: '#354764',
            50: '#42526d',
            60: '#4b5e7e',
            70: '#54698b',
            80: '#5d749c',
            90: '#6c83a7',
            100: '#afc3e4',
            200: '#bacbe8',
            300: '#c6d3ec',
            400: '#d1dcf0',
            500: '#dde5f4',
            600: '#e8eff7',
            700: '#f0f2fa',
            800: '#f7fafc',
            900: '#ffffff',
        },
        alpha: {
            10: 'rgba(229, 238, 255, .04)',
            20: 'rgba(229, 238, 255, .06)',
            30: 'rgba(229, 238, 255, .08)',
            40: 'rgba(229, 238, 255, .12)',
            50: 'rgba(229, 238, 255, .18)',
            60: 'rgba(229, 238, 255, .24)',
            70: 'rgba(229, 238, 255, .32)',
            80: 'rgba(229, 238, 255, .40)',
            90: 'rgba(229, 238, 255, .48)',
            100: 'rgba(229, 238, 255, .65)',
            200: 'rgba(229, 238, 255, .70)',
            300: 'rgba(229, 238, 255, .75)',
            400: 'rgba(229, 238, 255, .80)',
            500: 'rgba(229, 238, 255, .85)',
            600: 'rgba(229, 238, 255, .90)',
            700: 'rgba(229, 238, 255, .95)',
            800: '#e5eeff',
            900: '#ffffff',
        },
    },
    blue: {
        opaque: {
            10: '#003066',
            20: '#003C80',
            30: '#004899',
            40: '#0954AA',
            50: '#0A5FC2',
            60: '#0C6BDA',
            70: '#0C77F3',
            80: '#1A84FF',
            90: '#3391FF',
            100: '#61ACFF',
            200: '#75B7FF',
            300: '#8AC0FF',
            400: '#9ECBFF',
            500: '#B2D6FF',
            600: '#C2DDFF',
            700: '#D1E8FF',
            800: '#E0EFFF',
            900: '#F0F7FF',
        },
        alpha: {
            10: 'rgba(204, 228, 255, .05)',
            20: 'rgba(204, 228, 255, .10)',
            30: 'rgba(204, 228, 255, .15)',
            40: 'rgba(204, 228, 255, .20)',
            50: 'rgba(204, 228, 255, .25)',
            60: 'rgba(204, 228, 255, .30)',
            70: 'rgba(204, 228, 255, .35)',
            80: 'rgba(204, 228, 255, .40)',
            90: 'rgba(204, 228, 255, .45)',
            100: 'rgba(204, 228, 255, .60)',
            200: 'rgba(204, 228, 255, .65)',
            300: 'rgba(204, 228, 255, .70)',
            400: 'rgba(204, 228, 255, .75)',
            500: 'rgba(204, 228, 255, .80)',
            600: 'rgba(204, 228, 255, .85)',
            700: 'rgba(204, 228, 255, .90)',
            800: 'rgba(204, 228, 255, .95)',
            900: '#cce4ff',
        },
    },
    purple: {
        opaque: {
            10: '#3c0066',
            20: '#4b0080',
            30: '#580099',
            40: '#6800b2',
            50: '#7700cc',
            60: '#840bda',
            70: '#920df2',
            80: '#9d25f4',
            90: '#a933ff',
            100: '#b44dff',
            200: '#bd61ff',
            300: '#c575ff',
            400: '#ce8aff',
            500: '#d79eff',
            600: '#dfb3ff',
            700: '#e6c7ff',
            800: '#f1dbff',
            900: '#f9f0ff',
        },
        alpha: {
            10: 'rgba(213, 153, 255, .05)',
            20: 'rgba(213, 153, 255, .10)',
            30: 'rgba(213, 153, 255, .15)',
            40: 'rgba(213, 153, 255, .20)',
            50: 'rgba(213, 153, 255, .25)',
            60: 'rgba(213, 153, 255, .30)',
            70: 'rgba(213, 153, 255, .35)',
            80: 'rgba(213, 153, 255, .40)',
            90: 'rgba(213, 153, 255, .45)',
            100: 'rgba(213, 153, 255, .60)',
            200: 'rgba(213, 153, 255, .65)',
            300: 'rgba(213, 153, 255, .70)',
            400: 'rgba(223, 178, 255, .95)',
            500: 'rgba(234, 204, 255, .95)',
            600: 'rgba(242, 224, 255, .95)',
            700: '#f2e0ff',
            800: '#f9f0ff',
            900: '#ffffff',
        },
    },
    magenta: {
        opaque: {
            10: '#660022',
            20: '#80002a',
            30: '#990033',
            40: '#b2003b',
            50: '#c20a47',
            60: '#da0b50',
            70: '#f20d59',
            80: '#f4256a',
            90: '#f53d7a',
            100: '#ff4785',
            200: '#ff6699',
            300: '#ff7aa7',
            400: '#ff8fb4',
            500: '#ffa3c2',
            600: '#ffb8cf',
            700: '#ffccdd',
            800: '#ffe0eb',
            900: '#fff0f4',
        },
        alpha: {
            10: 'rgba(255, 153, 178, .05)',
            20: 'rgba(255, 153, 178, .10)',
            30: 'rgba(255, 153, 178, .15)',
            40: 'rgba(255, 153, 178, .20)',
            50: 'rgba(255, 153, 178, .25)',
            60: 'rgba(255, 153, 178, .30)',
            70: 'rgba(255, 153, 178, .35)',
            80: 'rgba(255, 153, 178, .40)',
            90: 'rgba(255, 153, 178, .45)',
            100: 'rgba(255, 153, 178, .60)',
            200: 'rgba(255, 153, 178, .65)',
            300: 'rgba(255, 153, 178, .70)',
            400: 'rgba(255, 153, 178, .75)',
            500: 'rgba(255, 153, 178, .80)',
            600: 'rgba(255, 153, 178, .85)',
            700: 'rgba(255, 204, 216, .95)',
            800: '#ffccd9',
            900: '#ffebf0',
        },
    },
    red: {
        opaque: {
            10: '#661400',
            20: '#801a00',
            30: '#991f00',
            40: '#b22400',
            50: '#cc2900',
            60: '#e52e00',
            70: '#ff3300',
            80: '#ff471a',
            90: '#ff5c33',
            100: '#ff704d',
            200: '#ff7b61',
            300: '#ff9175',
            400: '#ffa18a',
            500: '#ffb19e',
            600: '#ffc1b2',
            700: '#ffd2c7',
            800: '#ffe2db',
            900: '#fff3f0',
        },
        alpha: {
            10: 'rgba(255, 173, 153, .05)',
            20: 'rgba(255, 173, 153, .10)',
            30: 'rgba(255, 173, 153, .15)',
            40: 'rgba(255, 173, 153, .20)',
            50: 'rgba(255, 173, 153, .25)',
            60: 'rgba(255, 173, 153, .30)',
            70: 'rgba(255, 173, 153, .35)',
            80: 'rgba(255, 173, 153, .40)',
            90: 'rgba(255, 173, 153, .45)',
            100: 'rgba(255, 173, 153, .60)',
            200: 'rgba(255, 173, 153, .65)',
            300: 'rgba(255, 173, 153, .70)',
            400: 'rgba(255, 173, 153, .75)',
            500: 'rgba(255, 173, 153, .80)',
            600: 'rgba(255, 173, 153, .85)',
            700: 'rgba(255, 173, 153, .90)',
            800: 'rgba(255, 173, 153, .95)',
            900: '#ffad99',
        },
    },
    orange: {
        opaque: {
            10: '#4d2800',
            20: '#663500',
            30: '#804200',
            40: '#994d00',
            50: '#b25900',
            60: '#cc6500',
            70: '#e57200',
            80: '#ff8000',
            90: '#ff8c1a',
            100: '#ff9933',
            200: '#ffb266',
            300: '#ffbd7a',
            400: '#ffc78f',
            500: '#ffd1a3',
            600: '#ffdcb8',
            700: '#ffe5cc',
            800: '#ffefe0',
            900: '#fff7f0',
        },
        alpha: {
            10: 'rgba(255, 178, 102, .05)',
            20: 'rgba(255, 178, 102, .10)',
            30: 'rgba(255, 178, 102, .15)',
            40: 'rgba(255, 178, 102, .20)',
            50: 'rgba(255, 178, 102, .25)',
            60: 'rgba(255, 178, 102, .30)',
            70: 'rgba(255, 178, 102, .35)',
            80: 'rgba(255, 178, 102, .40)',
            90: 'rgba(255, 178, 102, .45)',
            100: 'rgba(255, 178, 102, .60)',
            200: 'rgba(255, 178, 102, .65)',
            300: 'rgba(255, 178, 102, .70)',
            400: 'rgba(255, 178, 102, .75)',
            500: 'rgba(255, 178, 102, .80)',
            600: 'rgba(255, 178, 102, .85)',
            700: 'rgba(255, 178, 102, .90)',
            800: 'rgba(255, 191, 128, .95)',
            900: '#ffcc99',
        },
    },
    yellow: {
        opaque: {
            10: '#332b00',
            20: '#4d4000',
            30: '#665400',
            40: '#806a00',
            50: '#998000',
            60: '#b29500',
            70: '#ccaa00',
            80: '#E5BF00',
            90: '#F2CC0D',
            100: '#F6DB56',
            200: '#F3DD6D',
            300: '#F5E17F',
            400: '#F6E593',
            500: '#F8EAA5',
            600: '#F9EEB8',
            700: '#FCF4CA',
            800: '#FDF8DD',
            900: '#E7FEED',
        },
        alpha: {
            10: 'rgba(255, 238, 153, .05)',
            20: 'rgba(255, 238, 153, .10)',
            30: 'rgba(255, 238, 153, .15)',
            40: 'rgba(255, 238, 153, .20)',
            50: 'rgba(255, 238, 153, .25)',
            60: 'rgba(255, 238, 153, .30)',
            70: 'rgba(255, 238, 153, .35)',
            80: 'rgba(255, 238, 153, .40)',
            90: 'rgba(255, 238, 153, .45)',
            100: 'rgba(255, 238, 153, .60)',
            200: 'rgba(255, 238, 153, .65)',
            300: 'rgba(255, 238, 153, .70)',
            400: 'rgba(255, 238, 153, .75)',
            500: 'rgba(255, 238, 153, .80)',
            600: 'rgba(255, 238, 153, .85)',
            700: 'rgba(255, 238, 153, .90)',
            800: 'rgba(255, 238, 153, .95)',
            900: '#ffee99',
        },
    },
    green: {
        opaque: {
            10: '#004d00',
            20: '#006600',
            30: '#008000',
            40: '#089108',
            50: '#12a112',
            60: '#19b219',
            70: '#1dc91d',
            80: '#20df20',
            90: '#36e236',
            100: '#63e963',
            200: '#75eb75',
            300: '#87ee87',
            400: '#98f098',
            500: '#aaf3aa',
            600: '#bcf5bc',
            700: '#cef8ce',
            800: '#e4fbe4',
            900: '#f1fdf1',
        },
        alpha: {
            10: 'rgba(153, 255, 153, .05)',
            20: 'rgba(153, 255, 153, .10)',
            30: 'rgba(153, 255, 153, .15)',
            40: 'rgba(153, 255, 153, .20)',
            50: 'rgba(153, 255, 153, .25)',
            60: 'rgba(153, 255, 153, .30)',
            70: 'rgba(153, 255, 153, .35)',
            80: 'rgba(153, 255, 153, .40)',
            90: 'rgba(153, 255, 153, .45)',
            100: 'rgba(153, 255, 153, .60)',
            200: 'rgba(153, 255, 153, .65)',
            300: 'rgba(153, 255, 153, .70)',
            400: 'rgba(153, 255, 153, .75)',
            500: 'rgba(153, 255, 153, .80)',
            600: 'rgba(153, 255, 153, .85)',
            700: 'rgba(153, 255, 153, .90)',
            800: 'rgba(153, 255, 153, .95)',
            900: '#99ff99',
        },
    },
};

export const getPalette = (mode: Mode = 'light'): Palette => {
    return mode === 'light' ? lightPalette : darkPalette;
};
