export const jsonColors: { [key: string]: string } = {
    content: '#009033',
    key: '#009033',
    string: '#b2762e',
    number: '#676dff',
    boolean: '#dc155e',
    date: '#2eb29a',
    regexp: '#b2762e',
    function: '#b2ab2e',
    symbol: '#b2762e',
    null: '#5d635f',
    undefined: '#5d635f',
    comma: '#5d635f',
    pt: '#5d635f',
    line: '#5d635f',
    items: '#b1b1b1',
    error: '#b1b1b1',
    set: '#f56847',
    map: '#f56847',
    highlight: '#FFFF00FF',
    highlightPartly: '#dbdee7',
    highlightBackground: '#e0e8ff',
    tagBackground: '#f7f7f7',
    tagBorder: '#dedede',
    transparent: 'transparent',
};

export const tagsStyleElements = [
    'null',
    'boolean',
    'undefined',
    'regexp',
    'date',
    'set',
    'map',
    'error',
    'symbol',
    'function',
];

export const KEYS_CONTAINS_EPOCH_TIME = [
    'createDate',
    'updateDate',
    'endDateTime',
    'readyDateTime',
    'createdAt',
    'revokedAt',
    'creationTime',
    'creationDateTime',
    'passwordLastUsed',
    'passwordLastChanged',
    'passwordNextRotation',
    'lastRotated',
    'lastUsedDate',
    'lastNonInteractiveSignIn',
    'lastSignIn',
    'createdTime',
    'updatedTime',
    'preparedAt',
    'lastModifiedTime',
    'latestCloudWatchLogsDeliveryError',
    'latestCloudWatchLogsDeliveryTime',
    'latestDeliveryAttemptSucceeded',
    'latestDeliveryAttemptTime',
    'latestDeliveryError',
    'latestDeliveryErrorString',
    'latestDeliveryTime',
    'latestDigestDeliveryError',
    'latestDigestDeliveryTime',
    'latestNotificationAttemptSucceeded',
    'latestNotificationAttemptTime',
    'latestNotificationError',
    'latestNotificationTime',
    'startLoggingTime',
    'stopLoggingTime',
    'timeLoggingStarted',
    'timeLoggingStopped',
    'expirationTime',
    'lastDecreaseDateTime',
    'lastIncreaseDateTime',
    'latestStreamArn',
    'latestStreamLabel',
    'lastInventoryDate',
    'lastUpdatedDate',
    'attachTime',
    'createTime',
    'createdTimeStamp',
    'createdTimestamp',
    'creationTimeStamp',
    'creationTimestamp',
    'lastAttachTimeStamp',
    'lastAttachTimestamp',
    'lastDetachTimeStamp',
    'lastDetachTimestamp',
    'lastModifiedTimeStamp',
    'lastModifiedTimestamp',
    'lastModifiedAt',
    'instanceCreateTime',
    'snapshotCreateTime',
    'connectivityAt',
    'executionStoppedAt',
    'pullStartedAt',
    'pullStoppedAt',
    'startedAt',
    'stoppedAt',
    'stoppingAt',
    'credentialReportGeneratedTime',
    'launchTime',
    'lastModified',
    'creationDate',
    'deletionDate',
    'lastStatusChange',
    'importedAt',
    'notBefore',
    'notAfter',
    'notAfterTime',
    'notBeforeTime',
    'cacheClusterCreateTime',
    'cacheNodeCreateTime',
    'uploadDate',
    'snapshotCreationTime',
    'lastModifiedTimeUtc',
    'automaticRestartTime',
    'lastStartTime',
    'lastStatusChangeTime',
    'lastStopTime',
    'createdOn',
    'updatedOn',
    'createdDateTime',
    'lastUpdatedDate',
    'lastPasswordChangeDateTime',
    'deletedDate',
    'lastAccessedDate',
    'lastChangedDate',
    'lastRotatedDate',
    'startTime',
    'endTime',
    'trainingStartTime',
    'trainingEndTime',
    'provisionTime',
    'requestTime',
    'deleteTime',
    'created',
    'lastUpdatedAt',
    'submittedTime',
    'completionTime',
    'createdAtUtc',
    'deletionTime',
    'lastCheckTimeStamp',
    'lastCheckTimestamp',
    'lastUpdatedTime',
    'createTimeStamp',
    'createTimestamp',
    'lastUpdateTimeStamp',
    'lastUpdateTimestamp',
    'timeCreated',
    'clusterCreateTime',
    'earliestBacktrackTime',
    'earliestRestorableTime',
    'latestRestorableTime',
    'createdDate',
    'certificateUploadDate',
    'updatedAt',
    'dateCreated',
    'dateUpdated',
    'updateDate',
    'lastLoginDate',
    'attachDate',
    'attachedTime',
    'detachedTime',
    'autoReleaseTime',
    'expiredTime',
    'expireTime',
    'receivedTimeStamp',
    'receivedTimestamp',
    'deleteDate',
    'materialExpireTime',
    'lastRotationDate',
    'automaticRotation',
    'nextRotationDate',
    'updateTime',
    'endTimeStamp',
    'endTimestamp',
    'modificationTime',
    'lastExecutionDate',
    'registrationDate',
    'lastSuccessfulAssociationExecutionDate',
    'lastPingDateTime',
    'validUntil',
    'lastAssociationExecutionDate',
    'lastStartTimestamp',
    'lastStopTimestamp',
    'lastSuspendedTimestamp',
    'lastEnabledTime',
    'deactivateTime',
    'restorePointInTime',
    'bucketCreatedAt',
    'lastUpdated',
    'lastJobRunTime',
    'validateTime',
    'inaccessibleEncryptionDateTime',
    'pointInTimeUTC',
    'deletedDateTime',
    'expirationDateTime',
    'expirationDate',
    'expiration',
    'renewedDateTime',
    'onPremisesLastSyncDateTime',
    'earliestRestorableDateTime',
    'latestRestorableDateTime',
    'certificationExpirationDateTime',
    'lastCertificationDateTime',
    'startDateTime',
    'addedDateTime',
    'timeUpdated',
    'subscribedAt',
    'subscriptionCreationTime',
    'key1CreationTime',
    'key2CreationTime',
    'replicationGroupCreateTime',
    'authTokenLastModifiedDate',
    'lastBackupTime',
    'lastFreshStart',
    'completeTime',
    'deleteLockExpireTime',
    'retainExpireTime',
    'lastAutomatedDiscoveryTime',
    'lastLoginAt',
    'validSince',
    'gmtCreated',
    'gmtModified',
    'lastUpdateTime',
];
