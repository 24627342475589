import React from 'react';
import { Button, Label, Stack, Typography } from '../../../design-system/components-v2';
import { showAssetDisplayNameDrawer, showAssetTypeNameDrawer, showCounterDrawer } from './DrawerExampleInitialize';
import { IAsset } from '../../../assets/common.assets';
import { ExampleStyled } from './DrawerExample.styled';

const getDate = () => {
    return String(new Date());
};

const ButtonsBox: React.FC<{ assetType: IAsset }> = ({ assetType }) => {
    return (
        <Stack direction='column' spacing={2} fullWidth>
            <ExampleStyled.DividerDiv />
            <Button
                color={'brandPrimary'}
                style={{ width: '300px' }}
                onClick={() => showAssetDisplayNameDrawer(assetType)}
                fullWidth
                dataAid='Display Name'
            >
                Display Name
            </Button>
            <Button
                color={'brandPrimary'}
                style={{ width: '200px' }}
                onClick={() => showAssetTypeNameDrawer(assetType)}
                fullWidth
                dataAid='Type Name'
            >
                Type Name
            </Button>
            <Button
                color={'brandPrimary'}
                style={{ width: '100px' }}
                onClick={() => showCounterDrawer(assetType)}
                fullWidth
                dataAid='Counter'
            >
                Counter
            </Button>
        </Stack>
    );
};

export const DrawerExampleTypeViewer: React.FC<{ assetType: IAsset; onDelete: (typeByPlatform: string) => void }> = ({
    assetType,
    onDelete,
}) => {
    return (
        <Stack direction='column' spacing={2} fullWidth>
            <Label text={`(${getDate()})`} />
            <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'blue' }}>{assetType.typeByPlatform}</div>
            <ExampleStyled.ButtonDiv onClick={() => onDelete(assetType.typeByPlatform)}>
                Delete Type
            </ExampleStyled.ButtonDiv>
            <ButtonsBox assetType={assetType} />
        </Stack>
    );
};

export const DrawerLargeExampleTextViewer: React.FC<{
    assetType: IAsset;
    onRestart: (date: Date) => void;
    onReverse: (date: Date) => void;
}> = ({ assetType, onRestart, onReverse }) => {
    return (
        <Stack direction='column' spacing={2} fullWidth>
            <Label text={`(${getDate()})`} />
            <div style={{ fontSize: '24px', fontWeight: 'bold', color: 'purple' }}>{assetType.displayName}</div>
            <ExampleStyled.ButtonDiv onClick={() => onRestart(new Date())}>Restart All Types</ExampleStyled.ButtonDiv>
            <ExampleStyled.ButtonDiv onClick={() => onReverse(new Date())}>Reverse Table Order</ExampleStyled.ButtonDiv>
            <ButtonsBox assetType={assetType} />
        </Stack>
    );
};

export const DrawerExampleCounter: React.FC<{ assetType: IAsset; count: number }> = ({ assetType, count }) => {
    return (
        <Stack direction='column' spacing={2} fullWidth>
            <Label text={`(${getDate()})`} />
            <Typography variant={'h2'}>Count: {count}</Typography>
            <ButtonsBox assetType={assetType} />
        </Stack>
    );
};
