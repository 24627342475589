import { ICustomTreeNode } from '../../custom/CustomTree/CustomTree.interface';

let idCounter = 0;
let nameCounter = 0;
const getId = (reuse?: boolean) => {
    if (!reuse) {
        idCounter++;
    }
    return `id-${idCounter++}`;
};

const getName = (reuse?: boolean) => {
    if (!reuse) {
        nameCounter++;
    }
    return `name-${nameCounter++}`;
};

export const customTreeExampleRoot: ICustomTreeNode = {
    id: getId(),
    data: { id: getName(true), name: getName(true) },
    rendererType: 'fancy',
    children: [
        {
            id: getId(),
            data: { id: getName(true), name: getName(true) },
            rendererType: 'fancy',
            children: [
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'label',
                },
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'fancy',
                },
            ],
        },
        {
            id: getId(),
            data: { id: getName(true), name: getName(true) },
            rendererType: 'label',
            children: [
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'fancy',
                    children: [
                        {
                            id: getId(),
                            data: { id: getName(true), name: getName(true) },
                            rendererType: 'fancy',
                        },
                        {
                            id: getId(),
                            data: { id: getName(true), name: getName(true) },
                            rendererType: 'fancy',
                        },
                    ],
                },
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'label',
                },
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'label',
                    children: [
                        {
                            id: getId(),
                            data: { id: getName(true), name: getName(true) },
                            rendererType: 'fancy',
                        },
                        {
                            id: getId(),
                            data: { id: getName(true), name: getName(true) },
                            rendererType: 'fancy',
                            children: [
                                {
                                    id: getId(),
                                    data: { id: getName(true), name: getName(true) },
                                    rendererType: 'label',
                                },
                                {
                                    id: getId(),
                                    data: { id: getName(true), name: getName(true) },
                                    rendererType: 'fancy',
                                    children: [
                                        {
                                            id: getId(),
                                            data: { id: getName(true), name: getName(true) },
                                            rendererType: 'label',
                                            children: [
                                                {
                                                    id: getId(),
                                                    data: { id: getName(true), name: getName(true) },
                                                    rendererType: 'fancy',
                                                },
                                                {
                                                    id: getId(),
                                                    data: { id: getName(true), name: getName(true) },
                                                    rendererType: 'label',
                                                },
                                            ],
                                        },
                                        {
                                            id: getId(),
                                            data: { id: getName(true), name: getName(true) },
                                            rendererType: 'label',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: getId(),
                            data: { id: getName(true), name: getName(true) },
                            rendererType: 'fancy',
                        },
                    ],
                },
            ],
        },
        {
            id: getId(),
            data: { id: getName(true), name: getName(true) },
            rendererType: 'fancy',
            children: [
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'label',
                },
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'fancy',
                    children: [
                        {
                            id: getId(),
                            data: { id: getName(true), name: getName(true) },
                            rendererType: 'label',
                        },
                        {
                            id: getId(),
                            data: { id: getName(true), name: getName(true) },
                            rendererType: 'label',
                        },
                    ],
                },
                {
                    id: getId(),
                    data: { id: getName(true), name: getName(true) },
                    rendererType: 'fancy',
                },
            ],
        },
    ],
};
