import React, { useCallback, useEffect, useState } from 'react';
import {
    renderAddFilter,
    renderAddItemFilter,
    renderClearAll,
    renderDefaultDateFilter,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRangeFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTagFilter,
    renderTreeFilter,
} from '../FilterPanelDefaultFilters';
import { DEFAULT_RANGES_VALUES } from '../DefaultFilters/DateFilter/DateFilter.consts';
import {
    CpLoadingDots,
    CpTable,
    CpTableCell,
    CpTableHeader,
    CpTableHeaders,
    CpTableRow,
    CpTableRows,
} from '@dome9/components/react/components';

import { getHttpService } from '../../../interface/services';
import { FILTER_PANEL_CONSTS, FILTERS_API_OBJECT_KEYS, FILTERS_KEYS } from '../FilterPanel.consts';
import { initFilterPanel } from '../FilterPanelManager';
import { Aggregations, IFiltersValues } from '../FilterPanel.interface';
import { getIconNameByEnvironmentCode } from '../../../utils/environments';
import { FINDINGS_SEARCH_URL } from '../../../module_interface/events/EventsConsts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from '../../../consts/DataConsts';
import { getSafeFindingSeverityInfo } from '../../../consts/FindingSeverity';

export const FilterPanelExamplePage: React.FC = () => {
    const [data, setData] = useState({} as any);
    const [filterElement, setFilterElement] = useState(<div></div>);
    const [initialData, setInitialData] = useState({ aggregations: {} as any });
    const [isLoading, setIsLoading] = useState(true);
    const initPageMemo = useCallback(initPage, []);
    useEffect(() => {
        initPageMemo();
    }, [initPageMemo]);

    async function initPage() {
        const initialData = await getDataFromServer({}, false, true);
        setInitialData(initialData);
    }

    const renderFilterPanelMemo = useCallback(renderFilterPanel, [initialData?.aggregations]);

    useEffect(() => {
        if (initialData.aggregations && Object.keys(initialData.aggregations).length) {
            renderFilterPanelMemo();
        }
    }, [initialData, renderFilterPanelMemo]);

    const getDataFromServer = async (filters: any, showLoader = true, init = false) => {
        showLoader && setIsLoading(true);
        const requestObject = {
            filter: filters,
            pageSize: init ? 0 : 500,
            sorting: { direction: -1, fieldName: 'createdTime' },
        };
        const currData = await getHttpService().request<any>(FINDINGS_SEARCH_URL, {
            data: requestObject,
            method: 'POST',
        });
        showLoader && setIsLoading(false);
        setData(currData);
        return currData;
    };

    function mapSeverityColor(aggregationItems: any) {
        if (aggregationItems) {
            return aggregationItems.reduce((prev: any, aggregationItem: any) => {
                prev[aggregationItem.value] = {
                    displayText: aggregationItem.value,
                    color: getSafeFindingSeverityInfo(aggregationItem.value).color,
                };
                return prev;
            }, {});
        } else {
            return null;
        }
    }

    function mapEnvironmentItem(aggregationItems: any) {
        if (aggregationItems) {
            return aggregationItems.reduce((prev: any, aggregationItem: any) => {
                if (aggregationItem?.value?.includes('|')) {
                    const splitDataArray = aggregationItem?.value.split('|');
                    const environmentTypeCode = splitDataArray[0];
                    const environmentId = splitDataArray[1];
                    const iconName = getIconNameByEnvironmentCode(environmentTypeCode);
                    prev[aggregationItem.value] = { displayText: environmentId, icon: iconName };
                }
                return prev;
            }, {});
        } else {
            return null;
        }
    }

    async function renderFilterPanel() {
        const getAggregations = async (filtersValues: IFiltersValues): Promise<Aggregations> => {
            return (await getDataFromServer(filtersValues, true)).aggregations;
        };

        const filterPanel = initFilterPanel({
            filterPanelElementsList: [
                {
                    filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                    renderFunction: renderAddFilter,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.DATE_PICKER,
                        defaultValue: DEFAULT_RANGES_VALUES.ONE_DAY,
                        options: [
                            DEFAULT_RANGES_VALUES.ONE_DAY,
                            DEFAULT_RANGES_VALUES.WEEK,
                            DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                            DEFAULT_RANGES_VALUES.ALL,
                        ],
                        title: 'Date',
                        limitations: { maxDaysSelected: 5, maxDaysBack: 30 },
                    },
                    keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.DATE_PICKER,
                    renderFunction: renderDefaultDateFilter,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.FREE_TEXT,
                        title: 'Free Text',
                    },
                    keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
                    renderFunction: renderDefaultFreeTextFilter,
                },
                {
                    filterProps: {
                        savedFilters: await getHttpService().request<any>(
                            'customization?component=alerts.postureFindings',
                            { method: 'GET' },
                        ),
                        savedFiltersComponentName: 'alerts.postureFindings',
                        selectedFilterID: '',
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                },
                {
                    filterProps: {
                        initialData: initialData?.aggregations[FILTER_PANEL_CONSTS.ADD_FILTER_TAG],
                        key: 'tag',
                        title: 'Tag',
                        isMultiTags: true,
                    },
                    renderFunction: renderTagFilter,
                },
                {
                    filterProps: {
                        initialData: oudata,
                        key: 'organizationalUnitId',
                        title: 'Organizational Units',
                        defaultValue: DEFAULT_ORGANIZATIONAL_UNIT_ID,
                    },
                    renderFunction: renderTreeFilter,
                },
                {
                    filterProps: {
                        initialData: initialData?.aggregations['severity'],
                        // Customization Example: defaultValue: ['High'],
                        key: 'severity',
                        title: 'Severity',
                        displayMapping: mapSeverityColor(initialData?.aggregations['severity']),
                    },
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        initialData: initialData?.aggregations['acknowledged'],
                        // Customization Example: defaultValue: ['false'],
                        key: 'acknowledged',
                        title: 'Acknowledged',
                    },
                    // Customization Example: displayTypes: [],
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        initialData: initialData?.aggregations['cloudAccountId_calc'],
                        key: 'cloudAccountId_calc',
                        title: 'Environment',
                        displayMapping: mapEnvironmentItem(initialData?.aggregations['cloudAccountId_calc']),
                    },
                    // Customization Example:hiddenList: [FILTERS_KEYS.ADD_FILTER],
                    renderFunction: renderMultiSelectFilter,
                },
                {
                    filterProps: {
                        min: 0,
                        max: 36,
                        key: 'range',
                        title: 'Range Filter',
                    },
                    renderFunction: renderRangeFilter,
                },
                {
                    filterProps: {
                        key: 'addItem',
                        title: 'Add Item Filter',
                    },
                    renderFunction: renderAddItemFilter,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: await getHttpService().request<any>(
                            'customization?component=findingsRecentlyUsedFilters',
                            { method: 'GET' },
                        ),
                        maxLength: 4,
                        componentName: 'findingsRecentlyUsedFilters',
                        title: 'Recently Used Filters',
                    },
                    renderFunction: renderRecentlyUsedFilters,
                },
                {
                    filterProps: {
                        key: FILTERS_KEYS.CLEAR_BUTTON,
                    },
                    renderFunction: renderClearAll,
                },
            ],
            getAggregations: getAggregations,
            shouldBuildObjectForAPI: true,
            filterId: 'filter-panel-example-page',
        });

        setFilterElement(filterPanel);
    }

    return (
        <div className='flex flex-col flex-1 overflow-hidden p-9'>
            {filterElement}
            {isLoading ? (
                <CpLoadingDots />
            ) : (
                <div className='flex-1 overflow-auto'>
                    <div>{`Showing ${data.totalFindingsCount} results`}</div>
                    <CpTable className='w-full' data-aid='example-page-table'>
                        <CpTableHeaders>
                            <CpTableHeader className='table-header' style={{ width: '200px' }}>
                                <span>Severity</span>
                            </CpTableHeader>
                            <CpTableHeader className='table-header' style={{ width: '200px' }}>
                                <span>Creation Time</span>
                            </CpTableHeader>
                            <CpTableHeader className='table-header' style={{ width: '200px' }}>
                                <span>Source</span>
                            </CpTableHeader>
                            <CpTableHeader className='table-header' style={{ width: '200px' }}>
                                <span>Entity</span>
                            </CpTableHeader>
                            <CpTableHeader className='table-header' style={{ width: '200px' }}>
                                <span>Entity Type</span>
                            </CpTableHeader>
                        </CpTableHeaders>
                        <CpTableRows>
                            {data.findings &&
                                data.findings.map(
                                    (item: {
                                        id: any;
                                        severity: any;
                                        createdTime: any;
                                        origin: any;
                                        entityName: any;
                                        entityType: any;
                                    }) => {
                                        return (
                                            <CpTableRow key={item.id}>
                                                <CpTableCell style={{ width: '200px' }}>{item.severity}</CpTableCell>
                                                <CpTableCell style={{ width: '200px' }}>{item.createdTime}</CpTableCell>
                                                <CpTableCell style={{ width: '200px' }}>{item.origin}</CpTableCell>
                                                <CpTableCell style={{ width: '200px' }}>{item.entityName}</CpTableCell>
                                                <CpTableCell style={{ width: '200px' }}>{item.entityType}</CpTableCell>
                                            </CpTableRow>
                                        );
                                    },
                                )}
                        </CpTableRows>
                    </CpTable>
                </div>
            )}
        </div>
    );
};
export default FilterPanelExamplePage;

const oudata = {
    id: DEFAULT_ORGANIZATIONAL_UNIT_ID,
    name: 'Dome9',
    path: 'Dome9',
    children: [
        {
            id: '38f735fc-db4f-4361-93a5-badc4aa0742b',
            name: 'Miri',
            path: 'Dome9/Miri',
            children: [],
        },
        {
            id: '0d23f3e7-30fe-4d39-ad36-d544b0a9ee0e',
            name: 'Daniel Master',
            path: 'Dome9/Daniel Master',
            children: [
                {
                    id: 'a06f1f40-3ac3-452b-90dd-fdf503767748',
                    name: 'Daniel Child',
                    path: 'Dome9/Daniel Master/Daniel Child',
                    children: [],
                },
            ],
        },
        {
            id: '3f446bde-e4d5-4011-bdc9-f4183ab5e7a8',
            name: 'Azure',
            path: 'Dome9/Azure',
            children: [],
        },
        {
            id: '25ae5ac5-7686-4250-9086-102227f5c3a0',
            name: 'K8s',
            path: 'Dome9/K8s',
            children: [],
        },
        {
            id: '768dec8a-3402-464c-9b11-4affacce9c71',
            name: 'RanTest',
            path: 'Dome9/RanTest',
            children: [
                {
                    id: 'e0aee52a-88e2-438a-aefe-678ac0c9a9ab',
                    name: 'TanRest',
                    path: 'Dome9/RanTest/TanRest',
                    children: [],
                },
            ],
        },
        {
            id: '68d49473-a69c-4097-a66a-da3162fd6d7a',
            name: 'Leonid',
            path: 'Dome9/Leonid',
            children: [
                {
                    id: '54ad3cda-eb08-45df-975b-72b687ece5b0',
                    name: 'Leonid-Limited',
                    path: 'Dome9/Leonid/Leonid-Limited',
                    children: [],
                },
                {
                    id: '544b4ec2-3ad1-42c2-ac88-c9ed10a075b9',
                    name: 'YoavOU1',
                    path: 'Dome9/Leonid/YoavOU1',
                    children: [],
                },
                {
                    id: 'b3ca7645-023d-4b56-a0cb-4d31f7b995f9',
                    name: 'Leonid-AWS',
                    path: 'Dome9/Leonid/Leonid-AWS',
                    children: [
                        {
                            id: 'd53e23423-3840-4a80-95b4-520e5d8cc237',
                            name: 'GCP2_1_6',
                            path: 'Dome9/Leonid1/Leonid-AWS/GCP2_1_!',
                            children: [
                                {
                                    id: '54adg54a-eb08-45df-975b-72b687ece5b0',
                                    name: 'Leonid-Limited5',
                                    path: 'Dome9/Leonid/Leonid-Limited',
                                    children: [],
                                },
                                {
                                    id: '544b4ec2-3ad1-42c2-ac88-c9ed1f3f75b9',
                                    name: 'YoavOU12',
                                    path: 'Dome9/Leonid/YoavOU1',
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: '15676d65-8ecb-42df-b7f5-90c4890a0eb1',
            name: 'AWS',
            path: 'Dome9/AWS',
            children: [],
        },
        {
            id: '635adc58-f536-467d-a9d5-11f3c6a2704c',
            name: 'Dana OU',
            path: 'Dome9/Dana OU',
            children: [],
        },
        {
            id: '3f9bd40e-9bd0-4236-8e27-bff0b7f07169',
            name: 'GCP',
            path: 'Dome9/GCP',
            children: [
                {
                    id: '1110c012-3956-4e9a-ba77-81e142e7edc7',
                    name: 'GCP2',
                    path: 'Dome9/GCP/GCP2',
                    children: [],
                },
            ],
        },
    ],
};
