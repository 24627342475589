import { isLocalhost } from '../../../utils/RuntimeEnvironment';
import { getProdEnvironmentBaseUrl } from '../../../utils/http';
import { CommonRegExp } from '../../utils/ermComponents.consts';

export const getVendorIconsUrlSource = (): string => {
    const baseUrl = isLocalhost ? getProdEnvironmentBaseUrl() : window.location.origin + '/v2/';
    return baseUrl + 'app-assets/vendor-icons/';
}

export const sanitizeElement = (elementString: string): string => {
    const regexToReplace = [CommonRegExp.HTML_EVENT, CommonRegExp.HTML_SCRIPT, CommonRegExp.HTML_IFRAME];

    return regexToReplace.reduce(
        (sanitized, regex) => sanitized.replace(regex, ''),
        elementString
    );
};
