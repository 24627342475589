import IconButton from 'common/design-system/components-v2/IconButton';
import Stack from 'common/design-system/components-v2/Stack';
import styled, { css } from 'styled-components';

interface WrapperProps {
    isLoading?: boolean;
    maxHeight?: number;
}
const Wrapper = styled(Stack)<WrapperProps>`
    position: relative;
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    background: ${({ theme }) => theme.palette.default.backgrounds.strength.weaker};
    padding: ${({ theme }) =>
        `${theme.spacing(4)}${theme.units} ${theme.spacing(8)}${theme.units} ${theme.spacing(4)}${theme.units} ${theme.spacing(4)}${theme.units}`};
    white-space: pre-line;
    justify-content: ${({ isLoading }) => isLoading && 'center'};
    word-break: break-all;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    ${({ maxHeight }) =>
        maxHeight &&
        css`
            max-height: ${maxHeight}px;
            overflow: auto;
        `}
`;

const CopyBtn = styled(IconButton)`
    position: absolute;
    top: 4px;
    right: 4px;
`;

const CommandStyles = {
    Wrapper,
    CopyBtn,
};

export default CommandStyles;
