import { Stack } from '../../../design-system/components-v2';
import styled from 'styled-components';

export const SvgIconExampleStyled = styled(Stack)`
    .red-halo svg {
        filter: drop-shadow(0 0 5px red);
    }

    .green-halo svg {
        filter: drop-shadow(0 0 5px green);
    }

    .dark-path path {
        fill: darkred;
    }
`;
