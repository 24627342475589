import React from 'react';
import { Spacing, AlignItems } from '../Stack/Stack.types';
import { IconProps } from '../Icon/Icon.types';

export interface IAlertProps {
    type?: 'error' | 'warning' | 'success' | 'info';
    customIcon?: IconProps;
    alignIcon?: AlignItems;
    children: React.ReactNode;
    className?: string;
    margin?: Spacing[];
    withCloseButton?: boolean;
    hideStatusIcon?: boolean;
    onClose?: () => void;
    iconSize?: number;
    fullWidth?: boolean;
}

export enum AlertTypeToContext {
    info = 'info',
    success = 'lowRisk',
    warning = 'low',
    error = 'high',
}

export type TAlertType = 'error' | 'warning' | 'success' | 'info';
