import { DataGrid } from '@dome9/berries/react-components';
import { GridApi, ICellRendererParams } from 'ag-grid-enterprise';
import { GridReadyEvent } from 'ag-grid-community';
import IconCellRenderer from 'common/components/Widgets/List/CellRenderers/IconCellRenderer';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { formatNumbersForDisplay } from '../helpers';
import { Tooltip } from 'common/design-system/components-v2';
import { useProfiler } from '@sentry/react';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';

/**
 * The list widget is actually ag-grid table with some small teaks
 * You can just use the ag-grid as you normally do.
 */

const clickableRenderer: React.FC<ICellRendererParams> = (params) => {
    const { data } = params;
    const isClickable = data.onclick;
    return (
        <Tooltip content={data.key}>
            <div
                className={`${isClickable ? 'hover: cursor-pointer' : ''} text-ellipsis`}
                onClick={() => data.onclick && data.onclick()}
            >
                {data.key}
            </div>
        </Tooltip>
    );
};

const ValueCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const value = params.data.value;
    const { data } = params;
    const isClickable = data.onclick;

    return (
        <Tooltip content={formatNumbersForDisplay(Number(value))}>
            <div className={`text-right text-ellipsis ${isClickable ? 'hover: cursor-pointer' : ''}`}>
                {formatNumbersForDisplay(Number(value))}
            </div>
        </Tooltip>
    );
};

const WidgetList: React.FC<IGenericWidgetDataProps> = (props) => {
    useProfiler(`WidgetList|${props.header}`);
    const items = props.data?.items;
    const showIconColumn = items?.some((item) => item.icon);
    const columnDefs: ColDef[] = [{ field: 'key', flex: 4, cellRenderer: clickableRenderer }];
    if (showIconColumn) {
        columnDefs.unshift({
            field: 'icon',
            cellRenderer: IconCellRenderer,
            cellRendererParams: { className: 'absolute inset-0 justify-center' },
            maxWidth: 16,
            minWidth: 16,
            flex: 0,
        });
    }
    if (!props.data?.options?.hideValueColumn) {
        columnDefs.push({
            field: 'value',
            maxWidth: 150,
            minWidth: 60,
            cellRenderer: ValueCellRenderer,
            flex: 1,
        });
    }
    const [gridApi, setGridApi] = useState<GridApi>();

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (!gridApi) {
            return;
        }
        if (items?.length === 0 || !items) {
            gridApi.showNoRowsOverlay();
        }
    }, [items, gridApi]);

    return (
        <DataGrid
            hideHeader={true}
            columnDefs={columnDefs}
            rowData={items}
            rowSelection='single'
            onSelectionChanged={(event) => {
                const selectedRows = event.api.getSelectedRows();
                if (selectedRows.length > 0 && selectedRows[0].onclick) {
                    selectedRows[0].onclick();
                }
            }}
            suppressContextMenu
            onGridReady={onGridReady}
            className='list-widget scrollbar--inner'
            rowModelType='clientSide'
            {...props.data}
        />
    );
};

export default WidgetList;
