import React from 'react';
import { DrawerBus } from './DrawerBus';

type IUseCloseDrawerHandlerProps = (
    closeEventHandlerId: string,
    handleCloseEvent: (closeAll?: boolean) => void,
) => void;
export const useCloseDrawerHandler: IUseCloseDrawerHandlerProps = (closeEventHandlerId, handleCloseEvent) => {
    React.useEffect(() => {
        DrawerBus.registerCloseEventHandler(closeEventHandlerId, handleCloseEvent);
        return () => {
            DrawerBus.unregisterCloseEventHandler(closeEventHandlerId);
        };
    }, [closeEventHandlerId, handleCloseEvent]);
};
