import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import {
    ColDef,
    Column,
    ColumnApi,
    ColumnRowGroupChangedEvent,
    GridApi,
    GridOptions,
    ICellRendererParams,
    IColumnToolPanel,
    ProcessCellForExportParams,
} from 'ag-grid-community';
import { Datasource } from './datasource';
import { getProtectedAssetsService, IProtectedAssetActionKey } from 'common/module_interface/assets/ProtectedAssets';
import AssetsExporter from './assetsExporter';
import { Trans, useTranslation } from 'react-i18next';
import { getNotificationsService } from 'common/interface/services';
import { profileComponent } from '../profileComponent';
import { IColumnUsageDef } from '../../interface/general';
import { Table, Typography } from 'common/design-system/components-v2';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import InsertEmailModal from '../Modals/InsertEmailModal/InsertEmailModal';
import { ensureInvisibleColumns, loadColumnsState, saveColumnsState } from '../../utils/tableUtils';
import { RowClassParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { IFiltersValues } from '../FilterPanel/FilterPanel.interface';

export enum CounterStatus {
    Pending = -1,
    Error = -2,
}

export type CountWithStatus = number | CounterStatus;

export const symbolClipboardValue = Symbol('clipboardValue');

export function CellToClipboardHandler(params: ICellRendererParams, value: string) {
    if (!params.data?.[symbolClipboardValue]) {
        params.data[symbolClipboardValue] = {};
    }
    params.data[symbolClipboardValue][params.column!.getColId()] = value;
}

type ComponentProps = {
    columns: IColumnUsageDef[];
    isLoading: boolean;
    datasource: Datasource;
    pageSize: number;
    totalCount: CountWithStatus;
    currentCount: CountWithStatus;
    tableId: string;
    actions?: IProtectedAssetActionKey[];
    hideExport?: boolean;
    isRowSelectable?: (node: any) => boolean; // Callback to be used to determine which rows are selectable
    onColumnRowGroupChanged?: (event: ColumnRowGroupChangedEvent) => void;
    autoGroupColumnDef?: ColDef;
    getRowClass?: (params: RowClassParams) => string | string[] | undefined;
    filterValues?: IFiltersValues;
    deselectAllOnFilterChange?: boolean;
};

function handleOverlay(
    apiRef: React.MutableRefObject<GridApi | undefined>,
    isLoading: boolean,
    datasource: Datasource,
) {
    apiRef.current?.hideOverlay();
    if (isLoading) {
        apiRef.current?.showLoadingOverlay();
    }
    if (datasource.totalCount === 0) {
        setTimeout(() => {
            //This is a temp solution, a ticket was opened on ag-grid. This timeout should not be needed here.
            apiRef.current?.showNoRowsOverlay();
        }, 1);
    }
}

const ProtectedAssetsTable: React.FC<ComponentProps> = ({
    columns,
    isLoading,
    datasource,
    pageSize,
    totalCount,
    currentCount,
    tableId,
    actions = [],
    hideExport,
    isRowSelectable,
    onColumnRowGroupChanged,
    autoGroupColumnDef,
    getRowClass,
    filterValues = {},
    deselectAllOnFilterChange = false,
}) => {
    const LOCAL_STORAGE_COLUMNS_SAVED_STATE = `${tableId}__COLUMNS_STATE`;
    const MAX_NUMBER_OF_GROUPS_ALLOWED = 5;
    const MAX_NUMBER_OF_ASSETS_ALLOW_TO_EXPORT = 10000;
    const [isExportEnabled, setIsExportEnabled] = useState(true);
    const [exportEmailCsvModal, setExportEmailCsvModal] = useState({ isOpen: false, withCurrFilter: false });

    const processCellForClipboard = (params: ProcessCellForExportParams<any>) => {
        if (params.node?.data[symbolClipboardValue][params.column.getColId()]) {
            return params.node?.data[symbolClipboardValue][params.column.getColId()];
        } else {
            return params.value;
        }
    };

    const closeExportToCSVEmailModal = () => {
        setExportEmailCsvModal({ isOpen: false, withCurrFilter: false });
    };

    const { t } = useTranslation();
    const apiRef = React.useRef<GridApi>();
    const columnApiRef = React.useRef<ColumnApi>();

    useEffect(() => {
        if (deselectAllOnFilterChange) {
            apiRef.current?.deselectAll();
        }
    }, [deselectAllOnFilterChange, filterValues]);

    useEffect(() => {
        handleOverlay(apiRef, isLoading, datasource);
    }, [isLoading, datasource]);

    const columnDefs = useMemo(() => {
        return getProtectedAssetsService().getColumnDefs(columns);
    }, [columns]);

    const actionsDefs = useMemo(() => {
        return getProtectedAssetsService().getActionsDefs(actions || []);
    }, [actions]);

    const onColumnStateChanged = useCallback(() => {
        if (!columnApiRef.current) {
            return;
        }
        ensureInvisibleColumns(columnApiRef.current);
        saveColumnsState(columnApiRef.current, LOCAL_STORAGE_COLUMNS_SAVED_STATE);
    }, [LOCAL_STORAGE_COLUMNS_SAVED_STATE]);

    const preventAddingGroupsIfNeeded = (params: ColumnRowGroupChangedEvent) => {
        const currentSelectedGroups = params.columns;
        const shouldPreventAddingGroups =
            currentSelectedGroups && currentSelectedGroups.length >= MAX_NUMBER_OF_GROUPS_ALLOWED;

        const _setEnableRowGroup = (column: Column, isRowGroupEnabled: boolean) => {
            column && column.setColDef({ ...column.getColDef(), enableRowGroup: isRowGroupEnabled }, null);
        };

        if (shouldPreventAddingGroups) {
            getNotificationsService().info(i18n.t('COMMON.PROTECTED_ASSETS_TABLE.MAXIMUM_NUMBER_GROUPS_REACHED'), '');
            const allColumns = params.columnApi.getColumns();
            allColumns &&
                allColumns.forEach((col: Column) => {
                    _setEnableRowGroup(col, false);
                });
        } else {
            const columnDefs = gridOptions.columnDefs;
            columnDefs &&
                columnDefs.forEach((colDef: ColDef) => {
                    const selectedColumn = params.columnApi.getColumn(colDef.colId);
                    selectedColumn && _setEnableRowGroup(selectedColumn, Boolean(colDef.enableRowGroup));
                });
        }
    };

    const groupingChangedCallback = (params: ColumnRowGroupChangedEvent) => {
        preventAddingGroupsIfNeeded(params);
        if (columnApiRef.current) {
            ensureInvisibleColumns(columnApiRef.current);
            saveColumnsState(columnApiRef.current, LOCAL_STORAGE_COLUMNS_SAVED_STATE);
        }
        handleOverlay(apiRef, isLoading, datasource);
        params.api.deselectAll();
    };

    const onGridReady = useCallback(
        (params: any) => {
            params.api.closeToolPanel();
            apiRef.current = params.api;
            columnApiRef.current = params.columnApi;
            const columnToolPanel = params.api.getToolPanelInstance('columns') as IColumnToolPanel | undefined;
            const columns: ColDef[] = [...columnDefs];
            columns.sort((firstColumn, secondColumn) => {
                return firstColumn.headerName!.localeCompare(secondColumn.headerName!);
            });
            columnToolPanel?.setColumnLayout(columns);
            loadColumnsState(params.columnApi, LOCAL_STORAGE_COLUMNS_SAVED_STATE);
        },
        [columnDefs, LOCAL_STORAGE_COLUMNS_SAVED_STATE],
    );

    useEffect(() => {
        if (apiRef && apiRef.current) {
            apiRef.current.setServerSideDatasource(datasource);
        }
    }, [datasource]);

    const getChildCount = useCallback((data: any) => {
        return data ? data.childCount : undefined;
    }, []);

    const exportToCsv = useCallback(
        async ({ isEmail = false, withCurrentFilters = false, recipients = [''] }) => {
            !isEmail && setIsExportEnabled(false);
            try {
                const assetExporter = new AssetsExporter(datasource);
                isEmail
                    ? await assetExporter.exportToCsvEmail(withCurrentFilters, recipients)
                    : await assetExporter.exportToCsv(withCurrentFilters);
            } finally {
                setIsExportEnabled(true);
            }
        },
        [datasource],
    );

    const footerText = React.useMemo(() => {
        if (currentCount === CounterStatus.Error || totalCount === CounterStatus.Error) {
            return t('COMMON.PROTECTED_ASSETS_TABLE.COUNTER_ERROR');
        }
        if (currentCount === CounterStatus.Pending || totalCount === CounterStatus.Pending) {
            return t('COMMON.PROTECTED_ASSETS_TABLE.LOADING');
        }
        return (
            <Typography>
                <Trans
                    components={{
                        boldText: (
                            <Typography elementType='span' style={{ fontWeight: 500 }}>
                                .
                            </Typography>
                        ),
                    }}
                    i18nKey='COMMON.PROTECTED_ASSETS_TABLE.RESULTS'
                    values={{ count: currentCount, totalCount }}
                />
            </Typography>
        );
    }, [currentCount, totalCount, t]);

    const gridOptions: GridOptions = {
        columnDefs,
        processCellForClipboard,
        rowBuffer: 0,
        maxConcurrentDatasourceRequests: 1,
        rowSelection: 'multiple',
        rowModelType: 'serverSide',
        onSortChanged: onColumnStateChanged,
        onColumnResized: onColumnStateChanged,
        onColumnMoved: onColumnStateChanged,
        onColumnVisible: onColumnStateChanged,
        onColumnRowGroupChanged,
        autoGroupColumnDef,
        isRowSelectable: (node) => (isRowSelectable ? isRowSelectable(node) : true),
        rowHeight: 48,
        onGridReady,
        loadingCellRendererParams: { currentCount },
        getChildCount,
        //@ts-ignore // need to check if this is still needed
        groupingChangedCallback,
        getRowClass,
    };

    const exportButtons = React.useMemo<ITableExportButton[]>(() => {
        if (hideExport) return [];
        return [
            {
                label: t('COMMON.PROTECTED_ASSETS_TABLE.TOOLBAR.EXPORT_FILTERED_ASSETS'),
                disabled: currentCount < 0 || currentCount > MAX_NUMBER_OF_ASSETS_ALLOW_TO_EXPORT || !isExportEnabled,
                tooltip:
                    currentCount > MAX_NUMBER_OF_ASSETS_ALLOW_TO_EXPORT
                        ? t('COMMON.PROTECTED_ASSETS_TABLE.DISABLED_FOR_MORE_THAN', {
                              limit: MAX_NUMBER_OF_ASSETS_ALLOW_TO_EXPORT,
                          })
                        : '',
                onClick: () => exportToCsv({ withCurrentFilters: true }),
            },
            {
                label: t('COMMON.PROTECTED_ASSETS_TABLE.TOOLBAR.EXPORT_FILTERED_ASSETS_EMAIL'),
                disabled: !isExportEnabled,
                onClick: () => setExportEmailCsvModal({ isOpen: true, withCurrFilter: true }),
            },
            {
                label: t('COMMON.PROTECTED_ASSETS_TABLE.TOOLBAR.EXPORT_ALL_ASSETS'),
                disabled: totalCount > MAX_NUMBER_OF_ASSETS_ALLOW_TO_EXPORT || !isExportEnabled,
                tooltip:
                    totalCount > MAX_NUMBER_OF_ASSETS_ALLOW_TO_EXPORT
                        ? t('COMMON.PROTECTED_ASSETS_TABLE.DISABLED_FOR_MORE_THAN', {
                              limit: MAX_NUMBER_OF_ASSETS_ALLOW_TO_EXPORT,
                          })
                        : '',
                onClick: () => exportToCsv({ withCurrentFilters: false }),
            },
            {
                label: t('COMMON.PROTECTED_ASSETS_TABLE.TOOLBAR.EXPORT_ALL_ASSETS_EMAIL'),
                disabled: !isExportEnabled,
                onClick: () => setExportEmailCsvModal({ isOpen: true, withCurrFilter: false }),
            },
        ];
    }, [hideExport, currentCount, totalCount, isExportEnabled, exportToCsv, t]);

    return (
        <>
            <Table
                pageSize={pageSize}
                gridOptions={gridOptions}
                exportButtons={exportButtons}
                actions={actionsDefs}
                footer={footerText}
            />
            {exportEmailCsvModal.isOpen && (
                <InsertEmailModal
                    isOpen={exportEmailCsvModal.isOpen}
                    onClose={() => closeExportToCSVEmailModal()}
                    title={t('COMMON.PROTECTED_ASSETS_TABLE.TOOLBAR.EXPORT_CSV_EMAIL_MODAL_TITLE')}
                    text={t('COMMON.PROTECTED_ASSETS_TABLE.TOOLBAR.EXPORT_CSV_EMAIL_MODAL_CONTENT')}
                    onConfirm={(recipients: string) => {
                        exportToCsv({
                            isEmail: true,
                            withCurrentFilters: exportEmailCsvModal.withCurrFilter,
                            recipients: [recipients],
                        });
                        closeExportToCSVEmailModal();
                    }}
                    data-aid='protected-assets-email-modal'
                />
            )}
        </>
    );
};

export default profileComponent(ProtectedAssetsTable) as React.FC<ComponentProps>;
