import React from 'react';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
import { formatNumbersForDisplay } from '../helpers';
import { Icon } from 'common/design-system/components-v2';
import { AllIconsName, IconProps, allIcons } from 'common/design-system/components-v2/Icon/Icon.types';

export const DataGridWidget: React.FC<IGenericWidgetDataProps> = (props) => {
    const items = props.data?.items;

    const getIconProps = (icon: AllIconsName | string): IconProps => {
        if (icon in allIcons) {
            return { name: icon as AllIconsName };
        }
        return { vendorNameOrPath: icon };
    };

    return (
        <div className='@container flex-1 py-4 px-6 overflow-auto scrollbar--inner'>
            <div className='flex-1 gap-x-6 grid grid-cols-1 @[150px]:grid-cols-2 @[750px]:grid-cols-4 @[900px]:grid-cols-7 @[300px]:gap-x-8 @[750px]:gap-x-9'>
                {items?.map((item) => {
                    return (
                        <div
                            className='tile flex flex-col gap-4 bg-content monochromatic-svg-group border-b'
                            key={String(item.key)}
                        >
                            <div className='hidden @[750px]:block '>
                                {' '}
                                {/* Show this div only for big size widgets */}
                                <div className='p-7'>
                                    <div className='flex gap-y-6 gap-x-4 items-center'>
                                        {item.icon && <Icon size={24} {...getIconProps(item.icon)} />}
                                        <div
                                            className='text-2xl @[900px]:text-3xl hover:underline cursor-pointer text-block ml-5'
                                            onClick={(event) => item.onclick && item.onclick(event)}
                                        >
                                            {formatNumbersForDisplay(Number(item.value))}
                                        </div>
                                    </div>
                                    <div className='flex flex-col flex-1 mt-5'>{String(item.key)}</div>
                                </div>
                            </div>

                            <div className='@[750px]:hidden'>
                                {' '}
                                {/* Show this div only for small size widgets */}
                                <div className='flex gap-7 items-center p-4'>
                                    {item.icon && <Icon size={16} {...getIconProps(item.icon)} />}
                                    <div className='flex-col flex-1 hidden @[300px]:flex'>{String(item.key)}</div>
                                    <div
                                        className='hover:underline cursor-pointer text-block ml-auto'
                                        onClick={(event) => item.onclick && item.onclick(event)}
                                    >
                                        {formatNumbersForDisplay(Number(item.value))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
