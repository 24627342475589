import history, { URL_PREFIX } from './history';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from '../interface/IFrame.message.model';
import { emitMessageToCloudInfra } from './iframe.communication';
import { GenericObject, IFieldInfo } from '../interface/general';
import { getIsCloudInfra } from './RuntimeEnvironment';
import { Cookies } from 'react-cookie';
import { SortingModel } from '../components/Findings/Findings.interface';
import { TimePresets } from '../components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { encodeUrlObject } from '../components/FilterPanel/filterUtils';
import { getWebAppIframeService } from 'common/interface/services';
import { removeUndefinedFields } from './helpFunctions';
import { AxiosError } from 'axios/index';
import i18n from '../services/translations/translations';

const cookies = new Cookies();
const CLOUD_INFRA_BASE_URL = 'dashboard/cloudguard#';
export const CSRF = 'csrf';
export const HTTP_BASE = 'http://';
export const HTTPS_BASE = 'https://';

export const getDomain = () => {
    const domainArr = window.location.hostname.split('.');
    domainArr.shift();
    return domainArr.join('.');
};

export const getUrlWithoutPrefix = () => {
    return document.location.pathname.replace(URL_PREFIX, '');
};

export const isUrlStartsWith = (path: string) => {
    return getUrlWithoutPrefix().startsWith(path);
};

export const setRefreshPathnameForAngular = () => {
    const angularHostName = getAngularHostName(document.location.hostname, document.location.port);
    if (!getIsCloudInfra()) {
        const pathWithoutV2 = getUrlWithoutPrefix();
        return `${document.location.protocol}//${angularHostName}${pathWithoutV2}${document.location.search}`;
    }
    return null;
};

export interface SetCookieOptions {
    path?: string;
    httpOnly?: boolean;
    domain?: string;
    sameSite?: boolean | 'none' | 'lax' | 'strict' | undefined;
    secure?: boolean;
}

export const CookieOptions: SetCookieOptions = {
    path: '/',
    httpOnly: false,
    domain: getDomain(),
    sameSite: 'none',
    secure: true,
};
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getAngularHostName = (localhostName: string, port: string) => {
    if (localhostName === 'localhost') {
        if (port === '') {
            return 'localhost:5000';
        } else {
            return 'localhost:3000';
        }
    } else if (localhostName.startsWith('local.')) {
        return localhostName + ':3000';
    } else {
        return 'central.' + localhostName.split('.').slice(1).join('.');
    }
};

export const getUrl = (UrlList: string[]): string => {
    if (UrlList.length === 1) {
        return UrlList[0];
    }
    const urlD9 = UrlList.find((e) => e.includes('.dome9.com') || e.includes('.falconetix.com'));
    if (
        urlD9 !== undefined &&
        (window.location.host.includes('.dome9.com') || window.location.host.includes('.falconetix.com'))
    ) {
        return urlD9;
    }
    const urlCheckpoint = UrlList.find((e) => e.includes('.checkpoint.com'));
    if (urlCheckpoint !== undefined) {
        return urlCheckpoint;
    }
    return UrlList[0];
};

export const isEmailValid = (email: string) => {
    try {
        return EMAIL_REGEX.test(email);
    } catch (err) {
        return false;
    }
};

export const isUrlValid = (url: string) => {
    const URL_REGEX =
        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    try {
        return URL_REGEX.test(url);
    } catch (err) {
        return false;
    }
};

export const isRequired = (field: string | undefined) => {
    if (!field || field === '') {
        return false;
    }
    return true;
};

const getCloudInfraPrefix = () => {
    return `${document.referrer}${CLOUD_INFRA_BASE_URL}`;
};

export const generateHref = (initialUrl: string) => {
    const isCloudInfra = getIsCloudInfra();
    if (!initialUrl || !initialUrl.trim()) {
        return isCloudInfra ? getCloudInfraPrefix() : '';
    }

    if (initialUrl.startsWith(HTTP_BASE) || initialUrl.startsWith(HTTPS_BASE)) {
        return initialUrl;
    }

    let url = initialUrl.trim();
    if (!url.startsWith('/')) {
        url = `/${url}`;
    }

    if (isCloudInfra) {
        const v2Regexp = new RegExp(`^${URL_PREFIX}/`);
        url = `${getCloudInfraPrefix()}${url.replace(v2Regexp, '/')}`;
    } else if (!url.startsWith(`${URL_PREFIX}/`)) {
        url = `${URL_PREFIX}${url}`;
    }
    return url.replace(/([^:]\/)\/+/g, '$1');
};

export const tryExtractResponseText = (response: any) => {
    if (response?.message) {
        return response.message;
    }
    try {
        return response.statusText || typeof response.responseText === 'string'
            ? response.responseText
            : JSON.parse(response.responseText).message;
    } catch {
        return null;
    }
};

export const getReturnUrl = () => {
    let url = window.location.pathname + window.location.search;
    if (url.startsWith('/v2')) {
        url = url.substr(3);
    }
    return encodeURIComponent(url);
};

export const changeUrl = (url: string, isReplace = false) => {
    let localURL = `${document.location.pathname}${document.location.search}`;
    if (localURL.startsWith(URL_PREFIX)) {
        localURL = localURL.replace(URL_PREFIX, '');
    }
    if (localURL === url) {
        return;
    }
    if (url.startsWith(URL_PREFIX)) {
        url = url.replace(URL_PREFIX, '');
    }
    let relativePath = url;
    const isCloudInfra = getIsCloudInfra();
    if (isCloudInfra) {
        relativePath = url.replace(getCloudInfraPrefix(), '').replace(URL_PREFIX, '');
    }
    if (isReplace || isCloudInfra) {
        history.replace(relativePath, '');
    } else {
        history.push(relativePath, '');
    }
    const iframeMessage = new IframeMessageModel({
        action: IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
        data: {
            relativePath,
            isCloudInfra,
        },
    });
    if (isCloudInfra && !isReplace) {
        const iframeMessageToCloudInfra = new IframeMessageModel({
            action: IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
            data: { relativePath },
        });
        //This fix could possibly create a race condition on some components while updating the URL for CI
        emitMessageToCloudInfra(iframeMessageToCloudInfra);
    }
    getWebAppIframeService().emitMessage(iframeMessage);
};

export const getUrlAngularStyle = (
    baseUrl: string,
    sorting?: SortingModel,
    filterFields?: IFieldInfo[],
    freeTextFilter?: string,
    timePreset?: TimePresets | Object,
) => {
    const queryString =
        'query=' +
        encodeUrlObject({
            sorting: sorting || undefined,
            filter: {
                fields: filterFields || [],
                freeTextPhrase: freeTextFilter || '',
            },
        });
    const timeString = timePreset
        ? '&time=' +
          encodeUrlObject({
              time: timePreset,
          })
        : '';
    return `${baseUrl}?${queryString}${timeString}`;
};

export const hasDoubleReturnUrl = (url: string) => {
    return (url.match(/returnUrl/g) || []).length > 1;
};

export const cleanDoubleReturnUrl = () => {
    const url = decodeURIComponent(window.location.href);
    if (hasDoubleReturnUrl(url)) {
        const splitted = url.split('returnUrl=');
        window.location.search = `returnUrl=${splitted[splitted.length - 1]}`;
    }
};

export const toQueryString = ({
    query,
    allowSlash,
    allowQuestion,
    replaceDot,
}: {
    query: GenericObject<any>;
    allowSlash?: boolean;
    allowQuestion?: boolean;
    replaceDot?: boolean;
}): string => {
    //According to the RFC the / and the ? characters don't have to be encoded.
    //https://datatracker.ietf.org/doc/html/rfc3986#section-3.4
    let result = new URLSearchParams([...Object.entries(removeUndefinedFields(query))]).toString();
    allowSlash ??= true;
    allowQuestion ??= false;
    replaceDot ??= true;
    if (allowSlash) {
        result = result.replaceAll(encodeURIComponent('/'), '/');
    }
    if (allowQuestion) {
        result = result.replaceAll(encodeURIComponent('?'), '?');
    }

    if (replaceDot) {
        result = result.replaceAll('.', '%2E');
    }
    return result;
};

export const getCookieOptions = () => {
    const isLocalhost = window.location.host.includes(':4000');
    return !isLocalhost ? CookieOptions : { path: '/', domain: getDomain() };
};

export const setCsrf = (csrf = '', cookieOptions?: SetCookieOptions): void => {
    cookies.set(CSRF, csrf, cookieOptions);
};
export const removeCsrf = (): void => {
    cookies.remove(CSRF);
};
export const getCsrf = (): string => {
    return cookies.get(CSRF);
};
export const changeUrlParam = (name: string, value?: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(name);
    if (value !== undefined) {
        const fixedValud = typeof value === 'string' ? value : JSON.stringify(value);
        searchParams.append(name, fixedValud);
    }
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    changeUrl(newUrl);
};
export const changeUrlParams = (params: { name: string; value?: any }[]) => {
    const searchParams = new URLSearchParams(window.location.search);

    params.forEach(({ name, value }) => {
        searchParams.delete(name);
        if (value !== undefined) {
            const fixedValud = typeof value === 'string' ? value : JSON.stringify(value);
            searchParams.append(name, fixedValud);
        }
    });

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    changeUrl(newUrl);
};
export const getProdEnvironmentBaseUrl = () => {
    // used for vendor-icons and other assets in berries
    if (window.location.href.startsWith('https://localhost')) {
        return 'https://secure.dome9.com/v2/';
    }
    const base = window.location.hostname.replace('local', 'secure');
    return window.location.protocol + '//' + base + '/v2/';
};
// This function will always throw an error, use that at a custom error handler
export const generalApiError = (error: AxiosError<any>) => {
    if (error.response?.data?.message) {
        throw error.response.data.message;
    }
    if (error.response?.data?.errors) {
        const errorsData: { [key: string]: string[] } = error.response.data.errors;
        throw Object.values(errorsData)[0][0];
    }
    if (error.response?.data) {
        throw error.response.data;
    }
    throw tryExtractResponseText(error) || i18n.t('HTTP.UNEXPECTED_ERROR');
};

export const cleanQueryParams = (url: string, paramsToRemove: string[]): URL => {
    const urlObject = new URL(url);
    const queryParams = new URLSearchParams(urlObject.search);

    // Loop through existing query parameters
    for (const [key] of queryParams.entries()) {
        // Remove parameter if it's not in the paramsToKeep array
        if (paramsToRemove.includes(key)) {
            queryParams.delete(key);
        }
    }

    // Update the search property of the URL object with the cleaned parameters
    urlObject.search = queryParams.toString();

    // Return the modified URL as string
    return urlObject;
};
