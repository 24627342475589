import globalAddinContainer, { buildPath } from 'common/extensibility/AddinContainer';
import { IDashboard, IDashboardSection, IDashboardWidget, NavigateOption } from './Interface';
import { DASHBOARD_ITEMS } from './Consts';

export function addDashboardWidgetItem(dashboardId: string, sectionId: string, widget: IDashboardWidget) {
    globalAddinContainer.addMap(buildPath(DASHBOARD_ITEMS, dashboardId, sectionId), [widget]);
}

export function addDashboardSection(dashboardId: string, section: IDashboardSection) {
    const sectionClone = Object.assign({}, section);
    delete sectionClone.widgets;
    globalAddinContainer.addMap(buildPath(DASHBOARD_ITEMS, dashboardId), [sectionClone]);
    if (section.widgets) {
        section.widgets.forEach((widget) => {
            addDashboardWidgetItem(dashboardId, section.id, widget);
        });
    }
}

export function addDashboardItem(dashboard: IDashboard) {
    const dashboardClone = Object.assign({}, dashboard);
    delete dashboardClone.sections;
    globalAddinContainer.addMap(DASHBOARD_ITEMS, [dashboardClone]);
    if (dashboard.sections) {
        dashboard.sections.forEach((dashboard) => {
            addDashboardSection(dashboard.id, dashboard);
        });
    }
}

export const getCloudAccountIdAggregationLink = (
    widget: IDashboardWidget,
    cloudAccountId: string,
    url: string,
    platform?: string,
) => {
    if (!widget.options.navigateOption) {
        widget.options.navigateOption = NavigateOption.PROTECTED_ASSET;
    }
    if (widget.options.navigateOption === NavigateOption.PROTECTED_ASSET) {
        return `${url}?filterPanel={"organizationalUnitId":"","cloudAccountId":["${cloudAccountId}"]}&`;
    }
    if (widget.options.navigateOption === NavigateOption.ENVIRONMENT) {
        return `${url}/${platform}/${cloudAccountId}`;
    }
};
