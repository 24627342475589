import styled from 'styled-components';
import { Button, Icon } from 'common/design-system/components-v2';

const BaseRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    align-items: center;
    gap: 20px;
`;

const RowHeaderDiv = styled(BaseRowDiv)`
    background-color: darkblue;
    height: 25px;
`;

const RowSimpleDiv = styled(BaseRowDiv)<{ isHighlight?: boolean }>`
    height: 35px;
`;

const BaseCellDiv = styled.div<{ width?: number }>`
    display: flex;
    flex-direction: row;
    padding: 5px;
    align-items: center;
    width: ${(props) => props.width || 250}px;
`;

const CellHeaderDiv = styled(BaseCellDiv)`
    font-weight: 600;
    border-right: 1px solid white;
    color: white;
`;

const CellLinkDiv = styled(BaseCellDiv)<{ color: string }>`
    cursor: pointer;
    gap: 10px;
    align-items: center;
    color: ${(props) => props.color};

    &:hover {
        text-decoration: underline;
    }
`;

const CellTextDiv = styled(BaseCellDiv)`
    cursor: default;
    gap: 10px;
    align-items: center;
`;

const CellIcon = styled(Icon)`
    width: 16px;
    height: 16px;
`;

export const AssetTypeRowStyled = {
    RowHeaderDiv,
    RowSimpleDiv,
    CellHeaderDiv,
    CellLinkDiv,
    CellTextDiv,
    CellIcon,
};

const TopDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
    background-color: white;
    height: 100%;
    gap: 20px;
`;

const DisclaimerDiv = styled.div`
    padding: 10px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
`;

const TableDiv = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    height: 100%;
`;

const ButtonDiv = styled(Button)`
    display: flex;
    margin-top: 15px;
    width: 200px;
    min-width: 200px;
    justify-content: center;
    border: 1px solid #bebef0;
    font-weight: bold;
    color: darkblue;
`;

const DividerDiv = styled.hr`
    height: 1px;
    width: 100%;
`;

export const ExampleStyled = {
    TopDiv,
    DisclaimerDiv,
    TableDiv,
    ButtonDiv,
    DividerDiv,
};
