import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IProtectedAssetActionKey, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { IColumnUsageDef } from 'common/interface/general';
import { SortingModel } from '../../Findings/Findings.interface';
import { ICachingConfig } from '../../../interface/services';
import { Spacing } from 'common/design-system/components-v2/Stack/Stack.types';

export const pageSize = 50;

export interface IDynamicDataSourceConfig {
    includedEntityTypes: Array<string>;
    defaultFilters?: IFiltersValues;
    filters?: IFiltersValues;
    defaultSortModel?: SortingModel;
    cachingConfig?: ICachingConfig;
}

export interface DynamicProtectedAssetsTableProps {
    columns: Array<IColumnUsageDef>;
    includedEntityTypes: Array<string>;
    tableId: string;
    defaultFilters?: IFiltersValues;
    defaultSortModel?: SortingModel;
    withTabsPanel?: {
        pageId: string;
        closeAllUrl: string;
    };
    withFilterPanel?: {
        filters: Array<IProtectedAssetFilter>;
    };
    hideExport?: boolean;
    tablePadding?: Array<Spacing> | Spacing;
    actions?: Array<IProtectedAssetActionKey>;
    isRowSelectable?: (node: any) => boolean;
    deselectAllOnFilterChange?: boolean;
}
export interface IWebAppFilterParams {
    filter?: {
        fields?: IWebAppFilter[];
        freeTextPhrase?: string;
        tags?: IWebAppKeyValue[];
    };
}

interface IWebAppFilter {
    name: string;
    value: string;
}

interface IWebAppKeyValue {
    key: string;
    value: string;
}
