import React from 'react';
import { Chip, ComponentOverflow } from 'common/design-system/components-v2';
import { useTheme } from 'styled-components';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';

export const MultiChipCellContent: React.FC<{ propsList: IChipProps[] }> = ({ propsList }) => {
    const theme = useTheme();
    return (
        <ComponentOverflow
            components={propsList.map((chipProps, index) => (
                <Chip
                    label={chipProps.label ?? ''}
                    leadingIconProps={chipProps.leadingIconProps}
                    trailIconProps={chipProps.trailIconProps}
                    tooltip={chipProps.tooltip}
                    onClick={chipProps.onClick}
                    size={chipProps.size || 'sm'}
                    disableInteraction={chipProps.disableInteraction}
                    pointerCursor={chipProps.pointerCursor}
                    customColor={theme.palette.surface.strong}
                    key={`chip-${index}`}
                />
            ))}
        />
    );
};
