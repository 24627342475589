import { IFieldInfo } from '../../interface/general';
import { FilterValueType, ICompactFilterItem } from './ermComponents.filters';
import { isArray, isString } from '../../utils/helpFunctions';
import { FILTERS_KEYS } from '../../components/FilterPanel/FilterPanel.consts';
import { getCompactFilterFields } from './ermComponents.utils';
import { convertOldDateObjToNew } from '../../components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { IDateFilterOption, TimeFilter } from '../../components/FilterPanel/DefaultFilters/DefaultFilters.interface';

export function fieldValueToJson(value: any): string {
    if (value === undefined) {
        return '';
    } else if (isArray(value)) {
        const values: any[] = value;
        const strings: string[] = values.map((val: any) => fieldValueToJson(val));
        return `[${strings.join(',')}]`;
    } else if (isString(value)) {
        const str = String(value).trim();
        if (str && !isNaN(Number(str))) {
            return str;
        }
        if (str === 'true' || str === 'false') {
            return str;
        }
    } else if (typeof value === 'number') {
        return String(value);
    }
    return JSON.stringify(value);
}

export function getUrlWithFilters(
    baseUrl: string,
    filtersItems?: IFieldInfo[],
    freeText?: string,
    timeFilter?: TimeFilter,
): string {
    let url;
    const compactFields: ICompactFilterItem[] = getCompactFilterFields(filtersItems) || [];
    if (freeText) {
        compactFields.push({
            name: FILTERS_KEYS.FREE_TEXT,
            value: freeText,
            valueType: FilterValueType.STRING,
        });
    }
    if (timeFilter) {
        const timeRangeObject: IDateFilterOption = convertOldDateObjToNew(timeFilter);
        compactFields.push({
            name: FILTERS_KEYS.DATE_PICKER,
            value: timeRangeObject,
            valueType: FilterValueType.DATE_RANGE,
        });
    }
    if (compactFields?.length) {
        const filterParts = compactFields.map((field) => {
            const strValue = fieldValueToJson(field.value);
            return `${JSON.stringify(field.name)}:${strValue}`;
        });
        const filterString = encodeURIComponent(filterParts.join(','));
        const paramsConnector = baseUrl.includes('?') ? '&' : '?';
        url = `${baseUrl}${paramsConnector}filterPanel={${filterString}}`;
    } else {
        url = baseUrl;
    }
    return url.startsWith('/') ? url : `/${url}`;
}

/**
 * This function is essential when working with translation strings that may include special characters. It ensures that the final output is correctly displayed by decoding any HTML entities present in the string.
 * @param text string to decode
 */
export const decodeHtmlEntities = (text: string): string => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(text, "text/html");
    const errorNode: Element | null = parsedDocument.querySelector('parsererror');
    if (errorNode) {
        console.error('Error parsing localization string', text);
        return '';
    }
    return parsedDocument.body.textContent ?? '';
};
