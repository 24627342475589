import { FC, useEffect, useState } from 'react';
import { SelectV2 } from 'common/design-system/components-v2';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionRuleProps } from '../../helpers/exclusions.interfaces';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { fetchRules, formatRules, handleFetchingError } from '../../helpers/exclusions.utils';
import { IRule, IRuleOption } from 'common/interface/exclusion';
import _ from 'lodash';

const ExclusionByRule: FC<IExclusionRuleProps> = ({
    isEnabled,
    selectedRuleset,
    initialSelectedOption,
    selectedRulesetDetails,
    onChange,
    onClose,
    selectedOption,
    tooltip,
}) => {
    const { t } = useTranslation(I18nExclusion);
    const [rulesFromApi, setRulesFromApi] = useState<IRule[]>([]);
    const [rulesList, setRulesList] = useState<SelectOption[]>([]);

    useEffect(() => {
        const handleClearListAndValue = () => {
            setRulesList([]);
            onChange([]);
        };
        const updateRulesList = (rules: SelectOption[]) => {
            setRulesList(rules);
        };
        async function handleFetchRules() {
            if (!selectedRuleset || !selectedRulesetDetails?.id) {
                handleClearListAndValue();
                return;
            }
            try {
                const rulesData = await fetchRules(selectedRulesetDetails?.id);
                setRulesFromApi(rulesData?.rules);
                const formattedRules = formatRules(rulesData?.rules);
                formattedRules && updateRulesList(formattedRules);
            } catch (error) {
                handleFetchingError(t, onClose);
            }
        }
        handleFetchRules();
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRuleset]);

    useEffect(() => {
        const handlePreselectedOption = (initialSelectedOption: IRuleOption[]) => {
            if (initialSelectedOption?.length) {
                const filteredInitialRules = initialSelectedOption.map((option) => {
                    return rulesFromApi.find((rule) => option.id === rule.ruleId);
                });
                const formattedInitialRules = filteredInitialRules.map((rule) => {
                    return rule?.logicHash;
                });
                formattedInitialRules && onChange(_.compact(formattedInitialRules));
            } else {
                onChange([]);
            }
        };
        initialSelectedOption && handlePreselectedOption(initialSelectedOption);
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rulesList]);

    const handleRuleChange = (newValue: string[]) => {
        onChange(newValue);
    };

    return (
        <SelectV2
            label={t('MODAL.TOPICS.RULE.TITLE')}
            labelProps={{ tooltip: tooltip }}
            onChange={(value: string[]) => handleRuleChange(value)}
            isMulti={true}
            options={rulesList}
            readOnly={!isEnabled || !selectedRulesetDetails?.id}
            placeholder={t('MODAL.GENERAL.SELECT')}
            value={selectedOption}
            data-aid='exclusions-rule-select'
            fullWidth
            clearable
        />
    );
};

export default ExclusionByRule;
