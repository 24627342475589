import { ForegroundByContext, Palette } from './colors.types';

export const foregrounds = (palette: Palette): ForegroundByContext => ({
    default: {
        strong: {
            default: palette.slate.alpha[900],
            saturation: palette.slate.alpha[500],
        },
        regular: {
            default: palette.slate.alpha[700],
            saturation: palette.slate.alpha[700],
        },
        weak: {
            default: palette.slate.alpha[500],
            saturation: palette.slate.alpha[500],
        },
        weaker: {
            default: palette.slate.alpha[300],
            saturation: palette.slate.alpha[300],
        },
        weakest: {
            default: palette.slate.alpha[100],
            saturation: palette.slate.alpha[100],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.slate.opaque[10],
        invertedRegular: palette.slate.opaque[20],
        invertedWeak: palette.slate.opaque[30],
        invertedWeaker: palette.slate.opaque[40],
        invertedWeakest: palette.slate.opaque[50],
    },
    critical: {
        strong: {
            default: palette.magenta.alpha[900],
            saturation: palette.magenta.opaque[500],
        },
        regular: {
            default: palette.magenta.alpha[700],
            saturation: palette.magenta.opaque[400],
        },
        weak: {
            default: palette.magenta.alpha[500],
            saturation: palette.magenta.opaque[300],
        },
        weaker: {
            default: palette.magenta.alpha[300],
            saturation: palette.magenta.opaque[200],
        },
        weakest: {
            default: palette.magenta.alpha[100],
            saturation: palette.magenta.opaque[90],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.magenta.opaque[10],
        invertedRegular: palette.magenta.opaque[20],
        invertedWeak: palette.magenta.opaque[30],
        invertedWeaker: palette.magenta.opaque[40],
        invertedWeakest: palette.magenta.opaque[50],
    },
    high: {
        strong: {
            default: palette.red.alpha[900],
            saturation: palette.red.opaque[400],
        },
        regular: {
            default: palette.red.alpha[700],
            saturation: palette.red.opaque[300],
        },
        weak: {
            default: palette.red.alpha[500],
            saturation: palette.red.opaque[200],
        },
        weaker: {
            default: palette.red.alpha[300],
            saturation: palette.red.opaque[100],
        },
        weakest: {
            default: palette.red.alpha[100],
            saturation: palette.red.opaque[90],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.red.opaque[10],
        invertedRegular: palette.red.opaque[20],
        invertedWeak: palette.red.opaque[30],
        invertedWeaker: palette.red.opaque[40],
        invertedWeakest: palette.red.opaque[50],
    },
    medium: {
        strong: {
            default: palette.orange.alpha[900],
            saturation: palette.orange.opaque[400],
        },
        regular: {
            default: palette.orange.alpha[700],
            saturation: palette.orange.opaque[300],
        },
        weak: {
            default: palette.orange.alpha[500],
            saturation: palette.orange.opaque[200],
        },
        weaker: {
            default: palette.orange.alpha[300],
            saturation: palette.orange.opaque[100],
        },
        weakest: {
            default: palette.orange.alpha[100],
            saturation: palette.orange.opaque[90],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.orange.opaque[10],
        invertedRegular: palette.orange.opaque[20],
        invertedWeak: palette.orange.opaque[30],
        invertedWeaker: palette.orange.opaque[40],
        invertedWeakest: palette.orange.opaque[50],
    },
    low: {
        strong: {
            default: palette.yellow.alpha[900],
            saturation: palette.yellow.opaque[400],
        },
        regular: {
            default: palette.yellow.alpha[700],
            saturation: palette.yellow.opaque[300],
        },
        weak: {
            default: palette.yellow.alpha[500],
            saturation: palette.yellow.opaque[200],
        },
        weaker: {
            default: palette.yellow.alpha[300],
            saturation: palette.yellow.opaque[100],
        },
        weakest: {
            default: palette.yellow.alpha[100],
            saturation: palette.yellow.opaque[90],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.yellow.opaque[10],
        invertedRegular: palette.yellow.opaque[20],
        invertedWeak: palette.yellow.opaque[30],
        invertedWeaker: palette.yellow.opaque[40],
        invertedWeakest: palette.yellow.opaque[50],
    },
    lowRisk: {
        strong: {
            default: palette.green.alpha[900],
            saturation: palette.green.opaque[500],
        },
        regular: {
            default: palette.green.alpha[700],
            saturation: palette.green.opaque[400],
        },
        weak: {
            default: palette.green.alpha[500],
            saturation: palette.green.opaque[300],
        },
        weaker: {
            default: palette.green.alpha[300],
            saturation: palette.green.opaque[200],
        },
        weakest: {
            default: palette.green.alpha[100],
            saturation: palette.green.opaque[90],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.green.opaque[10],
        invertedRegular: palette.green.opaque[20],
        invertedWeak: palette.green.opaque[30],
        invertedWeaker: palette.green.opaque[40],
        invertedWeakest: palette.green.opaque[50],
    },
    info: {
        strong: {
            default: palette.blue.alpha[900],
            saturation: palette.blue.opaque[200],
        },
        regular: {
            default: palette.blue.alpha[700],
            saturation: palette.blue.opaque[100],
        },
        weak: {
            default: palette.blue.alpha[500],
            saturation: palette.blue.opaque[90],
        },
        weaker: {
            default: palette.blue.alpha[300],
            saturation: palette.blue.opaque[80],
        },
        weakest: {
            default: palette.blue.alpha[100],
            saturation: palette.blue.opaque[70],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.blue.opaque[10],
        invertedRegular: palette.blue.opaque[20],
        invertedWeak: palette.blue.opaque[30],
        invertedWeaker: palette.blue.opaque[40],
        invertedWeakest: palette.blue.opaque[50],
    },
    important: {
        strong: {
            default: palette.purple.alpha[900],
            saturation: palette.purple.opaque[400],
        },
        regular: {
            default: palette.purple.alpha[700],
            saturation: palette.purple.opaque[300],
        },
        weak: {
            default: palette.purple.alpha[500],
            saturation: palette.purple.opaque[200],
        },
        weaker: {
            default: palette.purple.alpha[300],
            saturation: palette.purple.opaque[100],
        },
        weakest: {
            default: palette.purple.alpha[100],
            saturation: palette.purple.opaque[90],
        },
        invertedStronger: palette.slate.opaque[10],
        invertedStrong: palette.purple.opaque[10],
        invertedRegular: palette.purple.opaque[20],
        invertedWeak: palette.purple.opaque[30],
        invertedWeaker: palette.purple.opaque[40],
        invertedWeakest: palette.purple.opaque[50],
    },
});
