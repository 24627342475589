import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { ViewCommonAllUsers } from './viewCommonApi.interface';

const apiBaseUrl = 'view/common';

const getAllUsers: ViewCommonAllUsers.Function = async () => {
    return await getHttpService().request<AxiosResponse<ViewCommonAllUsers.Response>>(
        `${apiBaseUrl}/all-users`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        (err) => {
            throw err;
        },
    );
};

const ViewCommonApi = {
    getAllUsers,
};

export default ViewCommonApi;
