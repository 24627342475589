import styled from 'styled-components';
import Stack from 'common/design-system/components-v2/Stack';

const TopDiv = styled(Stack)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 200px;
    height: 100%;
`;

const LoadingDiv = styled(Stack)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const WidgetContentDiv = styled(Stack)`
    height: 100%;
    justify-content: center;
`;

const EmptyOrFailureDiv = styled(Stack)`
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.palette.status.error};
`;

export const WidgetWrapperStyled = {
    TopDiv,
    LoadingDiv,
    WidgetContentDiv,
    EmptyOrFailureDiv,
};
