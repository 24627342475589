import React, { useCallback, useMemo } from 'react';
import {
    IFilterTreeCommonProps,
    IFilterTreeNode,
    IFilterTreeSettings,
    LogicalOperatorsLimitation,
} from '../FilterTree.interface';
import { CompoundFilterLogicalOperator } from '../CompoundFilter';
import { FilterStyled } from './FilterTree.styled';
import { useTranslation } from 'react-i18next';
import { SelectV2, Stack } from 'common/design-system/components-v2';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { NodeAddBox } from './FilterTreeNode';
import { isNil } from '../../../../utils/helpFunctions';
import { useDraggable } from '@dnd-kit/core';

export const FilterTreeLogicalOperator: React.FC<{
    node: IFilterTreeNode,
    filterProps: IFilterTreeCommonProps
    }> =
    ({ node, filterProps }) => {
        const { t } = useTranslation();
        const { attributes, listeners, setNodeRef, transform } = useDraggable({
            id: node.id,
        });
        const style = transform ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        } : undefined;

        const isOrOnly = useMemo(() => (filterProps.settings?.logicalOperatorsLimitation === LogicalOperatorsLimitation.OR_ONLY_WITH_AND_ROOT), [filterProps.settings?.logicalOperatorsLimitation]);
        const opOptions: SelectOption[] = useMemo(() => {
            const ops: CompoundFilterLogicalOperator[] = isOrOnly ? [CompoundFilterLogicalOperator.OR] : Object.values(CompoundFilterLogicalOperator);
            return ops.map(op => {
                return {
                    label: t(`FILTER_TREE.LOGICAL_OPERATOR.${op.toUpperCase()}`),
                    value: String(op),
                };
            });
        }, [isOrOnly, t]);

        const onOperatorChange = useCallback((logicalOperator: CompoundFilterLogicalOperator, settings?: IFilterTreeSettings) => {
            filterProps.api.onLogicalOperatorChange(node, logicalOperator, settings);
        }, [filterProps, node]);

        const showLogicalOperator = useMemo(() => {
            switch (filterProps.settings?.logicalOperatorsLimitation) {
                case LogicalOperatorsLimitation.NO_LOGICAL_OPERATORS:
                    return false;

                case LogicalOperatorsLimitation.OR_ONLY_WITH_AND_ROOT:
                    return !!node.parentNode;

                default:
                    return true;
            }
        }, [filterProps.settings?.logicalOperatorsLimitation, node.parentNode]);

        return (
            <FilterStyled.LogicalOperatorDiv ref={setNodeRef} style={style}>
                {showLogicalOperator && <FilterStyled.LogicalOperatorSelectorDiv>
                    <Stack direction={'row'}>
                        {!filterProps.readOnly && !isNil(node.parentNode) && <FilterStyled.DragHandle {...listeners} {...attributes}/>}
                        <SelectV2
                            width={'80px'}
                            value={node.logicalOperator}
                            onChange={(value: string) => onOperatorChange(value as CompoundFilterLogicalOperator, filterProps.settings)}
                            placeholder={''}
                            options={opOptions}
                            isError={!!(filterProps.displayErrors && node.errorMsg)}
                            isMulti={false}
                            readOnly={filterProps.readOnly || isOrOnly}
                        />
                    </Stack>
                </FilterStyled.LogicalOperatorSelectorDiv>}
                {(filterProps.settings?.logicalOperatorsLimitation === LogicalOperatorsLimitation.NO_LOGICAL_OPERATORS) && <FilterStyled.NoLogicalOperatorDiv />}
                { !filterProps.readOnly && <NodeAddBox node={node} filterProps={filterProps} /> }
                <FilterStyled.StretchDiv />
            </FilterStyled.LogicalOperatorDiv>
        );
    };
