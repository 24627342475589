import React, { useEffect } from 'react';
import { IVendorRuleset } from 'common/interface/policy';
import { getRulesets } from 'common/components/policy/utils';
import { useTranslation } from 'react-i18next';
import { ListItem } from './MultiSelectList/ListItemTypes/interfaces';
import { MultiSelectListComponent } from './MultiSelectList';
import { TextIconVersionItem } from './MultiSelectList/ListItemTypes/TextIconVersionItem';

export interface OURulesetSelectionProps {
    onValidate: Function;
    selectedRulesetsChanged: Function;
    preSelectedItems?: ListItem[];
    vendorRulesets: IVendorRuleset[];
}

export const RulesetMultiSelectionComponent: React.FC<OURulesetSelectionProps> = ({
    vendorRulesets,
    preSelectedItems,
    onValidate,
    selectedRulesetsChanged,
}) => {
    const { t } = useTranslation();
    const [rulesets, setRulesets] = React.useState<ListItem[] | null>(null);

    const init = async () => {
        const uniqRulesetsItems = await getRulesets(vendorRulesets);
        setRulesets(uniqRulesetsItems);
    };

    useEffect(() => {
        init();
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        onValidate(selectedItems?.length);
        selectedRulesetsChanged(selectedItems);
        selectedItems.forEach((item) => {
            const match = rulesets?.find((_ruleset) => _ruleset.id === item.id);
            if (match) {
                match.selectedVersion = item.selectedVersion;
            }
        });
        rulesets && setRulesets([...rulesets]);
    };
    useEffect(() => {
        if (rulesets?.length && preSelectedItems?.length) {
            preSelectedItems.forEach((preSelectedItem) => {
                const match = rulesets?.find((_ruleset) => _ruleset.id === preSelectedItem.id);
                if (match) {
                    match.selectedVersion = preSelectedItem.selectedVersion;
                }
            });
            setRulesets([...rulesets]);
        }
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preSelectedItems, rulesets?.length]);

    return (
        <span data-aid={'rulesets-selection'}>
            <MultiSelectListComponent
                preSelectedItems={preSelectedItems}
                itemTemplate={TextIconVersionItem}
                header={t('GENERAL.SELECT_RULESET')}
                items={rulesets}
                onSelectedItemsChanged={selectedItemsChanged}
            />
        </span>
    );
};
