import { GenericObject } from '../interface/general';
import {
    DEFAULT_MIN_TIME_BETWEEN_REFRESH_AHEAD,
    getHttpService,
    ICacheEntry,
    ICacheLookupResult,
    ICachingConfig,
} from '../interface/services';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';

export const isMethodHasCache = (method: string): boolean => {
    return method === 'GET' || method === 'POST';
};

export const getCachablePayload = <P = any>(payload: P | undefined, cachingConfig?: ICachingConfig): any => {
    if (!payload) {
        return undefined;
    }
    return cachingConfig?.preparePayloadForCache ? cachingConfig.preparePayloadForCache(payload) : payload;
};

export const getRequestSignature = <P = any>(route: string, payload?: P, cachingConfig?: ICachingConfig): string => {
    return JSON.stringify({
        route,
        payload: getCachablePayload<P>(payload, cachingConfig),
    });
};

export const markEntryAsResolved = <T>(entry: ICacheEntry<T>) => {
    entry.resolutionTime = dayjs();
};

class CacheStore {
    private entriesMap: { [key: string]: ICacheEntry } = {};
    private readonly method: string;

    constructor(method: string) {
        this.method = method;
    }

    public getCacheEntry<T>(entryKey: string): ICacheEntry<T> | undefined {
        return this.entriesMap[entryKey];
    }

    public lookupCacheEntry<T>(entryKey: string, dataAgeLimit?: number): ICacheLookupResult<T> | undefined {
        const entry: ICacheEntry<T> = this.entriesMap[entryKey];
        if (entry) {
            if (dataAgeLimit !== undefined) {
                const latestValidTime = entry.creationTime.add(dataAgeLimit, 'second');
                const now = dayjs();
                return {
                    entry,
                    isValid: now.isBefore(latestValidTime),
                    remaining: latestValidTime.diff(now, 'seconds'),
                };
            }

            return {
                entry,
                isValid: true,
            };
        }
    }

    private createCacheEntry<T, P = any>(
        entryKey: string,
        route: string,
        promise: Promise<AxiosResponse<T>>,
        payload?: P,
        tags?: string[],
    ): ICacheEntry<T> {
        const now = dayjs();
        return {
            uniqueKey: entryKey,
            route,
            payload,
            promise,
            tags,
            creationTime: now,
        };
    }

    private mergeTags(tags1?: string[], tags2?: string[]): string[] | undefined {
        const finalTags: string[] = Array.from(new Set([...(tags1 || []), ...(tags2 || [])]));
        return finalTags.length ? finalTags : undefined;
    }

    public savePromiseToCache<T, P = any>(
        entryKey: string,
        route: string,
        promise: Promise<AxiosResponse<T>>,
        payload?: P,
        tags?: string[],
    ): ICacheEntry<T> {
        const entry: ICacheEntry<T> = this.createCacheEntry<T, P>(entryKey, route, promise, payload, tags);
        this.entriesMap[entry.uniqueKey] = entry;
        return entry;
    }

    public createAlternativeEntry<T, P = any>(
        origEntry: ICacheEntry,
        promise: Promise<AxiosResponse<T>>,
        tags?: string[],
    ): ICacheEntry<T> {
        const finalTags = this.mergeTags(origEntry.tags, tags);
        const alternativeEntry: ICacheEntry<T> = this.createCacheEntry<T, P>(
            origEntry.uniqueKey,
            origEntry.route,
            promise,
            origEntry.payload,
            finalTags,
        );
        origEntry.alternativeEntry = alternativeEntry;
        return alternativeEntry;
    }

    public insertAlternativeEntryToCache<T>(alternativeEntry: ICacheEntry<T>) {
        if (alternativeEntry.isRemoved) {
            return;
        }
        const origEntry = this.entriesMap[alternativeEntry.uniqueKey];
        if (origEntry) {
            if (origEntry === alternativeEntry) {
                return;
            }
            origEntry.alternativeEntry = undefined;
            this.clearCacheEntry(origEntry.uniqueKey);
        }
        this.entriesMap[alternativeEntry.uniqueKey] = alternativeEntry;
    }

    public clearCacheEntry(entryKey: string) {
        const entry = this.entriesMap[entryKey];
        if (entry) {
            entry.isRemoved = true;
            if (entry.alternativeEntry) {
                entry.alternativeEntry.isRemoved = true;
                entry.alternativeEntry = undefined;
            }
            delete this.entriesMap[entryKey];
        }
    }

    public clearCacheByTag<T>(tag: string) {
        Object.values(this.entriesMap).forEach((entry: ICacheEntry<T>) => {
            if (entry.tags?.includes(tag)) {
                this.clearCacheEntry(entry.uniqueKey);
            }
        });
    }

    public clearCacheByRoute<T>(route: string) {
        Object.values(this.entriesMap).forEach((entry: ICacheEntry<T>) => {
            if (entry.route === route) {
                this.clearCacheEntry(entry.uniqueKey);
            }
        });
    }

    public clearEntireCache() {
        Object.keys(this.entriesMap).forEach((entryKey: string) => {
            this.clearCacheEntry(entryKey);
        });
    }

    public getCacheSize(): number {
        return Object.keys(this.entriesMap).length;
    }
}

class ApiCaching {
    private readonly cache: GenericObject<CacheStore>;
    private readonly minTimeBetweenRefreshAhead: number;

    public constructor(minTimeBetweenRefreshAhead = DEFAULT_MIN_TIME_BETWEEN_REFRESH_AHEAD) {
        this.cache = {
            GET: new CacheStore('GET'),
            POST: new CacheStore('POST'),
        };
        this.minTimeBetweenRefreshAhead = minTimeBetweenRefreshAhead;
    }

    public lookupCacheEntry<T>(
        method: string,
        entryKey: string,
        dataAgeLimit?: number,
    ): ICacheLookupResult<T> | undefined {
        const cacheStore = this.cache[method.toUpperCase()];
        if (cacheStore) {
            return cacheStore.lookupCacheEntry<T>(entryKey, dataAgeLimit);
        }
        throw new Error(
            `Cannot lookup cache entry with key ${entryKey} in cache: Caching is not supported for method ${method}`,
        );
    }

    public lookupPromiseInCache<T>(
        method: string,
        entryKey: string,
        dataAgeLimit?: number,
    ): Promise<AxiosResponse<T>> | undefined {
        const lookupResult: ICacheLookupResult<T> | undefined = this.lookupCacheEntry<T>(
            method,
            entryKey,
            dataAgeLimit,
        );
        return lookupResult?.entry?.promise;
    }

    public getCacheEntry<T>(entryKey: string, method: string): ICacheEntry<T> | undefined {
        const cacheStore = this.cache[method.toUpperCase()];
        if (cacheStore) {
            return cacheStore.getCacheEntry<T>(entryKey);
        }
        throw new Error(`Cannot get cache entry with key ${entryKey}: Caching is not supported for method ${method}`);
    }

    public savePromiseToCache<T, P = any>(
        entryKey: string,
        route: string,
        method: string,
        promise: Promise<AxiosResponse<T>>,
        payload?: P,
        tags?: string[],
    ): ICacheEntry<T> {
        const cacheStore = this.cache[method.toUpperCase()];
        if (cacheStore) {
            return cacheStore.savePromiseToCache<T, P>(entryKey, route, promise, payload, tags);
        }
        throw new Error(
            `Cannot save promise with route ${route} to cache: Caching is not supported for method ${method}`,
        );
    }

    public createAlternativeEntry<T, P = any>(
        origCacheEntry: ICacheEntry,
        method: string,
        promise: Promise<AxiosResponse<T>>,
        tags?: string[],
    ): ICacheEntry<T> {
        const cacheStore = this.cache[method.toUpperCase()];
        if (cacheStore) {
            return cacheStore.createAlternativeEntry<T, P>(origCacheEntry, promise, tags);
        }
        throw new Error(`Cannot create alternative cache entry: Caching is not supported for method ${method}`);
    }

    public insertAlternativeEntryToCache<T>(alternativeEntry: ICacheEntry<T>, method: string) {
        const cacheStore = this.cache[method.toUpperCase()];
        if (cacheStore) {
            cacheStore.insertAlternativeEntryToCache<T>(alternativeEntry);
            return;
        }
        throw new Error(`Cannot insert alternative cache entry: Caching is not supported for method ${method}`);
    }

    public clearCacheEntry(entryKey: string, method: string) {
        const cacheStore = this.cache[method.toUpperCase()];
        if (cacheStore) {
            return cacheStore.clearCacheEntry(entryKey);
        }
        throw new Error(`Cannot clear cache entry with key ${entryKey}: Caching is not supported for method ${method}`);
    }

    public clearCacheByTag<T>(tag: string, method?: string) {
        const methods: string[] = method ? [method] : Object.keys(this.cache);
        methods.forEach((m) => {
            const cacheStore = this.cache[m.toUpperCase()];
            if (!cacheStore) {
                throw new Error(`Cannot clear cache by tag ${tag}: Caching is not supported for method ${m}`);
            }
            cacheStore.clearCacheByTag<T>(tag);
        });
    }

    public clearCacheByRoute<T>(route: string, method: string) {
        const cacheStore = this.cache[method.toUpperCase()];
        if (cacheStore) {
            return cacheStore.clearCacheByRoute<T>(route);
        }
        throw new Error(`Cannot clear cache by route ${route}: Caching is not supported for method ${method}`);
    }

    public clearEntireCache() {
        Object.values(this.cache).forEach((cacheStore) => {
            cacheStore.clearEntireCache();
        });
    }

    public getCacheSize(): number {
        let size = 0;
        Object.values(this.cache).forEach((cacheStore) => {
            size += cacheStore.getCacheSize();
        });
        return size;
    }

    public isAllowedRefreshAhead = <T>(entry: ICacheEntry<T>): boolean => {
        if (entry.alternativeEntry || !entry.resolutionTime) {
            return false;
        }
        const allowedTime = entry.resolutionTime.add(this.minTimeBetweenRefreshAhead, 'second');
        return allowedTime.isBefore(dayjs());
    };
}

export default ApiCaching;

export const getCacheTag = (serviceName: string, itemId?: string): string => {
    const baseTag = `${serviceName}-CACHE-TAG`;
    return itemId ? `${baseTag}:${itemId}` : baseTag;
};

export const clearCacheDataByTag = (serviceName: string, itemId?: string, method?: string) => {
    const tag = getCacheTag(serviceName, itemId);
    getHttpService().clearCacheByTag(tag, method);
};
