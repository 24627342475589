import { ErrorComponent } from './ErrorComponent';
import { HelperTextComponent } from './HelperTextComponent';
import { IBaseItemState, IFullErrorInfo } from '../../CustomForm.interface';
import React from 'react';
import { Stack } from '../../../../../design-system/components-v2';

export interface ISubComponentAreaProps {
    state: IBaseItemState;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
}

const ItemComponentBottomArea: React.FC<ISubComponentAreaProps> = ({ state, showErrors, errorInfo }) => {
    return (
        <Stack direction={'column'} spacing={1}>
            {!!state.helpText && <HelperTextComponent helpText={state.helpText} />}
            <ErrorComponent showErrors={showErrors} errorInfo={errorInfo} />
        </Stack>
    );
};

export default ItemComponentBottomArea;
