import { DevModalRegistry } from './DevModalRegistry';
import { DevModalExample2 } from './components/DevModalExample2';
import { DevModalExample1 } from './components/DevModalExample1';
import SvgIconExample from '../../basic/SvgIcon/SvgIcon.example';
import { HttpRunner } from './components/HttpRunner';

export const initializeDevModal = () => {
    DevModalRegistry.addTab({ title: 'Http', component: HttpRunner });
    DevModalRegistry.addTab({ title: 'Example1', component: DevModalExample1 });
    DevModalRegistry.addTab({ title: 'Example2', component: DevModalExample2 });
    DevModalRegistry.addTab({ title: 'SvgIcon', component: SvgIconExample });
};
