import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgLvlShrink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
     ref={ref}
     {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.25 1.69995C1.25 1.28574 1.58579 0.949951 2 0.949951H22C22.4142 0.949951 22.75 1.28574 22.75 1.69995C22.75 2.11416 22.4142 2.44995 22 2.44995H12.75V7.88929L14.4697 6.16962C14.7626 5.87673 15.2374 5.87673 15.5303 6.16962C15.8232 6.46251 15.8232 6.93739 15.5303 7.23028L12.5303 10.2303C12.2374 10.5232 11.7626 10.5232 11.4697 10.2303L8.46967 7.23028C8.17678 6.93739 8.17678 6.46251 8.46967 6.16962C8.76256 5.87673 9.23744 5.87673 9.53033 6.16962L11.25 7.88929V2.44995H2C1.58579 2.44995 1.25 2.11416 1.25 1.69995Z"
          />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M22.75 21.7C22.75 22.1142 22.4142 22.45 22 22.45L2 22.45C1.58579 22.45 1.25 22.1142 1.25 21.7C1.25 21.2857 1.58579 20.95 2 20.95H11.25L11.25 15.5106L9.53033 17.2303C9.23744 17.5232 8.76256 17.5232 8.46967 17.2303C8.17678 16.9374 8.17678 16.4625 8.46967 16.1696L11.4697 13.1696C11.7626 12.8767 12.2374 12.8767 12.5303 13.1696L15.5303 16.1696C15.8232 16.4625 15.8232 16.9374 15.5303 17.2303C15.2374 17.5232 14.7626 17.5232 14.4697 17.2303L12.75 15.5106L12.75 20.95H22C22.4142 20.95 22.75 21.2857 22.75 21.7Z"
          />
</svg>

);
const ForwardRef = forwardRef(SvgLvlShrink);
export default ForwardRef;
