import React, { Suspense, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Tabs from './components/Tabs/Tabs';
import { ContentItem, TopSection } from './EntityViewer.styled';
import { EntityViewerProps } from './EntityViewer.interface';
import useTabs from './hooks/useTabs';
import { EntityHeaderProps } from 'common/design-system/components-v2/EntityHeader/EntityHeader.types';
import EntityHeader from 'common/design-system/components-v2/EntityHeader/EntityHeader';
import { IWebappIframeServicePlacement, getWebAppIframeService } from 'common/interface/services';
import { Spinner, Stack } from 'common/design-system/components-v2';
import { setIsAngularFrameOnRemote, setIsReactPageWithAngular } from 'App.reducer';
import { ErrorBoundary } from '@sentry/react';
import EmptyState from '../EmptyState';

const EntityViewer: React.FunctionComponent<EntityViewerProps> = ({
    title,
    levelIcon,
    titleRightElements,
    details,
    tabs,
    chips,
    context,
    dataAid,
    ignoreUrlParams,
}) => {
    const dispatch = useDispatch();
    const { selectedTab, handleOnTabChange } = useTabs(tabs, ignoreUrlParams);
    const contentRef = React.useRef<HTMLDivElement>(null);

    const entityViewerInfoProps = useMemo<EntityHeaderProps>(
        () => ({
            title,
            levelIcon,
            titleRightElements,
            blocks: details,
            chips,
            initialShowMoreState: true,
            dataAid,
        }),
        [title, levelIcon, titleRightElements, details, chips, dataAid],
    );

    React.useEffect(() => {
        if (!contentRef.current || !selectedTab) return;
        dispatch(setIsReactPageWithAngular(!selectedTab.isReactTab));
        dispatch(setIsAngularFrameOnRemote(!selectedTab.isReactTab));
        getWebAppIframeService().setOverlayWrapperRef(
            contentRef.current,
            context || IWebappIframeServicePlacement.REACT_AND_ANGULAR,
        );
        getWebAppIframeService().setVisibility(!selectedTab.isReactTab);
        if (selectedTab.viewStateProps) {
            getWebAppIframeService().setViewState(selectedTab.viewStateProps);
        }
        return () => {
            if (!selectedTab.isReactTab) {
                dispatch(setIsReactPageWithAngular(false));
                dispatch(setIsAngularFrameOnRemote(false));
            }
            getWebAppIframeService().clearOverlayWrapperRef();
            if (selectedTab?.viewStateProps) {
                getWebAppIframeService().removeViewState();
            }
        };
    }, [contentRef, selectedTab, context, dispatch]);

    const relevantTabs = tabs.filter((tab) => {
        if (typeof tab.isRelevant === 'function') return tab.isRelevant();
        return tab.isRelevant !== undefined ? tab.isRelevant : true;
    });

    return (
        <Stack fullHeight overflow='hidden' data-aid='entity-viewer'>
            <TopSection padding={6}>
                <EntityHeader {...entityViewerInfoProps} />
            </TopSection>
            {selectedTab && (
                <Stack direction='row' fullHeight overflow='hidden'>
                    <Tabs
                        headers={relevantTabs.map((tab) => ({
                            label: tab.label || tab.name,
                            chipLabel: tab.chipLabel,
                        }))}
                        selectedTab={relevantTabs.findIndex((tab) => tab.name === selectedTab.name)}
                        onTabChange={(tabIndex) => handleOnTabChange(relevantTabs[tabIndex])}
                    />
                    <Stack padding={6} fullHeight fullWidth overflow='auto'>
                        <ContentItem
                            ref={contentRef}
                            id='wrapperREf'
                            reactTabOverflowHidden={selectedTab.reactTab?.overflowHidden}
                        >
                            {selectedTab.reactTab && (
                                <ErrorBoundary
                                    key={selectedTab.name}
                                    fallback={
                                        <EmptyState
                                            iconName='cloudGuard'
                                            label='Something went wrong'
                                            description='We were unable to load the content. Please try again later.'
                                        />
                                    }
                                >
                                    <Suspense
                                        fallback={
                                            <Stack fullHeight fullWidth justifyContent='center' alignItems='center'>
                                                <Spinner size={48} context='info' saturation />
                                            </Stack>
                                        }
                                    >
                                        {selectedTab.reactTab.wrapperComponent ? (
                                            <selectedTab.reactTab.wrapperComponent
                                                {...selectedTab.reactTab.wrapperComponentProps}
                                            >
                                                <selectedTab.reactTab.component
                                                    {...selectedTab.reactTab.componentProps}
                                                />
                                            </selectedTab.reactTab.wrapperComponent>
                                        ) : (
                                            <selectedTab.reactTab.component {...selectedTab.reactTab.componentProps} />
                                        )}
                                    </Suspense>
                                </ErrorBoundary>
                            )}
                        </ContentItem>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default EntityViewer;
