import React from 'react';
import CountBox from 'common/components/CountBox/CountBox';
import { FindingSeverityEnum, getSafeFindingSeverityInfo } from '../../consts/FindingSeverity';

type CountBoxSize = 'tiny' | 'standard' | 'extend';

interface FindingSeverityBoxProps {
    number: number;
    size: CountBoxSize;
    severity: FindingSeverityEnum;
    isAddCommas?: boolean;
    isAddShorthand?: boolean;
    onClick?: () => any;
}

export const FindingSeverityBox: React.FC<FindingSeverityBoxProps> = ({
    number,
    size,
    severity,
    isAddCommas,
    isAddShorthand,
    onClick,
}) => {
    return (
        <CountBox
            text={getSafeFindingSeverityInfo(severity).displayText}
            onClick={onClick}
            number={number}
            size={size}
            color={getSafeFindingSeverityInfo(severity).color}
            isAddCommas={isAddCommas}
            isAddShorthand={isAddShorthand}
        ></CountBox>
    );
};

export default FindingSeverityBox;
