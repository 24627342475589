import Ccpa from './Ccpa';
export { default as Ccpa } from './Ccpa';
import Cis from './Cis';
export { default as Cis } from './Cis';
import Csa from './Csa';
export { default as Csa } from './Csa';
import Gdpr from './Gdpr';
export { default as Gdpr } from './Gdpr';
import Hipaa from './Hipaa';
export { default as Hipaa } from './Hipaa';
import Iso27001 from './Iso27001';
export { default as Iso27001 } from './Iso27001';
import Nist from './Nist';
export { default as Nist } from './Nist';
import Nzism from './Nzism';
export { default as Nzism } from './Nzism';
import Pci from './Pci';
export { default as Pci } from './Pci';
import PciDss from './PciDss';
export { default as PciDss } from './PciDss';
import Soc2 from './Soc2';
export { default as Soc2 } from './Soc2';
import Soc from './Soc';
export { default as Soc } from './Soc';
import Alibaba from './Alibaba';
export { default as Alibaba } from './Alibaba';
import Aws from './Aws';
export { default as Aws } from './Aws';
import Azure from './Azure';
export { default as Azure } from './Azure';
import CheckpointInfinityPortal from './CheckpointInfinityPortal';
export { default as CheckpointInfinityPortal } from './CheckpointInfinityPortal';
import CloudGuard from './CloudGuard';
export { default as CloudGuard } from './CloudGuard';
import CpLogotypeFlat from './CpLogotypeFlat';
export { default as CpLogotypeFlat } from './CpLogotypeFlat';
import CpLogotype from './CpLogotype';
export { default as CpLogotype } from './CpLogotype';
import Docker from './Docker';
export { default as Docker } from './Docker';
import Gcp from './Gcp';
export { default as Gcp } from './Gcp';
import Github from './Github';
export { default as Github } from './Github';
import Gke from './Gke';
export { default as Gke } from './Gke';
import Harbor from './Harbor';
export { default as Harbor } from './Harbor';
import ImageScan from './ImageScan';
export { default as ImageScan } from './ImageScan';
import Jfrog from './Jfrog';
export { default as Jfrog } from './Jfrog';
import Kubernetes from './Kubernetes';
export { default as Kubernetes } from './Kubernetes';
import Oracle from './Oracle';
export { default as Oracle } from './Oracle';
import Quay from './Quay';
export { default as Quay } from './Quay';
import SonataNexus from './SonataNexus';
export { default as SonataNexus } from './SonataNexus';
import Terraform from './Terraform';
export { default as Terraform } from './Terraform';

export const AllIcons = {
  ccpa: Ccpa,
  cis: Cis,
  csa: Csa,
  gdpr: Gdpr,
  hipaa: Hipaa,
  iso27001: Iso27001,
  nist: Nist,
  nzism: Nzism,
  pci: Pci,
  pciDss: PciDss,
  soc2: Soc2,
  soc: Soc,
  alibaba: Alibaba,
  aws: Aws,
  azure: Azure,
  checkpointInfinityPortal: CheckpointInfinityPortal,
  cloudGuard: CloudGuard,
  cpLogotypeFlat: CpLogotypeFlat,
  cpLogotype: CpLogotype,
  docker: Docker,
  gcp: Gcp,
  github: Github,
  gke: Gke,
  harbor: Harbor,
  imageScan: ImageScan,
  jfrog: Jfrog,
  kubernetes: Kubernetes,
  oracle: Oracle,
  quay: Quay,
  sonataNexus: SonataNexus,
  terraform: Terraform,
};
