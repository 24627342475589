import React from 'react';
import CommandStyles from './Command.styles';
import { ICommandProps } from './Command.types';
import Stack from '../Stack/Stack';
import Typography from '../Typography/Typography';
import Spinner from '../Spinner/Spinner';

const Command = React.forwardRef<HTMLDivElement, ICommandProps>((props, ref) => {
    const { text, className, customLoadingElement, isLoading, children, maxHeight, fullHeight } = props;

    const [copyIcon, setCopyIcon] = React.useState<'copy' | 'check'>('copy');
    const commandDebounceTimeout = React.useRef<NodeJS.Timeout>();

    const handleCopyClick = () => {
        navigator.clipboard.writeText(text);
        setCopyIcon('check');
        clearTimeout(commandDebounceTimeout.current);
        commandDebounceTimeout.current = setTimeout(() => {
            setCopyIcon('copy');
        }, 3000);
    };

    return (
        <CommandStyles.Wrapper
            maxHeight={maxHeight}
            className={className}
            isLoading={isLoading}
            ref={ref}
            fullWidth
            fullHeight={fullHeight}
        >
            {isLoading ? (
                <Stack justifyContent='center' alignItems='center' fullWidth>
                    {customLoadingElement ? customLoadingElement : <Spinner size={16} />}
                </Stack>
            ) : (
                <>
                    {children ? children : <Typography variant='codeMd'>{text}</Typography>}
                    <CommandStyles.CopyBtn
                        onClick={handleCopyClick}
                        iconProps={{ name: copyIcon }}
                        size='small'
                        color='normal'
                        id='DS_Command_copy-btn'
                    />
                </>
            )}
        </CommandStyles.Wrapper>
    );
});
Command.displayName = 'Command';

export default Command;
