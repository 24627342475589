import { IProtectedAssetViewModel } from '../components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ICloudEntity } from '../module_interface/assets/ICloudEntity';
import { SpecificEntityFetcher } from './common.assets';
import { getHttpService } from '../interface/services';
import { toQueryString } from '../utils/http';
import { GenericObject } from '../interface/general';

export const getDefaultEntityFetcher = (url: string, idField: string): SpecificEntityFetcher => {
    return async (protectedAsset: IProtectedAssetViewModel): Promise<ICloudEntity | null> => {
        try {
            const data = toQueryString({
                query: {
                    externalId: protectedAsset.entityId,
                    cloudAccountId: protectedAsset.cloudAccountId,
                },
            });

            const result = await getHttpService().get<GenericObject<any>>(`${url}?${data}`);
            result.dome9Id = protectedAsset.id;
            result.id = result[idField];
            return result;
        } catch (error) {
            return null;
        }
    };
};
