import { forwardRef, useEffect, useState } from 'react';
import { Wrapper, Container } from './JsonViewer.styles';
import { IEntityViewerProps } from './JsonViewer.types';
import Stack from '../Stack';
import Command from '../Command';
import JsonRender from './Components/JsonRender/JsonRender';
import {
    scrollToMatch,
    isTypeArrayOrObject,
    extractSearchableItems,
    scrollToElementWithOffset,
} from './Components/JsonRender/helpers/JsonView.utils';
import JsonToolbar from './Components/JsonToolbar';

const JsonViewer = forwardRef<HTMLDivElement, IEntityViewerProps>((props, ref) => {
    const { data, dataAid, showFont, showSearch, showExpandAll, ...rest } = props;
    const [searchText, setSearchText] = useState('');
    const [fontSize, setFontSize] = useState<number>(12);
    const [collapseLvl, setCollapseLvl] = useState(0);
    const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
    const [matches, setMatches] = useState<Element[]>([]);
    const [matchIds, setMatchIds] = useState<string[]>([]);
    const [currentMatchId, setCurrentMatchId] = useState<string>();
    const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(0);
    const text = JSON.stringify(data);

    useEffect(() => {
        if (isTypeArrayOrObject(data)) {
            setOptions(extractSearchableItems(data));
        }
    }, [data]);

    const handleSearchSelect = (option: string) => {
        setSearchText(option);
        setCurrentMatchIndex(0);
        setCurrentMatchId('');
        const timeout = setTimeout(() => {
            const { matches, ids } = scrollToMatch(option);
            setMatches(matches);
            setMatchIds(ids);
            setCurrentMatchId(ids[0]);
            clearTimeout(timeout);
        }, 150);
    };

    const handleOpenSearch = () => {
        setCollapseLvl(10);
    };

    const handleFontIncrease = () => {
        if (fontSize >= 20) return;
        setFontSize(fontSize + 2);
    };

    const handleFontDecrease = () => {
        if (fontSize <= 12) return;
        setFontSize(fontSize - 2);
    };

    const onCollapseAll = () => {
        setCollapseLvl(0);
    };

    const onExpandAll = () => {
        setCollapseLvl(100);
    };

    const scrollToNextMatch = () => {
        if (!matches.length) return;
        const currentMatchIdx = (currentMatchIndex + 1) % matches.length;
        setCurrentMatchIndex(currentMatchIdx);
        scrollToElementWithOffset(matches[currentMatchIdx], 150);
        setCurrentMatchId(matchIds[currentMatchIdx]);
    };

    const scrollToPrevMatch = () => {
        if (!matches.length) return;
        const currentMatchIdx = (currentMatchIndex - 1 + matches.length) % matches.length;
        setCurrentMatchIndex(currentMatchIdx);
        scrollToElementWithOffset(matches[currentMatchIdx], 150);
        setCurrentMatchId(matchIds[currentMatchIdx]);
    };

    return (
        <Wrapper ref={ref} alignItems='flex-start' direction='row' {...rest} data-aid={dataAid} fullHeight>
            <Stack fullWidth direction={'column'} alignItems={'flex-start'} spacing={2} padding={[1]} fullHeight>
                <JsonToolbar
                    showFont={showFont}
                    showSearch={showSearch}
                    showExpandAll={showExpandAll}
                    selectOptions={options}
                    matches={matches}
                    currentMatchIndex={currentMatchIndex}
                    onSelectOpen={handleOpenSearch}
                    onSelectChange={handleSearchSelect}
                    onFontIncrease={handleFontIncrease}
                    onFontDecrease={handleFontDecrease}
                    onCollapseAll={onCollapseAll}
                    onExpandAll={onExpandAll}
                    onPrevMatch={scrollToPrevMatch}
                    onNextMatch={scrollToNextMatch}
                />
                <Container
                    fullWidth
                    fullHeight
                    overflow='hidden'
                    direction={'row'}
                    alignItems={'flex-start'}
                    padding={[2, 0, 0]}
                >
                    <Command text={text} fullHeight>
                        <JsonRender
                            data={data}
                            searchTerm={searchText}
                            collapseLevel={collapseLvl}
                            fontSize={fontSize}
                            currentMatchId={currentMatchId}
                        />
                    </Command>
                </Container>
            </Stack>
        </Wrapper>
    );
});

JsonViewer.displayName = 'JsonViewer';

export default JsonViewer;
