import i18n from 'i18next';
import { CompoundFilterLogicalOperator, ICompoundFilterNode } from '../custom/FilterTree/CompoundFilter';
import { IFilterCondition } from '../custom/FilterTree/FilterCondition';
import {
    IDisplayMappingObject,
    IMultiSelectFilter,
    IMultiSelectFilterSortInfo,
} from '../../components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { Aggregations } from '../../components/FilterPanel/FilterPanel.interface';
import { GenericObject } from '../../interface/general';
import _ from 'lodash';
import { isNil } from '../../utils/helpFunctions';
import { getSafeFindingSeverityInfo, getFindingSeverityOptions } from '../../consts/FindingSeverity';
import { SelectOption } from '../../design-system/components-v2/SelectV2/Select.types';
import {
    getDisplayMappingFromOptions,
    getFilterValuesInitialDataWithOptions,
} from '../custom/ClientFilterPageTable/ClientFilterPageTable.filters';

export const getYesNoDisplayMapping = (): IDisplayMappingObject => ({
    true: {
        displayText: i18n.t('GENERAL.YES'),
    },
    false: {
        displayText: i18n.t('GENERAL.NO'),
    },
});

export const getCleanCompoundFilter = (conditions?: IFilterCondition[]): ICompoundFilterNode | undefined => {
    if (!conditions || (conditions.length === 0)) {
        return;
    }

    return {
        logicalOperator: CompoundFilterLogicalOperator.AND,
        operands: conditions,
    };
};

export const ensureDefaultBooleanAggregations = (aggregations: Aggregations, fieldName: string): void => {
    for (const currKey of [true, false]) {
        if (!aggregations[fieldName]?.find((item) => currKey === item.value)) {
            aggregations[fieldName]?.push({ value: currKey, count: 0 });
        }
    }
};

export const removeEmptyValues = (obj?: GenericObject<any>): GenericObject<any> | undefined => {
    if (!obj) {
        return;
    }

    const result: GenericObject<any> = {};
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (!_.isEmpty(value)) {
            result[key] = value;
        }
    });
    return Object.keys(result).length > 0 ? result : undefined;
};

export const getFindingSeverityFilterSortInfo = (): IMultiSelectFilterSortInfo => ({
    comparer: (option1: any, option2: any) => {
        const val1: number = (isNil(option1?.value) ? 0 : getSafeFindingSeverityInfo(option1.value).level);
        const val2: number = (isNil(option2?.value) ? 0 : getSafeFindingSeverityInfo(option2.value).level);
        return val2 - val1;
    },
    label: i18n.t('ERM_COMPONENTS.FILTERS.CUSTOM_SORT.SORT_BY_SEVERITY'),
});

export const getFindingSeverityFilterProps = (aggregations: Aggregations, field: string): Partial<IMultiSelectFilter> => {
    const options: SelectOption[] = getFindingSeverityOptions();
    return {
        initialData: getFilterValuesInitialDataWithOptions(aggregations, field, options),
        displayMapping: getDisplayMappingFromOptions(options),
        customSortInfo: getFindingSeverityFilterSortInfo(),
    };
};

