import React, { useEffect } from 'react';
import { DoneInterface } from './Done.interface';
import { Typography } from '../../../design-system/components-v2';

export const Done: React.FC<DoneInterface> = ({ onValidate, message, hasError = false }) => {
    useEffect(() => {
        onValidate(!hasError, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError]);
    return (
        <div data-aid='wizard-done'>
            <Typography variant={'h1'}>{message}</Typography>
        </div>
    );
};
