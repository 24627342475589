import React, { useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { ICloudAccount, getCloudAccountsService } from 'common/interface/data_services';

interface UseAllCloudAccountsInterface {
    allCloudAccounts: Array<ICloudAccount>;
    getByAccountId: (accountId: string) => ICloudAccount | undefined;
    isLoading: boolean;
}

type UseAllCloudAccounts = () => UseAllCloudAccountsInterface;
const useAllCloudAccountsImple: UseAllCloudAccounts = (): UseAllCloudAccountsInterface => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [allCloudAccounts, setAllCloudAccounts] = React.useState<Array<ICloudAccount>>([]);

    const updateCloudAccounts = (allCloudAccounts: Array<ICloudAccount>) => {
        setAllCloudAccounts(allCloudAccounts);
    };

    const getByAccountId = React.useCallback(
        (accountId: string) => {
            return allCloudAccounts.find((account) => account.id === accountId);
        },
        [allCloudAccounts],
    );

    useEffect(() => {
        const subscribeKey = getCloudAccountsService().subscribe((newState) => {
            updateCloudAccounts(newState);
            setIsLoading(false);
        });
        return () => {
            getCloudAccountsService().unsubscribe(subscribeKey);
        };
    }, []);

    return {
        allCloudAccounts,
        getByAccountId,
        isLoading,
    };
};

const useAllCloudAccounts: UseAllCloudAccounts = singletonHook(
    { allCloudAccounts: [], getByAccountId: () => undefined, isLoading: true },
    useAllCloudAccountsImple,
);
export default useAllCloudAccounts;
