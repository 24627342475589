import { IOverviewPanelCategory, IOverviewPanelCategoryItem } from './ProtectedAssets';
import { buildPath, globalAddinContainer } from '../../extensibility/AddinContainer';
import { ICloudEntityData } from './ICloudEntity';
import { PATH_PROTECTED_ASSETS_PAGE } from './ProtectedAssets.consts';

const PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_OVERVIEW_PANEL_CATEGORIES = buildPath(
    PATH_PROTECTED_ASSETS_PAGE,
    'asset details panel',
    'overview',
    'categories',
);

export const OverviewPanelRegistry = {
    addCategory: (
        categoryId: string,
        position: number,
        titleGetter: () => string,
        isRelevant?: (entity: ICloudEntityData) => boolean,
        permission?: () => boolean,
    ) => {
        globalAddinContainer.add<IOverviewPanelCategory>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_OVERVIEW_PANEL_CATEGORIES,
            [
                {
                    id: categoryId,
                    position,
                    content: (): IOverviewPanelCategory => {
                        return {
                            id: categoryId,
                            title: titleGetter(),
                            isRelevant,
                        };
                    },
                    permission,
                },
            ],
        );
    },

    addCategoryItemByPath: (
        path: string,
        itemId: string,
        position: number,
        item: IOverviewPanelCategoryItem,
        permission?: () => boolean,
    ) => {
        globalAddinContainer.add<IOverviewPanelCategoryItem>(path, [
            {
                id: itemId,
                position,
                content: item,
                permission,
            },
        ]);
    },

    addCategoryItem: (
        itemId: string,
        categoryId: string,
        position: number,
        item: IOverviewPanelCategoryItem,
        permission?: () => boolean,
    ) => {
        OverviewPanelRegistry.addCategoryItemByPath(
            buildPath(PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_OVERVIEW_PANEL_CATEGORIES, categoryId),
            itemId,
            position,
            item,
            permission,
        );
    },

    getCategories: (): IOverviewPanelCategory[] => {
        return globalAddinContainer.get<IOverviewPanelCategory>(
            PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_OVERVIEW_PANEL_CATEGORIES,
        );
    },

    getCategoryItems: (categoryId: string): IOverviewPanelCategoryItem[] => {
        return globalAddinContainer.get<IOverviewPanelCategoryItem>(
            buildPath(PATH_PROTECTED_ASSETS_PAGE_ASSET_DETAILS_OVERVIEW_PANEL_CATEGORIES, categoryId),
        );
    },
};
