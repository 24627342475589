import { forwardRef } from 'react';
import ButtonStyles from './IconButton.styles';
import { IIconButtonProps } from './IconButton.types';

const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>((props, ref) => {
    return <ButtonStyles.Wrapper {...props} variant={props.variant || 'text'} iconButton ref={ref} />;
});
IconButton.displayName = 'IconButton';

export default IconButton;
