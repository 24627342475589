import { TFunction } from 'i18next';
import { EntityTypesEnum } from './exclusions.interfaces';

export const I18nExclusion = 'exclusion';

export const modalPrefixAnalytics = 'exclusion-modal';

export enum VENDORS_FOR_ICONS {
    AWS = 'aws',
    AZURE = 'azure',
    GCP = 'gcp',
    GOOGLE = 'google',
    KUBERNETES = 'kubernetes',
    KUBERNETES_RUNTIME_ASSURANCE = 'kubernetesruntimeassurance',
    IMAGE_ASSURANCE = 'imageassurance',
    ALIBABA = 'alibaba',
    OCI = 'oci',
    ORACLE = 'oracle',
}

export enum EXCLUSION_SELECTED_TYPE {
    ORGANIZATIONAL_UNIT = 'organizationalUnit',
    ENVIRONMENT = 'environment',
}

export enum ENTITY_FILTER_BY_KEY {
    ORGANIZATIONAL_UNIT = 'organizationalUnitId',
    ENVIRONMENT = 'cloudAccountId',
}

export enum FindingSeverityEnum {
    CRITICAL = 'Critical',
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low',
    INFORMATIONAL = 'Informational',
}

export enum TagsEnum {
    KEY = 'key',
    VALUE = 'value',
}

export const rangeType = 'range';

export const entityTypes = (t: TFunction) => [
    {
        dataAid: 'entityType-name',
        value: EntityTypesEnum.byName,
        name: 'entityTypes',
        label: t('MODAL.TOPICS.ENTITY.ENTITY_NAME'),
    },
    {
        dataAid: 'entityType-id',
        value: EntityTypesEnum.byID,
        name: 'entityTypes',
        label: t('MODAL.TOPICS.ENTITY.ENTITY_ID'),
    },
];

export const FindingTypes = {
    Package: 'Package',
    Malware: 'Malware',
    InsecureContent: 'InsecureContent',
};

export const scannedFindingType = (t: TFunction) => [
    {
        dataAid: 'package-asset-type',
        value: FindingTypes.Package,
        name: 'scannedAssetTypes',
        label: t('MODAL.TOPICS.FINDING_TYPE.PACKAGE'),
    },
    {
        dataAid: 'malware-asset-type',
        value: FindingTypes.Malware,
        name: 'scannedAssetTypes',
        label: t('MODAL.TOPICS.FINDING_TYPE.MALWARE'),
    },
    {
        dataAid: 'insecure-content-asset-type',
        value: FindingTypes.InsecureContent,
        name: 'scannedAssetTypes',
        label: t('MODAL.TOPICS.FINDING_TYPE.INSECURE_CONTENT'),
    },
];

export const scannedFindingTypeInputs = (t: TFunction) => {
    return {
        [FindingTypes.Package]: [
            {
                name: t('MODAL.TOPICS.FINDING_TYPE.NAME'),
                propName: 'name',
                value: '',
                gsl: (value: string) => {
                    return `name like '${value}' and category = 'Package'`;
                },
            },
            {
                name: t('MODAL.TOPICS.FINDING_TYPE.VERSION'),
                propName: 'version',
                value: '',
                gsl: (value: string) => {
                    return `version like '${value}' and category = 'Package'`;
                },
            },
            {
                name: t('MODAL.TOPICS.FINDING_TYPE.PATH'),
                propName: 'package-manager.path',
                value: '',
                gsl: (value: string) => {
                    return `package-manager.path like '${value}' and category = 'Package'`;
                },
            },
        ],
        [FindingTypes.Malware]: [
            {
                name: t('MODAL.TOPICS.FINDING_TYPE.NAME'),
                propName: 'name',
                value: '',
                gsl: (value: string) => {
                    return `name like '${value}' and category = 'Malware'`;
                },
            },
            {
                name: t('MODAL.TOPICS.FINDING_TYPE.PATH'),
                propName: 'file-path',
                value: '',
                gsl: (value: string) => {
                    return `files contain [file-path like '${value}'] and category = 'Malware'`;
                },
            },
        ],
        [FindingTypes.InsecureContent]: [
            {
                name: t('MODAL.TOPICS.FINDING_TYPE.PATH'),
                propName: 'file-path',
                value: '',
                gsl: (value: string) => {
                    return `files contain [file-path like '${value}'] and category = 'InsecureContent'`;
                },
            },
            {
                name: t('MODAL.TOPICS.FINDING_TYPE.PAYLOAD'),
                propName: 'payload-sha256',
                value: '',
                gsl: (value: string) => {
                    return `files contain [contents contain [payload-sha256 like '${value}']] and category = 'InsecureContent'`;
                },
            },
        ],
    };
};
