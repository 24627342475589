/* eslint-disable react/prop-types */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef } from 'react';
import { IGenericWidgetDataItem, IGenericWidgetDataItemSet } from '../../module_interface/overview/Interface';
import { IAreaWidget } from '../Widgets/Area/AreaWidget';
import { profileComponent } from '../profileComponent';

/**
     * TODO notes here

*/

const WidgetArea: React.FC<IAreaWidget> = ({ data }) => {
    const items = data?.items;
    const options = data?.options;
    const chartComponent = useRef<any>(null);
    let isDataSet;

    const parseTrendData = (items: IGenericWidgetDataItemSet[] | IGenericWidgetDataItem[]) => {
        const getNumberRange = (trendDataObjValue: any[]) => {
            let numberRange: number[] = [];
            trendDataObjValue.forEach((item) => {
                numberRange = numberRange.concat(item.data);
            });
            numberRange = numberRange.filter((n) => n);
            return numberRange;
        };

        const getMinValue = (trendDataObjValue: any[]) => {
            let min = 0;
            const numberRange = getNumberRange(trendDataObjValue);
            if (numberRange.length) {
                min = Math.min(...numberRange) - 2;
                if (min < 0) {
                    min = 0;
                }
            }
            return min;
        };

        const getMaxValue = (trendDataObjValue: any[]) => {
            let max = 0;
            const numberRange = getNumberRange(trendDataObjValue);
            if (numberRange.length) {
                max = Math.max(...numberRange) + 2;
                if (max > 100) {
                    max = 100;
                }
            }
            return max;
        };

        const trendDataObjValue = [] as any;
        const keys: string[] = [];

        items?.forEach((item) => {
            const values: number[] = [];
            if (Array.isArray(item.value)) {
                item.value.forEach((value) => {
                    values.push(value.value as number);
                    keys.push(value.key);
                });
            } else {
                values.push(item.value as number);
                keys.push(item.key);
            }
            trendDataObjValue.push({ name: item.key, data: values });
        });

        isDataSet = trendDataObjValue.length > 1;

        return {
            yAxis: {
                labels: {
                    format: '{value}',
                },
                max:
                    trendDataObjValue?.length > 0 && trendDataObjValue[0]?.data?.length > 0
                        ? getMaxValue(trendDataObjValue)
                        : 100,
                min:
                    trendDataObjValue?.length > 0 && trendDataObjValue[0]?.data?.length > 0
                        ? getMinValue(trendDataObjValue)
                        : 0,
            },
            xAxis: {
                categories: keys,
            },
            series: trendDataObjValue,
            legend: {
                enabled: true,
            },
            ...options,
        };
    };

    const parsedData = items && parseTrendData(items);

    const defaultLegend = {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        itemStyle: {
            fontWeight: '400',
            color: 'var(--color-fg-default)',
            fontFamily: 'OpenSans',
        },
        x: 0,
        y: 0,
    };

    const defaultTooltip = {}; // TBD

    const _options = {
        chart: {
            type: 'areaspline',
            backgroundColor: 'transparent',
        },
        legend: parsedData?.legend || defaultLegend,
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            lineWidth: 1,
            labels: {
                style: {
                    fontSize: 11,
                },
            },
            ...parsedData?.xAxis,
        },
        yAxis: {
            gridLineDashStyle: 'Dash',
            lineWidth: 1,
            title: {
                text: '',
            },
            labels: {
                style: {
                    fontSize: 11,
                },
            },
            ...parsedData?.yAxis,
        },
        plotOptions: {
            areaspline: {
                fillOpacity: isDataSet ? 0 : 0.9,
            },
        },
        series: parsedData?.series,
        tooltip: parsedData?.tooltip || defaultTooltip,
    };

    useEffect(() => {
        const chart = chartComponent.current?.chart;

        if (chart) chart.reflow(false);
    }, [data]);

    return (
        <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            options={_options}
            containerProps={{
                style: {
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                    height: '100%',
                },
            }}
        />
    );
};

export default profileComponent(WidgetArea);
