import { lazy } from 'react';
import { addMainMenuItem } from '../../helpers/menu';
import { isAfErmComponentsExamples } from '../../utils/debugUtils';
import { IReactPageRoute } from '../../interface/routing';
import { initializeRecipeEditorDrawer } from './RecipeFormExample/RecipeEditor.initialize';
import { getAppRootRegistry } from 'common/interface/services';

const RECIPES_FORM_EXAMPLE_PAGE_URL = '/risk-management/custom-form-example';
const CUSTOM_TREE_EXAMPLE_PAGE_URL = '/risk-management/custom-tree-example';
const GSL_QUERY_EXAMPLE_PAGE_URL = '/gsl-query-examples';

const initializeMenu = () => {
    addMainMenuItem({
        id: 'erm-components-example',
        position: 0,
        icon: 'risk-management_4',
        label: 'ERM Examples',
        permission: () => isAfErmComponentsExamples(),
        sections: [
            {
                id: 'default',
                position: 0,
                items: [
                    {
                        id: 'recipes form example',
                        position: 0,
                        label: 'Recipes Form',
                        state: RECIPES_FORM_EXAMPLE_PAGE_URL,
                        permission: () => isAfErmComponentsExamples(),
                    },
                    {
                        id: 'custom tree example',
                        position: 10,
                        label: 'Custom Tree',
                        state: CUSTOM_TREE_EXAMPLE_PAGE_URL,
                        permission: () => isAfErmComponentsExamples(),
                    },
                    {
                        id: 'gsl query examples',
                        position: 30,
                        label: 'GSL Query Examples',
                        state: GSL_QUERY_EXAMPLE_PAGE_URL,
                        permission: () => isAfErmComponentsExamples(),
                    }
                ],
            },

        ],
    });

    const recipesFormExamplePage = (): IReactPageRoute => {
        return {
            condition: isAfErmComponentsExamples(),
            component: lazy(() => import('./RecipeFormExample/RecipeEditorPage/RecipeEditorPage')),
            path: RECIPES_FORM_EXAMPLE_PAGE_URL,
        };
    };

    const customTreeExamplePage = (): IReactPageRoute => {
        return {
            condition: isAfErmComponentsExamples(),
            component: lazy(() => import('./CustomTreeExample/CustomTreeExamplePage')),
            path: CUSTOM_TREE_EXAMPLE_PAGE_URL,
        };
    };

    const gslQueryExamplePage = (): IReactPageRoute => {
        return {
            condition: isAfErmComponentsExamples(),
            component: lazy(() => import('./GslQueryExample/GslQueryFilterExample')),
            path: GSL_QUERY_EXAMPLE_PAGE_URL,
        };
    };

    getAppRootRegistry().addRoutes([
        { id: 'RECIPES_FORM_EXAMPLE_PAGE', content: recipesFormExamplePage },
        { id: 'CUSTOM_TREE_EXAMPLE_PAGE_URL', content: customTreeExamplePage },
        { id: 'GSL_QUERY_EXAMPLE_PAGE', content: gslQueryExamplePage },
    ]);
};

export const initializeErmComponentsExamples = () => {
    initializeMenu();
    initializeRecipeEditorDrawer();
};