import { useState, useRef, useEffect } from 'react';

interface HoverState {
    hovered: boolean;
    targetRef: React.RefObject<any>;
}

const useHover = (): HoverState => {
    const [hovered, setHovered] = useState(false);
    const targetRef = useRef<any>(null);

    useEffect(() => {
        const node = targetRef.current as HTMLElement;

        if (!node) return;

        const handleMouseEnter = () => setHovered(true);
        const handleMouseLeave = () => setHovered(false);

        node.addEventListener('mouseenter', handleMouseEnter);
        node.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            node.removeEventListener('mouseenter', handleMouseEnter);
            node.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    return { targetRef, hovered };
};

export default useHover;
