import styled from 'styled-components';
import Stack from '../Stack';

const Wrapper = styled(Stack)`
    background-color: ${({ theme }) => theme.palette.surface.primary};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    min-height: 0%;
`;

const WizardStyles = {
    Wrapper,
};

export default WizardStyles;
