import {
    ACTIVE_FEATURE_PAGE_MANAGER_KEY,
    IActiveFeatureInfo,
} from 'common/registries/ActiveFeatureManager/ActiveFeatures';
import { isNonProd } from 'common/utils/RuntimeEnvironment';
import { ActiveFeaturesRegistry } from 'common/registries/ActiveFeatureManager/ActiveFeaturesRegistry';

export function updateLocalStorageActiveFeature(key: string, isActive: boolean): void {
    if (isActive) {
        localStorage.setItem(key, 'true');
    } else {
        localStorage.removeItem(key);
    }
}

export const getAllActiveFeatures = () => ActiveFeaturesRegistry.getAllActiveFeaturesInfos();

export const isPermittedActiveFeature = (activeFeatureInfo: IActiveFeatureInfo): boolean => {
    return !activeFeatureInfo.nonProd || isNonProd();
};

export const isPermittedActiveFeatureKey = (key: string): boolean => {
    if (key === ACTIVE_FEATURE_PAGE_MANAGER_KEY) {
        return true;
    }

    const afInfo: IActiveFeatureInfo | undefined = getAllActiveFeatures().find((af) => af.key === key);
    if (!afInfo) {
        return true;
    }
    return isPermittedActiveFeature(afInfo);
};
