import { IDashboardWidget, IGenericWidgetDataItem, NavigateOption } from '../../module_interface/overview/Interface';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    IAggregationDataItem,
    IOrganizationalUnitAllChildrens,
} from '../../interface/data_services';
import { getVendor, IVendor, VENDORS } from '../../consts/vendors';
import { buildProtectedAssetsWidgetLink, limitDataItems } from './helpers';
import i18n from 'i18next';
import { changeUrl } from '../../utils/http';
import { getProtectedAssetsService } from '../../module_interface/assets/ProtectedAssets';
import { IAsset } from '../../assets/common.assets';
import { getCloudAccountIdAggregationLink } from 'common/module_interface/overview/Utils';
import { EventType } from 'common/module_interface/events/EventsConsts';
import { getEventsTableWidgetService } from 'common/module_interface/events/EventsTableRegistry';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

const defaultIcon: AllIconsName = 'cloud';
const PROTECTED_ASSETS_BASE_URL = '/protected-asset/index';
const CLOUD_ACCOUNT_BASE_URL = '/cloud-account';

export const getPlatformDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((platform) => {
        const vendorData = VENDORS.find(
            (item) =>
                item.elasticVendorType === platform.value ||
                item.name === platform.value ||
                item.uri === platform.value,
        ) as IVendor;
        return {
            key: vendorData.displayName || vendorData.name || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: platform.count,
            icon: vendorData.newIcon || defaultIcon,
            onclick: () =>
                changeUrl(
                    getEventsTableWidgetService().getMainTableLink(
                        EventType.ALL,
                        `%7B"sorting":%7B"fieldName":"createdTime","direction":-1%7D,"filter":%7B"fields":%5B%7B"name":"organizationalUnitId","value":"00000000-0000-0000-0000-000000000000"%7D,%7B"name":"cloudAccountType","value":"${vendorData.elasticVendorType}"%7D%5D,"freeTextPhrase":""%7D%7D&time=%7B"time":"All"%7D`,
                    ),
                ),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getRegionDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.GLOBAL'),
            value: item.count,
            icon: defaultIcon,
            onclick: () =>
                changeUrl(
                    getEventsTableWidgetService().getMainTableLink(
                        EventType.ALL,
                        `%7B"sorting":%7B"fieldName":"createdTime","direction":-1%7D,"filter":%7B"fields":%5B%7B"name":"organizationalUnitId","value":"00000000-0000-0000-0000-000000000000"%7D,%7B"name":"region","value":"${item.value}"%7D%5D,"freeTextPhrase":""%7D%7D&time=%7B"time":"All"%7D`,
                    ),
                ),
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};

export const getAssetTypeDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation.map((aggregationDataItem) => {
        const asset = getProtectedAssetsService().getAssetByType(aggregationDataItem.value) as IAsset;
        return {
            key: asset?.displayName || aggregationDataItem.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: aggregationDataItem.count,
            icon: asset?.icon,
            onclick: () => changeUrl(buildProtectedAssetsWidgetLink(widget, aggregationDataItem)),
        };
    });
    return {
        items: limitDataItems(items, widget.limit || items.length),
    };
};

export const getCloudAccountIdDisplayData = async (
    selectedAggregation: IAggregationDataItem[],
    widget: IDashboardWidget,
) => {
    const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
    const cloudAccountItems = selectedAggregation.reduce(
        (result: IGenericWidgetDataItem[], aggItem: IAggregationDataItem) => {
            const selectedCloudAccount = allCloudAccounts.find(
                (cloudAccount: any) => aggItem.value === cloudAccount.id,
            );
            if (selectedCloudAccount) {
                const vendor = getVendor(selectedCloudAccount.platform);
                const link =
                    widget.options.navigateOption === NavigateOption.ENVIRONMENT
                        ? getCloudAccountIdAggregationLink(
                              widget,
                              selectedCloudAccount.id,
                              CLOUD_ACCOUNT_BASE_URL,
                              selectedCloudAccount.platform,
                          )
                        : getCloudAccountIdAggregationLink(widget, selectedCloudAccount.id, PROTECTED_ASSETS_BASE_URL);
                result.push({
                    key: selectedCloudAccount?.name || aggItem.value || i18n.t('EVENTS.NOT_AVAILABLE'),
                    value: aggItem.count,
                    icon: vendor?.newIcon || defaultIcon,
                    onclick: () => changeUrl(link!),
                });
            }
            return result;
        },
        [],
    );
    return {
        items: limitDataItems(cloudAccountItems, widget.limit || cloudAccountItems.length),
    };
};

// selectedAggregation is aggregation of cloudAccountId, so we need to get the OU of each cloudAccountId and aggregate by OU
export const getOrganizationalUnitDisplayData = async (
    selectedAggregation: IAggregationDataItem[],
    widget: IDashboardWidget,
) => {
    const indexedCloudAccounts = await getCloudAccountsService().getIndexedCloudAccounts();

    const flatOUs = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithAllChildrens(true);
    const indexedOUs = flatOUs.reduce<{ [ouId: string]: IOrganizationalUnitAllChildrens }>((result, ou) => {
        result[ou.id] = ou;
        return result;
    }, {});

    const aggregatedByOU = selectedAggregation.reduce<{ [ouId: string]: IAggregationDataItem }>((result, aggItem) => {
        const cloudAccount = indexedCloudAccounts[aggItem.value];
        if (cloudAccount) {
            const ouId = cloudAccount.organizationalUnitId;
            if (result[ouId]) {
                result[ouId].count += aggItem.count;
            } else {
                result[ouId] = {
                    count: aggItem.count,
                    value: ouId,
                };
            }
        }
        return result;
    }, {});

    const items: IGenericWidgetDataItem[] = Object.values(aggregatedByOU)
        .sort((a, b) => {
            return b.count - a.count;
        })
        .map((aggItem) => {
            return {
                key: indexedOUs[aggItem.value]?.name || aggItem.value || 'N/A',
                value: aggItem.count,
                onclick: () => changeUrl(buildProtectedAssetsWidgetLink(widget, aggItem)),
            };
        });

    return {
        items: widget.limit && limitDataItems(items, widget.limit),
    };
};
