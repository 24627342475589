import React, { useMemo } from 'react';
import { IMultiSelectorEntity, IMultiSelectorItem, IMultiSelectorSettings } from './MultiSelector.interface';
import { getAllListItems } from './MultiSelector.utils';
import MultiSelectorDropdown from './MultiSelectorDropdown';

const MultiSelectorListDropdown: React.FC<{
    entities?: IMultiSelectorEntity[];
    readOnly?: boolean;
    selectedIds?: string[];
    nonSelectableIds?: string[];
    onSelectionChanged: (selectedIds: string[]) => void;
    settings?: IMultiSelectorSettings;
    nonSelectableReason?: string;
}> = ({
    entities,
    readOnly,
    selectedIds = [],
    nonSelectableIds,
    onSelectionChanged,
    settings,
    nonSelectableReason,
}) => {
    const listItems: IMultiSelectorItem[] = useMemo(() => (entities ? getAllListItems(entities) : []), [entities]);
    return (
        <MultiSelectorDropdown
            isTree={false}
            items={listItems}
            readOnly={readOnly}
            selectedIds={selectedIds}
            nonSelectableIds={nonSelectableIds}
            nonSelectableReason={nonSelectableReason}
            onSelectionChanged={onSelectionChanged}
            settings={settings}
        />
    );
};

export default MultiSelectorListDropdown;
