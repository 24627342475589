import { IOrganizationalUnit, getOrganizationalUnitService } from 'common/interface/data_services';
import { useCallback, useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const flatten = (arr: Array<IOrganizationalUnit>): Array<IOrganizationalUnit> => {
    return arr.reduce<Array<IOrganizationalUnit>>((acc, org) => {
        const { children, ...rest } = org;
        acc.push(rest);
        if (children) {
            acc.push(...flatten(children));
        }
        return acc;
    }, []);
};

export interface UseOrganizationalUnitsPayload {
    isLoading: boolean;

    organizationalUnits: Array<IOrganizationalUnit>;
}

export type UseOrganizationalUnits = () => UseOrganizationalUnitsPayload;

const useOrganizationalUnitsImpl: UseOrganizationalUnits = () => {
    const [organizationalUnits, setOrganizationalUnits] = useState<Array<IOrganizationalUnit>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getAndSetAllOrganizationalUnits = useCallback(async () => {
        setIsLoading(true);
        const getAllOrganizationalUnitsRes = await getOrganizationalUnitService().getOrganizationalUnitsView();
        setOrganizationalUnits(flatten([getAllOrganizationalUnitsRes]));
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getAndSetAllOrganizationalUnits();
    }, [getAndSetAllOrganizationalUnits]);

    return { isLoading, organizationalUnits };
};

export const useOrganizationalUnits: UseOrganizationalUnits = singletonHook(
    { isLoading: true, organizationalUnits: [] },
    useOrganizationalUnitsImpl,
);
