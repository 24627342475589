import { useState, useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';

interface Size {
    width: number;
    height: number;
}

type UseDocumentSize = () => Size;
const useDocumentSizeImpl: UseDocumentSize = (): Size => {
    const debounceTime = 100;
    const [size, setSize] = useState<Size>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        const handleResize = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                setSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }, debounceTime);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [debounceTime]);

    return size;
};

const useDocumentSize: UseDocumentSize = singletonHook({ width: 0, height: 0 }, useDocumentSizeImpl);
export default useDocumentSize;
