import styled from 'styled-components';
import Stack from '../Stack/Stack';
import Button from '../Button/Button';

const TitleIconWrapper = styled(Stack)`
    flex-grow: 1;
    overflow: hidden;

    * {
        overflow: hidden;
    }
`;

const TitleWrapper = styled.div`
    min-width: 50px;
    & p {
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

interface BlockContainerProps {
    isOpen: boolean;
    disablePaddingTop?: boolean;
}
const BlockContainer = styled.div<BlockContainerProps>`
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 24px 32px;

    transition:
        max-height 0.05s linear,
        padding-top 0.1s linear,
        opacity 0.1s linear;
    max-height: ${(props) => (props.isOpen ? '100%' : '0px')};
    padding: ${(props) => (props.isOpen && !props.disablePaddingTop ? '16px 0 0 0' : '0px')};
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
`;

const ToggleButton = styled(Button)<{ isOpen: boolean }>`
    & svg {
        transform: rotate(${(props) => (props.isOpen ? '90deg' : '0')});
    }
`;

const EntityHeaderStyled = {
    TitleIconWrapper,
    TitleWrapper,
    BlockContainer,
    ToggleButton,
};

export default EntityHeaderStyled;
