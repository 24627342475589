import { forwardRef } from 'react';
import { WizardProps } from './Wizard.types';
import WizardHeader from './WizardHeader';
import WizardContent from './WizardContent';
import WizardStyles from './Wizard.styled';
import WizardFooter from './WizardFooter';

const Wizard = forwardRef<HTMLDivElement, WizardProps>((props, ref) => {
    const { title, content, onClose, info, stepperProps, footerActions } = props;

    return (
        <WizardStyles.Wrapper ref={ref}>
            <WizardHeader title={title} onClose={onClose} info={info} />
            <WizardContent stepperProps={stepperProps}>{content}</WizardContent>
            {footerActions && <WizardFooter>{footerActions}</WizardFooter>}
        </WizardStyles.Wrapper>
    );
});

Wizard.displayName = 'Wizard';

export default Wizard;
