import React, { useEffect } from 'react';
import { Stack, Typography } from 'common/design-system/components-v2';
import Card from './Card';
import { Vendors } from '../../consts/vendors';
import { useTranslation } from 'react-i18next';
import { OnValidate } from '../Wizard/Wizard';

const PreselectedPlatformRulesetComponent: React.FC<{
    onValidate: OnValidate;
    rulesetName?: string;
    platformName?: string;
}> = ({ onValidate, rulesetName = '', platformName = '' }) => {
    const { t } = useTranslation();
    useEffect(() => {
        onValidate(true);
        // TODO: fix dependencies - if decide to ignore explain why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Stack spacing={10}>
            <Card selected={true} title={platformName} icon={platformName} id={Vendors.GENERIC} />
            <Stack>
                <Typography>{t('GENERAL.RULESET_NAME')}</Typography>
                <Typography variant={'bodyLg'}>{rulesetName}</Typography>
            </Stack>
        </Stack>
    );
};

export default PreselectedPlatformRulesetComponent;
