import { getProtectedAssetsService, IProtectedAssetDetailProvider } from './ProtectedAssets';
import { emptyPaneFieldInfo, InfoPaneFieldProps } from '../../components/InfoPane/InfoPane';
import { ICloudEntity } from './ICloudEntity';
import i18next from 'i18next';
import EnvironmentLink from '../../components/ProtectedAssets/Renderers/EnvironmentLink';
import { getRegionByName, getVendorDisplayName } from '../../consts/vendors';
import { isString } from '../../utils/helpFunctions';
import { Chip, ComponentOverflow } from 'common/design-system/components-v2';
import { IProtectedAssetViewModel } from '../../components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ICloudAccount } from '../../interface/data_services';

export const ASSETS_SEARCH_URL = 'protected-asset/search';
export const ASSETS_GROUPING_URL = 'protected-asset/group-by-properties';

export const ASSETS_EXPORT_CSV_EMAIL = 'protectedAssetsReport/exportToCsv';
export const ASSETS_EXPORT_CSV_DOWNLOAD = 'protectedAssetsReport/exportToCsv/downloadCSV';

export interface IAssetFieldsMap {
    nameField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    typeField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    idField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    internalIdField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    createdDateField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    environmentField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    vpcField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    regionField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    descriptionField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    platformField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    organizationalUnitField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
    tagsField: IProtectedAssetDetailProvider<InfoPaneFieldProps>;
}

const getNamePanel = (name: string): InfoPaneFieldProps => {
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.NAME'),
        value: name,
    };
};

const getTypePanel = (type: string): InfoPaneFieldProps => {
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.TYPE'),
        value: type,
    };
};

const getIdPanel = (id: string): InfoPaneFieldProps => {
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.ID'),
        value: id,
    };
};

const isRelevantCreatedDatePanel = (cloudEntity?: ICloudEntity): boolean => {
    if (!cloudEntity) return false;
    return (
        cloudEntity.createDate ??
        cloudEntity.createTime ??
        cloudEntity.creationDate ??
        cloudEntity.createdDate ??
        cloudEntity.createdAt ??
        cloudEntity.creationTime !== undefined
    );
};

const getCreatedDatePanel = (cloudEntity?: ICloudEntity): InfoPaneFieldProps => {
    if (!cloudEntity) return emptyPaneFieldInfo;

    const data =
        cloudEntity.createDate ??
        cloudEntity.createTime ??
        cloudEntity.creationDate ??
        cloudEntity.createdDate ??
        cloudEntity.createdAt ??
        cloudEntity.creationTime;
    let displayDate: string | undefined;
    if (typeof data === 'number') {
        displayDate = i18next.t('COMMON.DATE_TIME', {
            value: new Date(data * 1000),
            formatParams: {
                value: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
            },
            interpolation: { escapeValue: false },
        });
    } else {
        displayDate = String(data);
    }
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.CREATED_DATE'),
        value: displayDate,
    };
};

const getEnvironmentPanel = (cloudAccountId: string): InfoPaneFieldProps => {
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.ENVIRONMENT'),
        value: EnvironmentLink,
        componentProps: { cloudAccountId },
    };
};

const isVpcRelevant = (cloudEntity?: ICloudEntity): boolean => {
    if (!cloudEntity) return false;
    return (
        (isString(cloudEntity.vpc) && cloudEntity.vpc !== '') ||
        (cloudEntity.vpc?.id ?? cloudEntity.vpcId !== undefined)
    );
};

const getVpcPanel = (cloudEntity?: ICloudEntity): InfoPaneFieldProps => {
    if (!cloudEntity) return emptyPaneFieldInfo;

    const vpc = isString(cloudEntity.vpc) ? cloudEntity.vpc : (cloudEntity.vpc?.id ?? cloudEntity.vpcId);
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.VPC'),
        value: vpc,
    };
};

const getPlatform = (protectedAsset?: IProtectedAssetViewModel, cloudAccount?: ICloudAccount): string | undefined =>
    protectedAsset?.platform || cloudAccount?.platform;
const isRegionRelevant = (
    protectedAsset?: IProtectedAssetViewModel,
    cloudAccount?: ICloudAccount,
    cloudEntity?: ICloudEntity,
): boolean => {
    if (!cloudEntity) return false;
    const platform = getPlatform(protectedAsset, cloudAccount);
    if (!platform) return false;

    const regions = getRegionByName(protectedAsset?.region || cloudEntity.region, platform);
    return regions.length > 0;
};

const getRegionPanel = (
    protectedAsset?: IProtectedAssetViewModel,
    cloudAccount?: ICloudAccount,
    cloudEntity?: ICloudEntity,
): InfoPaneFieldProps => {
    if (!cloudEntity) return emptyPaneFieldInfo;
    const platform = getPlatform(protectedAsset, cloudAccount);
    if (!platform) return emptyPaneFieldInfo;

    const assetRegion = protectedAsset?.region || cloudEntity.region;
    const regions = getRegionByName(assetRegion, platform);
    let value = i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.UNKNOWN');
    if (regions.length === 1) {
        value = `${regions[0].region.description} (${assetRegion})`;
    }
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.REGION'),
        value,
    };
};

const getDescriptionPanel = (cloudEntity?: ICloudEntity): InfoPaneFieldProps => {
    if (!cloudEntity?.description) return emptyPaneFieldInfo;

    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.DESCRIPTION'),
        value: cloudEntity.description,
    };
};

const isPlatformRelevant = (protectedAsset?: IProtectedAssetViewModel, cloudAccount?: ICloudAccount): boolean => {
    return !!getPlatform(protectedAsset, cloudAccount);
};

const getPlatformPanel = (
    protectedAsset?: IProtectedAssetViewModel,
    cloudAccount?: ICloudAccount,
): InfoPaneFieldProps => {
    const platform = getPlatform(protectedAsset, cloudAccount);
    if (!platform) return emptyPaneFieldInfo;

    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.PLATFORM'),
        value: getVendorDisplayName(platform) || platform,
    };
};

const getOrganizationalUnitFieldPanel = (organizationalUnitFullPath?: string): InfoPaneFieldProps => {
    if (!organizationalUnitFullPath) return emptyPaneFieldInfo;

    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.ORGANIZATIONAL_UNIT'),
        value: organizationalUnitFullPath,
    };
};

const isTagsRelevant = (cloudEntity?: ICloudEntity) => {
    if (!cloudEntity?.tags) return false;
    const isArrayTags = Array.isArray(cloudEntity.tags);
    return isArrayTags ? cloudEntity.tags.length > 0 : Object.keys(cloudEntity.tags).length > 0;
};

const getTagsPanel = (cloudEntity?: ICloudEntity): InfoPaneFieldProps => {
    if (!cloudEntity?.tags) return emptyPaneFieldInfo;

    const chips: string[] = [];
    const isArrayTags = Array.isArray(cloudEntity.tags);

    if (isArrayTags) {
        cloudEntity.tags.forEach((tag: any) => {
            chips.push(`${tag.key}: ${tag.value}`);
        });
    } else {
        Object.keys(cloudEntity.tags).forEach((key) => {
            chips.push(`${key}: ${cloudEntity.tags[key]}`);
        });
    }
    return {
        title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.TAGS'),
        value: ComponentOverflow,
        componentProps: {
            components: chips.map((chip) => <Chip key={chip} label={chip} />),
        },
    };
};

export const AssetFieldsMap: IAssetFieldsMap = {
    nameField: {
        isRelevant: ({ protectedAsset }) => {
            return !!protectedAsset.name;
        },
        getValue({ protectedAsset }): InfoPaneFieldProps {
            return getNamePanel(protectedAsset.name);
        },
        alternativeProvider: {
            isRelevant: true,
            getValue({ entityName }): InfoPaneFieldProps {
                return getNamePanel(entityName);
            },
        },
    },

    typeField: {
        isRelevant: true,
        getValue({ typeByPlatform }): InfoPaneFieldProps {
            const asset = getProtectedAssetsService().getAssetByType(typeByPlatform);
            return getTypePanel(asset?.displayName || '');
        },
        alternativeProvider: {
            isRelevant: true,
            getValue({ asset, entityType }): InfoPaneFieldProps {
                return getTypePanel(asset?.displayName || entityType);
            },
        },
    },

    idField: {
        isRelevant: true,
        getValue({ entityId }): InfoPaneFieldProps {
            return getIdPanel(entityId);
        },
        alternativeProvider: {
            isRelevant: true,
            getValue({ entityId }): InfoPaneFieldProps {
                return getIdPanel(entityId);
            },
        },
    },

    internalIdField: {
        isRelevant: true,
        getValue({ protectedAsset }): InfoPaneFieldProps {
            return {
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.FIELDS.INTERNAL_ID'),
                value: protectedAsset.id,
            };
        },
    },

    createdDateField: {
        isRelevant: ({ cloudEntity }) => {
            return isRelevantCreatedDatePanel(cloudEntity);
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {
            //Bonus point for naming consistency at the backend :/
            return getCreatedDatePanel(cloudEntity);
        },
        alternativeProvider: {
            isRelevant: ({ cloudEntity }) => {
                return isRelevantCreatedDatePanel(cloudEntity);
            },
            getValue({ cloudEntity }): InfoPaneFieldProps {
                //Bonus point for naming consistency at the backend :/
                return getCreatedDatePanel(cloudEntity);
            },
        },
    },

    environmentField: {
        isRelevant: true,
        getValue({ cloudAccountId }): InfoPaneFieldProps {
            return getEnvironmentPanel(cloudAccountId);
        },
        alternativeProvider: {
            isRelevant: true,
            getValue({ cloudAccountId }): InfoPaneFieldProps {
                return getEnvironmentPanel(cloudAccountId);
            },
        },
    },

    vpcField: {
        isRelevant: ({ cloudEntity }) => {
            return isVpcRelevant(cloudEntity);
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {
            return getVpcPanel(cloudEntity);
        },
        alternativeProvider: {
            isRelevant: ({ cloudEntity }) => {
                return isVpcRelevant(cloudEntity);
            },
            getValue({ cloudEntity }): InfoPaneFieldProps {
                return getVpcPanel(cloudEntity);
            },
        },
    },

    regionField: {
        isRelevant: ({ protectedAsset, cloudAccount, cloudEntity }) => {
            return isRegionRelevant(protectedAsset, cloudAccount, cloudEntity);
        },
        getValue({ protectedAsset, cloudAccount, cloudEntity }): InfoPaneFieldProps {
            return getRegionPanel(protectedAsset, cloudAccount, cloudEntity);
        },
        alternativeProvider: {
            isRelevant: ({ cloudAccount, cloudEntity }) => {
                return isRegionRelevant(undefined, cloudAccount, cloudEntity);
            },
            getValue({ cloudAccount, cloudEntity }): InfoPaneFieldProps {
                return getRegionPanel(undefined, cloudAccount, cloudEntity);
            },
        },
    },

    descriptionField: {
        isRelevant: ({ cloudEntity }) => {
            return !!cloudEntity.description;
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {
            return getDescriptionPanel(cloudEntity);
        },
        alternativeProvider: {
            isRelevant: ({ cloudEntity }) => {
                return !!cloudEntity?.description;
            },
            getValue({ cloudEntity }): InfoPaneFieldProps {
                return getDescriptionPanel(cloudEntity);
            },
        },
    },

    platformField: {
        isRelevant: ({ protectedAsset, cloudAccount }) => {
            return isPlatformRelevant(protectedAsset, cloudAccount);
        },
        getValue({ protectedAsset, cloudAccount }): InfoPaneFieldProps {
            return getPlatformPanel(protectedAsset, cloudAccount);
        },
        alternativeProvider: {
            isRelevant: ({ cloudAccount }) => {
                return isPlatformRelevant(undefined, cloudAccount);
            },
            getValue({ cloudAccount }): InfoPaneFieldProps {
                return getPlatformPanel(undefined, cloudAccount);
            },
        },
    },

    organizationalUnitField: {
        isRelevant: ({ organizationalUnitFullPath }) => {
            return !!organizationalUnitFullPath;
        },
        getValue({ organizationalUnitFullPath }): InfoPaneFieldProps {
            return getOrganizationalUnitFieldPanel(organizationalUnitFullPath);
        },
        alternativeProvider: {
            isRelevant: ({ organizationalUnitFullPath }) => {
                return !!organizationalUnitFullPath;
            },
            getValue({ organizationalUnitFullPath }): InfoPaneFieldProps {
                return getOrganizationalUnitFieldPanel(organizationalUnitFullPath);
            },
        },
    },

    tagsField: {
        isRelevant: ({ cloudEntity }) => {
            return isTagsRelevant(cloudEntity);
        },
        getValue({ cloudEntity }): InfoPaneFieldProps {
            return getTagsPanel(cloudEntity);
        },
        alternativeProvider: {
            isRelevant: ({ cloudEntity }) => {
                return isTagsRelevant(cloudEntity);
            },
            getValue({ cloudEntity }): InfoPaneFieldProps {
                return getTagsPanel(cloudEntity);
            },
        },
    },
};
