import { useEffect, useRef, useState } from 'react';

interface IntersectionObserverArgs {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
}

export const useIntersectionObserver = (options: IntersectionObserverArgs): [any, boolean] => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef<Element>(null);

    useEffect(() => {
        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                setIsIntersecting(entry.isIntersecting);
            });
        };

        const observer = new IntersectionObserver(observerCallback, options);
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [options]);

    return [ref, isIntersecting];
};
