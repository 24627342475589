import { DefaultTheme } from 'styled-components';
import { TSize, ICalculateStyle } from './ScoreTile.type';

export const calculateStyle = (size: TSize, theme: DefaultTheme): ICalculateStyle => {
    const baseStyle: ICalculateStyle = {
        borderRadius: `${theme.border.width.xlarge}px`,
        iconSize: 32,
    };
    const { width, radius } = theme.border;

    switch (size) {
        case 'sm':
            return {
                iconSize: 24,
                borderRadius: `${radius(1.5)}px`,
            };
        case 'xs':
            return {
                iconSize: 16,
                borderRadius: `${width.large}px`,
            };
        default:
            return baseStyle;
    }
};
