import Stack from '../Stack';
import styled, { css } from 'styled-components';

interface WrapperProps {
    maxHeight: number;
    width?: number;
}
const Wrapper = styled(Stack)<WrapperProps>`
    border-radius: 8px;
    max-height: ${({ theme, maxHeight }) => `${maxHeight}${theme.units}`};
    overflow: auto;

    background: ${({ theme }) => theme.palette.surface.primary};
    color: ${({ theme }) => theme.palette.text.normal};
    box-shadow: ${({ theme }) => theme.shadows[2]};

    ${({ width, theme }) =>
        width &&
        css`
            width: ${width}${theme.units};
            overflow-x: hidden;
        `}
`;

const PopoverStyles = {
    Wrapper,
};

export default PopoverStyles;
