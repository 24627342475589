import { FC, useCallback, useMemo } from 'react';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { FormStyled } from '../../CustomForm.styled';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import i18n from 'i18next';
import { IOnFieldChanged, IItemsMap, ILazySelectItem, IFullErrorInfo } from '../../CustomForm.interface';
import { SelectAsync, Typography } from '../../../../../design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { WILDCARD_MARK } from '../../CustomForm.consts';
import ItemComponentBottomArea from '../helperComponents/ItemComponentBottomArea';

export interface ILazySelectComponentProps {
    item: ILazySelectItem;
    itemsMap: IItemsMap;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
}

export const LazySelectItemComponent: FC<ILazySelectComponentProps> = ({
    item,
    itemsMap,
    onFieldChanged,
    showErrors,
    errorInfo,
    skipTitle,
    isInner,
}) => {
    const { state } = item;
    const values: string[] = useMemo(() => state.value || [], [state.value]);
    const { t } = useTranslation();
    const wildcardSuffix = useMemo(
        () => (state.disableWildcards ? '' : ` ${t('CUSTOM_FORM.COMPONENTS.LAZY_SELECT_WILDCARD_SUFFIX')}`),
        [state.disableWildcards, t],
    );
    const placeholder = useMemo(
        () =>
            state.placeholder ||
            (item.getLazyOptions
                ? t('CUSTOM_FORM.COMPONENTS.LAZY_SELECT_DEFAULT_PLACEHOLDER')
                : t('CUSTOM_FORM.COMPONENTS.LAZY_SELECT_NO_SELECTION_PLACEHOLDER')),
        [item.getLazyOptions, state.placeholder, t],
    );
    const instruction = useMemo(
        () =>
            item.getLazyOptions
                ? t('CUSTOM_FORM.COMPONENTS.LAZY_SELECT_SELECTOR_INSTRUCTION', { wildcardSuffix })
                : t('CUSTOM_FORM.COMPONENTS.LAZY_SELECT_NO_SELECTION_INSTRUCTION', { wildcardSuffix }),
        [item.getLazyOptions, wildcardSuffix, t],
    );

    const loadOptions = useCallback(
        async (inputValue?: string): Promise<SelectOption[]> => {
            if (item.getLazyOptions && inputValue && inputValue.length > 1 && !inputValue.includes(WILDCARD_MARK)) {
                return await item.getLazyOptions(inputValue, itemsMap);
            }

            return [];
        },
        [item, itemsMap],
    );

    const selectedOptions: SelectOption[] = useMemo(() => {
        return values.map((val) => {
            if (!state.disableWildcards) {
                if (val.startsWith(WILDCARD_MARK) && val.endsWith(WILDCARD_MARK)) {
                    return {
                        value: val,
                        label: `${val} (${i18n.t('CUSTOM_FORM.CONDITIONS.CONTAINS')} ${val.substring(1, val.length - 1)})`,
                    };
                } else if (val.startsWith(WILDCARD_MARK)) {
                    return {
                        value: val,
                        label: `${val} (${i18n.t('CUSTOM_FORM.CONDITIONS.ENDS_WITH')} ${val.substring(1)})`,
                    };
                } else if (val.endsWith(WILDCARD_MARK)) {
                    return {
                        value: val,
                        label: `${val} (${i18n.t('CUSTOM_FORM.CONDITIONS.STARTS_WITH')} ${val.substring(0, val.length - 1)})`,
                    };
                }
            }

            return {
                value: val,
                label: val,
            };
        });
    }, [state.disableWildcards, values]);

    const onBlurInput = useCallback(
        (text: string) => {
            if (!text || item.getLazyOptions) {
                return;
            }

            const val = text.trim();
            if (!values.includes(val)) {
                onFieldChanged({ ...item, state: { ...state, value: [...values, val] } });
            }
        },
        [item, onFieldChanged, state, values],
    );

    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <Typography variant={'bodyXs'} color={'light'}>
                {instruction}
            </Typography>
            <SelectAsync
                fullWidth
                clearable
                creatable
                options={selectedOptions}
                value={values}
                onChange={(values) => onFieldChanged({ ...item, state: { ...state, value: values } })}
                loadOptions={loadOptions}
                disabled={state.disabled || state.readonly}
                placeholder={placeholder}
                isError={!!(showErrors && errorInfo)}
                onBlur={(event) => onBlurInput(event.target.value)}
            />
            <ItemComponentBottomArea state={state} showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};
