import { CIEM_TOPIC_ID, IDENTITY_ACTIVE_FEATURES } from './ActiveFeatures.consts';
import { ActiveFeaturesRegistry } from '../../../registries/ActiveFeatureManager/ActiveFeaturesRegistry';

export default function initializeIdentityActiveFeatures() {
    ActiveFeaturesRegistry.addActiveFeatureTopic({
        key: CIEM_TOPIC_ID,
        title: 'CIEM',
        icon: 'ciem',
    });
    ActiveFeaturesRegistry.addActiveFeatureInfos(IDENTITY_ACTIVE_FEATURES);
}
