import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const TabsWrapper = styled(Stack)`
    max-width: 200px;
    min-width: 200px;
    border-right: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
`;

export const ChipWrapper = styled.div`
    position: absolute;
    top: -5px;
    right: -5px;
`;
