import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tab } from '../EntityViewer.interface';
import useReactRouterQuery, { ChangeParamPayload } from 'common/hooks/useReactRouterQuery';

const useTabs = (tabs: Array<Tab>, ignoreUrlParams?: boolean) => {
    const history = useHistory();
    const { changeParams, params } = useReactRouterQuery<{ tabName?: string; tabOnly?: string }>();

    const [selectedTab, setSelectedTab] = React.useState<Tab | undefined>(undefined);

    type GetSelectedTabFromUrl = () => Tab | undefined;
    const getSelectedTabFromUrl: GetSelectedTabFromUrl = React.useCallback(() => {
        return tabs.find((tab) => tab.name.toLowerCase() === params.tabName?.toLowerCase()) || tabs[0];
    }, [params, tabs]);

    const handleOnTabChange = React.useCallback(
        (newTab: Tab) => {
            if (newTab.name === selectedTab?.name) return;
            setSelectedTab(newTab);

            if (ignoreUrlParams) return;

            const paramsToChange: ChangeParamPayload[] = [{ paramKey: 'tabName', newValue: newTab.name }];

            const newTabHasViewStateTab = !!newTab.viewStateProps;
            if (!newTab.isReactTab && !newTabHasViewStateTab && !params.tabOnly) {
                paramsToChange.push({ paramKey: 'tabOnly', newValue: 'true' });
            }

            changeParams(paramsToChange);
        },
        [changeParams, params, selectedTab, ignoreUrlParams],
    );

    React.useEffect(() => {
        if (ignoreUrlParams) return;
        const newTab = getSelectedTabFromUrl();
        if (!newTab) return;
        handleOnTabChange(newTab);
    }, [history, getSelectedTabFromUrl, handleOnTabChange, ignoreUrlParams]);

    React.useEffect(() => {
        if (ignoreUrlParams && !selectedTab && tabs.length > 0) {
            setSelectedTab(tabs[0]);
        }
    }, [tabs, ignoreUrlParams, selectedTab]);

    return { selectedTab, handleOnTabChange };
};

export default useTabs;
