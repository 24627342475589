import { I18nExclusion } from './exclusions.consts';
import { useTranslation } from 'react-i18next';
import { Stack, Alert, Typography } from 'common/design-system/components-v2';

const ExclusionWarning = () => {
    const { t } = useTranslation(I18nExclusion);
    return (
        <Stack data-aid={'exclusion-warning'}>
            <Alert type='warning'>
                <Typography variant='bodyLg'>{t('MODAL.VALIDATIONS.EXCLUSION_WARNING.MESSAGE')} </Typography>
            </Alert>
        </Stack>
    );
};

export default ExclusionWarning;
