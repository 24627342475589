import React, { useEffect, useMemo, useRef } from 'react';
import {
    FilterTreeItemType,
    IFilterTreeCommonProps,
    IFilterTreeCondition,
    IFilterTreeItem,
    IFilterTreeNode,
    IMovingTreeItemInfo,
} from '../FilterTree.interface';
import { FilterStyled, PrimaryRowStyled, SecRowStyled } from './FilterTree.styled';
import { FilterTreeCondition } from './FilterTreeCondition';
import { FilterTreeLogicalOperator } from './FilterTreeLogicalOperator';
import { isNil } from 'lodash';
import { getConditionError } from '../FilterTree.errors';
import { useTranslation } from 'react-i18next';
import { Button, Label, Stack, Tooltip } from '../../index';
import { useDroppable } from '@dnd-kit/core';
import { getMovingTreeItemInfo, getPathItems, getTreeRoot, isLastItemInParent } from '../FilterTree.utils';

enum PrimaryPartType {
    TShape,
    LShape,
    Line,
    Empty,
}

interface IPrimaryPartInfo {
    itemId: string;
    partType: PrimaryPartType;
}

const PartLShape: React.FC = () => (
    <Stack direction={'row'}>
        <PrimaryRowStyled.PrimaryRowLeftHalfLinePart />
        <PrimaryRowStyled.PrimaryRowRightHalfLinePart />
    </Stack>
);

const PartTShape: React.FC = () => (
    <Stack direction={'row'}>
        <PrimaryRowStyled.PrimaryRowLeftLinePart />
        <PrimaryRowStyled.PrimaryRowRightHalfLinePart />
    </Stack>
);

const PrimaryRowParts: React.FC<{ item: IFilterTreeItem }> = ({ item }) => {
    const pathItems: IFilterTreeItem[] = getPathItems(item).reverse();

    const partTypeInfos: IPrimaryPartInfo[] = pathItems
        .slice(0, pathItems.length - 1)
        .map((anItem: IFilterTreeItem, index: number) => {
            if (index === 0) {
                return {
                    itemId: anItem.id,
                    partType: isLastItemInParent(item) ? PrimaryPartType.LShape : PrimaryPartType.TShape,
                };
            } else {
                return {
                    itemId: anItem.id,
                    partType: isLastItemInParent(pathItems[index]) ? PrimaryPartType.Empty : PrimaryPartType.Line,
                };
            }
        });

    const getFullPart = (partTypeInfo: IPrimaryPartInfo) => {
        const { partType, itemId } = partTypeInfo;
        return (
            <Stack direction={'row'} key={itemId}>
                {partType === PrimaryPartType.LShape && <PartLShape />}
                {partType === PrimaryPartType.TShape && <PartTShape />}
                {partType === PrimaryPartType.Line && <PrimaryRowStyled.PrimaryRowWideLinePart />}
                {partType === PrimaryPartType.Empty && <PrimaryRowStyled.PrimaryRowWideEmptyPart />}
                {(partType === PrimaryPartType.Line || partType === PrimaryPartType.Empty) && (
                    <PrimaryRowStyled.PrimaryRowNarrowPart />
                )}
            </Stack>
        );
    };

    if (!item.parentNode) {
        return null;
    }

    return (
        <PrimaryRowStyled.PrimaryRowPartsTopDiv>
            {partTypeInfos.reverse().map((info) => getFullPart(info))}
        </PrimaryRowStyled.PrimaryRowPartsTopDiv>
    );
};

interface ISecPartInfo {
    itemId: string;
    hasLine: boolean;
}

const SecondaryRow: React.FC<{ item: IFilterTreeItem; isHighlightDrag: boolean }> = ({ item, isHighlightDrag }) => {
    const pathItems: IFilterTreeItem[] = getPathItems(item).reverse();
    const partInfos: ISecPartInfo[] = pathItems.map((anItem: IFilterTreeItem, index: number) => {
        if (index === 0) {
            return {
                itemId: anItem.id,
                hasLine:
                    item.itemType === FilterTreeItemType.condition
                        ? false
                        : (item as IFilterTreeNode).childItems.length > 0,
            };
        } else {
            return {
                itemId: anItem.id,
                hasLine: !isLastItemInParent(pathItems[index - 1]),
            };
        }
    });

    const getFullPart = (partInfo: ISecPartInfo, isLastPart: boolean) => {
        const { itemId, hasLine } = partInfo;
        return (
            <SecRowStyled.SecRowFullPart
                highlight={isLastPart && isHighlightDrag && item.itemType === FilterTreeItemType.condition}
                key={itemId}
            >
                {hasLine ? <SecRowStyled.SecRowWideLinePart /> : <SecRowStyled.SecRowWideEmptyPart />}
                <SecRowStyled.SecRowNarrowPart />
            </SecRowStyled.SecRowFullPart>
        );
    };

    return (
        <SecRowStyled.SecRowTopDiv>
            <SecRowStyled.SecRowIconSpacer />
            {partInfos
                .reverse()
                .map((partInfo: ISecPartInfo, index: number) => getFullPart(partInfo, index === partInfos.length - 1))}
            <SecRowStyled.SecRowStretch
                highlight={isHighlightDrag}
                hasLeftPart={item.itemType === FilterTreeItemType.condition}
            />
        </SecRowStyled.SecRowTopDiv>
    );
};

const ItemButtonsBar: React.FC<{ item: IFilterTreeItem; filterProps: IFilterTreeCommonProps }> = ({
    item,
    filterProps,
}) => {
    const { t } = useTranslation();
    return (
        <FilterStyled.ButtonsRowWrapper>
            {!isNil(item.parentNode) && (
                <Button
                    variant={'text'}
                    iconProps={{ name: 'delete', size: 16 }}
                    onClick={() => filterProps.api.onRemoveItem(item)}
                />
            )}
            {isNil(item.parentNode) &&
                item.itemType === FilterTreeItemType.node &&
                (item as IFilterTreeNode).childItems.length > 0 && (
                    <Button
                        variant={'text'}
                        label={t('FILTER_TREE.ACTIONS.CLEAR_FILTER')}
                        iconProps={{ name: 'remove', size: 16 }}
                        onClick={() => filterProps.api.clearFilter()}
                    />
                )}
        </FilterStyled.ButtonsRowWrapper>
    );
};

export const ItemErrorMark: React.FC<{ item: IFilterTreeItem; displayErrors: boolean }> = ({ item, displayErrors }) => {
    const errorMarkRef = useRef<HTMLInputElement>(null);
    const errorMsg: string | undefined = useMemo(
        () =>
            item.itemType === FilterTreeItemType.node
                ? (item as IFilterTreeNode).errorMsg
                : getConditionError(item as IFilterTreeCondition),
        [item],
    );

    useEffect(() => {
        item.errorMarkRef = errorMarkRef.current;
    }, [errorMsg, item]);

    return (
        <FilterStyled.ItemErrorIconDiv>
            {displayErrors && errorMsg && (
                <Tooltip content={errorMsg}>
                    <Label text='' leadingIconProps={{ name: 'warning', color: 'alert' }} ref={errorMarkRef} />
                </Tooltip>
            )}
        </FilterStyled.ItemErrorIconDiv>
    );
};

export const FilterTreeItem: React.FC<{ item: IFilterTreeItem; filterProps: IFilterTreeCommonProps }> = ({
    item,
    filterProps,
}) => {
    const { isOver, setNodeRef, active } = useDroppable({
        id: item.id,
    });

    const isHighlightDrag = useMemo(() => {
        if (!isOver) {
            return false;
        }
        const dragId = String(active?.id || '');
        if (!dragId) {
            return false;
        }
        const root: IFilterTreeNode = getTreeRoot(item);

        const info: IMovingTreeItemInfo | undefined = getMovingTreeItemInfo(root, dragId, item.id);
        if (!info) {
            return false;
        }
        return true;
    }, [active?.id, isOver, item]);

    return (
        <Stack direction={'column'} ref={setNodeRef}>
            <FilterStyled.TreeItemRowDiv>
                <ItemErrorMark item={item} displayErrors={filterProps.displayErrors} />
                <PrimaryRowParts item={item} />
                {item.itemType === FilterTreeItemType.condition && (
                    <FilterTreeCondition condition={item as IFilterTreeCondition} filterProps={filterProps} />
                )}
                {item.itemType === FilterTreeItemType.node && (
                    <FilterTreeLogicalOperator node={item as IFilterTreeNode} filterProps={filterProps} />
                )}
                <ItemButtonsBar item={item} filterProps={filterProps} />
            </FilterStyled.TreeItemRowDiv>
            <SecondaryRow item={item} isHighlightDrag={isHighlightDrag} />
        </Stack>
    );
};
