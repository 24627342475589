import { FC, useState } from 'react';
import { ExportButton } from './ExpandedButton.interface';
import './ExpandedButton.scss';
import { Icon } from '@dome9/berries/react-components';

const ExpandButton: FC<ExportButton> = ({ children, title }) => {
    const [expandedContent, setExpandedContent] = useState(false);
    return (
        <div className={'expanded-container flex-column mt-9'}>
            <div className={'flex'} onClick={() => setExpandedContent(!expandedContent)}>
                <Icon
                    name={title}
                    // @ts-ignore
                    icon={'chevron-right'}
                />
            </div>

            {expandedContent && (
                <div className={'expanded-data'}>{children.props.children.map((content: any) => content)}</div>
            )}
        </div>
    );
};

export default ExpandButton;
