import { FC } from 'react';
import PreTag from './PreTag';
import { CpCopyToClipboard } from '@dome9/components/react/components';

interface SnippetProps {
    id?: string;
    code: string;
}

const Snippet: FC<SnippetProps> = ({ code, id }) => {
    return (
        <div className='flex space-between mt-6' id={id}>
            <PreTag>{code}</PreTag>
            <CpCopyToClipboard text={code} />
        </div>
    );
};

export default Snippet;
