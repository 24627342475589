import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgNetworkActivity = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 30c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14Zm0 2c8.837 0 16-7.163 16-16s-7.163-16-16-16S.5 7.163.5 16s7.163 16 16 16Z"
      />
      <path d="M9.805 6.788c-.124.101-.218.241-.218.311s-.03.124-.077.124c-.055 0-.078 1.266-.078 3.65v3.651h1.554V8.155h10.718v6.369h1.553v-3.728c0-2.43-.023-3.728-.077-3.728-.04 0-.078-.054-.078-.124s-.07-.14-.155-.163c-.086-.024-.156-.07-.156-.11 0-.038-2.741-.069-6.384-.069h-6.392l-.21.186Z" />
      <path d="M11.918 10.33v.777h9.009V9.553h-9.01v.777ZM11.918 13.281v.777l4.489-.015 4.481-.024.024-.76.023-.754h-9.018v.776Z" />
      <path d="M25.222 13.095c-.225.225-.411.49-.411.575 0 .1.295.45.776.932.428.427.777.792.777.816 0 .023-4.714.038-10.485.038H5.393v1.398H15.88c5.77 0 10.485.016 10.485.04 0 .023-.35.388-.777.815-.481.481-.776.83-.776.932 0 .21.8 1.01 1.01 1.01.1 0 .698-.544 1.67-1.515l1.514-1.515v-.932l-1.515-1.514c-1.102-1.103-1.56-1.515-1.685-1.507-.109 0-.342.17-.583.427Z" />
      <path d="M9.432 21.437c0 2.384.023 3.65.078 3.65.046 0 .077.055.077.125s.094.21.218.31l.21.187h6.392c3.643 0 6.384-.031 6.384-.07 0-.039.07-.086.156-.109.085-.023.155-.093.155-.163s.039-.124.078-.124c.054 0 .077-1.297.077-3.728v-3.729h-1.553v6.369H10.985v-6.369H9.433v3.65Z" />
      <path d="M11.918 19.03v.776h9.017l-.023-.761-.024-.754-4.481-.023-4.49-.016v.777ZM11.918 21.98v.777h9.009v-1.553h-9.01v.777Z" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgNetworkActivity);
export default ForwardRef;
