import styled, { css } from 'styled-components';
import { RadioButtonLabelProps } from './RadioButton.types';

interface RadioButtonWrapperProps {
    checked?: boolean;
    disabled?: boolean;
    size?: RadioButtonLabelProps['size'];
}

const Wrapper = styled.div<RadioButtonWrapperProps>`
    display: flex;
    position: relative;
    cursor: pointer;
    width: fit-content;
    margin-left: -8px;
    justify-content: flex-start;
    flex-direction: row;
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `}
`;

const Input = styled.input`
    pointer-events: all;
    left: 4px;
    top: 4px;
    height: 16px;
    width: 16px;
    z-index: 1;
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    outline: none;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        box-shadow: none;
    }
    &:before {
        content: '';
        position: absolute;
        border-radius: 50%;
        height: 16px;
        width: 16px;
    }
    &:hover&:before {
        box-shadow: ${({ theme }) => `${theme.shadows.hover}`};
    }
    &:focus&:before {
        box-shadow: ${({ theme }) => `${theme.shadows.focus}`};
    }
`;

const getPaddingTop = (size: RadioButtonLabelProps['size']) => {
    switch (size) {
        case 'md':
            return '8px';
        case 'lg':
            return '4px';
        case 'xl':
            return '4px';
    }
};

interface LabelWrapperProps {
    hasChildren: boolean;
    disabled?: boolean;
    size: RadioButtonLabelProps['size'];
}
const LabelWrapper = styled.div<LabelWrapperProps>`
    padding-top: ${({ size, hasChildren }) => (hasChildren ? 'unset' : getPaddingTop(size))};
    width: 100%;
    max-width: fit-content;
    overflow: hidden;
    ${({ theme, disabled }) =>
        disabled &&
        css`
            opacity: ${theme.disabledOpacity};
        `}
`;

const RadioButtonStyles = {
    Wrapper,
    Input,
    LabelWrapper,
};

export default RadioButtonStyles;
