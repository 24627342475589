import { buildPath } from 'common/extensibility/AddinContainer';

export const PATH_ENVIRONMENT_PAGE = 'environment';
export const PATH_ALIBABA_ENVIRONMENT = buildPath(PATH_ENVIRONMENT_PAGE, 'alibabaEnvironment');
export const PATH_ENVIRONMENT_PAGE_ALIBABA_DETAILS_PANEL = buildPath(PATH_ALIBABA_ENVIRONMENT, 'detailsPanel');
export const PATH_ENVIRONMENT_PAGE_ALIBABA_TAB = buildPath(PATH_ALIBABA_ENVIRONMENT, 'tab');
export const PATH_ENVIRONMENT_PAGE_ALIBABA_CHIP = buildPath(PATH_ALIBABA_ENVIRONMENT, 'chip');
export const PATH_ENVIRONMENT_PAGE_ACTION = buildPath(PATH_ALIBABA_ENVIRONMENT, 'action');

export interface AlibabaEnvironmentData {
    account: AlibabaCloudAccount;
}

export interface AlibabaCloudAccount {
    id: string;
    vendor: string;
    name: string;
    alibabaAccountId: string;
    projectNumber: string;
    externalAccountNumber: string;
    error: string;
    magellan: boolean;
    isFetchingSuspended: boolean;
    creationDate: string;
    credentials: CloudAccountCredentials;
    organizationalUnitId?: any;
    organizationalUnitPath: string;
    organizationalUnitName: string;
    totalAssets: number;
    isServerlessActive: boolean;
    isCloudbotsOnboarded?: boolean;
}

interface CloudAccountCredentials {
    accessKey?: string;
    accessSecret?: string;
}

export interface ServerlessAlibabaAccountStatesGetResponse {
    cloudAccountId: string;
    externalAccountNumber: string;
    cloudVendor: string;
    state: string;
    accountId: number;
    reason: string;
    shouldUpdate: boolean;
    isOrgOnboarding: boolean;
    hasMissingPermissions: boolean;
    validatePermissionsStatus: string;
    lastValidatePermissionsTimestamp: number;
}
