import { ITableAction, ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';

export interface IExclusionProps extends IInitHookProps {
    actionButtons: IExclusionTableActions;
}
export type IGenericFunction = (...args: any[]) => any;

export interface IExclusionTableActions {
    buttonsOptions: ITableAction<IGlobalStringObject>[];
    exportButtonsOptions: ITableExportButton[];
    onRowSelect?: IGenericFunction;
}

export enum EFilterComps {
    Save = 'vulnerabilities-exclusions-save',
    RecentlyUsed = 'vulnerabilities-exclusions-read',
}
export enum EDataItem {
    exclusions = 'exclusions',
    accounts = 'accounts',
    flatOrganizationalUnits = 'flatOrganizationalUnits',
    organizationalUnits = 'organizationalUnits',
    ruleSet = 'ruleSet',
    user = 'user',
    allAccounts = 'allAccounts',
    savedFilters = 'savedFilters',
    recentlyUsed = 'recentlyUsed',
}

export interface IExclusion {
    cloudAccountIds: string[];
    cloudAccountType: string;
    comment: string;
    dateRange: { from: string; to: string };
    id: string;
    logicExpressions: string[];
    organizationalUnitIds: any;
    platform: string;
    regions: string[];
    rules: any[];
    rulesetId: number;
    severities: any;
    dateRangeString?: string;
    environment?: string;
    ruleSet?: string;
}

type IInitFilterKey = {
    [key: string]: Set<string>;
};

export type IInitFilters = {
    [K in keyof IDataItem]?: IInitFilterKey;
};

export interface IInitHookProps {
    init: () => { name: EDataItem; func: () => Promise<any> }[];
    initFilters?: IInitFilters;
    isFetching?: boolean;
}

export interface IUseInitReturn {
    isLoading: boolean;
    exclusions: IExclusion[] | null;
    isError: boolean;
    data: IDataItem | null;
}

export interface IDataItem {
    exclusions: IExclusion[] | null;
    accounts?: IGlobalStringObject[];
    flatOrganizationalUnits?: IGlobalStringObject[];
    organizationalUnits: IGlobalStringObject[];
    ruleSet: IGlobalStringObject[];
    user?: IGlobalStringObject[];
    allAccounts: IGlobalStringObject[];
    savedFilters?: IGlobalStringObject[];
    recentlyUsed?: IGlobalStringObject[];
}

export interface IFetchedData {
    data: IDataItem[];
}
export interface IGenericArrayObject {
    [key: string]: any[];
}

export interface IGlobalStringObject {
    [key: string]: string;
}
export interface IUseFilterProps {
    filterBy: IGlobalStringObject | null;
    data: IExclusion[] | null;
}
