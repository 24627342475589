import { IFieldInfo } from '../interface/general';
import {
    getProtectedAssetsService,
    IProtectedAssetFilter,
    IProtectedAssetFilterParams,
} from '../module_interface/assets/ProtectedAssets';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { IFilterProps } from '../components/FilterPanel/FilterPanelManager';
import { FieldConvertorsRegistry, FieldEntityKind, IGNORE_FIELD } from '../registries/FieldConvertorsRegistry';
import { buildPath } from '../extensibility/AddinContainer';
import { FAVORITES, FILTERS_KEYS, RECENTLY_USED } from '../components/FilterPanel/FilterPanel.consts';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { IAsset } from 'common/assets/common.assets';
import { getVendorByElasticNumber } from 'common/consts/vendors';

export const convertFilterField = (
    sourceEntityKind: FieldEntityKind,
    targetEntityKind: FieldEntityKind,
    sourceField: IFieldInfo,
): IFieldInfo | undefined => {
    const fieldConvertor = FieldConvertorsRegistry.getFieldConvertor(
        sourceEntityKind,
        targetEntityKind,
        sourceField.name,
    );
    if (!fieldConvertor) {
        return undefined;
    }

    let targetValue;
    if (fieldConvertor.getTargetValue) {
        targetValue = fieldConvertor.getTargetValue(sourceField.value);
        if (targetValue === IGNORE_FIELD) {
            return undefined;
        }
    } else {
        targetValue = sourceField.value;
    }

    return {
        name: fieldConvertor.targetFieldName || sourceField.name,
        value: targetValue,
    };
};

export const filterFieldsChanges = (oldFields: IFieldInfo[] = [], newFields: IFieldInfo[] = []) => {
    const comparator = (f1: IFieldInfo, f2: IFieldInfo) => {
        return f1.name.localeCompare(f2.name) || String(f1.value || '').localeCompare(String(f2.value || ''));
    };
    const oldSorted = oldFields.sort(comparator);
    const newSorted = newFields.sort(comparator);
    return JSON.stringify(oldSorted) !== JSON.stringify(newSorted);
};
export const isFilterFieldsChanged = (oldFields: IFieldInfo[] = [], newFields: IFieldInfo[] = []) => {
    return filterFieldsChanges(oldFields, newFields);
};

export type IEntityItem = { [key: string]: any };
export const getSavedFilterDefs = (
    filters: IProtectedAssetFilter[],
    params: IProtectedAssetFilterParams,
    getFilter: (filterId: string, filterParams: IProtectedAssetFilterParams[]) => IFilterProps | undefined,
): IFilterProps[] => {
    const result: IFilterProps[] = [];
    filters.forEach((filter) => {
        const matchedFilterDef = getFilter(filter.id, [params]);
        if (matchedFilterDef) {
            const filterDef = { ...matchedFilterDef };
            filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
            result.push(filterDef);
        }
    });
    return result;
};

export const createRecentlyUsedFilter = (tableId: string) => {
    const componentName = buildPath(tableId, RECENTLY_USED);
    return {
        id: FILTERS_KEYS.RECENTLY_USED_FILTERS,
        filterProps: {
            componentName: componentName,
        },
    };
};

export const createFavoritesFilter = (tableId: string) => {
    const componentName = buildPath(tableId, FAVORITES);
    return {
        id: FILTERS_KEYS.SAVED_FILTERS,
        filterProps: {
            savedFiltersComponentName: componentName,
        },
    };
};

export const getAssetByEntityTypeByEnvironmentType = (entityTypeByEnvironmentType?: string): IAsset | null => {
    if (!entityTypeByEnvironmentType) return null;
    const [vendorCodeOrName, platformType] = entityTypeByEnvironmentType.split('|');
    let vendorName = '';
    if (!isNaN(Number(vendorCodeOrName))) {
        const vendor = getVendorByElasticNumber(Number(vendorCodeOrName));
        if (vendor) {
            vendorName = vendor.name;
        }
    } else {
        vendorName = vendorCodeOrName.toLowerCase();
    }
    const typeByPlatform = `${vendorName}|${platformType}`;
    const asset = getProtectedAssetsService().getAssetByType(typeByPlatform);
    return asset;
};

export const getEntityTypeAggregationData = (aggregations: Aggregations) => {
    const displayMapping: IDisplayMappingObject = {};
    const initialData: { value: string }[] = [];
    aggregations['entityTypeByEnvironmentType']?.forEach((item: { value: string }) => {
        const asset = getAssetByEntityTypeByEnvironmentType(item.value);
        if (asset?.displayName) {
            displayMapping[item.value] = {
                displayText: asset?.displayName ?? item.value,
                icon: asset?.icon,
            };
            initialData.push(item);
        }
    });

    return { displayMapping, initialData };
};
