import React from 'react';

export interface iStatusMessagePanelProps {
    text: React.ReactElement | string;
    type: StatusType;
}

export enum StatusType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
}
