import { getAppRootRegistry } from 'common/interface/services';
import protectedAssetTabsReducer from './ProtectedAssetTabs.reducer';

export function initializeProtectedAssets() {
    getAppRootRegistry().addReducers(
        [
            {
                name: 'protectedAssetTabs',
                reducer: protectedAssetTabsReducer,
                isBlackList: false,
            },
        ],
        'name',
    );
}
