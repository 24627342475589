import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AssetStyled } from './AssetPropertiesPanel.styled';
import { LoadingState } from 'common/interface/general';
import { Icon, Spinner, ComponentOverflow } from 'common/design-system/components-v2';
import { InfoPaneFieldProps, InfoPaneItem, InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { useTranslation } from 'react-i18next';
import {
    getActionsAllowAlternativeEntity,
    getFieldsAllowAlternativeEntity,
    getProtectedAssetsService,
} from '../../module_interface/assets/ProtectedAssets';
import { ICloudEntityData } from '../../module_interface/assets/ICloudEntity';
import { runAll } from '../../utils/helpFunctions';
import { IconProps } from '../../design-system/components-v2/Icon/Icon.types';
import { IAssetPropertiesPanelProps } from './AssetPropertiesPanel.interface';

export const AssetPropertiesPanel: React.FC<IAssetPropertiesPanelProps> = (props) => {
    const {
        registeredEntityFields,
        getCloudEntity,
        alternativeCloudEntityData,
        typeByPlatform,
        headerComponent,
        title,
        children,
        onAssetLoaded,
    } = props;
    const [chips, setChips] = useState<InfoPanelActionProps[] | undefined>();
    const [fields, setFields] = useState<InfoPaneFieldProps[] | undefined>();
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const { t } = useTranslation();

    const iAsset = useMemo(() => getProtectedAssetsService().getAssetByType(typeByPlatform), [typeByPlatform]);

    const iconProps: IconProps | undefined = iAsset?.icon ? { vendorNameOrPath: iAsset.icon, size: 32 } : undefined;

    const createChipsArea = () =>
        chips?.map((chip: InfoPanelActionProps, index: number) => (
            <chip.component key={index} {...chip.componentProps} />
        ));

    const createFieldsArea = () =>
        fields?.map((field: InfoPaneFieldProps, index: number) => (
            <AssetStyled.FieldDiv key={index}>
                <InfoPaneItem key={index} {...field} />
            </AssetStyled.FieldDiv>
        ));

    const isLoading = useMemo((): boolean => {
        return !fields || !chips;
    }, [chips, fields]);

    const clearFieldsAndChips = useCallback(() => {
        setFields(undefined);
        setChips(undefined);
    }, []);

    const setFieldsAndChips = useCallback(
        (cloudEntity?: ICloudEntityData) => {
            const promisesMap = {
                fields: getFieldsAllowAlternativeEntity(
                    registeredEntityFields,
                    cloudEntity,
                    alternativeCloudEntityData,
                ),
                chips: getActionsAllowAlternativeEntity(cloudEntity, alternativeCloudEntityData),
            };
            runAll(promisesMap).then((resultsMap) => {
                if (!resultsMap.fields && !resultsMap.chips) {
                    setLoadingState(LoadingState.LOADING_FAILED);
                } else {
                    setFields(resultsMap.fields);
                    setChips(resultsMap.chips);
                    setLoadingState(LoadingState.LOADING_SUCCEEDED);
                }
            });
        },
        [alternativeCloudEntityData, registeredEntityFields],
    );

    const tryUsingAlternativeCloudEntity = useCallback(() => {
        if (!alternativeCloudEntityData) {
            setLoadingState(LoadingState.LOADING_FAILED);
            return;
        }
        setFieldsAndChips();
    }, [alternativeCloudEntityData, setFieldsAndChips]);

    useEffect(() => {
        clearFieldsAndChips();
        setLoadingState(LoadingState.IS_LOADING);
        getCloudEntity()
            .then((entity: ICloudEntityData | undefined | null) => {
                if (entity) {
                    onAssetLoaded?.(entity.protectedAsset.id);
                    setFieldsAndChips(entity);
                } else {
                    tryUsingAlternativeCloudEntity();
                }
            })
            .catch(() => {
                tryUsingAlternativeCloudEntity();
            });
    }, [
        clearFieldsAndChips,
        getCloudEntity,
        iAsset,
        registeredEntityFields,
        onAssetLoaded,
        setFieldsAndChips,
        tryUsingAlternativeCloudEntity,
    ]);

    return (
        <AssetStyled.TopDiv>
            <AssetStyled.TitleDiv>{title}</AssetStyled.TitleDiv>
            {loadingState !== LoadingState.LOADING_FAILED && (
                <AssetStyled.ContentDiv>
                    <AssetStyled.HeaderDiv withSpinner={isLoading}>
                        <AssetStyled.HeaderLeftDiv>
                            {iconProps && (
                                <AssetStyled.HeaderLeftIconDiv>
                                    <Icon {...iconProps} />
                                </AssetStyled.HeaderLeftIconDiv>
                            )}
                            <AssetStyled.HeaderLeftTextStack>{headerComponent}</AssetStyled.HeaderLeftTextStack>
                        </AssetStyled.HeaderLeftDiv>
                        {chips && (
                            <AssetStyled.HeaderRightDiv>
                                <ComponentOverflow
                                    components={createChipsArea() || ([] as ReactNode[])}
                                    ellipsisMinWidth={55}
                                />
                            </AssetStyled.HeaderRightDiv>
                        )}
                        {isLoading && (
                            <AssetStyled.SpinnerDiv>
                                <Spinner size={16} />
                            </AssetStyled.SpinnerDiv>
                        )}
                    </AssetStyled.HeaderDiv>

                    {fields && <AssetStyled.FieldsDiv>{createFieldsArea()}</AssetStyled.FieldsDiv>}

                    {children}
                </AssetStyled.ContentDiv>
            )}
            {loadingState === LoadingState.LOADING_FAILED && (
                <AssetStyled.ContentDiv>
                    <AssetStyled.ErrorDiv>{t('ASSETS.ASSET_DETAILS.COULD_NOT_FIND_ASSET')}</AssetStyled.ErrorDiv>
                </AssetStyled.ContentDiv>
            )}
        </AssetStyled.TopDiv>
    );
};
