import { useEffect, useState } from 'react';
import { getRolesService, IRole } from 'common/interface/services';

interface UseRolesInterface {
    roles: IRole[];
    isLoading: boolean;
}

export const useRoles = (): UseRolesInterface => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [roles, setRoles] = useState<IRole[]>([]);

    const getRoles = async () => {
        const data = await getRolesService().getRoles();
        setRoles(data);
        setIsLoading(false);
    };

    useEffect(() => {
        void getRoles();
    }, []);

    return {
        roles,
        isLoading,
    };
};
