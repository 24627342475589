export enum GslSource {
    flowlogs = 'vpcfl',
    cloudtrail = 'cloudtrail',
    alerts = 'alerts',
    azurect = 'azurect',
    azurefl = 'azurefl',
    kubernetes = 'k8s',
    kubernetescloudtrail = 'k8sct',
    gcpct = 'gcpct',
    gcpfl = 'gcpfl',
    mitre = 'mitre',
}

export const GSL_CLAUSES = {
    WHERE: 'where',
    SUMMARIZE: 'summarize',
    FACET_BY: 'facet by',
    JOIN_MITRE: 'join mitre',
    ORDER_BY: 'order by',
    LIMIT: 'limit',
    COUNT: 'count',
    DISTINCT_COUNT: 'dcount',
    UNIX_TO_DATETIME: 'unixtime_milliseconds_todatetime',
    JOIN: 'join',
    BIN: 'bin',
};
