import { IconButton } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const CarouselWrapper = styled.div``;

export const ChildrenWrapper = styled.div<{ gap: number }>`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: auto;
    gap: ${(props) => `${props.gap}px`};
    scroll-behavior: smooth;
`;

export const ChildWrapper = styled.span`
    display: flex;
    & > * {
        width: 100%;
    }
`;

export const PaginationDotsWrapper = styled.div`
    display: flex;
    gap: 4px;
    min-width: 30px;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
`;

export const PaginationDot = styled.div<{ selected: boolean }>`
    width: 10px;
    height: 10px;
    border: 1px solid rgb(37, 51, 70);
    border-radius: 50%;
    background-color: ${(props) => (props.selected ? 'rgb(37, 51, 70)' : '')};
    cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
`;

export const ArrowButton = styled(IconButton)<{ direction: string }>`
    opacity: 0;

    position: absolute;
    z-index: 200;
    top: 50%;

    transition: transform 0.2s ease-in-out;
    transition: 0.1s opacity linear;

    &&&:hover {
        opacity: 1;
    }

    ::before {
        position: absolute;
        left: -15px;
        right: -15px;
        top: -15px;
        bottom: -15px;
        content: '';
    }
`;

export const ArrowLeft = styled(ArrowButton)`
    left: 0;
    transform: translateY(-50%) translateX(-80%) rotate(180deg);
`;

export const ArrowRight = styled(ArrowButton)`
    right: 0;
    transform: translateY(-50%) translateX(80%);
`;

export const CarouselBody = styled.div`
    position: relative;

    &:hover {
        ${ArrowLeft} {
            opacity: 0.8;
            transform: translateY(-50%) translateX(-50%) rotate(180deg);
            transition: transform 0.2s ease-in-out;
        }

        ${ArrowRight} {
            opacity: 0.8;
            transform: translateY(-50%) translateX(50%);
            transition: transform 0.2s ease-in-out;
        }
    }

    ::before {
        position: absolute;
        left: -20px;
        right: -20px;
        top: -20px;
        content: '';
    }
`;
