import { ChangeEvent, FC, useEffect, useState } from 'react';
import { I18nExclusion, TagsEnum } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { Stack, Button, Label, Input } from 'common/design-system/components-v2';
import { IExclusionTagsProps, ITags } from '../../helpers/exclusions.interfaces';
import { formatTags } from '../../helpers/exclusions.utils';

const ExclusionByTags: FC<IExclusionTagsProps> = ({
    isEnabled,
    onChange,
    selectedOption,
    tooltip,
    initialSelectedOption,
}) => {
    const { t } = useTranslation(I18nExclusion);
    const [tagList, setTagList] = useState<ITags[]>([]);

    useEffect(() => {
        if (initialSelectedOption && initialSelectedOption.length) {
            const formattedTags = formatTags(initialSelectedOption as string[]);
            setTagList(formattedTags);
        }
    }, [initialSelectedOption]);

    useEffect(() => {
        tagList?.length && onChange(tagList);
    }, [tagList, onChange]);

    const handleAddTagClick = () => {
        setTagList([...tagList, { key: '', value: '' }]);
    };

    const handleRemoveTag = (index: number) => {
        const updatedTagList = tagList.filter((tag, i) => i !== index);
        setTagList(updatedTagList);
    };

    const handleTagsChange = (index: number, TagsEnum: string, e: ChangeEvent<HTMLInputElement>) => {
        const updatedTagList = tagList.map((tag, i) => {
            if (i === index) {
                return { ...tag, [TagsEnum]: e.target.value };
            }
            return tag;
        });
        setTagList(updatedTagList);
    };

    const tagListItems =
        tagList?.length > 0 &&
        tagList.map((tag, index) => {
            return (
                <Stack
                    key={index}
                    direction={'row'}
                    spacing={3}
                    padding={[0, 0, 0, 6]}
                    alignItems={'flex-end'}
                    fullWidth
                >
                    <Input
                        value={tag.key}
                        onChange={(e) => handleTagsChange(index, TagsEnum.KEY, e)}
                        label={index === 0 ? t('MODAL.TOPICS.TAGS.KEY') : undefined}
                        placeholder={t('MODAL.TOPICS.TAGS.ENTER_KEY')}
                        fullWidth
                        dataAid={`exclusion-tags-key-${index}`}
                    />
                    <Input
                        value={tag.value}
                        onChange={(e) => handleTagsChange(index, TagsEnum.VALUE, e)}
                        label={index === 0 ? t('MODAL.TOPICS.TAGS.VALUE') : undefined}
                        placeholder={t('MODAL.TOPICS.TAGS.ENTER_KEY')}
                        fullWidth
                        dataAid={`exclusion-tags-value-${index}`}
                    />
                    <Button
                        variant={'outlined'}
                        onClick={() => handleRemoveTag(index)}
                        iconProps={{ name: 'remove' }}
                        fullWidth={false}
                        style={{ height: '32px', width: '32px' }}
                        dataAid={`exclusion-delete-button-${index}`}
                    />
                </Stack>
            );
        });

    return (
        <Stack spacing={2}>
            <Label text={t('MODAL.TOPICS.TAGS.TITLE')} tooltip={tooltip} />
            {tagListItems}
            <Stack padding={[0, 0, 0, 6]}>
                <Button
                    variant={'outlined'}
                    onClick={() => handleAddTagClick()}
                    label={t('MODAL.TOPICS.TAGS.ADD_TAG')}
                    disabled={!isEnabled}
                    iconProps={{ name: 'plus' }}
                    data-testid={'add-tag-button'}
                />
            </Stack>
        </Stack>
    );
};
export default ExclusionByTags;
