import SvgIcon from './SvgIcon';
import { Label, Stack } from '../../../design-system/components-v2';
import { SvgIconExampleStyled } from './SvgIcon.example.styled';

const SvgIconExample = () => {
    const svgExampleUrl = 'aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_User_48_Light.svg';

    return (
        <Stack spacing={2} direction={'column'}>
            <Label text={'Example: SVG customizable icon'} size={'xl'} />
            <SvgIconExampleStyled spacing={2} direction={'row'} alignItems={'center'}>
                <SvgIcon svgUrl={svgExampleUrl} dataAid={'svg-icon-example1'} size={48} />
                <SvgIcon svgUrl={svgExampleUrl} dataAid={'svg-icon-example2'} size={48} className={'red-halo'} />
                <SvgIcon svgUrl={svgExampleUrl} dataAid={'svg-icon-example3'} size={48} className={'green-halo'} />
                <SvgIcon svgUrl={svgExampleUrl} dataAid={'svg-icon-example4'} size={48} className={'dark-path'} />
            </SvgIconExampleStyled>
        </Stack>
    );
};

export default SvgIconExample;
