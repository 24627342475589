import {
    FilterTreeItemType,
    IFilterTreeCondition,
    IFilterTreeItem,
    IFilterTreeNode,
    IMovingTreeItemInfo,
} from './FilterTree.interface';
import { CompoundFilterLogicalOperator, ICompoundFilter } from './CompoundFilter';

export const getFlatItems = (item: IFilterTreeItem): IFilterTreeItem[] => {
    let items: IFilterTreeItem[] = [item];
    if (item.itemType === FilterTreeItemType.node) {
        const node: IFilterTreeNode = item as IFilterTreeNode;
        for (let i = 0; i < node.childItems.length; i++) {
            items = [...items, ...getFlatItems(node.childItems[i])];
        }
    }
    return items;
};

export const isLastItemInParent = (item: IFilterTreeItem): boolean => {
    if (!item.parentNode) {
        return false;
    }
    return item.parentNode.childItems[item.parentNode.childItems.length - 1].id === item.id;
};

export const getPathItems = (treeItem: IFilterTreeItem): IFilterTreeItem[] => {
    if (!treeItem.parentNode) {
        return [treeItem];
    }
    const parentPath = getPathItems(treeItem.parentNode);
    return [...parentPath, treeItem];
};

export const getTreeRoot = (treeItem: IFilterTreeItem): IFilterTreeNode => {
    const pathItems: IFilterTreeItem[] = getPathItems(treeItem);
    if (pathItems.length === 0) {
        return treeItem as IFilterTreeNode;
    }

    return pathItems[0] as IFilterTreeNode;
};

export const findTreeItemIndexInParent = (item: IFilterTreeItem): number => {
    if (!item.parentNode) {
        return -1;
    }
    return item.parentNode.childItems.findIndex((anItem) => anItem.id === item.id);
};

export const getMovingTreeItemInfo = (
    root: IFilterTreeNode,
    movingItemId: string,
    targetItemId: string,
): IMovingTreeItemInfo | undefined => {
    if (movingItemId === targetItemId) {
        return undefined;
    }

    const movingItem: IFilterTreeItem | undefined = findTreeItemById(root, movingItemId);
    if (!movingItem?.parentNode) {
        return undefined;
    }

    const targetItem: IFilterTreeItem | undefined = findTreeItemById(root, targetItemId);
    if (!targetItem) {
        return undefined;
    }

    const targetParentPath: IFilterTreeItem[] = getPathItems(targetItem);
    if (targetParentPath.some((item) => item.id === movingItemId)) {
        return undefined;
    }

    if (targetItem.itemType === FilterTreeItemType.condition) {
        if (!targetItem.parentNode) {
            return undefined;
        }

        return {
            movingItem,
            oldParent: movingItem.parentNode,
            newParent: targetItem.parentNode,
            indexInNewParent: findTreeItemIndexInParent(targetItem) + 1,
        };
    }

    if (movingItem.parentNode.id === targetItem.id && findTreeItemIndexInParent(movingItem) === 0) {
        return undefined;
    }

    return {
        movingItem,
        oldParent: movingItem.parentNode,
        newParent: targetItem as IFilterTreeNode,
        indexInNewParent: 0,
    };
};

export const moveTreeItem = (root: IFilterTreeNode, movingItemId: string, targetItemId: string): boolean => {
    const info: IMovingTreeItemInfo | undefined = getMovingTreeItemInfo(root, movingItemId, targetItemId);
    if (!info) {
        return false;
    }

    const { movingItem, oldParent, newParent, indexInNewParent } = info;
    oldParent.childItems = oldParent.childItems.filter((item) => item.id !== movingItem.id);
    if (newParent.childItems.length <= indexInNewParent) {
        newParent.childItems.push(movingItem);
    } else {
        newParent.childItems.splice(indexInNewParent, 0, movingItem);
    }
    movingItem.parentNode = newParent;
    return true;
};

export const findTreeItemById = (topItem: IFilterTreeItem, id: string): IFilterTreeItem | undefined => {
    if (topItem.id === id) {
        return topItem;
    }

    if (topItem.itemType === FilterTreeItemType.node) {
        const node: IFilterTreeNode = topItem as IFilterTreeNode;
        for (let i = 0; i < node.childItems.length; i++) {
            const innerItem = findTreeItemById(node.childItems[i], id);
            if (innerItem) {
                return innerItem;
            }
        }
    }
};

export const findTreeConditionById = (topItem: IFilterTreeItem, id: string): IFilterTreeCondition | undefined => {
    const itemResult = findTreeItemById(topItem, id);
    return itemResult?.itemType === FilterTreeItemType.condition ? (itemResult as IFilterTreeCondition) : undefined;
};

export const findTreeNodeById = (topItem: IFilterTreeItem, id: string): IFilterTreeNode | undefined => {
    const itemResult = findTreeItemById(topItem, id);
    return itemResult?.itemType === FilterTreeItemType.node ? (itemResult as IFilterTreeNode) : undefined;
};

export const createDefaultCompoundFilter = (): ICompoundFilter => {
    return {
        root: {
            logicalOperator: CompoundFilterLogicalOperator.AND,
            operands: [],
        },
    };
};
