import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import IconLinkCellRenderer from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { SyntheticEvent } from 'react';
import { changeUrl } from 'common/utils/http';
import { IExclusion } from '../exclusions.type';

export const getColumnDefs = () => [
    {
        id: 'checkbox',
        colId: 'checkbox',
        headerName: '',
        checkboxSelection: true,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
    },
    {
        id: 'ruleSet',
        colId: 'ruleSet',
        field: 'ruleSet',
        headerName: 'Rule Set',
        sortable: true,
        enableRowGroup: true,
        cellRenderer: (params: ICellRendererParams) => (
            <IconLinkCellRenderer
                value={params.value}
                isLink={true}
                href={''}
                onClick={(event: SyntheticEvent) => {
                    event.preventDefault();
                    changeUrl(`/compliance-engine/policy/${params.data.rulesetId}`);
                }}
            />
        ),
        flex: 1,
    },
    {
        id: 'ruleName',
        colId: 'ruleName',
        field: 'ruleName',
        headerName: 'Rule Name',
        sortable: true,
        enableRowGroup: true,
        flex: 1,
    },
    {
        id: 'environment',
        colId: 'environment',
        field: 'environment',
        headerName: 'Environment/OU',
        sortable: true,
        enableRowGroup: true,
        flex: 1,
    },
    {
        id: 'filter',
        colId: 'filter',
        field: 'filter',
        headerName: 'Filter',
        sortable: true,
        flex: 3,
    },
    {
        id: 'dateRange',
        colId: 'dateRange',
        field: 'dateRangeString',
        headerName: 'Date Range',
        enableRowGroup: true,
        flex: 1,
    },
    {
        id: 'severity',
        colId: 'severities',
        field: 'severities',
        headerName: 'Severity',
        sortable: true,
        flex: 1,
    },
    {
        id: 'comment',
        colId: 'comment',
        field: 'comment',
        headerName: 'Comment',
        sortable: true,
        flex: 3,
    },
];

export const getGridOptions = (exclusions: IExclusion[]): GridOptions => {
    return {
        columnDefs: getColumnDefs(),
        rowSelection: 'single',
        rowData: exclusions,
        onRowClicked(event) {
            const rowNode = event.node;
            const isSelected = rowNode.isSelected();
            rowNode.setSelected(!isSelected);
        },
    };
};
