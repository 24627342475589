import {
    GSL_RUN_URL,
    GSL_SERVICE_ID,
    IGslService,
    IGslServiceParams,
} from 'common/module_interface/intelligence/intelligence';
import {
    IColumnMapper,
    IGslRunRequest,
    IGslRunResponse,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    convertFacetsToAggregations,
    createGetAggregationsQuery,
    createGetCountQuery,
    createGetItemsQuery,
} from './GslServiceQueries';
import { IGslCount } from 'common/components/gsl/GslService.interface';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { sendHttpRequestNoCache } from '../../erm-components/utils/ermComponents.http';
import isArray from 'lodash/isArray';

const getItemsFromResponse = <T>(response: IGslRunResponse): T[] => {
    const columnMapper: IColumnMapper = {};
    Object.entries(response.cols).forEach(([fieldName, colIndex]) => {
        columnMapper[fieldName] = colIndex;
    });

    return response.data.map((row: any) => {
        const serverItem: Partial<T> = {};
        Object.entries(columnMapper).forEach(([fieldName, colIndex]) => {
            serverItem[fieldName as keyof T] = row[colIndex];
        });
        return serverItem as T;
    });
};

export class GslService implements IGslService {
    public async getItems<R, T>(params: IGslServiceParams<T>): Promise<R[]> {
        const { filter, limit, sort, join, options, additionalParams } = params;
        const gslQuery = createGetItemsQuery(filter, limit, sort, join);
        const response: IGslRunResponse = await sendHttpRequestNoCache<IGslRunResponse>(GSL_RUN_URL, {
            method: 'POST',
            data: {
                ...additionalParams,
                gsl: gslQuery,
                withCredentials: true,
                options,
            },
        });
        return getItemsFromResponse(response);
    }

    public async getItemsByRequest<T>(request: IGslRunRequest): Promise<T[]> {
        const response: IGslRunResponse = await sendHttpRequestNoCache<IGslRunResponse>(GSL_RUN_URL, {
            method: 'POST',
            data: request,
        });
        return getItemsFromResponse(response);
    }

    public async getCount<R, T>(params: IGslServiceParams<T>, count: IGslCount): Promise<R[]> {
        const { filter, limit, sort, join, additionalParams, options } = params;
        const gslQuery = createGetCountQuery(filter, count, join, sort, limit);
        const result: IGslRunResponse = await sendHttpRequestNoCache<IGslRunResponse>(GSL_RUN_URL, {
            method: 'POST',
            data: {
                ...additionalParams,
                gsl: gslQuery,
                withCredentials: true,
                options,
            },
        });
        return getItemsFromResponse(result);
    }

    public async getAggregations<T>(params: IGslServiceParams<T>, aggregations: string[]): Promise<Aggregations> {
        // Note: there is an any here and a small workaround to fix a backend issue, it's supposed to be fixed in
        // the server, and then we will be able to remove this
        const { filter, join, additionalParams, options } = params;
        const gslQuery = createGetAggregationsQuery(filter, aggregations, join);
        const result: IGslRunResponse | IGslRunResponse[] = await sendHttpRequestNoCache<
            IGslRunResponse | IGslRunResponse[]
        >(GSL_RUN_URL, {
            method: 'POST',
            data: {
                ...additionalParams,
                gsl: gslQuery,
                withCredentials: true,
                options,
            },
        });
        const facets: IGslRunResponse[] = [];
        if (!isArray(result)) {
            facets.push(result as IGslRunResponse);
        } else {
            facets.push(...(result as IGslRunResponse[]));
        }
        return convertFacetsToAggregations(aggregations, facets);
    }
}

export default function initializeGslService() {
    globalAddinContainer.addService(GSL_SERVICE_ID, new GslService());
}
