export const getDateMinus = (pastDaysAmount: number) => {
    const dateMinus = new Date();
    dateMinus.setDate(dateMinus.getDate() - pastDaysAmount);
    return dateMinus;
};

export const getFutureDate = (addDaysAmount: number) => {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + addDaysAmount);
    return futureDate;
};

export const getDaysFromToday = (dateString: string): number => {
    const inputDate = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const difference = inputDate.getTime() - today.getTime();
    return Math.floor(difference / (1000 * 60 * 60 * 24));
};

export const formatDateAndTime = (date: Date | string): string => {
    if (!date) return '';
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${month} ${day} ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatDate = (date: Date | string): string => {
    if (!date) return '';
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
};
