import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    AlibabaEnvironmentData,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_ALIBABA_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_ALIBABA_TAB,
    PATH_ENVIRONMENT_PAGE_ALIBABA_CHIP,
} from 'common/module_interface/assets/AlibabaEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { AddinBase } from './addinBase';
import { EnvironmentAction } from 'common/module_interface/assets/Environments';

export interface AlibabaEnvironmentAddingBase<T, T2 extends [AlibabaEnvironmentData] = [AlibabaEnvironmentData]>
    extends AddinBase<T, T2> {
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

function addDetailsPanelItems(item: Addin<AlibabaEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): AlibabaEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_DETAILS_PANEL));
}

function addTabs(item: Addin<AlibabaEnvironmentAddingBase<Tab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_TAB), item);
}

function getTabs(): AlibabaEnvironmentAddingBase<Tab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_TAB));
}

function addActions(item: Addin<AlibabaEnvironmentAddingBase<EnvironmentAction<[AlibabaEnvironmentData]>>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): AlibabaEnvironmentAddingBase<EnvironmentAction<[AlibabaEnvironmentData]>>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<AlibabaEnvironmentAddingBase<IChipProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_CHIP), item);
}

function getChips(): AlibabaEnvironmentAddingBase<IChipProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ALIBABA_CHIP));
}

export const AlibabaEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips,
};
