import { Pages } from 'common/enum/Pages';
import { getStoreService } from '../interface/services';
import DATA_CENTERS, { IDataCenter } from '../consts/data.centers';

export enum RuntimeEnvironmentType {
    LOCAL = 'local',
    STAGING = 'staging',
    PRODUCTION = 'production',
    CLOUD_INFRA_PROD = 'CloudInfra production',
    CLOUD_INFRA_STAGING = 'CloudInfra staging',
    UNKNOWN = 'unknown',
    PREQA = 'preqa',
    INSPECT = 'inspect',
}

export function getRuntimeEnvironmentType(): RuntimeEnvironmentType {
    const hostString = window.location.host;
    const ancestorOrigin = window.location !== window.parent.location ? document.referrer : document.location.href;
    if (hostString.startsWith('local.') && hostString.endsWith(':4000')) {
        return RuntimeEnvironmentType.LOCAL;
    }

    if (hostString.startsWith('localhost') && !hostString.includes(':')) {
        return RuntimeEnvironmentType.INSPECT;
    }

    if (hostString.startsWith('secure') && hostString.endsWith('.dome9.com')) {
        return RuntimeEnvironmentType.PRODUCTION;
    }

    if (hostString.startsWith('secure') && hostString.endsWith('dev.falconetix.com')) {
        return RuntimeEnvironmentType.PREQA;
    }

    if (hostString.startsWith('secure') && hostString.endsWith('.falconetix.com')) {
        return RuntimeEnvironmentType.STAGING;
    }

    if (ancestorOrigin?.includes('checkpoint.com')) {
        if (ancestorOrigin?.startsWith('https://q.')) {
            return RuntimeEnvironmentType.CLOUD_INFRA_STAGING;
        } else {
            return RuntimeEnvironmentType.CLOUD_INFRA_PROD;
        }
    }
    return RuntimeEnvironmentType.UNKNOWN;
}

export function getCurrentDataCenter(): IDataCenter | undefined {
    return DATA_CENTERS.find((dataCenter) => dataCenter.urls.includes(window.location.hostname));
}

export function getDataCenterDisplayName(): string {
    const dataCenter = getCurrentDataCenter();
    return dataCenter ? dataCenter.label : '';
}

export function getIsCloudInfra(): boolean {
    const runtimeEnvironmentType = getRuntimeEnvironmentType();
    return (
        runtimeEnvironmentType === RuntimeEnvironmentType.CLOUD_INFRA_PROD ||
        runtimeEnvironmentType === RuntimeEnvironmentType.CLOUD_INFRA_STAGING
    );
}

export function getIsCloudInfraAvailable(): boolean {
    if (getStoreService().state.app.cloudInfraUrl) {
        return true;
    }
    return false;
}

export function getRegistrationUrl(): string {
    const isCloudInfraAvailable = getIsCloudInfraAvailable();
    return isCloudInfraAvailable
        ? getStoreService().state.app.cloudInfraUrl + '/create-account'
        : new URL('.' + getCloudGuardRegistrationRoute(isCloudInfraAvailable), window.location.href).toString();
}

export function getCloudGuardRegistrationRoute(isCloudInfraAvailable: boolean): string {
    return isCloudInfraAvailable ? `/${Pages.Old_CG_RegisterPage_ForTesting}` : `/${Pages.Register}`;
}

export const isLocalhost =
    getRuntimeEnvironmentType() === RuntimeEnvironmentType.INSPECT ||
    getRuntimeEnvironmentType() === RuntimeEnvironmentType.LOCAL;
export const isPreQA = getRuntimeEnvironmentType() === RuntimeEnvironmentType.PREQA;

export const isNonProd = (): boolean => {
    const nonProdTypes = [
        RuntimeEnvironmentType.LOCAL,
        RuntimeEnvironmentType.STAGING,
        RuntimeEnvironmentType.CLOUD_INFRA_STAGING,
        RuntimeEnvironmentType.PREQA,
        RuntimeEnvironmentType.INSPECT,
    ];

    return nonProdTypes.includes(getRuntimeEnvironmentType());
};
