import { buildPath } from '../../../extensibility/AddinContainer';
import { IOrganizationalUnit, ICloudAccount } from '../../../interface/data_services';
import { Aggregations } from '../../../components/FilterPanel/FilterPanel.interface';
import { SentinelInputType } from './consts';
import React from 'react';

export const PATH_SETTINGS_PREFIX = buildPath('settings', 'integrations');
export const NEW_NOTIFICATIONS_PAGE_AF = 'new-integrations';

export interface IConfiguration {
    name: string;
    id?: string;
    configurationObj: any;
    AdvancedUrl?: boolean;
    AuthType?: string;
    IgnoreCertificate?: boolean;
    MethodType?: string;
    Password?: string;
    Url?: string;
    Username?: string;
}

export interface IGetAllConfigurationsModel {
    [key: string]: IConfiguration[];
}

export interface INewServiceAccountResponse {
    name: string;
    id: string;
    apiKeyId: string;
    apiKeySecret: string;
    roleIds: number[];
}

export interface IFindingsEvent {
    searchRequest: SearchRequest;
    findings: Finding[];
    totalFindingsCount: number;
    aggregations: FindingsAggregations;
    searchAfter: string[];
}

interface SearchRequest {
    searchAfter: any[];
    pageSize: number;
    skipAggregations: any;
    lowAggregationsSize: any;
    sorting: Sorting;
    multiSorting: any;
    filter: Filter;
    dataSource: string;
}

interface Sorting {
    fieldName: any;
    direction: number;
}

interface Filter {
    freeTextPhrase: any;
    fields: Field[];
    onlyCIEM: boolean;
    onlyCustomPolicy: boolean;
    creationTime: CreationTime;
    hasRemediation: any;
}

interface Field {
    name: string;
    value: string;
}

interface CreationTime {
    from: string;
    to: string;
}

interface Finding {
    id: string;
    findingKey: string;
    createdTime: string;
    updatedTime: string;
    cloudAccountType: string;
    comments: any[];
    cloudAccountId: string;
    cloudAccountExternalId: string;
    organizationalUnitId: string;
    organizationalUnitPath: string;
    bundleId: number;
    bundleVersion?: string;
    alertType: string;
    ruleId: string;
    ruleName: string;
    ruleLogic: string;
    entityDome9Id: string;
    entityExternalId: string;
    entityType: string;
    entityTypeByEnvironmentType: string;
    entityName: string;
    entityNetwork?: string;
    entityTags?: any[];
    severity: string;
    description: string;
    remediation: string;
    tag: string;
    region?: string;
    bundleName: string;
    acknowledged: boolean;
    origin: string;
    lastSeenTime: string;
    ownerUserName: any;
    magellan: any;
    isExcluded: boolean;
    webhookResponses: any;
    remediationActions: any[];
    additionalFields: AdditionalField[];
    occurrences: any[];
    scanId: any;
    status: string;
    statusReason: string;
    category: string;
    action: string;
    labels?: any[];
}

interface AdditionalField {
    name: string;
    value: string;
}

interface FindingsAggregations {
    severity: CountableValue[];
    cloudAccountId_calc: CountableValue[];
    alertType: CountableValue[];
    acknowledged: CountableValue[];
    cloudAccountType: CountableValue[];
    entityType: CountableValue[];
    isExcluded: CountableValue[];
    origin: CountableValue[];
    entityTypeByEnvironmentType: CountableValue[];
    labels: CountableValue[];
    action: CountableValue[];
    ownerUserName: CountableValue[];
    ruleName: CountableValue[];
    bundleName: CountableValue[];
    entityNetwork: CountableValue[];
    category: CountableValue[];
    region: CountableValue[];
    entity: CountableValue[];
}

interface CountableValue {
    value: string | number;
    count: number;
}

export interface CloudGuardRole {
    users: CloudGuardRoleUser[];
    serviceAccounts: CloudGuardRoleServiceAccount[];
    id: number;
    name: string;
    description: string;
    permissions: CloudGuardRolePermissions;
}

interface CloudGuardRolePermissions {
    access: string[];
    manage: string[];
    rulesets: string[];
    notifications: string[];
    policies: string[];
    alertActions: string[];
    create: string[];
    view: string[];
    onBoarding: string[];
    crossAccountAccess: string[];
}
interface CloudGuardRoleServiceAccount {
    serviceAccountName: string;
    serviceAccountId: string;
}
interface CloudGuardRoleUser {
    userId: number;
    userName: string;
}

export interface IIntegrationsOrganizationalUnit {
    id: string;
    name: string;
    path: string;
    children?: IIntegrationsOrganizationalUnit[];
}

export interface IFiltersInitialData {
    organizationalUnits?: IOrganizationalUnit;
    allCloudAccounts?: ICloudAccount[];
}

export interface IIntegrationsFilterParams {
    aggregations: Aggregations;
    filtersInitialData: IFiltersInitialData;
    savedFiltersComponentName?: string;
}

export interface StepComponentProps {
    toggleModal: () => void;
    handleCopyDataCenter: () => void;
    onFilterValueChange: (filtersValues: SentinelInputType) => void;
    textAreaValue: string;
    dataCenterUrl: string;
}

export interface stepOrderManagerProps {
    key: string;
    Component: React.FC<StepComponentProps>;
    title?: string;
    subTitle?: string;
    props: StepComponentProps;
}
