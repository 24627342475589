import React, { useCallback, useEffect, useState } from 'react';
import { computeRangeFromSelection, convertOldDateObjToNew, DEFAULT_RANGES_VALUES } from './DateFilter.consts';
import { FILTER_BOX_DISPLAY_TYPES, FILTER_DISPAY_TYPES, FILTER_EVENTS } from '../../FilterPanel.consts';
import { IExtendDateFilterProps } from '../DefaultFilters.interface';
import Accordion from '../../GeneralComponents/Accordion/Accordion';
import { isIncludeSearchTerm, renderAccordionClearButton } from '../../filterUtils';
import { useTranslation } from 'react-i18next';
import DateFilterStyles from './DateFilter.styles';
import { Button, DatePickerBase, Dropdown, List, Stack, Tabs } from 'common/design-system/components-v2';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';

export const DateFilter: React.FC<{ filterProps: IExtendDateFilterProps }> = ({ filterProps }) => {
    const { t } = useTranslation();
    const { options, value, key, defaultValue, onEvent, searchTerm, title, displayType, limitations } = filterProps;
    const [selectedFilterOption, setSelectedFilterOption] = useState(value || defaultValue);
    const [lastSelectedRange, setLastSelectedRange] = useState<IDateRange>();
    const [selectedTabId, setSelectedTabId] = useState(1);
    const [canApplyDateRange, setCanApplyDateRange] = useState(true);
    const handleValueChangedMemo = useCallback(handleValueChanged, [defaultValue, value]);
    function setStartDateLimitation() {
        const startDate = new Date();
        limitations?.maxDaysBack
            ? startDate.setDate(startDate.getDate() - limitations?.maxDaysBack)
            : startDate.setDate(startDate.getDate() - 20000);
        return startDate;
    }
    const [isOpenRowView, setIsOpenRowView] = React.useState<boolean>(false);
    const [isOpenSeperateView, setIsOpenSeperateView] = React.useState<boolean>(false);

    const rangeFromLimitation = setStartDateLimitation();

    useEffect(() => {
        handleValueChangedMemo();
    }, [filterProps.value, handleValueChangedMemo]);

    useEffect(() => {
        if (selectedFilterOption.epoch) {
            const asDateRange: IDateRange | undefined = selectedFilterOption.epoch && {
                from: new Date(selectedFilterOption.epoch.from),
                to: new Date(selectedFilterOption.epoch.to),
            };

            setLastSelectedRange(asDateRange);
        } else {
            const initialRange = computeRangeFromSelection(selectedFilterOption);
            const asDateRange: IDateRange = {
                from: new Date(initialRange.from),
                to: new Date(initialRange.to),
            };
            setLastSelectedRange(asDateRange);
        }
    }, [selectedFilterOption]);

    function handleValueChanged() {
        const selectedFilter = value || defaultValue;
        setSelectedFilterOption(
            selectedFilter.isNewDateFilter ? selectedFilter : convertOldDateObjToNew(selectedFilter),
        );
    }

    const handleFilterOptionSelected = (selectedValue: any) => {
        if (selectedValue.key !== selectedFilterOption.key) {
            setIsOpenSeperateView(false);
            onEvent!({ action: FILTER_EVENTS.FILTER_CHANGED, filterKey: key, payload: selectedValue });
        }
    };

    const handleCustomDateRangeSelected = (selectedValue?: IDateRange) => {
        setLastSelectedRange(selectedValue);
        if (selectedValue && selectedValue.to && selectedValue.from && limitations?.maxDaysSelected) {
            const timeRangeInMS = selectedValue.to.getTime() - selectedValue.from.getTime();
            const daysRange = Math.ceil(timeRangeInMS / (1000 * 3600 * 24));
            setCanApplyDateRange(limitations?.maxDaysSelected > daysRange);
        } else {
            setCanApplyDateRange(false);
        }
    };

    const applyDateChanges = () => {
        const selectedRange = {
            from: lastSelectedRange?.from?.toISOString(),
            to: lastSelectedRange?.to?.toISOString(),
        };
        const selectedOption = {
            displayName:
                lastSelectedRange?.from?.toLocaleDateString() + '-' + lastSelectedRange?.to?.toLocaleDateString(),
            epoch: selectedRange,
            key: DEFAULT_RANGES_VALUES.CUSTOM.key,
            isNewDateFilter: true,
        };
        setIsOpenSeperateView(false);
        onEvent!({ action: FILTER_EVENTS.FILTER_CHANGED, filterKey: key, payload: selectedOption });
    };

    const renderDateFilter = () => {
        const items = options.map<IListItemProps>((option) => {
            return {
                label: option.displayName,
                value: option.key,
                selected: option.key === selectedFilterOption.key,
            };
        });

        return (
            <DateFilterStyles.Wrapper>
                <Tabs
                    tabs={[
                        {
                            value: 0,
                            label: t('FILTER_PANEL.DATE_FILTER.TABS.PRESETS'),
                        },
                        {
                            value: 1,
                            label: t('FILTER_PANEL.DATE_FILTER.TABS.CUSTOM'),
                        },
                    ]}
                    selectedTab={selectedTabId}
                    onTabSelected={(tab, index) => setSelectedTabId(index)}
                />
                {selectedTabId === 0 && (
                    <List
                        options={items}
                        onOptionClick={(option) => {
                            const selectedVal = options.find((opt) => opt.key === option.value);
                            handleFilterOptionSelected(selectedVal);
                        }}
                    />
                )}
                {selectedTabId === 1 && (
                    <Stack alignItems='flex-end' spacing={2}>
                        <DatePickerBase
                            mode='range'
                            selected={lastSelectedRange}
                            disabled={[{ before: rangeFromLimitation }, { after: new Date() }]}
                            onSelect={(date) => handleCustomDateRangeSelected(date)}
                        />
                        <Button
                            color='brandPrimary'
                            disabled={!canApplyDateRange}
                            tooltip={
                                canApplyDateRange
                                    ? ''
                                    : t('FILTER_PANEL.DATE_FILTER.DAY_RANGE_LIMITATION', {
                                          limit: limitations?.maxDaysSelected,
                                      })
                            }
                            label={t('FILTER_PANEL.DATE_FILTER.APPLY')}
                            onClick={applyDateChanges}
                        />
                    </Stack>
                )}
            </DateFilterStyles.Wrapper>
        );
    };

    const renderBoxViewDateFilter = () => {
        return (
            shouldShowFilterBySearch() && (
                <Accordion
                    title={title}
                    content={renderDateFilter()}
                    optionsList={[
                        renderAccordionClearButton(() =>
                            onEvent({
                                action: FILTER_EVENTS.CLEAR_FILTERS,
                                filterKey: key,
                                payload: '',
                            }),
                        ),
                    ]}
                />
            )
        );
    };
    const renderRowViewDateFilter = () => {
        return (
            <div className='filter-panel__date-filter'>
                <Dropdown
                    placement='bottom-start'
                    open={isOpenRowView}
                    onStateChange={(state) => setIsOpenRowView(state)}
                    label={t(selectedFilterOption.displayName)}
                    buttonProps={{ iconProps: { name: 'calendar' }, size: 'small', dataAid: 'date-filter' }}
                    maxHeight={500}
                >
                    {renderDateFilter()}
                </Dropdown>
            </div>
        );
    };
    const renderSeparateViewDateFilter = () => {
        return (
            <div className='flex flex-column mb-7'>
                <div className='font-semibold mb-3'>{t('FILTER_PANEL.DATE_FILTER.DATE_FILTER_LABEL')}:</div>
                <div className='inline-block border border-inherit mr-4 w-fit'>
                    <Dropdown
                        placement='bottom-start'
                        open={isOpenSeperateView}
                        onStateChange={(state) => setIsOpenSeperateView(state)}
                        label={t(selectedFilterOption.displayName)}
                        buttonProps={{ iconProps: { name: 'calendar' }, size: 'small', dataAid: 'date-filter' }}
                        maxHeight={500}
                    >
                        {renderDateFilter()}
                    </Dropdown>
                </div>
            </div>
        );
    };

    const shouldShowFilterBySearch = () => {
        return searchTerm ? isIncludeSearchTerm(searchTerm, title) : true;
    };

    const renderView = () => {
        switch (displayType) {
            case FILTER_DISPAY_TYPES.SEPARATE_FIELDS:
                return renderSeparateViewDateFilter();
                break;
            case FILTER_DISPAY_TYPES.ROW:
                return renderRowViewDateFilter();
                break;
            case FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1:
                return renderBoxViewDateFilter();
                break;
        }
    };

    return <>{renderView()}</>;
};

export default DateFilter;
