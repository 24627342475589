import styled from 'styled-components';

const TopDiv = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`;

const BulletDiv = styled.div<{ value: boolean }>`
    display: flex;
    border-radius: 6px;
    width: 6px;
    height: 6px;
    background: ${({ value, theme }) => (value ? theme.palette.onSurface.normal : 'transparent')};
    margin: auto;
`;

export const CellStyled = {
    TopDiv,
    BulletDiv,
};
