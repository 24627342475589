import styled, { css } from 'styled-components';
import Icon from '../Icon/Icon';

interface StyledIconProps {
    $isOpen: boolean;
}
const StyledIcon = styled(Icon)<StyledIconProps>`
    ${({ $isOpen }) =>
        $isOpen &&
        css`
            transform: rotate(180deg);
        `};
`;

const DropdownStyles = {
    StyledIcon,
};

export default DropdownStyles;
