import { forwardRef } from 'react';
import { WizardProps } from './Wizard.types';
import WizardHeader from './WizardHeader';
import WizardStyles from './Wizard.styled';
import WizardFooter from './WizardFooter/WizardFooter';
import WizardContentStyles from './WizardContent/WizardContent.styled';
import Stepper from '../Stepper';

const WizardUI = forwardRef<HTMLDivElement, WizardProps>((props, ref) => {
    const { title, onClose, info, steps, currentStep, footer, fullHeight, showStepper = true } = props;
    if (steps.length === 0) {
        return null;
    }
    const currentStepContent = steps[currentStep].component;
    const footerComponent = steps[currentStep].footer || footer;
    const isShowStepper =
        typeof steps[currentStep].showStepper === 'boolean' ? steps[currentStep].showStepper : showStepper;
    return (
        <WizardStyles.Wrapper fullHeight={fullHeight} ref={ref}>
            <WizardHeader title={title} onClose={onClose} info={info} />
            <WizardContentStyles.Wrapper ref={ref} direction='row' padding={6} spacing={6} fullHeight>
                {isShowStepper && (
                    <WizardContentStyles.StepperWrapper fullHeight>
                        <Stepper steps={steps} currentStep={currentStep} />
                    </WizardContentStyles.StepperWrapper>
                )}
                <WizardContentStyles.ContentWrapper>{currentStepContent}</WizardContentStyles.ContentWrapper>
            </WizardContentStyles.Wrapper>
            {footerComponent && <WizardFooter spacing={2}>{footerComponent}</WizardFooter>}
        </WizardStyles.Wrapper>
    );
});

WizardUI.displayName = 'WizardUI';

export default WizardUI;
