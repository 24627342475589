import React from 'react';
import { ISavedFilter } from '../SavedFilters.interface';
import { SavedFiltersListProps } from './SavedFiltersList.interface';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { List } from 'common/design-system/components-v2';
import SavedFilterListStyled from './SavedFilterList.styled';

const SavedFiltersList: React.FC<SavedFiltersListProps> = ({
    savedFiltersByCategories,
    selectedFilterID,
    onFilterItemSelected,
    onFilterItemDeleted,
}) => {
    const handleItemDelete = (filter: ISavedFilter) => {
        onFilterItemDeleted(filter);
    };

    type GetFiltersList = () => Array<IListItemProps>;
    const getFiltersList: GetFiltersList = () => {
        const options: Array<IListItemProps> = [];
        Object.keys(savedFiltersByCategories).forEach((title) => {
            if (savedFiltersByCategories[title].length > 0) {
                options.push({ label: title, itemType: 'seperator', value: `${title}-sep` });
                savedFiltersByCategories[title].forEach((item) => {
                    options.push({
                        label: item.data.name,
                        value: item.id,
                        selected: item.id === selectedFilterID,
                        onClear: () => handleItemDelete(item),
                        onClick: () => onFilterItemSelected(item.data.state, item.id),
                    });
                });
            }
        });
        return options;
    };

    return (
        <SavedFilterListStyled.Wrapper overflow='auto'>
            <List options={getFiltersList()} />
        </SavedFilterListStyled.Wrapper>
    );
};

export default SavedFiltersList;
