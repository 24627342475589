import { StatusTypes } from 'common/common_types';
import { IDashboardWidget, IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';

export const generateColors = (length: number) => {
    const colors = [];
    for (let index = 0; index < length; index++) {
        colors.push(`hsla(${index * -24 - 30}, 70%, ${(index * 1) / length + 50}%, 1)`);
    }
    return colors;
};

export const getColors = (items: IGenericWidgetDataItem[]) => {
    const itemAlternateColor = generateColors(items?.length);
    return items?.map((item, index) => item.color || itemAlternateColor[index]);
};

export const getGradientBackgroundColorClass = (statusColor: StatusTypes): string => {
    // this function with switch is mandatory for tailwind to use gradient background color.
    // I know it looks ugly but this is how tailwind works ):

    const baseClass = 'theme theme-dark-primary bg-gradient-radial';
    switch (statusColor) {
        case 'warning':
            return `${baseClass} from-warning-default to-warning-dark`;
        case 'danger':
            return `${baseClass} from-danger-default to-danger-dark`;
        case 'success':
            return `${baseClass} from-success-default to-success-dark`;
        case 'info':
            return `${baseClass} from-info-default to-info-dark`;
        default:
            return '';
    }
};

export const getTextColorClass = (statusColor: StatusTypes): string => {
    // this function with switch is mandatory for tailwind to use gradient background color.
    // I know it looks ugly but this is how tailwind works ):

    switch (statusColor) {
        case 'warning':
            return 'text-warning';
        case 'danger':
            return 'text-danger';
        case 'success':
            return 'text-success';
        case 'info':
            return 'text-info';
        default:
            return '';
    }
};

export const limitDataItems = (
    genericWidgetDataItems: IGenericWidgetDataItem[],
    limit: number,
): IGenericWidgetDataItem[] => {
    const maxItemsToShow = limit || genericWidgetDataItems.length - 1;
    const colorsArr = generateColors(genericWidgetDataItems.length);
    return genericWidgetDataItems.reduce((result: any, dataItem, index) => {
        dataItem.color = dataItem.color || colorsArr[index];
        if (index < maxItemsToShow) {
            result.push(dataItem);
        } else if (index === maxItemsToShow) {
            dataItem.key = 'other';
            dataItem.onclick = undefined;
            result.push(dataItem);
        } else {
            result[maxItemsToShow].value = result[maxItemsToShow].value + dataItem.value;
        }
        return result;
    }, []);
};

export const formatNumbersForDisplay = (value: any) => {
    return typeof value === 'number'
        ? value.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        : value;
};

export const compareTimeFunction = (a: any, b: any) => {
    return a.localeCompare(b);
};

export const compareTimeFunctionWithKeys = (a: any, b: any) => {
    return a.key.localeCompare(b.key);
};

export const buildProtectedAssetsWidgetLink = (widget: IDashboardWidget, item: any) => {
    // TODO: Fix types of widget and item
    const filterState: any[] = widget.filterState;
    const filterPanelObject: any = {};
    filterState.forEach((filter: any) => {
        if (filter.value || filter.value === 0) {
            if (filterPanelObject[filter.name]) {
                filterPanelObject[filter.name].push(filter.value);
            } else {
                if (filter.name === 'free-text') {
                    filterPanelObject[filter.name] = filter.value;
                } else {
                    filterPanelObject[filter.name] = [filter.value];
                }
            }
        }
    });
    if (widget.aggregation && !filterPanelObject[widget.aggregation]) {
        filterPanelObject[widget.aggregation] = [item.value];
    }
    const filterPanelStr = JSON.stringify(filterPanelObject);
    return `/protected-asset/index?filterPanel=${filterPanelStr}`;
};
