import { CommonLevelPalette, FullColorsPalette, ThemePalette } from './theme.types';

export const colors: FullColorsPalette = {
    blue: {
        50: '#ebf4ff',
        75: '#acd3ff',
        100: '#8ac1ff',
        200: '#57a6ff',
        300: '#3594ff',
        400: '#2568b3',
        500: '#205a9c',
    },
    error: {
        50: '#feeded',
        75: '#fab6b6',
        100: '#f79898',
        200: '#f46c6c',
        300: '#f24e4e',
        400: '#a93737',
        500: '#943030',
    },
    success: {
        50: '#edfce8',
        75: '#b7f1a1',
        100: '#99ec7a',
        200: '#6de440',
        300: '#3ba713',
        400: '#2f850f',
        500: '#24640b',
    },
    warning: {
        50: '#fff8ea',
        75: '#ffe1a9',
        100: '#ffd585',
        200: '#ffc351',
        300: '#ffb72d',
        400: '#b3801f',
        500: '#9c701b',
    },
    grey: {
        0: '#ffffff',
        10: '#fcfdfd',
        20: '#f9fafb',
        30: '#F6F7FA',
        40: '#E8EDF5',
        50: '#DBE0E9',
        60: '#D2D8E3',
        70: '#CBD2DF',
        80: '#C2CBDA',
        90: '#B9C3D4',
        100: '#7A8699',
        200: '#6B788E',
        300: '#5D6B82',
        400: '#505F79',
        500: '#42526D',
        600: '#354764',
        700: '#243757',
        800: '#15294B',
        900: '#091E42',
        1000: '#000000',
    },
    elementary: {
        dangerCritical: {
            darkest: '#800020',
            darker: '#B8002E',
            dark: '#F0003C',
            regular: '#FF295E',
            light: '#FF668C',
            lighter: '#FF9EB6',
            lightest: '#FFD6E0',
        },
        danger: {
            darkest: '#B81F00',
            darker: '#EB2700',
            dark: '#FF401A',
            regular: '#FF6A4D',
            light: '#FF9480',
            lighter: '#FFBBAD',
            lightest: '#FFE6E0',
        },
        warning: {
            darkest: '#8A4500',
            darker: '#CC6600',
            dark: '#FA7D00',
            regular: '#FF9933',
            light: '#FFB266',
            lighter: '#FFCF9E',
            lightest: '#FFF1D6',
        },
        warningLow: {
            darkest: '#7A6600',
            darker: '#AD9100',
            dark: '#D0B00B',
            regular: '#E8C930',
            light: '#EED868',
            lighter: '#F9E994',
            lightest: '#F4F5BC',
        },
        success: {
            darkest: '#005000',
            darker: '#216C21',
            dark: '#428842',
            regular: '#63A563',
            light: '#85C285',
            lighter: '#A8E0A8',
            lightest: '#D6FFD6',
        },
        info: {
            darkest: '#205A9C',
            darker: '#2568B3',
            dark: '#3594FF',
            regular: '#57A6FF',
            light: '#8AC1FF',
            lighter: '#CCE4FF',
            lightest: '#EBF4FF',
        },
        standout: {
            darkest: '#6B00B8',
            darker: '#8000DB',
            dark: '#AA33FF',
            regular: '#C16BFF',
            light: '#D293FF',
            lighter: '#E1B7FF',
            lightest: '#F2E0FF',
        },
    },
};

const commonLevelPalette: CommonLevelPalette = {
    criticalFg: colors.elementary.dangerCritical.darker,
    criticalBg: colors.elementary.dangerCritical.lightest,
    highFg: colors.elementary.danger.dark,
    highBg: colors.elementary.danger.lightest,
    mediumFg: colors.elementary.warning.dark,
    mediumBg: colors.elementary.warning.lightest,
    unknownFg: colors.grey['500'],
    unknownBg: colors.grey['30'],
};

export function addAlpha(color: string, opacity: number) {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

export const lightPallete: ThemePalette = {
    mode: 'light',
    common: {
        black: colors.grey['1000'],
        white: colors.grey['0'],
    },
    surface: {
        primary: colors.grey['0'],
        secondary: colors.grey['30'],
        tertiary: colors.grey['40'],
        normal: colors.grey['40'],
        strong: colors.grey['50'],
        blue: colors.blue['500'],
        brandPrimary: colors.blue['300'],
        brandLight: colors.blue['50'],
        warningLight: colors.warning['50'],
        alertLight: colors.error['50'],
        successLight: colors.success['50'],
        alert: colors.error['300'],
        success: colors.success['300'],
        warning: colors.warning['300'],
    },
    onSurface: {
        light: colors.grey['200'],
        normal: colors.grey['500'],
        strong: colors.grey['800'],
        overlay: addAlpha(colors.grey['600'], 0.2),
        reverse: colors.grey['0'],
        brandPrimary: colors.blue['300'],
        alert: colors.error['300'],
        warning: colors.warning['300'],
        success: colors.success['300'],
    },
    surfaceStates: {
        primaryHover: colors.grey['20'],
        primaryFocus: colors.grey['20'],
        primaryActive: colors.grey['30'],
        secondaryHover: colors.grey['40'],
        secondaryFocus: colors.grey['40'],
        secondaryActive: colors.grey['50'],
        tertiaryHover: colors.grey['50'],
        tertiaryFocus: colors.grey['50'],
        tertiaryActive: colors.grey['60'],
        normalHover: colors.grey['60'],
        normalFocus: colors.grey['60'],
        normalActive: colors.grey['70'],
        strongHover: colors.grey['60'],
        strongFocus: colors.grey['60'],
        strongActive: colors.grey['70'],
        brandPrimaryHover: '#3085E6',
        brandPrimaryFocus: '#3085E6',
        brandPrimaryActive: '#2A76CC',
        brandLightHover: '#E1EFFF',
        brandLightFocus: '#E1EFFF',
        brandLightActive: '#C0DEFF',
        alertHover: '#DA4546',
        alertFocus: '#DA4546',
        alertActive: '#C23E3E',
        alertLightHover: '#FDE4E4',
        alertLightFocus: '#FDE4E4',
        alertLightActive: '#FBC8C8',
        warningHover: '#E6A529',
        warningFocus: '#E6A529',
        warningActive: '#CC9224',
        warningLightHover: '#FFF4E0',
        warningLightFocus: '#FFF4E0',
        warningLightActive: '#FFE9BE',
        successHover: colors.success['400'],
        successFocus: colors.success['400'],
        successActive: colors.success['500'],
        successLightHover: '#E5FADD',
        successLightFocus: '#E5FADD',
        successLightActive: '#C8F5B8',
    },
    componentStates: {
        ghostHover: colors.grey['40'],
        ghostFocus: colors.grey['40'],
        ghostActive: '#C0DEFF',
        ghostDoubleActive: '#B7D6F8',
    },
    border: {
        light: colors.grey['50'],
        normal: colors.grey['60'],
        strong: colors.grey['70'],
        success: colors.success['300'],
        warning: colors.warning['300'],
        alert: colors.error['300'],
        information: colors.blue['300'],
        primary: colors.grey['0'],
    },
    text: {
        strong: colors.grey['800'],
        normal: colors.grey['500'],
        light: colors.grey['200'],
        helper: colors.grey['100'],
        link: colors.blue['400'],
        brandPrimary: colors.blue['300'],
        success: colors.success['300'],
        warning: colors.warning['300'],
        alert: colors.error['300'],
        inverse: colors.grey['0'],
    },
    brandColor: '#DA1572',
    status: {
        error: colors.error['300'],
        offline: colors.grey['50'],
        info: colors.blue['300'],
        success: colors.success['300'],
        warning: colors.warning['300'],
    },
    severity: {
        criticalFg: commonLevelPalette.criticalFg,
        criticalBg: commonLevelPalette.criticalBg,
        highFg: commonLevelPalette.highFg,
        highBg: commonLevelPalette.highBg,
        mediumFg: commonLevelPalette.mediumFg,
        mediumBg: commonLevelPalette.mediumBg,
        lowFg: colors.elementary.warningLow.dark,
        lowBg: colors.elementary.warningLow.lightest,
        informationalFg: colors.elementary.info.dark,
        informationalBg: colors.elementary.info.lightest,
        unknownFg: commonLevelPalette.unknownFg,
        unknownBg: commonLevelPalette.unknownBg,
    },
    riskLevel: {
        criticalFg: commonLevelPalette.criticalFg,
        criticalBg: commonLevelPalette.criticalBg,
        highFg: commonLevelPalette.highFg,
        highBg: commonLevelPalette.highBg,
        mediumFg: commonLevelPalette.mediumFg,
        mediumBg: commonLevelPalette.mediumBg,
        lowFg: colors.elementary.success.dark,
        lowBg: colors.elementary.success.lightest,
        unknownFg: commonLevelPalette.unknownFg,
        unknownBg: commonLevelPalette.unknownBg,
    },
    importance: {
        criticalFg: colors.grey['0'],
        criticalBg: colors.elementary.standout.darker,
        highFg: colors.grey['0'],
        highBg: colors.elementary.standout.regular,
        mediumFg: colors.elementary.standout.darker,
        mediumBg: colors.elementary.standout.lightest,
        lowFg: colors.grey['500'],
        lowBg: colors.grey['30'],
        unknownFg: colors.grey['500'],
        unknownBg: colors.grey['30'],
    },
    graph: {
        list: [
            '#259BE5',
            '#DA1E83',
            '#F7D133',
            '#8E24AA',
            '#0EB7A7',
            '#EB8C09',
            '#3949AB',
            '#D983C9',
            '#A77740',
            '#A5C159',
            '#1579DD',
            '#B772CB',
        ],
        other: '#A3A9AD',
    },
};

export const darkPallete: ThemePalette = {
    mode: 'dark',
    common: {
        black: colors.grey['1000'],
        white: colors.grey['0'],
    },
    surface: {
        primary: colors.grey['900'],
        secondary: colors.grey['800'],
        tertiary: colors.grey['700'],
        normal: colors.grey['700'],
        strong: colors.grey['400'],
        blue: colors.blue['500'],
        brandPrimary: colors.blue['300'],
        brandLight: colors.blue['500'],
        warningLight: colors.warning['500'],
        alertLight: colors.error['500'],
        successLight: colors.success['500'],
        alert: colors.error['300'],
        success: colors.success['300'],
        warning: colors.warning['300'],
    },
    onSurface: {
        light: colors.grey['80'],
        normal: colors.grey['40'],
        strong: colors.grey['10'],
        overlay: addAlpha(colors.grey['100'], 0.7),
        reverse: colors.grey['0'],
        brandPrimary: colors.blue['300'],
        alert: colors.error['300'],
        warning: colors.warning['300'],
        success: colors.success['300'],
    },
    surfaceStates: {
        primaryHover: colors.grey['800'],
        primaryFocus: colors.grey['700'],
        primaryActive: colors.grey['600'],
        secondaryHover: colors.grey['700'],
        secondaryFocus: colors.grey['600'],
        secondaryActive: colors.grey['500'],
        tertiaryHover: colors.grey['600'],
        tertiaryFocus: colors.grey['500'],
        tertiaryActive: colors.grey['400'],
        normalHover: colors.grey['300'],
        normalFocus: colors.grey['300'],
        normalActive: colors.grey['200'],
        strongHover: colors.grey['300'],
        strongFocus: colors.grey['300'],
        strongActive: colors.grey['200'],
        brandPrimaryHover: colors.blue['200'],
        brandPrimaryFocus: colors.blue['200'],
        brandPrimaryActive: colors.blue['100'],
        brandLightHover: colors.blue['400'],
        brandLightFocus: colors.blue['400'],
        brandLightActive: '#2A75CA',
        alertHover: colors.error['200'],
        alertFocus: colors.error['200'],
        alertActive: colors.error['100'],
        alertLightHover: colors.error['400'],
        alertLightFocus: colors.error['400'],
        alertLightActive: '#C34040',
        warningHover: colors.warning['200'],
        warningFocus: colors.warning['200'],
        warningActive: colors.warning['100'],
        warningLightHover: '#996E1B',
        warningLightFocus: '#735214',
        warningLightActive: '#594010',
        successHover: colors.success['400'],
        successFocus: colors.success['400'],
        successActive: colors.success['500'],
        successLightHover: colors.success['400'],
        successLightFocus: colors.success['400'],
        successLightActive: colors.success['300'],
    },
    componentStates: {
        ghostHover: colors.grey['700'],
        ghostFocus: colors.grey['700'],
        ghostActive: '#286FBF',
        ghostDoubleActive: '#327ED3',
    },
    border: {
        light: colors.grey['500'],
        normal: colors.grey['300'],
        strong: colors.grey['200'],
        success: colors.success['300'],
        warning: colors.warning['300'],
        alert: colors.error['300'],
        information: colors.blue['300'],
        primary: colors.grey['900'],
    },
    text: {
        strong: colors.grey['10'],
        normal: colors.grey['40'],
        light: colors.grey['80'],
        helper: colors.grey['90'],
        link: colors.blue['200'],
        brandPrimary: colors.blue['300'],
        success: colors.success['300'],
        warning: colors.warning['300'],
        alert: colors.error['300'],
        inverse: colors.grey['0'],
    },
    brandColor: '#DA1572',
    status: {
        error: colors.error['300'],
        offline: colors.grey['50'],
        info: colors.blue['300'],
        success: colors.success['300'],
        warning: colors.warning['300'],
    },
    severity: lightPallete.severity,
    riskLevel: lightPallete.riskLevel,
    importance: lightPallete.importance,
    graph: {
        list: [
            '#259BE5',
            '#DA1E83',
            '#F7D133',
            '#8E24AA',
            '#0EB7A7',
            '#EB8C09',
            '#3949AB',
            '#D983C9',
            '#A77740',
            '#A5C159',
            '#1579DD',
            '#B772CB',
        ],
        other: '#A3A9AD',
    },
};
