import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    OciEnvironmentData,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_OCI_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_OCI_TAB,
    PATH_ENVIRONMENT_PAGE_OCI_CHIP,
} from 'common/module_interface/assets/OciEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { AddinBase } from './addinBase';
import { EnvironmentAction } from 'common/module_interface/assets/Environments';

export interface OciEnvironmentAddingBase<T, T2 extends [OciEnvironmentData] = [OciEnvironmentData]>
    extends AddinBase<T, T2> {
    customLoadingLabel?: string; // Relevant for chips only at the moment
}

function addDetailsPanelItems(item: Addin<OciEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): OciEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_DETAILS_PANEL));
}

function addTabs(item: Addin<OciEnvironmentAddingBase<Tab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_TAB), item);
}

function getTabs(): OciEnvironmentAddingBase<Tab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_TAB));
}

function addActions(item: Addin<OciEnvironmentAddingBase<EnvironmentAction<[OciEnvironmentData]>>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): OciEnvironmentAddingBase<EnvironmentAction<[OciEnvironmentData]>>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<OciEnvironmentAddingBase<IChipProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_CHIP), item);
}

function getChips(): OciEnvironmentAddingBase<IChipProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_OCI_CHIP));
}

export const OciEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips,
};
