import React, { useCallback, useMemo } from 'react';
import {
    IFilterTreeCommonProps,
    IFilterTreeCondition,
    IFilterTreeFieldDefinition
} from '../FilterTree.interface';
import { FilterStyled } from './FilterTree.styled';
import { useTranslation } from 'react-i18next';
import { FilterConditionOperator, IFilterConditionValue } from '../FilterCondition';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { SelectV2, Stack } from 'common/design-system/components-v2';
import { useDraggable } from '@dnd-kit/core';

export const FilterTreeCondition: React.FC<{
    condition: IFilterTreeCondition,
    filterProps: IFilterTreeCommonProps
    }> =
    ({ condition, filterProps }) => {
        const { t } = useTranslation();
        const { attributes, listeners, setNodeRef, transform } = useDraggable({
            id: condition.id,
        });
        const style = transform ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        } : undefined;
        const filterDef = useMemo(() => filterProps.filterDefinitions.find(def => def.name === condition.name), [condition.name, filterProps.filterDefinitions]);

        const nameOptions: SelectOption[] = useMemo(() => {
            return filterProps.filterDefinitions.map(def => {
                return {
                    label: def.header,
                    value: def.name,
                };
            });
        }, [filterProps.filterDefinitions]);

        const opOptions: SelectOption[] = useMemo(() => {
            const supportedOperators: FilterConditionOperator[] = filterDef ? filterDef.conditionOperatorsInfo.operators : [];
            return supportedOperators.map(op => {
                return {
                    label: t(`FILTER_CONDITION_OPERATOR.${op}`),
                    value: String(op),
                };
            });
        }, [filterDef, t]);

        const onNameChange = useCallback((newName: string) => {
            const newFilterDef: IFilterTreeFieldDefinition | undefined = filterProps.filterDefinitions.find(def => def.name === newName);
            const newCond: IFilterTreeCondition = {
                ...condition,
                name: newName,
                operator: newFilterDef ? newFilterDef.conditionOperatorsInfo.defaultOperator : undefined,
                values: [],
            };
            filterProps.api.onConditionChange(newCond);
        }, [condition, filterProps.api, filterProps.filterDefinitions]);

        const onOperatorChange = useCallback((operator: FilterConditionOperator) => {
            const newCond = {
                ...condition,
                operator,
            };
            filterProps.api.onConditionChange(newCond);
        }, [condition, filterProps]);

        const onValuesChange = useCallback((values: IFilterConditionValue[]) => {
            const newCond: IFilterTreeCondition = {
                ...condition,
                values,
            };
            filterProps.api.onConditionChange(newCond);
        }, [condition, filterProps]);

        return (
            <FilterStyled.ConditionDiv ref={setNodeRef} style={style}>
                {!filterProps.readOnly && <FilterStyled.DragHandle {...listeners} {...attributes}/> }
                <Stack direction={'row'} spacing={4} fullWidth>
                    <FilterStyled.SelectContainer width={filterProps.settings?.conditionFieldWidth ?? '160px'}>
                        <SelectV2
                            fullWidth
                            value={condition.name}
                            onChange={(value: string) => onNameChange(value)}
                            placeholder={filterProps.readOnly ? undefined : t('FILTER_TREE.FIELD_PLACEHOLDER')}
                            options={nameOptions}
                            isError={!!(filterProps.displayErrors && condition.nameErrorMsg)}
                            isMulti={false}
                            readOnly={filterProps.readOnly}
                        /></FilterStyled.SelectContainer>
                    <FilterStyled.SelectContainer width={filterProps.settings?.conditionOperatorWidth ?? '110px'}>
                        <SelectV2
                            fullWidth
                            value={condition.operator}
                            onChange={(value: string) => onOperatorChange(value as FilterConditionOperator)}
                            placeholder={''}
                            options={opOptions}
                            isError={!!(filterProps.displayErrors && filterDef && condition.operatorErrorMsg)}
                            disabled={!filterDef}
                            isMulti={false}
                            readOnly={filterProps.readOnly}
                        /></FilterStyled.SelectContainer>
                    {filterDef && <filterDef.conditionValuesComponent onValuesChange={onValuesChange} condition={condition} filterProps={filterProps} />}
                    {!filterDef &&
                        <FilterStyled.InputDiv
                            fullWidth
                            style={{ flexGrow: '1' }}
                            placeholder={filterProps.readOnly ? undefined : t('FILTER_TREE.VALUE_PLACEHOLDER')}
                            readOnly={filterProps.readOnly}
                            disabled
                        />}
                </Stack>
            </FilterStyled.ConditionDiv>
        );
    };
