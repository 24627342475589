import { FC, useCallback } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { FieldLabelComponent } from '../helperComponents/FieldLabelComponent';
import { convertDateToValue, convertValueToDate } from '../../CustomForm.values';
import { IFullErrorInfo, IOnFieldChanged, ISingleDateItem } from '../../CustomForm.interface';
import { DatePicker } from '../../../../../design-system/components-v2';
import ItemComponentBottomArea from '../helperComponents/ItemComponentBottomArea';

export interface ISingleDateItemComponentProps {
    item: ISingleDateItem;
    onFieldChanged: IOnFieldChanged;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    skipTitle?: boolean;
    isInner?: boolean;
    now: Date;
}

export const SingleDateItemComponent: FC<ISingleDateItemComponentProps> = ({
    item,
    onFieldChanged,
    errorInfo,
    showErrors,
    skipTitle,
    isInner,
    now,
}) => {
    const { state } = item;

    const onChange = useCallback(
        (date: Date | undefined) => {
            onFieldChanged({ ...item, state: { ...item.state, value: convertDateToValue(date) } });
        },
        [item, onFieldChanged],
    );

    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            {!skipTitle && <FieldLabelComponent item={item} />}
            <DatePicker
                type={'single'}
                value={convertValueToDate(state.value)}
                onChange={onChange}
                disabled={state.disabled}
                readOnly={state.readonly}
                datePickerProps={{
                    fromDate: state.allowPast ? undefined : now,
                }}
            />
            <ItemComponentBottomArea state={state} showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};
