import { buildPath, getService } from 'common/extensibility/AddinContainer';
import { ICloudAccount, IIntelligenceCloudAccount } from '../../interface/data_services';
import { IElasticEvent } from '../elasticEvents/ElasticEvent.interface';
import {
    IAcknowledgeRequest,
    IArchiveRequest,
    IAssignUserRequest,
    IChangeSeverityRequest,
    ICloseRequest,
    ICommentRequest,
    IGslRunFacetResponse,
    IGslRunPaginationOption,
    IGslRunRequest,
    IGslRunResponse,
    IIntelligencePolicy,
    IntelligenceRule,
    IntelligenceRuleset,
    IPolicyDto,
    IUpdatePolicyDto,
} from './Intelligence.interface';
import { IKustoCountEvent, IKustoEvent } from '../../components/KustoEvents/KustoEvent.interface';
import { IGslCount, IGslFilter, IGslJoin, IGslSort } from 'common/components/gsl/GslService.interface';
import { IFindingGslFilter } from 'common/module_interface/intelligence/Gsl/FindingGslService.interface';
import { Aggregations } from '../../components/FilterPanel/FilterPanel.interface';
import { IFieldInfo } from '../../interface/general';
import { TimeFilter } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { ICountLog, ILog } from './Logs/Logs.interface';

export const INTELLIGENCE_SERVICE_ID = 'IntelligenceService';
export const MAGELLAN_LINK_GENERATOR_SERVICE_ID = 'MagellanLinkGeneratorService';
export const GSL_SERVICE_ID = 'GslService';
export const KUSTO_EVENT_SERVICE_ID = 'KustoEventService';
export const CDR_URLS_SERVICE_ID = 'CdrUrlsService';
export const LOGS_SERVICE_ID = 'LogsService';

export const INTELLIGENCE = 'intelligence';
export const INTELLIGENCE_TABLE = buildPath(INTELLIGENCE, 'table');
export const INTELLIGENCE_TABLE_FILTERS = buildPath(INTELLIGENCE_TABLE, 'filters');

export const NETWORK_TRAFFIC = 'network-traffic';
export const NETWORK_TRAFFIC_TABLE = buildPath(NETWORK_TRAFFIC, 'table');
export const NETWORK_TRAFFIC_TABLE_FILTERS = buildPath(NETWORK_TRAFFIC_TABLE, 'filters');

export const GSL_RUN_URL = 'gslws/gsl-run?action=fetch';

export interface IIntelligenceService {
    getGSLRunDataCount(data: IGslRunRequest): Promise<number>;

    getGSLRunData(data: IGslRunRequest): Promise<IGslRunResponse>;

    getAllEnvironmentsOnboardedToAccountActivity(): Promise<ICloudAccount[]>;

    getAllEnvironmentsOnboardedToNetworkTraffic(): Promise<ICloudAccount[]>;

    getIntelligenceCloudAccounts(): Promise<IIntelligenceCloudAccount[]>;

    getIntelligenceFindingGraph(findingKey: string): Promise<any>;

    getIntelligenceFinding(findingKey: string): Promise<IElasticEvent | undefined>;

    getIntelligenceFacets(data: IGslRunRequest): Promise<IGslRunFacetResponse>;

    getIntelligenceFindingQuery(data: IGslRunRequest): Promise<IElasticEvent[]>;

    getIntelligenceRulesets(): Promise<IntelligenceRuleset[]>;

    getIntelligenceRules(rulesetId: number): Promise<IntelligenceRule[]>;

    savePolicies(payload: IPolicyDto): Promise<void>;

    getPolicies(useCache?: boolean): Promise<IIntelligencePolicy[]>;

    updatePolicy(payload: IUpdatePolicyDto): Promise<void>;

    acknowledgeFindings(request: IAcknowledgeRequest): Promise<void>;

    addComment(request: ICommentRequest): Promise<void>;

    closeFindings(request: ICloseRequest): Promise<void>;

    changeSeverity(request: IChangeSeverityRequest): Promise<void>;

    assignUser(request: IAssignUserRequest): Promise<void>;

    archiveFindings(request: IArchiveRequest): Promise<void>;
}

export interface IMagellanLinkGeneratorService {
    iamFunc(finding: string, feature: any): string,

    generateFilterForProtectedAssets(feature: string, asset: { type: string }): string,

    generateLinkForFinding(finding: IKustoEvent): string,
}

export function getMagellanLinkGeneratorService(): IMagellanLinkGeneratorService {
    return getService<IMagellanLinkGeneratorService>(MAGELLAN_LINK_GENERATOR_SERVICE_ID);
}

export function getIntelligenceService(): IIntelligenceService {
    return getService<IIntelligenceService>(INTELLIGENCE_SERVICE_ID);
}

export interface IGslServiceParams<T> {
    filter: IGslFilter;
    sort?: IGslSort[];
    join?: IGslJoin;
    limit?: number;
    additionalParams?: T;
    options?: {
        organizationalUnitIds?: string[];
    };
}

export interface IGslService {
    getItems: <R, T = undefined>(params: IGslServiceParams<T>) => Promise<R[]>;
    getItemsByRequest: <R>(request: IGslRunRequest) => Promise<R[]>;
    getCount: <R, T = undefined>(params: IGslServiceParams<T>, count: IGslCount) => Promise<R[]>;
    getAggregations: <T = undefined> (params: IGslServiceParams<T>, aggregations: string[]) => Promise<Aggregations>;
}

export function getGslService(): IGslService {
    return getService<IGslService>(GSL_SERVICE_ID);
}

export interface IKustoEventService {
    getKustoEvents: (filter: IFindingGslFilter, limit?: number, sort?: IGslSort[], join?: IGslJoin) => Promise<IKustoEvent[]>;
    getKustoEventsByRequest: (request: IGslRunRequest) => Promise<IKustoEvent[]>;
    getKustoEventById: (eventId: string, joinMitre?: boolean) => Promise<IKustoEvent>;
    getKustoEventFacets: (filter: IFindingGslFilter, aggregations: string[], join?: IGslJoin) => Promise<Aggregations>;
    getKustoCountEvents: (filter: IFindingGslFilter, count: IGslCount, join?: IGslJoin, sort?: IGslSort[], limit?: number) => Promise<IKustoCountEvent[]>;
}

export function getKustoEventService(): IKustoEventService {
    return getService<IKustoEventService>(KUSTO_EVENT_SERVICE_ID);
}

export interface ILogAdditionalParams {
    cloudAccount: string[],
    pagination?: IGslRunPaginationOption
}

export interface ILogsService {
    getLogs: (filter: IGslFilter, limit?: number, sort?: IGslSort[], additionalParams?: ILogAdditionalParams) => Promise<ILog[]>;
    getLogFacets: (filter: IGslFilter, aggregations: string[], additionalParams?: ILogAdditionalParams) => Promise<Aggregations>;
    getLogCount: (filter: IGslFilter, count: IGslCount, sort?: IGslSort[], additionalParams?: ILogAdditionalParams) => Promise<ICountLog[]>;
}

export function getLogsService(): ILogsService {
    return getService<ILogsService>(LOGS_SERVICE_ID);
}

export interface ICdrUrlsService {
    goToEventsTable(filterFields: IFieldInfo[], timeFilter?: TimeFilter, freeTextPhrase?: string): void;

    goToEnvironmentTable(filterFields?: IFieldInfo[]): void;
}

export function getCdrUrlsService(): ICdrUrlsService {
    return getService<ICdrUrlsService>(CDR_URLS_SERVICE_ID);
}
