import React from 'react';
import { Column, ColumnApi, ColumnRowGroupChangedEvent, GridApi } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { getCompsI18nNS } from 'common/design-system/initialize.i18n';
import GroupBySelectStyles from './GroupBySelect.styles';
import { Icon, SelectV2 } from 'common/design-system/components-v2';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';

export interface GroupBySelectProps {
    gridApi: GridApi | undefined;
    columnApi: ColumnApi | undefined;
    isLoading?: boolean;
}

const GroupBySelect: React.FC<GroupBySelectProps> = (props) => {
    const { gridApi, columnApi, isLoading } = props;
    const { t } = useTranslation(getCompsI18nNS('table'));

    const [options, setOptions] = React.useState<SelectOption[]>([]);
    const [values, setValues] = React.useState<string[]>([]);

    const onGroupByChange = React.useCallback(
        (values: string[]) => {
            if (!columnApi) return;
            columnApi.setRowGroupColumns(values);
            setValues(values);
        },
        [columnApi],
    );

    const setDefaultColumnsState = React.useCallback(() => {
        if (!columnApi) return;
        const columns = columnApi.getAllGridColumns();
        const columnsGrouped = columnApi.getRowGroupColumns();

        setOptions(
            columns
                .filter((column) => column.isAllowRowGroup())
                .map((column) => ({
                    label: column.getColDef().headerName || column.getColId(),
                    value: column.getColId(),
                })),
        );
        setValues(columnsGrouped.map((column) => column.getColId()));
    }, [columnApi]);

    const setActiveColumnsState = React.useCallback((columns: Column[]) => {
        setValues(columns.map((column) => column.getColId()));
    }, []);

    React.useEffect(() => {
        if (!gridApi) return;
        gridApi.addEventListener('columnRowGroupChanged', (event: ColumnRowGroupChangedEvent) => {
            setActiveColumnsState(event.columns || []);
        });

        return () => {
            gridApi.removeEventListener('columnRowGroupChanged', () => {
                setActiveColumnsState([]);
            });
        };
    }, [gridApi, setActiveColumnsState]);

    React.useEffect(() => {
        if (!gridApi || !columnApi) return;
        setDefaultColumnsState();
    }, [gridApi, columnApi, setDefaultColumnsState]);

    return (
        <GroupBySelectStyles.SelectWrapper data-aid='table-component_groupby-select'>
            <SelectV2
                startAdornment={<Icon name='groupColumns' />}
                isMulti
                clearable
                placeholder={t('TOOLBAR.GROUPBY')}
                onChange={onGroupByChange}
                options={options}
                value={values}
                disabled={isLoading}
                id='DS_Table_GroupBySelect'
                dataAid='DS_Table_GroupBySelect'
            />
        </GroupBySelectStyles.SelectWrapper>
    );
};

export default GroupBySelect;
