import styled, { css } from 'styled-components';

interface IJsonViewProps {
    fontSize: number;
    lineHeight: number;
}

interface IStyleProps {
    backgroundColor?: string;
}

interface ISpanProps {
    color?: string;
    backgroundColor?: string;
    transform?: string;
    display?: string;
    isTag?: boolean;
}

export const JsonRenderWrapper = styled.div<IJsonViewProps>`
    display: block;
    width: 100%;
    background-color: transparent;
    box-sizing: border-box;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: ${(props) => `${props.fontSize}px`};
    line-height: ${(props) => props.lineHeight};
    font-family: monospace;
    overflow: auto;
`;

export const JsonRow = styled.div<IStyleProps>`
    position: relative;
    text-align: left;
    padding-left: ${({ theme }) => `${theme.spacing(6)}${theme.units}`};
    padding-bottom: ${({ theme }) => `${theme.spacing(0.5)}${theme.units}`};
    margin: 0;
    background-color: ${(props) => props.backgroundColor};
`;

export const JsonP = styled.p<IStyleProps>`
    background-color: ${(props) => props.backgroundColor};
    position: relative;
    text-align: left;
    cursor: pointer;
    padding: 0;
    margin: 0;
`;

export const JsonFeet = styled.p`
    position: relative;
    text-align: left;
    padding: 0;
    margin: 0;
`;

export const Span = styled.span<ISpanProps>`
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    transform: ${(props) => props.transform};
    display: ${(props) => props.display};
    ${({ isTag }) =>
        isTag &&
        css`
            background-color: #f7f7f7;
            border-radius: 3px;
            padding: 0 2px;
            border: 1px solid #dedede;
        `};
`;

export const SpanMark = styled.span<ISpanProps>`
    position: absolute;
    left: 0;
    top: 2px;
    text-indent: 0;
    font-size: 12px;
    color: #545454;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    transform: ${(props) => props.transform};
    display: ${(props) => props.display};
`;

export const SpanChevron = styled.span<{ visible: boolean }>`
    transform: ${(props) => (props.visible ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: transform 0.3s;
    position: absolute;
    left: -15px;
    top: 5px;
`;
