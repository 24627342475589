import React, { ReactElement, useEffect, useState } from 'react';
import { SvgIconProps } from './SvgIcon.types';
import { getVendorIconsUrlSource, sanitizeElement } from './SvgIcon.utils';

const SvgIcon = React.forwardRef<HTMLAnchorElement, SvgIconProps>((props, ref) => {
    const { svgUrl, dataAid, className, size } = props;
    const [svgElement, setSvgElement] = useState<ReactElement | null>();

    useEffect(() => {
        const fetchSvg = async () => {
            try {
                const finalUrl = getVendorIconsUrlSource() + svgUrl;
                const svgResponse = await fetch(finalUrl);
                if (!svgResponse) {
                    setSvgElement(null);
                    return;
                }

                const svgText: string = await svgResponse.text();
                if (!svgText) {
                    setSvgElement(null);
                    return;
                }

                const parser = new DOMParser();
                const svgDoc: Document = parser.parseFromString(svgText, 'image/svg+xml');
                const svgNode: SVGSVGElement | null = svgDoc.querySelector('svg');
                if (!svgNode) {
                    setSvgElement(null);
                    return;
                }
                const sanitizedSvg = sanitizeElement(svgNode.outerHTML.trim());

                setSvgElement(React.createElement('svg', { dangerouslySetInnerHTML: { __html: sanitizedSvg } }));
            } catch (error) {
                setSvgElement(null);
            }
        };

        void fetchSvg();
    }, [svgUrl]);

    return (
        <div
            ref={ref as any}
            data-aid={dataAid}
            className={className}
            style={{ width: `${size}px`, height: `${size}px` }}
        >
            {svgElement}
        </div>
    );
});

export default SvgIcon;
