import { Spinner } from 'common/design-system/components-v2';
import { useTheme } from 'styled-components';
import React from 'react';
import { FullPageLoaderStyled } from './FullPageLoader.styled';

const FullPageLoader: React.FC<{ withOverlay?: true }> = ({ withOverlay }) => {
    const theme = useTheme();
    return (
        <FullPageLoaderStyled.Overlay withOverlay={withOverlay ?? false}>
            <Spinner color={theme.palette.info.iconColor} size={48}/>
        </FullPageLoaderStyled.Overlay>
    );
};

export default FullPageLoader;