import styled from 'styled-components';
import { css, DefaultTheme } from 'styled-components';
import { StatusType } from './StatusMessagePanel.types';

const handleStatusColor = (type: StatusType, theme: DefaultTheme) => {
    switch (type) {
        case StatusType.SUCCESS:
            return {
                color: theme.palette.surface.successLight,
                border: theme.palette.border.success,
            };
        case StatusType.ERROR:
            return {
                color: theme.palette.surface.alertLight,
                border: theme.palette.border.alert,
            };
        default:
            return {
                color: theme.palette.surface.brandLight,
                border: theme.palette.border.information,
            };
    }
};

export const StatusMessageWrapper = styled.div<{ type: StatusType }>`
    ${(props) =>
        props.theme &&
        css`
            display: flex;
            line-height: 18px;
            background-color: ${handleStatusColor(props.type, props.theme).color};
            border: ${'1px solid' + handleStatusColor(props.type, props.theme).border};
            padding: ${props.theme.spacing(2)}${props.theme.units} ${props.theme.spacing(3)}${props.theme.units};

            i {
                margin-right: ${props.theme.spacing(2)}${props.theme.units};
                margin-top: ${props.theme.spacing(0.5)}${props.theme.units};
            }
        `};
`;

export const StatusMessageContent = styled.div`
    width: fit-content;
`;
