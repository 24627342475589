import { GenericObject, IColumnUsageDef } from 'common/interface/general';
import { buildPath, getService } from '../../extensibility/AddinContainer';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { ColDef } from 'ag-grid-enterprise';
import { IWidgetConfigFilterState } from '../intelligence/Intelligence.interface';
import { getFindingSeverityInfo } from '../../consts/FindingSeverity';
import { IElasticEvent } from '../elasticEvents/ElasticEvent.interface';
import React from 'react';
import { AllIconsName } from '../../design-system/components-v2/Icon/Icon.types';
import { IKustoEvent } from '../../components/KustoEvents/KustoEvent.interface';
import { FindingStatusCode } from '../../components/KustoEvents/KustoEvent.const';
import { AxiosResponse } from 'axios';
import {
    ComplianceFindingAcknowledge,
    ComplianceFindingArchive,
    ComplianceFindingAssign,
    ComplianceFindingClose,
    ComplianceFindingComment,
    ComplianceFindingGroupsByProperties,
    ComplianceFindingSearch,
    ComplianceFindingSearchWithCustomAggregations,
    ComplianceFindingSeverity,
} from 'common/module_interface/events/complianceFindingApi.interface';
import { ICachingConfig } from '../../interface/services';

export const EVENTS_SERVICE_ID = 'EventsService';
export const COMPLIANCE_FINDING_API_SERVICE_ID = 'ComplianceFindingApiService';
export const PATH_EVENTS_FILTERS_DEFINITIONS = buildPath('events', 'filters_definitions');
export const PATH_EVENTS_WIDGETS_FILTERS = buildPath('events', 'widgets_filters');
export const PATH_RULE_AGGREGATION_FILTERS = buildPath('events', 'rule_aggregation_filters');
export const PATH_EVENTS_TREND_WIDGETS_FILTERS = buildPath('events', 'widgets_events_trend_filters');

export interface IFiltersInitialData {
    recentlyUsed?: { [key: string]: string | object }[];
    savedFilters?: { [key: string]: string | object }[];
    organizationalUnits?: IOrganizationalUnit;
}

export interface IEventsFilterParams {
    aggregations: Aggregations;
    filtersInitialData?: IFiltersInitialData;
    allCloudAccounts?: ICloudAccount[];
}

export interface IEventsFilter {
    id: string;
    filterProps?: GenericObject<any>;
}

export interface IEventsService {
    getFilterDefs(filters: IEventsFilter[], params: IEventsFilterParams): IFilterProps[];
    getColumnDefs(columnUsages: IColumnUsageDef[]): ColDef[];
}

export function getEventsService(): IEventsService {
    return getService<IEventsService>(EVENTS_SERVICE_ID);
}

export interface IComplianceFindingService {
    addComment(request: ComplianceFindingComment.Request): Promise<AxiosResponse<ComplianceFindingComment.Response>>;
    changeSeverity(
        request: ComplianceFindingSeverity.Request,
    ): Promise<AxiosResponse<ComplianceFindingSeverity.Response>>;
    acknowledge(
        request: ComplianceFindingAcknowledge.Request,
    ): Promise<AxiosResponse<ComplianceFindingAcknowledge.Response>>;
    assign(request: ComplianceFindingAssign.Request): Promise<AxiosResponse<ComplianceFindingAssign.Response>>;
    close(request: ComplianceFindingClose.Request): Promise<AxiosResponse<ComplianceFindingClose.Response>>;
    archive(request: ComplianceFindingArchive.Request): Promise<AxiosResponse<ComplianceFindingArchive.Response>>;
    unarchive(request: ComplianceFindingArchive.Request): Promise<AxiosResponse<ComplianceFindingArchive.Response>>;
    search(
        request: ComplianceFindingSearch.Request,
        cachingConfig?: ICachingConfig,
    ): Promise<AxiosResponse<ComplianceFindingSearch.Response>>;
    searchWithCustomAggregations(
        request: ComplianceFindingSearchWithCustomAggregations.Request,
        cachingConfig?: ICachingConfig,
    ): Promise<AxiosResponse<ComplianceFindingSearchWithCustomAggregations.Response>>;
    groupsByProperties(
        request: ComplianceFindingGroupsByProperties.Request,
        cachingConfig?: ICachingConfig,
    ): Promise<AxiosResponse<ComplianceFindingGroupsByProperties.Response>>;
}

export function getComplianceFindingApiService(): IComplianceFindingService {
    return getService(COMPLIANCE_FINDING_API_SERVICE_ID);
}

export const getEventsOvertimeFilterString = (widgetConfigFilterStates: IWidgetConfigFilterState[]): string | null => {
    if (!widgetConfigFilterStates?.length) {
        return null;
    }

    enum KeyNameToFilterValue {
        'cloudAccountType' = 'env.cloudAccountType',
        'bundleName' = 'bundle.name',
        'origin' = 'origin',
        'severity' = 'rule.severity',
    }

    const filtersObject = widgetConfigFilterStates.reduce(
        (result: { [key: string]: string[] }, filterItem: IWidgetConfigFilterState) => {
            const filterName = filterItem.name;
            let value = null;
            if (filterName === 'severity') {
                const severityValue = getFindingSeverityInfo(filterItem.value)?.level || 0;
                value = `'${severityValue}'`;
            } else {
                value = `'${filterItem.value}'`;
            }
            if (result[filterName] && result[filterName].length && typeof filterItem.value !== 'undefined') {
                result[filterName].push(value);
            } else if (!result[filterName] && (filterItem.value || filterItem.value === 0)) {
                result[filterName] = [value];
            }
            return result;
        },
        {},
    );

    if (Object.keys(filtersObject).length > 0) {
        return Object.keys(filtersObject).reduce((result: string, filterName, index: number) => {
            const isLastFilterItem = index === Object.keys(filtersObject).length - 1;
            const values: string[] = filtersObject[filterName as keyof typeof filtersObject];
            const expression = ` ${KeyNameToFilterValue[filterName as keyof typeof KeyNameToFilterValue]} in (${values})`;

            if (isLastFilterItem) {
                result += expression;
            } else {
                result += `${expression} and`;
            }
            return result;
        }, 'alerts where');
    } else {
        return null;
    }
};

export interface IEventOverviewPanelProvider {
    panelId: string;
    panel: React.FC<{ event: IKustoEvent }>;
    isRelevant?: (finding: IKustoEvent) => boolean;
}

export enum SysEventType {
    elastic,
    kusto,
}

export type IEvent<T extends IKustoEvent | IElasticEvent = any> = T extends { sysEventType: SysEventType.kusto }
    ? IKustoEvent
    : IElasticEvent;

export interface EventAction {
    label: string;
    icon?: AllIconsName;
    callback: (event: IEvent) => void;
    disabled?: (event: IEvent) => boolean;
}

export const isClosedEvent = (event: IEvent): boolean => {
    return event.sysEventType === SysEventType.kusto ? event.statusId === FindingStatusCode.CLOSE : false;
};

export const isAnyClosedFinding = (events: IEvent[]): boolean => {
    return events.some((event: IEvent) => isClosedEvent(event));
};
