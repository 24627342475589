import { FC } from 'react';
import { FormStyled } from '../../CustomForm.styled';
import { IFullErrorInfo, IItemLabelProps, ILabelItem } from '../../CustomForm.interface';
import { Label } from '../../../../../design-system/components-v2';
import { LabelColor } from '../../../../../design-system/components-v2/Label/Label.types';
import ItemComponentBottomArea from '../helperComponents/ItemComponentBottomArea';

export interface ILabelItemComponentProps {
    item: ILabelItem;
    showErrors: boolean;
    errorInfo?: IFullErrorInfo;
    isInner?: boolean;
}

export const LabelItemComponent: FC<ILabelItemComponentProps> = ({ item, showErrors, errorInfo, isInner }) => {
    const { state } = item;
    const labelProps: IItemLabelProps = state.labelProps || {};
    return (
        <FormStyled.TopItem widthInfo={state.widthInfo} isInner={isInner}>
            <Label
                {...labelProps}
                required={state.isRequired}
                text={item.state.label}
                color={state.labelProps?.color as LabelColor}
                leadingIconProps={state.icon}
            />
            {(state.secondaryLabel || state.secondaryIcon) && (
                <Label
                    {...labelProps}
                    required={state.isRequired}
                    text={item.state.secondaryLabel || ''}
                    color={state.labelProps?.color as LabelColor}
                    leadingIconProps={state.secondaryIcon}
                />
            )}
            <ItemComponentBottomArea state={state} showErrors={showErrors} errorInfo={errorInfo} />
        </FormStyled.TopItem>
    );
};
