import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import {
    BUTTON_STATE,
    COMPONENT_TEXT_COLOR,
    SUBMIT_STATUS_RESPONSE,
} from 'common/module_interface/settings/integrations/consts';

const DiscardSubmitButtonElement = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 24px;
`;

interface ICancelSubmitButtonWrapperObj {
    handleDiscardChanges: () => void;
    discardChangesButtonHidden: boolean;
    isButtonDisabled: boolean;
    submitForm: () => void;
    viewOnly: boolean;
    responseMessageSuccess?: boolean;
}

const GenericCancelSubmitButtonWrapper: React.FC<ICancelSubmitButtonWrapperObj> = ({
    handleDiscardChanges,
    discardChangesButtonHidden,
    isButtonDisabled,
    submitForm,
    viewOnly,
    responseMessageSuccess,
}) => {
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [saveMessageResponse, setSaveMessageResponse] = useState<string>('');

    const { t } = useTranslation();

    const handleSaveButtonOnClick = async () => {
        setSpinnerLoading(true);
        let resp: any = {};
        try {
            resp = await submitForm();
        } finally {
            resp.Status === SUBMIT_STATUS_RESPONSE.SUCCESS && setSaveMessageResponse(t('COMMON.SAVED_SUCCESSFULLY'));
            resp.Status === SUBMIT_STATUS_RESPONSE.FAIL_TEST && setSaveMessageResponse(t('COMMON.TEST_FAILED'));
            resp.Status === SUBMIT_STATUS_RESPONSE.FAIL_SAVE &&
                setSaveMessageResponse(resp.Message || t('COMMON.SAVED_FAILED'));
            setSpinnerLoading(false);
        }
    };

    return (
        <>
            <Stack margin={[0, 6]} alignItems={'flex-end'}>
                <Typography
                    elementType='h5'
                    color={responseMessageSuccess ? COMPONENT_TEXT_COLOR.SUCCESS : COMPONENT_TEXT_COLOR.ALERT}
                >
                    {saveMessageResponse}
                </Typography>
            </Stack>
            <DiscardSubmitButtonElement>
                <Button
                    variant='text'
                    size='medium'
                    onClick={handleDiscardChanges}
                    hidden={discardChangesButtonHidden ? false : true}
                    disabled={isButtonDisabled || viewOnly}
                    dataAid='Discard Changes'
                >
                    {t('COMMON.DISCARD_CHANGES')}
                </Button>
                <Button
                    variant='contained'
                    color='brandPrimary'
                    size='medium'
                    style={{ height: 32, marginLeft: 10 }}
                    disabled={isButtonDisabled || viewOnly}
                    dataAid='saveButton'
                    onClick={handleSaveButtonOnClick}
                    className={isButtonDisabled ? BUTTON_STATE.DISABLED : BUTTON_STATE.ACTIVE}
                >
                    {spinnerLoading ? <Spinner size={12} /> : t('COMMON.SAVE')}
                </Button>
            </DiscardSubmitButtonElement>
        </>
    );
};

export default GenericCancelSubmitButtonWrapper;
