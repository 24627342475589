import { Stack } from 'common/design-system/components-v2';
import styled, { css } from 'styled-components';

export const Content = styled.div`
    flex-grow: 1;
    padding: ${({ theme }) => `0 ${theme.spacing(4)}${theme.units}`};
    overflow: hidden;
`;

export const TopSection = styled(Stack)`
    border-bottom: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
`;

export const ContentItem = styled.div<{ reactTabOverflowHidden?: boolean }>`
    position: relative;
    background: #ffffff;
    flex-grow: 1;

    ${({ reactTabOverflowHidden }) =>
        reactTabOverflowHidden &&
        css`
            overflow: hidden;
        `}
`;
