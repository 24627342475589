export const buildFilterPropsByProperty = <T>(
    property: keyof T,
    data: T[],
): Record<string, { displayText: string }> => {
    const groupBy: Record<string, { displayText: string }> = {};
    data.forEach((item) => {
        const key = item[property];

        if (!key || typeof key === 'object') {
            return;
        }
        const keyString = String(key);

        if (!groupBy[keyString]) {
            groupBy[keyString] = { displayText: keyString };
        }
    });
    return groupBy;
};

export const buildValueCountArray = <T>(property: keyof T, exclusions: T[]) => {
    const countMap: { [key: string]: { value: any; count: number } } = {};
    exclusions.forEach((obj) => {
        const value = obj[property];
        const valueString = String(value);

        if (!countMap[valueString]) {
            countMap[valueString] = {
                value,
                count: 1,
            };
        } else {
            countMap[valueString].count++;
        }
    });

    return Object.keys(countMap).map((value) => ({
        value,
        count: countMap[value],
    }));
};
