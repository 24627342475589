import { useEffect, useState } from 'react';
import { IDataItem, IExclusion, IInitHookProps, IUseInitReturn } from '../exclusions.type';
import { applyFilters, factorExclusions } from '../services/utils';

export const useInit = ({ init, initFilters }: IInitHookProps): IUseInitReturn => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<IDataItem | null>(null);
    const [exclusions, setExclusions] = useState<IExclusion[] | null>(null);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const initItems = init();
                const promises = initItems.map((item) => item.func());
                const results = await Promise.allSettled(promises);
                const fetchedData: IDataItem = {
                    exclusions: null,
                    organizationalUnits: [],
                    ruleSet: [],
                    allAccounts: [],
                };
                results.forEach((result, index) => {
                    if (result.status === 'fulfilled') {
                        const responseData = result.value.data ?? result.value;
                        const key = initItems[index].name;
                        fetchedData[key] = applyFilters(key, responseData, initFilters);
                    }
                });
                const exclusions = factorExclusions(fetchedData as IDataItem);
                setExclusions(exclusions);
                setData(fetchedData as IDataItem);
                const hasErrors = results.some((result) => result.status === 'rejected');
                setIsError(hasErrors);
            } catch (error) {
                console.error((error as Error)?.message);
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };
        if (!init) return;
        fetchData();
    }, [init, initFilters]);

    return {
        isLoading,
        exclusions,
        isError,
        data,
    };
};
