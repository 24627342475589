import { numberWithCommas } from 'common/utils/helpFunctions';
import { InfoBox, StickyAlert } from '../ExportToCsv.styled';
import { ErrorType, ExportMethod, FormProps, IncludeColumns, IncludeRows, RadioName } from '../ExportToCsv.types';
import { useExportToCsvTranslations } from '../hooks/useExportToCsvTranslations';
import { GroupSelection, Icon, Input, Stack, Typography } from 'common/design-system/components-v2';
import { getFormRadioOptions, handleChangeRadioValue } from '../ExportToCsv.util';
import { DataTestIds } from '../ExportToCsv.consts';

const Form: React.FC<FormProps> = ({
    error,
    rowsCount,
    filteredRowsCount,
    allColumnsCount,
    visibleColumnsCount,
    includeColumns,
    includeRows,
    exportMethod,
    email,
    isOverLimit,
    onEmailChange,
    onRadioChange,
}) => {
    const translations = useExportToCsvTranslations(rowsCount, filteredRowsCount, allColumnsCount, visibleColumnsCount);
    const { handleChangeColumnsRadio, handleChangeMethodRadio, handleChangeRowsRadio } =
        handleChangeRadioValue(onRadioChange);

    const errors = {
        [ErrorType.OverLimit]: translations.errorOverLimit,
        [ErrorType.Email]: translations.errorInvalidEmail,
    };

    const columnOptions = getFormRadioOptions(RadioName.Columns, translations);
    const rowOptions = getFormRadioOptions(RadioName.Rows, translations);
    const methodOptions = getFormRadioOptions(RadioName.Method, translations);

    const exportMethodHelperText =
        exportMethod === ExportMethod.DirectDownload ? translations.helperDownload : translations.helperEmail;

    return (
        <Stack direction='column' spacing={6}>
            {error === ErrorType.OverLimit ? (
                <StickyAlert type='error'>
                    <Typography>{errors[error]}</Typography>
                </StickyAlert>
            ) : null}
            <Stack direction='column' spacing={5}>
                <GroupSelection
                    direction='row'
                    defaultValue={IncludeColumns.Visible}
                    label={translations.columnsLabel}
                    onChange={handleChangeColumnsRadio}
                    options={columnOptions}
                />
                <GroupSelection
                    direction='row'
                    defaultValue={IncludeRows.Filtered}
                    label={translations.rowsLabel}
                    onChange={handleChangeRowsRadio}
                    options={rowOptions}
                />
                <GroupSelection
                    direction='row'
                    defaultValue={ExportMethod.DirectDownload}
                    label={translations.deliveryLabel}
                    onChange={handleChangeMethodRadio}
                    options={methodOptions}
                    helperText={exportMethodHelperText}
                />
                {exportMethod === ExportMethod.ByEmail && (
                    <Input
                        value={email}
                        onChange={onEmailChange}
                        label={translations.emailLabel}
                        isError={error === ErrorType.Email}
                        helperText={error === ErrorType.Email ? errors[error] : ''}
                        dataAid={DataTestIds.emailInput}
                    />
                )}
            </Stack>
            <Stack direction='row' spacing={2}>
                <InfoBox direction='column' spacing={2} isError={isOverLimit} data-aid={DataTestIds.rowsInfo}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography>{translations.rowsTitle}</Typography>
                        {isOverLimit && <Icon name='warning' size={12} context='high' />}
                    </Stack>
                    <Typography variant='2xl'>
                        {includeRows === IncludeRows.Filtered
                            ? numberWithCommas(filteredRowsCount)
                            : numberWithCommas(rowsCount)}
                    </Typography>
                </InfoBox>
                <InfoBox direction='column' spacing={2} data-aid={DataTestIds.columnsInfo}>
                    <Typography>{translations.columnsTitle}</Typography>
                    <Typography variant='2xl'>
                        {includeColumns === IncludeColumns.Visible
                            ? numberWithCommas(visibleColumnsCount)
                            : numberWithCommas(allColumnsCount)}
                    </Typography>
                </InfoBox>
            </Stack>
        </Stack>
    );
};

export default Form;
