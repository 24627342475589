import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFlag = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 65.456 65.456'
        xmlSpace='preserve'
        ref={ref}
        {...props}
    >
        <path
            fill='currentColor'
            d='M57.427 5.031C53.76 1.646 49.895 0 45.611 0c-5.052 0-9.663 2.314-14.123 4.553-4.012 2.014-7.801 3.916-11.432 3.916-2.742 0-5.203-1.092-7.745-3.438a2.994 2.994 0 0 0-5.018 1.983 2.99 2.99 0 0 0-.23 1.149v54.293a3 3 0 1 0 6 0V44.807c2.222 1.1 4.536 1.66 6.992 1.66h.002c5.051-.001 9.662-2.314 14.122-4.553 4.013-2.014 7.803-3.915 11.434-3.915 2.742 0 5.203 1.092 7.744 3.438a3 3 0 0 0 5.035-2.204V7.235c0-.838-.35-1.636-.965-2.204zm-5.035 28.503A15.515 15.515 0 0 0 45.613 32c-5.052 0-9.664 2.314-14.125 4.553-4.012 2.013-7.801 3.914-11.431 3.915h-.001c-2.393 0-4.572-.833-6.778-2.605V12.934a15.502 15.502 0 0 0 6.779 1.535c5.052 0 9.664-2.314 14.123-4.553C38.192 7.902 41.982 6 45.612 6c2.395 0 4.574.833 6.78 2.605v24.929z'
        />
    </svg>
);
const ForwardRef = forwardRef(SvgFlag);
export default ForwardRef;
