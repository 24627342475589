import React from 'react';
import styled from 'styled-components';
import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { Vendors } from '../../consts/vendors';
import { getFixedVendorIcon } from './utils';

export interface CardItem {
    id: Vendors;
    icon: string;
    title?: string;
}

export interface CardProps extends CardItem {
    selected?: boolean;
}

const CardWrapper = styled.div<{ selected: boolean }>`
    width: 250px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    &:hover {
        box-shadow: ${({ theme }) => `${theme.shadows.hover}`};
        border: 1px solid
            ${({ selected, theme }) =>
                selected ? theme.palette.surface.brandPrimary : `${theme.palette.border.strong}`};
    }
    border: 1px solid
        ${({ selected, theme }) => (selected ? theme.palette.surface.brandPrimary : `${theme.palette.border.strong}`)};
    background: ${({ selected, theme }) => (selected ? theme.palette.surface.brandLight : 'inherit')};
`;

const Card: React.FC<CardProps> = ({ id, title, icon, selected }) => {
    const fixedIcon = getFixedVendorIcon(icon);

    return (
        <CardWrapper selected={!!selected} data-aid={`card-${id}`}>
            <Stack padding={10} spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Icon vendorNameOrPath={fixedIcon} size={32} />
                <Typography>{title}</Typography>
            </Stack>
        </CardWrapper>
    );
};

export default Card;
