import { ICustomTreeNode, ICustomTreeRendererProps, ICustomTreeSettings } from './CustomTree.interface';
import React from 'react';

import { CustomTreeDefaultNodeRenderer } from './renderers/CustomTreeDefaultNodeRenderer';
import { DEFAULT_ROWS_GAP } from './CustomTree.consts';

export const getCustomTreeRowsGap = (settings?: ICustomTreeSettings): number => settings?.rowsGap || DEFAULT_ROWS_GAP;

export const getCustomTreeNodeDepth = (node: ICustomTreeNode): number => {
    let depth = 0;
    let parent = node.parent;
    while (parent) {
        depth++;
        parent = parent.parent;
    }
    return depth;
};

export const getCustomTreeNodeRenderer = (
    node: ICustomTreeNode,
    settings?: ICustomTreeSettings,
): React.FC<ICustomTreeRendererProps> => {
    let renderer: React.FC<ICustomTreeRendererProps> | undefined;
    if (node.rendererType && settings?.renderersMap) {
        renderer = settings.renderersMap[node.rendererType];
    }
    return renderer || settings?.defaultRenderer || CustomTreeDefaultNodeRenderer;
};

export const enrichCustomTreeNode = (node: ICustomTreeNode, parent?: ICustomTreeNode): ICustomTreeNode => {
    const newNode: ICustomTreeNode = {
        id: node.id,
        data: node.data,
        rendererType: node.rendererType,
        parent,
    };
    if (node.children) {
        newNode.children = node.children.map((child) => enrichCustomTreeNode(child, newNode));
    }
    return newNode;
};

export const duplicateCustomTreeNode = (node: ICustomTreeNode): ICustomTreeNode => {
    const newNode: ICustomTreeNode = {
        id: node.id,
        data: node.data,
        rendererType: node.rendererType,
    };
    if (node.children) {
        newNode.children = node.children.map((child) => duplicateCustomTreeNode(child));
    }

    return enrichCustomTreeNode(newNode);
};

export const getCustomTreeNode = (root: ICustomTreeNode, nodeId: string): ICustomTreeNode | undefined => {
    if (root.id === nodeId) {
        return root;
    }

    if (root.children) {
        for (const child of root.children) {
            const matchingNode = getCustomTreeNode(child, nodeId);
            if (matchingNode) {
                return matchingNode;
            }
        }
    }
    return undefined;
};

export const updateCustomTreeNode = (
    root: ICustomTreeNode,
    oldNodeId: string,
    newData: any,
    newRendererType?: string,
): ICustomTreeNode => {
    const dupRoot: ICustomTreeNode = duplicateCustomTreeNode(root);
    const node = getCustomTreeNode(dupRoot, oldNodeId);
    if (node) {
        node.data = newData;
        if (newRendererType) {
            node.rendererType = newRendererType;
        }
    }
    return dupRoot;
};

export const addCustomTreeNode = (
    root: ICustomTreeNode,
    parentNodeId: string,
    newNodeId: string,
    newData: any,
    newRendererType?: string,
): ICustomTreeNode => {
    const dupRoot: ICustomTreeNode = duplicateCustomTreeNode(root);
    const newParent = getCustomTreeNode(dupRoot, parentNodeId) || root;
    if (!newParent.children) {
        newParent.children = [];
    }
    const newNode: ICustomTreeNode = {
        id: newNodeId,
        data: newData,
        rendererType: newRendererType,
    };
    const enrichedNewNode = enrichCustomTreeNode(newNode, newParent);
    newParent.children.push(enrichedNewNode);
    return dupRoot;
};

export const removeCustomTreeNode = (root: ICustomTreeNode, oldNodeId: string): ICustomTreeNode | undefined => {
    if (root.id === oldNodeId) {
        return undefined;
    }
    const dupRoot: ICustomTreeNode = duplicateCustomTreeNode(root);
    const node = getCustomTreeNode(dupRoot, oldNodeId);
    if (!node) {
        return dupRoot;
    }

    if (node.parent?.children) {
        node.parent.children = node.parent.children.filter((child) => child.id !== node.id);
    }
    return dupRoot;
};

export const stripAllParents = (node: ICustomTreeNode): ICustomTreeNode => {
    const newNode: ICustomTreeNode = {
        ...node,
        parent: undefined,
    };
    if (node.children) {
        newNode.children = node.children.map((child) => stripAllParents(child));
    }
    return newNode;
};

export const getCustomTreeRoot = <T = any>(node: ICustomTreeNode<T>): ICustomTreeNode<T> => {
    if (!node.parent) {
        return node;
    }

    return getCustomTreeRoot(node.parent);
};

const getNodeWithAllFlatChildren = <T = any>(node: ICustomTreeNode<T>): ICustomTreeNode<T>[] => {
    const flatNodes: ICustomTreeNode<T>[] = [node];
    if (node.children) {
        node.children.forEach((child) => {
            flatNodes.push(...getNodeWithAllFlatChildren(child));
        });
    }
    return flatNodes;
};

export const getAllCustomTreeFlatNodes = <T = any>(node: ICustomTreeNode<T>): ICustomTreeNode<T>[] => {
    const root: ICustomTreeNode<T> = getCustomTreeRoot(node);
    return getNodeWithAllFlatChildren(root);
};
