import React, { forwardRef } from 'react';
import ButtonGroupStyles from './ButtonGroup.styles';
import { IButtonGroupOptionProps, IButtonGroupProps } from './ButtonGroup.types';
import Stack from '../Stack/Stack';
import InputLabel from '../InputLabel/InputLabel';
import Typography from '../Typography/Typography';

const ButtonGroup = forwardRef<HTMLDivElement, IButtonGroupProps>((props, ref) => {
    const {
        options,
        value,
        defaultValue,
        onChange,
        buttonsProps,
        disabled,
        direction = 'row',
        label,
        helperText,
        labelProps,
        required,
        isError,
        removeBorder,
        removeBorderRadius,
        removeDivider,
        dataAid,
        ...restProps
    } = props;

    const [selectedButton, setSelectedButton] = React.useState<string>(
        value || defaultValue || options[0]?.value || '',
    );

    React.useEffect(() => {
        if (value) {
            setSelectedButton(value);
        }
    }, [value]);

    const handleOnClick = (option: IButtonGroupOptionProps, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (option.value) {
            if (!value) {
                setSelectedButton(option.value);
            }
            onChange && onChange(option.value, event);
        }
        option.onClick && option.onClick(event);
    };

    const shouldPresentDivider = (currentButtonIndex: number) => {
        if (removeDivider) return false;
        const currentValue = options[currentButtonIndex].value;
        const nextValue = options[currentButtonIndex + 1]?.value;
        const isLastButton = currentButtonIndex === options.length - 1;

        const isCurrentButtonSelected = currentValue === selectedButton;
        const isNextButtonSelected = nextValue === selectedButton;

        if (isLastButton || isCurrentButtonSelected || isNextButtonSelected) return false;
        return true;
    };

    return (
        <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={2} ref={ref}>
            {label && <InputLabel text={label} {...labelProps} required={required} />}
            <ButtonGroupStyles.Wrapper
                direction={direction}
                removeBorder={removeBorder}
                removeBorderRadius={removeBorderRadius}
                value={selectedButton}
                data-aid={dataAid}
            >
                {options.map((option, index) => (
                    <React.Fragment key={`button-group-item-${index}`}>
                        <ButtonGroupStyles.ButtonStyled
                            {...buttonsProps}
                            {...option}
                            {...restProps}
                            disabled={disabled || option.disabled}
                            selected={selectedButton === option.value}
                            onClick={(event) => handleOnClick(option, event)}
                            variant={
                                option.value
                                    ? selectedButton === option.value
                                        ? 'outlined'
                                        : 'text'
                                    : option.variant || 'text'
                            }
                            color={
                                option.value
                                    ? selectedButton === option.value
                                        ? 'brandPrimary'
                                        : 'normal'
                                    : option.color || 'normal'
                            }
                            direction={direction}
                            toggleButton={!!option.value}
                            id={`DS_ButtonGroup_${option.value}`}
                        />
                        {shouldPresentDivider(index) && <ButtonGroupStyles.Divider direction={direction} />}
                    </React.Fragment>
                ))}
            </ButtonGroupStyles.Wrapper>
            {helperText && (
                <Typography color={isError ? 'alert' : 'strong'} variant={'xs'}>
                    {helperText}
                </Typography>
            )}
        </Stack>
    );
});
ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
