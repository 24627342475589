import { IBaseUserModel, IUser } from './user';

export const USERS_SERVICE_ID = 'users_service_id';

export interface IUsersService {
    getUsers: (useCache?: boolean) => Promise<IUser[]>;
    addUser: (user: IBaseUserModel) => Promise<IUser>;
    deleteUser: (userId: number) => Promise<void>;
    editUser: (user: IUser) => Promise<IUser>;
}
