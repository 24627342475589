import React from 'react';
import { DrawerState, IDrawerContent, IDrawerOptions } from './Drawer.interface';
import { Drawer } from './Drawer';

export interface ILightDrawerProps {
    content?: IDrawerContent;
    options?: IDrawerOptions;
    onClose: (closeAll?: boolean) => void;
    state: DrawerState;
}

export const LightDrawer: React.FC<ILightDrawerProps> = ({ content, options, onClose, state }) => (
    <Drawer
        options={options}
        content={content ? React.createElement(content.component, content.componentProps) : undefined}
        title={content?.title}
        icon={content?.icon}
        state={state}
        rightHeaderContent={content?.rightHeaderContent}
        onClose={onClose}
        actions={content?.actions}
    />
);
