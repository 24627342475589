import { Addin } from 'common/extensibility/AddinRegistry';
import globalAddinContainer, { buildPath } from '../extensibility/AddinContainer';
import {
    AzureEnvironmentData,
    PATH_ENVIRONMENT_PAGE,
    PATH_ENVIRONMENT_PAGE_ACTION,
    PATH_ENVIRONMENT_PAGE_AZURE_DETAILS_PANEL,
    PATH_ENVIRONMENT_PAGE_AZURE_TAB,
    PATH_ENVIRONMENT_PAGE_AZURE_CHIP,
    PATH_ENVIRONMENT_PAGE_CUSTOM,
} from 'common/module_interface/assets/AzureEnvironment';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { AddinBase } from './addinBase';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { EnvironmentAction } from 'common/module_interface/assets/Environments';
import { ReactNode } from 'react';

export interface AzureEnvironmentAddingBase<T, T2 extends [AzureEnvironmentData] = [AzureEnvironmentData]>
    extends AddinBase<T, T2> {
    customLoadingLabel?: string; // Relevant for chips only at the moment
}
export interface CustomModalContent {
    content: ReactNode;
    name: string;
}

function addDetailsPanelItems(item: Addin<AzureEnvironmentAddingBase<BlockInfoProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_DETAILS_PANEL), item);
}

function getDetailsPanelItems(): AzureEnvironmentAddingBase<BlockInfoProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_DETAILS_PANEL));
}

function addTabs(item: Addin<AzureEnvironmentAddingBase<Tab>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_TAB), item);
}

function getTabs(): AzureEnvironmentAddingBase<Tab>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_TAB));
}

function addActions(item: Addin<AzureEnvironmentAddingBase<EnvironmentAction<[AzureEnvironmentData]>>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION), item);
}

function getActions(): AzureEnvironmentAddingBase<EnvironmentAction<[AzureEnvironmentData]>>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_ACTION));
}

function addChips(item: Addin<AzureEnvironmentAddingBase<IChipProps>>[]) {
    globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_CHIP), item);
}

function getChips(): AzureEnvironmentAddingBase<IChipProps>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_AZURE_CHIP));
}
function addModalCustomContent(item: Addin<AzureEnvironmentAddingBase<CustomModalContent>>[]) {
    return globalAddinContainer.add(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CUSTOM), item);
}
function getModalCustomContent(): AzureEnvironmentAddingBase<CustomModalContent>[] {
    return globalAddinContainer.get(buildPath(PATH_ENVIRONMENT_PAGE, PATH_ENVIRONMENT_PAGE_CUSTOM));
}

export const AzureEnvironmentAddinRegistry = {
    addDetailsPanelItems,
    getDetailsPanelItems,
    addTabs,
    getTabs,
    addActions,
    getActions,
    addChips,
    getChips,
    addModalCustomContent,
    getModalCustomContent,
};
