import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
        }
`;

const Svg = styled.svg<{ size: number }>`
    animation: ${spin} 2s linear infinite;
    height: ${(p) => p.size}px;
    width: ${(p) => p.size}px;
`;

const Circle = styled.circle<{ color: string }>`
    stroke: ${({ color }) => color};
    stroke-linecap: square;
    animation: ${dash} 1.5s ease-in-out infinite;
    stroke-width: 5;
    fill: none;
    stroke-linecap: round;
`;

const CircleBackground = styled.circle`
    stroke: ${({ theme }) => theme.palette.default.backgrounds.default};
    stroke-linecap: square;
    stroke-width: 5;
    fill: none;
`;

export default {
    Svg,
    Circle,
    CircleBackground,
};
