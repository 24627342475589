import dayjs from 'dayjs';
import i18n from 'common/services/translations/translations';
import React from 'react';
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { getAdditionalFieldValueGetter } from 'common/utils/ColumnDisplayValueGetters';

const formatLastRunningDate = (lastRunningDate: string) => {
    const imageRanInPastSevenDays = dayjs(lastRunningDate).isAfter(dayjs().subtract(7, 'days'));

    if (imageRanInPastSevenDays) {
        const daysDiff = dayjs(lastRunningDate).diff(dayjs(), 'days');
        // relativetime is a translation key that is used to format the relative time
        return i18n.t('COMMON.RELATIVE_TIME', { value: daysDiff });
    }

    return dayjs(lastRunningDate).format('MMM D, YYYY h:mm A Z');
};

const LastRunningDateCellRenderer: React.FC<ICellRendererParams> = (params) => {
    if (params.data.isGrouped) {
        return null;
    }
    let lastRunningDateString = '';
    const lastRunningDate = params.value;
    const imageIsRunning = getAdditionalFieldValueGetter('IsRunning')(params as ValueGetterParams) === 'True'; //This is the server value, not an actual boolean
    if (imageIsRunning) {
        lastRunningDateString = i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.LAST_RUNNING_DATE.NOW');
    } else if (lastRunningDate) {
        lastRunningDateString = formatLastRunningDate(lastRunningDate);
    }

    CellToClipboardHandler(params, String(lastRunningDateString));
    return <div>{lastRunningDateString}</div>;
};

export default LastRunningDateCellRenderer;
