import { Icon } from 'common/design-system/components-v2';
import React from 'react';
import { formatNumbersForDisplay } from '../helpers';
import { IGenericWidgetDataProps } from 'common/module_interface/overview/Interface';
import { AllIconsName, IconProps, allIcons } from 'common/design-system/components-v2/Icon/Icon.types';
import { ColorsWithState } from 'common/design-system/theme/theme';

interface IGenericWidgetDataCallback {
    onClickCallback?: () => void;
}

const IconAndNumberWidget: React.FC<IGenericWidgetDataProps & IGenericWidgetDataCallback> = (props) => {
    const items = props?.data?.items;
    const className = props?.data?.className;
    const status = props?.data?.status;

    const getIconProps = (icon: AllIconsName | string): IconProps => {
        if (icon in allIcons) {
            const fixedStatus: ColorsWithState | undefined = (() => {
                switch (status) {
                    case 'success':
                        return 'success';
                    case 'warning':
                        return 'warning';
                    case 'danger':
                        return 'alert';
                    case 'info':
                        return 'brandPrimary';
                }
            })();
            return { name: icon as AllIconsName, color: fixedStatus };
        }
        return { vendorNameOrPath: icon, color: status };
    };

    return (
        <div className='@container flex flex-1 items-center justify-center'>
            {items?.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={`items-center justify-center flex flex-1 p-6 @[150px]:p-8 ${className}`}
                    >
                        <div className='flex items-center flex-col gap-6 @[150px]:gap-7 @[300px]:gap-7 @[150px]:min-w-[120px]'>
                            {item.icon && (
                                <>
                                    <Icon size={48} {...getIconProps(item.icon)} />
                                    <div className={'border-t border-dashed self-stretch'}></div>
                                </>
                            )}
                            {/* eslint-disable-next-line */}
                            <a
                                onClick={props.onClickCallback}
                                style={{ color: item.color }}
                                className={`text-block text-center over:underline hover:cursor-pointer text-xl @[150px]:text-2xl @[200px]:text-3xl @[300px]:text-4xl ${status && 'drop-shadow'} `}
                            >
                                {formatNumbersForDisplay(Number(item.value))}
                            </a>
                            {item.key && (
                                <>
                                    <div className={'border-t border-dashed self-stretch'}></div>
                                    <div
                                        className={`text-center text-block text-2xs @[200px]:text-md ${!status && 'text-weak'} ${status && 'drop-shadow'}`}
                                    >
                                        {item.key}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default IconAndNumberWidget;
