import StackedColumnChart from 'common/components/Highcharts/StackedColumnChart';
import { IGenericWidgetDataSetProps } from 'common/module_interface/overview/Interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NoDataToDisplay from '../NoDataToDisplay/NoDataToDisplay';

const StackedColumnWidget: React.FC<IGenericWidgetDataSetProps> = (props) => {
    const { t } = useTranslation();
    const { items, additionalInformation } = props.data;
    const isDataExist = items?.find((item) => item.value?.length > 0);

    return (
        <div className='m-auto'>
            {isDataExist && (
                <div className='flex flex-column'>
                    <StackedColumnChart {...props} header={props.widget?.title || ''} />
                    {additionalInformation?.itemsCount && (
                        <div className='m-7'>
                            {' '}
                            {t('OVERVIEW.DASHBOARD.WIDGETS.RESULTS_COUNT', {
                                itemsCount: items?.length,
                                totalCount: additionalInformation?.itemsCount,
                            })}
                        </div>
                    )}
                </div>
            )}
            {!isDataExist && (
                <div>
                    <NoDataToDisplay />
                </div>
            )}
        </div>
    );
};

export default StackedColumnWidget;
