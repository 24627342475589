import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
    border-top: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
`;

const FooterStyles = {
    Wrapper,
};

export default FooterStyles;
