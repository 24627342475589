import Stack from '../../../design-system/components-v2/Stack';
import Icon from '../../../design-system/components-v2/Icon';
import Tooltip from '../../../design-system/components-v2/Tooltip';
import React, { ReactNode } from 'react';
import { IconSizes } from '../../../design-system/components-v2/Icon/Icon.types';
import { ColorsWithState } from '../../../design-system/theme/theme';

export const InfoPoint: React.FC<{ content: ReactNode, interactive?: boolean, iconSize?: IconSizes, iconColor?: ColorsWithState }> =
({ content, interactive, iconSize, iconColor }) =>
    <Tooltip content={content} interactive={interactive}>
        <Stack justifyContent='center' alignItems='center' style={{ zIndex: 1 }}>
            <Icon name='info' size={iconSize || 16} color={iconColor || 'brandPrimary'} />
        </Stack>
    </Tooltip>;
