import { CGColDef } from '../../../components/ProtectedAssets/ProtectedAssetsTable.interface';
import { buildPath, globalAddinContainer } from '../../../extensibility/AddinContainer';
import { Addin } from '../../../extensibility/AddinRegistry';
import { IFilterProps } from '../../../components/FilterPanel/FilterPanelManager';
import { IProtectedAssetFilter } from '../../assets/ProtectedAssets';
import { LOGS_TABLE } from './Logs.consts';
import { ILogTableFilterParams, ILogTablePlatformTab } from './Logs.interface';
import { COLUMNS, FILTERS } from '../Intelligence.const';

const LOGS_TABLE_COLUMN_DEFS = buildPath(LOGS_TABLE, COLUMNS);
const LOGS_TABLE_FILTER_DEFS = buildPath(LOGS_TABLE, FILTERS);
const LOGS_TABLE_TABS_DEFS = buildPath(LOGS_TABLE, 'tabs');

export const LogsTableRegistry = {
    addColumnDefs: (columnDefs: CGColDef[], idProperty: string) => {
        globalAddinContainer.addMap<CGColDef>(LOGS_TABLE_COLUMN_DEFS, columnDefs, idProperty);
    },

    getColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(LOGS_TABLE_COLUMN_DEFS);
    },

    addFilterDefs: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(LOGS_TABLE_FILTER_DEFS, filterAddins);
    },

    getFilterById: (filterId: string, params?: ILogTableFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(LOGS_TABLE_FILTER_DEFS, filterId, params);
    },

    getFilterDefs(filters: IProtectedAssetFilter[], params: ILogTableFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = LogsTableRegistry.getFilterById(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    },

    addTabs: (tabs: ILogTablePlatformTab[]) => {
        globalAddinContainer.addMap<ILogTablePlatformTab>(LOGS_TABLE_TABS_DEFS, tabs);
    },

    getTabs: (): ILogTablePlatformTab[] => {
        return globalAddinContainer.get<ILogTablePlatformTab>(LOGS_TABLE_TABS_DEFS);
    },

    getTabById: (tabId: string): ILogTablePlatformTab | undefined => {
        return globalAddinContainer.getById<ILogTablePlatformTab>(LOGS_TABLE_TABS_DEFS, tabId);
    },

};
