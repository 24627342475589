import styled from 'styled-components';

const StyledTooltip = styled.div`
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    box-shadow: ${({ theme }) => theme.shadows[2]};
    border: unset;
    background-color: ${({ theme }) => theme.palette.surface.primary};
    padding: ${({ theme }) => `${theme.spacing(2)}${theme.units} ${theme.spacing(4)}${theme.units}`};
    max-width: 500px;
    white-space: pre-wrap;
    max-height: 90vh;
    overflow: auto;
`;

const StyledArrow = styled.div`
    position: relative;
    &::before {
        content: '';
        position: absolute;
        display: block;
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        background: ${({ theme }) => theme.palette.surface.primary};
        border: 1px solid transparent;
    }

    &[data-placement*='top'] {
        bottom: 0;
        &::before {
            bottom: -6.6px;
            left: -2px;
            transform-origin: center top;
            border-right-color: ${({ theme }) => theme.palette.border.light};
            border-bottom-color: ${({ theme }) => theme.palette.border.light};
        }
    }
    &[data-placement*='right'] {
        left: 0;
        &::before {
            bottom: -6px;
            left: -5px;
            border-left-color: ${({ theme }) => theme.palette.border.light};
            border-bottom-color: ${({ theme }) => theme.palette.border.light};
        }
    }
    &[data-placement*='bottom'] {
        top: 0;
        &::before {
            top: -6.6px;
            left: -8px;
            transform-origin: center bottom;
            border-left-color: ${({ theme }) => theme.palette.border.light};
            border-top-color: ${({ theme }) => theme.palette.border.light};
        }
    }
    &[data-placement*='left'] {
        right: 0;
        &::before {
            bottom: -2px;
            right: -6.6px;
            transform-origin: center left;
            border-right-color: ${({ theme }) => theme.palette.border.light};
            border-top-color: ${({ theme }) => theme.palette.border.light};
        }
    }
`;

export default {
    StyledTooltip,
    StyledArrow,
};
