import styled from 'styled-components';
import Typography from '../Typography/Typography';

const StyledWrapper = styled.div`
    white-space: pre-wrap;
    word-break: break-word;
    overflow: visible;
`;

const TextWithDisc = styled(Typography)`
    margin-left: 12px;
    position: relative;
    ::before {
        content: '•';
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export default {
    StyledWrapper,
    TextWithDisc,
};
